import { FeedbackGet } from "@/app/core/base/controllers/FeedbackController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import React, { useEffect, useState } from "react";
// import ReactToExcel from "react-html-table-to-excel";

export default function FeedbackReport({ structures, feedback }) {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    loadFeedbackData();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  async function loadFeedbackData() {
    await FeedbackGet(
      feedback.id,
      `${startDate}T00:00:00`,
      `${endDate}T23:59:00`,
      (res) => {
        setData(res);
      },
    );
  }

  return (
    <div className="block block-bordered">
      <div className="block-header">
        <div className="block-title">Relatórios</div>
        <div className="block-tools row">
          <div className="form-group col-md-6">
            <span>Data de início</span>
            <input
              type="date"
              className="form-control form-control-alt form-control-sm"
              value={startDate}
              onChange={(evt) => setStartDate(evt.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <span>Data fim</span>
            <input
              type="date"
              className="form-control form-control-alt form-control-sm"
              value={endDate}
              onChange={(evt) => setEndDate(evt.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="block-content">
        {/* <ReactToExcel
                    className="btn btn-info mt-3"
                    table="feedback"
                    filename={`Relatorio Feedback - ${feedback.name} - periodo: ${startDate} - ${endDate}`}
                    sheet="sheet 1"
                    buttonText="Exportar"
                /> */}
        <table
          className="table table-hover table-bordered"
          id="feedback"
          name="feedback"
        >
          <thead>
            <tr>
              {structures &&
                structures.map((item) => (
                  <th>
                    <strong>{item.description}</strong>
                  </th>
                ))}
              <th>Cliente</th>
              <th>Data de criação</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item) => (
                <tr>
                  {item.feedbacks &&
                    item.feedbacks.map((res) => <td>{res.value}</td>)}
                  <td>
                    {item.customer &&
                      `${item.customer.customerCode} - ${item.customer.name}`}
                  </td>
                  <td>{DateTranslate(item.createdDate)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
