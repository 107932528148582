import React, { useEffect, useState } from "react";
import Loading from "@/app/core/layouts/main/components/Loading";
import { ToastContainer, toast } from "react-toastify";
import { ArrayMes, DateTranslate } from "@/app/core/util/DateTranslater";
import { Message } from "@/app/core/util/messages/GeralMessages";
import CustomerListCard from "../../oportunities/main/CustomerListCard";
import ReportController from "@/app/core/base/controllers/ReportController";
import { UpdateCustomerStage } from "@/app/core/base/controllers/CustomerController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function MainComponent(props) {
    const [loading, setLoading] = useState(false);
    const [oportunitiesRessurgenceList, setOportunitiesRessurgenceList] =
        useState([]);
    const [data, setData] = useState({});
    const date = new Date();

    const [startDate, setStartDate] = useState(date.getMonth());
    const [endDate, setEndDate] = useState(date.getFullYear());

    const cols = [
        { header: "Cód.", isSortable: true, name: "customerCode" },
        {
            header: "Nome",
            isSortable: true,
            name: "name",
            style: {
                maxWidth: "220px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
            },
        },
        {
            header: "Email",
            isSortable: true,
            name: "email",
        },
        { header: "Telefone", isSortable: true, name: "phone" },
        { header: "Celular", isSortable: true, name: "whatsapp" },
        { header: "Última compra", isSortable: true, name: "lastBuy" },
    ];

    useEffect(() => {
        loadEvaluation();
    }, [startDate, endDate]);

    async function loadEvaluation() {
        ReportController().CustomRetention(startDate + 1, endDate, (res) => {
            for (let s in res.list) {
                res.list[s].lastBuy = DateTranslate(res.list[s].lastBuy);
            }
            setOportunitiesRessurgenceList(res.list);
            setData(res);
            setLoading(false);
        });
    }

    return (
        <div className="content">
            <Loading loading={loading} />
            <div className="row">
                <div className="col-md-6">
                    <div className="content-heading ml-3">
                        <h2>Oportunidades</h2>
                    </div>
                </div>

                <div className="form-group col-md-2">
                    <span>Mês</span>
                    <select
                        className="form-control form-control-alt form-control-sm"
                        value={startDate}
                        onChange={(evt) => setStartDate(parseInt(evt.target.value))}
                    >
                        <option value={undefined}>Selecione</option>
                        {ArrayMes.map((item, index) => (
                            <option value={index} selected={startDate === index ? true : false}>{item}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group col-md-2">
                    <span>Ano</span>
                    <input
                        type="number"
                        className="form-control form-control-alt form-control-sm"
                        value={endDate}
                        max={date.getFullYear()}
                        min={date.getFullYear() - 5}
                        onChange={(evt) => setEndDate(parseInt(evt.target.value))}
                    />
                </div>
                <div className="col-md-2">
                    <button
                        className="btn btn-info mt-3"
                        onClick={() => loadEvaluation()}
                    >
                        Pesquisar
                    </button>
                </div>
                <div className="col-md-4">

                    <a class="block block-rounded block-link-pop border border-2x border-info" href="javascript:void(0)">
                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                            <div>
                                <i class="si si-users text-primary"></i>
                            </div>
                            <div class="ml-3 text-right">
                                <p class="font-size-h3 font-w300 mb-0">
                                    {data && data.preview}
                                </p>
                                <p class="text-muted mb-0">
                                    Clientes mês passado
                                </p>
                            </div>
                        </div>
                    </a>

                </div>
                <div className="col-md-4">

                    <a class="block block-rounded block-link-pop border border-2x border-success" href="javascript:void(0)">
                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                            <div>
                                <i class="si si-users text-primary"></i>
                            </div>
                            <div class="ml-3 text-right">
                                <p class="font-size-h3 font-w300 mb-0">
                                    {data && data.current}
                                </p>
                                <p class="text-muted mb-0">
                                    Clientes retidos este mês
                                </p>
                            </div>
                        </div>
                    </a>

                </div>
                <div className="col-md-4">

                    <a class="block block-rounded block-link-pop border border-2x border-warning" href="javascript:void(0)">
                        <div class="block-content block-content-full d-flex align-items-center justify-content-between">
                            <div>
                                <i class="si si-users text-primary"></i>
                            </div>
                            <div class="ml-3 text-right">
                                <p class="font-size-h3 font-w300 mb-0">
                                    {data && data.diference && (data.diference).toFixed(2)}%
                                </p>
                                <p class="text-muted mb-0">
                                    % Retidos
                                </p>
                            </div>
                        </div>
                    </a>

                </div>
                <div className="col-md-12">
                    <CustomerListCard
                        title={oportunitiesRessurgenceList && `(${oportunitiesRessurgenceList.length}) Retenção`}
                        isOpen={true}
                        infos={oportunitiesRessurgenceList}
                        cols={cols}
                    />
                </div>
            </div>
        </div>
    );
}
