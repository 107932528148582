import ReportController from "@/app/core/base/controllers/ReportController";
import React, { useEffect, useState } from "react";
import ExigencyReportModal from "./Modals/ExigencyReportModal";
import Storage from "@/app/core/util/Storage";

export default function ExigencyCounter({ startDate, endDate, refresh }) {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const report = ReportController();
  const [data, setData] = useState({
    notStarted: 0,
    ongoing: 0,
    done: 0,
    accuracity: 0,
    total: 0,
    internals: 0,
    internalsPercentage: 0,
    others: 0,
    othersPercentage: 0,
  });

  useEffect(() => {
    loadCounters()
  }, [refresh]);

  async function loadCounters(value) {
      setLoading(true);
      await report.ExigencyCounters(value != undefined ? value : 1, (res) => {
        res.internalsPercentage = parseInt((res.internals / res.total) * 100);
        res.othersPercentage = parseInt((res.others / res.total) * 100);
        
        let oldTotal = res.protocols;// + res.exigencies;
        res.protocolsPercentage = parseInt((res.protocols / oldTotal) * 100);
        res.exigenciesPercentage = parseInt((res.exigencies / oldTotal) * 100);
        
        let newTotal = res.currentProtocols;// + res.currentExigencies;
        res.currentProtocolsPercentage = parseInt((res.currentProtocols / newTotal) * 100);
        res.currentExigenciesPercentage = parseInt((res.currentExigencies / newTotal) * 100);
        
        res.startDate = startDate;
        res.endDate = endDate;
        setLoading(false);
        
        setData(res);
      });
      setLoading(false);
  }

  return (
    <div>
      <a class="block block-rounded bg-danger" href="javascript:void(0)">
        <div class="block-content bg-danger row">
          <p class="col-md-6 text-white text-uppercase font-size-sm font-w700">
            Exigências
          </p>
          <div className="col-6 tex-right">
            <div className="form-group">
              <select
                className="form-control"
                onChange={(evt) => loadCounters(evt.target.value)}
              >
                <option value={1}>7x7 dias</option>
                <option value={2}>15x15 dias</option>
                <option value={3}>Mês passado x mês atual</option>
                <option value={4}>Trimestre passado x trimestre atual</option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="block-content block-content-full"
          onClick={() => setShowModal(true)}
        >
          {loading === true ? (
            <div className="text-center ">
              <i className={"fa fa-circle-notch fa-spin py-5 fa-2x"}></i>
            </div>
          ) : (
            <div class="row text-center">
              <div className="col-6 text-white">Período anterior</div>
              <div className="col-6 text-white">Período atual</div>
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <div class="item item-circle text-white bg-black-25 mx-auto">
                    {data && data.protocols}
                  </div>
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {data && data.protocolsPercentage}%
                  </p>
                  <p class="text-white-75 mb-0">Protocolos</p>
                </div>
              </div>
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <div class="item item-circle text-white bg-black-25 mx-auto">
                    {data && data.exigencies}
                  </div>
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {data && data.exigenciesPercentage}%
                  </p>
                  <p class="text-white-75 mb-0">Exigencias</p>
                </div>
              </div>

              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <div class="item item-circle text-white bg-black-25 mx-auto">
                    {data && data.currentProtocols}
                  </div>
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {data && data.currentProtocolsPercentage}%
                  </p>
                  <p class="text-white-75 mb-0">Protocolos</p>
                </div>
              </div>
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <div class="item item-circle text-white bg-black-25 mx-auto">
                    {data && data.currentExigencies}
                  </div>
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {data && data.currentExigenciesPercentage}%
                  </p>
                  <p class="text-white-75 mb-0">Exigencias</p>
                </div>
              </div>
              {/* <div class="col-4">
              <div class="py-3 border-right border-black-op">
                <div class="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.total}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">100%</p>
                <p class="text-white-75 mb-0">Total</p>
              </div>
            </div>
            <div class="col-4">
              <div class="py-3 border-right border-black-op">
                <div class="item item-circle text-white bg-black-25 mx-auto">
                  {data && data.internals}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.internalsPercentage}%
                </p>
                <p class="text-white-75 mb-0">Erros internos</p>
              </div>
            </div>
            <div class="col-4">
              <div class="py-3 border-black-op">
                <div class="item item-circle bg-black-25 mx-auto text-white">
                  {data && data.others}
                </div>
                <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.othersPercentage}%
                </p>
                <p class="text-white-75 mb-0">Outras exigências</p>
              </div>
            </div> */}
            </div>
          )}
        </div>
      </a>
      <ExigencyReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        infos={data && data.returns}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}
