import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import AccountController, {
  UserConsult,
  UserInsert,
  UserUpdate,
} from "@/app/core/base/controllers/UsersController";
import { SendUserInvitation } from "@/app/core/base/controllers/ContactController";
import {
  DevolutionStepDelete,
  DevolutionStepInsert,
  DevolutionStepUpdate,
  DevolutionStepUser,
} from "@/app/core/base/controllers/DevolutionStepController";
import { Dropdown } from "react-bootstrap";
import OrganListHelper, {
  OrganStatusList,
  OrganStatusUserTranslate,
} from "@/app/core/util/helpers/OrganListHelper";
import { DeleteConfirm, Message } from "@/app/core/util/messages/GeralMessages";
import Storage from "@/app/core/util/Storage";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import {
  OrganAssignmentByUser,
  OrganAssignmentDelete,
  OrganAssignmentInsert,
} from "@/app/core/base/controllers/OrganAssignmentController";
import { ContractTypeList } from "@/app/core/util/helpers/UserHelper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PreparationStepCard from "./PreparationStepCard";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { OnDataChange } from "@/app/core/util/services/DataService";
import OportunitiesCard from "./OportunitiesCard";

export default function UserFormModal({
  show,
  onHide,
  isLoading,
  values,
  customerId,
}) {
  const [data, setData] = useState({});
  const [organs, setOrgans] = useState([]);
  const [organsAssignment, setOrgansAssignment] = useState([]);
  const [devolutionStep, setDevolutionStep] = useState("");
  const [link, setLink] = useState("");
  const [organ, setOrgan] = useState(0);
  const userData = Storage.getUserData();
  const organCtrl = OrganListController();
  const account = AccountController();

  useEffect(() => {
    let infos = {};
    if (values.id !== undefined) {
      infos = values;
      load();
    }
    if (customerId !== undefined) {
      infos.customerId = parseInt(customerId);
      infos.level = 5;
    }
    setData(infos);
    loadOrgans();
  }, [values, customerId]);

  function loadOrgans() {
    organCtrl.OrgansSelect((res) => {
      setOrgans(res);
    });
  }

  function loadOrgansAttibutions() {
    OrganAssignmentByUser(values.id, (res) => {
      setOrgansAssignment(res);
    });
  }

  function load() {
    setTimeout(() => {
      if (values.level === 4) {
        loadDevolutionAttibutions();
      }
      if (values.level !== 1 && values.level !== 5) {
        loadOrgansAttibutions();
      }
    }, 500);
  }

  function loadDevolutionAttibutions() {
    DevolutionStepUser(values.id, (res) => {
      const old = values;
      setData({ ...old, devolutionSteps: res });
    });
  }

  function saveUser() {
    if (data.id !== undefined) {
      updateUser();
    } else {
      postUsers();
    }
  }

  function handleChange(evt, type) {
    let value = data;
    let newValue = {
      ...value,
      [evt.target.name]:
        evt.target.name === "level" ||
        evt.target.name === "contractType" ||
        evt.target.name === "status" ||
        evt.target.name === "role"
          ? parseInt(evt.target.value)
          : evt.target.value,
    };

    setData(OnDataChange(data, evt, type));
  }

  function removeStep(item) {
    if (window.confirm(DeleteConfirm)) {
      DevolutionStepDelete(item.id, (res) => {
        toast.success("Removido com sucesso");
        loadDevolutionAttibutions();
      });
    }
  }

  function removeOrganAssignment(item) {
    if (window.confirm(DeleteConfirm)) {
      OrganAssignmentDelete(item.id, (res) => {
        toast.success("Removido com sucesso");
        loadOrgansAttibutions();
      });
    }
  }

  function addStep() {
    if (devolutionStep.length > 0) {
      DevolutionStepInsert(
        { userId: data.id, step: parseInt(devolutionStep) },
        (res) => {
          toast.success("Salvo com sucesso");
          loadDevolutionAttibutions();
        },
      );
    } else {
      toast.error("Preencha os dados para salvar");
    }
  }

  function addOrgan() {
    if (organ.length > 0) {
      OrganAssignmentInsert(
        { userId: data.id, organId: parseInt(organ) },
        (res) => {
          toast.success("Salvo com sucesso");
          loadOrgansAttibutions();
        },
      );
    } else {
      toast.error("Preencha os dados para salvar");
    }
  }

  function addAllOrgan() {
    if (organs.length > 0) {
      for (let s in organs) {
        OrganAssignmentInsert(
          { userId: data.id, organId: organs[s].id },
          (res) => {
            toast.success("Salvo com sucesso");
            loadOrgansAttibutions();
          },
        );
      }
    }
  }

  function postUsers() {
    const user = {
      ...data,
      level: parseInt(data.level),
      userName: data.email,
      status: 1,
      password: "@HasaDocumentos01",
    };
    UserConsult(user.email, result => {
      if(result === true){
        toast.error(Message.AlreadyExist);
      }else{
        UserInsert(user, (res) => {
          SendUserInvitation(user);
          onHide();
          toast.info("Usuário Cadastrado com Sucesso");
        });
      }
    })
  }

  function updateUser() {
    UserUpdate(data, (res) => {
      toast.info("Usuário atualizado com Sucesso");
      onHide();
    });
  }

  async function resetAdmPassword() {
    if (window.confirm(Message.Confirm)) {
      await account.AdministratorRestartPassword(data.id, data, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  async function AdmPasswordGet() {
    if (window.confirm(Message.Confirm)) {
      await account.AdministratorForgotPassword(data.email, (res) => {
        toast.success(Message.Saved);
        setLink(res);
      });
    }
  }

  function changeType(item, value) {
    item.organType = value;
    DevolutionStepUpdate(item, (res) => {
      toast.success(Message.Saved);
      load();
    });
  }

  return (
    <Modal
      title="Usuário"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={saveUser}
      onSubmit={() => saveUser()}
    >
      <div className="row">
        <div className="col-sm-12">
          <div className="col-sm-12">
            <label for="firstName">Nome</label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={data && data.firstName}
              name="firstName"
              placeholder="Nome"
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div className="col-sm-12">
            <label for="firstName">sobrenome</label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={data && data.lastName}
              name="lastName"
              placeholder="Sobrenome"
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div className="col-sm-12">
            <label for="email">Email</label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              value={data && data.email}
              onChange={(event) => handleChange(event)}
              placeholder="E-mail do usuário"
            />
          </div>
          <div className="col-sm-12">
            <label for="phone">Celular</label>
            <InputMask
              mask="(99) 99999-9999"
              maskChar=""
              type="text"
              className="form-control"
              id="advanced-filter-document"
              name="phone"
              placeholder="(11) 99999-9999"
              value={data && data.phone}
              onChange={(event) => {
                handleChange({
                  target: {
                    value: event.target.value.replace(/\D/g, ""),
                    name: "phone",
                  },
                });
              }}
            />
          </div>
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="level">Departamento</label>
              <select
                className="form-control"
                id="level"
                name="level"
                onChange={(event) => handleChange(event, FieldTypeObj.Number)}
                disabled={customerId !== undefined ? true : false}
                defaultValue={data && data.level}
              >
                <option value={null}>Selecionar</option>
                <option value="1" selected={data && data.level === 1}>
                  Orçamento
                </option>
                <option value="2" selected={data && data.level === 2}>
                  Análise
                </option>
                <option value="3" selected={data && data.level === 3}>
                  Preparação
                </option>
                <option value="4" selected={data && data.level === 4}>
                  Devolução Órgão
                </option>
                {customerId !== undefined ? (
                  <option
                    value="5"
                    selected={
                      data.level === 5 || customerId !== undefined
                        ? true
                        : false
                    }
                  >
                    Cliente
                  </option>
                ) : (
                  ""
                )}
                <option value="6" selected={data && data.level === 6}>
                  Operação
                </option>
                <option value="7" selected={data && data.level === 7}>
                  Cobrança
                </option>
                <option value="8" selected={data && data.level === 7}>
                  Financeiro
                </option>
                <option value="10" selected={data && data.level === 10}>
                  Administrador
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="contractType">Tipo de contrato</label>
              <select
                className="form-control"
                id="contractType"
                name="contractType"
                onChange={(event) => handleChange(event, FieldTypeObj.Number)}
                defaultValue={data && data.contractType}
              >
                <option value={null}>Selecionar</option>
                {ContractTypeList.map((item) => (
                  <option
                    value={item.value}
                    selected={data && data.contractType === item.value}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            ""
          )}
          {userData && userData.level === 10 ? (
            <div className="col-sm-12 py-4">
              <label for="contractType">Alterar senha</label>
              <input
                className="form-control"
                name="newPassword"
                onChange={(event) => handleChange(event)}
              />
              <button
                className="btn btn-dual btn-info"
                onClick={() => resetAdmPassword()}
              >
                Alterar senha
              </button>
            </div>
          ) : (
            ""
          )}
          {userData && userData.level === 10 ? (
            <div className="col-sm-12 py-4">
              <button
                className="btn btn-warning btn-info"
                onClick={() => AdmPasswordGet()}
              >
                Gerar link de recuperação
              </button>
              {link && link.length > 0 ? (
                <CopyToClipboard
                  text={link}
                  onCopy={() =>
                    toast.info("Copiado para a área de transferência")
                  }
                >
                  <button type="button" className="btn btn-dark" title="copiar">
                    <i className="far fa-copy" /> Copiar Link
                  </button>
                </CopyToClipboard>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {data && data.level === 4 && data.id !== undefined ? (
            <div className="col-sm-12">
              <div className="block block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Atribuições</div>
                  <div className="block-tools">
                    <Dropdown>
                      <Dropdown.Toggle variant="light">
                        <i className="si si-plus"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="form-group">
                          <span>Selecione o passo</span>
                          <select
                            className="form-control"
                            onChange={(evt) =>
                              setDevolutionStep(evt.target.value)
                            }
                          >
                            <option>Selecione</option>
                            {OrganStatusList.map((item) => (
                              <option value={item.value}>{item.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-square btn-success"
                            onClick={() => addStep()}
                          >
                            Salvar
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="block-content">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Id</td>
                        <td>Passo</td>
                        <td>Tipo de Protocolos</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.devolutionSteps &&
                        data.devolutionSteps.map((item) => (
                          <tr>
                            <td>{item.id}</td>
                            <td>{OrganStatusUserTranslate(item.step)}</td>
                            <td>
                              <div
                                class="btn-group"
                                role="group"
                                aria-label="Horizontal Secondary"
                              >
                                {OrganListHelper().OrganListTypeList.map(
                                  (type) => (
                                    <button
                                      type="button"
                                      onClick={() =>
                                        changeType(item, type.value)
                                      }
                                      className={`btn btn-sm btn${item.organType == type.value ? "" : "-outline"}-secondary`}
                                    >
                                      {type.name}
                                    </button>
                                  ),
                                )}
                              </div>
                            </td>
                            <td>
                              <button
                                className="btn btn-dual"
                                onClick={() => removeStep(item)}
                              >
                                <i className="si si-close text-danger"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {data && data.level === 3 && data.id !== undefined ? (
            <div className="col-sm-12 mt-3">
              <PreparationStepCard userId={data.id} />
            </div>
          ) : (
            ""
          )}
          {data &&
          (data.level === 1 || data.level === 10) &&
          data.id !== undefined ? (
            <div className="col-sm-12 mt-3">
              <OportunitiesCard userId={data.id} />
            </div>
          ) : (
            ""
          )}
          {data &&
          data.level !== 1 &&
          data.level !== 5 &&
          data.level !== 7 &&
          data.id !== undefined ? (
            <div className="col-sm-12">
              <div className="block block-fx-pop">
                <div className="block-header">
                  <div className="block-title">Atribuições de órgãos</div>
                  <div className="block-tools">
                    <Dropdown>
                      <Dropdown.Toggle variant="light">
                        <i className="si si-plus"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="form-group">
                          <span>Selecione o órgão a ser atribuído</span>
                          <select
                            className="form-control"
                            onChange={(evt) => setOrgan(evt.target.value)}
                          >
                            <option>Selecione</option>
                            {organs &&
                              organs.map((item) => (
                                <option value={item.id}>{item.name}</option>
                              ))}
                          </select>
                        </div>
                        <div className="text-center">
                          <button
                            className="btn btn-square btn-block btn-success"
                            onClick={() => addOrgan()}
                          >
                            Salvar
                          </button>
                          <div className="text-center py-3">OU</div>
                          <button
                            className="btn btn-square btn-block btn-warning mt-1"
                            onClick={() => addAllOrgan()}
                          >
                            Adicionar todos
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="block-content">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <td>Id</td>
                        <td>Órgão</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {organsAssignment &&
                        organsAssignment.map((item) => (
                          <tr>
                            <td>{item.id}</td>
                            <td>{item.organ && item.organ.name}</td>
                            <td>
                              <button
                                className="btn btn-dual"
                                onClick={() => removeOrganAssignment(item)}
                              >
                                <i className="si si-close text-danger"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="status">Status</label>
              <select
                className="form-control"
                id="status"
                name="status"
                onChange={(event) => handleChange(event, FieldTypeObj.Number)}
                defaultValue={data && data.active}
              >
                <option value={null}>Selecionar</option>
                <option value={0} selected={data && data.status === 0}>
                  Pendente
                </option>
                <option value={1} selected={data && data.status === 1}>
                  Ativo
                </option>
                <option value={2} selected={data && data.status === 2}>
                  Inativo
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="status">
                Esteira de consulta de situação cadastral
              </label>
              <select
                className="form-control"
                name="registrationStatus"
                onChange={(event) => handleChange(event, FieldTypeObj.Bool)}
                defaultValue={data && data.active}
              >
                <option value={null}>Selecionar</option>
                <option
                  value={true}
                  selected={
                    data && data.registrationStatus == true ? true : false
                  }
                >
                  Habilitado
                </option>
                <option
                  value={false}
                  selected={
                    data && data.registrationStatus == false ? true : false
                  }
                >
                  Desabilitado
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="supervisorPanel">Painel supervisor</label>
              <select
                className="form-control"
                name="supervisorPanel"
                onChange={(event) => handleChange(event, FieldTypeObj.Bool)}
                defaultValue={data && data.supervisorPanel}
              >
                <option value={null}>Selecionar</option>
                <option
                  value={true}
                  selected={data && data.supervisorPanel == true ? true : false}
                >
                  Habilitado
                </option>
                <option
                  value={false}
                  selected={
                    data && data.supervisorPanel == false ? true : false
                  }
                >
                  Desabilitado
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="exigencyPanel">Painel de exigências</label>
              <select
                className="form-control"
                name="exigencyPanel"
                onChange={(event) => handleChange(event, FieldTypeObj.Bool)}
                defaultValue={data && data.exigencyPanel}
              >
                <option value={null}>Selecionar</option>
                <option
                  value={true}
                  selected={data && data.exigencyPanel == true ? true : false}
                >
                  Habilitado
                </option>
                <option
                  value={false}
                  selected={data && data.exigencyPanel == false ? true : false}
                >
                  Desabilitado
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="nps">Painel NPS</label>
              <select
                className="form-control"
                name="nps"
                onChange={(event) => handleChange(event, FieldTypeObj.Bool)}
                defaultValue={data && data.nps}
              >
                <option value={null}>Selecionar</option>
                <option
                  value={true}
                  selected={data && data.nps == true ? true : false}
                >
                  Habilitado
                </option>
                <option
                  value={false}
                  selected={data && data.nps == false ? true : false}
                >
                  Desabilitado
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          {customerId === undefined ? (
            <div className="col-sm-12">
              <label for="nps">Painel Retenção</label>
              <select
                className="form-control"
                name="retentionPanel"
                onChange={(event) => handleChange(event, FieldTypeObj.Bool)}
                defaultValue={data && data.retentionPanel}
              >
                <option value={null}>Selecionar</option>
                <option
                  value={true}
                  selected={data && data.retentionPanel == true ? true : false}
                >
                  Habilitado
                </option>
                <option
                  value={false}
                  selected={data && data.retentionPanel == false ? true : false}
                >
                  Desabilitado
                </option>
              </select>
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-12">
            <label for="role">Tipo</label>
            <select
              className="form-control"
              name="role"
              onChange={(event) => handleChange(event, FieldTypeObj.Number)}
              defaultValue={data && data.role}
            >
              <option value={null}>Selecionar</option>
              <option value={0} selected={0 === data.role ? true : false}>
                Operador
              </option>
              {customerId === undefined ? (
                <option value={1} selected={1 === data.role ? true : false}>
                  Supervisor
                </option>
              ) : (
                ""
              )}
              <option value={2} selected={2 === data.role ? true : false}>
                Administrador
              </option>
            </select>
          </div>
        </div>
      </div>
    </Modal>
  );
}
