import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const ElaboratorController = {
  Insert : async function (data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `elaborator/insert`,
        data
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Update : async function (data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `elaborator/update`,
        data
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  List : async function (customerId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `elaborator/customer/${customerId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Get : async function (id, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `elaborator/${id}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Delete : async function (id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `elaborator/delete/${id}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
}