import React, { useEffect, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomerExigencies from "@/app/core/layouts/main-components/exigencies/CustomerExigencies";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import Storage from "@/app/core/util/Storage";

function ExigenciesComponent(props) {
  const organCtrl = OrganListController();
  const [show, setShow] = useState(false);
  const [theLogo, setLogo] = useState(Logo);
  const [userData, setUserData] = useState(Storage.getUserData());
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    loadOrgan();
  }, []);

  async function loadOrgan() {
    await organCtrl.SelectById(props.match.params.key, (res) => {
      checkCustomer(res.process);
    });
  }

  function checkCustomer(process) {
    if (userData !== null || show == true) {
      setShow(true);
      return;
    }
    const baseCustomerCode = process.customer.customerCode.replace(/\s/g, "");
    let customerCode = window.prompt(
      "Para acessar essa tela, confirme seu código de cliente",
    );
    if (customerCode === baseCustomerCode) {
      setShow(true);
    } else {
      let customerCode2 = window.prompt(
        "Código de cliente inválido, tente novamente : ",
      );
      if (customerCode2 === baseCustomerCode) {
        setShow(true);
      } else {
        if (customerCode === baseCustomerCode) {
          setShow(true);
        } else {
          let customerCode2 = window.prompt(
            "Código de cliente inválido, tente novamente : ",
          );
          if (customerCode2 === baseCustomerCode) {
            setShow(true);
          } else {
            if (customerCode === baseCustomerCode) {
              setShow(true);
            } else {
              let customerCode2 = window.prompt(
                "Código de cliente inválido, tente novamente : ",
              );
              if (customerCode2 === baseCustomerCode) {
                setShow(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === baseCustomerCode) {
                  setShow(true);
                } else {
                  toast.info(
                    "Você errou o código muitas vezes e será redirecionado ao site",
                  );
                  setTimeout(() => {
                    window.location = "https://hasa.com.br";
                  }, 3900);
                }
              }
            }
          }
        }
      }
    }
  }

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={theLogo} width="150px" />
          </div>
          {show === true ? (
            <div className="col-md-12">
              <CustomerExigencies
                organListId={props.match.params.key}
                reportLogo={(url) => setLogo(url)}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <ToastContainer className="toastr" />
      </div>
    </div>
  );
}

export default ExigenciesComponent;
