import React, { useEffect, useState } from "react";
import ToDoController from "@/app/core/base/controllers/ToDoController";
import TaskModal from "./TaskModal";
import PlanButton from "@/app/core/layouts/main-components/plan/PlanButton";

export default function ElaboratorCard({ reload, customerId, available }) {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    loadTasks()
  }, [customerId])

  function loadTasks() {

  }

  function openItem() {
    if (available) {
      window.location = `#/elaborator?customerId=${customerId}`;
    } else {
    }
  }

  return (
    <div>
      <div
        className="block block-fx-pop bg-white block-roundedMax mb-3 pb-3 ml-1 cursor"
        style={{ height: 210 }}
        onClick={() => openItem()}
      >
        <div className="block-header">
          <div className="block-title">
            <i className="far fa-file-alt fa-2x"></i>
          </div>
          <div className="block-tools">
            <i
              className="si si-question "
              title=" Crie contratos de Abertura, Alteração e Cancelamento rapidamente com o Hasa Pro!"
            />
          </div>
        </div>

        {available ?
          <div className="block-content pt-0">
            {data && data.length == 0 ? (
              <div>
                <h3 className=" mt-0">
                  Elaborador de contratos
                </h3>
              </div>
            ) : (
              <div>
                <h3 className=" mt-0">
                  {data && data.length}{" "}
                  {data && data.length == 1 ? "Tarefa" : "Tarefas"}  em aberto
                </h3>

                <ul>
                  {data && data.map((item, index) => {
                    if (index < 3 && item.description && item.description.length > 0) {
                      return (
                        <li className="text-dark"> {item.description}</li>
                      )
                    }
                  })}
                </ul>
              </div>
            )}
          </div>
          :
          <PlanButton customerId={customerId} name={"Elaborador de contratos"} reload={() => reload()} />
        }
      </div>
      <TaskModal
        show={showModal}
        customerId={customerId}
        reload={() => reload()}
        onHide={() => setShowModal(false) | loadTasks()}
      />

    </div>
  );
}
