import React, { useState } from "react";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import {
  CnpjMask,
  PhoneMask
} from "@/app/core/util/helpers/ContactHelper";
import CompanyModal from "@/app/modules/companies/main/components/CompanyModal";

export default function CompanySearchItem({ data, reload }) {
    const [showModal, setShowModal] = useState(false)
  
    function openCompany(){
        setShowModal(true)
    }

  return (
    <div class="col-md-6">
      <div class="block block-bordered block-rounded block-fx-pop">
        <div className="block-header">
          <div className="block-title">Cliente : {data && data.name}</div>
          {/* <div className="block-tools">
            <div class="font-size-sm text-success mb-1 text-right">
              Cód : {data && data.customerCode}
            </div>
          </div> */}
        </div>
        <div className="block-content">
          <div
            className="block block-bordered block-rounded"
            onClick={() => openCompany()}
            style={{ cursor: "pointer" }}
          >
            <table className="table table-hover">
              <tbody>
                <tr>
                  <td>Filial</td>
                  <td>{data && data.filial === false ? "Não": "Sim"}</td>
                </tr>
                  <tr>
                    <td>CNPJ</td>
                    <td>{data && CnpjMask(data.doc)}</td>
                  </tr>
                <tr>
                  <td>Última atualização</td>
                  <td>{DateTranslate(data.updatedDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    <CompanyModal infos={data} show={showModal} onHide={()=> setShowModal(false) | reload()} />
    </div>
  );
}
