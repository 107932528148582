import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { CnpjMask, CpfMask } from "@/app/core/util/helpers/ContactHelper";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import React, { useEffect, useState } from "react";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { toast } from "react-toastify";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";
import ElaboratorTutorialModal from "./components/ElaboratorTutorialModal";
import { Tab, Tabs } from "react-bootstrap";
import SendFulfillmentContractModal from "./components/SendFulfillmentContractModal";
import NewContractModal from "./components/NewContractModal";
import BudgetCard from "../../customerProfile/main/components/BudgetCard";
import CompaniesCard from "../../customerProfile/main/components/CompaniesCard";
import ResumeTaskCard from "../../customerProfile/main/components/ResumeTaskCard";
import ReminderCard from "../../customerProfile/main/components/ReminderCard";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { ElaboratorController } from "@/app/core/base/controllers/ElaboratorController";
import { ElaboratorHelper } from "@/app/core/util/helpers/ElaboratorHelper";

export default function ElaboratorComponent({ customerId }) {
  const userData = Storage.getUserData();
  const [customer, setCustomer] = useState(null);
  const [data, setData] = useState([]);
  const [waiting, setWaiting] = useState([]);
  const [done, setDone] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [showNewContractModal, setShowNewContractModal] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());

  useEffect(() => {
    if (userData && userData.level != 5) {
      setCustomer(customerId);
      loadCustomer(customerId);
    } else {
      setCustomer(userData.customerId);
      loadCustomer(userData.customerId);
    }
  }, []);

  function loadCustomer(customerId) {
    CustomerSelect(customerId, (res) => {
      setCustomerData(res);
    });
    ElaboratorController.List(customerId, res => {
      for (let s in res) {
        res[s].time = new Date(res[s].createdDate).getTime();
      }
      res.sort(function (a, b) {
        return b.time - a.time;
      });

      setData(res.slice(0,5));
      setWaiting(res.filter(x => x.status !== ElaboratorHelper.Status.Done))
      setDone(res.filter(x => x.status === ElaboratorHelper.Status.Done))
      notifyChange();
    })
  }

  function deleteDoc(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      ElaboratorController.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadCustomer(customer);
      });
    }
  }

  function openDoc(item) {
    // setCompany(item);
    if (userData && userData.level == 5) {
      // setShowCompany(true);
    } else {
      // setShowOrganModal(true);
    }
  }

  function newService(item) {
    setSelected(item);
    setShowFulfillmentModal(true);
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function line(item) {
    return (
      <tr key={item.id}>
        <td>
          {item.name}
        </td>
        <td>
          {ElaboratorHelper.GetType(item.type)}
        </td>
        <td>{DateTranslate(item.updatedDate)}</td>
        <td>
          {ElaboratorHelper.GetStatus(item.status)}
        </td>
        <td>
          <button
            type="button"
            class="btn btn-hero-sm btn-hero-success"
            onClick={() => newService(item)}
          >
            Enviar p/ preenchimento
          </button>
        </td>
        <td>
          <button
            className="btn btn-sm btn-dual ml-2 mr-2"
            title="Editar"
            onClick={() =>
              window.open(`#/drafter/${item.id}`, "_blank")
            }
          >
            <i className="fa fa-fw fa-pencil-alt" />
          </button>
          <button
            className="btn btn-sm btn-dual ml-2 mr-2"
            title="Visualizar"
            onClick={() => openDoc(item)}
          >
            <i class="si si-cloud-download" />
          </button>
          <button
            className="btn btn-sm btn-dual ml-2 mr-2"
            title="Excluir"
            onClick={() => deleteDoc(item)}
          >
            <i class="fas fa-times" />
          </button>
        </td>
      </tr>
    )
  }

  function header(name) {
    return (
      <div className="block-header">
        <div className="block-title">{name}</div>
        <div className="block-tools">
          {userData && userData.level == 5 ? (
            <button
              className="btn btn-outline-info"
              onClick={() => setShowNewContractModal(true)}
            >
              <i className="si si-plus" /> Iniciar contrato
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="content mr-0 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-md-10 mb-3">
          <h2 className="mb-0 ml-3 mt-2">
            <strong className="font-w700">Elaborador de Contratos 📝</strong>
            <br />
          </h2>
          <p className="text-muted ml-3 mb-1 mt-2">
            Desenvolvido para ajudar você a elaborar contratos de Abertura, Alteração e Cancelamento de empresas em tempo recorde!
            <br />Clique em Iniciar Contrato para começar agora e monitore suas elaborações em tempo real no painel abaixo.
          </p>
        </div>
        <div className="col-md-2 mb-3 text-right">
          <button className="btn btn-info" onClick={() => setShowModal(true)}>
            <i className="si si-question" /> Tutorial
          </button>
        </div>


        <div className="col-md-12 mt-4">
          <Tabs defaultActiveKey="resumo">
            <Tab eventKey="resumo" title={data && `(${data.length}) Recentes`}>
              <div className="block block-bordered border-2x">
                {header("Recentes")}
                <div className="block-content">
                  <table className="table table-hover table-borderless">
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Tipo</th>
                        <th>Última atualização</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {change &&
                        data &&
                        data.map((item, index) => line(item))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="waiting" title={waiting && `(${waiting.length}) Aguardando Preenchimento`}>
              <div className="block block-bordered border-2x">
                {header("Aguardando preenchimento")}
                <div className="block-content">
                  <table className="table table-hover table-borderless">
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Tipo</th>
                        <th>Data de início</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {change &&
                        waiting &&
                        waiting.map((item, index) => line(item))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="done" title={done && `(${done.length}) Concluídos`}>
              <div className="block block-bordered border-2x">
                {header("Concluídos")}
                <div className="block-content">
                  <table className="table table-hover table-borderless">
                    <thead>
                      <tr>
                        <th>Empresa</th>
                        <th>Tipo</th>
                        <th>Data de início</th>
                        <th>Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {change &&
                        done &&
                        done.map((item, index) => line(item))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>

        <div className="col-md-3">
          <ResumeTaskCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} reload={() => loadCustomer()} />
        </div>
        <div className="col-md-3">
          <BudgetCard customerId={customerId != undefined ? customerId : userData.customerId} />
        </div>
        <div className="col-md-3">
          <CompaniesCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Regularity, customerData)} reload={() => loadCustomer()} />
        </div>
        <div className="col-md-3">
          <ReminderCard customerId={customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Reminder, customerData)} reload={() => loadCustomer()} />
        </div>
      </div>
      <ElaboratorTutorialModal
        show={showModal}
        infos={selected}
        onHide={() => setShowModal(false)} />

      <SendFulfillmentContractModal
        show={showFulfillmentModal}
        infos={selected}
        customer={customerData}
        onHide={() => setShowFulfillmentModal(false)} />

      <NewContractModal
        show={showNewContractModal}
        infos={selected}
        customerId={customerId != undefined ? customerId : userData.customerId}
        onHide={() => setShowNewContractModal(false) | loadCustomer()} />

    </div>
  );
}
