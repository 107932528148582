import React, { useState, useEffect } from "react";
import CustomModal from "@/app/core/layouts/main/components/CustomModal";
import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";

function UserModal(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function sendWhatsapp() {
    if (data.phone === undefined) {
      toast.info("Usuário não possui um telefone cadastrado");
      return;
    }
    const url =
      "https://api.whatsapp.com/send?phone=" +
      phoneCheck(data.phone) +
      "&text=" +
      "Olá " +
      data.name +
      "! Tudo bem?";
    window.open(url, "_blank");
  }

  function sendEmail() {
    window.open("mailto:" + data.email);
  }

  function phoneCheck(i) {
    let phone = "";
    if (i.Length == 9 || i.Length < 9) {
      phone = "5511" + i;
    } else if (i.Length == 11) {
      phone = "55" + i;
    } else {
      phone = i;
    }
    return phone;
  }

  function userPicture() {
    if (data.picture === undefined || data.picture === null) {
      return "https://simpleicon.com/wp-content/uploads/user1.png";
    } else {
      return data.picture;
    }
  }

  return (
    <CustomModal
      title="Usuário"
      show={props.show}
      onHide={props.onHide}
      size="sm"
    >
      <div class="row">
        <div class="col-sm-12">
          <div>
            <div className="block block-rounded text-center">
              <div className="block-content block-content-full bg-image">
                <img
                  className="img-avatar img-avatar-thumb"
                  src={userPicture()}
                  alt="userPicture"
                />
                <br />
                <span>Conversar via : </span>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Horizontal Success"
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      sendWhatsapp();
                    }}
                  >
                    Whatsapp
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    disabled
                  >
                    Chat
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={() => {
                      sendEmail();
                    }}
                  >
                    E-mail
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="example-masked-time">Nome</label>
              <div className="form-control">
                {data.firstName} {data.lastName}
              </div>
            </div>
            <div class="form-group">
              <label for="example-masked-time">Email</label>
              <div className="form-control">{data.email}</div>
            </div>
            <div class="form-group">
              <label for="example-masked-time">Telefone</label>
              <div className="form-control">{data.phone}</div>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default UserModal;
