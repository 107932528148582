import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { withFormik } from "formik";
import * as Yup from "yup";

function ServicesFormComponent(props) {
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  const {
    touched,
    errors,
    handleSubmit,
    handleChange,
    show,
    onHide,
    submit,
    isLoading,
  } = props;

  useEffect(() => {
    setData(props.data);
    if (data === null || data.id === 0) {
      setId(0);
    } else {
      setId(data.id);
    }

  }, [props.data]);

  return (
    <Modal
      title="Atalho"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label for="example-masked-time">Nome</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={data && data.name}
                onChange={handleChange}
              />
              {errors.name && touched.name && (
                <div style={{ color: "red" }}>{errors.name}</div>
              )}
            </div>
            <div className="form-group">
              <label for="example-masked-time">URL</label>
              <input
                type="text"
                className="form-control"
                name="url"
                value={data && data.url}
                onChange={handleChange}
                placeholder=""
              />
            </div>
            <div className="form-group">
              <label for="department">Departamento</label>
              <select
                className="form-control"
                name="level"
                onChange={handleChange}
              >
                <option value={null}>Selecione</option>
                <option value={1} selected={data && data.level === 1}>
                  Orçamento
                </option>
                <option value={2} selected={data && data.level === 2}>
                  Análise
                </option>
                <option value={3} selected={data && data.level === 3}>
                  Preparação
                </option>
                <option value={4} selected={data && data.level === 4}>
                  Devolução órgão
                </option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

const FolderEditForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ name, url, level }) {
    return {
      name: name || "",
      url: url || "",
      level: parseInt(level) || null,
    };
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    url: Yup.string().required("Endereço de url é obrigatório"),
  }),
  handleSubmit(values, { props }) {
    props.submit({
      ...values,
      id: props.data !== undefined ? props.data.id : 0,
    });
  },
})(ServicesFormComponent);

export default FolderEditForm;
