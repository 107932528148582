import React from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { withFormik } from "formik";
import * as Yup from "yup";

function ResearchFormComponent(props) {
  const {
    touched,
    errors,
    handleSubmit,
    handleChange,
    budgetId,
    show,
    onHide,
    submit,
    isLoading,
  } = props;

  return (
    <Modal
      title="Pesquisa de atendimento"
      show={show}
      isLoading={isLoading}
      onHide={onHide}
      onSave={submit}
      hideButton={true}
      onSubmit={() => handleSubmit()}
    >
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12">
              <h3 className="text-muted">Ajude-nos a melhorar nossa oferta</h3>
            </div>
            {/* <div className="col-sm-12">
              <label>Em uma escala de 0 á 10, como você avalia o nosso atendimento?</label>
              <select
                className="form-control"
                id="answer"
                name="answer"
                onChange={handleChange}
                defaultValue={null}
              >
                <option value="null">Escolha um número</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              {errors.level && touched.level && (
                <div style={{ color: "red" }}>{errors.level}</div>
              )}
            </div> */}
            <div className="col-sm-12">
              <label>Por qual motivo recusou nosso orçamento?</label>
              <textarea
                type="text"
                className="form-control"
                name="observation"
                onChange={handleChange}
                id="observation"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

const ResearchForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ answer, observation }) {
    return {
      answer: parseInt(answer) || 0,
      observation: observation || "",
    };
  },
  validationSchema: Yup.object().shape({
    answer: Yup.string().required("Selecione um número"),
  }),
  handleSubmit(values, { props }) {
    props.submit({
      values,
    });
  },
})(ResearchFormComponent);

export default ResearchForm;
