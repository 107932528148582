import ReportController from "@/app/core/base/controllers/ReportController";
import React, { useEffect, useState } from "react";
import CustomerReportModal from "./Modals/CustomerReportModal";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function CustomerCounter({ startDate, endDate, refresh }) {
  const report = ReportController();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(0);
  const [data, setData] = useState({
    total: 0,
    actives: 0,
    inactives: 0,
    idle: 0,
    blocked: 0,
    pending: 0,
    activesPercentage: 0,
    inactivesPercentage: 0,
    idlePercentage: 0,
    blockedPercentage: 0,
    pendingPercentage: 0,
    partners: 0,
    oportunities: 0,
  });

  const base = CustomerHelper().ReportType;

  let hint = `PARCEIROS - Clientes com 2 ou mais orçamentos aceitos nos últimos 60 Dias corridos

CADASTRADOS - Contatos que fizeram o cadastro na plataforma.

CONVERTIDOS - Cadastrados que efetivaram compra.

ATIVOS - Clientes que aceitaram orçamento no período. 

PARCEIROS - Clientes com 2 ou mais orçamentos aceitos nos últimos 60 Dias corridos.

INATIVOS - Clientes que não aceitaram orçamentos no período consultado.

OCIOSOS - Clientes que não acessaram a plataforma no período consultado.

BLOQUEADOS - Clientes com pendências financeiras ou outras.

PENDENTE - Cadastros incompletos (Faltam dados, e/ou assinatura de Contrato).

OPORTUNIDADES - Cadastrados que não efetivaram compra.

OPORTUNIDADE INICIAL - Cadastrados que não efetivaram compra.

OPORTUNIDADE RECORRÊNCIA - Convertidos que não aceitaram orçamentos nos últimos 60 Dias`;

  useEffect(() => {
    loadCounters();
  }, [refresh]);

  async function loadCounters() {
    setLoading(true)
    await report.Customers(startDate, endDate, (res) => {
      res.activesPercentage = parseInt((res.actives / res.conversions) * 100);
      res.inactivesPercentage = parseInt(
        (res.inactives / res.conversions) * 100,
      );
      res.idlePercentage = parseInt((res.idle / res.conversions) * 100);
      res.blockedPercentage = parseInt((res.blocked / res.conversions) * 100);
      res.pendingPercentage = parseInt((res.pending / res.conversions) * 100);
      res.partnersPercentage = parseInt((res.partners / res.conversions) * 100);
      res.conversionsPercentage = parseInt((res.conversions / res.total) * 100);
      res.oportunitiesPercentage = parseInt(
        (res.oportunities / res.total) * 100,
      );
      res.oportunitiesRessurgencePercentage = parseInt(
        (res.oportunitiesRessurgence / res.conversions) * 100,
      );
      setData(res);
    });
    setLoading(false)
  }

  function openReport(type) {
    setReportType(type);
    setShowModal(true);
  }

  return (
    <div>
      <div className="block block-rounded bg-xinspire">
        <div className="block-header bg-xinspire-dark">
          <div className="block-title">
            <p className="text-white text-uppercase font-size-sm font-w700">
              Clientes
            </p>
          </div>
          <div className="block-tools d-flex">
          {loading === true ? (
          <div className="mt-2 text-center">
            <i className={"fa fa-circle-notch fa-spin text-warning"}></i>
          </div>
        ) : ""}
            <button className="btn btn-dual" title={hint}>
              <i className="far fa-question-circle text-white" />
            </button>
          </div>
        </div>
        <div className="block-content block-content-full">
          <div className="row text-center">
            <div className="col-2" onClick={() => openReport(data.totalList)}>
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.total}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.total}
                </p>
                <p className="text-white-75 mb-0">Cadastrados</p>
              </div>
            </div>
            <div className="col-2" onClick={() => openReport(data.activesList)}>
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.actives}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.activesPercentage}%
                </p>
                <p className="text-white-75 mb-0">Ativos</p>
              </div>
            </div>
            <div
              className="col-2"
              onClick={() => openReport(data.inactivesList)}
            >
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.inactives}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.inactivesPercentage}%
                </p>
                <p className="text-white-75 mb-0">Inativos</p>
              </div>
            </div>
            <div
              className="col-2 border-right border-black-op"
              onClick={() => openReport(data.blockerList)}
            >
              <div className="py-3">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.blocked}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.blockedPercentage}%
                </p>
                <p className="text-white-75 mb-0">Bloqueados</p>
              </div>
            </div>
            <div
              className="col-4"
              onClick={() => openReport(data.oportunitiesList)}
            >
              <div className="py-3">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.oportunities}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.oportunitiesPercentage}%
                </p>
                <p className="text-white-75 mb-0">Oportunidade inicial</p>
              </div>
            </div>
            <div
              className="col-2"
              onClick={() => openReport(data.conversionsList)}
            >
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.conversions}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.conversionsPercentage}%
                </p>
                <p className="text-white-75 mb-0">Convertidos</p>
              </div>
            </div>
            <div className="col-2" onClick={() => openReport(data.partnerList)}>
              <div className="py-3  border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 border-right mx-auto">
                  {data && data.partners}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.partnersPercentage}%
                </p>
                <p className="text-white-75 mb-0">Parceiros</p>
              </div>
            </div>
            <div className="col-2" onClick={() => openReport(data.idleList)}>
              <div className="py-3 border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.idle}{" "}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.idlePercentage}%
                </p>
                <p className="text-white-75 mb-0">Ociosos</p>
              </div>
            </div>
            <div className="col-2" onClick={() => openReport(data.pendingList)}>
              <div className="py-3  border-right border-black-op">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.pending}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.pendingPercentage}%
                </p>
                <p className="text-white-75 mb-0">Pendentes</p>
              </div>
            </div>
            <div
              className="col-4"
              onClick={() => openReport(data.oportunitiesRessurgenceList)}
            >
              <div className="py-3">
                <div className="text-white item item-circle cursor bg-black-25 mx-auto">
                  {data && data.oportunitiesRessurgence}
                </div>
                <p className="text-white font-size-h3 font-w300 mt-3 mb-0">
                  {data && data.oportunitiesRessurgencePercentage}%
                </p>
                <p className="text-white-75 mb-0">Oportunidade recorrência</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomerReportModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
        infos={reportType}
      />
    </div>
  );
}
