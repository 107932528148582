export const ReminderHelper = {
  ChargeTypeList: [
    {
      name: "Solicitar Documento - Solicite o envio de documentos de forma simplificada",
      value: 0,
    },
    {
      name: "Cobrança de Valores - Solicite o anexo de comprovantes para reduzir inadimplências",
      value: 1,
    },
    {
      name: "Lembrete Simples – Crie lembretes simples para facilitar seu dia a dia",
      value: 2,
    },
  ],
  SimpleChargeTypeList: [
    {
      name: "Solicitação de Documento",
      value: 0,
    },
    {
      name: "Cobrança de Valores",
      value: 1,
    },
    {
      name: "Lembrete Simples",
      value: 2,
    },
  ],
  ChargeType: {
    Doc: 0,
    Money: 1,
    Reminder: 2,
  },
  StatusList: [
    { name: "Em andamento", value: 0 },
    { name: "Cancelado", value: 1 },
    { name: "Concluído", value: 2 },
    { name: "Excluído", value: 3 },
  ],
  Status: {
    Ongoing: 0,
    Canceled: 1,
    Done: 2,
    Deleted: 3,
  },
  ForWho: {
    Other:0,
    Me: 1,
  }
};
