import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const PartnerController = {
  Insert : async function (data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `partner/insert`,
        data
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Update : async function (data, response, error) {
    await BaseService(
      {
        method: "POST",
        url: `partner/update`,
        data
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  List : async function (elaboratorId, response, error) {
    await BaseService(
      {
        method: "GET",
        url: `partner/elaborator/${elaboratorId}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
  Delete : async function (id, response, error) {
    await BaseService(
      {
        method: "DELETE",
        url: `partner/delete/${id}`,
      },
      (res) => {
        return response(res);
      },
      (err) =>
        error === undefined ? toast.error(HandleErrors(err)) : error(err),
    );
  },
}