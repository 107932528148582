import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import Logo from "@/assets/img/logo.jpg";
import QuizController from "@/app/core/base/controllers/QuizController";
import DynamicField from "@/app/core/util/helpers/DynamicFieldHelper";
import Storage from "@/app/core/util/Storage";
import { FeedbackInsert } from "@/app/core/base/controllers/FeedbackController";
import { SendEmailFeedbackMail } from "@/app/core/base/controllers/ContactController";

export default function Feedback(props) {
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const userData = Storage.getUserData();
  const quizCtrl = QuizController();

  useEffect(() => {
    loadFeedback();
  }, []);

  async function loadFeedback() {
    await quizCtrl.Get(props.match.params.key, (res) => {
      setRaw(res);
      setData(res.structure);
    });
  }

  async function saveResponse() {
    await FeedbackInsert(data, async (res) => {
      await SendEmailFeedbackMail(data, userData);
      toast.success(
        "Obrigado pelo feedback! Você será redirecionado ao sistema",
      );

      setTimeout(() => {
        window.location = "#/";
      }, 2580);
    });
  }

  function handleChange(evt, item, index) {
    let oldData = data;

    oldData[index].value = evt.target.value + "";
    oldData[index].question = item.description;
    oldData[index].quizId = parseInt(props.match.params.key);

    if (
      userData !== null &&
      userData.customerId !== 0 &&
      userData.customerId !== undefined
    ) {
      oldData[index].customerId = userData.customerId;
    }


    setData(oldData);
  }

  return (
    <div id="page-container">
      <main id="main-container">
        <div
          className="bg-image"
          // data-vide-bg="assets/media/videos/city_night"
          style={{
            backgroundImage: "url('assets/media/photos/photo12@2x.jpg')",
          }}
        >
          <div className="row no-gutters bg-gd-sun-op">
            <div className="hero-static col-md-6 d-flex align-items-center bg-white">
              <div className="p-3 w-100">
                <div className="mb-3 text-center">
                  <a
                    className="link-fx font-w700 font-size-h1"
                    href="index.html"
                  >
                    <img src={Logo} style={{ maxWidth: "150px" }} />
                  </a>
                  <p className="text-uppercase font-w700 font-size-sm text-muted">
                    Feedback
                  </p>
                  <h5>
                    Seja o mais sincero (a) possível na sua mensagem,
                    <br />
                    nosso time agradece de verdade!
                  </h5>
                </div>

                <div className="row no-gutters justify-content-center">
                  <div className="col-sm-8 col-xl-6">
                    {data &&
                      data.map((item, index) => (
                        <DynamicField
                          item={item}
                          data={item.feedbackValues}
                          handleChange={(evt) => handleChange(evt, item, index)}
                        />
                      ))}
                  </div>
                </div>
                <div
                  className="d-flex justify-content-around mb-3"
                  style={{ marginTop: "2em" }}
                >
                  <button
                    onClick={() => saveResponse()}
                    className="btn btn-hero-warning"
                  >
                    Enviar resposta
                  </button>
                </div>
              </div>
            </div>

            <div className="hero-static col-md-6 d-none d-md-flex align-items-md-center justify-content-md-center text-md-center">
              <div className="p-3">
                <p className="display-4 font-w600 text-white mb-3">
                  Que bom que está aqui!
                  <br />
                  Contamos com você para nos ajudar a aprimorar nossos serviços
                </p>
                <p className="font-size-lg font-w600 text-white-75 mb-0">
                  Copyright <b>Hasa</b> &copy;{" "}
                  <span className="js-year-copy">
                    {new Date().getFullYear()}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </div>
  );
}
