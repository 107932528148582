import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Dropdown } from "react-bootstrap";
import Storage from "@/app/core/util/Storage";
import React, { useEffect, useState } from "react";
import ReminderModal from "./components/ReminderModal";
import {
  DateTranslate,
  DateTranslateToInput,
} from "@/app/core/util/DateTranslater";
import ReminderStatsModal from "./components/ReminderStatsModal";
import { CnpjMask } from "@/app/core/util/helpers/ContactHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";
import { ReminderController } from "@/app/core/base/controllers/ReminderController";
import { Panel, Tab, Tabs } from "@bumaga/tabs";
import { ResumeCard } from "../../dashboard/components/panels/administratorElements/ResumeCard";
import ElaboratorCard from "../../customerProfile/main/components/ElaboratorCard";
import BudgetCard from "../../customerProfile/main/components/BudgetCard";
import CompaniesCard from "../../customerProfile/main/components/CompaniesCard";
import ResumeTaskCard from "../../customerProfile/main/components/ResumeTaskCard";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import CustomerHelper from "@/app/core/util/helpers/CustomerHelper";

export default function ReminderComponent(props) {
  const userData = Storage.getUserData();
  const [customerId, setCustomerId] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [query, setQuery] = useState("");
  const [customerData, setCustomerData] = useState({});
  const [data, setData] = useState([]);
  const [raw, setRaw] = useState([]);
  const [done, setDone] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const date = new Date();
  const [change, setChange] = useState(date.getTime());
  const [value, onChange] = useState(new Date());

  useEffect(() => {
    if (userData && userData.level != 5) {
      const params = new URLSearchParams(props.location.search);
      let baseCustomerId = params.get("customerId");
      setCustomerId(baseCustomerId);
      setCustomer(baseCustomerId);
      getReminders(baseCustomerId);
      loadCustomer(baseCustomerId);
    } else {
      setCustomer(userData.customerId);
      getReminders(userData.customerId);
      loadCustomer(userData.customerId);
    }
  }, []);

  useEffect(() => {
    processFilter();
  }, [query]);
  
  function loadCustomer(customerId) {
    CustomerSelect(customerId, (res) => {
      setCustomerData(res);
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function getReminders(id) {
    ReminderController.List(id, (res) => {
      for (let s in res) {
        res[s].time = new Date(res[s].createdDate).getTime();
        if (res[s].sendDate != "0001-01-01T00:00:00+00:00") {
          res[s].sendDate = res[s].sendDate.slice(0, 16);
        }
      }
      res.sort(function (a, b) {
        return b.time - a.time;
      });
      setData(res.filter((x) => x.status == ReminderHelper.Status.Ongoing));
      setDone(res.filter((x) => x.status != ReminderHelper.Status.Ongoing && x.status != ReminderHelper.Status.Deleted));
      setDeleted(res.filter((x) => x.status == ReminderHelper.Status.Deleted));
      setRaw(res);
    });
  }
  
  function processFilter(){
    let base = [];
    if(query.length > 0){
      base = autocomplete()
    }else{
      base = raw;
    }
    
    setData(base.filter((x) => x.status == ReminderHelper.Status.Ongoing));
    setDone(base.filter((x) => x.status != ReminderHelper.Status.Ongoing && x.status != ReminderHelper.Status.Deleted));
    setDeleted(base.filter((x) => x.status == ReminderHelper.Status.Deleted));
  }

  function autocomplete(){
    let newResults = [];
    const inputValue = query.trim().toLowerCase();

    for (let item of raw) {

      const name = item.companyName?.toLowerCase();
      const title = item.title?.toLowerCase();
      const descrition = item.descrition?.toLowerCase();
      if (
        (name && name.includes(inputValue)) || 
        (title && title.includes(inputValue)) || 
        (descrition && descrition.includes(inputValue))
    ) {
        newResults.push(item);
      }
    }
    return newResults;
  }

  function checkOrUncheck(evt, index) {
    let old = data;
    old[index].checked = evt.target.checked;
    setData(old);
    notifyChange();
  }

  function deleteReminder(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      ReminderController.Update({ ...item, status: ReminderHelper.Status.Deleted }, (res) => {
        getReminders(customer);
      });
    }
  }

  function deleteList() {
    if (window.confirm(Message.DeleteConfirm)) {
      for (let s in data) {
        if (data[s].checked) {
          ReminderController.Update({ ...data[s], status: ReminderHelper.Status.Deleted }, (res) => {
            getReminders(customer);
          });
        }
      }
    }
  }

  function editReminder(item) {
    setShowModal(true);
    setSelected(item);
  }

  function openStats(item) {
    setShowStatsModal(true);
    setSelected(item);
  }

  function dulpicateReminder(item) {
    delete item.id;

    item.status = ReminderHelper.Status.Ongoing;
    setShowModal(true);
    setSelected(item);
  }

  function openReminder(item) {
    window.open(`#/charge/${item.id}`, "_blank");
  }

  function openDate(item) {
    const contacts = [
      {
        name: userData.firstName,
        email: userData.email,
        phone: userData.phone,
      },
    ];
    setSelected({
      type: ReminderHelper.ChargeType.Reminder,
      sendDate: DateTranslateToInput(item),
      contacts,
      frequency: 1,
    });
    setShowModal(true);
  }

  function Line(item, index) {
    return (
      <div
        className={`block block-bordered border-2x block-rounded ${item.status == ReminderHelper.Status.Done ? "border-success" : item.status == ReminderHelper.Status.Canceled || item.status == ReminderHelper.Status.Deleted ? "border-danger" : ""}`}
      >
        <div className="block-header mb-0">
          <div className="block-title">
            {item.type != ReminderHelper.ChargeType.Reminder && item.status != ReminderHelper.Status.Done && item.status != ReminderHelper.Status.Canceled && item.status != ReminderHelper.Status.Deleted ? (
              <div className="custom-control custom-checkbox custom-checkbox-rounded-circle custom-control-lg custom-control-success mb-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`check-${item.id}`}
                  name={`check-${item.id}`}
                  onChange={(evt) => checkOrUncheck(evt, index)}
                />
                <label
                  className="custom-control-label"
                  for={`check-${item.id}`}
                >
                  Titulo: {item.title} <br />
                  {item && item.companyName ? (
                    <small>
                      Empresa: {item && item.companyName} - Tipo: {item && ReminderHelper.SimpleChargeTypeList[item.type].name}
                    </small>
                  ) : (
                    <small>
                      Contato:{" "}
                      {item &&
                        item.contacts &&
                        item.contacts.length > 0 &&
                        item.contacts[0].name}
                    </small>
                  )}
                </label>
              </div>
            ) : (
              <label
                className="">
                Lembrete: {item.title}
              </label>
            )}
          </div>
          <div className="block-tools text-center">
            {item && item.status != ReminderHelper.Status.Done ? (
              <span>
                Frequencia de lembrete
                {item.frequency == 0 || item.frequency == 1 ? (
                  <span className="ml-2 badge badge-success">
                    Disparo único
                  </span>
                ) : (
                  <span className="ml-2 badge badge-success">
                    {item.frequency}x{item.frequency}
                  </span>
                )}
              </span>
            ) : (
              ""
            )}
            {item && item.status == ReminderHelper.Status.Done ? (
              <button
                className="btn btn-dual text-primary ml-2"
                onClick={() => openStats(item)}
              >
                <i className="far fa-eye" /> Ver resposta
              </button>
            ) : (
              <div className="d-flex align-items-center justify-content-between">
                {item &&
                  (item.status == ReminderHelper.Status.Canceled || item.status == ReminderHelper.Status.Deleted) ? (
                  <button
                    className="btn btn-dual text-primary ml-2"
                    onClick={() => dulpicateReminder(item)}
                  >
                    <i className="far fa-copy" /> Repetir envio
                  </button>
                ) : (
                  <button
                    className="btn btn-dual text-primary ml-2"
                    onClick={() => editReminder(item)}
                  >
                    <i className="far fa-edit" />
                  </button>
                )}
                {item.status != ReminderHelper.Status.Deleted ?
                  <button
                    className="btn btn-dual text-danger ml-2"
                    onClick={() => deleteReminder(item)}
                  >
                    <i className="si si-close" />
                  </button>
                  : ""}
                <Dropdown>
                  <Dropdown.Toggle variant="light">
                    <i className="fa fa-ellipsis-v" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <button
                      className="btn btn-dual text-primary ml-2"
                      onClick={() => openReminder(item)}
                    >
                      <i className="far fa-eye mr-2" /> Ver como
                      contato
                    </button>
                    <button
                      className="btn btn-dual text-primary ml-2"
                      onClick={() => openStats(item)}
                    >
                      <i className="far fa-chart-bar mr-2" />{" "}
                      Envios
                    </button>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        <hr className="mt-0" />
        <div className="ml-3 mr-3 mb-2">
          <div className="row">
            <div className="col-6">
              {/* Último envio: 00/00/0000 */}
              {item && item.sends.length == 0 && item.status != ReminderHelper.Status.Deleted ? (
                <span>
                  <i className="far fa-clock mr-2" />
                  {item.type ==
                    ReminderHelper.ChargeType.Reminder ||
                    item.sendDate != "0001-01-01T00:00:00+00:00"
                    ? `O lembrete será enviado em ${DateTranslate(item.sendDate)}`
                    : "Em até 15 minutos será feito o primeiro disparo"}
                </span>
              ) : (
                ""
              )}
              {item &&
                item.status == ReminderHelper.Status.Canceled ? (
                <span>
                  <i className="fa fa-exclamation-circle mr-2 ml-2" />{" "}
                  O contato não respondeu
                </span>
              ) : (
                ""
              )}
              {item && item.sends && item.sends.length > 0 && item.sends.map((reminder, index) => {
                if (item.sends.length == (index + 1)) {
                  return (
                    <span>
                      <i className="si si-clock text-success mr-2 ml-2" />{" "}
                      Lembrete enviado em{" "}
                      {DateTranslate(reminder.updatedDate)}
                    </span>
                  )
                }
              })}
              {item && item.status == ReminderHelper.Status.Done ? (
                <span>
                  <i className="si si-check text-success mr-2 ml-2" />{" "}
                  Lembrete finalizado em{" "}
                  {DateTranslate(item.updatedDate)}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-6 text-right">
              <i className="far fa-calendar-plus mr-2" />
              Criado em: {DateTranslate(item.createdDate)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="content mr-0 pl-0 pr-0">
      <div className="row mr-0 pr-0">
        <div className="col-8 mb-3">
          <h2 className="mb-0 ml-3 mt-2">
            <strong className="font-w700">Lembretes 📅</strong>
          </h2>
          <div className="text-sm-left ml-3">
            Facilite a gestão de documentos e cobre seus clientes com praticidade!
            <br />
            <br />
            <span className="font-w700">•Eficiência Financeira:</span> Reduza o risco de inadimplência com lembretes automáticos.
            <br />
            <span className="font-w700">•Eficiência Operacional:</span> Automatize o envio de cobranças de documentos e simplifique o processo.


            {/* <br />
            <br />• <b className="font-w700">Eficiência Financeira</b> – Crie
            lembretes de cobrança para reduzir riscos de inadimplências;
            <br />• <b className="font-w700">Eficiência Operacional</b> – Com
            nossa automação a cobrança para envio de documentos nunca foi tão
            simples */}
          </div>
        </div>
        <div className="col-4 text-right mb-3">
          {userData && userData.level == 5 ? (
            <button
              className="btn btn-outline-info"
              onClick={() => setShowModal(true)}
            >
              <i className="si si-plus" /> Lembrete
            </button>
          ) : (
            ""
          )}
          <button
            className="btn btn-sm btn-outline-danger"
            onClick={() => deleteList()}
          >
            <i className="si si-close" /> (
            {data && data.filter((x) => x.checked == true).length}) Excluir
            Lembretes
          </button>
        </div>
        <div className="col-md-8">
          <Tabs>
            <div className="bg-gray mb-3">
              <Tab>
                <button className="btn btn-dual">
                  ({data && data.length}) Aguardando envio
                </button>
              </Tab>
              <Tab>
                <button className="btn btn-dual">
                  ({done && done.filter(x => x.status == ReminderHelper.Status.Done).length}) Respondidos 
                </button>
              </Tab>
              <Tab>
                <button className="btn btn-dual">
                  ({deleted && deleted.length}) Arquivados 
                </button>
              </Tab>
            </div>
            <div className="input-group mb-2">
                <input
                  type="text"
                  placeholder={`Filtrar...`}
                  className="form-control form-control-alt"
                  value={query}
                  onChange={(evt) => setQuery(evt.target.value)}
                />
                <div className="input-group-append">
                  <span className="input-group-text input-group-text-alt">
                    <i className="fa fa-search" onClick={() => null}></i>
                  </span>
                </div>
              </div>
            <Panel>
              {change &&
                data &&
                data.map((item, index) => { return Line(item, index) })}
              
              {data && data.length == 0 ? (
                <div className="text-center mt-5">
                  <h4 className="font-w700 text-dark">
                    Você não tem nenhum lembrete em andamento
                  </h4>
                  <p className="font-w700 text-dark">
                    Adicione o seu primeiro lembrete para começar
                  </p>
                  <button
                    className="btn btn-success"
                    onClick={() => setShowModal(true)}
                  >
                    Adicionar Lembrete
                  </button>
                  <hr style={{ width: 120 }} />
                </div>
              ) : (
                ""
              )}
            </Panel>
            <Panel>
              {change &&
                done &&
                done.map((item, index) => {
                  if (item.status == ReminderHelper.Status.Done) {
                    return Line(item, index)
                  }
                })}
              {done && done.length == 0 ? (
                <div className="text-center mt-5">
                  <h4 className="font-w700 text-dark">
                    Você não tem nenhum lembrete concluído
                  </h4>
                  <button
                    className="btn btn-success"
                    onClick={() => setShowModal(true)}
                  >
                    Adicionar lembrete
                  </button>
                  <hr style={{ width: 120 }} />
                </div>
              ) : (
                ""
              )}
            </Panel>
            <Panel>
              <p>
                Observação: Lembretes finalizados serão excluídos
                automaticamente após uma semana
              </p>
              {change &&
                deleted &&
                deleted.map((item, index) => {
                  return Line(item, index)
                })}
              {deleted && deleted.length == 0 ? (
                <div className="text-center mt-5">
                  <h4 className="font-w700 text-dark">
                    Você não tem nenhum lembrete concluído
                  </h4>
                  <button
                    className="btn btn-success"
                    onClick={() => setShowModal(true)}
                  >
                    Adicionar lembrete
                  </button>
                  <hr style={{ width: 120 }} />
                </div>
              ) : (
                ""
              )}
            </Panel>
          </Tabs>
        </div>
        <div className="col-4">
          <div className="mt-2">
            <ResumeTaskCard customerId={customerId && customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Tasks, customerData)} reload={() => loadCustomer()} />
            <ElaboratorCard customerId={customerId && customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Elaborator, customerData)} reload={() => null} />
            <BudgetCard customerId={customerId && customerId != undefined ? customerId : userData.customerId} />
            <CompaniesCard customerId={customerId && customerId != undefined ? customerId : userData.customerId} available={change && data && CustomerHelper().CheckAvailability(CustomerHelper().ProComponents.Regularity, customerData)} reload={() => null} />
          </div>
        </div>
      </div>
      <ReminderModal
        customerId={customerId && customerId != undefined ? customerId : userData.customerId}
        infos={selected}
        show={showModal}
        onHide={() =>
          setShowModal(false) | getReminders(customer) | setSelected({})
        }
      />
      <ReminderStatsModal
        infos={selected}
        show={showStatsModal}
        onHide={() =>
          setShowStatsModal(false) | getReminders(customer) | setSelected({})
        }
      />
    </div>
  );
}
