import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";
// import ReactToExcel from "react-html-table-to-excel";
import { DateTranslate, ProcessDates } from "@/app/core/util/DateTranslater";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import { EvaluationListByDate } from "@/app/core/base/controllers/EvaluationController";
import { Tabs, Tab, Panel } from "@bumaga/tabs";

export default function ReviewReportModal({
  showModal,
  onHide,
  isBudget,
  startDate,
  endDate,
  change
}) {
  const [data, setData] = useState([]);
  const [budget, setBudget] = useState([]);
  const organHelper = OrganListHelper();

  useEffect(() => {
    if (showModal) {
      loadEvaluations();
    }
  }, [showModal, change]);

  async function loadEvaluations() {
    EvaluationListByDate(startDate, endDate, (res) => {
      let base = [];
      let baseBudget = [];
      for (let s in res) {
        res[s].processName =
          res[s].process != undefined ? res[s].process.processName : "S.I.";
        res[s].organName =
          res[s].organList != undefined ? res[s].organList.organ.name : "S.I.";
        if (res[s].organListId != undefined) {
          base.push(res[s]);
        } else {
          res[s].endBudgetDate = DateTranslate(res[s].process.endBudgetDate);
          baseBudget.push(res[s]);
        }
      }
      setData(ProcessDates(base));
      setBudget(ProcessDates(baseBudget));
    });
  }

  return (
    <Modal
      title={isBudget == false ? "NPS" : "Feedback orçamentos recusados"}
      show={showModal}
      onHide={onHide}
    >
      {isBudget == false ? (
        <div>
          <div className="block-header">
            <div className="block-title">{data && data.length + " NPS"}</div>
            <div className="block-tools">
              {/* <ReactToExcel
                                className="btn btn-info mt-3"
                                table="done"
                                filename={`Relatorio - NPS periodo: ${startDate} - ${endDate}`}
                                sheet="sheet 1"
                                buttonText="Exportar"
                            /> */}
            </div>
          </div>

          <Table
            title={data && data.length + " NPS registrados"}
            tableId="done"
            data={data}
            hideHeader={true}
            columns={organHelper.ReviewCols}
            hide={false}
            refreshData={() => null}
            setFilter={(filter) => Functions.ProcessFilter(filter, data)}
          />
        </div>
      ) : (
        <div>
          <div className="block-header">
            <div className="block-title">
              {budget && budget.length + " Feedbacks"}
            </div>
            <div className="block-tools">
              {/* <ReactToExcel
                                className="btn btn-info mt-3"
                                table="done"
                                filename={`Relatorio - Feedback recusados periodo: ${startDate} - ${endDate}`}
                                sheet="sheet 1"
                                buttonText="Exportar"
                            /> */}
            </div>
          </div>

          <Table
            title={budget && budget.length + " Feedback recusados"}
            tableId="done"
            data={budget}
            hideHeader={true}
            columns={organHelper.ReviewBudgetCols}
            hide={false}
            refreshData={() => null}
            setFilter={(filter) => Functions.ProcessFilter(filter, budget)}
          />
        </div>
      )}
    </Modal>
  );
}
