import React, { useEffect, useState } from "react";
import BaseService from "@/app/core/base/BaseService";
import Logo from "@/assets/img/logo.jpg";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CompleteRegister from "./CompleteRegister";
import "react-toastify/dist/ReactToastify.css";

function ConfirmService(props) {
  const [data, setData] = useState({});
  const [kickOut, setKickOut] = useState(false);
  const [redirectOut, setRedirectOut] = useState(false);
  const status = ["", "Solicitar Contato", "Recusar", "Aceitar"];
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    BaseService(
      {
        method: "GET",
        url: "prospect-info?prospectId=" + props.match.params.key + "&",
      },
      (res) => {
        setData(res);
      },
      (err) => {
        setKickOut(true);
      },
    );
  }, []);

  function toggleModal(data) {
    setShowModal(!showModal);
  }

  function postSearch(data) {
    BaseService(
      {
        method: "POST",
        url: "evaluation-register",
        data: {
          ...data.values,
          budgetId: props.match.params.key,
        },
      },
      (res) => {
        toast.info("Salvo com sucesso: ");
        setTimeout(() => {
          setRedirectOut(true);
        }, 500);
      },
      (err) => {
        toast.error("Ops! Ocorreu um erro: " + JSON.stringify(err));
      },
    );
  }

  function onTapButton(recvStatus) {
    if (
      window.confirm(
        `Tem certeza que deseja ${status[recvStatus]} este pedido?`,
      )
    ) {
      BaseService(
        {
          method: "POST",
          url: "prospect-response",
          data: {
            id: props.match.params.key,
            status: recvStatus,
          },
        },
        (res) => {
          toast.success("Realizado com sucesso!");
          setTimeout(() => {
            if (recvStatus === 3) {
              setShowModal(true);
            } else {
              setRedirectOut(true);
            }
          }, 450);
        },
        (err) => {
          toast.error("Ops! Ocorreu um erro: " + JSON.stringify(err));
        },
      );
    } else {
      toast.info("Cancelado");
    }
  }

  if (kickOut) {
    //return <Redirect to="/error/404" />;
  }

  if (redirectOut) {
    //return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <div
        className="container"
        style={{ maxWidth: "1000px", margin: "0 auto", marginTop: "3em" }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={Logo} width="150px" />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #efefef",
            backgroundColor: "white",
            padding: "2em",
            width: "100%",
          }}
        >
          <div className="row" style={{ marginBottom: "1em" }}>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h5>Responsável pelo processo</h5>
            </div>
          </div>
          <div className="row" style={{ marginBottom: "1em" }}>
            <div className="col-lg-6 col-md-6 col-sm-6">Processo</div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {data.budget && data.budget.name}
            </div>
          </div>
          <div className="row" style={{ marginBottom: "1em" }}>
            <div className="col-lg-6 col-md-6 col-sm-6">Serviço</div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {data.service && data.service.name}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">Observações</div>
            <div className="col-lg-6 col-md-6 col-sm-6">
              {data.budget && data.budget.observation}
            </div>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ marginTop: "2em" }}
          >
            <button onClick={() => onTapButton(4)} className="btn btn-primary">
              Solicitar Contato
            </button>
            <button onClick={() => onTapButton(3)} className="btn btn-danger">
              Recusar
            </button>
            <button onClick={() => onTapButton(2)} className="btn btn-success">
              Aceitar
            </button>
          </div>
        </div>
      </div>
      <ToastContainer className="toastr" />
      <CompleteRegister
        show={showModal}
        isLoading={modalLoading}
        onHide={() => setShowModal(!showModal)}
        submit={(data) => postSearch(data)}
      />
    </div>
  );
}

export default ConfirmService;
