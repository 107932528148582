import React, { useEffect, useState } from "react";
import Storage from "@/app/core/util/Storage";
import ProcessController from "@/app/core/base/controllers/ProcessController";
import { OrganStatus } from "@/app/core/util/helpers/OrganListHelper";
import { BudgetStatus } from "@/app/core/util/helpers/BudgetHelper";
import JobsController from "@/app/core/base/controllers/JobsController";
import { MoneyMask, ToFixed } from "@/app/core/util/helpers/MoneyHelper";
import UserReportModal from "@/app/core/layouts/main-components/user/UserReportModal";
import UserAccountController from "@/app/core/base/controllers/UserAccountController";
import WithdrawModal from "@/app/core/layouts/main-components/user/WithdrawModal";
import {
  ContractType,
  PreparationStep,
} from "@/app/core/util/helpers/UserHelper";
import ReportController from "@/app/core/base/controllers/ReportController";

export default function Productivity({ userData, hide }) {
  const date = new Date();
  const [report, setReport] = useState({
    sends: 0,
    accepted: 0,
    waiting: 0,
    refused: 0,
    acuracity: 0,
  });
  const [total, setTotal] = useState(0);
  const [show, setShow] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth() + 1)
      .toISOString()
      .substring(0, 10),
  );
  const processCtrl = ProcessController();
  const jobs = JobsController();
  const userCashFlow = UserAccountController();
  const user = Storage.getUserData();

  useEffect(() => {
    loadUserData();
    loadUserRemuneration();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  async function loadUserRemuneration() {
    await userCashFlow.Report(userData.id, (res) => setTotal(res.value));
  }

  async function loadUserData() {
    if (userData.level != 1) {
      await processCtrl.UserReport(
        userData.id,
        startDate,
        endDate,
        async (res) => {
          let done = 0,
            deferred = 0,
            accuracity = 0,
            ongoing = 0,
            error = 0;

          if (userData.level >= 2) {
            for (let s in res) {
              let item = res[s];

              if (item.stage > userData.level) {
                done++;
              }
              if (
                (item.status === OrganStatus.Deferred ||
                  item.status === OrganStatus.Done) &&
                item.returned !== true
              ) {
                deferred++;
              }
              if (item.returned === true) {
                error++;
              }

              if (
                item.status !== OrganStatus.Deferred &&
                item.status !== OrganStatus.Done &&
                item.returned !== true &&
                item.stage > userData.level
              ) {
                ongoing++;
              } else if (
                item.status !== OrganStatus.Deferred &&
                item.status !== OrganStatus.Done &&
                item.returned !== true &&
                item.stage === 3
              ) {
                if (item.preparationStage === PreparationStep.Review) {
                  ongoing++;
                }
              }
            }

            await jobs.List(
              userData.id,
              `${startDate}T00:00:00`,
              `${endDate}T23:59:59`,
              (response) => {
                const closeRight = response.filter(
                  (x) => x.right === true,
                ).length;

                const closeWrong = response.filter(
                  (x) => x.right === false,
                ).length;

                const indefinidos = response.filter(
                  (x) => x.right === false && x.organList == undefined,
                ).length;
                const emConferencia = response.filter(
                  (x) =>
                    x.right === false &&
                    x.organList != undefined &&
                    x.organList.preparationStage > 0 &&
                    x.organList.stage == 3,
                ).length;
                const deferidos = response.filter(
                  (x) =>
                    x.right === false &&
                    x.organList != undefined &&
                    x.organList.preparationStage > 0 &&
                    (x.organList.status == 4 || x.organList.status == 6),
                ).length;
                const emAndamento =
                  closeWrong - (indefinidos + emConferencia + deferidos);

                // accuracity = parseInt((deferred / (closeRight + indefinidos + emConferencia + deferidos)) * 100);

                const newDone =
                  closeRight +
                  indefinidos +
                  emConferencia +
                  deferidos +
                  closeWrong;

                let remuneration = 0;

                for (let s in response) {
                  let item = response[s];
                  if (
                    userData &&
                    userData.contractType === 1 &&
                    item.remuneration !== undefined
                  ) {
                    if (item.right === true) {
                      remuneration +=
                        item.remuneration.basePrice +
                        item.remuneration.priceRight;
                    } else {
                      remuneration += item.remuneration.basePrice;
                    }
                  }
                }

                const base = response.filter(
                  (x) =>
                    x.organList !== undefined &&
                    x.organList.preparationStage > 0 &&
                    x.organList.stage === 3,
                );
                const error = response.filter((x) => x.organList == undefined);
                let count = 0;
                const anotherBase = [];

                for (let f in base) {
                  let exist = response.filter(
                    (x) =>
                      x.organList != undefined &&
                      x.organListId == base[f].organListId,
                  );
                  if (exist.length > 0) {
                    count++;
                    anotherBase.push([...anotherBase, ...exist]);
                  }
                }

                if (userData && userData.level == 3) {
                  const baseReport = {
                    done: newDone,
                    deferred,
                    error: closeWrong.length,
                    ongoing,
                    revenue: remuneration,
                    startDate,
                    endDate,
                    userId: userData.id,
                    accuracity: ToFixed(
                      (deferred / (newDone - ongoing)) * 100,
                      1,
                    ),
                  };
                  setReport(baseReport);
                } else {
                  const baseReport = {
                    done: response.length,
                    deferred,
                    error: closeWrong.length,
                    ongoing,
                    revenue: remuneration,
                    startDate,
                    endDate,
                    accuracity: ToFixed(
                      (deferred / (response.length - ongoing)) * 100,
                      1,
                    ),
                  };
                  setReport(baseReport);
                }
              },
              (error) =>{});
          } else {
            let done = 0,
              deferred = 0,
              accuracity = 0,
              ongoing = 0,
              error = 0;

            let countDone = res.filter(
              (x) =>
                x.budgetStatus === BudgetStatus.Acepted ||
                x.budgetStatus === BudgetStatus.Refused,
            );

            let accepted = res.filter(
              (x) => x.budgetStatus === BudgetStatus.Acepted,
            );

            done = countDone.length;
            deferred = accepted.length;
            ongoing = res.filter(
              (x) => x.budgetStatus === BudgetStatus.WaitingApproval,
            ).length;
            accuracity = parseInt((deferred / done) * 100);

            setReport({
              done,
              deferred,
              accuracity,
              error,
              ongoing,
              revenue: 0,
            });
          }
        },
      );
    } else {
      await ReportController().BudgetReport(
        userData.id,
        startDate,
        endDate,
        (res) => {
          if (res) {
            res.acuracity = (res.accepted / (res.sends - res.waiting)) * 100;
            setReport(res);
          }
        },
      );
    }
  }

  function getUserPicture() {
    if (userData.avatarImage === "" || userData.avatarImage === undefined) {
      return `${process.env.PUBLIC_URL}/assets/media/avatars/avatar8.jpg`;
    } else {
      return userData.avatarImage.url;
    }
  }

  function goProfile() {
    window.location = "#/profile";
  }

  function openReport() {
    setShowModal(true);
  }

  return (
    <div className="text-center">
      {hide !== true ? (
        <div className="block-content block-content-full bg-black-50 ribbon ribbon-dark">
          <div
            className="ribbon-box"
            onClick={() => {
              goProfile();
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-user mr-1"></i> Perfil
          </div>

          <img
            className="img-avatar img-avatar-thumb img-avatar-rounded"
            src={getUserPicture()}
            id="currentUserHome"
            alt="profile"
          />
        </div>
      ) : (
        ""
      )}
      {/* user.contractType == ContractType.CLT */}
      {user && (user.role > 0 || user.level == 1) ? (
        <div className="block-content block-content-full bg-black-50 ribbon ribbon-dark">
          <div
            className="ribbon-box"
            onClick={() => {
              openReport();
            }}
            style={{ cursor: "pointer" }}
          >
            <i className="si si-pie-chart mr-1"></i> Relatórios
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="block-content block-content-full block-content-sm bg-black-50">
        <div className="font-size-sm text-muted row">
          <div className="form-group col-md-6">
            <span>Data de início</span>
            <input
              type="date"
              className="form-control form-control-alt form-control-sm"
              value={startDate}
              onChange={(evt) => setStartDate(evt.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <span>Data fim</span>
            <input
              type="date"
              className="form-control form-control-alt form-control-sm"
              value={endDate}
              onChange={(evt) => setEndDate(evt.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="block-content block-content-full bg-black-50">
        {userData && userData.level != 1 ? (
          <div className="row text-center">
            <div className="col-sm-4 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.done}
                </p>
                <p className="text-white mb-0">
                  Feitos{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Processos passados para frente"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="waitingReturnHome"
                >
                  {report.deferred}
                </p>
                <p className="text-white mb-0">
                  {userData.level > 1 ? "Deferidos de 1ª" : "Aprovados"}{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Processos deferidos no primeiro protocolo no órgão"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesAccuracityHome"
                >
                  {report.accuracity}%
                </p>
                <p className="text-white mb-0">
                  Assertividade
                  <i
                    className="ml-3 si si-question"
                    title="Taxa de assertividade no trabalho. (Calculado da seguinte forma: Nº de Deferidos de 1ª dividido pela diferença entre Feitos e Aguard. Protocolo.)"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-12">
              <hr className="text-white" />
            </div>

            <div
              className={
                user && user.role > 0 && userData && userData.contractType === 1
                  ? "col-md-6"
                  : "col-md-12"
              }
            >
              <div className="text-center">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesAccuracityHome"
                >
                  {report.ongoing}
                </p>
                <p className="text-white mb-0">
                  {userData.level > 1
                    ? "Aguardando protocolo / retorno"
                    : "Aguardando aprovação"}
                  <i
                    className="ml-3 si si-question"
                    title="Processos que estão aguardando o protocolo no órgão"
                  />
                </p>
              </div>
            </div>
            {user &&
            user.role > 0 &&
            userData &&
            userData.contractType === 1 ? (
              <div className={"col-md-6"}>
                <div className="text-center">
                  <p
                    className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                    id="salesAccuracityHome"
                  >
                    {show === true ? MoneyMask(total, 2) : "**,**"}
                    {/* R${show === true ? MoneyMask(total, 2) : "**,**"} */}
                    <i
                      onClick={() => setShow(!show)}
                      className={
                        show === true
                          ? "font-size-h6 far fa-eye-slash text-white ml-1"
                          : "font-size-h6 far fa-eye text-white ml-1"
                      }
                    />
                  </p>
                  <p className="text-white mb-0">
                    Rendimento
                    <i
                      className="ml-3 si si-question"
                      title="Valor atual em aberto para pagamento pelos trabalhos"
                    />
                    <br />
                    <button
                      className="btn btn-sm btn-white"
                      onClick={() => setShowWithdrawModal(true)}
                    >
                      Solicitar saque
                    </button>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className="row text-center">
            <div className="col-sm-4 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.sends}
                </p>
                <p className="text-white mb-0">
                  Enviados{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Orçamentos enviados"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.accepted}
                </p>
                <p className="text-white mb-0">
                  Aceitos{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Orçamentos aceitos"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.waiting}
                </p>
                <p className="text-white mb-0">
                  Aguardando Resposta{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Orçamentos aguardando resposta do cliente"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.refused}
                </p>
                <p className="text-white mb-0">
                  Recusados{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Orçamentos recusados"
                  />
                </p>
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="border-right">
                <p
                  className="font-size-h3 font-w300 mt-3 mb-0 text-white"
                  id="salesHome"
                >
                  {report.acuracity && report.acuracity.toFixed(2) != NaN
                    ? report.acuracity.toFixed(2).toLocaleString()
                    : 0}
                  %
                </p>
                <p className="text-white mb-0">
                  Assertividade{" "}
                  <i
                    className="ml-3 si si-question"
                    title="Taxa de processos convertidos"
                  />
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <UserReportModal
        show={showModal}
        onHide={() => setShowModal(false)}
        userId={userData.id}
        level={userData.level}
      />
      <WithdrawModal
        show={showWithdrawModal}
        onHide={() => setShowWithdrawModal(false)}
        user={userData}
        valores={report}
      />
    </div>
  );
}
