import React, { useEffect, useState } from "react";
import Logo from "@/assets/img/logo.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Storage from "@/app/core/util/Storage";
import {
  CustomerResponseTerms,
  CustomerTerms,
} from "@/app/core/base/controllers/TermsController";
import { ContractList } from "@/app/core/base/controllers/ContractController";
import { Message } from "@/app/core/util/messages/GeralMessages";
import GenerateContract from "@/app/core/layouts/main-components/contract/Contract";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import AutomationsController from "@/app/core/base/controllers/AutomationsController";

export default function VerificationComponent(props) {
  const [step, setStep] = useState(0);
  const [terms, setTerms] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [customer, setCustomer] = useState({});
  const [message, setMessage] = useState(
    "Para continuar é necessário aceitar os seguintes itens :",
  );
  const userData = Storage.getUserData();
  const automations = AutomationsController();

  useEffect(() => {
    loadConfigurations();
  }, []);

  async function loadConfigurations() {
    if (userData.level === 5) {
      CustomerTerms(userData.customerId, async (response) => {
        let results = response.filter((x) => x.response === false);

        setTerms(response);
        await automations.CheckContracts(
          (res) => {},
          (err) => {
            console.error("Automatique", err);
          },
        );
        await ContractList(userData.customerId, (res) => {
          if (res.length > 0) {
            let contractResults = res.filter(
              (x) => x.status === 2 || x.status === 1,
            );
            if (results.length === 0 && contractResults.length > 0) {
              window.location = `#/customer-profile/${userData.customerId}`;
            }
            setContracts(res);
          }
        });

        await CustomerSelect(userData.customerId, (res) => {
          setCustomer(res);
        });

        if (results.length === 0) {
          setTimeout(() => {
            setStep(1);
          }, 500);
          setTimeout(() => {
            handleNext();
          }, 2500);
        }
      });
    }
  }

  async function handleChange(checked, item, index) {
    CustomerResponseTerms(item.id, checked, (res) => loadConfigurations());
    let old = terms;
    old[index].response = checked;
    setTerms(old);
  }

  function handleNext() {
    if (step === 0) {
      let notAccepteds = terms.filter((x) => x.response === false);
      let doneContracts = contracts.filter((x) => x.status !== 0);
      if (notAccepteds.length > 0) {
        toast.error("Aceite os termos para prosseguir");
        return;
      } else if (doneContracts.length > 0 && contracts.length > 0) {
        toast.success(Message.Saved);
        setTimeout(() => {
          window.location = `#/customer-profile/${userData.customerId}`;
        }, 250);
      } else {
        toast.success(Message.Saved);
        setMessage("Última etapa... ");
        setStep(1);
      }
    }
  }

  function handleStep() {
    switch (step) {
      case 0:
        return (
          <div className="text-center">
            {terms &&
              terms.map((item, index) => (
                <div className="form-group">
                  <div className="custom-control custom-checkbox custom-control-primary">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"check-" + item.id}
                      name={"check-" + item.id}
                      checked={item.response}
                      onChange={(evt) =>
                        handleChange(evt.target.checked, item, index)
                      }
                    />
                    <label
                      className="custom-control-label"
                      for={"check-" + item.id}
                    >
                      Eu concordo com{" "}
                      <a href={item.term.url} target="_blank" rel="noreferrer">
                        {item.term && item.term.name}
                      </a>
                    </label>
                  </div>
                </div>
              ))}
            <div className="text-center py-3">
              <button
                id="terms-accepted"
                className="btn btn-hero-success"
                onClick={() => (window.location = "#/contract")}
              >
                Próximo <i className="fa fa-arrow-right ml-1"></i>
              </button>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="block">
            <div className=""></div>
            {customer && customer !== undefined ? (
              <GenerateContract customer={customer} />
            ) : (
              ""
            )}
          </div>
        );
      default:
        break;
    }
  }

  function leave() {
    if (window.confirm("Tem certeza que deseja sair?")) {
      localStorage.removeItem("user_data");
      window.location = "/#";
    }
  }

  return (
    <div className="row no-gutters justify-content-center bg-body-dark">
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-NF8WHLV4"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
      <div className="hero-static col-sm-10 col-md-12 col-xl-8 d-flex align-items-center p-2 px-sm-0">
        <div className="block block-rounded block-transparent block-fx-pop w-100 mb-0 overflow-hidden bg-image">
          <div className="row no-gutters">
            <div className="col-md-12 order-md-1 bg-white">
              <div className="block-content block-content-full px-lg-5 py-md-5 py-lg-6">
                <div className="mb-2 text-center">
                  <a
                    className="link-fx text-success font-w700 font-size-h1"
                    href="index.html"
                  >
                    <img src={Logo} style={{ maxWidth: "100px" }} />
                  </a>
                  <p className="font-w700 font-size-sm text-muted">{message}</p>
                </div>

                <div className="text-center">
                  {terms &&
                    terms.map((item, index) => (
                      <div className="form-group">
                        <div className="custom-control custom-checkbox custom-control-primary">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={"check-" + item.id}
                            name={"check-" + item.id}
                            checked={item.response}
                            onChange={(evt) =>
                              handleChange(evt.target.checked, item, index)
                            }
                          />
                          <label
                            className="custom-control-label"
                            for={"check-" + item.id}
                          >
                            Eu concordo com{" "}
                            <a
                              href={item.term.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.term && item.term.name}
                            </a>
                          </label>
                        </div>
                      </div>
                    ))}
                  <div className="text-center py-3">
                    <button
                      id="terms-accepted"
                      className="btn btn-hero-success"
                      onClick={() => (window.location = "#/contract")}
                    >
                      Próximo <i className="fa fa-arrow-right ml-1"></i>
                    </button>
                  </div>
                </div>

                <div className="mt-3 text-center">
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    alt="Sair"
                    onClick={() => leave()}
                  >
                    <i className="si si-logout mr-1"></i> Sair
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className="toastr" />
    </div>
  );
}
