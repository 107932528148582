import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Logo from "@/assets/img/logo.jpg";
import "react-toastify/dist/ReactToastify.css";
import Storage from "@/app/core/util/Storage";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import NotificationController from "@/app/core/base/controllers/NotificationController";
import OrganListHelper, {
  OrganStatus,
} from "@/app/core/util/helpers/OrganListHelper";
import DocMasterItem from "@/app/core/layouts/main-components/documents/DocMasterItem";
import DocsController from "@/app/core/base/controllers/DocumentsController";
import { Tabs, Tab, Panel } from "@bumaga/tabs";
import CustomerExigencies from "@/app/core/layouts/main-components/exigencies/CustomerExigencies";
import HelpModal from "@/app/core/layouts/main-components/HelpModal";
import { CompleteSelectedService } from "@/app/core/base/controllers/ServicesController";
import EvaluationModal from "@/app/core/layouts/main-components/evaluation/EvaluationModal";

function DeferredService(props) {
  const [data, setData] = useState({});
  const [organList, setOrganList] = useState([]);
  const [selectedOrgan, setSelectedOrgan] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const userData = Storage.getUserData();
  const organCtrl = OrganListController();
  const docsController = DocsController();
  const organListHelper = OrganListHelper();

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    await CompleteSelectedService(props.match.params.key, (res) => {
      setData(res);
      let organs = [];

      for (let s in res.organList) {
        let item = res.organList[s];

        item.docs = res.docs.filter((x) => x.organListId === item.id);
        item.exigencies = res.exigencies.filter(
          (x) => x.organListId === item.id,
        );

        organs.push(item);
      }

      setOrganList(organs);
      checkCustomer(res.service.process);
    });
  }

  function checkCustomer(process) {
    if (userData !== null) {
      setShow(true);
      return;
    }
    let customerCode = window.prompt(
      "Para acessar essa tela, confirme seu código de cliente",
    );
    if (customerCode === process.customer.customerCode) {
      setShow(true);
    } else {
      let customerCode2 = window.prompt(
        "Código de cliente inválido, tente novamente : ",
      );
      if (customerCode2 === process.customer.customerCode) {
        setShow(true);
      } else {
        if (customerCode === process.customer.customerCode) {
          setShow(true);
        } else {
          let customerCode2 = window.prompt(
            "Código de cliente inválido, tente novamente : ",
          );
          if (customerCode2 === process.customer.customerCode) {
            setShow(true);
          } else {
            if (customerCode === process.customer.customerCode) {
              setShow(true);
            } else {
              let customerCode2 = window.prompt(
                "Código de cliente inválido, tente novamente : ",
              );
              if (customerCode2 === process.customer.customerCode) {
                setShow(true);
              } else {
                let customerCode2 = window.prompt(
                  "Código de cliente inválido, tente novamente : ",
                );
                if (customerCode2 === process.customer.customerCode) {
                  setShow(true);
                } else {
                  toast.info(
                    "Você errou o código muitas vezes e será redirecionado ao site",
                  );
                  setTimeout(() => {
                    window.location = "https://hasa.com.br";
                  }, 3900);
                }
              }
            }
          }
        }
      }
    }
  }

  function openSearch(organListId) {
    setSelectedOrgan(organListId);
    setShowModal(true);
  }

  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        marginBottom: 260,
        height: "100vh",
        backgroundColor: "white",
        paddingBottom: 90,
      }}
      className="py-3"
    >
      <div
        className="container mb-5 "
        style={{
          maxWidth: "1000px",
          margin: "0 auto",
          marginTop: "3em",
          paddingBottom: 90,
        }}
      >
        <div className="row">
          <div
            className="col-lg-12"
            align="center"
            style={{ marginBottom: "1em" }}
          >
            <img src={Logo} width="150px" />
            <br />
            <br />
          </div>
        </div>
        {show === true ? (
          <div>
            <div>
              <div className="block block-fx-shadow">
                <div className="text-center py-3">
                  <h3></h3>
                  <p>
                    Segue documentos conforme o serviço solicitado
                    {/* Os órgãos do serviço fragmentado{" "}
                    {organList.selectedService &&
                      organList.selectedService.serviceName}{" "}
                    estão sendo concluídos <br />
                    Abaixo poderá fazer o download dos documentos e/ou verificar
                    as exigências anotadas */}
                  </p>
                </div>
              </div>
            </div>
            {data &&
              organList &&
              organList.map((item) => (
                <div
                  className={`block block-rounded border border-warning border-2x block-fx-pop mb-2 ribbon ribbon-right ribbon-bookmark ribbon-warning ribbon-top`}
                  key={item.id}
                >
                  {item && item.status === OrganStatus.Done ? (
                    <div className="ribbon-box" style={{ marginRight: -15 }}>
                      <small>Concluído</small>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="block-header">
                    <div className="block-title">
                      {item && item.organ && item.organ.name}
                    </div>
                  </div>
                  <div className="block-content">
                    <div className="row">
                      <div className="col-12 text-left">
                        {/* <Tabs> */}
                        {/* <div
                            className="nav nav-tabs nav-tabs-block nav-justified js-tabs-enabled"
                            style={{ padding: 7 }}
                          >
                            <Tab>
                              <button
                                type="button"
                                className="btn btn-dual mr-1"
                                alt="Documentos"
                              >
                                <i className="far fa-file-archive"></i>{" "}
                                Documentos
                              </button>
                            </Tab>
                            <Tab>
                              <button
                                type="button"
                                className="btn btn-dual mr-1"
                                data-toggle="layout"
                                alt="Documentos"
                              >
                                <i className="fa fa-tasks"></i> Exigências
                              </button>
                            </Tab>
                          </div> */}
                        {/* <Panel> */}
                        {item.docs &&
                          item.docs.map((current) => (
                            <DocMasterItem
                              data={current}
                              organList={item}
                              reload={() => loadData()}
                              openSearch={() => openSearch(item.id)}
                            />
                          ))}
                        {/* </Panel> */}
                        {/* <Panel>
                            <CustomerExigencies
                              organListId={item && item.id}
                              processId={item && item.processId}
                              isInternal={true}
                              hideButtons={true}
                            />
                          </Panel>
                        </Tabs> */}
                      </div>
                      <div className="col-12 py-5 text-center">
                        <button
                          className="btn btn-hero-secondary js-click-ripple-enabled"
                          onClick={() => setShowHelpModal(true)}
                        >
                          Solicitar contato
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          ""
        )}
      </div>

      <HelpModal
        show={showHelpModal}
        organListId={
          organList &&
          organList.length > 0 &&
          organList[0].id &&
          organList[0].id
        }
        onHide={() => setShowHelpModal(false)}
        processId={data && data.service && data.service.processId}
        processName={
          data &&
          data.service &&
          data.service.process &&
          data.service.process.processName
        }
      />

      <EvaluationModal
        show={showModal}
        processId={data && data.service && data.service.processId}
        onHide={() => setShowModal(false) | loadData()}
        organListId={selectedOrgan}
      />
      <ToastContainer className="toastr" />
    </div>
  );
}

export default DeferredService;
