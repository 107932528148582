import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { NegotiationController } from "@/app/core/base/controllers/NegotiationController";
import NegotiationModal from "./NegotiationModal";

export default function NegotiationCard({ customerId, reload }) {
    const [negotiations, setNegotiations] = useState([]);
    const [selectedReminder, setSelectedReminder] = useState({});
    const [showReminderModal, setShowReminderModal] = useState(false);

    useEffect(() => {
        loadNegotiations();
    }, []);

    async function loadNegotiations() {
        if (customerId) {
            await NegotiationController.ByCustomer(customerId, async (res) => {
                setNegotiations(res);
            });
        }
    }

    function deleteReminder(item) {
        if (window.confirm(Message.DeleteConfirm)) {
            NegotiationController.Delete(item.id, async (res) => {
                loadNegotiations();
            });
        }
    }

    function updateReminder(item) {
        item.done = !item.done;
        NegotiationController.Update(item, async (res) => {
            loadNegotiations();
        });
    }

    return (
        <div className="block block-themed col-md-12 block-mode-loading-refresh">
            <div className="block-header">
                <h3 className="block-title">Negociações</h3>
                
            </div>
            <div className="block-content">
                <table className="table table-hover table-striped">
                    <thead>
                        <tr>
                            <td>Descrição</td>
                            <td>Valor</td>
                            <td>Responsável</td>
                            <td>Data de criação</td>
                            <td>Ações</td>
                        </tr>
                    </thead>
                    <tbody>
                        {negotiations && negotiations.map((item) => (
                            <tr>
                                <td>{item.description}</td>
                                <td>{(item.value).toLocaleString()}</td>
                                <td>{item.user && item.user.firstName}</td>
                                <td>{DateTranslate(item.createdDate)}</td>
                                <td>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <NegotiationModal
                infos={selectedReminder}
                show={showReminderModal}
                customerId={parseInt(customerId)}
                onHide={() =>
                    setShowReminderModal(false) | loadNegotiations() | setSelectedReminder({}) | reload()
                }
            />
        </div>
    )
}