import React from "react";
import { renderRoutes } from "react-router-config";
import { Redirect } from "react-router-dom";
import PreparationDashboard from "./main";
import Storage from "@/app/core/util/Storage";
let ProjectShell;
try {
  ProjectShell = (props) =>
    Storage.isAuthenticated() ? (
      <div {...props}>{renderRoutes(props.route.routes, props)}</div>
    ) : (
      <Redirect to="/" />
    );
} catch (err) {
}

const Redirecter = () => <Redirect to="/error/404" />;

const PreparationRouter = {
  path: "/preparation/:key",
  component: ProjectShell,
  routes: [
    {
      path: "/preparation/:key",
      exact: false,
      component: PreparationDashboard,
    },
    {
      path: "*",
      component: Redirecter,
    },
  ],
};

export default PreparationRouter;
