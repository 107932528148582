import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CnpjMask, PhoneMask } from "@/app/core/util/helpers/ContactHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { ReminderHelper } from "@/app/core/util/helpers/ReminderHelper";

export default function ReminderStatsModal(
  { show, onHide, infos, customerId },
  props,
) {
  const userData = Storage.getUserData();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const date = new Date();

  useEffect(() => {
    setData(infos);
    console.log("Lembrete", infos);
  }, [show, infos]);

  function save() {
    end();
  }

  function end() {
    setTimeout(() => {
      toast.success(Message.Saved);
      setLoading(false);
      onHide();
    }, 400);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Relatório"
      onSave={() => save()}
      onSubmit={() => save()}
      isLoading={loading}
    >
      {data && data.type != ReminderHelper.ChargeType.Reminder ? (
        <div className="form-group">
          <span>Empresa</span>
          <div>
            <b>{data && data.companyName}</b> -{" "}
            {data.company && CnpjMask(data.company.doc)}
            <br/>
            <br/>
            <b>Lembrete: {data && data.description}</b>
          </div>
        </div>
      ) : (
        <div className="form-group">
          <span>Lembrete:</span>
          <div>
            <b>{data && data.description}</b>
          </div>
        </div>
      )}

      <div className="block block-bordered">
        <div className="block-header">
          <div className="block-title">Contatos</div>
        </div>
        <div className="block-content">
          <div className="row">
            {data &&
              data.contacts &&
              data.contacts.map((item, index) => (
                <div
                  class="block block-rounded block-bordered border-success block-link-pop col-md-6"
                  href="javascript:void(0)"
                >
                  <div class="block-content block-content-full d-flex align-items-center ">
                    <img
                      class="img-avatar img-avatar48"
                      src="assets/media/avatars/avatar12.jpg"
                      alt=""
                    />
                    <div class="ml-1 text-left">
                      <p class="font-w600 mb-0">{item.name}</p>
                      <p class="font-size-sm font-italic text-muted mb-0">
                        {item.phone && (
                          <span className="mr-3">
                            <i className="si si-screen-smartphone" />{" "}
                            {PhoneMask(item.phone)}
                          </span>
                        )}
                        {item.email && (
                          <span>
                            <i className="si si-envelope" /> {item.email}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <table className="table table-hover">
        <thead></thead>
        <tbody>
          {data &&
            data.sends &&
            data.sends.map((item) => (
              <tr>
                <td>{DateTranslate(item.createdDate)}</td>
                <td>
                  Envio de mensagem via{" "}
                  {item.channel == 0 ? "Email" : "WhatsApp"}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {data && data.files && data.files.length > 0 ? (
        <div className="block block-bordered border-2x border-success">
          <div className="block-header">
            <div className="block-title">Arquivos enviados pelo contato</div>
            <div className="block-tools"></div>
          </div>
          <div className="ml-2 mr-2 mt-2 mb-3">
            {data.files.map((doc) => {
              return (
                <div className="block block-bordered border-2x">
                  <div className="block-header">
                    <div className="block-title">
                      {doc.name} - Data do upload:{" "}
                      {DateTranslate(doc.createdDate)}
                    </div>
                    <div className="block-tools">
                      <button
                        className="btn btn-sm btn-dual"
                        onClick={() => window.open(doc.url, "_blank")}
                      >
                        <i className="far fa-eye"></i> Visualizar
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
}
