import React, { useState } from "react";
import SelectPlanModal from "./SelectPlanModal";
import Storage from "@/app/core/util/Storage";

export default function PlanButton({ name, reload, customerId, subtitle }) {
    const [showModal, setShowModal] = useState(false);
    const userData = Storage.getUserData();

    return (
        <div className="block-content pt-0">
            <h3 className=" mt-0">{name}</h3>
            <p>{subtitle}</p>
            {userData.requested === undefined ? 
            <div className=""><button className="btn btn-warning text-dark" onClick={()=> setShowModal(true)}><i className="si si-lock-open" /> Quero fazer upgrade</button></div>
                :
                <div className="btn btn-info text-dark" style={{marginTop: -14}}>Sua solicitação foi recebida. <br/>Aguarde a liberação do acesso</div>
        }
            <SelectPlanModal
                show={showModal}
                customerId={customerId}
                onHide={() => setShowModal(false) | reload()}
            />
        </div>
    )
}