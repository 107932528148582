import { useState } from "react";
import React, { useEffect } from "react";
import ReviewReportModal from "./Modals/ReviewsModal";
import { NumberMask, ToFixed } from "@/app/core/util/helpers/MoneyHelper";
import { EvaluationListByDate } from "@/app/core/base/controllers/EvaluationController";

export default function Nps({ startDate, endDate, refresh }) {
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadNps();
  }, [refresh]);

  function loadNps() {
    EvaluationListByDate(startDate, endDate, (res) => {
      const base = [];
      for (let s in res) {
        if (res[s].organListId != undefined) {
          base.push(res[s]);
        }
      }
      let count = base.length;
      let media = sum(base) / base.length;
      let detractors = base.filter((x) => x.answer <= 6).length;
      let neutral = base.filter((x) => x.answer > 6 && x.answer < 9).length;
      let promoters = base.filter((x) => x.answer > 8).length;
      let newCont = promoters + detractors;

      let promo = (promoters / count) * 100;
      let detrac = (detractors / count) * 100;
      let nps = promo - detrac;


      setData({
        count,
        detractors,
        neutral,
        promoters,
        media,
        nps,
      });
    });
  }

  function sum(itens) {
    let base = 0;

    for (let s in itens) {
      base += itens[s].answer;
    }
    return base;
  }

  return (
    <div className="col-md-12 row">
      <a
        class="col-8 block block-rounded block-link-shadow"
        href="javascript:void(0)"
        onClick={() => setShowModal(true)}
      >
        <div className="block-header">
          <div className="block-title">NPS</div>
        </div>
        <div class="block-content block-content-full">
          <div class="row text-center">
            <div class="col-2">
              <div class="py-3 border-right">
                <div class="item item-circle bg-body-light mx-auto">
                  <p class="font-size-h3 font-w300 mt-3">
                    {data && data.detractors}
                  </p>
                </div>
                <p class="text-muted mb-0">Detratores</p>
              </div>
            </div>
            <div class="col-2">
              <div class="py-3 border-right">
                <div class="item item-circle bg-body-light mx-auto">
                  <p class="font-size-h3 font-w300 mt-3">
                    {data && data.neutral}
                  </p>
                </div>
                <p class="text-muted mb-0">Neutros</p>
              </div>
            </div>
            <div class="col-2">
              <div class="py-3 border-right">
                <div class="item item-circle bg-body-light mx-auto">
                  <p class="font-size-h3 font-w300 mt-3">
                    {data && data.promoters}
                  </p>
                </div>
                <p class="text-muted mb-0">Promotores</p>
              </div>
            </div>
            <div class="col-3">
              <div class="py-3 border-right">
                <div class="item item-circle bg-body-light mx-auto">
                  <p class="font-size-h3 font-w300 mt-3">
                    {data && data.count}
                  </p>
                </div>
                <p class="text-muted mb-0">Avaliações</p>
              </div>
            </div>
            <div class="col-3">
              <div class="py-3">
                <div class="item item-circle bg-body-light mx-auto">
                  <p class="font-size-h3 font-w300 mt-3">
                    {data && data.media && ToFixed(data.media, 1)}
                  </p>
                </div>
                <p class="text-muted mb-0">Média</p>
              </div>
            </div>
          </div>
        </div>
      </a>
      <a
        class="col-4 block block-rounded block-link-shadow block-bordered block-info"
        href="javascript:void(0)"
        onClick={() => setShowModal(true)}
      >
        <div className="block-header">
          <div className="block-title">NPS Oficial</div>
        </div>

        <div class="block-content block-content-full row">
          <div className="col-12">
            <h2 className="col-12 text-muted text-center">
              {data && NumberMask(data.nps, 2)}
            </h2>
            {/* Maior de 75 */}
            <div
              className={
                data &&
                data.nps &&
                `block block-bordered ${data.nps > 75 ? "border-success border-2x" : ""}`
              }
            >
              <div className="block-header">
                <div className="block-title">
                  <i className="far fa-2x fa-smile-beam text-success mr-2"></i>{" "}
                  Entre 75 e 100 = Zona de excelência
                </div>
              </div>
            </div>

            {/* Entre 50 e 75 */}
            <div
              className={
                data &&
                data.nps &&
                `block block-bordered ${data.nps > 50 && data.nps < 75 ? "border-success border-2x" : ""}`
              }
            >
              <div className="block-header">
                <div className="block-title">
                  <i className="far fa-2x fa-meh text-warning mr-2"></i> Entre
                  50 e 75 = Zona de qualidade
                </div>
              </div>
            </div>
            {/* Entre 30 e 50 */}
            <div
              className={
                data &&
                data.nps &&
                `block block-bordered ${data.nps > 0 && data.nps < 50 ? "border-success border-2x" : ""}`
              }
            >
              <div className="block-header">
                <div className="block-title">
                  <i className="far fa-2x fa-meh text-danger-light mr-2"></i>{" "}
                  Entre 0 e 50 = Zona de aperfeiçoamento
                </div>
              </div>
            </div>

            {/* Menor de 30 */}
            <div
              className={
                data &&
                data.nps &&
                `block block-bordered ${data.nps < 0 ? "border-success border-2x" : ""}`
              }
            >
              <div className="block-header">
                <div className="block-title">
                  <i className="far fa-2x fa-angry text-danger mr-2"></i> Entre
                  -100 e 00 = Zona crítica
                </div>
              </div>
            </div>
            {/* <i className={data && data.nps < 30 ?
                            "far fa-2x fa-angry text-danger mr-2" :
                            data.nps > 75 ? "far fa-2x fa-smile-beam text-success mr-2" :
                                data.nps > 30 && data.nps < 50 ?
                                    "far fa-2x fa-meh text-danger-light mr-2" :
                                    data.nps > 50 && data.nps < 75 ? "far fa-2x fa-meh text-warning mr-2" : "far fa-2x fa-meh text-secondary mr-2"} />  */}
          </div>
        </div>
      </a>
      <ReviewReportModal
        showModal={showModal}
        isBudget={false}
        startDate={startDate}
        endDate={endDate}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}
