import React, { useState, useEffect } from "react";
import Card from "@/app/core/components/CardModel";
import ServicesForm from "./ServicesForm";
import {
  ServiceDelete,
  ServiceInsert,
  ServiceList,
} from "@/app/core/base/controllers/ServicesController";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Storage from "@/app/core/util/Storage";
import { toast } from "react-toastify";
import Functions from "@/app/core/util/Functions";
import Table from "@/app/core/components/table/";

export default function DailyRatesComponent(props) {
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [service, setService] = useState(null);
  const [filter, setFilter] = useState([]);
  const userData = Storage.getUserData();

  const cols = [
    { header: "Serviço", isSortable: true, name: "name" },
    { header: "Valor", isSortable: true, name: "value" },
    { header: "Observação", isSortable: true, name: "observation" },
    {
      header: "Apresentável",
      isSortable: true,
      name: "visible",
      switch: {
        true: "Sim",
        false: "Não",
      },
    },
    {
      header: "Editável",
      isSortable: true,
      name: "editable",
      switch: {
        true: "Sim",
        false: "Não",
      },
    },
    { header: "Prazo", isSortable: true, name: "duration" },
    { header: "Criado em", isSortable: true, name: "createdDate" },
  ];

  useEffect(() => {
    getService();
  }, []);

  function getService() {
    ServiceList((res) => {
      for (let s in res) {
        if (res[s].name === null) {
          res[s].name = "";
        }
        if (res[s].value === null) {
          res[s].value = "";
        }
        if (res[s].observation === null) {
          res[s].observation = "";
        }
        if (res[s].time === null) {
          res[s].time = "";
        }
        res[s].createdDate = DateTranslate(res[s].createdDate);
      }
      setFilter(res);
    });
  }

  function postService(data) {
    setModalLoading(true);
    ServiceInsert(data.values, (res) => {
      setModalLoading(false);
      setShowModal(false);
      getService();
      toast.info("Serviço Cadastrado com Sucesso");
    });
  }

  function disableServices(data) {
    if (userData.level !== 10) {
      toast.info(
        "Seu nível de acesso não permite a exclusão de um serviço, solicite ao administrador",
      );
    } else {
      if (
        window.confirm(
          `Tem certeza que quer excluir o serviço ${data.data.name}`,
        )
      ) {
        ServiceDelete(data.data.id, (res) => {
          toast.info("Serviço removido com Sucesso");
          getService();
        });
      }
    }
  }

  function openService(item) {
    setService(item.data);
    setShowModal(true);
  }

  return (
    <>
      <Card
        title="Serviços"
        action={() => setShowModal(!showModal)}
        icon="plus"
      >
        <Table
          title={filter && filter.length + " Serviços"}
          loading={false}
          data={filter}
          columns={cols}
          hide={false}
          refreshData={() => getService()}
          insertAction={() => setShowModal(true)}
          deleteAction={(info) => disableServices(info)}
          setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
          viewAction={(selected) => openService(selected)}
        />
      </Card>

      <ServicesForm
        data={service}
        show={showModal}
        isLoading={modalLoading}
        onHide={() =>
          setShowModal(!showModal) | getService() | setService(null)
        }
        submit={(service) => postService(service)}
        title="Adicionar Serviço"
      />
    </>
  );
}
