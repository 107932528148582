import React, { useEffect, useState } from "react";
import ServicesReport from "./administratorElements/ServicesReports";
import Counters from "./administratorElements/Counters";
import ReportController from "@/app/core/base/controllers/ReportController";
import CustomerCounter from "./administratorElements/CustomerCounter";
import ObjectiveController from "@/app/core/base/controllers/ObjectiveController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import Nps from "./administratorElements/Nps";
import ReviewReportModal from "./administratorElements/Modals/ReviewsModal";
import { ResumeCard } from "./administratorElements/ResumeCard";
import ActiveAccountReport from "./administratorElements/ActiveAccountReport";
import TicketReport from "./administratorElements/Modals/TicketReport";
import SalesVolumeReport from "./administratorElements/SalesVolumeReport";
import BalanceReport from "./administratorElements/BalanceReport";
import Loading from "@/app/core/layouts/main/components/Loading";

export default function AdministratorPanel() {
  const date = new Date();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
    .toISOString()
    .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getLastDayOfMonth(date.getFullYear(), date.getMonth())
    .toISOString()
    .substring(0, 10),
  );
  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState(date.getTime());
  
  const [resume, setResume] = useState({
    budgetOpen: 0,
    budgetRefused: 0,
    ongoingServices: 0,
    servicesDone: 0,
  });
  const [infos, setInfos] = useState({
    bhag: 0,
    bhagResult: 0,
    id: 0,
  });
  const [ltv, setLtv] = useState({
    month: 0,
    lastMonth: 0,
    year: 0,
    lastYear: 0,
  });
  const reportController = ReportController();
  const objectiveCtrl = ObjectiveController();

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }

  useEffect(() => {
    loadCounter();
  }, []);

  useEffect(() => {}, []);

  async function loadCounter() {
    setLoading(true);
    await loadResume(1);
    // await reportController.GeneralCounter((res) => setData(res));
    await objectiveCtrl.List(startDate, endDate, (res) => setInfos(res));
    await reportController.LtvCounter((res) => setLtv(res));
    setChange(date.getTime())
    setLoading(false);
  }

  async function loadResume(value) {
    await reportController.ResumeOperational(
      value != undefined ? value : 1,
      (res) => {
        res.activeAccountsPercentage = ((res.currentActiveAccounts - res.activeAccounts) / res.activeAccounts) * 100;
       

        res.averagePercentage =
          ((res.currentAverage - res.average) / res.average) * 100;

        res.volumePercentage =
          ((res.currentVolume - res.volume) / res.volume) * 100;

        res.invoicingPercentage =
          ((res.currentInvoicing - res.invoicing) / res.invoicing) * 100;

        setResume(res);
      },
    );
  }

  return (
    <div className="row">
      <Loading loading={loading} />
      <div className="col-8">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Contadores
            Gerais
          </h2>
        </div>
      </div>
      <div className="col-4 tex-right">
        <div className="form-group">
          <span>Intervalo</span>
          <select
            className="form-control"
            onChange={(evt) => loadResume(evt.target.value)}
          >
            <option value={1}>7x7 dias</option>
            <option value={2}>15x15 dias</option>
            <option value={3}>Mês passado x mês atual</option>
            <option value={4}>Trimestre passado x trimestre atual</option>
          </select>
        </div>
      </div>
      <div className="col-md-3">
        <ResumeCard
          hint={
            "Clientes que aceitaram 1 ou mais orçamentos no período consultado"
          }
          title="Contas ativas"
          current={resume && resume.currentActiveAccounts}
          preview={resume && resume.activeAccounts}
          currentDiference={
            resume &&
            resume.activeAccountsPercentage &&
            resume.activeAccountsPercentage.toFixed(1)
          }
        />
      </div>
      <div className="col-md-3">
        <ResumeCard
          hint={
            "Valor médio de vendas por cliente (Soma de vendas dividido pelo total de clientes)"
          }
          title="Ticket médio"
          preview={resume && MoneyMask(resume.average, 2)}
          current={resume && MoneyMask(resume.currentAverage, 2)}
          currentDiference={
            resume &&
            resume.averagePercentage &&
            resume.averagePercentage.toFixed(1)
          }
        />
      </div>
      <div className="col-md-3">
        <ResumeCard
          hint={"Quantidade de serviços vendidos no período consultado"}
          title="Volume de vendas"
          preview={resume && resume.volume}
          current={resume && resume.currentVolume}
          currentDiference={
            resume &&
            resume.volumePercentage &&
            resume.volumePercentage.toFixed(1)
          }
        />
      </div>
      <div className="col-md-3">
        <ResumeCard
          hint={"Valor monetário gerado dentro do período consultado"}
          title="Faturamento"
          preview={resume && MoneyMask(resume.invoicing, 2)}
          current={resume && MoneyMask(resume.currentInvoicing, 2)}
          currentDiference={
            resume &&
            resume.invoicingPercentage &&
            resume.invoicingPercentage.toFixed(1)
          }
        />
      </div>
      <div className="col-8">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Operacional
          </h2>
        </div>
      </div>
      <div className="col-4 row">
        <div className="col-md-4">
          <div className="form-group">
            <span>Data de início</span>
            <input
              type={"date"}
              className="form-control"
              value={startDate}
              max={endDate}
              onChange={(evt) => setStartDate(evt.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <span>Data fim</span>
            <input
              type={"date"}
              className="form-control"
              value={endDate}
              onChange={(evt) => setEndDate(evt.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <button className="btn btn-info btn-square mt-4" onClick={()=> loadCounter()}><i className="far fa-search"/> Consultar</button>
        </div>
      </div>
      <div className="col-12">
        <Counters startDate={startDate} endDate={endDate} refresh={change}/>
      </div>
      <div className="col-12">
        <a class="block block-rounded bg-gd-aqua" href="javascript:void(0)">
          <div class="block-content bg-gd-aqua">
            <p class="text-white text-uppercase font-size-sm font-w700">LTV</p>
          </div>
          <div class="block-content block-content-full">
            <div class="row text-center">
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {ltv && MoneyMask(ltv.month, 0)}
                  </p>
                  <p class="text-white-75 mb-0">Mês</p>
                </div>
              </div>
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {ltv && MoneyMask(ltv.lastMonth, 0)}
                  </p>
                  <p class="text-white-75 mb-0">Mês passado</p>
                </div>
              </div>
              <div class="col-3">
                <div class="py-3 border-right border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {ltv && MoneyMask(ltv.year, 0)}
                  </p>
                  <p class="text-white-75 mb-0">Ano</p>
                </div>
              </div>
              <div class="col-3">
                <div class="py-3 border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {ltv && MoneyMask(ltv.lastYear, 0)}
                  </p>
                  <p class="text-white-75 mb-0">Ano passado</p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="col-12">
        <a class="block block-rounded bg-xwork-light" href="javascript:void(0)">
          <div class="block-content bg-xwork-light">
            <p class="text-white text-uppercase font-size-sm font-w700">BHAG</p>
          </div>
          <div class="block-content block-content-full">
            <div class="row text-center">
              <div class="col-4">
                <div class="py-3 border-right border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {infos && MoneyMask(infos.bhag, 0)}
                  </p>
                  <p class="text-white-75 mb-0">BHAG</p>
                </div>
              </div>
              <div class="col-4">
                <div class="py-3 border-right border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {infos && infos.bhagResult}
                  </p>
                  <p class="text-white-75 mb-0">Atemporal</p>
                </div>
              </div>
              <div class="col-4">
                <div class="py-3 border-black-op">
                  <p class="text-white font-size-h3 font-w300 mt-3 mb-0">
                    {infos && infos.id}
                  </p>
                  <p class="text-white-75 mb-0">No período</p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <Nps startDate={startDate} endDate={endDate} refresh={change}/>
      <div className="col-12">
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Estratégico
          </h2>
        </div>
      </div>
      <div className="col-12">
        <CustomerCounter startDate={startDate} endDate={endDate} refresh={change}/>
      </div>
      <div className="col-12">
        <ServicesReport startDate={startDate} endDate={endDate} refresh={change}/>
      </div>
      <div className="col-12">
        <ActiveAccountReport />
      </div>
      <div className="col-12">
        <TicketReport />
      </div>
      <div className="col-12">
        <SalesVolumeReport />
      </div>
      <div className="col-12">
        <BalanceReport />
      </div>
      <div className="col-12">
        {/* <CustomerReport startDate={startDate} endDate={endDate} /> */}
      </div>
      <ReviewReportModal
        isBudget={true}
        showModal={showModal}
        startDate={startDate}
        endDate={endDate}
        onHide={() => setShowModal(false)}
        change={change}
      />
    </div>
  );
}
