import React, { useEffect, useState } from "react";
import "./assets/nav.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Nav from "./components/Nav";
import Storage from "@/app/core/util/Storage";
import SideOverlay from "./components/SideOverlay";
import Chat from "./components/Chat";
import NewProspectBudget from "../main-components/NewProspectBudget";
import BaseService from "@/app/core/base/BaseService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toolbox from "../main-components/user/Toolbox";

export default function Layout(props) {
  const [showModal, setShowModal] = useState(false);
  const [showProspectModal, setShowProspectModal] = useState(false);
  const [menu, setMenu] = useState([]);
  const user = Storage.getUserData();
  const enviroment = process.env.REACT_APP_ENV;

  useEffect(() => {
    processMenu();
  }, [user]);

  function processMenu() {
    if (user !== null && user !== undefined && menu.length < 1) {
      if (user.level === 5) {
        let oldBase = [
          {
            name: "Home",
            icon: "si-home",
            routerLink: `/customer-profile/${user.customerId}`,
          },
          {
            name: "Serviços Oferecidos",
            icon: "si-bulb",
            routerLink: `/services`,
          },
          {
            name: "Certificação Digital",
            url: `https://www.certificadodigitalhasa.com.br/comprar-agora/`,
            icon: "si-badge",
          },
          {
            name: "Feedback",
            routerLink: `/survey`,
            icon: "si-speech",
          },
          {
            name: "Perfil",
            routerLink: `/company`,
            icon: "si-briefcase",
          },
          // {
          //   name: "Transparência",
          //   routerLink:`/terms`,
          //   icon: "si-graph",
          // },
          {
            name: "Política de Descontos",
            routerLink: `#`,
            url: `https://hasa.com.br/politica-de-descontos/`,
            icon: "si-wallet",
          },
          {
            name: "Blog Hasa",
            routerLink: `#`,
            url: `https://hasa.com.br/blog/`,
            icon: "si-paper-clip",
          },
        ];
          oldBase.push({
            name: "Financeiro",
            routerLink: `/billing`,
            badge: "Em breve",
            icon: "si-credit-card",
          });
        
        setMenu(oldBase);
      } else {
        let menuBase = [
          {
            name: "Home",
            icon: "si-cursor",
            routerLink: "/",
          },
          {
            name: "Ajustes",
            type: "over-text",
          },
          {
            name: "Perfil",
            routerLink: "/profile",
            icon: "si-user",
          },
        ];

        if (user.level === 7 || user.level === 10) {
          menuBase.push({
            name: "Fluxo de caixa",
            routerLink: "/cashflow",
            icon: "si-calculator",
          });
        }
        if (user.level === 1 || user.level === 10) {
          menuBase.push({
            name: "Recusados",
            routerLink: "/refuseds",
            icon: "si-close",
          });
          menuBase.push({
            name: "Oportunidades",
            routerLink: "/oportunities",
            icon: "si-bar-chart",
          });
        }
        if (
          user.level === 1 ||
          user.level === 2 ||
          user.level === 3 ||
          user.level === 10
        ) {
          menuBase.push({
            name: "Arquivo digital",
            routerLink: "/resend",
            icon: "si-paper-plane",
          });
          if (user.level == 10) {
            menuBase.push({
              name: "Devoluções",
              routerLink: "/resendDevolution",
              icon: "si-basket-loaded",
            });
          }
        }

        if (user.level == 4) {
          menuBase.push({
            name: "Arquivo digital",
            routerLink: "/resendDevolution",
            icon: "si-paper-plane",
          });
        }

        if (
          user.role > 0 ||
          user.level == 7 ||
          user.level == 8 ||
          user.level == 6 ||
          user.level == 10
        ) {
          menuBase.push({
            name: "Clientes",
            routerLink: "/customer",
            icon: "si-users",
          });
        }

        if (user.role > 0 || user.supervisorPanel == true) {
          menuBase.push({
            name: "Painel Supervisor",
            routerLink: "/supervisor",
            icon: "si-calendar",
          });
        }

        if (user && user.retentionPanel == true) {
          menuBase.push({
            name: "Retenção",
            routerLink: "/retention",
            icon: "si-directions",
          });
        }

        if (user.nps == true) {
          menuBase.push({
            name: "NPS",
            routerLink: "/nps",
            icon: "si-bar-chart",
          });
        }

        if (user.exigencyPanel == true) {
          menuBase.push({
            name: "Painel de exigências",
            routerLink: "/exigency-panel",
            icon: "si-ban",
          });
        }
        if (user.level == 1 || user.role > 0) {
          menuBase.push({
            name: "Lançamentos",
            routerLink: "/consult",
            icon: "si-notebook",
          });

          menuBase.push({
            name: "Disponibilidade",
            routerLink: "/available-cities",
            icon: "si-map",
          });
        }

        if (user.role !== 0) {
          if (user.level === 10) {
            menuBase.push({
              name: "Processos",
              routerLink: "/process",
              icon: "si-calendar",
            });
            menuBase.push({
              name: "Concluídos",
              routerLink: "/done",
              icon: "si-check",
            });
          }

          menuBase.push({
            name: "Nárnia",
            routerLink: "/narnia",
            icon: "si-directions",
          });
          menuBase.push({
            name: "Configurações",
            routerLink: "/settings",
            icon: "si-settings",
          });
        }

        setMenu(menuBase);
      }
    }
  }

  let pageContainerRef = React.createRef();
  const toggleOverlay = () => {
    const pageContainer = pageContainerRef.classList;
    pageContainer.remove("side-overlay-o");
    pageContainer.remove("sidebar-o");
  };
  const toggleNav = () => {
    const pageContainer = pageContainerRef.classList;
    if (window.innerWidth > 991) {
      pageContainer.remove("sidebar-o-xs");
      pageContainer.toggle("sidebar-o");
    } else {
      pageContainer.remove("sidebar-o");
      pageContainer.toggle("sidebar-o-xs");
    }
  };

  return (
    <div
      ref={(el) => (pageContainerRef = el)}
      id="page-container"
      className={`enable-page-overlay side-scroll page-header-fixed main-content-narrow page-footer-fixed  sidebar-dark page-header-${Storage.getTheme()} `}
    >
      <div id="page-overlay" onClick={() => toggleOverlay()} />
      <Nav toggleNav={toggleNav} menu={menu} />
      <Header
        showModal={() => setShowModal(!showModal)}
        showProspectModal={() => setShowProspectModal(!showProspectModal)}
        toggleNav={toggleNav}
        {...props.location}
      />
      <main id="main-container">{props.children}</main>
      <Footer />
      {/* <SideOverlay style={{ width: "500px" }} title="Toolbox">
        <Toolbox />
      </SideOverlay> */}
      {/* <NewBudget
        show={showModal}
        submit={(newBudget) => onNewBudget(newBudget)}
        onHide={() => setShowModal(false)}
      /> */}
      <NewProspectBudget
        show={showProspectModal}
        onHide={() => setShowProspectModal(false)}
      />
      <ToastContainer className="toastr" />
    </div>
  );
}
