import React from "react";
import InputMask from "react-input-mask";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { Typeahead } from "react-bootstrap-typeahead";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";

export default function Field({
    type,
    options,
    keyOption,
    nameOption,
    value,
    onChange,
    name,
    title,
    mask,
    hint,
    disabled,
    placeholder,
    maxLenght,
    defaultValue,
}) {
    const [query, setQuery] = useState("");
    const [visible, setVisible] = useState(false);

    return (
        <div className="form-group">
            {type !== FieldTypeObj.Checkbox ? 
            <span>{title}</span>
            : ""}
            {type === FieldTypeObj.Select ? (
                <select
                    className="form-control"
                    defaultValue={defaultValue}
                    value={value}
                    name={name}
                    key={name}
                    disabled={disabled}
                    onChange={(evt) => onChange(evt)}
                >
                    <option selected={value === null ? true : false}>Selecione</option>
                    {options &&
                        options.map((item) => (
                            <option value={item[keyOption]}>{item[nameOption]}</option>
                        ))}
                </select>
            ) : (
                ""
            )}
            {type === FieldTypeObj.Mask ? (
                <InputMask
                    mask={mask}
                    type="text"
                    className="form-control"
                    name={name}
                    placeholder={title}
                    key={name}
                    defaultValue={defaultValue}
                    value={value}
                    onChange={(event) => {
                        onChange({
                            target: {
                                name: name,
                                value: event.target.value,
                            },
                        });
                    }}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Currency ? (
                <CurrencyFormat
                    className={`form-control`}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    prefix={"R$ "}
                    onValueChange={(values) => {
                        const { formattedValue, value } = values;

                        onChange(
                            { target: { name: name, value: value > 0 ? value : 0 } },
                            FieldTypeObj.Currency
                        );
                    }}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.TypeAhead ? (
                <Typeahead
                    options={options}
                    labelKey={(option) => `${option[nameOption]}`}
                    onSearch={(q) => setQuery(q)}
                    placeholder={title}
                    key={name}
                    onChange={(selected) => {
                        if (selected[0] !== undefined || selected[0] !== null) {
                            onChange({ target: { value: selected[0].id, name } });
                        }
                    }}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Number ? (
                <input
                    type="number"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Time ? (
                <input
                    type="time"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Date ? (
                <input
                    type="date"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Email ? (
                <input
                    type="email"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === undefined || type === FieldTypeObj.Text ? (
                <input
                    type="text"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.TextArea ? (
                <textarea
                    type="text"
                    placeholder={placeholder}
                    name={name}
                    defaultValue={defaultValue}
                    value={value}
                    key={name}
                    disabled={disabled}
                    maxLength={maxLenght}
                    className="form-control"
                    onChange={(evt) => onChange(evt)}
                />
            ) : (
                ""
            )}
            {type === FieldTypeObj.Password ? (
                <div className="input-group">
                    <input
                        type={visible === true ? "text" : "password"}
                        className="form-control"
                        key={name}
                        name={name}
                        defaultValue={defaultValue}
                        value={value}
                        disabled={disabled}
                        placeholder={placeholder}
                        onChange={(evt) => onChange(evt)}
                    />
                    <div className="input-group-append">
                        <span
                            className="input-group-text"
                            onClick={() => setVisible(!visible)}
                        >
                            <i
                                className={
                                    visible === true
                                        ? "far fa-fw fa-eye"
                                        : "far fa-fw fa-eye-slash"
                                }
                            />
                        </span>
                    </div>
                </div>
            ) : (
                ""
            )}
            {type === FieldTypeObj.Bool ? (
                <div className="form-group">

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" id={`${name}-1`} name={name} defaultChecked={value === true ? true : false} onChange={evt => onChange({
                            target: {
                                name: name,
                                value: evt.target.checked === true ? true : false
                            }
                        })} />
                        <label class="custom-control-label" for={`${name}-1`}>Sim</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" className="custom-control-input" id={`${name}-2`} name={name} defaultChecked={value === false ? true : false} onChange={evt => onChange({
                            target: {
                                name: name,
                                value: evt.target.checked === true ? false : true
                            }
                        })} />
                        <label class="custom-control-label" for={`${name}-2`}>Não</label>
                    </div>
                </div>
            ) : (
                ""
            )}
           
            {type === FieldTypeObj.Checkbox ? (
                <div class="custom-control custom-switch custom-control-warning mb-1">
                    <input type="checkbox" class="custom-control-input" id={name} name={name} defaultChecked={value} onChange={evt => onChange({
                            target: {
                                name: name,
                                value: evt.target.checked === true ? true : false
                            }
                        })}/>
                    <label class="custom-control-label" for={name}>{title}</label>
                </div>
            ) : (
                ""
            )}


            {hint !== undefined ? <small>{hint}</small> : ""}
        </div>
    );
}
