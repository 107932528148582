import React, { useEffect, useState } from "react";
import Table from "@/app/core/components/table/";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import "react-toastify/dist/ReactToastify.css";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import Functions from "@/app/core/util/Functions";
import OrganListHelper from "@/app/core/util/helpers/OrganListHelper";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import OperationProcessModal from "@/app/core/layouts/main-components/OperationProcessModal";

export default function DoneComponent({ history, search }, props) {
  const date = new Date();
  const [raw, setRaw] = useState([]);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    limit: 100,
    totalRows: 100,
  });
  const userData = Storage.getUserData();
  const organListHelper = OrganListHelper();
  const organListCtrl = OrganListController();
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getLastDayOfMonth(date.getFullYear(), date.getMonth())
      .toISOString()
      .substring(0, 10),
  );

  useEffect(() => {
    loadOrganList();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }

  function loadOrganList() {
    setLoading(true);
    organListCtrl.DoneByDate(startDate, endDate, (res) => {
      for (let s in res) {
        res[s].processName = res[s].process.processName;
        res[s].serviceName = res[s].selectedService.serviceName;
        res[s].customerName = res[s].process.customer.name;
        res[s].organName = res[s].organ.name;

        res[s].createdDate = DateTranslate(res[s].createdDate);
        res[s].updatedDate = DateTranslate(res[s].updatedDate);
        res[s].endDevolutionDate = DateTranslate(res[s].endDevolutionDate);
      }

      setLoading(false);
      setRaw(res);
      setFilter(res);
      setData(res);
    });
  }

  function openOrgan(item) {
    setSelected({
      processId: item.processId,
      organListId: item.id,
      id: item.processId,
    });
    setShowModal(true);
  }

  function handleChange(value) {
    setQuery(value);
    if (value.length > 0) {
      setFilter([]);
      setFilter(autocomplete(value));
    } else {
      setFilter(raw);
    }
  }

  function autocomplete(value) {
    const inputValue = value.trim().toLowerCase();
    let itens = raw;
    let newResults = [];

    for (let s in itens) {
      const item = itens[s];
      const processName =
        item.processName !== undefined
          ? item.processName.trim().toLowerCase()
          : "";
      const serviceName =
        item.serviceName !== undefined ? item.serviceName : "";
      const customerName =
        item.customerName !== undefined ? item.customerName.toLowerCase() : "";
      const organName =
        item.organName !== undefined ? item.organName.toLowerCase() : "";

      const createdDate = item.endAnalysisDate;
      const endDevolutionDate = item.endDevolutionDate;

      if (
        processName.includes(inputValue) ||
        serviceName.includes(value) ||
        customerName.includes(inputValue) ||
        organName.includes(inputValue) ||
        endDevolutionDate.includes(inputValue) ||
        createdDate.includes(inputValue)
      ) {
        newResults.push(item);
      }
    }

    return newResults;
  }

  return (
    <>
      <div className="content">
        <div className="row">
          <div className="col-md-8">
            <h2 className="content-heading">
              <i className="fa fa-angle-right text-muted mr-1"></i>{" "}
              {data && data.length} Concluídos
            </h2>
          </div>
          <div className="col-4 row">
            {/* <div className="col-md-6">
                <div className="form-group">
                    <span>Data de início</span>
                    <input
                        type={"date"}
                        className="form-control"
                        value={startDate}
                        onChange={(evt) => setStartDate(evt.target.value)}
                    />
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                    <span>Data fim</span>
                    <input
                        type={"date"}
                        className="form-control"
                        value={endDate}
                        onChange={(evt) => setEndDate(evt.target.value)}
                    />
                </div>
            </div> */}
          </div>
          <div className="col-md-12">
            <input
              type="text"
              className="form-control form-control-alt"
              placeholder="Pesquisar..."
              onChange={(evt) => handleChange(evt.target.value)}
            />
          </div>
          <Table
            title={filter && filter.length + " Órgãos concluídos"}
            loading={loading}
            data={filter}
            columns={organListHelper.DoneCols}
            hide={false}
            refreshData={() => loadOrganList()}
            setFilter={(filtered) => Functions.ProcessFilter(filtered, filter)}
            viewAction={(selected) => openOrgan(selected.data)}
          />
        </div>
      </div>
      <OperationProcessModal
        show={showModal}
        onHide={() => setSelected({}) | setShowModal(false)}
        data={selected}
      />
      <ToastContainer className="toastr" />
    </>
  );
}
