import GetBaseUrl from "@/app/core/base/BaseAddress";
import { EmailCharge } from "@/app/core/base/controllers/ContactController";
import ServiceChargeController from "@/app/core/base/controllers/ServiceChargeController";
import {
  DateTranslate,
  GetMonthOfDate,
  OnlyDateTranslate,
} from "@/app/core/util/DateTranslater";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ChargeModal from "./elements/ChargeModal";
import Storage from "@/app/core/util/Storage";
import { Dropdown } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import WhatsAppController from "@/app/core/base/controllers/WhatsAppController";
import NegotiationsModal from "./NegotiationsModal";
import NegotiationCard from "./NegotiationCard";
import { NegotiationTimelineController } from "@/app/core/base/controllers/NegotiationTimelineController";
import { TimelineChannelEmail, TimelineChannelSistema } from "@/app/core/util/helpers/TimelineHelper";
import CustomContact from "../contact/CustomContact";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function CustomerPayments({ customerId }) {
  const [data, setData] = useState([]);
  const [anothers, setAnothers] = useState([]);
  const [selected, setSelected] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(Storage.getUserData())
  const enviroment = process.env.REACT_APP_ENV;

  const financialHelper = FinancialHelper();
  const charges = ServiceChargeController();

  useEffect(() => {
    loadCharges();
  }, []);

  async function loadCharges() {
    await charges.ListByCustomer(customerId, (res) => {
      let base = [];
      for(let s of res){
        if(s.price > 0){
          s.price *= -1;
        }
        s.time = new Date(s.dueDate).getTime();
        if(s.time > 1733446086343){
          base.push(s);
        }
      }

      base.sort(function (a, b) {
        return b.time - a.time;
      });
      if (base.length <= 3) {
        setData(base);
      } else {
        let all = [];
        let toData = [];
        for (let s in base) {
          if (parseInt(s) <= 3) {
            toData.push(base[s]);
          } else {
            all.push(base[s]);
          }
        }

        setData(toData);
        setAnothers(all);

      }
    });
  }

  function seeCharge(charge) {
    window.open(`${GetBaseUrl()}#/bill/${charge.id}`, "_blank")
  }

  async function deleteCharge(item) {
    if (window.confirm(Message.DeleteConfirm)) {
      await charges.Delete(item.id, (res) => {
        toast.success(Message.DeleteSuccess);
        loadCharges();
      });
    }
  }

  async function sendAll(item) {
    await EmailCharge(item, item.price);
    await sendByWhats(item);
  }
  async function sendByWhats(item) {
    await WhatsAppController().SendCharge(item.id, res => {
      toast.success(Message.Saved);
      ServiceChargeController().Update(item, res => {
        loadCharges()
      });
    });
  }
  async function sendByEmail(item) {
    await WhatsAppController().SendEmailCharge(item.id, res => {
      toast.success(Message.Saved);
      ServiceChargeController().Update(item, res => {
        loadCharges()
      });
    });
  }

  async function sendCustomEmail(item, email) {
    item.email = email;
    await EmailCharge(item, item.price);
    let timeline = {
      observation: `Enviado: '${item.name}' no valor ${MoneyMask(item.price, 2)} no email ${email}`,
      customerId: customerId,
      userId: userData.id,
      channel: TimelineChannelEmail,
    };

    await NegotiationTimelineController.Insert(timeline, (res) => {
      ServiceChargeController().Update(item, res => {
        loadCharges()
      });
    });
  }

  function changeStatus(item, status) {
    if (window.confirm(Message.Confirm)) {
      NegotiationTimelineController.Insert({
        CustomerId: parseInt(customerId),
        UserId: userData.id,
        Observation: `Alterou o status da fatura de '${item.name}' de '${FinancialHelper().PaymentStatusTranslate(item.status).name}' para '${FinancialHelper().PaymentStatusTranslate(status).name}' `,
        Channel: TimelineChannelSistema,
      }, res => {
        item.status = status;
        charges.Update(item, (res) => {
          loadCharges();
        });
      })

    }
  }

  return (
    <>
      <div className="block block-themed  block-mode-loading-refresh">
        <div className="block-header">
          <h3 className="block-title">Faturas</h3>
          <div className="block-tools">
          {userData && userData.level != 5 ?
            <button
              className="btn btn-sm btn-white"
              onClick={() => setShowModal(true)}
            >
              <i className="si si-notebook"></i> Timeline
            </button>
                      : ""}

          </div>
        </div>
        <div className="block-content">
          <table className="table table-hover table-striped">
            <thead>
              <tr>
                <td>Mês</td>
                <td>Valor</td>
                <td>Status</td>
                {/* <td>Código do boleto</td> */}
                <td>Ações</td>
                {userData && userData.level != 5 ?
                <td>Último envio</td>
                      : ""}

              </tr>
            </thead>
            <tbody>
              {data && data.map((item, index) => {
                return <tr>
                  <td>{item.name}</td>
                  <td>{item && item.price && MoneyMask(item.price)}</td>
                  <td>
                    {item && financialHelper.BillingPaymentStatusBadge(item.status)}
                  </td>
                  
                  {userData && userData.level != 5 ?
                    <td className="d-flex">
                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => seeCharge(item)}
                      >
                        <i className="fa fa-eye" /> Ver / Pagar Fatura
                      </button>

                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => sendAll(item)}
                        disabled={true}
                      >
                        <i className="si si-paper-plane" /> Enviar
                      </button>
                      
                      <Dropdown>
                        <Dropdown.Toggle variant="light"
                              disabled={true}>
                          <i className="si si-envelope-letter" /> Enviar por
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="text-center">
                            <button
                              type="button"
                              disabled={true}
                              className="btn btn-sm btn-dual btn-block"
                              onClick={() => sendByWhats(item)}
                            >
                              <i className="fab fa-whatsapp" />
                              <span className="ml-1 d-none d-sm-inline-block"></span> WhatsApp
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-dual btn-block"
                              disabled={true}
                              onClick={() => sendByEmail(item)}
                            >
                              <i className="fa fa-fw fa-envelope" />
                              <span className="ml-1 d-none d-sm-inline-block"></span> Email
                            </button>
                            <CopyToClipboard
                              text={`${GetBaseUrl()}#/bill/${item.id}`}
                              onCopy={() => toast.info("Copiado para a área de transferência")}
                            >
                              <button
                                type="button"
                                className="btn btn-sm btn-dual btn-block"
                                title="copiar"
                              >
                                <i className="far fa-copy" /> Copiar link
                              </button>
                            </CopyToClipboard>

                            <CustomContact send={(email) => sendCustomEmail(item, email)} />
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      
                      {/* <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => deleteCharge(item)}
                      >
                        <i className="si si-close text-danger" /> Excluir cobrança
                      </button> */}
                    </td>
                    :
                    <td>
                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => seeCharge(item)}>
                        <i className="fa fa-eye" />  Ver / Pagar Fatura
                      </button>
                    </td>
                  }
                        {userData && userData.level != 5 ?
                  <td>
                    {item && DateTranslate(item.updatedDate)}
                  </td>
                      : ""}


                </tr>
              })}
              {anothers && anothers.length > 0 ?
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  {userData && userData.level != 5 ?
                  <td></td>
                      : ""}

                  <td>
                    <button
                      className="btn btn-sm btn-light js-click-ripple-enabled"
                      onClick={() => setOpen(!open)}
                    >
                      <i className={`si si-arrow-${open === true ? "up" : "down"}`}></i>
                    </button>
                  </td>
                </tr>
                : ""}
              {open && anothers.map((item, index) => {
                return <tr>
                  <td>{item.name}</td>
                  <td>{item && item.price && MoneyMask(item.price)}</td>
                  <td>
                    {item && financialHelper.BillingPaymentStatusBadge(item.status)}
                  </td>
                  {userData && userData.level != 5 ?
                  <td>
                  </td>
                      : ""}

                  {userData && userData.level != 5 ?
                    <td className="d-flex">
                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => seeCharge(item)}
                        disabled={true}
                      >
                        <i className="fa fa-eye" /> Ver / Pagar Fatura
                      </button>

                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => sendAll(item)}
                        disabled={true}
                      >
                        <i className="si si-paper-plane" /> Enviar
                      </button>
                      <Dropdown>
                        <Dropdown.Toggle variant="light">
                          <i className="si si-envelope-letter" /> Enviar por
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="text-center">
                            <button
                              type="button"
                              // disabled={disabledWhats}
                              className="btn btn-sm btn-dual btn-block"
                              disabled={true}
                              onClick={() => sendByWhats(item)}
                            >
                              <i className="fab fa-whatsapp" />
                              <span className="ml-1 d-none d-sm-inline-block"></span> WhatsApp
                            </button>
                            <button
                              type="button"
                              className="btn btn-sm btn-dual btn-block"
                              disabled={true}
                              onClick={() => sendByEmail(item)}
                            >
                              <i className="fa fa-fw fa-envelope" />
                              <span className="ml-1 d-none d-sm-inline-block"></span> Email
                            </button>
                            <CopyToClipboard
                              text={`${GetBaseUrl()}#/bill/${item.id}`}
                              onCopy={() => toast.info("Copiado para a área de transferência")}
                            >
                              <button
                                type="button"
                                className="btn btn-sm btn-dual btn-block"
                                title="copiar"
                              >
                                <i className="far fa-copy" /> Copiar link
                              </button>
                            </CopyToClipboard>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>

                    </td>
                    :
                    <td>
                      <button
                        className="btn btn-sm btn-square btn-white m-1"
                        onClick={() => seeCharge(item)}>
                        <i className="fa fa-eye" />  Ver / Pagar Fatura
                      </button>
                    </td>
                  }
                        {userData && userData.level != 5 ?
                  <td>
                    {item && DateTranslate(item.updatedDate)}
                  </td>
                      : ""}

                </tr>
              })}
            </tbody>
          </table>
        </div>

        <NegotiationsModal
          selected={selected}
          show={showModal}
          customerId={customerId}
          onHide={() =>
            setShowModal(false) | loadCharges() | setSelected({})
          }
        />
        <ChargeModal
          selected={selected}
          show={false}
          customerId={customerId}
          onHide={() =>
            setShowModal(false) | loadCharges() | setSelected({})
          }
        />
      </div>
      {userData && userData.level != 5 ?
      <NegotiationCard customerId={customerId} reload={() => loadCharges()} />
                      : ""}

    </>
  );
}
