import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/CustomModal";
import { toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import { OnDataChange } from "@/app/core/util/services/DataService";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { SendPlanChangeEmail, SendPlanSelectEmail, SendSugestionEmail } from "@/app/core/base/controllers/ContactController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { PlanController } from "@/app/core/base/controllers/PlanController";
import { CustomerSelect } from "@/app/core/base/controllers/CustomerController";

export default function SelectPlanModal({
    show,
    onHide,
    submit,
    isLoading,
    customerId,
}) {
    const [data, setData] = useState({ timeType: 0 });
    const [customer, setCustomer] = useState({ timeType: 0 });
    const userData = Storage.getUserData();
    const [plans, setPlans] = useState([]);
    const [done, setDone] = useState(false);
    const [title, setTitle] = useState("Selecionar Plano");
    const [response, setResponse] = useState(<div></div>);
    const date = new Date();

    const [change, setChange] = useState(date.getTime());
    function notifyChange() {
        setChange(date.getTime());
    }

    useEffect(() => {
        if(show){
            loadData();
        }
    }, [show]);

    async function loadData() {
        PlanController.List(res => setPlans(res));
        CustomerSelect(customerId, res => setCustomer(res) | setData({...data, timeType: res.timeType}));
    }

    async function save() {
        if (
            data.name !== undefined &&
            data.name !== "" &&
            data.description !== undefined &&
            data.description !== ""
        ) {
            SendSugestionEmail(data);
            setTimeout(() => {
                toast.success("Sua sugestão foi enviada com sucesso, Obrigado!");
                onHide();
            }, 500);
        } else {
            toast.error(Message.PendingData);
        }
    }

    function handleChange(evt) {
        setData(OnDataChange(data, evt));
    }

    function activatePlan(item, timeType) {
        if (window.confirm(Message.Confirm)) {
            let base = Storage.getUserData();
            let token = Storage.getUserToken();

            if (base.level === 5) {
                let time = "";
                if(timeType == "0"){
                    time = "Anual";
                }else if(timeType == "1"){
                    time = "Trimestral";
                }else if(timeType == "2"){
                    time = "Mensal";
                }
                if(!base.customer.plan){
                    base.customer.plan = item;
                    base.requested = true;
                    
                    Storage.setUserData({ token: token.token, user: base });
                    setDone(true);
                    SendPlanSelectEmail(base.customer, `${item.name} ${time}`);
                    setTitle("");
                    setResponse(
                        <div className="text-center">
                    <h3 className="text-muted">Obrigado pelo seu interesse no Hasa Pro!</h3>
                    <p>
                        Nossa equipe recebeu sua solicitação e entrará em contato o mais breve possível para mostrar tudo o que o Hasa Pro pode oferecer.
                    </p>
                    <p>Enquanto isso, explore outros recursos disponíveis. Estamos aqui para potencializar o seu sucesso!</p>

                    <p>
                        Atenciosamente,<br />
                        <span className="font-w700">
                            Equipe Hasa
                        </span>
                    </p>
                </div>
                    )
                }else{
                    base.requested = true;
                    
                    Storage.setUserData({ token: token.token, user: base });
                    setDone(true);
                    SendPlanChangeEmail(base.customer, base.customer.plan.name, `${item.name} ${time}`);
                    setTitle("");
                    setResponse(
                        <div className="text-center">
                    <h3 className="text-muted">Parabéns por escolher o próximo nível!</h3>
                    <p>
                    Nossa equipe comercial entrará em contato em breve para ajudá-lo a aproveitar todos os benefícios do Hasa Pro.
                    </p>
                    <p>Enquanto isso, continue explorando nossas funcionalidades.</p>

                    <p>
                        Atenciosamente,<br />
                        <span className="font-w700">
                            Equipe Hasa
                        </span>
                    </p>
                </div>
                    )

                }
            }
        }
    }

    return (
        <Modal
            title={title}
            show={show}
            isLoading={isLoading}
            onHide={onHide}
            onSave={submit}
            onSubmit={() => save()}>
            {done === false ? (
                <div>
                    <div className="form-group">
                        <div className="block-header">
                            <div className="block-title">Selecione o plano</div>
                            <div className="block-tools">
                                <div class="form-group">
                                    <label class="d-block">Periodicidade</label>
                                    <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                                        <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg1" name="example-rd-custom-inline-lg"
                                            onChange={evt => setData({ ...data, timeType: 0 }) | notifyChange()} checked={data && data.timeType === 0 ? true : false} />
                                        <label class="custom-control-label" for="example-rd-custom-inline-lg1">Anual</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                                        <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg2" name="example-rd-custom-inline-lg"
                                            onChange={evt => setData({ ...data, timeType: 1 }) | notifyChange()} checked={data && data.timeType === 1 ? true : false} />
                                        <label class="custom-control-label" for="example-rd-custom-inline-lg2">Trimestral</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline custom-control-lg">
                                        <input type="radio" class="custom-control-input" id="example-rd-custom-inline-lg3" name="example-rd-custom-inline-lg"
                                            onChange={evt => setData({ ...data, timeType: 2 }) | notifyChange()} checked={data && data.timeType === 2 ? true : false} />
                                        <label class="custom-control-label" for="example-rd-custom-inline-lg3">Mensal</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            {change && plans && plans.map(item => (
                                <div className="col-md-4">
                                    <div class="block block-rounded block-bordered block-themed text-center">
                                        <div class="block-header bg-muted">
                                            <h3 class="block-title">{item.name}</h3>
                                        </div>
                                        <div class="block-content bg-body-light">
                                            <div class="py-2">
                                                <p class="h1 font-w700 mb-2">{data.timeType == 0 ? MoneyMask(item.priceYerly, 2) : data.timeType == 1 ? MoneyMask(item.priceQuartely, 2) : MoneyMask(item.price, 2)}  <small className="h6"> / mês</small></p>
                                                <p class="h6 text-muted"></p>
                                            </div>
                                        </div>
                                        <div class="block-content">
                                            <div class="py-2">
                                                {item.items.map(option => (
                                                    <p>
                                                        {option.name}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                        <div class="block-content block-content-full bg-body-light">
                                            {customer.planId == item.id && customer.timeType == data.timeType ?
                                                <button class="btn btn-hero-success px-4" disabled={true}>
                                                    <i class="fa fa-check mr-1"></i> Plano atual
                                                </button>
                                                :
                                                <button class="btn btn-hero-secondary px-4" onClick={() => activatePlan(item, data.timeType)}>
                                                    <i class="fa fa-check mr-1"></i> Ativar Plano
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : 
                <div>{response}</div>
            }
        </Modal>
    );
}
