import {
  XAxis,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  YAxis,
  CartesianGrid,
} from "recharts";
import ReportHelper from "@/app/core/util/helpers/ReportHelper";
import React, { useEffect, useState } from "react";
import ReportController from "@/app/core/base/controllers/ReportController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";

export default function BalanceReport({}) {
  const date = new Date();
  const [data, setData] = useState({ total: 0, ticket: 0, count: 0 });
  const [startDate, setStartDate] = useState(
    getFirstDayOfMonth(date.getFullYear(), 0).toISOString().substring(0, 10),
  );
  const [endDate, setEndDate] = useState(
    getLastDayOfMonth(date.getFullYear(), 11).toISOString().substring(0, 10),
  );
  const reportHelper = ReportHelper();
  const report = ReportController();

  useEffect(() => {
    loadReport();
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  function getLastDayOfMonth(year, month) {
    return new Date(year, month, month === 1 ? 28 : 30);
  }

  function loadReport() {
    report.Invoicing(startDate, endDate, async (res) => {
      process(res);
    });
  }

  function process(res) {
    let responses = reportHelper.base;

    for (let s in responses) {
      for (let i in res) {
        if (res[i].month == responses[s].value) {
          responses[s].valor = parseFloat(res[i].result);
        }
      }
    }
    setData(responses);
  }

  function processDate(year) {
    setStartDate(getFirstDayOfMonth(year, 0).toISOString().substring(0, 10));

    setEndDate(getLastDayOfMonth(year, 11).toISOString().substring(0, 10));
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="block block-fx-pop" style={{ width: 350 }}>
          <div className="block-content">
            <div className="row">
              {payload.map((item) => (
                <p className="col-md-12">
                  <i
                    class="fa fa-circle mr-2"
                    style={{
                      color: item.color,
                    }}
                  ></i>
                  {`${item.name} : ${MoneyMask(item.value, 2)}`}
                </p>
              ))}
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="col-md-12" key={"ticketsReport"}>
      <div className="block block-rounded block-bordered">
        <ul className="nav nav-tabs nav-tabs-block align-items-center border-bottom">
          <li className="nav-item">
            <a
              className="nav-link text-body-color active"
              href="javascript:void(0)"
            >
              Faturamento
            </a>
          </li>

          <li className="nav-item ml-auto">
            <div className="block-options mr-3">
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear() - 2)}
              >
                {date.getFullYear() - 2}
              </button>
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear() - 1)}
              >
                {date.getFullYear() - 1}
              </button>
              <button
                className="btn btn-dual"
                onClick={() => processDate(date.getFullYear())}
              >
                {date.getFullYear()}
              </button>
            </div>
          </li>
        </ul>
        {data && data ? (
          <div className="block-content block-content-full">
            <ResponsiveContainer height={300} key={"ativos"}>
              <AreaChart
                width={400}
                height={400}
                data={data}
                margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={CustomTooltip} />
                <Area
                  type="monotone"
                  dataKey={"valor"}
                  stroke={reportHelper.colors[3]}
                  fill={reportHelper.colors[3]}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
