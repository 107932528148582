import BaseService from "@/app/core/base/BaseService";
import { toast } from "react-toastify";
import { HandleErrors } from "../../util/helpers/ErrorHelper";

export const CompanyController = {
  Insert: async function (data, response) {
    await BaseService(
      {
        method: "POST",
        url: "company/insert",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(`Atenção: ${HandleErrors(err)}`);
      },
    );
  },
  Update: async function (data, response) {
    await BaseService(
      {
        method: "POST",
        url: "company/update",
        data: data,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Check: async function (companyId, response) {
    await BaseService(
      {
        method: "POST",
        url: `company/check/${companyId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Get: async function (companyId, response) {
    await BaseService(
      {
        method: "GET",
        url: `company/company/${companyId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  List: async function (customerId, response) {
    await BaseService(
      {
        method: "GET",
        url: `company/customer/${customerId}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  ListOperator: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: `company/`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  ListDoneOperator: async function (response) {
    await BaseService(
      {
        method: "GET",
        url: `company/done`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Search: async function (query, response) {
    await BaseService(
      {
        method: "GET",
        url: `company/search?query=${query}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
  Delete: async function (id, response) {
    await BaseService(
      {
        method: "DELETE",
        url: `company/delete/${id}`,
      },
      (res) => {
        response(res);
      },
      (err) => {
        toast.error(HandleErrors(err));
      },
    );
  },
};
