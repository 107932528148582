import React, { useEffect, useState } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import { CnpjMask } from "@/app/core/util/helpers/ContactHelper";
import { DateTranslate } from "@/app/core/util/DateTranslater";
import { CompanyController } from "@/app/core/base/controllers/CompanyController";
import { OrganHelper } from "@/app/core/util/helpers/OrganHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { CompanyOrganController } from "@/app/core/base/controllers/CompanyOrganController";
import { toast } from "react-toastify";
import OrganListController from "@/app/core/base/controllers/OrganListController";
import DocInput from "../documents/DocInput";
import { CompanyOrganFileController } from "@/app/core/base/controllers/CompanyOrganFileController";

export default function CompanyOrgansModal({ show, onHide, companyId }) {
  const date = new Date();
  const [data, setData] = useState({});
  const [newOrgan, setNewOrgan] = useState("");
  const [loading, setLoading] = useState(false);
  const [organs, setOrgans] = useState([]);
  const [change, setChange] = useState(date.getTime());
  useEffect(() => {
    if (show) {
      loadCompany();
    } else {
      setData({});
      setOrgans([]);
      notifyChange();
    }
  }, [show, companyId]);

  function loadCompany() {
    setLoading(true);
    CompanyController.Get(companyId, (res) => {
      setLoading(false);

      setData(res);

      let newOrgans = res.organs
      .sort(function (a, b) {
        return a.position - b.position;
      });
  
      if (res.organs) {
        let irregular = newOrgans.filter(x => x.status == OrganHelper.CompanyOrganStatus.Irregular);
        let pending = newOrgans.filter(x => (x.status == OrganHelper.CompanyOrganStatus.Pending || x.status == OrganHelper.CompanyOrganStatus.NotConsulted) && x.status !== OrganHelper.CompanyOrganStatus.NotApply);
        let others = newOrgans.filter(x => x.status !== OrganHelper.CompanyOrganStatus.Pending && x.status !== OrganHelper.CompanyOrganStatus.Irregular && x.status !== OrganHelper.CompanyOrganStatus.NotApply);
        let notApply = newOrgans.filter(x => x.status === OrganHelper.CompanyOrganStatus.NotApply);
        setOrgans([
          ...irregular,
          ...pending,
          ...others,
          ...notApply,
        ]);
      }

      notifyChange();
    });
  }

  function notifyChange() {
    setChange(date.getTime());
  }

  function handleChange(index, status) {
    let old = organs;
    old[index].status = status;
    CompanyOrganController.Update(old[index], (res) => {});

    setOrgans(old);
    notifyChange();
  }

  function save() {
    let pending = organs.filter(x => x.status === OrganHelper.CompanyOrganStatus.Pending || x.status === OrganHelper.CompanyOrganStatus.NotConsulted);
    if(pending.length > 0 ){
      toast.error("Existem consultas pendentes, conclua para prosseguir")
    }else if (window.confirm(Message.Confirm)) {
      CompanyOrganController.Response(organs, (res) => {
        toast.success(Message.Saved);
        onHide();
      });
    }
  }

  function saveNewOrgan() {
    if (newOrgan.length == 0) {
      toast.error(Message.PendingData);
    } else {
      CompanyOrganController.Insert(
        { name: newOrgan, companyId: data.id, position: organs.length + 1 },
        (res) => {
          setNewOrgan("");
          setOrgans([...organs, res]);
          notifyChange();
        },
      );
    }
  }

  async function uploadFiles(fl, item) {
    var formData = new FormData();
    for (let s in fl) {
      formData.append("file", fl[s]);
    }
    setLoading(true);

    CompanyOrganFileController.Upload(formData, item.id, (res) => {
      setLoading(false);
      loadCompany();
    });
  }

  function hideDoc(doc) {
    CompanyOrganFileController.Update({ ...doc, visible: false }, (res) => {
      loadCompany();
    });
  }
  function showDoc(doc) {
    CompanyOrganFileController.Update({ ...doc, visible: true }, (res) => {
      loadCompany();
    });
  }
  function deleteDoc(doc) {
    if (window.confirm(Message.DeleteConfirm)) {
      setLoading(true);
      CompanyOrganFileController.Delete(doc.id, (res) => {
        setLoading(false);
        loadCompany();
      });
    }
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title="Empresa"
      onSave={() => save()}
      onSubmit={() => save()}
      isLoading={loading}
    >
      <div
        className="block block-rounded block-fx-pop mb-2 js-appear-enabled animated fadeIn"
        data-toggle="appear"
      >
        <div className="block-content block-content-full border-left border-3x border-success">
          <div className="d-md-flex justify-content-md-between align-items-md-center">
            <div className="p-1 p-md-3">
              <h3 className="h4 font-w700 mb-1">Nome: {data && data.name}</h3>
              <p className="font-size-sm mb-2">
                <a className="mr-1 mb-1" href="javascript:void(0)">
                  CNPJ: {data && CnpjMask(data.doc)}
                </a>
              </p>
            </div>
            <div className="p-1 p-md-3">
              Data de criação: {data && DateTranslate(data.createdDate)}
            </div>
          </div>
        </div>
      </div>

      {change &&
        organs &&
        organs.map((item, index) => (
          <div
            className={
              change &&
              `block block-rounded block-bordered block-fx-pop mb-2 js-appear-enabled animated fadeIn border-3x border-${item.status == OrganHelper.CompanyOrganStatus.NotApply ? "seccondary" : item.status == OrganHelper.CompanyOrganStatus.Irregular ? "danger" : item.status == OrganHelper.CompanyOrganStatus.Pending || item.status == OrganHelper.CompanyOrganStatus.NotConsulted ? "warning" : "success"}`
            }
            data-toggle="appear"
          >
            <div className="block-header">
              <div className="block-title">{item && item.name}</div>
              <div className="block-tools">
                <DocInput
                  multiple={true}
                  uploadFile={(evt) => uploadFiles(evt, item)}
                />
              </div>
            </div>
            <div className="block-content block-content-full ">
              <div className="form-group col-10">
                {/* <label className="d-block"></label> */}
                <div className="custom-control custom-radio custom-control-inline custom-control-success custom-control-lg">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`${item.id}-${OrganHelper.CompanyOrganStatus.Regular}`}
                    name={item.id}
                    checked={
                      item.status == OrganHelper.CompanyOrganStatus.Regular
                        ? true
                        : false
                    }
                    onChange={(evt) =>
                      handleChange(
                        index,
                        OrganHelper.CompanyOrganStatus.Regular,
                      )
                    }
                  />

                  <label
                    className="custom-control-label"
                    for={`${item.id}-${OrganHelper.CompanyOrganStatus.Regular}`}
                  >
                    Regular
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline custom-control-danger custom-control-lg">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`${item.id}-${OrganHelper.CompanyOrganStatus.Irregular}`}
                    name={item.id}
                    checked={
                      item.status == OrganHelper.CompanyOrganStatus.Irregular
                        ? true
                        : false
                    }
                    onChange={(evt) =>
                      handleChange(
                        index,
                        OrganHelper.CompanyOrganStatus.Irregular,
                      )
                    }
                  />

                  <label
                    className="custom-control-label"
                    for={`${item.id}-${OrganHelper.CompanyOrganStatus.Irregular}`}
                  >
                    Irregular
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline custom-control-warning custom-control-lg">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`${item.id}-${OrganHelper.CompanyOrganStatus.Pending}`}
                    name={item.id}
                    checked={
                      item.status == OrganHelper.CompanyOrganStatus.Pending
                        ? true
                        : false
                    }
                    onChange={(evt) =>
                      handleChange(
                        index,
                        OrganHelper.CompanyOrganStatus.Pending,
                      )
                    }
                  />

                  <label
                    className="custom-control-label"
                    for={`${item.id}-${OrganHelper.CompanyOrganStatus.Pending}`}
                  >
                    Pendente
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline custom-control-warning custom-control-lg">
                  <input
                    type="radio"
                    className="custom-control-input"
                    id={`${item.id}-${OrganHelper.CompanyOrganStatus.NotApply}`}
                    name={item.id}
                    checked={
                      item.status == OrganHelper.CompanyOrganStatus.NotApply
                        ? true
                        : false
                    }
                    onChange={(evt) =>
                      handleChange(
                        index,
                        OrganHelper.CompanyOrganStatus.NotApply,
                      )
                    }
                  />

                  <label
                    className="custom-control-label"
                    for={`${item.id}-${OrganHelper.CompanyOrganStatus.NotApply}`}
                  >
                    Não se aplica
                  </label>
                </div>
              </div>
              {item && item.docs && item.docs.length > 0 ? (
                <div>
                  <span>Arquivos</span>
                  {item.docs.map((doc) => (
                    <div className="block block-bordered border-2x">
                      <div className="block-header">
                        <div className="block-title">{doc.name}</div>
                        <div className="block-tools">
                          <button className="btn btn-sm btn-dual"
                            onClick={() => window.open(doc.url, "_blank")}>
                            <i className="far fa-eye"></i> Visualizar
                          </button>
                          {/* <button
                            className="btn btn-sm btn-dual"
                            onClick={() => window.open(doc.url, "_blank")}
                          >
                            <i className="fa fa-file-download"></i> Download
                          </button> */}
                          {doc.visible == true ? (
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => hideDoc(doc)}
                            >
                              Tornar invisível
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-info"
                              onClick={() => showDoc(doc)}
                            >
                              Tornar Visível
                            </button>
                          )}
                          <button
                            className="btn btn-sm btn-dual"
                            onClick={() => deleteDoc(doc)}
                          >
                            <i className="si si-close"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}

      <div
        className="block block-rounded block-bordered border-3x border-warning block-fx-pop mb-2 js-appear-enabled animated fadeIn"
        data-toggle="appear"
      >
        <div className="block-content block-content-full">
          <div className="d-md-flex justify-content-md-between align-items-md-center">
            <div className="form-group">
              <span>Adicionar órgão</span>
              <input
                type="text"
                className="form-control"
                value={newOrgan}
                onChange={(evt) => setNewOrgan(evt.target.value)}
              />
            </div>
            <div className="py-3">
              <button
                className="btn btn-square btn-success"
                onClick={() => saveNewOrgan()}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
