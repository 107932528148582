import React, { useState, useEffect } from "react";
import Modal from "@/app/core/layouts/main/components/Modal";
import {
  DateToPast,
  DateTranslateSimple,
} from "@/app/core/util/DateTranslater";
import { DepartmentTranslate } from "@/app/core/util/helpers/DepartmentHelper";
import { toast } from "react-toastify";
import ProcessController from "@/app/core/base/controllers/ProcessController";

export default function ProcessDoneModal({ customerId, open, show, onHide }) {
  const [days, setDays] = useState(7);
  const [processes, setProcesses] = useState([]);
  const processCtrl = ProcessController();

  useEffect(() => {
    if (customerId !== undefined && show) {
      loadCustomerProcesses();
    }
  }, [show]);

  async function loadProcessDone() {
    await processCtrl.CustomerProcessByDays(customerId, days, (res) => {
      let response = [];
      for (let s in res) {
        res[s].time = new Date(res[s].updatedDate).getTime();
        if (res[s].status === 2) {
          response.push(res[s]);
        }
      }

      response.sort(function (a, b) {
        return b.time - a.time;
      });

      setProcesses(response);
    });
  }

  function loadCustomerProcesses() {
    loadProcessDone();
  }

  return (
    <Modal
      title="Processos concluídos"
      show={show}
      onHide={onHide}
      onSubmit={() => onHide()}
    >
      <div className="block-header">
        <div className="block-title">
          <div className="form-group">
            <span>Carregar :</span>
            <select
              className="form-control"
              value={days}
              onChange={(evt) => setDays(parseInt(evt.target.value))}
            >
              <option value={7}>Últimos 7 dias</option>
              <option value={30}>Últimos 30 dias</option>
              <option value={300}>Todos os processos</option>
            </select>
          </div>
          <div className="form-group py-3">
            <button
              className="btn btn-sm btn-info"
              onClick={() => loadCustomerProcesses()}
            >
              Consultar
            </button>
          </div>
        </div>
      </div>
      <div className="block-content">
        {processes &&
          processes.map((item) => (
            <div className="block block-fx-pop">
              <div className="block-header">
                <div className="block-title">
                  Processo - {item && item.processName}
                </div>
                <div className="block-tools">
                  Data de finalização - {DateTranslateSimple(item.updatedDate)}
                  <button
                    className="btn btn-sm btn-dual"
                    onClick={() =>
                      open({ data: { ...item, processId: item.id } })
                    }
                  >
                    <i className="fa fa-eye" />
                  </button>
                </div>
              </div>
              {/* <div className="block-content">
                <div className="row">
                  <div className="col-md-8">{item.message}</div>
                  <div className="col-md-4 text-right"></div>
                </div>
              </div> */}
            </div>
          ))}
      </div>
    </Modal>
  );
}
