import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Storage from "@/app/core/util/Storage";
import CardItem from "@/app/core/layouts/main-components/CounterCardItem";
import FinancialEntries from "@/app/core/layouts/main-components/financial/FinancialEntriesCard";
import FinancialHelper from "@/app/core/util/helpers/FinancialHelper";
import CashFlowController from "@/app/core/base/controllers/CashFlowController";
import { MoneyMask } from "@/app/core/util/helpers/MoneyHelper";
import { SelectedServiceByDate } from "@/app/core/base/controllers/ServicesController";
import { CustomerList, CustomerSelect } from "@/app/core/base/controllers/CustomerController";
import { DateTranslateSimple, OnlyDateTranslate } from "@/app/core/util/DateTranslater";

export default function MainCustomer(props) {
  const [loading] = useState(false);
  const [data, setData] = useState([]);
  let customers = [
    {
      "Id": 1,
      "Name": "Victor Augusto",
      "Email": "victor_augusto_silva@hotmail.com",
      "CustomerCode": "123456",
      "Phone": "1133336660",
      "Whatsapp": "11996356358",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 28,
      "Name": "Renan",
      "Email": "renan@hasa.com.br",
      "CustomerCode": "0",
      "Phone": "11952389283",
      "Whatsapp": "11952389283",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 29,
      "Name": "Thais Amaral",
      "Email": "taaiiz.amaral@hotmail.com",
      "CustomerCode": "0",
      "Phone": "1133336660",
      "Whatsapp": "11943892854",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 36,
      "Name": "Hga Contabilidade",
      "Email": "herondeabreu@hgacontabilidade.com.br",
      "CustomerCode": "17451",
      "Phone": "1138053522",
      "Whatsapp": "1197408446",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 37,
      "Name": "Contábil Correa Ltda.",
      "Email": "danilo@contabilcorrea.com.br",
      "CustomerCode": "17907",
      "Phone": "1123522227",
      "Whatsapp": "11947152050",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 38,
      "Name": "JMC Contabilidade",
      "Email": "contabilidadejmc@uol.com.br",
      "CustomerCode": "18663",
      "Phone": "1125034787",
      "Whatsapp": "11983016269",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 39,
      "Name": "Marcelo Augusto de Barros",
      "Email": "vivianeramos@fortes.adv.br",
      "CustomerCode": "18752",
      "Phone": "1131492000",
      "Whatsapp": "11989838532",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 40,
      "Name": "Lourenção Soluções Contábeis Ltda.",
      "Email": "expediente@lourencaoassessoria.com.br",
      "CustomerCode": "18949",
      "Phone": "1135560801",
      "Whatsapp": "11999047735",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 41,
      "Name": "Controller Assessoria Contábil S/C Ltda.",
      "Email": "ctrl@uol.com.br",
      "CustomerCode": "38138",
      "Phone": "1141231319",
      "Whatsapp": "11996798100",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 42,
      "Name": "Ballotin Assessoria Em Administração Tributária ",
      "Email": "marcelo@ballotin.adv.br",
      "CustomerCode": "38512",
      "Phone": "1141214216",
      "Whatsapp": "11968380667",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 43,
      "Name": "Tmfl da Silva Processamento de Dados",
      "Email": "juridico@asconsystems.com.br",
      "CustomerCode": "61377",
      "Phone": "1236693303",
      "Whatsapp": "12987049796",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 44,
      "Name": "Jbquality Contabilidade Ltda.",
      "Email": "legalizacao@jbqualitycontabilidade.com.br",
      "CustomerCode": "64976",
      "Phone": "4430341704",
      "Whatsapp": "44999915653",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 45,
      "Name": "Auditing Auditoria e Contabilidade",
      "Email": "bigarelli@contabilidadebigarelli.com.br",
      "CustomerCode": "37125",
      "Phone": "1147030810",
      "Whatsapp": "11999386275",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 46,
      "Name": "Gouvêa Consultoria",
      "Email": "gouveaconsult@uol.com.br",
      "CustomerCode": "15004",
      "Phone": "1135864030",
      "Whatsapp": "1196234038",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 47,
      "Name": "Premier Assessoria",
      "Email": "felmar@uol.com.br",
      "CustomerCode": "15021",
      "Phone": "1133923277",
      "Whatsapp": "1199854863",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 48,
      "Name": "Luiz Antônio Correia da Silva",
      "Email": "luiz@luancontabilidade.com.br",
      "CustomerCode": "15023",
      "Phone": "1137821677",
      "Whatsapp": "11998910878",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 49,
      "Name": "Contábil Borges",
      "Email": "contabilborges@terra.com.br",
      "CustomerCode": "15029",
      "Phone": "1150712047",
      "Whatsapp": "1141758455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 50,
      "Name": "Compass Serviços de Digitação Ltda ME",
      "Email": "paralegal@compass.adm.br",
      "CustomerCode": "15031",
      "Phone": "1137272535",
      "Whatsapp": "1147091192",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 51,
      "Name": "Am Contabilidade SS Ltda",
      "Email": "samuel@amcontabilidadebrasil.com.br",
      "CustomerCode": "15038",
      "Phone": "1126515255",
      "Whatsapp": "1173189905",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 52,
      "Name": "Sonia Tabosa Trevisani",
      "Email": "soniatabosa@uol.com.br",
      "CustomerCode": "15049",
      "Phone": "1123520014",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 53,
      "Name": "Mdbaro Serviços Contábeis Ltda-ME",
      "Email": "valter@barometro.com.br",
      "CustomerCode": "15053",
      "Phone": "1129516361",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 54,
      "Name": "Celso Irineu de Andrade Fragoso",
      "Email": "lucel266@terra.com.br",
      "CustomerCode": "15058",
      "Phone": "1139795979",
      "Whatsapp": "1173308314",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 55,
      "Name": "Tecnomidia Serviços S/C Ltda.",
      "Email": "contato@tecnomidia.com.br",
      "CustomerCode": "15059",
      "Phone": "1133621199",
      "Whatsapp": "1199474292",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 56,
      "Name": "Valdemir Freire de Menezes",
      "Email": "vfmenezes@gmail.com",
      "CustomerCode": "15067",
      "Phone": "1139233908",
      "Whatsapp": "11982440069",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 58,
      "Name": "Ukei Assessoria Contábil e Empresarial",
      "Email": "ricardo@ukei.com.br",
      "CustomerCode": "15093",
      "Phone": "1122722511",
      "Whatsapp": "1199518018",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 59,
      "Name": "Contass C&M Contabilidade e Assessoria Ltda. - ME",
      "Email": "carlos@cmcontass.com.br",
      "CustomerCode": "15095",
      "Phone": "1138163625",
      "Whatsapp": "1198374394",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 60,
      "Name": "Senir dos Santos Soares",
      "Email": "soarescontabil@yahoo.com.br",
      "CustomerCode": "15096",
      "Phone": "1136283437",
      "Whatsapp": "1163485969",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 61,
      "Name": "Balfer Contábil S/S Limitada",
      "Email": "balferfiscal@hotmail.com",
      "CustomerCode": "15112",
      "Phone": "1133858390",
      "Whatsapp": "1132511948",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 62,
      "Name": "Srmendes Consultoria e Contabilidade Técnica Ltda.",
      "Email": "soniarmendes@terra.com.br",
      "CustomerCode": "15118",
      "Phone": "1137295091",
      "Whatsapp": "1191216923",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 63,
      "Name": "Contábil Soalheiro & Carneiro",
      "Email": "nancysec@terra.com.br",
      "CustomerCode": "15132",
      "Phone": "1129010056",
      "Whatsapp": "1172337843",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 64,
      "Name": "Aristides Sampaio Xavier Neto",
      "Email": "asxn@terra.com.br",
      "CustomerCode": "15134",
      "Phone": "1129093650",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 65,
      "Name": "Escritório Morata de Contabilidade Ltda.",
      "Email": "le.morata@terra.com.br",
      "CustomerCode": "15138",
      "Phone": "1138956836",
      "Whatsapp": "11939046372",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 66,
      "Name": "Escritório Contábil Vaz S/C Ltda.",
      "Email": "expediente.vaz@terra.com.br",
      "CustomerCode": "15147",
      "Phone": "1129491577",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 67,
      "Name": "Alis Organização Contábil Ltda.",
      "Email": "alis@aliscontabil.com.br",
      "CustomerCode": "15149",
      "Phone": "1122494133",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 68,
      "Name": "GG Contabilidade",
      "Email": "gggodoy@uol.com.br",
      "CustomerCode": "15150",
      "Phone": "1129734157",
      "Whatsapp": "1181806995",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 69,
      "Name": "Reginaldo Cabral Contabilidade",
      "Email": "cabral44@bol.com.br",
      "CustomerCode": "15153",
      "Phone": "1129503675",
      "Whatsapp": "1197774153",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 70,
      "Name": "Escritório Contábil Rebecchi",
      "Email": "fernanda@escritoriorebecchi.com.br",
      "CustomerCode": "15155",
      "Phone": "1129523555",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 71,
      "Name": "Simone Chandelier",
      "Email": "sisterscontabil@gmail.com",
      "CustomerCode": "15157",
      "Phone": "1122394774",
      "Whatsapp": "1193569625",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 72,
      "Name": "D & R Contábil S/C Ltda.",
      "Email": "patricia@drcontab.com.br",
      "CustomerCode": "15162",
      "Phone": "1139668456",
      "Whatsapp": "11991185138",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 73,
      "Name": "Aparecido José Vieira",
      "Email": "aparecidocontador@gmail.com",
      "CustomerCode": "15170",
      "Phone": "1144413729",
      "Whatsapp": "11953972526",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 74,
      "Name": "Teruo Ikeda",
      "Email": "contmotoike@gmail.com",
      "CustomerCode": "15173",
      "Phone": "1132294566",
      "Whatsapp": "1183463297",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 75,
      "Name": "Art Contábil Ltda.",
      "Email": "artcontabil@yahoo.com.br",
      "CustomerCode": "15186",
      "Phone": "1120912384",
      "Whatsapp": "1176051001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 76,
      "Name": "Wm Assessoria Empresarial Ltda.",
      "Email": "wmempresarial@bol.com.br",
      "CustomerCode": "15211",
      "Phone": "1121548456",
      "Whatsapp": "1147546212",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 77,
      "Name": "Mauro Kiyotake Hanagusku - ME",
      "Email": "maurocontabil@terra.com.br",
      "CustomerCode": "15227",
      "Phone": "1120289802",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 78,
      "Name": "W&A Service Gestão Administrativa Ltda. ME",
      "Email": "verasoares@weaservice.com.br",
      "CustomerCode": "15232",
      "Phone": "1126717288",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 79,
      "Name": "Nova Continental Assessoria Contábil SS Ltda.",
      "Email": "novacontinental@uol.com.br",
      "CustomerCode": "15248",
      "Phone": "1120982935",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 80,
      "Name": "Escritório Contábil Fátima Quintal",
      "Email": "quintalfatima00@gmail.com",
      "CustomerCode": "15264",
      "Phone": "1143069313",
      "Whatsapp": "1181248199",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 81,
      "Name": "Jkr Assessoria Contábil",
      "Email": "diretoria@taiyocontabilidade.com.br",
      "CustomerCode": "15275",
      "Phone": "1129584126",
      "Whatsapp": "1196253980",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 82,
      "Name": "Sancti Assessoria Contábil e Empresarial Ltda.",
      "Email": "fiscal@sanctiassessoria.com.br",
      "CustomerCode": "15282",
      "Phone": "1126104088",
      "Whatsapp": "1171089502",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 83,
      "Name": "Marcelo Bueno Terada",
      "Email": "terada@hotmail.com",
      "CustomerCode": "15283",
      "Phone": "1120930456",
      "Whatsapp": "1195637335",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 84,
      "Name": "Rocha Contabilidade",
      "Email": "roctar@terra.com.br",
      "CustomerCode": "15295",
      "Phone": "1155830583",
      "Whatsapp": "1198600139",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 85,
      "Name": "Samuel Lobo - Serviços Contábeis",
      "Email": "lobo.samuel@gmail.com",
      "CustomerCode": "15300",
      "Phone": "1122010771",
      "Whatsapp": "11986233520",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 86,
      "Name": "Dinamar Contábil S/C Ltda.",
      "Email": "renato@dinamar.com.br",
      "CustomerCode": "15306",
      "Phone": "1125780855",
      "Whatsapp": "1162070000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 87,
      "Name": "Marcos Gonçalves",
      "Email": "jusdata.sp@gmail.com",
      "CustomerCode": "15313",
      "Phone": "1120614040",
      "Whatsapp": "1176898812",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 88,
      "Name": "Del Rio Assessoria Contábil Ltda..",
      "Email": "mauricio@delriocontabilidade.com.br",
      "CustomerCode": "15323",
      "Phone": "1129467073",
      "Whatsapp": "11998769955",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 89,
      "Name": "André Luiz Crepaldi Poincaré - ME",
      "Email": "contato@awrcontabilidade.com.br",
      "CustomerCode": "15330",
      "Phone": "1122764448",
      "Whatsapp": "1199698803",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 90,
      "Name": "Contex Contabilidade & Associados",
      "Email": "keli@contex.com.br",
      "CustomerCode": "15333",
      "Phone": "1155639900",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 91,
      "Name": "Gonçalves & Associados Serviços Ltda.",
      "Email": "gestao@goncalvesesassociados.com.br",
      "CustomerCode": "15335",
      "Phone": "1155654580",
      "Whatsapp": "1152420157",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 92,
      "Name": "Triade Assessoria Empresarial",
      "Email": "triadecontabil@uol.com.br",
      "CustomerCode": "15340",
      "Phone": "1137943720",
      "Whatsapp": "1160865441",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 93,
      "Name": "Shiguenori Arakaki",
      "Email": "shigue@ourocontabil.com.br",
      "CustomerCode": "15345",
      "Phone": "1129714610",
      "Whatsapp": "11975681279",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 94,
      "Name": "High Assessoria Contábil",
      "Email": "high.sampa@terra.com.br",
      "CustomerCode": "15349",
      "Phone": "1155231703",
      "Whatsapp": "1130052785",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 95,
      "Name": "Escritório Contábil N.S. Sabará",
      "Email": "supercontabil@yahoo.com.br",
      "CustomerCode": "15351",
      "Phone": "1156134060",
      "Whatsapp": "1199728982",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 96,
      "Name": "Dc Assessoria e Consultoria Ltda.",
      "Email": "lsakuraba@uol.com.br",
      "CustomerCode": "15356",
      "Phone": "1138855049",
      "Whatsapp": "1198717455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 97,
      "Name": "Ji Contabilidade Ltda.",
      "Email": "jicontabilidade@uol.com.br",
      "CustomerCode": "15357",
      "Phone": "1150491323",
      "Whatsapp": "1185363928",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 98,
      "Name": "Orsolin Contabilistas Associados",
      "Email": "orsolincont@terra.com.br",
      "CustomerCode": "15364",
      "Phone": "1126398055",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 99,
      "Name": "Gilberto Leroza Junior",
      "Email": "lerozacontabilidade@hotmail.com",
      "CustomerCode": "15372",
      "Phone": "1155630664",
      "Whatsapp": "11978694277",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 100,
      "Name": "Carlos Piccinin",
      "Email": "contabildfs@gmail.com",
      "CustomerCode": "15376",
      "Phone": "1156312917",
      "Whatsapp": "1191868005",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 101,
      "Name": "Valter Lopes",
      "Email": "val.contabil@terra.com.br",
      "CustomerCode": "15400",
      "Phone": "1156318564",
      "Whatsapp": "1152506592",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 102,
      "Name": "Dinâmica Organização Contábil Eireli",
      "Email": "gina@dinamicaorg.cnt.br",
      "CustomerCode": "15406",
      "Phone": "1155216028",
      "Whatsapp": "1191784559",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 103,
      "Name": "DH2 Sistema Contabilidade",
      "Email": "ybhidak@globo.com",
      "CustomerCode": "15415",
      "Phone": "1156665355",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 104,
      "Name": "Carrara Contabilidade",
      "Email": "andreluis_jc@hotmail.com",
      "CustomerCode": "15418",
      "Phone": "",
      "Whatsapp": "1165584357",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 105,
      "Name": "Roseli de Matos Azevedo Ikeda",
      "Email": "roseli.acft@gmail.com",
      "CustomerCode": "15424",
      "Phone": "1156620930",
      "Whatsapp": "1196085586",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 106,
      "Name": "José Carlos Freitas da Cruz",
      "Email": "jcmoreira@uol.com.br",
      "CustomerCode": "15427",
      "Phone": "1155150164",
      "Whatsapp": "1193138504",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 107,
      "Name": "B&A Contabilidade Ltda. EPP",
      "Email": "bassociados@yahoo.com.br",
      "CustomerCode": "15435",
      "Phone": "1138654092",
      "Whatsapp": "1183289700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 108,
      "Name": "MPS Office Assessoria",
      "Email": "manuel_afonso@uol.com.br",
      "CustomerCode": "15437",
      "Phone": "1142798796",
      "Whatsapp": "1184650172",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 109,
      "Name": "Assepeme Assistência Contábil S/C Ltda.",
      "Email": "assepeme@terra.com.br",
      "CustomerCode": "15452",
      "Phone": "1136213080",
      "Whatsapp": "11942243080",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 110,
      "Name": "Perus Proc. de Dados e Serv. Comerciais Ltda. - ME",
      "Email": "simone.adm@contabilidademartins.com.br",
      "CustomerCode": "15458",
      "Phone": "1139153645",
      "Whatsapp": "11994518184",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 111,
      "Name": "R.C. Escritório Contábil",
      "Email": "rcirera@terra.com.br",
      "CustomerCode": "15461",
      "Phone": "1137148149",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 112,
      "Name": "Hélio Kazuo Yosidaki",
      "Email": "helioky@hotmail.com",
      "CustomerCode": "15463",
      "Phone": "1125063340",
      "Whatsapp": "11991074241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 113,
      "Name": "Andreia Cristiane D Agostini",
      "Email": "andreiaagostini1@outlook.com",
      "CustomerCode": "15464",
      "Phone": "1138125771",
      "Whatsapp": "1193768164",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 114,
      "Name": "Francisco de Souza Kanaan",
      "Email": "sandra@kanaan.com.br",
      "CustomerCode": "15465",
      "Phone": "1138152153",
      "Whatsapp": "1199989687",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 115,
      "Name": "José Carlos Contabilidade",
      "Email": "maranhata@uol.com.br",
      "CustomerCode": "15477",
      "Phone": "1137467672",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 116,
      "Name": "Urasaki Assessoria Contábil S/C Ltda.",
      "Email": "urasakimsu@gmail.com",
      "CustomerCode": "15479",
      "Phone": "1137469753",
      "Whatsapp": "1199941630",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 117,
      "Name": "Elsa Caetano Pereira",
      "Email": "elsaglaucia@gmail.com",
      "CustomerCode": "15486",
      "Phone": "1158412416",
      "Whatsapp": "1158955646",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 119,
      "Name": "Orsales Contabilidade S/C Ltda.",
      "Email": "dita@orsales.com.br",
      "CustomerCode": "15508",
      "Phone": "1130589901",
      "Whatsapp": "1173352544",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 120,
      "Name": "Assessoria Contábil e Fgioscal Flamingo",
      "Email": "contabilflamingo@gmail.com",
      "CustomerCode": "15521",
      "Phone": "1122050524",
      "Whatsapp": "1133249199",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 121,
      "Name": "Organização Contábil Oliveira",
      "Email": "cont.seg@terra.com.br",
      "CustomerCode": "15522",
      "Phone": "1127481624",
      "Whatsapp": "11992328339",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 122,
      "Name": "Marcio - Serviços Contábeis S/C Ltda.",
      "Email": "mscontabeis@terra.com.br",
      "CustomerCode": "15524",
      "Phone": "1129190646",
      "Whatsapp": "1191878224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 123,
      "Name": "Cetec Organização Contábil",
      "Email": "ceteccontabilidade@yahoo.com.br",
      "CustomerCode": "15536",
      "Phone": "1131042943",
      "Whatsapp": "1198199769",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 124,
      "Name": "Ghrs Consultoria Contábil Eireli ME",
      "Email": "legalizacao@ghrs.com.br",
      "CustomerCode": "15554",
      "Phone": "1139016427",
      "Whatsapp": "11974724215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 125,
      "Name": "Marcos Antônio Fiorita",
      "Email": "marcosfiorita@hotmail.com",
      "CustomerCode": "15562",
      "Phone": "1139551100",
      "Whatsapp": "1182592250",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 126,
      "Name": "Edino José Cairrão",
      "Email": "edinocairrao1@gmail.com",
      "CustomerCode": "15566",
      "Phone": "1137689642",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 127,
      "Name": "Ética Assessoria Contábil",
      "Email": "etica.contato@gmail.com",
      "CustomerCode": "15571",
      "Phone": "1139813290",
      "Whatsapp": "1176285893",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 128,
      "Name": "Donato Gaeta Filho",
      "Email": "dogaeta@hotmail.com",
      "CustomerCode": "15583",
      "Phone": "1155618022",
      "Whatsapp": "11992022769",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 129,
      "Name": "Franco Ass. Auditores. Independentes S/C Ltda.",
      "Email": "francoai@terra.com.br",
      "CustomerCode": "15585",
      "Phone": "1132622740",
      "Whatsapp": "1176334419",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 130,
      "Name": "Caçula Contabilidade",
      "Email": "caculacontabilidade@terra.com.br",
      "CustomerCode": "15593",
      "Phone": "1125816687",
      "Whatsapp": "11993027525",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 131,
      "Name": "Paulo Cesar de Carvalho",
      "Email": "paulocarvalho@rbmulti.com.br",
      "CustomerCode": "15596",
      "Phone": "1150112829",
      "Whatsapp": "1181995093",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 132,
      "Name": "Francisco Ivan Alves Bezerra",
      "Email": "franivan007@hotmail.com",
      "CustomerCode": "15598",
      "Phone": "1122967781",
      "Whatsapp": "11973752264",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 133,
      "Name": "Iervolino Assessoria Contábil S/C Ltda.",
      "Email": "Rcleitao.seguros@gmail.com",
      "CustomerCode": "15609",
      "Phone": "1122583416",
      "Whatsapp": "11975273797",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 134,
      "Name": "Nilson Pereira dos Santos",
      "Email": "skcontabil@uol.com.br",
      "CustomerCode": "15610",
      "Phone": "1734451813",
      "Whatsapp": "11996361722",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 135,
      "Name": "Probil Processamento Fisco Contábil S/C",
      "Email": "probilprocessamento@yahoo.com.br",
      "CustomerCode": "15619",
      "Phone": "1123840963",
      "Whatsapp": "11973331412",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 136,
      "Name": "ILS Contabilidade S/S",
      "Email": "legal@ilscontabilidade.com.br",
      "CustomerCode": "15627",
      "Phone": "1155811704",
      "Whatsapp": "1188885001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 137,
      "Name": "Angra Contabilidade Assessoria Fiscal S/C Ltda.",
      "Email": "diretoria@angracontabilidade.com.br",
      "CustomerCode": "15629",
      "Phone": "1129502080",
      "Whatsapp": "11976445232",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 138,
      "Name": "World Contabilidade Ltda.",
      "Email": "contato@worldcont.com.br",
      "CustomerCode": "15638",
      "Phone": "1137195844",
      "Whatsapp": "11943094365",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 139,
      "Name": "Contabilidade Grajaú",
      "Email": "cvccontab@hotmail.com",
      "CustomerCode": "15643",
      "Phone": "1141131595",
      "Whatsapp": "1153525892",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 140,
      "Name": "Total 10 Serviços Contábeis Ltda.",
      "Email": "juvenal@totaldez.com.br",
      "CustomerCode": "15645",
      "Phone": "1138190269",
      "Whatsapp": "11991876459",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 141,
      "Name": "Tylson Tadeu Peres Camanho",
      "Email": "tylson@patrimoniun.com.br",
      "CustomerCode": "15647",
      "Phone": "1131672605",
      "Whatsapp": "11983260974",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 142,
      "Name": "Premier Soluções Contábeis Consult e Pericias Ltda.",
      "Email": "waltercs30@yahoo.com.br",
      "CustomerCode": "15654",
      "Phone": "1151805100",
      "Whatsapp": "1194380496",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 143,
      "Name": "Otatic Organização Técnica",
      "Email": "cesilva70@bol.com.br",
      "CustomerCode": "15655",
      "Phone": "1132667688",
      "Whatsapp": "1169756348",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 144,
      "Name": "Juscelino Ribeiro da Silva",
      "Email": "juscelinoribeiro@uol.com.br",
      "CustomerCode": "15663",
      "Phone": "1124412406",
      "Whatsapp": "1163374928",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 145,
      "Name": "LCR - Consultores Associados S/S Ltda.",
      "Email": "legalizacao@lcrcontadores.com.br",
      "CustomerCode": "15671",
      "Phone": "1150427500",
      "Whatsapp": "11932678511",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 146,
      "Name": "Organização Contábil Jamar",
      "Email": "contajam@uol.com.br",
      "CustomerCode": "15675",
      "Phone": "1127815933",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 147,
      "Name": "Contmax Ass. Contábil Empresarial",
      "Email": "contmaxcont@terra.com.br",
      "CustomerCode": "15681",
      "Phone": "1120264971",
      "Whatsapp": "11988729507",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 148,
      "Name": "Mwn Contabilidade Ltda.",
      "Email": "marcos@mwncontabilidade.com.br",
      "CustomerCode": "15700",
      "Phone": "1132946857",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 149,
      "Name": "Contabilidade Maki",
      "Email": "contabilmaki@terra.com.br",
      "CustomerCode": "15702",
      "Phone": "1122979519",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 151,
      "Name": "Antônio Celso dos Santos",
      "Email": "acs240559@gmail.com",
      "CustomerCode": "15706",
      "Phone": "1135629076",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 152,
      "Name": "Contábil e Adm Gouveia",
      "Email": "escritoriogouveia@hotmail.com",
      "CustomerCode": "15710",
      "Phone": "1129187211",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 153,
      "Name": "Matangrano & Silveira Assistência Contábil e Fiscal ltda ",
      "Email": "heloisa@matangrano.com.br",
      "CustomerCode": "15716",
      "Phone": "1155493822",
      "Whatsapp": "1191571523",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 154,
      "Name": "Pedro Francisco Coltri de Oliveira",
      "Email": "dpnovacon@terra.com.br",
      "CustomerCode": "15722",
      "Phone": "1125335602",
      "Whatsapp": "1186658840",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 155,
      "Name": "Maria Rosemeire Martins",
      "Email": "meire.martins@uol.com.br",
      "CustomerCode": "15738",
      "Phone": "1151836113",
      "Whatsapp": "1196314696",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 156,
      "Name": "Roberto Massoni",
      "Email": "henrique@roder.com.br",
      "CustomerCode": "15739",
      "Phone": "1150695900",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 157,
      "Name": "Direção Consultoria e Auditoria Contábil Eirele",
      "Email": "daisymontresor@uol.com.br",
      "CustomerCode": "15743",
      "Phone": "1155310782",
      "Whatsapp": "1199459560",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 158,
      "Name": "Conti Exata",
      "Email": "claudia.contiexata@terra.com.br",
      "CustomerCode": "15744",
      "Phone": "1136282766",
      "Whatsapp": "1176641597",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 159,
      "Name": "Danilo Oliveira Moraes",
      "Email": "danilomoraes@msn.com",
      "CustomerCode": "15746",
      "Phone": "1140755460",
      "Whatsapp": "1183827793",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 160,
      "Name": "Assessoria Contábil Monte Santo",
      "Email": "contadelino2015@gmail.com",
      "CustomerCode": "15751",
      "Phone": "1125463184",
      "Whatsapp": "11985453206",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 161,
      "Name": "Pacon Escritório Contábil",
      "Email": "pacarbas07@gmail.com",
      "CustomerCode": "15752",
      "Phone": "1122157662",
      "Whatsapp": "1138001243",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 162,
      "Name": "K Naruse Gestão Contábil",
      "Email": "knaruse@knaruse.com.br",
      "CustomerCode": "15755",
      "Phone": "1155811164",
      "Whatsapp": "1173833728",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 163,
      "Name": "Antônio Nivaldo de Matos",
      "Email": "denismatos@uol.com.br",
      "CustomerCode": "15758",
      "Phone": "1122117801",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 164,
      "Name": "José Antônio Braga",
      "Email": "bragactual@uol.com.br",
      "CustomerCode": "15759",
      "Phone": "1138578920",
      "Whatsapp": "1191151225",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 165,
      "Name": "Assessoria Contábil Vitoria",
      "Email": "assessoriacontabilvitoria@gmail.com",
      "CustomerCode": "15761",
      "Phone": "1156413499",
      "Whatsapp": "1199041224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 166,
      "Name": "Carminato Associados Auditoria Contabilidade Ltda.",
      "Email": "edson@carminatoassociados.com.br",
      "CustomerCode": "15763",
      "Phone": "1137294838",
      "Whatsapp": "1199452826",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 167,
      "Name": "CRJ Assessoria Empresarial - Eireli",
      "Email": "renato@mlc.com.br",
      "CustomerCode": "15766",
      "Phone": "1146451373",
      "Whatsapp": "11947945026",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 168,
      "Name": "Falcirolli Contabilidade Ltda.",
      "Email": "mariana@falcirolli.com.br",
      "CustomerCode": "15781",
      "Phone": "1129816677",
      "Whatsapp": "1171121959",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 169,
      "Name": "Sancor Assessoria Contábil Empres S/C Ltda.",
      "Email": "priscila@sancorsp.com.br",
      "CustomerCode": "15788",
      "Phone": "1135963144",
      "Whatsapp": "11992720145",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 170,
      "Name": "Israel Antônio Cintra Correa",
      "Email": "israel@consultmega.com.br",
      "CustomerCode": "15801",
      "Phone": "1132225776",
      "Whatsapp": "1182819696",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 171,
      "Name": "Mc - Marim Contábil",
      "Email": "marimcontab@uol.com.br",
      "CustomerCode": "15802",
      "Phone": "1120619212",
      "Whatsapp": "1192367086",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 172,
      "Name": "Mark-Up Serviços S/C Ltda.",
      "Email": "lho.negocios@gmail.com",
      "CustomerCode": "15811",
      "Phone": "11",
      "Whatsapp": "11984130149",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 173,
      "Name": "GAE - Grupo de Asses Empresarial SC Ltda.",
      "Email": "gaecont@terra.com.br",
      "CustomerCode": "15826",
      "Phone": "1146199930",
      "Whatsapp": "11982992143",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 174,
      "Name": "Múltipla Assessoria Contabilidade Ltda.",
      "Email": "mauro@multipla-rnc.com.br",
      "CustomerCode": "15828",
      "Phone": "1127905700",
      "Whatsapp": "1181963842",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 175,
      "Name": "Hb Machado Contabilidade ME",
      "Email": "venezacontabil@terra.com.br",
      "CustomerCode": "15837",
      "Phone": "1143728344",
      "Whatsapp": "1174301500",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 176,
      "Name": "Clama Organização Contábil SC Ltda.",
      "Email": "fernando.clama@gmail.com",
      "CustomerCode": "15845",
      "Phone": "1124408259",
      "Whatsapp": "1140347568",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 177,
      "Name": "T M C Assessoria Contábil",
      "Email": "tmcinternacional@gmail.com",
      "CustomerCode": "15849",
      "Phone": "1123655179",
      "Whatsapp": "11947616694",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 178,
      "Name": "Razão Organização Contábil",
      "Email": "razao@razaorazec.com.br",
      "CustomerCode": "19322",
      "Phone": "1125487402",
      "Whatsapp": "1176256778",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 179,
      "Name": "Escritório Contábil Câmara",
      "Email": "contabilcamara1212@gmail.com",
      "CustomerCode": "15852",
      "Phone": "1156672157",
      "Whatsapp": "1191916063",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 181,
      "Name": "Darjil Contábil S/C Ltda.",
      "Email": "jair2001@uol.com.br",
      "CustomerCode": "15892",
      "Phone": "1122032183",
      "Whatsapp": "1199687491",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 182,
      "Name": "José Aparecido Ramos",
      "Email": "zerramos1cartunista@gmail.com",
      "CustomerCode": "15896",
      "Phone": "1129697194",
      "Whatsapp": "1194041492",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 183,
      "Name": "Mullers Contabilidade S/C Ltda.",
      "Email": "contato@mullers.com.br",
      "CustomerCode": "15897",
      "Phone": "1133615133",
      "Whatsapp": "1183589750",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 184,
      "Name": "Aniete Thereza Gaglize Zanca Parziale Rodrigues",
      "Email": "financeiro@parzialecontabilidade.com.br",
      "CustomerCode": "15918",
      "Phone": "1122976654",
      "Whatsapp": "11947844636",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 185,
      "Name": "Digittus Contabilidade e Digitalização Ltda.",
      "Email": "contabilar@gmail.com",
      "CustomerCode": "15921",
      "Phone": "1137313013",
      "Whatsapp": "11999777959",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 186,
      "Name": "Xyz Consultoria Contábil S/C Ltda.",
      "Email": "andreza.sangregorio@xyzconsultoria.com",
      "CustomerCode": "15945",
      "Phone": "1132859000",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 187,
      "Name": "Isao Yasukawa Contabilidade",
      "Email": "chenrique@consultcontabil.cnt.br",
      "CustomerCode": "15947",
      "Phone": "1126464911",
      "Whatsapp": "1147552299",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 188,
      "Name": "João Pellegrini Neto",
      "Email": "jpell@bol.com.br",
      "CustomerCode": "15956",
      "Phone": "1129667066",
      "Whatsapp": "1193628372",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 189,
      "Name": "Orconfis - Consultoria Contábil Tributária SC Ltda.",
      "Email": "orconfis@terra.com.br",
      "CustomerCode": "15966",
      "Phone": "1132422584",
      "Whatsapp": "1199606911",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 190,
      "Name": "Semac Assessoria Contábil SC Ltda.",
      "Email": "roberto@semaccontabilidade.com.br",
      "CustomerCode": "15973",
      "Phone": "1120638777",
      "Whatsapp": "1174647109",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 191,
      "Name": "Sonia Maria Vieira Sarmento",
      "Email": "soniasarmento@uol.com.br",
      "CustomerCode": "15976",
      "Phone": "1137680099",
      "Whatsapp": "1197522853",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 192,
      "Name": "Mfc Assessoria Contábil Simples Ltda.",
      "Email": "mfc.contabil@uol.com.br",
      "CustomerCode": "15981",
      "Phone": "1123020320",
      "Whatsapp": "1190100470",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 193,
      "Name": "Sdn Contabilidade Serv Administrativos",
      "Email": "sdncontabil@terra.com.br",
      "CustomerCode": "15984",
      "Phone": "1139013233",
      "Whatsapp": "1198906455",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 194,
      "Name": "Valmir Arlete Gonçalves",
      "Email": "valmir.tchen@terra.com.br",
      "CustomerCode": "15996",
      "Phone": "1129614426",
      "Whatsapp": "11969386464",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 195,
      "Name": "Maria Cristina Ferreira",
      "Email": "cris.somater@hotmail.com",
      "CustomerCode": "16028",
      "Phone": "1139236130",
      "Whatsapp": "11999085614",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 196,
      "Name": "Offycyo Consultoria e Assessoria Contábil SC Ltda.",
      "Email": "valmir@offycyo.com.br",
      "CustomerCode": "16031",
      "Phone": "1155734266",
      "Whatsapp": "1192157833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 197,
      "Name": "Marco Venceslau dos Reis",
      "Email": "mvrassessoria2@gmail.com",
      "CustomerCode": "16033",
      "Phone": "1138569609",
      "Whatsapp": "1182932851",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 198,
      "Name": "Otacílio Akira Tamaki",
      "Email": "oatamaki@terra.com.br",
      "CustomerCode": "16049",
      "Phone": "1150491323",
      "Whatsapp": "1181112321",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 199,
      "Name": "Vaz Contabilidade",
      "Email": "grupovaz@terra.com.br",
      "CustomerCode": "16059",
      "Phone": "1122832727",
      "Whatsapp": "11993157440",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 200,
      "Name": "Consultar Auditoria Cons Contabilidade SC Ltda.",
      "Email": "iraci@consultarcontabilidade.com.br",
      "CustomerCode": "16068",
      "Phone": "1129587011",
      "Whatsapp": "1147424537",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 201,
      "Name": "Peceuss Escritório Contábil Ltda.",
      "Email": "mahap@terra.com.br",
      "CustomerCode": "16085",
      "Phone": "1150314882",
      "Whatsapp": "1163223771",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 202,
      "Name": "Cardoso & Garisto Serv. Contábeis Ltda-ME",
      "Email": "acgaristo@garisto.com.br",
      "CustomerCode": "16091",
      "Phone": "1155151055",
      "Whatsapp": "1168334191",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 203,
      "Name": "Francisco Trevinho",
      "Email": "f.trevinho@uol.com.br",
      "CustomerCode": "16107",
      "Phone": "1127817797",
      "Whatsapp": "1197254545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 204,
      "Name": "Contucci Consultoria Contábil SC Ltda.",
      "Email": "contucci@uol.com.br",
      "CustomerCode": "16115",
      "Phone": "1138629425",
      "Whatsapp": "1196483678",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 205,
      "Name": "Tj Assessoria Contábil",
      "Email": "tjassescont@gmail.com",
      "CustomerCode": "16117",
      "Phone": "1126383605",
      "Whatsapp": "1198127473",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 206,
      "Name": "Ana Maria de Mello Araújo",
      "Email": "anamelloaraujo@hotmail.com",
      "CustomerCode": "16121",
      "Phone": "1122683652",
      "Whatsapp": "1199783341",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 207,
      "Name": "Direcional Assessoria Contábil S/S Ltda.",
      "Email": "paulo@dadivacontabilidade.com.br",
      "CustomerCode": "16136",
      "Phone": "1132580538",
      "Whatsapp": "1192351985",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 208,
      "Name": "Monte Alegre Assessoria Contábil Ltda.",
      "Email": "contaibi@terra.com.br",
      "CustomerCode": "16143",
      "Phone": "1122767052",
      "Whatsapp": "1166915462",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 209,
      "Name": "Klg Consultores Associados SC Ltda.",
      "Email": "lemos@klg.com.br",
      "CustomerCode": "16145",
      "Phone": "1132146462",
      "Whatsapp": "1172930442",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 210,
      "Name": "Assessoria Contábil Castro Alves",
      "Email": "diretoria@castroalvescontabilidade.com.br",
      "CustomerCode": "16167",
      "Phone": "1172209411",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 211,
      "Name": "Liberdade Serviços Contábeis Ltda.",
      "Email": "silvia@liberdade-contabil.com.br",
      "CustomerCode": "16175",
      "Phone": "1155490340",
      "Whatsapp": "1199513752",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 212,
      "Name": "Dj Orientadora Contábil Fiscal Tributária SC Ltda.",
      "Email": "djorient@terra.com.br",
      "CustomerCode": "16177",
      "Phone": "1122088221",
      "Whatsapp": "11991221023",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 213,
      "Name": "Fiscon Assessoria Fiscal e Contábil SC Ltda.",
      "Email": "financeiro@fiscon.com.br",
      "CustomerCode": "16186",
      "Phone": "1155644059",
      "Whatsapp": "1199728474",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 214,
      "Name": "Paulo Lucena",
      "Email": "pvlucena@terra.com.br",
      "CustomerCode": "16193",
      "Phone": "1164613191",
      "Whatsapp": "11964613191",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 215,
      "Name": "Rr Assessoria Contábil SC Ltda.",
      "Email": "freitasrr@rrconsultoria.com.br",
      "CustomerCode": "16202",
      "Phone": "1138657308",
      "Whatsapp": "1196005517",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 216,
      "Name": "Mauricio Silva Onofre",
      "Email": "masion1951@gmail.com",
      "CustomerCode": "16204",
      "Phone": "1126598421",
      "Whatsapp": "11985366289",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 217,
      "Name": "Contabilidade Brito",
      "Email": "contabilidadebrito2011@hotmail.com",
      "CustomerCode": "16216",
      "Phone": "1138361489",
      "Whatsapp": "1172637843",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 218,
      "Name": "On Assessoria Contábil Ltda.",
      "Email": "noshiroassessoria@gmail.com",
      "CustomerCode": "16222",
      "Phone": "1122391821",
      "Whatsapp": "1194545454",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 219,
      "Name": "Organização Contábil Mercantil SC Ltda.",
      "Email": "organizacaomercantil@terra.com.br",
      "CustomerCode": "16235",
      "Phone": "1120910318",
      "Whatsapp": "1193968730",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 220,
      "Name": "Dgm Consultoria S/C Ltda.",
      "Email": "carlos.rm2011@gmail.com",
      "CustomerCode": "16239",
      "Phone": "1195657029",
      "Whatsapp": "1195657029",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 221,
      "Name": "Comava Contabilidade SS EPP",
      "Email": "comavacontabil@comavacontabil.com.br",
      "CustomerCode": "16240",
      "Phone": "1158121452",
      "Whatsapp": "11948495601",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 222,
      "Name": "Jireh & Nissi Contabilidade",
      "Email": "contabilidadejireh.1986@gmail.com",
      "CustomerCode": "16252",
      "Phone": "1138623862",
      "Whatsapp": "1198500345",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 223,
      "Name": "Adage Contabilidade",
      "Email": "so_coelho@hotmail.com",
      "CustomerCode": "16259",
      "Phone": "1155600861",
      "Whatsapp": "1192350857",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 224,
      "Name": "Módulo Assessoria Empresarial SC Ltda.",
      "Email": "terezamarrafa@uol.com.br",
      "CustomerCode": "16278",
      "Phone": "1129875895",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 225,
      "Name": "Montana Contábil Assessoria Consultoria SC Ltda.",
      "Email": "montacom@hotmail.com",
      "CustomerCode": "16279",
      "Phone": "1155813135",
      "Whatsapp": "1199115805",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 226,
      "Name": "Sol Escritório Contábil Eireli",
      "Email": "contabilsol@terra.com.br",
      "CustomerCode": "16287",
      "Phone": "1139045447",
      "Whatsapp": "1199881551",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 227,
      "Name": "Escritório Contábil Mac",
      "Email": "adriano@maccontabil.com.br",
      "CustomerCode": "16294",
      "Phone": "113772637",
      "Whatsapp": "11941740216",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 228,
      "Name": "Cleide Aparecida Ferreira Manduca Muniz",
      "Email": "nova-contt@uol.com.br",
      "CustomerCode": "16326",
      "Phone": "1120244722",
      "Whatsapp": "1158782451",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 229,
      "Name": "Paulo Sergio Ferreira",
      "Email": "solucontab@terra.com.br",
      "CustomerCode": "16341",
      "Phone": "1131205458",
      "Whatsapp": "1199092679",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 230,
      "Name": "Partner Adm. e Serv. S/C Ltda.",
      "Email": "partneradm@terra.com.br",
      "CustomerCode": "16376",
      "Phone": "1133415810",
      "Whatsapp": "1397001021",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 231,
      "Name": "Escritório Contábil Alborghete",
      "Email": "jalborgh@uol.com.br",
      "CustomerCode": "16385",
      "Phone": "1122417404",
      "Whatsapp": "1175172777",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 232,
      "Name": "Gm Assessoria Contábil",
      "Email": "gm_contabil@hotmail.com",
      "CustomerCode": "16389",
      "Phone": "1122165966",
      "Whatsapp": "1191596776",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 233,
      "Name": "Júlio Tomozo Ishibashi",
      "Email": "jotaishibashi@gmail.com",
      "CustomerCode": "16395",
      "Phone": "1125320206",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 234,
      "Name": "Alb Contabilidade Integra",
      "Email": "ademir.alb@gmail.com",
      "CustomerCode": "16409",
      "Phone": "1131298322",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 235,
      "Name": "Contabilidade Antiquera Seles",
      "Email": "contato@trinocont.com.br",
      "CustomerCode": "16419",
      "Phone": "1129180357",
      "Whatsapp": "1175810357",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 236,
      "Name": "Wascon Soluções Empresariais Eireli",
      "Email": "wesley@wascon.com.br",
      "CustomerCode": "16438",
      "Phone": "1137352829",
      "Whatsapp": "11972424500",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 237,
      "Name": "Arm Force Serviços de Apoio Ltda.",
      "Email": "contabil@armservicos.com.br",
      "CustomerCode": "16439",
      "Phone": "1129704380",
      "Whatsapp": "11963043516",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 238,
      "Name": "Carlos Otávio Cabral Caetano",
      "Email": "co.caetano@uol.com.br",
      "CustomerCode": "16449",
      "Phone": "1137191928",
      "Whatsapp": "11997482824",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 239,
      "Name": "Associação Cultura Inglesa - São Paulo",
      "Email": "luis.tiburcio@culturainglesa.com.br",
      "CustomerCode": "16452",
      "Phone": "1130390564",
      "Whatsapp": "11971173925",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 240,
      "Name": "Ricardo Pacheco",
      "Email": "riwacontabil@terra.com.br",
      "CustomerCode": "16453",
      "Phone": "1127421742",
      "Whatsapp": "1197432433",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 241,
      "Name": "Carla Borges Bertin",
      "Email": "carla.bertin@outlook.com",
      "CustomerCode": "16454",
      "Phone": "11",
      "Whatsapp": "1182029419",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 242,
      "Name": "Jorge Luiz Ribeiro de Miranda",
      "Email": "jorgermiranda@bol.com.br",
      "CustomerCode": "16470",
      "Phone": "1120251214",
      "Whatsapp": "11982453305",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 243,
      "Name": "Lcm Assessoria Consultoria Contábil",
      "Email": "lcmcontabil@uol.com.br",
      "CustomerCode": "16475",
      "Phone": "1127851639",
      "Whatsapp": "1175730355",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 244,
      "Name": "Plaufic Contabilidade Fiscais S/C Ltda.",
      "Email": "plaufic@plaufic.com.br",
      "CustomerCode": "16480",
      "Phone": "1132285889",
      "Whatsapp": "1197292936",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 245,
      "Name": "Saconff Serviços Adm Ltda ME",
      "Email": "saconff@uol.com.br",
      "CustomerCode": "16499",
      "Phone": "1156660950",
      "Whatsapp": "11986937281",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 247,
      "Name": "Wvip Assessoria Empresarial",
      "Email": "wvip@uol.com.br",
      "CustomerCode": "16538",
      "Phone": "1136273292",
      "Whatsapp": "1140091549",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 248,
      "Name": "Escritório Jc Arthur Contábil e Fiscal Ltda.",
      "Email": "jcarthur@jcarthur.com.br",
      "CustomerCode": "16545",
      "Phone": "1129090470",
      "Whatsapp": "11993153802",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 249,
      "Name": "Dsc Consultores & Associados",
      "Email": "dsclaw@gmail.com",
      "CustomerCode": "16549",
      "Phone": "113229264",
      "Whatsapp": "1183664415",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 250,
      "Name": "Afp Serviços Gerais de Escritório Ltda. - ME",
      "Email": "afppereira62@gmail.com",
      "CustomerCode": "16554",
      "Phone": "1156147669",
      "Whatsapp": "1192549213",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 251,
      "Name": "Organização Pupo",
      "Email": "contato@contabilidadepupo.com.br",
      "CustomerCode": "16571",
      "Phone": "1132210518",
      "Whatsapp": "11933336349",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 252,
      "Name": "Escritório Contábil Jaguari",
      "Email": "jaguari@uol.com.br",
      "CustomerCode": "16575",
      "Phone": "1129125181",
      "Whatsapp": "1157851301",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 253,
      "Name": "Avir Contabilidade",
      "Email": "avir@avir.com.br",
      "CustomerCode": "16576",
      "Phone": "1139354477",
      "Whatsapp": "11998329285",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 254,
      "Name": "Planad Consultoria Organização e Assessoria",
      "Email": "fcfcontabil@gmail.com",
      "CustomerCode": "16595",
      "Phone": "1122157829",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 255,
      "Name": "Valdivino Alves de Sousa",
      "Email": "alvescontabilidade@uol.com.br",
      "CustomerCode": "16598",
      "Phone": "1132299277",
      "Whatsapp": "1196083728",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 256,
      "Name": "Aparecido Alcides Bandeira",
      "Email": "aab@contband.com.br",
      "CustomerCode": "16608",
      "Phone": "1155662299",
      "Whatsapp": "11982620308",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 257,
      "Name": "Pancrom Indústria Gráfica Ltda.",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "16611",
      "Phone": "1133406922",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 258,
      "Name": "Rita de Cássia Silva Fernandes",
      "Email": "rclima2010@hotmail.com",
      "CustomerCode": "16632",
      "Phone": "1153683541",
      "Whatsapp": "1153683541",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 259,
      "Name": "Brain Assessoria SC Ltda.",
      "Email": "rubens.costa@braincapital.com.br",
      "CustomerCode": "16639",
      "Phone": "1132510755",
      "Whatsapp": "1181445925",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 260,
      "Name": "Escritório Molina de Contabilidade",
      "Email": "escritoriomolina787@gmail.com",
      "CustomerCode": "16650",
      "Phone": "",
      "Whatsapp": "1193190753",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 261,
      "Name": "Visão Contabilidade Ltda-ME",
      "Email": "legal@visaoae.com",
      "CustomerCode": "16659",
      "Phone": "1120917269",
      "Whatsapp": "11983638995",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 262,
      "Name": "Sennior Assessoria Empresarial S/S Ltda.",
      "Email": "renato.costa@sennior.srv.br",
      "CustomerCode": "16660",
      "Phone": "1131818440",
      "Whatsapp": "1198579553",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 263,
      "Name": "Veredas Contabilidade Ltda - ME",
      "Email": "robson@veredascontabil.com.br",
      "CustomerCode": "16663",
      "Phone": "1150411432",
      "Whatsapp": "11963033232",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 264,
      "Name": "Marcos Vinicius Moreira",
      "Email": "mvmservicoscontabeis@gmail.com",
      "CustomerCode": "16693",
      "Phone": "1133622685",
      "Whatsapp": "1189299321",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 265,
      "Name": "Contibem Assessoria Contábil Ltda.",
      "Email": "financeiro@contibem.com.br",
      "CustomerCode": "16740",
      "Phone": "1137981990",
      "Whatsapp": "11992273451",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 266,
      "Name": "Santos Nobre Assessoria Jurídica S.C",
      "Email": "heisla@santosnobre.com.br",
      "CustomerCode": "16741",
      "Phone": "1129529388",
      "Whatsapp": "1199703685",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 267,
      "Name": "Seven Contabilidade",
      "Email": "sevencont@terra.com.br",
      "CustomerCode": "16750",
      "Phone": "",
      "Whatsapp": "1194487441",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 268,
      "Name": "Tatsugiro Ioshioca",
      "Email": "consulti.tayo@uol.com.br",
      "CustomerCode": "16751",
      "Phone": "1150349507",
      "Whatsapp": "1192660502",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 269,
      "Name": "Organização Contábil Virtual",
      "Email": "orgvirtual@gmail.com",
      "CustomerCode": "16753",
      "Phone": "1146120852",
      "Whatsapp": "1197511638",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 270,
      "Name": "Mauricio Antônio da Silva",
      "Email": "fococonsultoria@globo.com",
      "CustomerCode": "16757",
      "Phone": "1129882129",
      "Whatsapp": "1150210767",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 271,
      "Name": "Fatima Fonseca Perez",
      "Email": "serprocon@globo.com",
      "CustomerCode": "16768",
      "Phone": "1127497622",
      "Whatsapp": "1158389691",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 272,
      "Name": "Digicont Organização Contábil Ltda.",
      "Email": "legalizacao@dgct.com.br",
      "CustomerCode": "16769",
      "Phone": "1150902020",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 273,
      "Name": "C L M- Medicina e Saúde Ocupacional S/C Ltda.",
      "Email": "anita@clm-med.com.br",
      "CustomerCode": "16791",
      "Phone": "1156836500",
      "Whatsapp": "1175415471",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 274,
      "Name": "Carlos Eduardo Ubeira Pereira Franco",
      "Email": "payroll.calculos@gmail.com",
      "CustomerCode": "16804",
      "Phone": "1155941081",
      "Whatsapp": "1192255559",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 275,
      "Name": "Paulo Fernando Ruiz",
      "Email": "ruizpfr@gmail.com",
      "CustomerCode": "16819",
      "Phone": "1123521547",
      "Whatsapp": "1198222181",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 276,
      "Name": "Eni da Silva",
      "Email": "feraro52@gmail.com",
      "CustomerCode": "16824",
      "Phone": "1139043538",
      "Whatsapp": "1154907316",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 277,
      "Name": "Rpm Informações e Soluções Empresarias Ltda-ME",
      "Email": "gruporpm.sp@gmail.com",
      "CustomerCode": "16827",
      "Phone": "1151818678",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 278,
      "Name": "Antônio Carlos Cesar",
      "Email": "seletivaconfisc@uol.com.br",
      "CustomerCode": "16828",
      "Phone": "1129625013",
      "Whatsapp": "1195706353",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 279,
      "Name": "Carlo Paccagnella",
      "Email": "carlo@valordonegocio.com.br",
      "CustomerCode": "16829",
      "Phone": "1141522908",
      "Whatsapp": "1181498548",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 280,
      "Name": "Master Assessoria Contábil e Tributária SC Ltda.",
      "Email": "secretaria_diretoria@master-servicos.com.br",
      "CustomerCode": "16840",
      "Phone": "1135672100",
      "Whatsapp": "1170273150",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 281,
      "Name": "Luiz Antônio Riqueza",
      "Email": "riquezaadvogados@hotmail.com",
      "CustomerCode": "16841",
      "Phone": "1139812487",
      "Whatsapp": "1191037926",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 282,
      "Name": "João Carlos da Silva",
      "Email": "joaocaconde@ymail.com",
      "CustomerCode": "16848",
      "Phone": "",
      "Whatsapp": "1180795966",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 283,
      "Name": "Seno Contábil SC Ltda.",
      "Email": "laseno@uol.com.br",
      "CustomerCode": "16849",
      "Phone": "1129966644",
      "Whatsapp": "1184399208",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 284,
      "Name": "Valmir Alves Bezerra",
      "Email": "valmircrc@gmail.com",
      "CustomerCode": "16862",
      "Phone": "1131238420",
      "Whatsapp": "1154636373",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 285,
      "Name": "Aruanda Suporte Empresarial Eireli",
      "Email": "diretoria@aruanda.com.br",
      "CustomerCode": "16873",
      "Phone": "1155851493",
      "Whatsapp": "11999356330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 286,
      "Name": "R.Cont Serviços Administrativos Ltda.",
      "Email": "rcont.adm@gmail.com",
      "CustomerCode": "16877",
      "Phone": "",
      "Whatsapp": "1170800483",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 287,
      "Name": "Silvio Cunha",
      "Email": "silvio@lordcontabilidade.com.br",
      "CustomerCode": "16904",
      "Phone": "1156658730",
      "Whatsapp": "1147828561",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 288,
      "Name": "Shirlei Nascimento",
      "Email": "shirlei0310@yahoo.com.br",
      "CustomerCode": "16920",
      "Phone": "1136250199",
      "Whatsapp": "1983227206",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 289,
      "Name": "Gilmar Sotero de Gouveia",
      "Email": "gilmargouveia@outlook.com",
      "CustomerCode": "16938",
      "Phone": "1120760961",
      "Whatsapp": "1187215471",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 290,
      "Name": "Rumo Certo Empresarial",
      "Email": "robertorumocertoempresarial@gmail.com",
      "CustomerCode": "16941",
      "Phone": "1155831296",
      "Whatsapp": "1191948566",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 291,
      "Name": "Cvn Assessoria Contábil S/C Ltda.",
      "Email": "koza@cvn.com.br",
      "CustomerCode": "16958",
      "Phone": "1136218582",
      "Whatsapp": "11995410001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 292,
      "Name": "Camila Dayane da Silva",
      "Email": "c_dayane@hotmail.com",
      "CustomerCode": "16961",
      "Phone": "1123793262",
      "Whatsapp": "1182914128",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 293,
      "Name": "Wmmb Assessoria e Negócios Ltda.",
      "Email": "woxavier@terra.com.br",
      "CustomerCode": "16981",
      "Phone": "1138532975",
      "Whatsapp": "1181767809",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 294,
      "Name": "Serfisco Serviço Fiscal e Contábil Ltda.",
      "Email": "serfisco@uol.com.br",
      "CustomerCode": "16992",
      "Phone": "1126926725",
      "Whatsapp": "1193435822",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 296,
      "Name": "Support Apoio & Soluções Empresariais S/C Ltda. ME",
      "Email": "jantonio@support-servicos.com.br",
      "CustomerCode": "17004",
      "Phone": "1126134595",
      "Whatsapp": "1151132341",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 297,
      "Name": "Amilton Cezar Angeloti",
      "Email": "contabangeloti@terra.com.br",
      "CustomerCode": "17007",
      "Phone": "1139997421",
      "Whatsapp": "1163923360",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 298,
      "Name": "Contábil Ozanam Ltda.",
      "Email": "ozanam@uol.com.br",
      "CustomerCode": "17010",
      "Phone": "1139763577",
      "Whatsapp": "1194750589",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 299,
      "Name": "Regiane Cristina da Silva Cunha",
      "Email": "regiane@contatosempresariais.com.br",
      "CustomerCode": "17012",
      "Phone": "1131594747",
      "Whatsapp": "1181646421",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 300,
      "Name": "Holoseg Importação e Comércio Ltda.",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "17018",
      "Phone": "1133406922",
      "Whatsapp": "11991511509",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 301,
      "Name": "Binário Distribuidora de Equipamentos Eletronicos",
      "Email": "cpereira@binarionet.com.br",
      "CustomerCode": "17028",
      "Phone": "1147040400",
      "Whatsapp": "1192222375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 302,
      "Name": "J.Rinaldi Assessoria Empresarial Ltda.",
      "Email": "jraempresarial@terra.com.br",
      "CustomerCode": "17029",
      "Phone": "1134762502",
      "Whatsapp": "1172420911",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 303,
      "Name": "CJ Contabilidade",
      "Email": "crz.contabilidade@hotmail.com",
      "CustomerCode": "17030",
      "Phone": "1129150018",
      "Whatsapp": "1147571651",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 304,
      "Name": "MARCOS PAULO FERREIRA VALERIO",
      "Email": "marcosp@integradora.com.br",
      "CustomerCode": "17040",
      "Phone": "11946571466",
      "Whatsapp": "11946571466",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 305,
      "Name": "Contábil Pascual",
      "Email": "lmp1@terra.com.br",
      "CustomerCode": "17048",
      "Phone": "1129015750",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 306,
      "Name": "Ronaldo José Zucchi",
      "Email": "atelassessoria@uol.com.br",
      "CustomerCode": "17056",
      "Phone": "1132574222",
      "Whatsapp": "1197878170",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 307,
      "Name": "Roberto Do Amaral Faria Impressos - ME",
      "Email": "rosagl@uol.com.br",
      "CustomerCode": "17058",
      "Phone": "1136447603",
      "Whatsapp": "1170870844",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 308,
      "Name": "Paulo Henrique Trellese",
      "Email": "thp@uol.com.br",
      "CustomerCode": "17062",
      "Phone": "1127832272",
      "Whatsapp": "1181639716",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 309,
      "Name": "Marcos Gilberto Furtado",
      "Email": "mf.marcosfurtado@me.com",
      "CustomerCode": "17063",
      "Phone": "1173477164",
      "Whatsapp": "1173477164",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 310,
      "Name": "One Administração e Participações Ltda.",
      "Email": "veruska@one1.com.br",
      "CustomerCode": "17067",
      "Phone": "1125390266",
      "Whatsapp": "11976675320",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 311,
      "Name": "CSA Santo Amaro Adm Part e Com Ltda.",
      "Email": "adomingues@santoamaro.com.br",
      "CustomerCode": "17070",
      "Phone": "1133351515",
      "Whatsapp": "1199515661",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 312,
      "Name": "FGF Serviços Contábeis Ltda.",
      "Email": "fgfcontabil@uol.com.br",
      "CustomerCode": "17078",
      "Phone": "1123521703",
      "Whatsapp": "1199385533",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 313,
      "Name": "AC-Tec Serviços de Digitação-ME",
      "Email": "actecdigitacao@terra.com.br",
      "CustomerCode": "17092",
      "Phone": "1135071993",
      "Whatsapp": "1184919554",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 314,
      "Name": "Marcos Antônio Garrido",
      "Email": "cendon.garrido@gmail.com",
      "CustomerCode": "17094",
      "Phone": "1159726424",
      "Whatsapp": "1191554345",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 315,
      "Name": "Norte Sul Hidrotecnologia e Comércio Ltda.",
      "Email": "financeiro@nortesultec.com.br",
      "CustomerCode": "17096",
      "Phone": "1120162862",
      "Whatsapp": "1147910803",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 316,
      "Name": "Priori Serviços e Soluções Eireli ME",
      "Email": "galileu2@gmail.com",
      "CustomerCode": "17110",
      "Phone": "1120922394",
      "Whatsapp": "1196606300",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 317,
      "Name": "Consult Asses Contab Fisc Trab",
      "Email": "wwolney@terra.com.br",
      "CustomerCode": "17112",
      "Phone": "1127914119",
      "Whatsapp": "11986146916",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 318,
      "Name": "Als Instalações de Programas de Softwares Ltda.",
      "Email": "alsconsultoriaempresarial@outlook.com.br",
      "CustomerCode": "17121",
      "Phone": "1127293550",
      "Whatsapp": "1185183275",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 319,
      "Name": "Escritório Contábil Visão",
      "Email": "rosacunha36@uol.com.br",
      "CustomerCode": "17128",
      "Phone": "1127022177",
      "Whatsapp": "1193125487",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 320,
      "Name": "LDJ Contadores Associados",
      "Email": "cristiano@julianijuliani.com.br",
      "CustomerCode": "17138",
      "Phone": "1122523778",
      "Whatsapp": "11966336996",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 321,
      "Name": "Marcos Vieira Santos",
      "Email": "kamathi@bol.com.br",
      "CustomerCode": "17144",
      "Phone": "",
      "Whatsapp": "11954855117",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 322,
      "Name": "SP Consult Consultoria Imobiliária",
      "Email": "victor@grupospconsult.com.br",
      "CustomerCode": "17145",
      "Phone": "1127370431",
      "Whatsapp": "11984400439",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 323,
      "Name": "Contábil NSC",
      "Email": "contabilnsc@gmail.com",
      "CustomerCode": "17162",
      "Phone": "1125168788",
      "Whatsapp": "11953776758",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 324,
      "Name": "Mtva Contábil",
      "Email": "mtva@mtva.com.br",
      "CustomerCode": "17165",
      "Phone": "1138721689",
      "Whatsapp": "11999341608",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 325,
      "Name": "Wilson Monteiro Rodrigues da Silva",
      "Email": "wilson.control@uol.com.br",
      "CustomerCode": "17169",
      "Phone": "1126403001",
      "Whatsapp": "1196677620",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 326,
      "Name": "Wft - Informática Brasil Ltda. ME",
      "Email": "marta.santos@workforce.coop.br",
      "CustomerCode": "17172",
      "Phone": "1128990982",
      "Whatsapp": "1183234462",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 327,
      "Name": "Gilberto Robson Alves",
      "Email": "g.robson@uol.com.br",
      "CustomerCode": "17184",
      "Phone": "1156778129",
      "Whatsapp": "1187994705",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 329,
      "Name": "Ouro Preto Assessoria Empresarial Ltda.",
      "Email": "ouropreto.molina@uol.com.br",
      "CustomerCode": "17200",
      "Phone": "1140563842",
      "Whatsapp": "1191518726",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 330,
      "Name": "Paulo Cesar Costa",
      "Email": "oluapcc@gmail.com",
      "CustomerCode": "17201",
      "Phone": "7331420001",
      "Whatsapp": "73999061994",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 331,
      "Name": "Luma Assessoria Contábil Ltda.",
      "Email": "ribeiro.correa80@gmail.com",
      "CustomerCode": "17204",
      "Phone": "1126456392",
      "Whatsapp": "1184077593",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 332,
      "Name": "Lidomar Antônio Martinhão",
      "Email": "compact12@uol.com.br",
      "CustomerCode": "17207",
      "Phone": "1156688836",
      "Whatsapp": "11980732886",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 333,
      "Name": "Wanderley Plana",
      "Email": "wanderleyplana@ig.com.br",
      "CustomerCode": "17210",
      "Phone": "1137980684",
      "Whatsapp": "1199388910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 334,
      "Name": "Magda Teixeira",
      "Email": "magui_rbt@outlook.com",
      "CustomerCode": "17213",
      "Phone": "1143268907",
      "Whatsapp": "1194713374",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 335,
      "Name": "C A de Almeida Contabilidade",
      "Email": "carlos@almeidacontabil.com.br",
      "CustomerCode": "17215",
      "Phone": "1141062187",
      "Whatsapp": "1181810056",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 336,
      "Name": "Mpa Escritório Contábil",
      "Email": "mpacontabilidade@hotmail.com",
      "CustomerCode": "17220",
      "Phone": "1137881736",
      "Whatsapp": "1180885275",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 337,
      "Name": "José Roberto Birolli",
      "Email": "birollicontabil@gmail.com",
      "CustomerCode": "17221",
      "Phone": "1125612488",
      "Whatsapp": "1152450037",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 338,
      "Name": "Fistex Auditoria e Contabilidade Ltda.",
      "Email": "laerte@fistex.com.br",
      "CustomerCode": "17226",
      "Phone": "1132586556",
      "Whatsapp": "11988996718",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 339,
      "Name": "Levorin Contábil",
      "Email": "levorin@globo.com",
      "CustomerCode": "17231",
      "Phone": "1136412782",
      "Whatsapp": "1150513871",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 340,
      "Name": "Easy Way Do Brasil",
      "Email": "rlandin@simplewaycontabil.com.br",
      "CustomerCode": "17236",
      "Phone": "1139690404",
      "Whatsapp": "11984968846",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 341,
      "Name": "C. Julien Contabilidade Empresarial",
      "Email": "cesar.julien@cjulien.com.br",
      "CustomerCode": "17243",
      "Phone": "1130321070",
      "Whatsapp": "11942008581",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 343,
      "Name": "Adac - Organização Contábil Ltda.",
      "Email": "fernando@adaccontabil.com.br",
      "CustomerCode": "17249",
      "Phone": "1155874522",
      "Whatsapp": "1173738710",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 344,
      "Name": "Fiel Consultoria e Contabilidade Ltda.",
      "Email": "valeriajra@hotmail.com",
      "CustomerCode": "17254",
      "Phone": "1132294055",
      "Whatsapp": "1184262990",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 345,
      "Name": "Achiles dos Santos Varino",
      "Email": "achiles.varino@gmail.com",
      "CustomerCode": "17257",
      "Phone": "1121434219",
      "Whatsapp": "11993340757",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 346,
      "Name": "Ciclo Contabilidade Ltda.",
      "Email": "osorio@ciclocontabilidade.com.br",
      "CustomerCode": "17260",
      "Phone": "1132140321",
      "Whatsapp": "11",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 347,
      "Name": "Katia S P Dutra",
      "Email": "katia.ksadv.juridico@gmail.com",
      "CustomerCode": "17263",
      "Phone": "",
      "Whatsapp": "11986531436",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 348,
      "Name": "Marcia Cristina Correia da Silva Benite",
      "Email": "m.cristina.benite@hotmail.com",
      "CustomerCode": "17277",
      "Phone": "1120760167",
      "Whatsapp": "1184197603",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 349,
      "Name": "Gestão Global Contábil - Eireli",
      "Email": "francisco@gestaoglobal.com.br",
      "CustomerCode": "17280",
      "Phone": "1132289532",
      "Whatsapp": "1164621909",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 350,
      "Name": "Nutrisport Empreend Imobiliários LTDA",
      "Email": "anastacio@nutrifashion.com.br",
      "CustomerCode": "17289",
      "Phone": "1138567907",
      "Whatsapp": "1199801538",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 351,
      "Name": "B&C Soluções",
      "Email": "rogerio@bc-contabeis.com.br",
      "CustomerCode": "17291",
      "Phone": "1155769243",
      "Whatsapp": "1147912850",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 352,
      "Name": "Maria Inês de Assis",
      "Email": "ines.sp@uol.com.br",
      "CustomerCode": "17298",
      "Phone": "1156318377",
      "Whatsapp": "1197114749",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 353,
      "Name": "Sergio Martino",
      "Email": "sergio@amscontabil.srv.br",
      "CustomerCode": "17303",
      "Phone": "1150621086",
      "Whatsapp": "1196973323",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 354,
      "Name": "Master Nmf Soluções",
      "Email": "adm@masterconsultores.com.br",
      "CustomerCode": "17321",
      "Phone": "1132940080",
      "Whatsapp": "11940674133",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 355,
      "Name": "Nilton da Silva",
      "Email": "ndsil@yahoo.com",
      "CustomerCode": "17333",
      "Phone": "",
      "Whatsapp": "11984821795",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 356,
      "Name": "H.Rodrigues Assessoria Contábil e Empresarial",
      "Email": "helyel.hrodrigues@hotmail.com",
      "CustomerCode": "17334",
      "Phone": "",
      "Whatsapp": "11984008873",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 357,
      "Name": "Lm Consultores Independentes",
      "Email": "lmservicoscontabeis@lmservicoscontabeis.com.br",
      "CustomerCode": "17339",
      "Phone": "1139263222",
      "Whatsapp": "11993569177",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 358,
      "Name": "Organização Contábil Romaly Ltda.",
      "Email": "mjf@romaly.com.br",
      "CustomerCode": "17342",
      "Phone": "1132597339",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 359,
      "Name": "São Miguel Assessoria e Apoio Administrativo Ltda.",
      "Email": "adm.alcesp@gmail.com",
      "CustomerCode": "17351",
      "Phone": "1127045816",
      "Whatsapp": "1177798516",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 360,
      "Name": "Luiz e Carmo Contabilidade ME",
      "Email": "luizecarmo@leccontabil.com.br",
      "CustomerCode": "17352",
      "Phone": "11993876666",
      "Whatsapp": "11993876666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 361,
      "Name": "Marko Consultoria Ltda.",
      "Email": "alessander@markoconsultoria.com.br",
      "CustomerCode": "17355",
      "Phone": "1132578288",
      "Whatsapp": "11976294847",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 362,
      "Name": "Domingos Sobral de Souza",
      "Email": "domingossobral@yahoo.com.br",
      "CustomerCode": "17357",
      "Phone": "1137946614",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 363,
      "Name": "Luiz Roberto Camilo",
      "Email": "lrcamilo@hotmail.com",
      "CustomerCode": "17358",
      "Phone": "1137583815",
      "Whatsapp": "11984881930",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 364,
      "Name": "Elo Contabilidade & Auditoria",
      "Email": "paulo@elocontabil.com.br",
      "CustomerCode": "17359",
      "Phone": "1138319047",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 365,
      "Name": "Raycont Apoio Adm Emp Ltda. ME",
      "Email": "raycont@uol.com.br",
      "CustomerCode": "17383",
      "Phone": "1120848251",
      "Whatsapp": "1195444242",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 366,
      "Name": "Ocd Organização Contábil Donadio S/C Ltda.",
      "Email": "ocdcont@ocdcont.com.br",
      "CustomerCode": "17386",
      "Phone": "1139757681",
      "Whatsapp": "1196274374",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 367,
      "Name": "Regadas Organização Contábil & Assessoria",
      "Email": "liliregadas@gmail.com",
      "CustomerCode": "17397",
      "Phone": "1150119993",
      "Whatsapp": "1196910709",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 368,
      "Name": "Edinor Aguiar Pereira",
      "Email": "edyaguiar1@uol.com.br",
      "CustomerCode": "17406",
      "Phone": "1137829805",
      "Whatsapp": "11953976630",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 369,
      "Name": "Web Managers The Solution Company",
      "Email": "zedaweb@hotmail.com",
      "CustomerCode": "17421",
      "Phone": "1139110528",
      "Whatsapp": "1147001862",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 370,
      "Name": "Vicente José Veneziiano",
      "Email": "vijve@uol.com.br",
      "CustomerCode": "17422",
      "Phone": "1137980546",
      "Whatsapp": "1197914344",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 371,
      "Name": "Dilma Santos Novaes",
      "Email": "dilma@dlnovaes.com.br",
      "CustomerCode": "17427",
      "Phone": "1122362795",
      "Whatsapp": "1194218816",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 372,
      "Name": "Contabildenis",
      "Email": "contabildenis@terra.com.br",
      "CustomerCode": "17434",
      "Phone": "1156616772",
      "Whatsapp": "1184494990",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 373,
      "Name": "Oliveira Castro Contab. & Assessoria Fiscal",
      "Email": "thiago@oliveiracastrocontabil.com.br",
      "CustomerCode": "17437",
      "Phone": "1139763672",
      "Whatsapp": "11987241668",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 374,
      "Name": "Cesar Rodrigues",
      "Email": "mwtcontabilidade.hasa@gmail.com",
      "CustomerCode": "17438",
      "Phone": "",
      "Whatsapp": "1198912044",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 375,
      "Name": "Jmf Assessoria Fiscal e Contábil Ltda.",
      "Email": "jmf.fiscal@terra.com.br",
      "CustomerCode": "17440",
      "Phone": "1130639792",
      "Whatsapp": "1184355821",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 376,
      "Name": "Orcef Organização Comercial Eficaz",
      "Email": "orcef@orcef.com.br",
      "CustomerCode": "17453",
      "Phone": "1138510167",
      "Whatsapp": "1175850167",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 377,
      "Name": "Andreia Jesus da Silva",
      "Email": "ads_assessoriacontabil@hotmail.com",
      "CustomerCode": "17460",
      "Phone": "1123022321",
      "Whatsapp": "1184423875",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 378,
      "Name": "Cleonice Maria de Souza",
      "Email": "cleomaria.contabilidade@gmail.com",
      "CustomerCode": "17461",
      "Phone": "1151828756",
      "Whatsapp": "1173776502",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 379,
      "Name": "Quanthabilidade",
      "Email": "sonia@quanthabilidade.com.br",
      "CustomerCode": "17468",
      "Phone": "1156816950",
      "Whatsapp": "1196241644",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 380,
      "Name": "Rodolfo da Silva Pagliari",
      "Email": "renata@dkee.com.br",
      "CustomerCode": "17469",
      "Phone": "1137131989",
      "Whatsapp": "1182973878",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 381,
      "Name": "Marfin Contábil",
      "Email": "marfincontabil@uol.com.br",
      "CustomerCode": "17475",
      "Phone": "1139023097",
      "Whatsapp": "1191349437",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 382,
      "Name": "Bertoncini Contabilidade Ltda.",
      "Email": "ronaldo@bertoncinicontabilidade.com.br",
      "CustomerCode": "17484",
      "Phone": "1133921356",
      "Whatsapp": "1172471801",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 383,
      "Name": "Mimo Importação e Exportação Ltda.",
      "Email": "fiscal@mimostile.com.br",
      "CustomerCode": "17490",
      "Phone": "1137874418",
      "Whatsapp": "11966274765",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 384,
      "Name": "Silvio Ramalho da Silva Filho",
      "Email": "silvioramalho@hotmail.com",
      "CustomerCode": "17491",
      "Phone": "1122911295",
      "Whatsapp": "1193692135",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 385,
      "Name": "Nova Regia Contabilidade LTDA",
      "Email": "novaregia@uol.com.br",
      "CustomerCode": "17493",
      "Phone": "1120939244",
      "Whatsapp": "1174686917",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 386,
      "Name": "Computecnica Tecnologia Ltda.",
      "Email": "aj.oliveira@computecnica.com.br",
      "CustomerCode": "17501",
      "Phone": "1121349000",
      "Whatsapp": "1121476465",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 387,
      "Name": "Cpd Contabilidade Ltda.",
      "Email": "contato@cpdcontabil.com.br",
      "CustomerCode": "17503",
      "Phone": "1155728109",
      "Whatsapp": "11954434471",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 388,
      "Name": "Suecon Consultoria e Contabilidade",
      "Email": "jair@suecon.com.br",
      "CustomerCode": "17505",
      "Phone": "1125944388",
      "Whatsapp": "1150253537",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 389,
      "Name": "Mas Contabilidade",
      "Email": "murilo@mascontabil.com.br",
      "CustomerCode": "17508",
      "Phone": "1139316014",
      "Whatsapp": "11993171231",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 390,
      "Name": "Espinosa Serviços Contábeis",
      "Email": "contabilespinosa@terra.com.br",
      "CustomerCode": "17512",
      "Phone": "1122624635",
      "Whatsapp": "1192743329",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 391,
      "Name": "Entidade Assessoria Contábil",
      "Email": "entidade@entidade.com.br",
      "CustomerCode": "17520",
      "Phone": "1122334866",
      "Whatsapp": "1177684866",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 392,
      "Name": "Imperio Contábil",
      "Email": "imperio.contabil@uol.com.br",
      "CustomerCode": "17522",
      "Phone": "1123690884",
      "Whatsapp": "1199719799",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 393,
      "Name": "Monteiro Assessoria Contábil",
      "Email": "almonteiros@icloud.com",
      "CustomerCode": "17534",
      "Phone": "1123596271",
      "Whatsapp": "11991278085",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 394,
      "Name": "Assessoria Empresarial Ltda.",
      "Email": "dolores@assessorialsp.com.br",
      "CustomerCode": "17536",
      "Phone": "1139384277",
      "Whatsapp": "1140201923",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 395,
      "Name": "Nogueira Organização Contábil",
      "Email": "financeiro@nogueiraorgcontabil.com",
      "CustomerCode": "17541",
      "Phone": "1128417771",
      "Whatsapp": "1177572314",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 396,
      "Name": "Tergec Terc. e Gerenciamento Contábil Ltda.",
      "Email": "contato@tergec.com.br",
      "CustomerCode": "17545",
      "Phone": "1147809875",
      "Whatsapp": "1199429781",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 397,
      "Name": "Romero Service",
      "Email": "romero.contabil@gmail.com",
      "CustomerCode": "17548",
      "Phone": "1120796857",
      "Whatsapp": "1194212741",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 398,
      "Name": "3A Assessoria e Consultoria Contábil",
      "Email": "societario@3acontabilidade.com.br",
      "CustomerCode": "17557",
      "Phone": "1127979500",
      "Whatsapp": "11999639840",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 399,
      "Name": "Contabilida de Moraes",
      "Email": "contabilidademoraes@uol.com.br",
      "CustomerCode": "17558",
      "Phone": "1137334160",
      "Whatsapp": "1185714520",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 400,
      "Name": "Carvalho & Silva Assessoria Em Repartições Pública",
      "Email": "tais.carvalho@cesconsultores.com.br",
      "CustomerCode": "17562",
      "Phone": "1133297180",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 401,
      "Name": "Biasi Advocacia",
      "Email": "biasihilton@gmail.com",
      "CustomerCode": "17564",
      "Phone": "1142246259",
      "Whatsapp": "1199529175",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 402,
      "Name": "Mauricio Damasceno Silva",
      "Email": "mauricionrc@hotmail.com",
      "CustomerCode": "17565",
      "Phone": "1138639337",
      "Whatsapp": "1196484594",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 403,
      "Name": "Ampla Contabilidade",
      "Email": "amplafaturamento@gmail.com",
      "CustomerCode": "17570",
      "Phone": "",
      "Whatsapp": "11993639110",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 404,
      "Name": "Atf-Assessoria Fiscal",
      "Email": "beth.martinezperes@gmail.com",
      "CustomerCode": "17583",
      "Phone": "",
      "Whatsapp": "11999659991",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 405,
      "Name": "Terol Assessoria Contábil Ltda.",
      "Email": "terol.contabilidade@gmail.com",
      "CustomerCode": "17588",
      "Phone": "1156319123",
      "Whatsapp": "1187127351",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 406,
      "Name": "Ricardo José Arcediacono",
      "Email": "ricardojosearcediacono@gmail.com",
      "CustomerCode": "17590",
      "Phone": "1138712523",
      "Whatsapp": "1195310815",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 407,
      "Name": "Herivelto Alves",
      "Email": "heri.alves@bol.com.br",
      "CustomerCode": "17591",
      "Phone": "1182261316",
      "Whatsapp": "1172120860",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 408,
      "Name": "Jmf Contabilidade",
      "Email": "juarez@jmfcontabil.com.br",
      "CustomerCode": "17592",
      "Phone": "1135303656",
      "Whatsapp": "1187471860",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 409,
      "Name": "L & B Contabilidade",
      "Email": "lobato@contabil-lb.com.br",
      "CustomerCode": "17598",
      "Phone": "1123672042",
      "Whatsapp": "1175659295",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 410,
      "Name": "Proxis Integração Comercial e Serviços Ltda.",
      "Email": "rosi.duarte@proxis.com.br",
      "CustomerCode": "17599",
      "Phone": "112847100",
      "Whatsapp": "1191600888",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 411,
      "Name": "Marcelo Cunha de Souza",
      "Email": "marcelo@2sc.com.br",
      "CustomerCode": "17605",
      "Phone": "1123084551",
      "Whatsapp": "1181018880",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 412,
      "Name": "Antônio José Trindade Junior",
      "Email": "tony.lory@bol.com.br",
      "CustomerCode": "17607",
      "Phone": "",
      "Whatsapp": "1174791508",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 413,
      "Name": "Jf Consultoria Empresarial",
      "Email": "joselaine@jffconsultoria.com.br",
      "CustomerCode": "17608",
      "Phone": "1131018955",
      "Whatsapp": "1199757863",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 414,
      "Name": "Contábil Maia Associados",
      "Email": "henrique@ng2contabilidade.com.br",
      "CustomerCode": "17613",
      "Phone": "1156313423",
      "Whatsapp": "118711008",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 415,
      "Name": "Revi Assessoria e Consultoria Contábil Ltda.",
      "Email": "apoio@reviconsultoria.com.br",
      "CustomerCode": "17619",
      "Phone": "1138014011",
      "Whatsapp": "1187574679",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 416,
      "Name": "Gsa Contabilidade",
      "Email": "jfsimoes57@gmail.com",
      "CustomerCode": "17623",
      "Phone": "1199199855",
      "Whatsapp": "1199199855",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 417,
      "Name": "Pinacont Contabilidade",
      "Email": "contabilidade@pinacont.com.br",
      "CustomerCode": "17631",
      "Phone": "1155391062",
      "Whatsapp": "1168912710",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 418,
      "Name": "Invictus Serviços Contábeis S/S Ltda.",
      "Email": "vanderleimontrezol@invictusbps.com.br",
      "CustomerCode": "17644",
      "Phone": "1125248156",
      "Whatsapp": "1174698126",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 419,
      "Name": "Fernando Pereira de Souza",
      "Email": "fpscontabil92@gmail.com",
      "CustomerCode": "17646",
      "Phone": "1128661840",
      "Whatsapp": "1191062448",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 420,
      "Name": "Itamar Donizete da Cunha",
      "Email": "lidima.pericias@terra.com.br",
      "CustomerCode": "17653",
      "Phone": "1120919195",
      "Whatsapp": "1182813069",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 421,
      "Name": "Meta Inteligência Empresarial Ltda-Epp",
      "Email": "pedro@metainteligencia.com.br",
      "CustomerCode": "17655",
      "Phone": "1132231113",
      "Whatsapp": "1199044616",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 422,
      "Name": "Consult Brasil Assessoria Contábil.",
      "Email": "consultbrasil80@gmail.com",
      "CustomerCode": "17656",
      "Phone": "1124769569",
      "Whatsapp": "1195372947",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 423,
      "Name": "Oscar dos Santos Fernandes",
      "Email": "o.dfernandes@hotmail.com",
      "CustomerCode": "17657",
      "Phone": "1129096052",
      "Whatsapp": "1186159248",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 424,
      "Name": "Sebastião Hilário Messias",
      "Email": "controllersp@hotmail.com",
      "CustomerCode": "17659",
      "Phone": "1185091344",
      "Whatsapp": "1185091344",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 425,
      "Name": "Dracon Apoio Adm. e Contábil Ltda.",
      "Email": "legalizacao@grupodracon.com.br",
      "CustomerCode": "17680",
      "Phone": "1123695797",
      "Whatsapp": "1159801508",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 426,
      "Name": "Ascef Assessoria Contábil Eficaz S/C",
      "Email": "ascef@ascef.com.br",
      "CustomerCode": "17681",
      "Phone": "1125774560",
      "Whatsapp": "1181128500",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 427,
      "Name": "V. V. Piroti e Nascimento Contabilidade Ltda.",
      "Email": "vvpiroti@hotmail.com",
      "CustomerCode": "17686",
      "Phone": "1139929798",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 428,
      "Name": "Júnior Cesar Do Nascimento Reginaldo",
      "Email": "junio_nascimento@hotmail.com",
      "CustomerCode": "17687",
      "Phone": "1133118252",
      "Whatsapp": "11985704301",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 429,
      "Name": "Josiane Rebuli Ramos",
      "Email": "josiane@reboliefagundes.com.br",
      "CustomerCode": "17697",
      "Phone": "11",
      "Whatsapp": "4791624461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 430,
      "Name": "Rogerio Do Nascimento Pereira",
      "Email": "rogerio_nascimento@terra.com.br",
      "CustomerCode": "17699",
      "Phone": "1155118338",
      "Whatsapp": "1183550403",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 431,
      "Name": "Controller Assessoria S/S",
      "Email": "controllersp@uol.com.br",
      "CustomerCode": "17702",
      "Phone": "1137743629",
      "Whatsapp": "1191471289",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 432,
      "Name": "Airton Haro Nedelciu",
      "Email": "airton.haro1964@gmail.com",
      "CustomerCode": "17705",
      "Phone": "1127686048",
      "Whatsapp": "1191083264",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 433,
      "Name": "Denis Araújo Digitações",
      "Email": "fiscal@consultoriaaraujo.com.br",
      "CustomerCode": "17707",
      "Phone": "1133846561",
      "Whatsapp": "1187971268",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 434,
      "Name": "Mauricio Guerreiro",
      "Email": "contabilmgg@gmail.com",
      "CustomerCode": "17710",
      "Phone": "",
      "Whatsapp": "1191130020",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 435,
      "Name": "Flavio Souza de Amorim",
      "Email": "flavio@samorim.cnt.br",
      "CustomerCode": "17711",
      "Phone": "1147887171",
      "Whatsapp": "11972878940",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 436,
      "Name": "Igreja Evangélica Assembleia de Deus Em Spp.",
      "Email": "paulovitor.ctb@terra.com.br",
      "CustomerCode": "17714",
      "Phone": "1126940782",
      "Whatsapp": "1199542121",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 437,
      "Name": "Sandra Aparecida da Silva - ME",
      "Email": "paralegal@contadezcontabilidade.com.br",
      "CustomerCode": "17716",
      "Phone": "1123847222",
      "Whatsapp": "1167132369",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 438,
      "Name": "Josué Ferreira",
      "Email": "jf@albau.com.br",
      "CustomerCode": "17719",
      "Phone": "1156313040",
      "Whatsapp": "1176035295",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 439,
      "Name": "Marilza Aparecida Machado",
      "Email": "tcqass@uol.com.br",
      "CustomerCode": "17728",
      "Phone": "1132562604",
      "Whatsapp": "1184256618",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 440,
      "Name": "Montana Coffee Máquinas",
      "Email": "fernando@montanacoffee.com.br",
      "CustomerCode": "17732",
      "Phone": "1120930070",
      "Whatsapp": "118444444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 441,
      "Name": "Amacont - Serviços Contábeis e Administrativos S/S",
      "Email": "adilvo@uol.com.br",
      "CustomerCode": "17735",
      "Phone": "1139030447",
      "Whatsapp": "1198198087",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 442,
      "Name": "Kbr Consultoria Contábil e Fiscal",
      "Email": "contador@kbrcontabilidade.com.br",
      "CustomerCode": "17736",
      "Phone": "",
      "Whatsapp": "1188977190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 443,
      "Name": "Villar Papini Contabilidade Ltda.",
      "Email": "papinicontabil@uol.com.br",
      "CustomerCode": "17741",
      "Phone": "1122891387",
      "Whatsapp": "1195452687",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 445,
      "Name": "Marinete Pereira Cosme",
      "Email": "mari.cosme@gmail.com",
      "CustomerCode": "17745",
      "Phone": "1120529652",
      "Whatsapp": "1192680956",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 446,
      "Name": "Maria Cristina Jácomo",
      "Email": "cristinajacomo@hotmail.com",
      "CustomerCode": "17749",
      "Phone": "1122562156",
      "Whatsapp": "1193523299",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 447,
      "Name": "Orcasi- Assessoria Empresarial e Contábil",
      "Email": "esdras@orcasi.com.br",
      "CustomerCode": "17753",
      "Phone": "1127173250",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 449,
      "Name": "Dbo Soluções Contábeis e Tributarias Eireli",
      "Email": "nilce@dbosolucoes.com.br",
      "CustomerCode": "17757",
      "Phone": "1139376480",
      "Whatsapp": "1192076504",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 450,
      "Name": "Contabilidade Pd",
      "Email": "karina@contabilidadepd.com.br",
      "CustomerCode": "17758",
      "Phone": "1158311786",
      "Whatsapp": "11963189351",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 451,
      "Name": "Alves Carrilho Contabilidade",
      "Email": "gilmara.financeiro@yahoo.com.br",
      "CustomerCode": "17767",
      "Phone": "1125328467",
      "Whatsapp": "1183446222",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 452,
      "Name": "Comércio de Materiais P/Construção Joli Ltda.",
      "Email": "arley@santricontabilidade.com.br",
      "CustomerCode": "17770",
      "Phone": "1129552249",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 453,
      "Name": "Delcontabil",
      "Email": "delcontabil@uol.com.br",
      "CustomerCode": "17771",
      "Phone": "1129875343",
      "Whatsapp": "1132291280",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 454,
      "Name": "Snec Indústria e Comércio Ltda.",
      "Email": "marizeti@snec.ind.br",
      "CustomerCode": "17773",
      "Phone": "1147879191",
      "Whatsapp": "1194189127",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 455,
      "Name": "Aleixo & Associados Contabilidade e Assessoria Ltda.",
      "Email": "secretaria@aleixopereira.com.br",
      "CustomerCode": "17777",
      "Phone": "1133723844",
      "Whatsapp": "1192818283",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 456,
      "Name": "Gjc Serviços de Apoio Administrativo Ltda.",
      "Email": "gilberto@sitivesp.org.br",
      "CustomerCode": "17788",
      "Phone": "1137425303",
      "Whatsapp": "1191697703",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 457,
      "Name": "Parise e Dias Advogados Associados",
      "Email": "parisedias@uol.com.br",
      "CustomerCode": "17791",
      "Phone": "1131043400",
      "Whatsapp": "1199657373",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 458,
      "Name": "Maria Cristina Toledo",
      "Email": "contabilidadetoledo2020@gmail.com",
      "CustomerCode": "17794",
      "Phone": "",
      "Whatsapp": "1172741180",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 459,
      "Name": "Camila Moreira Rodrigues",
      "Email": "camilamoreirarodrigues@gmail.com",
      "CustomerCode": "17796",
      "Phone": "1122285210",
      "Whatsapp": "1175209700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 460,
      "Name": "Sonia Aparecida Anselmo Sabença",
      "Email": "saascontabil@gmail.com",
      "CustomerCode": "17797",
      "Phone": "",
      "Whatsapp": "1193178771",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 461,
      "Name": "Nascom Contabilidade",
      "Email": "costamigueladvogados@uol.com.br",
      "CustomerCode": "17799",
      "Phone": "1129435011",
      "Whatsapp": "1170831587",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 462,
      "Name": "Ck Amorim Com. da Artefatos de Metais Ltda.",
      "Email": "carlos.santos@carlaamorim.com.br",
      "CustomerCode": "17805",
      "Phone": "1121075580",
      "Whatsapp": "1185182093",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 463,
      "Name": "Morumbycont Contabilidade",
      "Email": "bielcontabilidade@uol.com.br",
      "CustomerCode": "17811",
      "Phone": "",
      "Whatsapp": "1196532140",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 464,
      "Name": "Carlos Alberto Sguizzardi",
      "Email": "liderfilial.ax@polimix.com.br",
      "CustomerCode": "17812",
      "Phone": "",
      "Whatsapp": "1150734221",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 465,
      "Name": "Gustavo Pereira da Silva",
      "Email": "gustavo@auditway.com.br",
      "CustomerCode": "17816",
      "Phone": "",
      "Whatsapp": "1187970780",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 466,
      "Name": "AVC Contábil LTDA ME",
      "Email": "fabiana.avc@uol.com.br",
      "CustomerCode": "17818",
      "Phone": "1139042823",
      "Whatsapp": "11987110215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 467,
      "Name": "Gd7 Assessoria",
      "Email": "gd7@uol.com.br",
      "CustomerCode": "17820",
      "Phone": "1139441151",
      "Whatsapp": "1164596181",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 468,
      "Name": "Lds Associados",
      "Email": "pedro@ldsassociados.com.br",
      "CustomerCode": "17826",
      "Phone": "1131011982",
      "Whatsapp": "1147432211",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 469,
      "Name": "Vision Assessoria Contábil",
      "Email": "elias.souza@visioncont.com.br",
      "CustomerCode": "17861",
      "Phone": "1136417957",
      "Whatsapp": "1189954644",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 470,
      "Name": "Acsa Organização Contabil Ltda",
      "Email": "dribeirosantos@hotmail.com",
      "CustomerCode": "17865",
      "Phone": "1122144503",
      "Whatsapp": "1185347903",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 471,
      "Name": "Mauro Duarte",
      "Email": "mauroduartecontabil@gmail.com",
      "CustomerCode": "17866",
      "Phone": "1159734606",
      "Whatsapp": "1199753324",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 472,
      "Name": "Oikos Contabilidade",
      "Email": "contabil@oikoscontabilidade.com.br",
      "CustomerCode": "17874",
      "Phone": "1139233205",
      "Whatsapp": "1191472470",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 473,
      "Name": "Maria Jacilene Alves Brandão",
      "Email": "leninha@contabilagosto.com.br",
      "CustomerCode": "17880",
      "Phone": "1174738217",
      "Whatsapp": "1174738217",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 474,
      "Name": "Riolex Contabilidade e Assessoria Ltda.",
      "Email": "adauto.junior@riolex.com.br",
      "CustomerCode": "17887",
      "Phone": "1122911933",
      "Whatsapp": "1147606223",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 475,
      "Name": "Claudio da Silva",
      "Email": "costamagalhaes@terra.com.br",
      "CustomerCode": "17898",
      "Phone": "1131122586",
      "Whatsapp": "1199507627",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 476,
      "Name": "S/C Contabilidade Administração de Bens",
      "Email": "allconttari@gmail.com",
      "CustomerCode": "17901",
      "Phone": "1120760961",
      "Whatsapp": "1183375781",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 477,
      "Name": "Moore Stephens Contadores",
      "Email": "jmartins@mooremsll.com.br",
      "CustomerCode": "17903",
      "Phone": "1155612230",
      "Whatsapp": "11992222375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 478,
      "Name": "Athos Weslei de Oliveira dos Santos",
      "Email": "athos.weslei@awcontabilidade.com.br",
      "CustomerCode": "17905",
      "Phone": "1933055748",
      "Whatsapp": "1984472496",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 479,
      "Name": "Orgon Contabilidade",
      "Email": "goncalv3@terra.com.br",
      "CustomerCode": "17909",
      "Phone": "1155146262",
      "Whatsapp": "1154578334",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 480,
      "Name": "Maria Terezinha Moreira Pereira",
      "Email": "tematicacontabil@tematicacontabil.com.br",
      "CustomerCode": "17913",
      "Phone": "1150250086",
      "Whatsapp": "1150250086",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 481,
      "Name": "Daniel de Souza",
      "Email": "dnlsouza472@gmail.com",
      "CustomerCode": "17915",
      "Phone": "1137141789",
      "Whatsapp": "11974604291",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 482,
      "Name": "Eutimio Do Carmo Braga",
      "Email": "eutimiobragacont@gmail.com",
      "CustomerCode": "17918",
      "Phone": "1139044235",
      "Whatsapp": "1174747302",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 483,
      "Name": "Marka Contábil Assessoria Empresarial",
      "Email": "marcelo@markacont.com.br",
      "CustomerCode": "17920",
      "Phone": "1139350441",
      "Whatsapp": "11964172525",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 484,
      "Name": "Jfs Contabilidade",
      "Email": "plataformacontabilidade@gmail.com",
      "CustomerCode": "17935",
      "Phone": "1129354138",
      "Whatsapp": "11976082144",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 485,
      "Name": "T M Zotta de Paula Contabilidade",
      "Email": "agesan_contabil@terra.com.br",
      "CustomerCode": "17937",
      "Phone": "",
      "Whatsapp": "1197921979",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 486,
      "Name": "Oj Gonçalves Contábil",
      "Email": "ukgoncalves@gmail.com",
      "CustomerCode": "17938",
      "Phone": "1125076582",
      "Whatsapp": "1184451136",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 487,
      "Name": "Conperaudi S.S. Ltda.",
      "Email": "conperaudi2020@gmail.com",
      "CustomerCode": "17944",
      "Phone": "1131052723",
      "Whatsapp": "1150751911",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 488,
      "Name": "Papi Contabilidade",
      "Email": "claudio@papicontabilidade.com.br",
      "CustomerCode": "17946",
      "Phone": "1120719642",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 489,
      "Name": "Yumi Yamamoto Yoshioka",
      "Email": "yyyumi1002@gmail.com",
      "CustomerCode": "17947",
      "Phone": "1129754061",
      "Whatsapp": "1172004763",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 490,
      "Name": "Júlio Valério Campos",
      "Email": "crfjuliocampos@uol.com.br",
      "CustomerCode": "17948",
      "Phone": "1138055979",
      "Whatsapp": "1192775968",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 491,
      "Name": "Rosalina Merenna",
      "Email": "rosalinamerenna@gmail.com",
      "CustomerCode": "17951",
      "Phone": "1123512426",
      "Whatsapp": "1175972881",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 492,
      "Name": "Ventura Assessoria Contábil e Gestão Empresarial L",
      "Email": "adm@venturacontabilidade.com.br",
      "CustomerCode": "17954",
      "Phone": "1132423468",
      "Whatsapp": "1156197301",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 493,
      "Name": "Horvath Bazalia Assessoria Empresarial Ltda.",
      "Email": "hbassessoria@gmail.com",
      "CustomerCode": "17955",
      "Phone": "",
      "Whatsapp": "1198790106",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 494,
      "Name": "Marta Gonçalves da Silva",
      "Email": "mgdsbotelho@ig.com.br",
      "CustomerCode": "17965",
      "Phone": "1334742090",
      "Whatsapp": "1381357862",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 495,
      "Name": "Contabilidade Força Nova",
      "Email": "forcanovacontabil@terra.com.br",
      "CustomerCode": "17966",
      "Phone": "1126921725",
      "Whatsapp": "1182507887",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 496,
      "Name": "Fabio Maecelo Fuzinatto",
      "Email": "fabio@fiscolandis.com.br",
      "CustomerCode": "17970",
      "Phone": "1130316604",
      "Whatsapp": "1199990371",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 497,
      "Name": "Equipe Planejamento Empresarial Ltda.",
      "Email": "rita@equipeempresarial.com.br",
      "CustomerCode": "17973",
      "Phone": "1155831520",
      "Whatsapp": "1144931833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 498,
      "Name": "Orlando Lessa Junior",
      "Email": "orlandolessajr@yahoo.com",
      "CustomerCode": "17974",
      "Phone": "1122429781",
      "Whatsapp": "1191133284",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 499,
      "Name": "Zilda Estima da Silva",
      "Email": "zildacont@uol.com.br",
      "CustomerCode": "17976",
      "Phone": "1158312561",
      "Whatsapp": "11986230044",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 500,
      "Name": "Contabilidade Personnalise Ltda. - ME",
      "Email": "emerson_oliveira@contabilidadepersonnalise.com.br",
      "CustomerCode": "17978",
      "Phone": "1123641339",
      "Whatsapp": "1184696545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 501,
      "Name": "Fleury Contábil",
      "Email": "flavio.fleury@gmail.com",
      "CustomerCode": "17990",
      "Phone": "1156310106",
      "Whatsapp": "1199859335",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 502,
      "Name": "Contmaxxi Contabilidade",
      "Email": "contmaxxi@gmail.com",
      "CustomerCode": "17993",
      "Phone": "1126511066",
      "Whatsapp": "1173476692",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 503,
      "Name": "Otimus Assessoria Contábil e Fiscal S/S Ltda. ME",
      "Email": "otimus01@gmail.com",
      "CustomerCode": "17995",
      "Phone": "1143018872",
      "Whatsapp": "1191976221",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 504,
      "Name": "Rodrigues Botelho Sociedade de Advogados",
      "Email": "cassianobotelho@advpmr.com.br",
      "CustomerCode": "18010",
      "Phone": "1138429366",
      "Whatsapp": "1181998381",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 505,
      "Name": "Grupo Hj",
      "Email": "alessandra@grupohj.com.br",
      "CustomerCode": "18011",
      "Phone": "1126068585",
      "Whatsapp": "1178315556",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 506,
      "Name": "Aparecido Osmar Garcia",
      "Email": "fiuzicontabil@uol.com.br",
      "CustomerCode": "18012",
      "Phone": "1126790058",
      "Whatsapp": "1199922777",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 507,
      "Name": "Spot Contábil",
      "Email": "contato@spotcontabil.com.br",
      "CustomerCode": "18019",
      "Phone": "1123690591",
      "Whatsapp": "1193370593",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 508,
      "Name": "Regis Fabricio Pellizzon",
      "Email": "regis@amathiseguros.com.br",
      "CustomerCode": "18024",
      "Phone": "41995798000",
      "Whatsapp": "41995798000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 509,
      "Name": "Look Contabilidade Ltda. EPP",
      "Email": "lookandr@terra.com.br",
      "CustomerCode": "18029",
      "Phone": "1155119164",
      "Whatsapp": "1182831718",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 510,
      "Name": "Hassan Ahmad Hassan",
      "Email": "tradinghassan@yahoo.com.br",
      "CustomerCode": "18030",
      "Phone": "1155152624",
      "Whatsapp": "1179961025",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 511,
      "Name": "Ronaldo Manoel da Silva",
      "Email": "recont.contab@terra.com.br",
      "CustomerCode": "18037",
      "Phone": "1122125908",
      "Whatsapp": "1199695274",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 512,
      "Name": "Vanesca Mansani",
      "Email": "vanescamansani@icloud.com",
      "CustomerCode": "18039",
      "Phone": "1137821656",
      "Whatsapp": "1177624452",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 513,
      "Name": "Direto Contabilidade, Gestão e Consultoria Ltda.",
      "Email": "legalizacoes@diretocontabilidade.com.br",
      "CustomerCode": "18054",
      "Phone": "1132557287",
      "Whatsapp": "11986812123",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 514,
      "Name": "Ribeiro Contabilidade",
      "Email": "jijr@uol.com.br",
      "CustomerCode": "18055",
      "Phone": "1123352493",
      "Whatsapp": "1148526855",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 515,
      "Name": "Marte Organização Contábil Ltda. ME",
      "Email": "danieljuridico@uol.com.br",
      "CustomerCode": "18056",
      "Phone": "1132278696",
      "Whatsapp": "1149015677",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 516,
      "Name": "Carlos Henrique Simão",
      "Email": "carlos.henrique@jobadm.net",
      "CustomerCode": "18059",
      "Phone": "1136660004",
      "Whatsapp": "11975570055",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 517,
      "Name": "Berenice Gesualdi Masullo - ME",
      "Email": "contato@contabere.com.br",
      "CustomerCode": "18065",
      "Phone": "1126821713",
      "Whatsapp": "1199619903",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 519,
      "Name": "Capitallog Logistica Ltda.",
      "Email": "juridico@capitalsp.com.br",
      "CustomerCode": "18071",
      "Phone": "1155088100",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 520,
      "Name": "João Batista dos Santos Junior",
      "Email": "junior.jn@terra.com.br",
      "CustomerCode": "18073",
      "Phone": "1137190886",
      "Whatsapp": "1192007722",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 521,
      "Name": "Jair Armando Chaves",
      "Email": "chavesci@gmail.com",
      "CustomerCode": "18074",
      "Phone": "1158436536",
      "Whatsapp": "1174126361",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 522,
      "Name": "Escritório de Contabilidade Arrivabene Ltda. - EPP",
      "Email": "arrivabenesindical@terra.com.br",
      "CustomerCode": "18082",
      "Phone": "1132253737",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 523,
      "Name": "Censea Ocana Assessoria Contábil",
      "Email": "legal@ocana.com.br",
      "CustomerCode": "18087",
      "Phone": "1121550074",
      "Whatsapp": "1194760743",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 524,
      "Name": "Eiffel Organização Contábil Ltda.",
      "Email": "alinejanes@eiffelcnt.com.br",
      "CustomerCode": "18088",
      "Phone": "1120901979",
      "Whatsapp": "1194547101",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 525,
      "Name": "Ricardo José da Silva",
      "Email": "ricardojosedasilva@hotmail.com",
      "CustomerCode": "18096",
      "Phone": "1134738645",
      "Whatsapp": "1183463883",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 526,
      "Name": "Organização Contábil H. Advice Eireli ME",
      "Email": "geraldo.direcao@highadvicecontabil.com.br",
      "CustomerCode": "18101",
      "Phone": "1138641708",
      "Whatsapp": "1195919855",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 527,
      "Name": "Celia Prado",
      "Email": "celia.travagin@gmail.com",
      "CustomerCode": "18107",
      "Phone": "11",
      "Whatsapp": "1175554285",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 528,
      "Name": "Sabille Contabilidade",
      "Email": "eliascontab2@yahoo.com.br",
      "CustomerCode": "18108",
      "Phone": "1123010099",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 529,
      "Name": "Paulo Sergio Pavão",
      "Email": "felipe@felissacontabilidade.com.br",
      "CustomerCode": "18111",
      "Phone": "1126149412",
      "Whatsapp": "1170646556",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 530,
      "Name": "Muffato Assessoria Contábil",
      "Email": "carlos@muffato.cnt.br",
      "CustomerCode": "18113",
      "Phone": "",
      "Whatsapp": "1185010215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 531,
      "Name": "Rhoma Contabilidade Eireli",
      "Email": "william.gavaldao@uol.com.br",
      "CustomerCode": "18115",
      "Phone": "1129492777",
      "Whatsapp": "1179715243",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 532,
      "Name": "Lucilia da Silva Paes Nissato",
      "Email": "lucilia.nissato@gmail.com",
      "CustomerCode": "18116",
      "Phone": "1158410888",
      "Whatsapp": "1191331912",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 533,
      "Name": "Torres Contabilidade",
      "Email": "renatotorres.contab@gmail.com",
      "CustomerCode": "18117",
      "Phone": "1123851939",
      "Whatsapp": "1184096955",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 534,
      "Name": "Flexcont Assessoria Contábil Ltda.",
      "Email": "fabio.okamoto@flexcont.com.br",
      "CustomerCode": "18118",
      "Phone": "1133925366",
      "Whatsapp": "1195528852",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 536,
      "Name": "Lj Contabilidade Eireli",
      "Email": "lucas@contabilidadetotal.com",
      "CustomerCode": "18131",
      "Phone": "1129503593",
      "Whatsapp": "1182006181",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 537,
      "Name": "Luiz Augusto Silva da Conceição",
      "Email": "luiz@lascservicos.com.br",
      "CustomerCode": "18132",
      "Phone": "",
      "Whatsapp": "1158870699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 538,
      "Name": "Chaves & Chaves Serviços Empresarias Ltda.",
      "Email": "jairjchaves@msn.com",
      "CustomerCode": "18134",
      "Phone": "1133750508",
      "Whatsapp": "1196191548",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 539,
      "Name": "Altemani Advogados",
      "Email": "amablef@gmail.com",
      "CustomerCode": "18138",
      "Phone": "1135693676",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 540,
      "Name": "Organizações Z Consultoria Contábil - Eireli",
      "Email": "rafael@zatz.com.br",
      "CustomerCode": "18140",
      "Phone": "1136661476",
      "Whatsapp": "1199696889",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 541,
      "Name": "Maria Das Graças de Moura",
      "Email": "md_graca@hotmail.com",
      "CustomerCode": "18142",
      "Phone": "1135989670",
      "Whatsapp": "1161889415",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 542,
      "Name": "Wnr Consultoria Contábil",
      "Email": "a.diretoria@wnrconsultoria.com.br",
      "CustomerCode": "18144",
      "Phone": "1137945387",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 543,
      "Name": "Contabilidade Financeira Lacerda de Almeida Eireli",
      "Email": "claudio.lacerda@lacerdadealmeida.com.br",
      "CustomerCode": "18145",
      "Phone": "1127295410",
      "Whatsapp": "1191342775",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 544,
      "Name": "Edmilson Silva Santos",
      "Email": "kajimbure@gmail.com",
      "CustomerCode": "18146",
      "Phone": "1120529784",
      "Whatsapp": "1163496464",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 545,
      "Name": "Hakuo Nakamura",
      "Email": "contato@nakacont.com.br",
      "CustomerCode": "18147",
      "Phone": "1151832437",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 546,
      "Name": "Gs Domingues Contábil",
      "Email": "gerson@defattocontabil.com.br",
      "CustomerCode": "18148",
      "Phone": "1141020627",
      "Whatsapp": "1145946048",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 547,
      "Name": "Escritório Contábil Vida",
      "Email": "contabil.vida@gmail.com",
      "CustomerCode": "18153",
      "Phone": "",
      "Whatsapp": "1165104044",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 548,
      "Name": "Contábil Amarante",
      "Email": "valter.amarante@terra.com.br",
      "CustomerCode": "18154",
      "Phone": "1129498686",
      "Whatsapp": "11941024388",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 549,
      "Name": "Jaqueline Nunes Maia",
      "Email": "contaper.contabil@gmail.com",
      "CustomerCode": "18160",
      "Phone": "1126209726",
      "Whatsapp": "1162691452",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 550,
      "Name": "Gabriella Sileci Braga Gonçalves",
      "Email": "anderson@solutiontecno.com.br",
      "CustomerCode": "18163",
      "Phone": "13996341999",
      "Whatsapp": "13996341999",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 551,
      "Name": "New Work Contábil",
      "Email": "juridico@newworkcontabil.com.br",
      "CustomerCode": "18166",
      "Phone": "1134217776",
      "Whatsapp": "1149290568",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 552,
      "Name": "Jotabe Assessoria",
      "Email": "jeberger73@gmail.com",
      "CustomerCode": "18171",
      "Phone": "1124765883",
      "Whatsapp": "1140620936",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 553,
      "Name": "Atr Serviços Administrativos Ltda.",
      "Email": "contabil.atr@gmail.com",
      "CustomerCode": "18175",
      "Phone": "1136447603",
      "Whatsapp": "1196141149",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 554,
      "Name": "ACX Assessoria Contábil",
      "Email": "anapaula@acxcontabilidade.com.br",
      "CustomerCode": "18188",
      "Phone": "1123388414",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 555,
      "Name": "Douglas França Costa",
      "Email": "douglasfc@terra.com.br",
      "CustomerCode": "18200",
      "Phone": "1132581815",
      "Whatsapp": "1196697611",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 556,
      "Name": "Luciano Bovi Contábil",
      "Email": "lu1808sp@gmail.com",
      "CustomerCode": "18206",
      "Phone": "1120619117",
      "Whatsapp": "1191621123",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 557,
      "Name": "Solution Contabilidade Gerencial S/S Ltda.",
      "Email": "solution98@uol.com.br",
      "CustomerCode": "18210",
      "Phone": "1155241501",
      "Whatsapp": "1175206063",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 558,
      "Name": "Mauricio de Araújo Deienno",
      "Email": "contabilidade@metaltela.com.br",
      "CustomerCode": "18211",
      "Phone": "1120955266",
      "Whatsapp": "1194789340",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 559,
      "Name": "Roniel de Oliveira Ramos",
      "Email": "ronielramos.adv@gmail.com",
      "CustomerCode": "18217",
      "Phone": "1136577646",
      "Whatsapp": "1197314515",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 560,
      "Name": "Mauricio da Silva",
      "Email": "mauricio@lakotaconsultoria.com.br",
      "CustomerCode": "18222",
      "Phone": "",
      "Whatsapp": "1174526905",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 561,
      "Name": "J A Bugni - Apoio Administrativo",
      "Email": "bugni@terra.com.br",
      "CustomerCode": "18232",
      "Phone": "1129093709",
      "Whatsapp": "1199663699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 562,
      "Name": "Delta Brasil Contadores S/S Ltda.",
      "Email": "niveson@deltabrasil.com.br",
      "CustomerCode": "18236",
      "Phone": "1134735750",
      "Whatsapp": "1188725361",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 563,
      "Name": "G&C Assessoria Contábil Ltda.",
      "Email": "sued-cb@uol.com.br",
      "CustomerCode": "18249",
      "Phone": "1146615018",
      "Whatsapp": "1191857745",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 564,
      "Name": "Maxwell Bressane",
      "Email": "maxwellbressane@hotmail.com",
      "CustomerCode": "18259",
      "Phone": "1120910889",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 565,
      "Name": "Asa Assessoria Contábil",
      "Email": "martins_isabel36@yahoo.com.br",
      "CustomerCode": "18260",
      "Phone": "1141166559",
      "Whatsapp": "1148176277",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 566,
      "Name": "Nakaura Contabilidade",
      "Email": "legal@nakauracontabil.com.br",
      "CustomerCode": "18265",
      "Phone": "1150123598",
      "Whatsapp": "11988532427",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 567,
      "Name": "Apolo Brinquedos",
      "Email": "juridico@liderbrinquedos.com.br",
      "CustomerCode": "18268",
      "Phone": "1145435000",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 568,
      "Name": "Congalli Inteligencia Contabil Ltda",
      "Email": "galli@livrepme.com.br",
      "CustomerCode": "18270",
      "Phone": "1134598410",
      "Whatsapp": "11996345996",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 569,
      "Name": "Mbr Serv. Contábeis e Apoio Administrativo Ltda.",
      "Email": "paralegal@mbrconsultores.com.br",
      "CustomerCode": "18272",
      "Phone": "1130440446",
      "Whatsapp": "1164787673",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 570,
      "Name": "P&L Serviços Temporários Ltda.",
      "Email": "marcosmassa1964@gmail.com",
      "CustomerCode": "18274",
      "Phone": "1133239494",
      "Whatsapp": "1194307962",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 571,
      "Name": "Marcelo Damaso Polato",
      "Email": "marcelo.polato@outlook.com",
      "CustomerCode": "18281",
      "Phone": "1126396053",
      "Whatsapp": "1185927468",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 572,
      "Name": "Escritório Belem",
      "Email": "debby@ecb.com.br",
      "CustomerCode": "18283",
      "Phone": "1133856300",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 573,
      "Name": "Emndel Serviços Administrativos Ltda.",
      "Email": "edwin-delgado@hotmail.com",
      "CustomerCode": "18287",
      "Phone": "1145615939",
      "Whatsapp": "1148380910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 574,
      "Name": "Leader Consult e Contabilidade",
      "Email": "joseluiz@leaderconsult.com.br",
      "CustomerCode": "18289",
      "Phone": "1158412846",
      "Whatsapp": "11991640176",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 575,
      "Name": "Ckn Contabilidade Eireli",
      "Email": "cristino@cknconsultoria.com.br",
      "CustomerCode": "18295",
      "Phone": "1150630023",
      "Whatsapp": "1176074405",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 576,
      "Name": "Santos Advocacia",
      "Email": "juridico@santoscassiano.adv.br",
      "CustomerCode": "18297",
      "Phone": "1135781999",
      "Whatsapp": "1192351999",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 577,
      "Name": "Iest Serviços Contábeis Ltda.",
      "Email": "samuel.camargo@iestconsulting.com",
      "CustomerCode": "18298",
      "Phone": "1123095904",
      "Whatsapp": "1154349479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 578,
      "Name": "André Pimentel",
      "Email": "andre.pimentelnet@gmail.com",
      "CustomerCode": "18305",
      "Phone": "1123697183",
      "Whatsapp": "11942655777",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 579,
      "Name": "Alves & Artilha Consultoria Contábil Ltda.",
      "Email": "diretoria@alveseartilha.com",
      "CustomerCode": "18308",
      "Phone": "1132977511",
      "Whatsapp": "1185619678",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 580,
      "Name": "Tonini Serviços Contábeis",
      "Email": "toniniservicoscontabeis@gmail.com",
      "CustomerCode": "18310",
      "Phone": "1125373890",
      "Whatsapp": "1197146332",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 581,
      "Name": "Rosemeire Lopes de Aquino",
      "Email": "rose.andrade@savvyconsult.com.br",
      "CustomerCode": "18315",
      "Phone": "1148912220",
      "Whatsapp": "1199041628",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 582,
      "Name": "Reunew Organização Contábil S/S Ltda.",
      "Email": "danilo@reunew.com.br",
      "CustomerCode": "18319",
      "Phone": "1120902650",
      "Whatsapp": "11996617380",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 583,
      "Name": "Unifisco Serviços Contábeis",
      "Email": "unifisco@terra.com.br",
      "CustomerCode": "18325",
      "Phone": "1139928598",
      "Whatsapp": "1141092324",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 584,
      "Name": "Domingues Cavalcanti Serv Adm Ltda.",
      "Email": "cdcavalcanti@uol.com.br",
      "CustomerCode": "18326",
      "Phone": "1155130462",
      "Whatsapp": "1181290106",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 585,
      "Name": "Wilvale de Rigo S/A",
      "Email": "antonio.fagner@derigo.com.br",
      "CustomerCode": "18327",
      "Phone": "1138391900",
      "Whatsapp": "1195152351",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 586,
      "Name": "Thiago Pinaffi",
      "Email": "t.pinaffi@hotmail.com",
      "CustomerCode": "18333",
      "Phone": "1194338533",
      "Whatsapp": "1194338533",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 587,
      "Name": "Dra Soluções e Transportes Ltda-ME",
      "Email": "diego.rsilva14@gmail.com",
      "CustomerCode": "18340",
      "Phone": "1125210002",
      "Whatsapp": "1164280034",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 588,
      "Name": "Ipava Contábil",
      "Email": "ipavacontabil.edson@gmail.com",
      "CustomerCode": "18341",
      "Phone": "1158957878",
      "Whatsapp": "1198348119",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 589,
      "Name": "José Moreira Campos",
      "Email": "campos.jm@globomail.com",
      "CustomerCode": "18343",
      "Phone": "1127917981",
      "Whatsapp": "1165353514",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 590,
      "Name": "M7 Contabilidade",
      "Email": "contabilidadem7@gmail.com",
      "CustomerCode": "18345",
      "Phone": "1134770704",
      "Whatsapp": "1140710989",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 591,
      "Name": "Escritório Governante",
      "Email": "escritoriogovernante@gmail.com",
      "CustomerCode": "18354",
      "Phone": "1333039510",
      "Whatsapp": "1193289219",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 592,
      "Name": "Max 3 Locação",
      "Email": "tereza@max3locadora.com.br",
      "CustomerCode": "18359",
      "Phone": "1130409500",
      "Whatsapp": "179172721",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 593,
      "Name": "Auttoria Consultoria",
      "Email": "alexis.campos@auttoria.adm.br",
      "CustomerCode": "18360",
      "Phone": "1132973985",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 594,
      "Name": "Priolli da Cunha Sociedade de Advogados",
      "Email": "rafael@priollidacunha.com",
      "CustomerCode": "18361",
      "Phone": "1155310484",
      "Whatsapp": "1169905050",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 595,
      "Name": "Phocux Contabilidade",
      "Email": "nelson_cmorais@yahoo.com.br",
      "CustomerCode": "18362",
      "Phone": "1155217322",
      "Whatsapp": "1181019868",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 596,
      "Name": "Lrs Assessoria Contábil",
      "Email": "laercio.santoss@gmail.com",
      "CustomerCode": "18365",
      "Phone": "1198624435",
      "Whatsapp": "1186244351",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 597,
      "Name": "Josilene Cristina Ferreira",
      "Email": "josy@contbless.com.br",
      "CustomerCode": "18373",
      "Phone": "1122399696",
      "Whatsapp": "1159116658",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 598,
      "Name": "Ks2B Consultoria Contábil e Treinamento Ltda.",
      "Email": "liana@kapitalsistemico.com.br",
      "CustomerCode": "18380",
      "Phone": "",
      "Whatsapp": "1159501015",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 599,
      "Name": "Gabriel Silva Guedes",
      "Email": "gabriel_contabil@hotmail.com",
      "CustomerCode": "18385",
      "Phone": "1127226685",
      "Whatsapp": "1183413792",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 600,
      "Name": "Manoel de Almeida Passos",
      "Email": "mapcontabil@uol.com.br",
      "CustomerCode": "18387",
      "Phone": "1129247871",
      "Whatsapp": "1199780480",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 601,
      "Name": "Aline Domingues Bertoldo",
      "Email": "aline.domingues@expressotaubate.com.br",
      "CustomerCode": "18389",
      "Phone": "1236271222",
      "Whatsapp": "12974019851",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 602,
      "Name": "New Partner Contabilidade Ltda.",
      "Email": "marcelo.koga@newpartner.net.br",
      "CustomerCode": "18391",
      "Phone": "1122070834",
      "Whatsapp": "1181969957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 603,
      "Name": "Marinho & Wiltshire Advogados Associados",
      "Email": "contato@marinhowiltshire.com.br",
      "CustomerCode": "18393",
      "Phone": "1155234939",
      "Whatsapp": "1147286974",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 604,
      "Name": "Daniel Leonam da Silva Gomes",
      "Email": "gomesbertolazzo15@gmail.com",
      "CustomerCode": "18395",
      "Phone": "1122170084",
      "Whatsapp": "1152045382",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 605,
      "Name": "Rubenilton Alves de Melo",
      "Email": "stepimentamelo@gmail.com",
      "CustomerCode": "18400",
      "Phone": "1129172643",
      "Whatsapp": "1173624295",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 606,
      "Name": "Cassio Leandro Soares",
      "Email": "cassiosoares1980@gmail.com",
      "CustomerCode": "18406",
      "Phone": "1137193707",
      "Whatsapp": "1191934896",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 607,
      "Name": "Luvisetto & Vieira Assessoria Contábil SS",
      "Email": "fabiana@luvicontabilidade.com.br",
      "CustomerCode": "18407",
      "Phone": "1126183386",
      "Whatsapp": "1140762636",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 608,
      "Name": "Marcos Cassimiro Gomes",
      "Email": "atendimentosevilha@gmail.com",
      "CustomerCode": "18409",
      "Phone": "1126750762",
      "Whatsapp": "1141867870",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 609,
      "Name": "C Brasil Serviços Contábeis S/S Ltda. - EPP",
      "Email": "gilson@cbrasilcontabilidade.com.br",
      "CustomerCode": "18416",
      "Phone": "1132293027",
      "Whatsapp": "1191274131",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 610,
      "Name": "Concentrix Brasil",
      "Email": "lucas.luna@concentrix.com",
      "CustomerCode": "18418",
      "Phone": "1126787227",
      "Whatsapp": "1189742976",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 611,
      "Name": "Innovate Assessoria Contábil e Financeira Ltda.",
      "Email": "jose.castilho@innovatecontabil.com.br",
      "CustomerCode": "18424",
      "Phone": "1129783255",
      "Whatsapp": "1148295518",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 612,
      "Name": "Luís Do Nascimento",
      "Email": "luis.nascimento.1010@gmail.com",
      "CustomerCode": "18426",
      "Phone": "1155113804",
      "Whatsapp": "1180275525",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 613,
      "Name": "Prospect Consultoria e Planejamento Empresarial",
      "Email": "ubirajara@rioto.adv.br",
      "CustomerCode": "18427",
      "Phone": "1155215030",
      "Whatsapp": "1154270660",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 614,
      "Name": "Sandoval Mendes Oliveira",
      "Email": "sandoval.mendes@terra.com.br",
      "CustomerCode": "18429",
      "Phone": "1120815192",
      "Whatsapp": "1152954102",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 615,
      "Name": "Solução Contabil Empresarial e Patrimonial LTDA",
      "Email": "adm@contabilsolucao.com.br",
      "CustomerCode": "18431",
      "Phone": "1129416578",
      "Whatsapp": "11976040480",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 616,
      "Name": "Contábil Souza Dias",
      "Email": "contabilsouzadias@uol.com.br",
      "CustomerCode": "18433",
      "Phone": "1139315213",
      "Whatsapp": "1196374354",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 617,
      "Name": "Santos Aguiar e Signorelli Sociedade de Adv",
      "Email": "fernando@saslaw.com.br",
      "CustomerCode": "18434",
      "Phone": "1139693304",
      "Whatsapp": "1182226148",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 618,
      "Name": "R&M Contabilidade",
      "Email": "ra.contabilidade.contato@gmail.com",
      "CustomerCode": "18435",
      "Phone": "1156148490",
      "Whatsapp": "1181788218",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 619,
      "Name": "Lobby Contábil",
      "Email": "william.moreira@lobbycontabil.com.br",
      "CustomerCode": "18436",
      "Phone": "1140634991",
      "Whatsapp": "1147889100",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 620,
      "Name": "Antônio Gomes",
      "Email": "antonio@ag10contabil.com.br",
      "CustomerCode": "18437",
      "Phone": "11983397968",
      "Whatsapp": "1198339796",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 621,
      "Name": "Jt Prestação de Serviços Contábeis e Digitação",
      "Email": "jennifer@gjacintho.com.br",
      "CustomerCode": "18438",
      "Phone": "1131242555",
      "Whatsapp": "1185365138",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 622,
      "Name": "Conticontábil",
      "Email": "econti9_8@hotmail.com",
      "CustomerCode": "18439",
      "Phone": "1137192278",
      "Whatsapp": "1186176505",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 623,
      "Name": "Roseli Ferreira Guimaraes",
      "Email": "aliancaroseli@uol.com.br",
      "CustomerCode": "18440",
      "Phone": "1138310141",
      "Whatsapp": "1171662518",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 624,
      "Name": "Metalúrgica Mauser Indústria e Comércio Ltda.",
      "Email": "contabilidade@mauser.com.br",
      "CustomerCode": "18442",
      "Phone": "1139090912",
      "Whatsapp": "1198173197",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 625,
      "Name": "Agnaldo Arley Roz",
      "Email": "agnaldo.roz10@gmail.com",
      "CustomerCode": "18445",
      "Phone": "1141116600",
      "Whatsapp": "1153933739",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 626,
      "Name": "Mir Assessoria Contábil e Financeira S/S Ltda.",
      "Email": "contmir@mircontabil.com.br",
      "CustomerCode": "18447",
      "Phone": "1150343322",
      "Whatsapp": "1191397013",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 627,
      "Name": "Oziel Correa de Oliveira",
      "Email": "ozielcorreadeoliveira@gmail.com",
      "CustomerCode": "18448",
      "Phone": "",
      "Whatsapp": "1199809238",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 628,
      "Name": "Rp Gonzales Tecnologia da Informação ME",
      "Email": "rodrigo.pierini1985@icloud.com",
      "CustomerCode": "18450",
      "Phone": "1176596107",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 629,
      "Name": "Leonel da Silva Ameixieira Filho",
      "Email": "leonel@personnalidade.com",
      "CustomerCode": "18453",
      "Phone": "1129869000",
      "Whatsapp": "1192356425",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 630,
      "Name": "Eduardo Moises",
      "Email": "societario@contabilmoyses.com.br",
      "CustomerCode": "18454",
      "Phone": "1150950868",
      "Whatsapp": "1195815223",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 631,
      "Name": "Py Oba Assessoria e Consultoria Contábil",
      "Email": "pedroyoshio@gmail.com",
      "CustomerCode": "18456",
      "Phone": "1126266361",
      "Whatsapp": "1194378359",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 632,
      "Name": "Rueda & Rueda Sociedade de Advogados",
      "Email": "silvio@ruedaerueda.com.br",
      "CustomerCode": "18457",
      "Phone": "8131286150",
      "Whatsapp": "8195486996",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 633,
      "Name": "Josué Alves da Silva",
      "Email": "contabilidade.jas@gmail.com",
      "CustomerCode": "18458",
      "Phone": "1122809724",
      "Whatsapp": "1188258511",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 634,
      "Name": "Log Tech Assessoria Contábil SS Ltda.",
      "Email": "logtech@logtechcontabil.com.br",
      "CustomerCode": "18459",
      "Phone": "1132564009",
      "Whatsapp": "11971882725",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 635,
      "Name": "Bellas Contábeis Eirelli ME",
      "Email": "marilene@bellascontabeis.com",
      "CustomerCode": "18460",
      "Phone": "1125076399",
      "Whatsapp": "1180102906",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 636,
      "Name": "Marinalva Aparecida Batista Bras",
      "Email": "nalvaabb@yahoo.com.br",
      "CustomerCode": "18461",
      "Phone": "1195395011",
      "Whatsapp": "119539511",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 637,
      "Name": "Gesplanning Apoio Empresarial",
      "Email": "valter@gesplanoffice.com.br",
      "CustomerCode": "18462",
      "Phone": "1131569363",
      "Whatsapp": "1197804971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 638,
      "Name": "Marques Assessoria e Contabilidade Ltda. - ME",
      "Email": "miguel@marquescontabilidade-crc.com.br",
      "CustomerCode": "18463",
      "Phone": "1130813167",
      "Whatsapp": "1186144630",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 639,
      "Name": "Valentim Donizete Do Carmo",
      "Email": "valentim.contabilfinanceiro@uol.com.br",
      "CustomerCode": "18464",
      "Phone": "1129830507",
      "Whatsapp": "1199123925",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 640,
      "Name": "Raul Borges Barcelos Jr",
      "Email": "raulbarce@gmail.com",
      "CustomerCode": "18465",
      "Phone": "1129770082",
      "Whatsapp": "1191774194",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 641,
      "Name": "Leros Energia e Participações S.A.",
      "Email": "societario@grupoleros.com.br",
      "CustomerCode": "18466",
      "Phone": "1130462565",
      "Whatsapp": "1159169214",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 642,
      "Name": "Lts Line Travel Service Viagens e Turismo Ltda.",
      "Email": "filipe.santos@ltsviagens.com",
      "CustomerCode": "18467",
      "Phone": "1131225470",
      "Whatsapp": "1181656960",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 643,
      "Name": "Sidney Wilson Silva",
      "Email": "simonalwilson8@gmail.com",
      "CustomerCode": "18468",
      "Phone": "1131562488",
      "Whatsapp": "1147770418",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 644,
      "Name": "Ana Paula Nishimura Nakazone",
      "Email": "apn.nakazone@gmail.com",
      "CustomerCode": "18469",
      "Phone": "1122116799",
      "Whatsapp": "1193165136",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 645,
      "Name": "Mario Leinfelder Savio",
      "Email": "contabilidadesapopemba@gmail.com",
      "CustomerCode": "18470",
      "Phone": "1135391030",
      "Whatsapp": "1186418494",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 646,
      "Name": "Falcon, Gail, Feijó e Sluiuzas Sociedade de Advoga",
      "Email": "jaq@a3advogados.com",
      "CustomerCode": "18471",
      "Phone": "1130954200",
      "Whatsapp": "1183319414",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 647,
      "Name": "Quality Score Contabilidade Ltda.",
      "Email": "cintia.cruz@qsco.com.br",
      "CustomerCode": "18473",
      "Phone": "1125485136",
      "Whatsapp": "1193373857",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 648,
      "Name": "Altimus Contábil Ltda.",
      "Email": "mairan@altimuscontabil.com",
      "CustomerCode": "18474",
      "Phone": "1155999079",
      "Whatsapp": "1175486255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 649,
      "Name": "Elaine Cristina Gaspareto",
      "Email": "elainegasparetto@gmail.com",
      "CustomerCode": "18475",
      "Phone": "1143238045",
      "Whatsapp": "1172924700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 650,
      "Name": "Patrícia de Freitas Doutor",
      "Email": "patriciadoutor@gmail.com",
      "CustomerCode": "18476",
      "Phone": "1123877335",
      "Whatsapp": "1180837335",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 651,
      "Name": "Rogerio Tadeu Circuncisão",
      "Email": "rogtadcir@gmail.com",
      "CustomerCode": "18477",
      "Phone": "1140250457",
      "Whatsapp": "1140250457",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 652,
      "Name": "Jaime Pinheiro Participações S.A.",
      "Email": "diego.ricoboni@jppsa.com.br",
      "CustomerCode": "18478",
      "Phone": "1121871473",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 653,
      "Name": "Sevilha Contabilidade",
      "Email": "muffato@sevilha.com.br",
      "CustomerCode": "18479",
      "Phone": "1128796669",
      "Whatsapp": "1185010215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 655,
      "Name": "Wcarvalho Serviços de Apoio Administrativo",
      "Email": "societario@carvalhocontabil.com.br",
      "CustomerCode": "18483",
      "Phone": "1120601061",
      "Whatsapp": "1147108029",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 656,
      "Name": "Setorial Serviços e Assessoria Contábil",
      "Email": "edrielen@setorialcontabil.com.br",
      "CustomerCode": "18550",
      "Phone": "1133133940",
      "Whatsapp": "11968360665",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 657,
      "Name": "Silva & Gouveia Assessoria Contábil Ltda.",
      "Email": "contato@silvaegouveia.com",
      "CustomerCode": "18551",
      "Phone": "1135950400",
      "Whatsapp": "1396502776",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 658,
      "Name": "Visão Contábil Ltda.",
      "Email": "diretoria@contabilvisao.com.br",
      "CustomerCode": "18552",
      "Phone": "1136755001",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 659,
      "Name": "Neo Contábil Ass. e Consultoria Em Contabilidade",
      "Email": "fonseca.contabil@terra.com.br",
      "CustomerCode": "18553",
      "Phone": "1137695870",
      "Whatsapp": "1167543663",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 660,
      "Name": "I9 Contabilidade Assessoria e Consultoria",
      "Email": "i9contabil.gerencia@gmail.com",
      "CustomerCode": "18554",
      "Phone": "1141021929",
      "Whatsapp": "1167650675",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 661,
      "Name": "Edcon Contabilidade Eirelli ME",
      "Email": "edcontcontabil@yahoo.com",
      "CustomerCode": "18555",
      "Phone": "1156150886",
      "Whatsapp": "1187363704",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 662,
      "Name": "Jorge Robson Barreto Silva",
      "Email": "barcon@uol.com.br",
      "CustomerCode": "18561",
      "Phone": "1137420102",
      "Whatsapp": "1191296672",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 663,
      "Name": "Ubajara Casartelli",
      "Email": "ubajara@casartelli.com.br",
      "CustomerCode": "18562",
      "Phone": "1134592562",
      "Whatsapp": "1176940611",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 664,
      "Name": "2FD Contabilidade - ME",
      "Email": "2fdcontabilidade@gmail.com",
      "CustomerCode": "18568",
      "Phone": "1141142966",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 665,
      "Name": "Ronaldo Ribeiro de Andrade",
      "Email": "ronaldo.andradeealmeida@gmail.com",
      "CustomerCode": "18569",
      "Phone": "1138547038",
      "Whatsapp": "1180627149",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 666,
      "Name": "Ocd Contabilidade Eireli",
      "Email": "carlos@ocd.com.br",
      "CustomerCode": "18570",
      "Phone": "1127647566",
      "Whatsapp": "1191290991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 667,
      "Name": "Jorge Augusto Amorim Nogueira",
      "Email": "jorgeaugustoamorim@gmail.com",
      "CustomerCode": "18573",
      "Phone": "1150830486",
      "Whatsapp": "1134209166",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 668,
      "Name": "Equilíbrio Escritório Contábil SS ME",
      "Email": "simone.silva@contabilequilibrio.com.br",
      "CustomerCode": "18574",
      "Phone": "1123085473",
      "Whatsapp": "1196796688",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 669,
      "Name": "Bdo Rcs Auditores Independentes ­ Sociedade Simpl",
      "Email": "controladoria_paralegal@bdo.com.br",
      "CustomerCode": "18575",
      "Phone": "1138485880",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 670,
      "Name": "Amauri Mateus",
      "Email": "mateus.amauri26@gmail.com",
      "CustomerCode": "18576",
      "Phone": "1184195302",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 671,
      "Name": "Rafael Lanza",
      "Email": "rafael@lanzacontabilidade.com.br",
      "CustomerCode": "18577",
      "Phone": "1123090900",
      "Whatsapp": "1142068700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 672,
      "Name": "Fertábil Serviços Contábeis Ltda.",
      "Email": "francisco.fernandes@fertabil.com.br",
      "CustomerCode": "18578",
      "Phone": "1122817020",
      "Whatsapp": "1199757010",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 673,
      "Name": "Kadet Assessoria Contábil Ltda.",
      "Email": "amanda@kaed.com.br",
      "CustomerCode": "18579",
      "Phone": "1122937774",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 674,
      "Name": "Rd Benedetti Serviços Empresariais Ltda. ME",
      "Email": "cananeiacont@uol.com.br",
      "CustomerCode": "18581",
      "Phone": "1120600046",
      "Whatsapp": "1193740575",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 675,
      "Name": "Maria Do Carmo dos Santos Pereira",
      "Email": "mariadcpereira@gmail.com",
      "CustomerCode": "18583",
      "Phone": "1126281509",
      "Whatsapp": "1174929099",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 676,
      "Name": "Maria da Graça de Oliveira Gonçalves Silveira",
      "Email": "maogs@terra.com.br",
      "CustomerCode": "18584",
      "Phone": "1126017004",
      "Whatsapp": "1192013993",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 677,
      "Name": "Base Forte Contabilidade e Processamento de Dados",
      "Email": "carlosalfa@uol.com.br",
      "CustomerCode": "18585",
      "Phone": "1155719356",
      "Whatsapp": "1194542194",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 678,
      "Name": "Leirison Marinho da Silva",
      "Email": "leirison@gmail.com",
      "CustomerCode": "18588",
      "Phone": "1176915474",
      "Whatsapp": "1176915474",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 679,
      "Name": "Eduardo Azize Martins",
      "Email": "elc.consultoria@gmail.com",
      "CustomerCode": "18589",
      "Phone": "1123315194",
      "Whatsapp": "1191991655",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 680,
      "Name": "Escritório Santana de Contabilidade Ltda.",
      "Email": "tersildo@escritoriosantana.com.br",
      "CustomerCode": "18591",
      "Phone": "1130187700",
      "Whatsapp": "1199991262",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 681,
      "Name": "Valdeci Medeiros",
      "Email": "valdeci@valdecicontabilidade.cnt.br",
      "CustomerCode": "18592",
      "Phone": "1146173262",
      "Whatsapp": "1165858064",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 682,
      "Name": "Guerra Siscar Consultoria Empresarial Ltda.",
      "Email": "yara@guerrasiscar.com.br",
      "CustomerCode": "18593",
      "Phone": "",
      "Whatsapp": "1182192327",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 683,
      "Name": "Maribene Gestão Contábil Ltda.",
      "Email": "viviane@maribene.com.br",
      "CustomerCode": "18594",
      "Phone": "1137961563",
      "Whatsapp": "1163004053",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 684,
      "Name": "Valtenice Andrade",
      "Email": "nicevieira_3@hotmail.com",
      "CustomerCode": "18596",
      "Phone": "1155281263",
      "Whatsapp": "1162497833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 685,
      "Name": "Premium Contabilidade e Auditoria Financeira",
      "Email": "marta@preconfi.com.br",
      "CustomerCode": "18597",
      "Phone": "1147896450",
      "Whatsapp": "1191990914",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 686,
      "Name": "Thiago Luiz Ribeiro",
      "Email": "thiago.luiz2404@hotmail.com",
      "CustomerCode": "18599",
      "Phone": "1129573602",
      "Whatsapp": "1153852369",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 687,
      "Name": "Infoco Assessoria Em Tecnologia e Gestão Ltda.",
      "Email": "marcelo.ventola@infoco.com.br",
      "CustomerCode": "18600",
      "Phone": "1125063649",
      "Whatsapp": "1199690535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 689,
      "Name": "José de Souza Rocha Poço",
      "Email": "jose.poco40@gmail.com",
      "CustomerCode": "18602",
      "Phone": "1138590397",
      "Whatsapp": "1181862922",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 690,
      "Name": "Evolution Contábil Ltda.",
      "Email": "atendimento@evolutioncontabil.net",
      "CustomerCode": "18603",
      "Phone": "1126850718",
      "Whatsapp": "1151244413",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 691,
      "Name": "Eso Contabilidade",
      "Email": "erivanfilho@esoconta.com.br",
      "CustomerCode": "18604",
      "Phone": "1128940007",
      "Whatsapp": "1177264892",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 692,
      "Name": "Fabiana Ferreira dos Santos",
      "Email": "juridico@isecbrasil.com.br",
      "CustomerCode": "18605",
      "Phone": "1133207457",
      "Whatsapp": "1169396168",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 693,
      "Name": "Heindl Contabilidade Eireli",
      "Email": "andreaheindl@hotmail.com",
      "CustomerCode": "18606",
      "Phone": "1122425446",
      "Whatsapp": "1196770757",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 694,
      "Name": "Carla Andrioli",
      "Email": "candrioli2015@gmail.com",
      "CustomerCode": "18607",
      "Phone": "1132095774",
      "Whatsapp": "1192721701",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 695,
      "Name": "Carlos Eduardo Gonçalves",
      "Email": "carlos@cgoncalves.adv.br",
      "CustomerCode": "18608",
      "Phone": "1155041928",
      "Whatsapp": "1176860784",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 696,
      "Name": "Mads Assessoria e Consultoria Contábil Ltda-ME",
      "Email": "mads.silva@madsassessoriacontabil.com.br",
      "CustomerCode": "18609",
      "Phone": "1138395921",
      "Whatsapp": "1170513327",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 697,
      "Name": "Madis Rodbel Soluções de Ponto e Acesso Ltda.",
      "Email": "rodrigo.cabral@pamdir.com.br",
      "CustomerCode": "18610",
      "Phone": "1136464116",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 698,
      "Name": "Haroldo José de Oliveira",
      "Email": "contabilidade@haude.com.br",
      "CustomerCode": "18611",
      "Phone": "1141058806",
      "Whatsapp": "1185558781",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 699,
      "Name": "Elizete Carlos dos Santos",
      "Email": "elizetecarlos2@gmail.com",
      "CustomerCode": "18612",
      "Phone": "1155109225",
      "Whatsapp": "1175018132",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 700,
      "Name": "Elkom Assessoria",
      "Email": "contato@elkom.com.br",
      "CustomerCode": "18613",
      "Phone": "1141130063",
      "Whatsapp": "1182184222",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 701,
      "Name": "Macre Alimentação Saudável Eireli - EPP",
      "Email": "saladenhapaulista@gmail.com",
      "CustomerCode": "18614",
      "Phone": "1131710837",
      "Whatsapp": "1197995123",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 702,
      "Name": "Primelexis Consultoria Contábil Ltda. ME",
      "Email": "marciorcosta@gmail.com",
      "CustomerCode": "18616",
      "Phone": "1182257740",
      "Whatsapp": "1182257740",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 703,
      "Name": "Atcb Consultoria Auditoria e Contabilidade",
      "Email": "alexandre@atcbconsultoria.com.br",
      "CustomerCode": "18617",
      "Phone": "1135674155",
      "Whatsapp": "1176049919",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 704,
      "Name": "West Atlantic Company Importação e Exportação",
      "Email": "director@westatlantic.com.br",
      "CustomerCode": "18619",
      "Phone": "1131410107",
      "Whatsapp": "1140030924",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 705,
      "Name": "Contavic - Contabilidade e Assessoria Empresarial",
      "Email": "ari@contavic.com.br",
      "CustomerCode": "18620",
      "Phone": "1138044245",
      "Whatsapp": "1193041699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 706,
      "Name": "Matias Rodrigues da Silva",
      "Email": "srmatias@yahoo.com.br",
      "CustomerCode": "18621",
      "Phone": "1120562688",
      "Whatsapp": "1198769596",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 707,
      "Name": "Alexandre de Freitas França",
      "Email": "francaalexandre@yahoo.com.br",
      "CustomerCode": "18622",
      "Phone": "1137913495",
      "Whatsapp": "1176376755",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 708,
      "Name": "Marcello Klug Vieira",
      "Email": "marcello73@gmail.com",
      "CustomerCode": "18623",
      "Phone": "1135682128",
      "Whatsapp": "1199866987",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 709,
      "Name": "Arnaldo Pereira da Silva",
      "Email": "alinhar@alinharcontabilidade.com.br",
      "CustomerCode": "18624",
      "Phone": "1142139380",
      "Whatsapp": "1148293504",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 710,
      "Name": "Prime Advice Contabilidade Ltda.",
      "Email": "rafael@primeadvice.com.br",
      "CustomerCode": "18625",
      "Phone": "1120225696",
      "Whatsapp": "1183880144",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 711,
      "Name": "Alessandra Bezerra",
      "Email": "alessandra@labezerra.adv.br",
      "CustomerCode": "18626",
      "Phone": "1127370301",
      "Whatsapp": "1175966598",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 712,
      "Name": "Maria Flavia dos Santos Mistreta",
      "Email": "lyssaconsultoria@uol.com.br",
      "CustomerCode": "18628",
      "Phone": "1127438051",
      "Whatsapp": "1186851536",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 713,
      "Name": "Daniele da Silva Rosa Assessoria Contábil",
      "Email": "fdcontabil2017@gmail.com",
      "CustomerCode": "18630",
      "Phone": "1125185501",
      "Whatsapp": "1140396219",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 714,
      "Name": "Rafael Arcangelo Neto",
      "Email": "arcangel@uol.com.br",
      "CustomerCode": "18632",
      "Phone": "1122953130",
      "Whatsapp": "1196680444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 715,
      "Name": "Renato Lopes de Freitas",
      "Email": "innover.contabil@gmail.com",
      "CustomerCode": "18633",
      "Phone": "1155156217",
      "Whatsapp": "1197042521",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 716,
      "Name": "Avalia Contabilidade Ltda. - ME",
      "Email": "cesar.camargo@avaliacontabilidade.com.br",
      "CustomerCode": "18634",
      "Phone": "1132511067",
      "Whatsapp": "1150209103",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 717,
      "Name": "Gobpo Assessoria Contábil Eireli",
      "Email": "fernando.souza@gobpo.com.br",
      "CustomerCode": "18636",
      "Phone": "1143171770",
      "Whatsapp": "1158767788",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 718,
      "Name": "Elite Digitação e Serviços Ltda.",
      "Email": "elitecontabilsp@gmail.com",
      "CustomerCode": "18637",
      "Phone": "1156661000",
      "Whatsapp": "1163735969",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 719,
      "Name": "Barboza Assessoria Fiscal Tributaria e Paralegal",
      "Email": "barbozafiscal@gmail.com",
      "CustomerCode": "18638",
      "Phone": "1128850914",
      "Whatsapp": "1185517637",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 720,
      "Name": "Danillo L Do N Ferreira Consultoria e Assessoria C",
      "Email": "danillo.laurentino@consultoriaathos.com",
      "CustomerCode": "18639",
      "Phone": "1129638907",
      "Whatsapp": "1187859535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 721,
      "Name": "Walter Landio dos Santos",
      "Email": "walter.proc@hotmail.com",
      "CustomerCode": "18640",
      "Phone": "1126458445",
      "Whatsapp": "1184452270",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 722,
      "Name": "Wilson Cardoso Costa Filho",
      "Email": "wilson.ccf@uol.com.br",
      "CustomerCode": "18642",
      "Phone": "1155491399",
      "Whatsapp": "1186566210",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 723,
      "Name": "Ds2 Consultoria Contábil Ltda. - EPP",
      "Email": "legal01@ds2consultoria.com.br",
      "CustomerCode": "18643",
      "Phone": "1155211852",
      "Whatsapp": "1164469429",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 724,
      "Name": "João Carlos Bueno Abitante",
      "Email": "joao.abitante.contabeis@gmail.com",
      "CustomerCode": "18645",
      "Phone": "1131293596",
      "Whatsapp": "11976910766",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 725,
      "Name": "Planner Assessoria Contábil e Empresarial SS Ltda.",
      "Email": "contato@planner-contab.com.br",
      "CustomerCode": "18646",
      "Phone": "1138461686",
      "Whatsapp": "118331110",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 726,
      "Name": "Vladimir Bueno da Silva Junior",
      "Email": "vladimirbueno@uol.com.br",
      "CustomerCode": "18648",
      "Phone": "1126455547",
      "Whatsapp": "1198437461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 727,
      "Name": "D.H.P. Serviços Contábeis Ltda.",
      "Email": "denis.rguima@hotmail.com",
      "CustomerCode": "18649",
      "Phone": "1155100811",
      "Whatsapp": "1182062449",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 728,
      "Name": "Simone de Souza Leme",
      "Email": "simone.sleme@gmail.com",
      "CustomerCode": "18651",
      "Phone": "1132279821",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 729,
      "Name": "Thiago Marques Lopes dos Santos",
      "Email": "hmcontabil@yahoo.com.br",
      "CustomerCode": "18652",
      "Phone": "1123860576",
      "Whatsapp": "1196700259",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 730,
      "Name": "Roberto Luís Amaral dos Santos Farias",
      "Email": "rfgestaoempresarial4@gmail.com",
      "CustomerCode": "18653",
      "Phone": "1142555962",
      "Whatsapp": "1140250757",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 731,
      "Name": "Calazans de Freitas & Advogados Associados",
      "Email": "flavio@calazansdefreitas.adv.br",
      "CustomerCode": "18654",
      "Phone": "1127686814",
      "Whatsapp": "1194320334",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 732,
      "Name": "Leandro Figueiredo Silva",
      "Email": "leandro.figueiredo@coelhoadv.com.br",
      "CustomerCode": "18655",
      "Phone": "1130810944",
      "Whatsapp": "1194343597",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 733,
      "Name": "Alexsandro Valentim",
      "Email": "valentim@valentim.net.br",
      "CustomerCode": "18657",
      "Phone": "1139940229",
      "Whatsapp": "11981982195",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 734,
      "Name": "Maria Aparecida de Souza Celestino",
      "Email": "wcf.contabilidade@gmail.com",
      "CustomerCode": "18658",
      "Phone": "1129189215",
      "Whatsapp": "1183907800",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 735,
      "Name": "Nova Era Assessoria e Consultoria Contábil",
      "Email": "gabriel@necont.com.br",
      "CustomerCode": "18659",
      "Phone": "1133117979",
      "Whatsapp": "1142293281",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 736,
      "Name": "Persevera Gestão de Recursos Ltda.",
      "Email": "thogarma.ribeiro@persevera.com.br",
      "CustomerCode": "18660",
      "Phone": "1126267229",
      "Whatsapp": "1189081027",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 737,
      "Name": "José Carlos Rosolia",
      "Email": "rosoliaj@gmail.com",
      "CustomerCode": "18661",
      "Phone": "1156152389",
      "Whatsapp": "1196254155",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 738,
      "Name": "André Santos de Jesus",
      "Email": "andresantos00081@bol.com.br",
      "CustomerCode": "18666",
      "Phone": "1130590559",
      "Whatsapp": "1170277513",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 739,
      "Name": "Leandro Mendonca de Oliveira",
      "Email": "leandro@mmendonca.adv.br",
      "CustomerCode": "18669",
      "Phone": "1138721222",
      "Whatsapp": "1140039014",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 740,
      "Name": "Vida Nova Contabilidade",
      "Email": "vidanovacontabilidade@yahoo.com.br",
      "CustomerCode": "18670",
      "Phone": "1141191371",
      "Whatsapp": "1154972479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 741,
      "Name": "Jussara Aparecida da Costa Pinson Guimaraes",
      "Email": "jussara2810@hotmail.com",
      "CustomerCode": "18671",
      "Phone": "1129599072",
      "Whatsapp": "1183031592",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 742,
      "Name": "Valmir Lunguinho dos Santos",
      "Email": "lunguinho2012@gmail.com",
      "CustomerCode": "18672",
      "Phone": "1132073200",
      "Whatsapp": "1147375435",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 743,
      "Name": "Universus Adm e Corretagem de Seguros Ltda.",
      "Email": "waltergsilva@uol.com.br",
      "CustomerCode": "18673",
      "Phone": "1155754616",
      "Whatsapp": "1176286654",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 744,
      "Name": "Luciano Nogueira Lucas Advogados Associados",
      "Email": "marcelo.dias@lnladvogados.com.br",
      "CustomerCode": "18644",
      "Phone": "1532595540",
      "Whatsapp": "1174226198",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 745,
      "Name": "Karoline Cristina de Souza Silva",
      "Email": "juridico@salvatto.com.br",
      "CustomerCode": "18675",
      "Phone": "1134267683",
      "Whatsapp": "1174284421",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 746,
      "Name": "Renata Blois Siqueira",
      "Email": "doc@bloisassociados.com.br",
      "CustomerCode": "18676",
      "Phone": "1137980649",
      "Whatsapp": "1193835172",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 747,
      "Name": "Gino Bellucci Filho",
      "Email": "gino@gbfcontabil.com.br",
      "CustomerCode": "18677",
      "Phone": "1132240200",
      "Whatsapp": "1183590059",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 748,
      "Name": "Maria Claudia da Silva",
      "Email": "mcr.servicoscontabeis@gmail.com",
      "CustomerCode": "18678",
      "Phone": "1147558922",
      "Whatsapp": "1195814921",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 749,
      "Name": "SS Apoio Empresarial",
      "Email": "ss.apoioempresarial@gmail.com",
      "CustomerCode": "18679",
      "Phone": "1137153108",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 750,
      "Name": "Livance Serviços de Locação de Equipamentos Ltda.",
      "Email": "amanda.coatti@livance.com.br",
      "CustomerCode": "18680",
      "Phone": "",
      "Whatsapp": "1171873882",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 751,
      "Name": "Sirlene Ferreira Silva",
      "Email": "marcelltelecom@hotmail.com",
      "CustomerCode": "18681",
      "Phone": "1134671262",
      "Whatsapp": "1194577460",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 752,
      "Name": "Frconsulting",
      "Email": "fernandaracau@gmail.com",
      "CustomerCode": "18682",
      "Phone": "",
      "Whatsapp": "1195815352",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 753,
      "Name": "MF Assessoria Contábil",
      "Email": "fgomes@mfco.com.br",
      "CustomerCode": "18683",
      "Phone": "1120904570",
      "Whatsapp": "1149471679",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 754,
      "Name": "Afran Organização Fiscal e Contábil",
      "Email": "andreia@afrancontabilidade.com.br",
      "CustomerCode": "18686",
      "Phone": "1120410772",
      "Whatsapp": "1175483864",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 756,
      "Name": "João Mauricio de Oliveira",
      "Email": "escritorio.aquarius@uol.com.br",
      "CustomerCode": "18689",
      "Phone": "1129470027",
      "Whatsapp": "1181547024",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 757,
      "Name": "Rodger Luís Salvatierra",
      "Email": "rodger@contabilista.com",
      "CustomerCode": "18690",
      "Phone": "",
      "Whatsapp": "19981038461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 758,
      "Name": "José Parziale Rodrigues Junior",
      "Email": "junior.parziale@hotmail.com",
      "CustomerCode": "18691",
      "Phone": "1125578883",
      "Whatsapp": "1147912031",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 759,
      "Name": "Valter Laureano",
      "Email": "laureanovalter@gmail.com",
      "CustomerCode": "18692",
      "Phone": "1122691126",
      "Whatsapp": "1161362602",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 760,
      "Name": "José Arnaldo Barbosa",
      "Email": "j.arnaldo_barbosa@hotmail.com",
      "CustomerCode": "18693",
      "Phone": "",
      "Whatsapp": "1182143354",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 761,
      "Name": "Josemir Matias Rodrigues",
      "Email": "josemir.matias@hotmail.com",
      "CustomerCode": "18695",
      "Phone": "1183995895",
      "Whatsapp": "1183995895",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 762,
      "Name": "Henrique Roosevelt Kumabe Moreira Lima",
      "Email": "henrique.moreiralima@gmail.com",
      "CustomerCode": "18696",
      "Phone": "1138316958",
      "Whatsapp": "1191238719",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 763,
      "Name": "Naspav Assessoria Contábil e Financeira Ltda.",
      "Email": "naspav.contabilidade@gmail.com",
      "CustomerCode": "18697",
      "Phone": "1125928196",
      "Whatsapp": "1197229898",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 764,
      "Name": "Maga Assessoria Contábil",
      "Email": "mag_assessoriacontabil@yahoo.com.br",
      "CustomerCode": "18698",
      "Phone": "1155235345",
      "Whatsapp": "1149718909",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 765,
      "Name": "Martins Associados",
      "Email": "contmartinsassociados@gmail.com",
      "CustomerCode": "18699",
      "Phone": "1143273858",
      "Whatsapp": "1170655973",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 766,
      "Name": "Agrofresh Brasil Ltda.",
      "Email": "jvictor@agrofresh.com",
      "CustomerCode": "18700",
      "Phone": "1120783929",
      "Whatsapp": "1198031241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 767,
      "Name": "Consultee Contabilidade Eireli",
      "Email": "elaineserra@consultee.com.br",
      "CustomerCode": "18701",
      "Phone": "1141186593",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 768,
      "Name": "AC Contabilidade",
      "Email": "lucimaraandrian@hotmail.com",
      "CustomerCode": "18702",
      "Phone": "",
      "Whatsapp": "1193630288",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 769,
      "Name": "Sidney Correa Bueno",
      "Email": "scbueno@hotmail.com",
      "CustomerCode": "18703",
      "Phone": "1125458478",
      "Whatsapp": "1194137900",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 770,
      "Name": "Claudia Luciane Candido Brandão",
      "Email": "claudia@assessoriaalternativa.com.br",
      "CustomerCode": "18704",
      "Phone": "1137815688",
      "Whatsapp": "1198840444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 771,
      "Name": "Janio Correa Leite",
      "Email": "janio.correa@hotmail.com",
      "CustomerCode": "18705",
      "Phone": "1126217009",
      "Whatsapp": "1199690914",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 772,
      "Name": "Wsi Digital Box",
      "Email": "gabriela@wsidigitalbox.com",
      "CustomerCode": "18706",
      "Phone": "1135008072",
      "Whatsapp": "1170919272",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 773,
      "Name": "Ceo Gestão e Serviços Administrativos Eireli",
      "Email": "cesar@ceogestao.com",
      "CustomerCode": "18707",
      "Phone": "1131563702",
      "Whatsapp": "1195790699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 774,
      "Name": "Vascotto Assessoria Empresarial Ltda",
      "Email": "adm@vgempresarial.com.br",
      "CustomerCode": "18708",
      "Phone": "1122110347",
      "Whatsapp": "11987552632",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 776,
      "Name": "Saeng Engenharia e Comércio Ltda.",
      "Email": "rafaela@saeng.com.br",
      "CustomerCode": "18711",
      "Phone": "1130586833",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 777,
      "Name": "Flavio Rodrigues Justiniano",
      "Email": "frjustiniano@hotmail.com",
      "CustomerCode": "18712",
      "Phone": "1137986691",
      "Whatsapp": "1148703550",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 778,
      "Name": "Hom Empreendimentos e Participações Ltda.",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "18713",
      "Phone": "1133406919",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 779,
      "Name": "Swarovski Cristal",
      "Email": "ila.santos@swarovski.com",
      "CustomerCode": "18714",
      "Phone": "1159044581",
      "Whatsapp": "1191903134",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 780,
      "Name": "Fabiano André de Brito",
      "Email": "fabiano56@hotmail.com",
      "CustomerCode": "18716",
      "Phone": "1181060657",
      "Whatsapp": "1181060657",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 781,
      "Name": "Marilia Hordonho Barbosa",
      "Email": "bhm2@globo.com",
      "CustomerCode": "18717",
      "Phone": "1156658150",
      "Whatsapp": "1199408008",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 782,
      "Name": "Cristiano Jacinto dos Santos",
      "Email": "processos@polifisco.com",
      "CustomerCode": "18718",
      "Phone": "",
      "Whatsapp": "1181605896",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 783,
      "Name": "Progressiva Organização de Serviços Empresariais Eireli",
      "Email": "claudiofabricio2005@uol.com.br",
      "CustomerCode": "18719",
      "Phone": "1126090380",
      "Whatsapp": "1181038511",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 784,
      "Name": "Mega Vest Casa",
      "Email": "paralegal@vestcasa.com",
      "CustomerCode": "18720",
      "Phone": "1128136262",
      "Whatsapp": "1192818433",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 786,
      "Name": "Ssac Serviços Empresariais Eireli",
      "Email": "admssac@gmail.com",
      "CustomerCode": "18723",
      "Phone": "1133133940",
      "Whatsapp": "1148461010",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 787,
      "Name": "B Ferraz Comunicação Promocional Ltda.",
      "Email": "juridico@bferraz.ag",
      "CustomerCode": "18724",
      "Phone": "1133777918",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 788,
      "Name": "Starr Sistemas e Tecnologias de Autenticação e Ra",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "18726",
      "Phone": "1133406922",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 789,
      "Name": "H. Vilella Empreendimentos e Participações Ltda.",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "18727",
      "Phone": "1133406922",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 790,
      "Name": "Luciana Santos Ribeiro",
      "Email": "andrebarbosa501@gmail.com",
      "CustomerCode": "18728",
      "Phone": "11982188526",
      "Whatsapp": "1182188526",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 791,
      "Name": "Unecont União Econômico Contábil Ltda.",
      "Email": "biagio@unecont.com.br",
      "CustomerCode": "18729",
      "Phone": "1133491414",
      "Whatsapp": "1191679400",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 792,
      "Name": "Eliana Aparecida Balarini da Silva",
      "Email": "pceliana64@gmail.com",
      "CustomerCode": "18730",
      "Phone": "1122115681",
      "Whatsapp": "1191775377",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 793,
      "Name": "Joaquim Vilarino",
      "Email": "joevillarino@terra.com.br",
      "CustomerCode": "18732",
      "Phone": "1199289527",
      "Whatsapp": "1154200460",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 794,
      "Name": "Monique Lopes Matias",
      "Email": "andre.gabriel@mhadv.adv.br",
      "CustomerCode": "18733",
      "Phone": "1150526400",
      "Whatsapp": "1146276879",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 795,
      "Name": "Souza & Dias Assessoria Contábil Ltda.",
      "Email": "rose.dias@souzadiascontabil.com.br",
      "CustomerCode": "18734",
      "Phone": "1122271593",
      "Whatsapp": "1197585824",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 796,
      "Name": "Alessandra Bonalume Tomita",
      "Email": "alessandra.tomita@abtassessoria.com.br",
      "CustomerCode": "18735",
      "Phone": "1143232947",
      "Whatsapp": "1182771242",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 797,
      "Name": "Edison Pugaciov",
      "Email": "edipuga1@gmail.com",
      "CustomerCode": "18736",
      "Phone": "1184442082",
      "Whatsapp": "1184442082",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 798,
      "Name": "Carlos Wilson de Azevedo",
      "Email": "cwazevedo@hotmail.com",
      "CustomerCode": "18737",
      "Phone": "1132977138",
      "Whatsapp": "1191647150",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 799,
      "Name": "Pvm Empreendimentos Imobiliários Ltda.",
      "Email": "claudio@pancrom.com.br",
      "CustomerCode": "18738",
      "Phone": "1133406922",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 800,
      "Name": "Accounting Services Contabilidade e Planejamento T",
      "Email": "luizbloedau@acservices.com.br",
      "CustomerCode": "18739",
      "Phone": "1141715548",
      "Whatsapp": "1189017921",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 801,
      "Name": "R A D Maciel Consultoria Contábil",
      "Email": "radmcontabilidade@gmail.com",
      "CustomerCode": "18740",
      "Phone": "1136618432",
      "Whatsapp": "1180282519",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 802,
      "Name": "Metafisco Soluções Contábeis Eireli",
      "Email": "fiscal@metafisco.com.br",
      "CustomerCode": "18741",
      "Phone": "1141303896",
      "Whatsapp": "11947492712",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 803,
      "Name": "Amaury da Mata Ribeiro",
      "Email": "goama@terra.com.br",
      "CustomerCode": "18742",
      "Phone": "1155113558",
      "Whatsapp": "1199149004",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 804,
      "Name": "Júlio Nakano",
      "Email": "julionakano1@uol.com.br",
      "CustomerCode": "18743",
      "Phone": "1182491975",
      "Whatsapp": "1141380147",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 805,
      "Name": "Mlc & Machado Assessoria Empresarial Eireli",
      "Email": "mlcmachado@mlcmachado.com.br",
      "CustomerCode": "18744",
      "Phone": "1132558344",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 806,
      "Name": "Paulino F Contábil",
      "Email": "paulino.evoluir@gmail.com",
      "CustomerCode": "18745",
      "Phone": "1156310079",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 808,
      "Name": "Ncnet Contabilidade Ltda ME",
      "Email": "contabilidadencnet@gmail.com",
      "CustomerCode": "18747",
      "Phone": "1158414227",
      "Whatsapp": "1197920200",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 809,
      "Name": "Sandra Passos Georgetti",
      "Email": "sandrageorgetti@terra.com.br",
      "CustomerCode": "18748",
      "Phone": "1125411129",
      "Whatsapp": "1195529923",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 810,
      "Name": "Suely Ribeiro",
      "Email": "srsocietaria@gmail.com",
      "CustomerCode": "18749",
      "Phone": "1134313251",
      "Whatsapp": "1179743014",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 811,
      "Name": "Waldir Ferreira Duarte",
      "Email": "waldirduarte.contador@gmail.com",
      "CustomerCode": "18750",
      "Phone": "1125132556",
      "Whatsapp": "1182335053",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 812,
      "Name": "Lourenzem Contabilidade SS ME",
      "Email": "contato@lourenzem.com.br",
      "CustomerCode": "18751",
      "Phone": "1150512539",
      "Whatsapp": "1161555444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 813,
      "Name": "Fvs Serviços Contábeis",
      "Email": "fabio@fvscontabil.com.br",
      "CustomerCode": "18753",
      "Phone": "1129369555",
      "Whatsapp": "1160644457",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 814,
      "Name": "Leôncio da Silva Alves",
      "Email": "contabilidade@solventex.com.br",
      "CustomerCode": "18754",
      "Phone": "1159229088",
      "Whatsapp": "1196719655",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 815,
      "Name": "Diz e Soler Sociedade de Advogados",
      "Email": "jonathas@dizesoler.com.br",
      "CustomerCode": "18755",
      "Phone": "1131320533",
      "Whatsapp": "1175042412",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 816,
      "Name": "Mariana Fernandes da Silva Sociedade de Advogados",
      "Email": "contato@fernandesesilvaadvogados.com",
      "CustomerCode": "18756",
      "Phone": "1131054821",
      "Whatsapp": "1142837505",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 817,
      "Name": "M5 Assessoria Contábil S/S Ltda.",
      "Email": "andreia.oliveira@mc5contabil.com.br",
      "CustomerCode": "18758",
      "Phone": "1132111188",
      "Whatsapp": "1170269086",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 818,
      "Name": "Alexandre de Melo Alves",
      "Email": "diamantenegrosp35@hotmail.com",
      "CustomerCode": "18759",
      "Phone": "",
      "Whatsapp": "1160394258",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 819,
      "Name": "Bianca de Castro Bortholotte",
      "Email": "bianca.bortholotte@hotmail.com",
      "CustomerCode": "18760",
      "Phone": "1141877392",
      "Whatsapp": "1146690426",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 820,
      "Name": "Agapes & Cardoso Serviços Contábeis",
      "Email": "debora@agapesecardoso.com.br",
      "CustomerCode": "18761",
      "Phone": "1127429423",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 821,
      "Name": "Rafael Brito Viana",
      "Email": "rafaviana19@hotmail.com",
      "CustomerCode": "18762",
      "Phone": "",
      "Whatsapp": "1185128299",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 822,
      "Name": "Sampahtti Assessoria Contábil",
      "Email": "sampahtti@yahoo.com.br",
      "CustomerCode": "18764",
      "Phone": "1138260678",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 823,
      "Name": "Mauricio Braz",
      "Email": "mauricio.braz@gmail.com",
      "CustomerCode": "18765",
      "Phone": "1139189350",
      "Whatsapp": "1191893503",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 824,
      "Name": "Marcello Graciano Nicolau ME",
      "Email": "diretoria@gncontabilidade.com.br",
      "CustomerCode": "18766",
      "Phone": "1129441962",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 825,
      "Name": "M S Ferreira Calado Serviços Contábeis",
      "Email": "docarmo.contabil30@gmail.com",
      "CustomerCode": "18767",
      "Phone": "1125810721",
      "Whatsapp": "1182611191",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 826,
      "Name": "Erika da Silva",
      "Email": "adm@impactarecursoshumanos.com.br",
      "CustomerCode": "18768",
      "Phone": "1155338384",
      "Whatsapp": "1193618439",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 827,
      "Name": "Rápido 900 de Transportes Rodoviários Ltda.",
      "Email": "marciabello25@gmail.com",
      "CustomerCode": "18769",
      "Phone": "1126320933",
      "Whatsapp": "1169017031",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 828,
      "Name": "Wânia Bezerra da Silva",
      "Email": "reginacelia23@hotmail.com",
      "CustomerCode": "18770",
      "Phone": "1137218223",
      "Whatsapp": "118918111",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 829,
      "Name": "Tfm Contabilidade S.S.",
      "Email": "raul@tfmcontabilidade.com.br",
      "CustomerCode": "18772",
      "Phone": "1150814353",
      "Whatsapp": "1169747996",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 830,
      "Name": "Microauditoria Associados Limitada",
      "Email": "lazaro@lazaroauditoria.com",
      "CustomerCode": "18773",
      "Phone": "1141713523",
      "Whatsapp": "1175857444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 831,
      "Name": "Victor Barcelos dos Santos",
      "Email": "financeiro@ativore.com",
      "CustomerCode": "18774",
      "Phone": "",
      "Whatsapp": "1140368127",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 832,
      "Name": "Ocae - Org. Contábil Eireli",
      "Email": "legal@ocae.com.br",
      "CustomerCode": "18775",
      "Phone": "1150694025",
      "Whatsapp": "1185351391",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 833,
      "Name": "José de Arimatéia de Castro Pereira",
      "Email": "ari.contador@uol.com.br",
      "CustomerCode": "18776",
      "Phone": "1138468404",
      "Whatsapp": "1199005574",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 834,
      "Name": "Foccus Assessoria Contábil S/S Ltda.",
      "Email": "foccus.contabilidade2009@gmail.com",
      "CustomerCode": "18777",
      "Phone": "1122012804",
      "Whatsapp": "1163476015",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 835,
      "Name": "Modelo Contábil Ltda.",
      "Email": "modelo@modelocontabil.com.br",
      "CustomerCode": "18778",
      "Phone": "1129111611",
      "Whatsapp": "1176806088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 836,
      "Name": "Antônio Olavo Gomes dos Santos",
      "Email": "antonioolavo22@gmail.com",
      "CustomerCode": "18779",
      "Phone": "1141159926",
      "Whatsapp": "1171624587",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 838,
      "Name": "Adalberto Silva",
      "Email": "adalberto.c@outlook.com",
      "CustomerCode": "18781",
      "Phone": "1127221200",
      "Whatsapp": "11992309662",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 839,
      "Name": "Planner Contabilidade Empresarial e Consultoria Lt",
      "Email": "iara@plannercontabil.com.br",
      "CustomerCode": "18782",
      "Phone": "1120633181",
      "Whatsapp": "1199091210",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 840,
      "Name": "Luís Henrique Brandão Rosa",
      "Email": "gl.contabilidadeeadvocacia@gmail.com",
      "CustomerCode": "18783",
      "Phone": "1158511337",
      "Whatsapp": "1171511383",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 841,
      "Name": "Fbs Contabilidade",
      "Email": "felipe.bartolomeu.santos@gmail.com",
      "CustomerCode": "18784",
      "Phone": "1134732703",
      "Whatsapp": "1188725361",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 842,
      "Name": "Assis Pereira Monteiro",
      "Email": "mpoassessoria@uol.com.br",
      "CustomerCode": "18785",
      "Phone": "1150966208",
      "Whatsapp": "1144627551",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 843,
      "Name": "Afg Contabilidade Sociedade Simples Ltda",
      "Email": "adriana@assentcontabilidade.com.br",
      "CustomerCode": "18786",
      "Phone": "1126542750",
      "Whatsapp": "1185889913",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 844,
      "Name": "Gma3 Gestão de Negócios",
      "Email": "anderson@gma3.com.br",
      "CustomerCode": "18788",
      "Phone": "1123593739",
      "Whatsapp": "1185840677",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 846,
      "Name": "Consulplan Consultores e Contadores Ltda.",
      "Email": "mauricio.pandolfi@consulplan-consultores.com",
      "CustomerCode": "18790",
      "Phone": "1150493394",
      "Whatsapp": "1196012013",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 847,
      "Name": "Prioriza Contábil Contabilidade e Assessoria Ltda.",
      "Email": "contato@novapriorizacontabil.com.br",
      "CustomerCode": "18791",
      "Phone": "1125893745",
      "Whatsapp": "1198182367",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 848,
      "Name": "Arnaldo de Oliveira",
      "Email": "raizes.contabil.arnaldo@gmail.com",
      "CustomerCode": "18792",
      "Phone": "1139784734",
      "Whatsapp": "11947303181",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 849,
      "Name": "Marcos Antônio Mouzinho de Santana -ME",
      "Email": "marcosamouzinho@gmail.com",
      "CustomerCode": "18793",
      "Phone": "",
      "Whatsapp": "11946645774",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 850,
      "Name": "Wellington Araújo",
      "Email": "tonmendesaraujo31@gmail.com",
      "CustomerCode": "18794",
      "Phone": "",
      "Whatsapp": "1193611973",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 851,
      "Name": "Louis Winkler",
      "Email": "louiswinkler53@yahoo.com.br",
      "CustomerCode": "18795",
      "Phone": "1150558129",
      "Whatsapp": "1196222036",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 852,
      "Name": "Escritório de Assessoria Contábil Visão SS",
      "Email": "contabvisao@terra.com.br",
      "CustomerCode": "18796",
      "Phone": "1131593562",
      "Whatsapp": "1189289683",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 853,
      "Name": "Walter Scmitz",
      "Email": "jaqueline-monteiro2011@hotmail.com",
      "CustomerCode": "18797",
      "Phone": "",
      "Whatsapp": "1194380496",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 854,
      "Name": "Rafael Marques dos Anjos",
      "Email": "rcrmarques@outlook.com.br",
      "CustomerCode": "18798",
      "Phone": "11",
      "Whatsapp": "1191255920",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 855,
      "Name": "Torayoshi Mario Kuabara",
      "Email": "tkuabara@outlook.com",
      "CustomerCode": "18799",
      "Phone": "1137580660",
      "Whatsapp": "1196209499",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 856,
      "Name": "Sga Auditores Independentes",
      "Email": "avelar@sgaconsult.com.br",
      "CustomerCode": "18800",
      "Phone": "1122951444",
      "Whatsapp": "1173368884",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 857,
      "Name": "Paulo Sergio Gonçalves Fagundes",
      "Email": "brasil.contabil@yahoo.com.br",
      "CustomerCode": "18801",
      "Phone": "1141720419",
      "Whatsapp": "11959387569",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 858,
      "Name": "Daniel Flavio de Lima",
      "Email": "danielflaviodelima.sac@gmail.com",
      "CustomerCode": "18802",
      "Phone": "1126092781",
      "Whatsapp": "1182212548",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 859,
      "Name": "Cristina Rodrigues de Lima",
      "Email": "cristina.lima83@hotmail.com",
      "CustomerCode": "18803",
      "Phone": "",
      "Whatsapp": "1197417686",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 860,
      "Name": "Creficuls e Branca Ltda.",
      "Email": "brancapericia@gmail.com",
      "CustomerCode": "18804",
      "Phone": "",
      "Whatsapp": "119401190319",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 861,
      "Name": "Alp Contabilidade",
      "Email": "andreuzios@hotmail.com",
      "CustomerCode": "18805",
      "Phone": "1158932476",
      "Whatsapp": "1179671330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 862,
      "Name": "Nogueira Leite Advogados",
      "Email": "iurie@nladv.com.br",
      "CustomerCode": "18806",
      "Phone": "1136417727",
      "Whatsapp": "1186970693",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 863,
      "Name": "Anderson Camargo dos Santos",
      "Email": "tomdecamargo@gmail.com",
      "CustomerCode": "18807",
      "Phone": "1142350879",
      "Whatsapp": "1165129473",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 864,
      "Name": "Contabilidade Médica",
      "Email": "rodrigo.contmedica@hotmail.com",
      "CustomerCode": "18808",
      "Phone": "1125455979",
      "Whatsapp": "1182947480",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 865,
      "Name": "Felipe Zanetti Tallo",
      "Email": "zanetti.contabil@yahoo.com",
      "CustomerCode": "18809",
      "Phone": "1136872176",
      "Whatsapp": "1195153782",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 866,
      "Name": "José Santana Filho",
      "Email": "escregional2020@hotmail.com",
      "CustomerCode": "18810",
      "Phone": "1133118164",
      "Whatsapp": "1194852827",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 867,
      "Name": "Spp. Cálculos e Certificações Ltda.",
      "Email": "alexandre@spcalculos.com.br",
      "CustomerCode": "18811",
      "Phone": "1133921487",
      "Whatsapp": "119637559119",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 868,
      "Name": "Contcor Assessoria Contábil e Fiscal Ltda.",
      "Email": "valdemir.soprani@contcor.com.br",
      "CustomerCode": "18812",
      "Phone": "",
      "Whatsapp": "1196487160",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 869,
      "Name": "Luciana Santilli",
      "Email": "lucianasantilli@ig.com.br",
      "CustomerCode": "18813",
      "Phone": "1136415997",
      "Whatsapp": "1183890728",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 870,
      "Name": "Skoberg Pires Sociedade de Advogados",
      "Email": "tatiane.skoberg@skpadv.com.br",
      "CustomerCode": "18814",
      "Phone": "1125327173",
      "Whatsapp": "1169977220",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 871,
      "Name": "Adalberto Pereira da Costa",
      "Email": "adalberto@acostacontabil.com.br",
      "CustomerCode": "18815",
      "Phone": "1132312402",
      "Whatsapp": "11982956649",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 872,
      "Name": "Vega Contabilidade Ltda.",
      "Email": "administrativo@vegacontabil.com.br",
      "CustomerCode": "18816",
      "Phone": "1122515784",
      "Whatsapp": "119511983531",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 873,
      "Name": "Agi Chiandotti Contábil Ltda.",
      "Email": "andrey@agicontabil.com.br",
      "CustomerCode": "18817",
      "Phone": "1129590615",
      "Whatsapp": "1189650745",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 874,
      "Name": "Rogério Spolidoro Filho",
      "Email": "rsf.spolidoro@gmail.com",
      "CustomerCode": "18818",
      "Phone": "1139859175",
      "Whatsapp": "1174117409",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 875,
      "Name": "Fmlo Consultoria",
      "Email": "fernanda@fmloconsultoria.com.br",
      "CustomerCode": "18819",
      "Phone": "",
      "Whatsapp": "1182718225",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 876,
      "Name": "Daniel Darley Montini",
      "Email": "danielmontini@globo.com",
      "CustomerCode": "18820",
      "Phone": "1176211812",
      "Whatsapp": "1194698672",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 877,
      "Name": "Organização Contábil Câmara e Araújo Ltda.",
      "Email": "fiscal@camaraearaujo.com.br",
      "CustomerCode": "18821",
      "Phone": "1138540841",
      "Whatsapp": "1184185517",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 878,
      "Name": "José Valto de Almeida Junior",
      "Email": "junior.jubilocontabilidade@gmail.com",
      "CustomerCode": "18822",
      "Phone": "1123458440",
      "Whatsapp": "1164783948",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 879,
      "Name": "Daniela de Almeida Victor",
      "Email": "daniela.victor@aasp.org.br",
      "CustomerCode": "18823",
      "Phone": "1121435105",
      "Whatsapp": "1192872666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 881,
      "Name": "Vitaly Contabilidade Eireli",
      "Email": "anderson@vitalycontabil.com.br",
      "CustomerCode": "18825",
      "Phone": "1125711095",
      "Whatsapp": "1167368924",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 882,
      "Name": "Ivanilde Rosa de Lima Lopes",
      "Email": "citycontabil@yahoo.com",
      "CustomerCode": "18826",
      "Phone": "1122118004",
      "Whatsapp": "1184209206",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 883,
      "Name": "Helder Cury Ricciardi",
      "Email": "leandro@ricciardiassociados.com.br",
      "CustomerCode": "18827",
      "Phone": "1132510177",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 884,
      "Name": "Antônio Silva Cavalcanti Júnior",
      "Email": "antonyscjr@gmail.com",
      "CustomerCode": "18828",
      "Phone": "",
      "Whatsapp": "1182348090",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 885,
      "Name": "Conthabilie Contabilidade Ltda.",
      "Email": "paralegal@conthabilie.com.br",
      "CustomerCode": "18829",
      "Phone": "1138169888",
      "Whatsapp": "1195789891",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 887,
      "Name": "Márcia Soares Gomes Guimarães Seixas",
      "Email": "mar_blue71@hotmail.com",
      "CustomerCode": "18831",
      "Phone": "1137519871",
      "Whatsapp": "1193872753",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 888,
      "Name": "Marcelo Souza Granjeia",
      "Email": "newtimecontabil@gmail.com",
      "CustomerCode": "18832",
      "Phone": "11965042174",
      "Whatsapp": "1196504217",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 889,
      "Name": "Alexandre Pereda Rosa",
      "Email": "splegalnegocios@gmail.com",
      "CustomerCode": "18833",
      "Phone": "1333038050",
      "Whatsapp": "1176119125",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 890,
      "Name": "Waldir Carboni",
      "Email": "waldir.carboni@terra.com.br",
      "CustomerCode": "18834",
      "Phone": "1195703038",
      "Whatsapp": "1199748190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 891,
      "Name": "Alexandra Patrícia de Oliveira Zamonaro",
      "Email": "aco.apoz@gmail.com",
      "CustomerCode": "18835",
      "Phone": "1150342138",
      "Whatsapp": "1193527343",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 892,
      "Name": "Leandro Raphael Delascio Salgueiro",
      "Email": "salgueiroleandro@gmail.com",
      "CustomerCode": "18836",
      "Phone": "",
      "Whatsapp": "1174586476",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 893,
      "Name": "José Roberto Pissiguelli",
      "Email": "guelli.contador@gmail.com",
      "CustomerCode": "18837",
      "Phone": "1138725833",
      "Whatsapp": "11988994746",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 894,
      "Name": "Rhemor Contabilidade Eireli",
      "Email": "priscila@rhemorcontabilidade.com.br",
      "CustomerCode": "18838",
      "Phone": "1120609150",
      "Whatsapp": "11968334191",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 895,
      "Name": "Waldemar José Cardoso",
      "Email": "rodrigo@grupowalcar.com.br",
      "CustomerCode": "18839",
      "Phone": "1122114234",
      "Whatsapp": "11963877375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 896,
      "Name": "Accounting Serviços Empresariais Ltda.",
      "Email": "contabilidade@accountingservicos.com.br",
      "CustomerCode": "18840",
      "Phone": "1123056550",
      "Whatsapp": "11953082613",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 898,
      "Name": "Marcelo Caldeira Silva",
      "Email": "edmarcon@uol.com.br",
      "CustomerCode": "18842",
      "Phone": "1150845906",
      "Whatsapp": "1191360425",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 899,
      "Name": "Estefania dos Santos Alencar",
      "Email": "estefania@genesecontabil.com.br",
      "CustomerCode": "18843",
      "Phone": "1185271757",
      "Whatsapp": "1185271757",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 900,
      "Name": "Silvestre Comercial e Serviços de Informática Ltda.",
      "Email": "silvestre.associados@uol.com.br",
      "CustomerCode": "18844",
      "Phone": "1125465299",
      "Whatsapp": "1195911691",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 901,
      "Name": "Jaqueline da Silva Batista",
      "Email": "carlaregianemp@gmail.com",
      "CustomerCode": "18845",
      "Phone": "",
      "Whatsapp": "11985272386",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 902,
      "Name": "Luís Henrique Lopes Pereira",
      "Email": "luishlopes_p@hotmail.com",
      "CustomerCode": "18846",
      "Phone": "1139956102",
      "Whatsapp": "1182497517",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 903,
      "Name": "Blois Associados Servios Contábeis Ltda.",
      "Email": "barbara@bloisassociados.com.br",
      "CustomerCode": "18847",
      "Phone": "1138265661",
      "Whatsapp": "11947791911",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 904,
      "Name": "Ribeiros Contabilistas",
      "Email": "fabio@ribeiroscont.com",
      "CustomerCode": "18848",
      "Phone": "1120311555",
      "Whatsapp": "1168331414",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 905,
      "Name": "Conmed Do Brasil Comércio, Importação e Exportação",
      "Email": "andreiacasquet@conmed.com",
      "CustomerCode": "18849",
      "Phone": "1123916388",
      "Whatsapp": "1176231643",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 906,
      "Name": "Tec Assessoria Contábil Ltda.",
      "Email": "teclegal@teccontabilidade.com.br",
      "CustomerCode": "18850",
      "Phone": "1134593970",
      "Whatsapp": "1199782480",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 907,
      "Name": "Elio Flavio Poterio Vaz de Campos",
      "Email": "elio.vaz@gmail.com",
      "CustomerCode": "18852",
      "Phone": "1138152888",
      "Whatsapp": "1187770772",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 908,
      "Name": "Inês Rosa de Oliveira",
      "Email": "irosa.oliveira29@hotmail.com",
      "CustomerCode": "18853",
      "Phone": "1150214401",
      "Whatsapp": "11947531298",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 909,
      "Name": "Washington Freire da Silva",
      "Email": "wfreirecontabil@gmail.com",
      "CustomerCode": "18854",
      "Phone": "",
      "Whatsapp": "1171866520",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 910,
      "Name": "Daniel Vital Rodrigues",
      "Email": "tatianeaquiles@hotmail.com",
      "CustomerCode": "18855",
      "Phone": "1158962408",
      "Whatsapp": "11949147493",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 911,
      "Name": "Patrícia Cristina dos Santos",
      "Email": "patriciacontabilidade.fiscal@gmail.com",
      "CustomerCode": "18856",
      "Phone": "1155101622",
      "Whatsapp": "11962259535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 912,
      "Name": "Gerasolo Empreendimentos e Construções Ltda.",
      "Email": "zaia1@terra.com.br",
      "CustomerCode": "18857",
      "Phone": "1133333020",
      "Whatsapp": "11973104790",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 913,
      "Name": "Gisélia de França Silva Matos",
      "Email": "giseliafs@gmail.com",
      "CustomerCode": "18858",
      "Phone": "1137960231",
      "Whatsapp": "1193929437",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 914,
      "Name": "Jorge Rui Alonso dos Santos",
      "Email": "jorgeruy@terra.com.br",
      "CustomerCode": "18859",
      "Phone": "1120418493",
      "Whatsapp": "1192129028",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 915,
      "Name": "Mariza Teixeira Barral ME",
      "Email": "marizabarral@gmail.com",
      "CustomerCode": "18860",
      "Phone": "1133931717",
      "Whatsapp": "1185369363",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 917,
      "Name": "S & S Contabilidade",
      "Email": "tamiris.silva@sscontabilidade.net.br",
      "CustomerCode": "18862",
      "Phone": "1155316623",
      "Whatsapp": "1187622690",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 918,
      "Name": "Carvalho & Cavalheiro Advogados",
      "Email": "jhanriot@cclaw.co",
      "CustomerCode": "18863",
      "Phone": "1132512045",
      "Whatsapp": "1176475821",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 919,
      "Name": "Econtax Gestão Contábil e Controladoria",
      "Email": "renato@econtax.com.br",
      "CustomerCode": "18864",
      "Phone": "",
      "Whatsapp": "1189601859",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 920,
      "Name": "Susana José Pereira Gonçalves",
      "Email": "susana@adviserbr.com.br",
      "CustomerCode": "18865",
      "Phone": "",
      "Whatsapp": "1188365592",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 921,
      "Name": "Juscon Consultoria Empresarial",
      "Email": "tamiris@jusconempresarial.com.br",
      "CustomerCode": "18866",
      "Phone": "1142115022",
      "Whatsapp": "1187622690",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 922,
      "Name": "Luiz Carlos de Souza Consultoria",
      "Email": "conthabeisassessoria@gmail.com",
      "CustomerCode": "18867",
      "Phone": "1120260356",
      "Whatsapp": "1182081130",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 923,
      "Name": "Daniel Evangelista Cardoso",
      "Email": "dnabru@msn.com",
      "CustomerCode": "18868",
      "Phone": "",
      "Whatsapp": "1187440518",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 924,
      "Name": "Viveiros Contabilidade Eireli",
      "Email": "roberto.rodriguesv@gmail.com",
      "CustomerCode": "18869",
      "Phone": "1139796868",
      "Whatsapp": "1193742045",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 927,
      "Name": "Robson Alberto Lopes",
      "Email": "lucaslopes@contup.com.br",
      "CustomerCode": "18872",
      "Phone": "1134360888",
      "Whatsapp": "11947176461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 928,
      "Name": "Exata Organização Contábil Eireli",
      "Email": "legalizacao@exataorganizacaocontabil.com.br",
      "CustomerCode": "18873",
      "Phone": "1155620472",
      "Whatsapp": "1192602723",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 929,
      "Name": "Nc Sistemas Contábil Ltda.",
      "Email": "crpelitz@ncsistema.com.br",
      "CustomerCode": "18874",
      "Phone": "1155242877",
      "Whatsapp": "11949552107",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 930,
      "Name": "Datamicro Contabilidade",
      "Email": "michelesegna@hotmail.com",
      "CustomerCode": "18875",
      "Phone": "1139554684",
      "Whatsapp": "11931513663",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 933,
      "Name": "André Tadeu Turbiani",
      "Email": "andre.ltcontabil@uol.com.br",
      "CustomerCode": "18878",
      "Phone": "1123626987",
      "Whatsapp": "1176321727",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 934,
      "Name": "Thot Negócios e Serviços Ltda.",
      "Email": "mauricio.brito@thotnegocios.com.br",
      "CustomerCode": "18879",
      "Phone": "",
      "Whatsapp": "1192090729",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 935,
      "Name": "Elisangela Prado Habenschuss",
      "Email": "marcelo.habenschuss@gmail.com",
      "CustomerCode": "18880",
      "Phone": "1143233736",
      "Whatsapp": "11947973360",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 936,
      "Name": "Rosangela Gomes da Sila Borges",
      "Email": "rosangelagsborges@gmail.com",
      "CustomerCode": "18881",
      "Phone": "1123696309",
      "Whatsapp": "1189610101",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 937,
      "Name": "Jorge Luiz da Silva",
      "Email": "jorge@contabilidadejl.com.br",
      "CustomerCode": "18882",
      "Phone": "1138951777",
      "Whatsapp": "1171303928",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 938,
      "Name": "Organização Contábil Marcelino Ltda. EPP",
      "Email": "abertura@contabilidademarcelino.com.br",
      "CustomerCode": "18883",
      "Phone": "1146782315",
      "Whatsapp": "11942449041",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 939,
      "Name": "Andrade, Foz, Hypolito e Médicis Sociedade de Advo",
      "Email": "lserafim@andradefoz.com.br",
      "CustomerCode": "18884",
      "Phone": "1130165090",
      "Whatsapp": "1167465872",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 940,
      "Name": "Ferraris, Cordeiro & Djanian Sociedade de Advogado",
      "Email": "contato@djancord.com.br",
      "CustomerCode": "18885",
      "Phone": "1123064955",
      "Whatsapp": "1196322467",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 941,
      "Name": "Eduardo Barbosa Moura",
      "Email": "assessoria.lmiranda@gmail.com",
      "CustomerCode": "18886",
      "Phone": "",
      "Whatsapp": "1177969251",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 942,
      "Name": "Gabriela Silva de Souza",
      "Email": "luanovacontabilidade@hotmail.com",
      "CustomerCode": "18887",
      "Phone": "",
      "Whatsapp": "1175467208",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 943,
      "Name": "Marco Aurélio Xavier Soares de Mello",
      "Email": "marcoaurelio@soaresdemello.com.br",
      "CustomerCode": "18888",
      "Phone": "",
      "Whatsapp": "1196219702",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 944,
      "Name": "Rn Gomes Serviços Contábeis ME",
      "Email": "rosa@gomesegomescontabilidade.com.br",
      "CustomerCode": "18889",
      "Phone": "1123672257",
      "Whatsapp": "1191421547",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 945,
      "Name": "Wilmara Chaves da Silva",
      "Email": "fiscal@wpsassessoriacontabil.com.br",
      "CustomerCode": "18890",
      "Phone": "1132292028",
      "Whatsapp": "1185679594",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 946,
      "Name": "Rita de Cassia Ribeiro de Souza",
      "Email": "ritacrs13@gmail.com",
      "CustomerCode": "18891",
      "Phone": "1122252441",
      "Whatsapp": "1187936235",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 947,
      "Name": "Pop Processamentos",
      "Email": "atendimento@popcontabil.com.br",
      "CustomerCode": "18892",
      "Phone": "1155637620",
      "Whatsapp": "1173755665",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 948,
      "Name": "Marcelo Gonçalves",
      "Email": "rh.estrela@terra.com.br",
      "CustomerCode": "18893",
      "Phone": "1137215700",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 949,
      "Name": "José Antenor Gomes Filho",
      "Email": "jagomesfilho@uol.com.br",
      "CustomerCode": "18894",
      "Phone": "1122037694",
      "Whatsapp": "1172826006",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 950,
      "Name": "Chiozzini Serviços de Informações e Contabilidade",
      "Email": "karina@chiozzini.com.br",
      "CustomerCode": "18895",
      "Phone": "1155474535",
      "Whatsapp": "1182413370",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 951,
      "Name": "Saf Assessoria Contábil e Financeira",
      "Email": "contato@safcontabil.com.br",
      "CustomerCode": "18896",
      "Phone": "",
      "Whatsapp": "1191804420",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 952,
      "Name": "Trademaster Serviços e Participações Sa",
      "Email": "financeiro@trademaster.com.br",
      "CustomerCode": "18897",
      "Phone": "1131765628",
      "Whatsapp": "1192801487",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 953,
      "Name": "Vitor Calado de Araújo",
      "Email": "vittorcallado@gmail.com",
      "CustomerCode": "18898",
      "Phone": "",
      "Whatsapp": "11949497779",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 954,
      "Name": "Rl Escritório Contábil Eireli",
      "Email": "ronaldo@rlcont.com.br",
      "CustomerCode": "18899",
      "Phone": "1149714827",
      "Whatsapp": "1176400003",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 955,
      "Name": "Denise Alexandre Netto",
      "Email": "denisenetto@adv.oabsp.org.br",
      "CustomerCode": "18900",
      "Phone": "1129506100",
      "Whatsapp": "11947201326",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 956,
      "Name": "Maktura Consultoria Contábil e Tributaria Ltda.",
      "Email": "rmaktura@grupomaktura.com.br",
      "CustomerCode": "18901",
      "Phone": "1138739404",
      "Whatsapp": "11961654344",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 957,
      "Name": "Afife Assessoria e Consultoria Contábil Eireli",
      "Email": "valmir.cardozo@afifecontabil.com.br",
      "CustomerCode": "18902",
      "Phone": "1133319927",
      "Whatsapp": "1185291875",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 958,
      "Name": "2Mil Contábil SS Ltda.",
      "Email": "legal.2mil@terra.com.br",
      "CustomerCode": "18903",
      "Phone": "1155651602",
      "Whatsapp": "1174842433",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 959,
      "Name": "Leandro Pereira Nascimento de Souza",
      "Email": "simonecn01@gmail.com",
      "CustomerCode": "18904",
      "Phone": "1125079920",
      "Whatsapp": "11963492144",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 960,
      "Name": "Rte Assessoria Empresarial",
      "Email": "thiago.tavares.rte@gmail.com",
      "CustomerCode": "18905",
      "Phone": "",
      "Whatsapp": "11957092798",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 961,
      "Name": "Lgm - Serviços de Escritório & Entretenimentos Ltda.",
      "Email": "mascarenhas804@hotmail.com",
      "CustomerCode": "18906",
      "Phone": "1150412707",
      "Whatsapp": "11947207883",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 962,
      "Name": "Felipe Cabral Sociedade Individual de Advocacia",
      "Email": "thayara@barrals.com.br",
      "CustomerCode": "18907",
      "Phone": "",
      "Whatsapp": "1193407003",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 963,
      "Name": "Aline Contábil Ltda.",
      "Email": "aline.contabil@grupoaline.com.br",
      "CustomerCode": "18908",
      "Phone": "1129557616",
      "Whatsapp": "1185619076",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 964,
      "Name": "Gob Sports",
      "Email": "berel@tecmayer.com.br",
      "CustomerCode": "18909",
      "Phone": "1143242737",
      "Whatsapp": "1198378118",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 966,
      "Name": "Domingues Consultoria Contábil Eireli",
      "Email": "marcel@bpoeficaz.com.br",
      "CustomerCode": "18911",
      "Phone": "1120535789",
      "Whatsapp": "1184676028",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 967,
      "Name": "Marcelo Menchon Felcar",
      "Email": "marcelo@felcaradvogados.com.br",
      "CustomerCode": "18912",
      "Phone": "1171119898",
      "Whatsapp": "1183544156",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 969,
      "Name": "Marcelo Artoni Coelho",
      "Email": "artonicoelho@gmail.com",
      "CustomerCode": "18914",
      "Phone": "",
      "Whatsapp": "1166158972",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 970,
      "Name": "Luiz Otávio Vieira Tristão de Almeida",
      "Email": "lovtalmeida@gmail.com",
      "CustomerCode": "18915",
      "Phone": "1136664355",
      "Whatsapp": "1180128235",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 971,
      "Name": "Daniel Quadros Paes de Barros",
      "Email": "daniel@lspq.com.br",
      "CustomerCode": "18916",
      "Phone": "1135824329",
      "Whatsapp": "1194478521",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 972,
      "Name": "Krg Serviços de Contabilidade Ltda.",
      "Email": "krg@krgcontabilidade.com.br",
      "CustomerCode": "18918",
      "Phone": "1127982950",
      "Whatsapp": "11959833025",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 973,
      "Name": "José Ayrton Ferreiera Leite",
      "Email": "ayrtonleite@ayrtonleite.adv.br",
      "CustomerCode": "18919",
      "Phone": "1134933670",
      "Whatsapp": "11950405100",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 974,
      "Name": "Roberto Gonzalez Correa",
      "Email": "rpateo@sapo.pt",
      "CustomerCode": "18920",
      "Phone": "1139071273",
      "Whatsapp": "1183159043",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 975,
      "Name": "Fernando Lettieri",
      "Email": "lettieri.fernando@yahoo.com",
      "CustomerCode": "18921",
      "Phone": "",
      "Whatsapp": "1198520343",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 976,
      "Name": "Antônio D Orazio",
      "Email": "andorazio@globo.com",
      "CustomerCode": "18922",
      "Phone": "1125413396",
      "Whatsapp": "1174010975",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 977,
      "Name": "J M Matsui Assessoria Fiscal e Contábil",
      "Email": "edsoncorrea@icloud.com",
      "CustomerCode": "18923",
      "Phone": "1135391507",
      "Whatsapp": "1184958127",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 980,
      "Name": "Prover Serviços de Contabilidade SS",
      "Email": "jaqueline@proverassessoria.com.br",
      "CustomerCode": "18926",
      "Phone": "1155873987",
      "Whatsapp": "11961547540",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 981,
      "Name": "Hungaro & Soares Sociedade de Advogados",
      "Email": "hungaro@terra.com.br",
      "CustomerCode": "18927",
      "Phone": "1127691233",
      "Whatsapp": "1199368800",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 982,
      "Name": "Marlene Aparecida Santos",
      "Email": "mazosantos@hotmail.com",
      "CustomerCode": "18928",
      "Phone": "",
      "Whatsapp": "1186651748",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 983,
      "Name": "Eni Maria dos Santos",
      "Email": "enimariac@yahoo.com.br",
      "CustomerCode": "18929",
      "Phone": "",
      "Whatsapp": "11949939053",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 984,
      "Name": "A.C. Assessoria Contábil Bechtold",
      "Email": "sandra@bechtold.com.br",
      "CustomerCode": "18930",
      "Phone": "1126675086",
      "Whatsapp": "1171193848",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 985,
      "Name": "Activa Gestão Empresarial Ltda.",
      "Email": "lucianacanutti@gmail.com",
      "CustomerCode": "18931",
      "Phone": "",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 986,
      "Name": "Madeira Assessoria e Serviços Ltda.",
      "Email": "financeiro@contabilmadeira.com.br",
      "CustomerCode": "18932",
      "Phone": "1122012000",
      "Whatsapp": "1199128494",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 987,
      "Name": "Ânima Contábil e Assessoria S/S Ltda.",
      "Email": "legal@animacontabil.com.br",
      "CustomerCode": "18933",
      "Phone": "1120210850",
      "Whatsapp": "1171700337",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 988,
      "Name": "Rudi Administração e Participações Sociedade Unipe",
      "Email": "adriana.quintale@gruporudi.com.br",
      "CustomerCode": "18934",
      "Phone": "1130512223",
      "Whatsapp": "1189448245",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 989,
      "Name": "Dr. Fam Clínica Médica S.A",
      "Email": "juridico.societario@adobenet.com.br ",
      "CustomerCode": "18935",
      "Phone": "1138976259",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 990,
      "Name": "Hc Morumbi Serviços Administrativos Ltda.",
      "Email": "legalizacao@healthcarecontabilidade.com.br",
      "CustomerCode": "18936",
      "Phone": "1125383681",
      "Whatsapp": "1171203432",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 991,
      "Name": "Aquatro Soluções Administrativas",
      "Email": "adm@solucoesaquatro.com",
      "CustomerCode": "18937",
      "Phone": "1155114571",
      "Whatsapp": "11939312934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 992,
      "Name": "Focus Consultoria Imobiliária Ltda.",
      "Email": "mdaud@uol.com.br",
      "CustomerCode": "18938",
      "Phone": "1130451727",
      "Whatsapp": "1182660335",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 993,
      "Name": "Mmg Assessoria e Consultoria Contábil e Fiscal Ltda.",
      "Email": "leandro.yamada@mmgconsultoria.com",
      "CustomerCode": "18939",
      "Phone": "1123720462",
      "Whatsapp": "1173835723",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 994,
      "Name": "Mcm Assessoria Contábil Ltda.",
      "Email": "reparticoes@mcmcontabil.com.br",
      "CustomerCode": "18940",
      "Phone": "113851238",
      "Whatsapp": "1184090088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 995,
      "Name": "Gerau Assessoria Contábil SS Ltda.",
      "Email": "gerau@terra.com.br",
      "CustomerCode": "18941",
      "Phone": "",
      "Whatsapp": "1181813650",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 996,
      "Name": "Pamela Suarames Lemos Teles",
      "Email": "pam.adv92@gmail.com",
      "CustomerCode": "18942",
      "Phone": "",
      "Whatsapp": "11954412773",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 998,
      "Name": "Marcia Moreira Franca Zanandre",
      "Email": "mdr.ass@uol.com.br",
      "CustomerCode": "18944",
      "Phone": "1129894731",
      "Whatsapp": "1173368824",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 999,
      "Name": "José de Lima Oliveira",
      "Email": "jloliveira@lopes.com.br",
      "CustomerCode": "18945",
      "Phone": "1139989179",
      "Whatsapp": "11995614195",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1000,
      "Name": "Contábil Sorrentino Ltda.",
      "Email": "josi@contabilsorrentino.com.br",
      "CustomerCode": "18946",
      "Phone": "1138128539",
      "Whatsapp": "1193746463",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1001,
      "Name": "Cleide Donizete Faustino",
      "Email": "tatiana.artemis@gmail.com",
      "CustomerCode": "18947",
      "Phone": "1131056211",
      "Whatsapp": "11961318855",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1002,
      "Name": "Luiz Henrique Palmeira",
      "Email": "luiz_henrique_contabilidade@hotmail.com",
      "CustomerCode": "18948",
      "Phone": "1120118582",
      "Whatsapp": "1168354350",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1003,
      "Name": "Danilo Aragão Santos Advogados",
      "Email": "d.aragao@dasadv.com.br",
      "CustomerCode": "18950",
      "Phone": "1155553654",
      "Whatsapp": "1189423762",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1004,
      "Name": "Charlene Coradi Barreto",
      "Email": "charlene_coradi@hotmail.com",
      "CustomerCode": "18951",
      "Phone": "",
      "Whatsapp": "1199134455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1005,
      "Name": "Contal Line Assessoria Empresarial Ltda.",
      "Email": "processos@consuport.com.br",
      "CustomerCode": "18952",
      "Phone": "1127311281",
      "Whatsapp": "11940354470",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1006,
      "Name": "Phonte Nova Recuperação de Crédito Ltda.",
      "Email": "juridico@phontenova.com.br",
      "CustomerCode": "18953",
      "Phone": "1137988626",
      "Whatsapp": "1185962244",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1007,
      "Name": "Marcelo José Victtor dos Anjos",
      "Email": "marcelo.victtor@gmail.com",
      "CustomerCode": "18954",
      "Phone": "1123840962",
      "Whatsapp": "1184851976",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1008,
      "Name": "Auo Duarte Consultoria",
      "Email": "alexandre@tecnolaw.com.br",
      "CustomerCode": "18955",
      "Phone": "1137917706",
      "Whatsapp": "1180955868",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1009,
      "Name": "Polli Rodrigues Sociedade de Advogados",
      "Email": "fabio@pollirodrigues.com",
      "CustomerCode": "18956",
      "Phone": "1132848110",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1010,
      "Name": "Alessandro Soares Costa",
      "Email": "lucianabarrosdiniz@hotmail.com",
      "CustomerCode": "18957",
      "Phone": "1140338288",
      "Whatsapp": "1170896269",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1011,
      "Name": "Luiz Fernando Messias Bispo",
      "Email": "luiz.bispo@fbispo.com.br",
      "CustomerCode": "18958",
      "Phone": "",
      "Whatsapp": "1172831547",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1012,
      "Name": "Claudia Serein",
      "Email": "serein@terra.com.br",
      "CustomerCode": "18959",
      "Phone": "1150737265",
      "Whatsapp": "1197516928",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1013,
      "Name": "Elaine da Silva Egidio",
      "Email": "elaine.egidio@compede.com.br",
      "CustomerCode": "18960",
      "Phone": "1125392673",
      "Whatsapp": "1168973159",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1015,
      "Name": "Mpo Contabilidade e Consultoria Ltda.",
      "Email": "marcos.oliveira@mpocontabilidade.com.br",
      "CustomerCode": "18962",
      "Phone": "",
      "Whatsapp": "1176091389",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1018,
      "Name": "Jasf Auditoria e Contabilidade",
      "Email": "contabilidade@grupojasf.com.br",
      "CustomerCode": "18965",
      "Phone": "1150844238",
      "Whatsapp": "1164444514",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1019,
      "Name": "Luís Henrique Favret",
      "Email": "luis@oliveirafavret.com.br",
      "CustomerCode": "18966",
      "Phone": "1123376044",
      "Whatsapp": "1198769309",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1020,
      "Name": "Zuleide Aparecida Barral Santos",
      "Email": "zubarral@gmail.com",
      "CustomerCode": "18967",
      "Phone": "",
      "Whatsapp": "1170939978",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1021,
      "Name": "Jobicont Consultoria Contábil",
      "Email": "carlos@jobicont.com.br",
      "CustomerCode": "18968",
      "Phone": "",
      "Whatsapp": "1171474325",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1022,
      "Name": "Antonino Costa Filho",
      "Email": "contatoalmalatina@terra.com.br",
      "CustomerCode": "18969",
      "Phone": "1126762677",
      "Whatsapp": "1173122683",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1023,
      "Name": "Roberto Faleck",
      "Email": "rfaleck@gmail.com",
      "CustomerCode": "18970",
      "Phone": "",
      "Whatsapp": "11963423628",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1024,
      "Name": "Rogerio Aparecido Dias Avelar",
      "Email": "rogeravelar@hotmail.com",
      "CustomerCode": "18971",
      "Phone": "1133921098",
      "Whatsapp": "11955557010",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1025,
      "Name": "Luiz Carlos Vieira",
      "Email": "lunecon@hotmail.com",
      "CustomerCode": "18973",
      "Phone": "1120428126",
      "Whatsapp": "1183675961",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1029,
      "Name": "Gabriella El Khouri",
      "Email": "gakhouri25@gmail.com",
      "CustomerCode": "18977",
      "Phone": "1133957097",
      "Whatsapp": "11998344399",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1030,
      "Name": "Eliel Soares Scoalheira ME",
      "Email": "eliel.scoalheira@hotmail.com",
      "CustomerCode": "18978",
      "Phone": "1129476923",
      "Whatsapp": "114724938",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1031,
      "Name": "Felipe Cavalhero Ojeda",
      "Email": "ojeda.felipe@ymail.com",
      "CustomerCode": "18979",
      "Phone": "1188274861",
      "Whatsapp": "1164355344",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1033,
      "Name": "Tatiane Soares Silva",
      "Email": "tatiane.ss@hotmail.com",
      "CustomerCode": "18981",
      "Phone": "1120530259",
      "Whatsapp": "1193052306",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1034,
      "Name": "Alexandre Gomes da Silva",
      "Email": "alexandre.gomes@tcst.com.br",
      "CustomerCode": "18982",
      "Phone": "1127876382",
      "Whatsapp": "11947958828",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1035,
      "Name": "Mfgiannini Contabilidade Ltda.",
      "Email": "seucontador.mfgiannini@gmail.com",
      "CustomerCode": "18983",
      "Phone": "1130904345",
      "Whatsapp": "11950213426",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1036,
      "Name": "Imagem Contábil",
      "Email": "imagemcontabil@outlook.com",
      "CustomerCode": "18984",
      "Phone": "1132415379",
      "Whatsapp": "1196286116",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1037,
      "Name": "Raimundo de Brito da Silva Silva",
      "Email": "raimundodebritocontador@gmail.com",
      "CustomerCode": "18985",
      "Phone": "",
      "Whatsapp": "119622008119",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1038,
      "Name": "Ido Ribeiro dos Santos",
      "Email": "idoribeiro@uol.com.br",
      "CustomerCode": "18986",
      "Phone": "1132420586",
      "Whatsapp": "11958688536",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1039,
      "Name": "Newfisc Assessoria Contábil e Fiscal",
      "Email": "newfisc@hotmail.com",
      "CustomerCode": "18987",
      "Phone": "1129123904",
      "Whatsapp": "1185841690",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1040,
      "Name": "R D Pardinho Contabilidade ME",
      "Email": "fiscal@rpardinhocontabilidade.com.br",
      "CustomerCode": "18988",
      "Phone": "1131512712",
      "Whatsapp": "11943189661",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1041,
      "Name": "Conti Contadores Associados SS",
      "Email": "fabio@conticontadores.com.br",
      "CustomerCode": "18989",
      "Phone": "1123668105",
      "Whatsapp": "1176393924",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1042,
      "Name": "Renata Rodrigues Pereira Barione",
      "Email": "recorre@recorrecontabilidade.com.br",
      "CustomerCode": "18990",
      "Phone": "1120436232",
      "Whatsapp": "11952179484",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1044,
      "Name": "Bloomer Gestão Contábil Ltda.",
      "Email": "felipe.costa0791@gmail.com",
      "CustomerCode": "18992",
      "Phone": "",
      "Whatsapp": "1169886254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1045,
      "Name": "Rubens Pereira França",
      "Email": "rubens.franca@gehaka.com.br",
      "CustomerCode": "18993",
      "Phone": "1121651133",
      "Whatsapp": "1194950864",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1046,
      "Name": "Avipam Viagens e Turismo Ltda.",
      "Email": "auditoriainterna@avipam.com.br",
      "CustomerCode": "18994",
      "Phone": "1448904397",
      "Whatsapp": "2181151881",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1047,
      "Name": "Paulo Roberto Andrade dos Santos",
      "Email": "paulo@andradeadvjur.com.br",
      "CustomerCode": "18995",
      "Phone": "",
      "Whatsapp": "1194631354",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1049,
      "Name": "Matheus Kitagawa Alves",
      "Email": "juridico@hagana.com.br",
      "CustomerCode": "18997",
      "Phone": "1133931717",
      "Whatsapp": "11963125851",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1050,
      "Name": "Pedro Henrique Mendes de Castro",
      "Email": "pedro_hmc@hotmail.com",
      "CustomerCode": "18998",
      "Phone": "1144210026",
      "Whatsapp": "1194520588",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1051,
      "Name": "Evoluconta Contabilidade Ltda.",
      "Email": "andersonflorencio@evoluconta.com.br",
      "CustomerCode": "18999",
      "Phone": "",
      "Whatsapp": "1988064866",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1052,
      "Name": "Contábil e Assessoria Total Ltda.",
      "Email": "mario@assessoriatotal.com.br",
      "CustomerCode": "19000",
      "Phone": "1126677787",
      "Whatsapp": "11981360054",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1053,
      "Name": "Eliana Contabilidade",
      "Email": "eliana@elianacontabilidade.com.br",
      "CustomerCode": "35101",
      "Phone": "1124429099",
      "Whatsapp": "1288193590",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1054,
      "Name": "Heisei Contábil S/C. Ltda.",
      "Email": "heisei@uol.com.br",
      "CustomerCode": "35107",
      "Phone": "1124632111",
      "Whatsapp": "1196905321",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1055,
      "Name": "Contábil Delta S/C Ltda.",
      "Email": "deltavaz1@gmail.com",
      "CustomerCode": "35122",
      "Phone": "1124683011",
      "Whatsapp": "1173379397",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1056,
      "Name": "Visão Nobre Serviços Especializados Ltda. - ME",
      "Email": "visao.nobre@gmail.com",
      "CustomerCode": "35127",
      "Phone": "1124682615",
      "Whatsapp": "1182310957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1057,
      "Name": "Floris Escritório Contábil S/C Ltda.",
      "Email": "floris@floris.com.br",
      "CustomerCode": "35128",
      "Phone": "1124610663",
      "Whatsapp": "1199723566",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1058,
      "Name": "Account Serviços Contábeis",
      "Email": "clara161@terra.com.br",
      "CustomerCode": "35129",
      "Phone": "1124610280",
      "Whatsapp": "1192416997",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1059,
      "Name": "Remacon Assessoria e Contabilidade S/C Ltda.",
      "Email": "dilma@remacon.com.br",
      "CustomerCode": "35131",
      "Phone": "1124750493",
      "Whatsapp": "1192117675",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1060,
      "Name": "Escritório Contábil Bastos S/C Ltda.",
      "Email": "natassia@contabilbastos.com.br",
      "CustomerCode": "35132",
      "Phone": "1124478100",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1061,
      "Name": "Ronaldo Luís Pellizzon",
      "Email": "ronaldopellizzon@gmail.com",
      "CustomerCode": "35154",
      "Phone": "1124590836",
      "Whatsapp": "1193070586",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1062,
      "Name": "Nova Era Assessoria Contábil",
      "Email": "nova_era@terra.com.br",
      "CustomerCode": "35155",
      "Phone": "1124613662",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1063,
      "Name": "Bso Digital Serv Adm Ltda.",
      "Email": "andre.borghi@bsocontabil.com.br",
      "CustomerCode": "35217",
      "Phone": "1124521208",
      "Whatsapp": "1181974422",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1064,
      "Name": "Ageu Assessoria Contábil Ltda.",
      "Email": "financeiro@ageucontabilidade.com.br",
      "CustomerCode": "35290",
      "Phone": "1149623900",
      "Whatsapp": "1192133693",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1065,
      "Name": "Cerqueira & Oliveira",
      "Email": "fecerqueira@gmail.com",
      "CustomerCode": "35298",
      "Phone": "1121480912",
      "Whatsapp": "1175851993",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1066,
      "Name": "Jjp Contabilidade e Consult. Empresarial Ltda.",
      "Email": "denis.gabriel@jjpconsultoria.com.br",
      "CustomerCode": "35300",
      "Phone": "1124414719",
      "Whatsapp": "1124414719",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1067,
      "Name": "Deuzelia Pinto da Luz Alexandroni",
      "Email": "deuzelia.alexandroni@uol.com.br",
      "CustomerCode": "35306",
      "Phone": "1124022473",
      "Whatsapp": "1169020364",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1068,
      "Name": "Maria Liane Scarcela Nascimento",
      "Email": "lianescarcela@gmail.com",
      "CustomerCode": "35309",
      "Phone": "1123043728",
      "Whatsapp": "1189592246",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1069,
      "Name": "Contfy Serviços Contábeis Eirel",
      "Email": "gilvan@contfy.com",
      "CustomerCode": "35311",
      "Phone": "1126005843",
      "Whatsapp": "1167280267",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1070,
      "Name": "Ariane Cristina Fontes Ferreira Bartholomeu",
      "Email": "apassescontab@gmail.com",
      "CustomerCode": "35312",
      "Phone": "1143860529",
      "Whatsapp": "11953922818",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1071,
      "Name": "Fernando Okabaiashi",
      "Email": "fernandookabaiashi@hotmail.com",
      "CustomerCode": "35313",
      "Phone": "1124432519",
      "Whatsapp": "1191803253",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1072,
      "Name": "Tupã Assessoria & Consultoria Contábil Ltda.",
      "Email": "mntupa@gmail.com",
      "CustomerCode": "35314",
      "Phone": "1124518688",
      "Whatsapp": "11940480978",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1073,
      "Name": "Neusa Maria de Souza Ribeiro",
      "Email": "starcontabilidad@terra.com.br",
      "CustomerCode": "35315",
      "Phone": "1142041369",
      "Whatsapp": "11950621763",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1074,
      "Name": "Welinton Balderrama dos Reis",
      "Email": "wbdosreis@gmail.com",
      "CustomerCode": "35316",
      "Phone": "1124640486",
      "Whatsapp": "1182438590",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1075,
      "Name": "Djavan Ribeiro Soares",
      "Email": "djavan.ribeiro@hotmail.com",
      "CustomerCode": "35317",
      "Phone": "",
      "Whatsapp": "11948741770",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1076,
      "Name": "Rodney Matias Contabilidade ME",
      "Email": "rodney@rgk-contabilidade.com.br",
      "CustomerCode": "35318",
      "Phone": "1143076356",
      "Whatsapp": "1185171772",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1077,
      "Name": "Felipe Encarnação Piovesan",
      "Email": "felipe.piovesan@mazars.com.br",
      "CustomerCode": "35319",
      "Phone": "1135244500",
      "Whatsapp": "1169124698",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1078,
      "Name": "Contabilidade Equipe",
      "Email": "simone3038@yahoo.com.br",
      "CustomerCode": "36106",
      "Phone": "1136819210",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1079,
      "Name": "Contabilidade Leiria",
      "Email": "lleiria@terra.com.br",
      "CustomerCode": "36110",
      "Phone": "1136833880",
      "Whatsapp": "1199627625",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1080,
      "Name": "Audiconta Contabilidade e Assessoria. Admin.",
      "Email": "audiconta.josecarlos@uol.com.br",
      "CustomerCode": "36117",
      "Phone": "1136913862",
      "Whatsapp": "1199372168",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1081,
      "Name": "Contabilidade Regionaly Ltda.",
      "Email": "contabilregionaly@hotmail.com",
      "CustomerCode": "36132",
      "Phone": "1136995287",
      "Whatsapp": "1198155514",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1082,
      "Name": "Marisa Fernandes Lopes",
      "Email": "davila.contabilidade@hotmail.com.br",
      "CustomerCode": "36135",
      "Phone": "1135991090",
      "Whatsapp": "1198591410",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1083,
      "Name": "Gerplan",
      "Email": "dp@gerplancont.com.br",
      "CustomerCode": "36139",
      "Phone": "1136875051",
      "Whatsapp": "1171075646",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1084,
      "Name": "Seven Contabilidade Ltda.",
      "Email": "douglaspneves@uol.com.br",
      "CustomerCode": "36144",
      "Phone": "1136082816",
      "Whatsapp": "1191041656",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1085,
      "Name": "Aparecido Antônio da Silva",
      "Email": "aparecido@protende.com.br",
      "CustomerCode": "36145",
      "Phone": "1136586111",
      "Whatsapp": "1197890129",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1086,
      "Name": "Contabilidade Ação",
      "Email": "contabilacao@yahoo.com.br",
      "CustomerCode": "36207",
      "Phone": "1136087610",
      "Whatsapp": "1198801713",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1087,
      "Name": "Robson Prado Sociedade Individual de Advocacia",
      "Email": "katia@robsonprado.adv.br",
      "CustomerCode": "36209",
      "Phone": "1141951939",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1088,
      "Name": "Renato Loureiro Morato",
      "Email": "swell.contabil@gmail.com",
      "CustomerCode": "36216",
      "Phone": "1144360000",
      "Whatsapp": "1184473310",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1089,
      "Name": "Carla Carine Lima Grangeia",
      "Email": "carla.carine@igb.com.br",
      "CustomerCode": "36220",
      "Phone": "1141661908",
      "Whatsapp": "1174275626",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1090,
      "Name": "Agregaconsult",
      "Email": "marcostenani@agregaconsult.com",
      "CustomerCode": "36227",
      "Phone": "",
      "Whatsapp": "1180272618",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1091,
      "Name": "D. B. Fernando Contabilidade",
      "Email": "d.b.fernando@uol.com.br",
      "CustomerCode": "36229",
      "Phone": "1136824143",
      "Whatsapp": "1191209892",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1092,
      "Name": "Everaldo Do Nascimento Almeida",
      "Email": "everaldonasc@hotmail.com",
      "CustomerCode": "36233",
      "Phone": "1135922111",
      "Whatsapp": "1192246080",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1093,
      "Name": "Moacir Leite da Silva",
      "Email": "moacir.mscont@gmail.com",
      "CustomerCode": "36236",
      "Phone": "1143117098",
      "Whatsapp": "1193505611",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1094,
      "Name": "Rosemari Valdo de Menezes Covay",
      "Email": "rose.valdo@hotmail.com",
      "CustomerCode": "36237",
      "Phone": "1136837707",
      "Whatsapp": "1199440904",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1095,
      "Name": "Daniel Manoel da Silva",
      "Email": "daniel@fxsistemas.com.br",
      "CustomerCode": "36238",
      "Phone": "1145560098",
      "Whatsapp": "1198000883",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1096,
      "Name": "Willian Ferreira da Silva",
      "Email": "contato@gestaoinova.com.br",
      "CustomerCode": "36246",
      "Phone": "1123374656",
      "Whatsapp": "1164850284",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1098,
      "Name": "Rogério Paneghine",
      "Email": "rpaneghine@hotmail.com",
      "CustomerCode": "36316",
      "Phone": "",
      "Whatsapp": "1199597360",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1099,
      "Name": "Ttl Soluções Empresariais",
      "Email": "controller@ttlcontroladoria.com.br",
      "CustomerCode": "36317",
      "Phone": "1143840761",
      "Whatsapp": "1195363536",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1100,
      "Name": "Dino Cesar de Souza Pisaneschi",
      "Email": "pisaneschicontabilidade@uol.com.br",
      "CustomerCode": "36318",
      "Phone": "1124516640",
      "Whatsapp": "1196011119",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1101,
      "Name": "Silene Rodrigues Fray",
      "Email": "contabilidade.fray@uol.com.br",
      "CustomerCode": "37108",
      "Phone": "1141612530",
      "Whatsapp": "1188194830",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1102,
      "Name": "Arlete Aparecida Viveiro Budicin",
      "Email": "arlete@plug2.com.br",
      "CustomerCode": "37120",
      "Phone": "1141581759",
      "Whatsapp": "1189268042",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1103,
      "Name": "Mecontábil S/C Ltda.",
      "Email": "mecontabil@allnet.com.br",
      "CustomerCode": "37131",
      "Phone": "1147012270",
      "Whatsapp": "1185057438",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1104,
      "Name": "Adail Vieira",
      "Email": "adailcon@uol.com.br",
      "CustomerCode": "37132",
      "Phone": "1147877793",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1105,
      "Name": "Emilio Cesar Galdino Matos",
      "Email": "contmatos@outlook.com",
      "CustomerCode": "37137",
      "Phone": "1146692133",
      "Whatsapp": "1196646873",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1106,
      "Name": "Contabec Serviços Fiscos e Contábeis",
      "Email": "maroliveira123@icloud.com",
      "CustomerCode": "37140",
      "Phone": "1146634855",
      "Whatsapp": "1175390048",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1107,
      "Name": "Contabilidade Menezes",
      "Email": "contabilidade@contabilidademenezes.com.br",
      "CustomerCode": "37143",
      "Phone": "1141944008",
      "Whatsapp": "1174232161",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1108,
      "Name": "Gabrielle Patrício de Jesus",
      "Email": "societario@mmjcontabil.com.br",
      "CustomerCode": "37147",
      "Phone": "1141674924",
      "Whatsapp": "11952545255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1109,
      "Name": "Fenix Escritório Contábil",
      "Email": "araujoluiz@bol.com.br",
      "CustomerCode": "37155",
      "Phone": "1157875718",
      "Whatsapp": "11996767255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1110,
      "Name": "Silveira Machado Contabilidade Empresarial S/C Ltda.",
      "Email": "milton@silveiramachado.com.br",
      "CustomerCode": "37166",
      "Phone": "1144451097",
      "Whatsapp": "11947765422",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1111,
      "Name": "Pereira Assessoria Contábil",
      "Email": "contato@fintt.com.br",
      "CustomerCode": "37169",
      "Phone": "1141936267",
      "Whatsapp": "1194210121",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1113,
      "Name": "Salvador Jacomin",
      "Email": "monte.sinae12@gmail.com",
      "CustomerCode": "37177",
      "Phone": "1141941415",
      "Whatsapp": "1174874427",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1114,
      "Name": "Contábil Caucaia",
      "Email": "ze.mauro@contabilcaucaia.com.br",
      "CustomerCode": "37188",
      "Phone": "1146117103",
      "Whatsapp": "1173200299",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1115,
      "Name": "Detafran Contábil SC Ltda.",
      "Email": "clodoaldo@detafran.com.br",
      "CustomerCode": "37193",
      "Phone": "1144482242",
      "Whatsapp": "1196896024",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1116,
      "Name": "Fkc Participações Ltda.",
      "Email": "administrativo4@fkc.com.br",
      "CustomerCode": "37232",
      "Phone": "1141918685",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1117,
      "Name": "Sebastião Xavier de Morais",
      "Email": "aste.con@terra.com.br",
      "CustomerCode": "37233",
      "Phone": "1142434270",
      "Whatsapp": "11943537755",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1118,
      "Name": "Contimaster Assessoria Empresarial",
      "Email": "dpfiscal@vinculoempresarial.com.br",
      "CustomerCode": "37237",
      "Phone": "1145661256",
      "Whatsapp": "1198108336",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1119,
      "Name": "Starcont Serv. Administrativos",
      "Email": "mari.pena@uol.com.br",
      "CustomerCode": "37241",
      "Phone": "1146146230",
      "Whatsapp": "1191461728",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1120,
      "Name": "Louping Organização Contábil S/C Ltda.",
      "Email": "contabil@louping.com.br",
      "CustomerCode": "37242",
      "Phone": "1146136274",
      "Whatsapp": "1199138334",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1121,
      "Name": "Thiago Faustino de Faria",
      "Email": "thiago@contabilidadefutura.com.br",
      "CustomerCode": "37262",
      "Phone": "1141546120",
      "Whatsapp": "11959200224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1122,
      "Name": "Aconthe Valentini Consultoria Contábil S/S",
      "Email": "fvalentini@aconthe.com.br",
      "CustomerCode": "37263",
      "Phone": "1141910211",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1123,
      "Name": "Jefferson Pereira da Silva Cavalcante",
      "Email": "gerencia@contabilidadecavalcante.com.br",
      "CustomerCode": "37264",
      "Phone": "1147739626",
      "Whatsapp": "1191191438",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1124,
      "Name": "Lubercon Prime Contabilidade Empresarial Ltda.",
      "Email": "lucia@millenionprime.com.br",
      "CustomerCode": "37276",
      "Phone": "1146043573",
      "Whatsapp": "11991447822",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1125,
      "Name": "Jrrd Contabilidade",
      "Email": "robertson@jrrdcontabilidade.com.br",
      "CustomerCode": "37293",
      "Phone": "1141989839",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1126,
      "Name": "Mattar Serviços Contábeis Ltda.",
      "Email": "fiscal@mattar.com.br",
      "CustomerCode": "37295",
      "Phone": "1146881560",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1127,
      "Name": "Antônio Borrego Junior",
      "Email": "dacaborrego@uol.com.br",
      "CustomerCode": "37296",
      "Phone": "1137313374",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1128,
      "Name": "Contabilidade Clamar",
      "Email": "contabilidadeclamar@uol.com.br",
      "CustomerCode": "37303",
      "Phone": "1147030805",
      "Whatsapp": "1196072740",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1129,
      "Name": "Sampa Assessoria e Organização Contábil",
      "Email": "prado_sampa@yahoo.com.br",
      "CustomerCode": "37313",
      "Phone": "1127717107",
      "Whatsapp": "1187026203",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1130,
      "Name": "Du-Pontes Assessoria Contabile Fiscal S/S Ltda.",
      "Email": "adair.pontes@terra.com.br",
      "CustomerCode": "37314",
      "Phone": "1147033148",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1131,
      "Name": "Metodo Contabilidade",
      "Email": "metodo.servicoscontabeis@gmail.com",
      "CustomerCode": "37317",
      "Phone": "1141841802",
      "Whatsapp": "1174712621",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1132,
      "Name": "Djalma Auditoria e Contabilidade",
      "Email": "djalmajose@ig.com.br",
      "CustomerCode": "37320",
      "Phone": "1147032606",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1133,
      "Name": "Lenon Santos",
      "Email": "leno@febscontabil.com.br",
      "CustomerCode": "37349",
      "Phone": "1142434317",
      "Whatsapp": "11947764640",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1134,
      "Name": "Meta Contabilidade & Condomínios",
      "Email": "gisela.macedo@metaempresasecondominios.com.br",
      "CustomerCode": "37351",
      "Phone": "1147017925",
      "Whatsapp": "1172418443",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1135,
      "Name": "Hamaro Thadeu - Contabilidade",
      "Email": "atbocontabil@gmail.com",
      "CustomerCode": "37355",
      "Phone": "1141416726",
      "Whatsapp": "11953625708",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1136,
      "Name": "Cibelle Jorge Luna Assessoria Contábil",
      "Email": "cibelle.luna@contabilidadeluna.com.br",
      "CustomerCode": "37357",
      "Phone": "1146120224",
      "Whatsapp": "1196082720",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1137,
      "Name": "Ronaldo Felizardo Galvão",
      "Email": "felizardogalvao2019@gmail.com",
      "CustomerCode": "37358",
      "Phone": "1146695566",
      "Whatsapp": "1170443316",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1138,
      "Name": "Garrido Contabilidade e Consultoria Tributaria Eireli",
      "Email": "equipe@garrido.com.br",
      "CustomerCode": "37366",
      "Phone": "1147833030",
      "Whatsapp": "11953530554",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1139,
      "Name": "Gislaine Maria da Silva",
      "Email": "gis.hsilva@gmail.com",
      "CustomerCode": "37373",
      "Phone": "1146155150",
      "Whatsapp": "1174189176",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1140,
      "Name": "Ademir Heleno Pascheto",
      "Email": "adepasc@hotmail.com",
      "CustomerCode": "37380",
      "Phone": "1141526830",
      "Whatsapp": "1170990110",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1141,
      "Name": "Elisabete Dias",
      "Email": "gerp12@hotmail.com",
      "CustomerCode": "37382",
      "Phone": "1141183479",
      "Whatsapp": "11945280312",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1142,
      "Name": "Amonex Do Brasil Indústria e Comércio Limitada",
      "Email": "agatha@amonex.com.br",
      "CustomerCode": "37384",
      "Phone": "1147898989",
      "Whatsapp": "1197735663",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1143,
      "Name": "Marcia Damas",
      "Email": "marcia@contabilidadefenix.net.br",
      "CustomerCode": "37396",
      "Phone": "1143212694",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1144,
      "Name": "Asj Solução Empresarial",
      "Email": "comercial@asjsolucoes.com",
      "CustomerCode": "37397",
      "Phone": "1143855726",
      "Whatsapp": "1164131026",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1145,
      "Name": "Cpc Contabilidade",
      "Email": "joaoantonio180664@gmail.com",
      "CustomerCode": "37398",
      "Phone": "1141370472",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1146,
      "Name": "Fcont Gestão Empresarial",
      "Email": "franco@fcontgestao.com.br",
      "CustomerCode": "37404",
      "Phone": "1147020057",
      "Whatsapp": "1199464285",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1147,
      "Name": "Matos Assessoria Contábil",
      "Email": "contato@matoscontador.com.br",
      "CustomerCode": "37407",
      "Phone": "1141398923",
      "Whatsapp": "1167090259",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1148,
      "Name": "Francisco de Paula de Jesus",
      "Email": "novaonlinecontabilidade@gmail.com",
      "CustomerCode": "37408",
      "Phone": "1143260081",
      "Whatsapp": "1175042613",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1149,
      "Name": "Marcio Rogério de Resende",
      "Email": "mvaservicoscontabeis@gmail.com",
      "CustomerCode": "37409",
      "Phone": "1147026362",
      "Whatsapp": "119611987603",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1150,
      "Name": "Consult-Fisco Contabilidade SS Ltda.",
      "Email": "euzebio@consult-fisco.com.br",
      "CustomerCode": "37410",
      "Phone": "1146688020",
      "Whatsapp": "1199877984",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1151,
      "Name": "Lt & F - Serviços Ltda.",
      "Email": "adm@ltfconsultoria.com.br",
      "CustomerCode": "37411",
      "Phone": "1136836195",
      "Whatsapp": "1175420727",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1152,
      "Name": "Duarte Contabilidade",
      "Email": "holiver.pinheiro@contduarte.com.br",
      "CustomerCode": "37413",
      "Phone": "1143031760",
      "Whatsapp": "1197307514",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1153,
      "Name": "Freitas Contabilidade S/S Ltda. EPP",
      "Email": "diretor@gilbertofreitascontabil.com.br",
      "CustomerCode": "37416",
      "Phone": "1141843925",
      "Whatsapp": "1173867500",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1154,
      "Name": "Engrecon S/A",
      "Email": "roniere.medeiros@engrecon.com.br",
      "CustomerCode": "37419",
      "Phone": "1141549021",
      "Whatsapp": "11948591659",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1155,
      "Name": "Antônio Roberto Nazareth dos Santos",
      "Email": "onazasantos@gmail.com",
      "CustomerCode": "37420",
      "Phone": "1146168460",
      "Whatsapp": "1199616975",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1156,
      "Name": "Claiton Rogério Pacheco",
      "Email": "pgrupopacheco@yahoo.com.br",
      "CustomerCode": "37421",
      "Phone": "1141430884",
      "Whatsapp": "11954078327",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1157,
      "Name": "Onisia Neres Micael",
      "Email": "eunicemicael3@gmail.com",
      "CustomerCode": "37422",
      "Phone": "",
      "Whatsapp": "11953874380",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1158,
      "Name": "Abnl Company Ltda.",
      "Email": "ballarin@abnl.com.br",
      "CustomerCode": "37423",
      "Phone": "1143808506",
      "Whatsapp": "1196430203",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1159,
      "Name": "Manoel Gambeta Luciano",
      "Email": "manoel@confasp.com.br",
      "CustomerCode": "37424",
      "Phone": "1136834418",
      "Whatsapp": "11983775485",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1160,
      "Name": "Wagner Pinheiro dos Santos",
      "Email": "wagner.contabilrgs@gmail.com",
      "CustomerCode": "37425",
      "Phone": "1148213868",
      "Whatsapp": "1171107892",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1161,
      "Name": "Braga Serviços de Escritório",
      "Email": "bragaescritorio@outlook.com",
      "CustomerCode": "37426",
      "Phone": "1193281440",
      "Whatsapp": "1193281440",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1162,
      "Name": "Adriana Aparecida Torelli",
      "Email": "escritoriocontabil01@gmail.com",
      "CustomerCode": "37427",
      "Phone": "1142013801",
      "Whatsapp": "1175595707",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1163,
      "Name": "Valmir Pires Silva",
      "Email": "contabilidade@contanalise.com.br",
      "CustomerCode": "37428",
      "Phone": "1141383079",
      "Whatsapp": "11951081250",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1164,
      "Name": "Daniel Henrique Paiva Tonon",
      "Email": "dahen@terra.com.br",
      "CustomerCode": "37429",
      "Phone": "1145545247",
      "Whatsapp": "1199099935",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1165,
      "Name": "Narimatsu Sociedade Individual de Advocacia",
      "Email": "patricia@narimatsu.adv.br",
      "CustomerCode": "37430",
      "Phone": "1146669300",
      "Whatsapp": "11943580890",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1166,
      "Name": "Nytron Internacional",
      "Email": "crisfonseca@nytroninternacional.com.br",
      "CustomerCode": "37431",
      "Phone": "1124243900",
      "Whatsapp": "1173296905",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1167,
      "Name": "Neves Aparecida Maria dos Santos",
      "Email": "neves.contabil@gmail.com",
      "CustomerCode": "37432",
      "Phone": "1147014617",
      "Whatsapp": "11947038155",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1168,
      "Name": "Eam Carneiro Contabilidade Eireli",
      "Email": "ewerton@eamcontabilidade.com.br",
      "CustomerCode": "37433",
      "Phone": "1147016514",
      "Whatsapp": "1192290852",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1169,
      "Name": "Francisco Carlos de Oliveira",
      "Email": "islifran@gmail.com",
      "CustomerCode": "37434",
      "Phone": "",
      "Whatsapp": "1188347490",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1170,
      "Name": "Aires e Rocha Assessoria Contábil Ltda.",
      "Email": "leandro@aireserocha.com.br",
      "CustomerCode": "37435",
      "Phone": "1141484827",
      "Whatsapp": "1197391194",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1171,
      "Name": "Amauri Apparecido Moris",
      "Email": "amaurimoris@uol.com.br",
      "CustomerCode": "37436",
      "Phone": "1144191714",
      "Whatsapp": "1171648943",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1172,
      "Name": "Ricardo Moraes Do Nascimento",
      "Email": "ricardonmoraes@hotmail.com",
      "CustomerCode": "37437",
      "Phone": "",
      "Whatsapp": "1191841940",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1173,
      "Name": "Florence Indústrial e Comercial Ltda.",
      "Email": "claudia.rufino@marcoboni.com.br",
      "CustomerCode": "37438",
      "Phone": "1146156500",
      "Whatsapp": "1189962599",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1174,
      "Name": "Ivanilde Gomes da Silva",
      "Email": "contabilidadegv@outlook.com.br",
      "CustomerCode": "37439",
      "Phone": "",
      "Whatsapp": "11947891377",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1175,
      "Name": "Odeilton de Jesus Mota",
      "Email": "legalizacao@icontabile.com",
      "CustomerCode": "37440",
      "Phone": "1141612199",
      "Whatsapp": "1165667064",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1176,
      "Name": "Asprev Soluções",
      "Email": "pereira@aspercontabilidade.com.br",
      "CustomerCode": "37441",
      "Phone": "1141914474",
      "Whatsapp": "1164795910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1177,
      "Name": "Flavio Ferreira dos Santos",
      "Email": "contato@msaec.com.br",
      "CustomerCode": "37442",
      "Phone": "1121375125",
      "Whatsapp": "1181038953",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1178,
      "Name": "Contabilidade Taboão",
      "Email": "giselecontabil@gmail.com",
      "CustomerCode": "37443",
      "Phone": "1141353675",
      "Whatsapp": "1165797618",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1179,
      "Name": "Miguel Angelo Passos",
      "Email": "rodrigorojas@fapsbpo.com.br",
      "CustomerCode": "37444",
      "Phone": "1141522484",
      "Whatsapp": "1189752457",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1180,
      "Name": "Sa Prime Contabilidade",
      "Email": "sacontabil@outlook.com.br",
      "CustomerCode": "37445",
      "Phone": "1126907015",
      "Whatsapp": "11947480052",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1181,
      "Name": "Claudio Bachega Junior",
      "Email": "bachegajr@gmail.com",
      "CustomerCode": "37446",
      "Phone": "1151840111",
      "Whatsapp": "1184821158",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1182,
      "Name": "Vsb Soluções Em Tecnologia Ltda.",
      "Email": "patricia.ponciano@viasoft.com.br",
      "CustomerCode": "37447",
      "Phone": "4621017777",
      "Whatsapp": "4699191991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1183,
      "Name": "Catho Online Ltda.",
      "Email": "juridico@catho.com",
      "CustomerCode": "37448",
      "Phone": "1141343500",
      "Whatsapp": "1167413625",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1184,
      "Name": "Central Giro Assessoria Contábil Eireli ME",
      "Email": "juntadocumentos@gmail.com",
      "CustomerCode": "37449",
      "Phone": "1145543386",
      "Whatsapp": "1183333179",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1185,
      "Name": "Leonardo de Oliveira Batista",
      "Email": "leonardo@lobcontabilidade.com.br",
      "CustomerCode": "37450",
      "Phone": "",
      "Whatsapp": "1173735189",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1187,
      "Name": "Donella Assessoria Contábil",
      "Email": "contabildonella@gmail.com",
      "CustomerCode": "37452",
      "Phone": "1141611525",
      "Whatsapp": "1184081070",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1188,
      "Name": "Joel Nobre",
      "Email": "mizpa1@terra.com.br",
      "CustomerCode": "37453",
      "Phone": "1145580915",
      "Whatsapp": "1184221909",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1189,
      "Name": "Ademar Franco Neto",
      "Email": "ademar.franco@francocontabil.com.br",
      "CustomerCode": "37454",
      "Phone": "1126805514",
      "Whatsapp": "1171717866",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1190,
      "Name": "Osiel Joaquim Ferreira",
      "Email": "oesk39@hotmail.com",
      "CustomerCode": "37455",
      "Phone": "",
      "Whatsapp": "11947427034",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1191,
      "Name": "Chb Asessoria Contábil Eireli ME",
      "Email": "chb.contabilidade@gmail.com",
      "CustomerCode": "37456",
      "Phone": "1146669649",
      "Whatsapp": "11947788944",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1193,
      "Name": "Vpp Servs.Contábeis Ltda-Epp",
      "Email": "smcardoso260568@gmail.com",
      "CustomerCode": "37458",
      "Phone": "1144454054",
      "Whatsapp": "11944425009",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1194,
      "Name": "Thomas Henrique dos Santos Xavier",
      "Email": "txcontabil2020@gmail.com",
      "CustomerCode": "37459",
      "Phone": "1142622357",
      "Whatsapp": "1174528067",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1196,
      "Name": "Camila Nolacio Macedo dos Santos",
      "Email": "camilanolacio@hotmail.com",
      "CustomerCode": "37461",
      "Phone": "1146056355",
      "Whatsapp": "1183165006",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1197,
      "Name": "Bruno de Moura Coelho Caparoz",
      "Email": "caparozcontabilidade@gmail.com",
      "CustomerCode": "37462",
      "Phone": "",
      "Whatsapp": "1182900832",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1198,
      "Name": "Organização Contábil Carleto",
      "Email": "rocarletto@hotmail.com",
      "CustomerCode": "38107",
      "Phone": "1144581062",
      "Whatsapp": "1176692560",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1199,
      "Name": "Nalva Toledo Assessoria Empresarial Ltda. ME",
      "Email": "barbara@toledoempresarial.com.br",
      "CustomerCode": "38114",
      "Phone": "1148248250",
      "Whatsapp": "1197368158",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1200,
      "Name": "Bandeira Contabilidade Ltda-ME",
      "Email": "eunice@bandeiracontabilidade.com.br",
      "CustomerCode": "38124",
      "Phone": "1142299030",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1201,
      "Name": "Marca Contábil",
      "Email": "marca.contabil@terra.com.br",
      "CustomerCode": "38135",
      "Phone": "1142245988",
      "Whatsapp": "1185919137",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1202,
      "Name": "Universal Ass.Contábil e Fiscal S/C Ltda.",
      "Email": "sergio@universalcontabil.com",
      "CustomerCode": "38136",
      "Phone": "1143629780",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1203,
      "Name": "Morais Escritório Contábil",
      "Email": "marciamorais012017@gmail.com",
      "CustomerCode": "38137",
      "Phone": "1143680306",
      "Whatsapp": "1191249375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1204,
      "Name": "Filipe Franco Ferreira",
      "Email": "contato@ffassessoriacontabil.com.br",
      "CustomerCode": "38142",
      "Phone": "1143574790",
      "Whatsapp": "1173572790",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1206,
      "Name": "Tekhwe Contábil Ltda.",
      "Email": "filomena@tekhnecontabil.com.br",
      "CustomerCode": "38146",
      "Phone": "1141217888",
      "Whatsapp": "1185790599",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1207,
      "Name": "Consisten Contabilidade",
      "Email": "contato@consistemcontabil.com.br",
      "CustomerCode": "38150",
      "Phone": "1141783923",
      "Whatsapp": "1183762333",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1208,
      "Name": "Contábil Sol & Mar S/C Ltda.",
      "Email": "sol-mar@uol.com.br",
      "CustomerCode": "38153",
      "Phone": "1143682655",
      "Whatsapp": "11998858735",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1209,
      "Name": "Escritório Contábil Prisma",
      "Email": "assessoriaprisma@bol.com",
      "CustomerCode": "38166",
      "Phone": "1143353408",
      "Whatsapp": "1167353613",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1210,
      "Name": "Tarciso Humberto Gerbelli",
      "Email": "gaiusoctavianus@ecgerbelli.com",
      "CustomerCode": "38169",
      "Phone": "1143526176",
      "Whatsapp": "1173365651",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1211,
      "Name": "Cosmolight Textos e Planilhas Ltda. ME",
      "Email": "contabilgdn@gmail.com",
      "CustomerCode": "38177",
      "Phone": "1143615075",
      "Whatsapp": "1173302505",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1212,
      "Name": "Valter Kazuo Kita",
      "Email": "kitakmb@gmail.com",
      "CustomerCode": "38178",
      "Phone": "1143616187",
      "Whatsapp": "11996279840",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1213,
      "Name": "Rm Organização Contábil Ltda.",
      "Email": "contabil@rmassessoria.com.br",
      "CustomerCode": "38188",
      "Phone": "1140444066",
      "Whatsapp": "1196117224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1214,
      "Name": "Organização Contábil Tashiro S/C Ltda.",
      "Email": "george@tashiro.com.br",
      "CustomerCode": "38190",
      "Phone": "1140573212",
      "Whatsapp": "11981862785",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1215,
      "Name": "Fuji Contábil",
      "Email": "fujicontabil@gmail.com",
      "CustomerCode": "38192",
      "Phone": "1140551393",
      "Whatsapp": "1199960404",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1216,
      "Name": "Abaco Contabilidade S/C Ltda.",
      "Email": "luiz@abacocontabilidade.com.br",
      "CustomerCode": "38194",
      "Phone": "1143308833",
      "Whatsapp": "1199782237",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1217,
      "Name": "Satélite Contábil",
      "Email": "contabilidadesatelite@yahoo.com.br",
      "CustomerCode": "38198",
      "Phone": "1140753054",
      "Whatsapp": "1180259141",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1218,
      "Name": "L.C. Pellizzon Contabilidade",
      "Email": "lcpellizzon@gmail.com",
      "CustomerCode": "38200",
      "Phone": "1140661798",
      "Whatsapp": "11973586701",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1219,
      "Name": "On Line Assessoria e Consultoria Contábil S",
      "Email": "rh02@onlineaccontabil.com.br",
      "CustomerCode": "38201",
      "Phone": "1140664385",
      "Whatsapp": "11947428653",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1220,
      "Name": "Sector Soluções Empresariais e Contábeis",
      "Email": "sectoradm@uol.com.br",
      "CustomerCode": "38205",
      "Phone": "1144796067",
      "Whatsapp": "1199213835",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1221,
      "Name": "Gil Garcez Serviços Contábeis",
      "Email": "gilgarcez@uol.com.br",
      "CustomerCode": "38207",
      "Phone": "1143555411",
      "Whatsapp": "11955521539",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1222,
      "Name": "Escritório Contábil Águia",
      "Email": "juridico@aguiacont.com.br",
      "CustomerCode": "38226",
      "Phone": "1140533900",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1223,
      "Name": "Contábil Sol Nascente",
      "Email": "contabilsolnascente@gmail.com",
      "CustomerCode": "38237",
      "Phone": "1141785553",
      "Whatsapp": "1176288918",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1224,
      "Name": "Coruja Assessoria Empresarial Ltda.",
      "Email": "corujaconti6@terra.com.br",
      "CustomerCode": "38238",
      "Phone": "1141225633",
      "Whatsapp": "11998029700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1225,
      "Name": "Contábil Soler",
      "Email": "soler@contabilsoler.com.br",
      "CustomerCode": "38254",
      "Phone": "",
      "Whatsapp": "1195766783",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1226,
      "Name": "Vicentainer Assessoria Empresarial S/C Ltda.",
      "Email": "vicentainer@uol.com.br",
      "CustomerCode": "38265",
      "Phone": "1141256893",
      "Whatsapp": "11999770246",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1227,
      "Name": "Contsmart Contabilidade e Corretora de Seguros",
      "Email": "luiz@contsmart.com.br",
      "CustomerCode": "38268",
      "Phone": "1149758120",
      "Whatsapp": "1184571672",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1228,
      "Name": "Claudio Ricardo Moreno",
      "Email": "crmmoreno@uol.com.br",
      "CustomerCode": "38270",
      "Phone": "",
      "Whatsapp": "1173999014",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1229,
      "Name": "José Alexandre Di Pacce",
      "Email": "contabildipacce@terra.com.br",
      "CustomerCode": "38277",
      "Phone": "1144386154",
      "Whatsapp": "1181111190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1230,
      "Name": "Eltoni Soares de Lima Junior",
      "Email": "eltoni@aoita.com.br",
      "CustomerCode": "38286",
      "Phone": "1141091875",
      "Whatsapp": "1168507382",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1231,
      "Name": "Alliancce Serviços Contábeis Ltda.",
      "Email": "rosangela@alliancce.com.br",
      "CustomerCode": "38289",
      "Phone": "1141247900",
      "Whatsapp": "1176092092",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1232,
      "Name": "Contábil Diretiva SC Ltda.",
      "Email": "financeiro@contabildiretiva.com.br",
      "CustomerCode": "38290",
      "Phone": "1143512666",
      "Whatsapp": "1194763878",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1233,
      "Name": "Pk Assessoria Contábil e Jurídica",
      "Email": "carmen@pkcontabil.cnt.br",
      "CustomerCode": "38295",
      "Phone": "1144752194",
      "Whatsapp": "1194411333",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1234,
      "Name": "Fernanda Kato de Lana",
      "Email": "elton@manifestoconsultoria.com.br",
      "CustomerCode": "38299",
      "Phone": "1125349853",
      "Whatsapp": "1177535610",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1235,
      "Name": "Rbs Consultoria Contábil Ltda.",
      "Email": "raimundobispo2009@hotmail.com",
      "CustomerCode": "38302",
      "Phone": "1141218886",
      "Whatsapp": "1175142597",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1236,
      "Name": "Ezza Orientador Tributário Ltda.",
      "Email": "gabriela@ezza.com.br",
      "CustomerCode": "38313",
      "Phone": "1142216676",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1237,
      "Name": "Norberto Roseiro",
      "Email": "lancacon2@gmail.com",
      "CustomerCode": "38315",
      "Phone": "1140727205",
      "Whatsapp": "1183034311",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1238,
      "Name": "Amorim Consultoria Contábil",
      "Email": "amorim@amorimcontabil.cnt.br",
      "CustomerCode": "38322",
      "Phone": "1129884200",
      "Whatsapp": "1178570704",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1239,
      "Name": "Controles e Custos Assessoria",
      "Email": "rtadvocacia@hotmail.com",
      "CustomerCode": "38338",
      "Phone": "1141256875",
      "Whatsapp": "1191925374",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1240,
      "Name": "Ricardo Aparecido Carlos",
      "Email": "mac.contabil@terra.com.br",
      "CustomerCode": "38350",
      "Phone": "1143653378",
      "Whatsapp": "1199004942",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1241,
      "Name": "Delta Assessoria Contábil",
      "Email": "rdndelta@gmail.com",
      "CustomerCode": "38351",
      "Phone": "1142381037",
      "Whatsapp": "1191204496",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1242,
      "Name": "Robson Goiano Muniz",
      "Email": "muniz33@gmail.com",
      "CustomerCode": "38359",
      "Phone": "1332191993",
      "Whatsapp": "11952318057",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1243,
      "Name": "Vanci Dantas",
      "Email": "vancidantas@cialdantas.com.br",
      "CustomerCode": "38363",
      "Phone": "1143623666",
      "Whatsapp": "1199592535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1244,
      "Name": "Taves Bagon Processamentos de Dados Ltda. ME",
      "Email": "brenda.soldo@tavesbagon.com.br",
      "CustomerCode": "38364",
      "Phone": "1141771312",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1245,
      "Name": "Bizarro, Godoi e Ballotin",
      "Email": "Sidnei@bizarro.adv.br",
      "CustomerCode": "38366",
      "Phone": "1143374200",
      "Whatsapp": "11996336783",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1246,
      "Name": "Contábil Santos & Freire",
      "Email": "gilberto@contabilsantos.net",
      "CustomerCode": "38368",
      "Phone": "1143385999",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1247,
      "Name": "Masli Assessoria Empresarial SS Ltda.",
      "Email": "livia.mello@masli.com.br",
      "CustomerCode": "38379",
      "Phone": "1142273145",
      "Whatsapp": "1184667746",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1248,
      "Name": "Crcc Construções e Comércio Ltda.",
      "Email": "asvcontabil@yahoo.com.br",
      "CustomerCode": "38382",
      "Phone": "1138737993",
      "Whatsapp": "1169065906",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1249,
      "Name": "Shalon Contabilidade Limitada",
      "Email": "shaloncontabilidade@gmail.com",
      "CustomerCode": "38397",
      "Phone": "1143415113",
      "Whatsapp": "1175383657",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1250,
      "Name": "Nilço Aparecido da Silva",
      "Email": "nilcont@hotmail.com",
      "CustomerCode": "38399",
      "Phone": "1123563960",
      "Whatsapp": "1178554037",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1251,
      "Name": "Ki & Nilo Serviços Especializados Ltda-ME",
      "Email": "gambetti@gambetti.com.br",
      "CustomerCode": "38404",
      "Phone": "1128658803",
      "Whatsapp": "1199849557",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1252,
      "Name": "Prisma Contábil",
      "Email": "prisma@prismacontabil.com.br",
      "CustomerCode": "38413",
      "Phone": "1143477496",
      "Whatsapp": "1185446779",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1253,
      "Name": "Manoel Costa de Morais",
      "Email": "leonam_manoel@hotmail.com",
      "CustomerCode": "38423",
      "Phone": "1141294529",
      "Whatsapp": "1196383009",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1254,
      "Name": "Wilson Roberto Silva Junior",
      "Email": "wrsilvajunior@hotmail.com",
      "CustomerCode": "38449",
      "Phone": "1138888776",
      "Whatsapp": "11938035633",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1255,
      "Name": "Manassés Galdino Alves",
      "Email": "atendimento@monteiroscontabil.com.br.",
      "CustomerCode": "38455",
      "Phone": "1143414084",
      "Whatsapp": "1186330034",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1256,
      "Name": "Nelcino Magdaleno",
      "Email": "contabilidade@tucuruviturismo.com.br",
      "CustomerCode": "38458",
      "Phone": "1132961800",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1257,
      "Name": "Proconta Assessoria e Consultoria Contábil Ltda.",
      "Email": "contabil@proconta.com",
      "CustomerCode": "38459",
      "Phone": "1149949933",
      "Whatsapp": "1199968644",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1258,
      "Name": "Fabio de Oliveira Camargo",
      "Email": "fabio.camargo@goalconsultoria.com.br",
      "CustomerCode": "38464",
      "Phone": "1123111767",
      "Whatsapp": "1183755877",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1259,
      "Name": "André Vinicius Bittencourt dos Santos - ME",
      "Email": "andre@realizarcontabil.com.br",
      "CustomerCode": "38466",
      "Phone": "1134383307",
      "Whatsapp": "1193475806",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1260,
      "Name": "Sérgio Luís Ventanilha",
      "Email": "sventanilha@uol.com.br",
      "CustomerCode": "38469",
      "Phone": "1138431604",
      "Whatsapp": "1187158013",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1261,
      "Name": "N M Contábil Ltda.",
      "Email": "nm.contabil@terra.com.br",
      "CustomerCode": "38473",
      "Phone": "1135312748",
      "Whatsapp": "1181162541",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1262,
      "Name": "Rejor Assessoria Contábil",
      "Email": "rejor@terra.com.br",
      "CustomerCode": "38477",
      "Phone": "1143435482",
      "Whatsapp": "1181248378",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1264,
      "Name": "Eduardo Niezer",
      "Email": "eduardo.niezer@gmail.com",
      "CustomerCode": "38487",
      "Phone": "1124415561",
      "Whatsapp": "1176401091",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1265,
      "Name": "Apros Assessoria Contábil",
      "Email": "aproscontabil@hotmail.com",
      "CustomerCode": "38494",
      "Phone": "",
      "Whatsapp": "11983269642",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1266,
      "Name": "Mauricio Marques Leitão",
      "Email": "mauricio.ml@terra.com.br",
      "CustomerCode": "38500",
      "Phone": "1128853870",
      "Whatsapp": "1170782002",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1267,
      "Name": "Tiago Rodrigues de Oliveira Eireli",
      "Email": "contato@oliveiracontadores.com.br",
      "CustomerCode": "38501",
      "Phone": "1149300187",
      "Whatsapp": "1172974253",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1268,
      "Name": "Ademar Pereira Santos - Aps",
      "Email": "aps@algarveaps.com.br",
      "CustomerCode": "38519",
      "Phone": "1199993227",
      "Whatsapp": "1199990533",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1269,
      "Name": "José Roberto Moreira e Silva",
      "Email": "jroberto25@hotmail.com.br",
      "CustomerCode": "38520",
      "Phone": "1144729108",
      "Whatsapp": "1174306560",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1270,
      "Name": "Tiago Fernandes Bragante",
      "Email": "tiagobragante@yahoo.com.br",
      "CustomerCode": "38525",
      "Phone": "",
      "Whatsapp": "11980781038",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1271,
      "Name": "Person Consultoria",
      "Email": "legal@personconsultoria.com.br",
      "CustomerCode": "38530",
      "Phone": "1131033450",
      "Whatsapp": "1197091836",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1272,
      "Name": "Vertical Consultoria",
      "Email": "josuewilliam@verticalconsultoria.com.br",
      "CustomerCode": "38531",
      "Phone": "1135932269",
      "Whatsapp": "11940700605",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1273,
      "Name": "Fabio Luiz Marques Baião Junior",
      "Email": "fabio_lmbj@hotmail.com",
      "CustomerCode": "38533",
      "Phone": "",
      "Whatsapp": "1788104661",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1274,
      "Name": "Alsi Serviços Administrativos Ltda.",
      "Email": "almir.bittencourt9@gmail.com",
      "CustomerCode": "38541",
      "Phone": "",
      "Whatsapp": "1193484535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1275,
      "Name": "Mario Wilson Aparecido de Oliveira",
      "Email": "mario@wilsonoliveiraadvogados.adv.br",
      "CustomerCode": "38543",
      "Phone": "1123811787",
      "Whatsapp": "1181486199",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1276,
      "Name": "Sergio Eugenio Do Nascimento",
      "Email": "sergio.nascimento@sanco.com.br",
      "CustomerCode": "38544",
      "Phone": "1144335595",
      "Whatsapp": "1180198323",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1277,
      "Name": "Líder Indústria e Comércio de Brinquedos Ltda.",
      "Email": "luzinete.controladoria@liderbrinquedos.com.br",
      "CustomerCode": "38545",
      "Phone": "1145435000",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1278,
      "Name": "Paulicon Contábil Eirelli",
      "Email": "kleber.legalizacao@paulicon.com.br",
      "CustomerCode": "38546",
      "Phone": "1141735366",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1279,
      "Name": "Monica Cilene Anastácio",
      "Email": "monica-cilene@uol.com.br",
      "CustomerCode": "38547",
      "Phone": "1131514536",
      "Whatsapp": "11944614545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1280,
      "Name": "Zf Consultoria e Assessoria Contábil Ltda.",
      "Email": "beatriz.fernandes@zfconsultoria.com.br",
      "CustomerCode": "38548",
      "Phone": "1127723332",
      "Whatsapp": "1164439815",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1281,
      "Name": "André Avelino Coelho Advogados Associados",
      "Email": "advogados@damasiocoelho.adv.br",
      "CustomerCode": "38549",
      "Phone": "1141251032",
      "Whatsapp": "1196860803",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1282,
      "Name": "Valdomiro Montes Junior",
      "Email": "radbonis@hotmail.com",
      "CustomerCode": "38550",
      "Phone": "1149946767",
      "Whatsapp": "1198239826",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1283,
      "Name": "Xplorer Assessoria Contábil Eireli",
      "Email": "nilza.souza@xplorercontabil.com.br",
      "CustomerCode": "38552",
      "Phone": "1120870888",
      "Whatsapp": "1191439544",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1284,
      "Name": "Claudia Aparecida Aguiar",
      "Email": "clauaguiiar@hotmail.com",
      "CustomerCode": "38553",
      "Phone": "1128975852",
      "Whatsapp": "1192153650",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1285,
      "Name": "Conta Junto Assessoria Contábil Ltda.",
      "Email": "legal@contajunto.com",
      "CustomerCode": "38554",
      "Phone": "1930434490",
      "Whatsapp": "1988080025",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1286,
      "Name": "Sds Contabilidade Empresarial SS",
      "Email": "sergio.donizete@sdscontabilidade.com.br",
      "CustomerCode": "38556",
      "Phone": "1144275277",
      "Whatsapp": "1190063131",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1287,
      "Name": "Md Contabilidade",
      "Email": "mdcontabilidade@uol.com.br",
      "CustomerCode": "38557",
      "Phone": "1141771686",
      "Whatsapp": "1197560221",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1288,
      "Name": "Debora Lopes Cardoso",
      "Email": "debora@bsladvocacia.com.br",
      "CustomerCode": "38558",
      "Phone": "1143900232",
      "Whatsapp": "1193905241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1289,
      "Name": "Polimold Indústrial S/A",
      "Email": "julianasilva@polimold.com.br",
      "CustomerCode": "38559",
      "Phone": "1143587343",
      "Whatsapp": "1182777296",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1290,
      "Name": "Alvaz Assessoria Contábil Ltda.",
      "Email": "fabio.alvaz@parceirocontador.com.br",
      "CustomerCode": "38560",
      "Phone": "1128978993",
      "Whatsapp": "11941219454",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1291,
      "Name": "Diamante Contabilidade",
      "Email": "legal@diamantecontabilidade.com.br",
      "CustomerCode": "38561",
      "Phone": "1143081037",
      "Whatsapp": "11944362020",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1292,
      "Name": "Glaucia de Servi Peres dos Santos",
      "Email": "contabilprismarh@gmail.com",
      "CustomerCode": "38562",
      "Phone": "1143353408",
      "Whatsapp": "1167260465",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1293,
      "Name": "Gilberto Siqueira da Silva",
      "Email": "gilberto.siqueira@adv.oabsp.org.br",
      "CustomerCode": "38563",
      "Phone": "1140448990",
      "Whatsapp": "1198539933",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1294,
      "Name": "Lkp Produtos Para Diagnósticos",
      "Email": "lkp@lkpdiagnosticos.com.br",
      "CustomerCode": "38564",
      "Phone": "1147029212",
      "Whatsapp": "11963722323",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1295,
      "Name": "Valdinei dos Santos Nascimento",
      "Email": "valdinei@contabilidadep.com.br",
      "CustomerCode": "38565",
      "Phone": "",
      "Whatsapp": "1175079977",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1296,
      "Name": "Júlio Cesar Paes Landim Fernandes",
      "Email": "contato@resiliencecont.com.br",
      "CustomerCode": "38566",
      "Phone": "",
      "Whatsapp": "1167092828",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1297,
      "Name": "Conectacon Contabilidade e Gestão Ltda.",
      "Email": "nb@conectacon.com.br",
      "CustomerCode": "38567",
      "Phone": "1135654628",
      "Whatsapp": "1181068890",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1298,
      "Name": "Daniel Renato Camargo Nunez",
      "Email": "dgabiru@hotmail.com",
      "CustomerCode": "38568",
      "Phone": "1195896825",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1299,
      "Name": "Luciano Araújo Costa",
      "Email": "lacosta.contabil@gmail.com",
      "CustomerCode": "38569",
      "Phone": "1126197030",
      "Whatsapp": "1190157254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1300,
      "Name": "Digitus Serviços Administrativos Ltda.",
      "Email": "digitusc@terra.com.br",
      "CustomerCode": "38571",
      "Phone": "1141867789",
      "Whatsapp": "1172829093",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1301,
      "Name": "Luiz Carlos Figueira",
      "Email": "figueira.luiz@uol.com.br",
      "CustomerCode": "38572",
      "Phone": "1142381154",
      "Whatsapp": "1189984459",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1302,
      "Name": "Conta Certa Escritório Contábil",
      "Email": "vltrondoli@hotmail.com",
      "CustomerCode": "38573",
      "Phone": "1133806078",
      "Whatsapp": "1191387871",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1303,
      "Name": "Lenon Ferraz de Souza",
      "Email": "lenon.contador@gmail.com",
      "CustomerCode": "38574",
      "Phone": "1142332372",
      "Whatsapp": "11962148152",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1304,
      "Name": "Possit Contábil Ltda.",
      "Email": "adm@possit.com.br",
      "CustomerCode": "38575",
      "Phone": "1123240527",
      "Whatsapp": "1195655508",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1305,
      "Name": "Kleidiana Pereira Silva",
      "Email": "pereirakleidiana@gmail.com",
      "CustomerCode": "38576",
      "Phone": "",
      "Whatsapp": "1185328932",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1306,
      "Name": "Assescon Assessoria Contábil Ltda.",
      "Email": "cynthia@assescontabil.com.br",
      "CustomerCode": "38577",
      "Phone": "1149906155",
      "Whatsapp": "11978393001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1307,
      "Name": "Eloisa Matias Santos",
      "Email": "jaspecontabilidade@bol.com.br",
      "CustomerCode": "38578",
      "Phone": "11",
      "Whatsapp": "1192194806",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1308,
      "Name": "QualityCont Assessoria Contábil Ltda",
      "Email": "ricardo.rodrigues@qualitycont.com.br",
      "CustomerCode": "38579",
      "Phone": "1141733031",
      "Whatsapp": "11972038259",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1309,
      "Name": "Luciana Aparecida da Silva",
      "Email": "servicosmeinegocio@gmail.com",
      "CustomerCode": "38580",
      "Phone": "1140522701",
      "Whatsapp": "11962775704",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1310,
      "Name": "Fpa Gestão Contábil Ltda.",
      "Email": "fpa@fpagescon.com",
      "CustomerCode": "38581",
      "Phone": "1149940555",
      "Whatsapp": "1169942019",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1311,
      "Name": "Iccb Instituto de Concessão de Credito",
      "Email": "adm@salvatto.com.br",
      "CustomerCode": "38582",
      "Phone": "1134267683",
      "Whatsapp": "11947373906",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1314,
      "Name": "Willbecont Partners Assessoria Contábil Ltda.",
      "Email": "roberto@willbecont.com.br",
      "CustomerCode": "38585",
      "Phone": "1159901298",
      "Whatsapp": "1172189560",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1315,
      "Name": "Fabio Wingles Espindola",
      "Email": "fwecontabilidade@gmail.com",
      "CustomerCode": "38586",
      "Phone": "",
      "Whatsapp": "1173108307",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1316,
      "Name": "Organização Contábil Triângulo Ltda.",
      "Email": "beatriz@triangulocontabil.com.br",
      "CustomerCode": "38587",
      "Phone": "1120874080",
      "Whatsapp": "11999949824",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1317,
      "Name": "Contabilidade São Paulo S/C Ltda.",
      "Email": "cadastro@contabilidadesaopaulo.com.br",
      "CustomerCode": "40101",
      "Phone": "1146527799",
      "Whatsapp": "1172037250",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1318,
      "Name": "As Assessoria de Serviços Contábeis",
      "Email": "paulo@asctbinf.com",
      "CustomerCode": "40103",
      "Phone": "1146543558",
      "Whatsapp": "1199103363",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1319,
      "Name": "Jra Assistência Contábil & Fiscal",
      "Email": "casport@hotmail.com",
      "CustomerCode": "40110",
      "Phone": "1144492510",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1320,
      "Name": "Escritório de Contabilidade Aguiar Ltda.",
      "Email": "cont_aguiar@uol.com.br",
      "CustomerCode": "40113",
      "Phone": "1144882336",
      "Whatsapp": "1197900427",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1321,
      "Name": "Knippel Contabilidade Ltda.",
      "Email": "katty@knippel.com.br",
      "CustomerCode": "40125",
      "Phone": "1147990111",
      "Whatsapp": "11999066644",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1322,
      "Name": "Mrb Contabilidade",
      "Email": "mrbcontabilidade@terra.com.br",
      "CustomerCode": "40129",
      "Phone": "1146053671",
      "Whatsapp": "1199982919",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1323,
      "Name": "Contábil J Barbieri",
      "Email": "j_barbieri@uol.com.br",
      "CustomerCode": "40144",
      "Phone": "1144484198",
      "Whatsapp": "11997683354",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1324,
      "Name": "Escritório Contábil Modelo",
      "Email": "gilsonteixeira284@hotmail.com",
      "CustomerCode": "40153",
      "Phone": "1144432323",
      "Whatsapp": "1196559484",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1325,
      "Name": "Michele Alessandra de Castro Marcondes Esparrinha",
      "Email": "recepcao-torreforte@outlook.com",
      "CustomerCode": "40156",
      "Phone": "1144086582",
      "Whatsapp": "1175732520",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1326,
      "Name": "Adilson Ribeiro Marques",
      "Email": "adilsonribeiromarques@gmail.com",
      "CustomerCode": "40168",
      "Phone": "1143261268",
      "Whatsapp": "11998732418",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1327,
      "Name": "De Melo Rossoni Serviços Administrativos Ltda.",
      "Email": "rossonineto@terra.com.br",
      "CustomerCode": "40170",
      "Phone": "1146361399",
      "Whatsapp": "1199494170",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1328,
      "Name": "Mazza Contabilidade",
      "Email": "contato@contabilmazza.com.br",
      "CustomerCode": "40173",
      "Phone": "",
      "Whatsapp": "1198695643",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1329,
      "Name": "Sox Contábil Assessoria e Contabilidade Ltda.",
      "Email": "liberio@soxconsult.com.br",
      "CustomerCode": "40177",
      "Phone": "1121789300",
      "Whatsapp": "11997559858",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1330,
      "Name": "Edison Camalionte",
      "Email": "edsonfnc@yahoo.com.br",
      "CustomerCode": "40178",
      "Phone": "1147015004",
      "Whatsapp": "1195797387",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1331,
      "Name": "João Antônio Cebrian",
      "Email": "jacebrian@uol.com.br",
      "CustomerCode": "40180",
      "Phone": "1146382301",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1332,
      "Name": "Elgin S/A",
      "Email": "rogerio.machado@elgin.com.br",
      "CustomerCode": "40181",
      "Phone": "1134112061",
      "Whatsapp": "11986734746",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1333,
      "Name": "Dominique Célia Ferreira Lima Sebastião de Godoy",
      "Email": "apoia.servadm@gmail.com",
      "CustomerCode": "40182",
      "Phone": "1128918183",
      "Whatsapp": "1183130050",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1334,
      "Name": "Rld Assessoria Contábil Ltda.",
      "Email": "estela@filadelfiacontabil.com.br",
      "CustomerCode": "40183",
      "Phone": "1147262427",
      "Whatsapp": "1196438348",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1335,
      "Name": "L Neto da Silva Contabilidade ME",
      "Email": "viellocontabilidade@gmail.com",
      "CustomerCode": "40184",
      "Phone": "1146367265",
      "Whatsapp": "1182123196",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1336,
      "Name": "Renan Pereira Aragão",
      "Email": "renan_613@hotmail.com",
      "CustomerCode": "40185",
      "Phone": "1146427136",
      "Whatsapp": "11961167796",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1337,
      "Name": "Jas Consulting - Assessoria e Consultoria Ltda.",
      "Email": "joao.abrao@jasconsulting.com.br",
      "CustomerCode": "40186",
      "Phone": "1148222653",
      "Whatsapp": "1196492808",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1338,
      "Name": "Marco Antônio da Silva Cordeiro",
      "Email": "mccontabil2016@gmail.com",
      "CustomerCode": "40187",
      "Phone": "1124969773",
      "Whatsapp": "1176140619",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1339,
      "Name": "Katia Darcolete",
      "Email": "katiadarcolete@gmail.com",
      "CustomerCode": "40188",
      "Phone": "1145114775",
      "Whatsapp": "1195851940",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1340,
      "Name": "J C C de Oliveira Contabilidade",
      "Email": "josecarlos@eajc.com.br",
      "CustomerCode": "40189",
      "Phone": "1144435614",
      "Whatsapp": "1171092772",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1341,
      "Name": "Escritório Futura",
      "Email": "rosilene@escritoriofutura.com.br",
      "CustomerCode": "61001",
      "Phone": "1434916161",
      "Whatsapp": "1499721843",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1342,
      "Name": "Débora Dolores de França Barbosa",
      "Email": "eccbarbosa@uol.com.br",
      "CustomerCode": "61002",
      "Phone": "1231324066",
      "Whatsapp": "1197127808",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1343,
      "Name": "Contabilidade Maresias",
      "Email": "marciamaresias@yahoo.com.br",
      "CustomerCode": "61005",
      "Phone": "1238656543",
      "Whatsapp": "12982666161",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1344,
      "Name": "Escritório Contábil Oriental",
      "Email": "escritorio.oriental@terra.com.br",
      "CustomerCode": "61007",
      "Phone": "1734232452",
      "Whatsapp": "1781281575",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1345,
      "Name": "Avant Center Contábil Ltda.",
      "Email": "info@avantcentercontabil.com.br",
      "CustomerCode": "61020",
      "Phone": "1238322580",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1346,
      "Name": "Mauricio Catani de Moraes- Salto - ME",
      "Email": "catanimoraes@uol.com.br",
      "CustomerCode": "61026",
      "Phone": "1140281869",
      "Whatsapp": "1173349133",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1347,
      "Name": "Romão Contábil",
      "Email": "jpromao@uol.com.br",
      "CustomerCode": "61032",
      "Phone": "1338641086",
      "Whatsapp": "1397156043",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1348,
      "Name": "Agnaldo Aparecido Soria",
      "Email": "agnaldosoria@hotmail.com",
      "CustomerCode": "61036",
      "Phone": "1733437854",
      "Whatsapp": "1792408920",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1349,
      "Name": "Irani Cristina de Oliveira Martins Esteves",
      "Email": "esteves@iron.com.br",
      "CustomerCode": "61039",
      "Phone": "1332192081",
      "Whatsapp": "1391767089",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1350,
      "Name": "Exactus Contabilidade",
      "Email": "pacagnan@netsite.com.br",
      "CustomerCode": "61049",
      "Phone": "1638263398",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1351,
      "Name": "Prado & Manara Assessoria Contábil Ltda.- ME",
      "Email": "cadastro@pradomanara.com.br",
      "CustomerCode": "61053",
      "Phone": "1140160220",
      "Whatsapp": "1181535744",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1352,
      "Name": "Fernando Antônio dos Santos",
      "Email": "contabilautenticasarapui@gmail.com",
      "CustomerCode": "61076",
      "Phone": "1532761241",
      "Whatsapp": "1597048545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1353,
      "Name": "Diogenes Fernando Santo Ferreira",
      "Email": "administracao1@realorgcontabil.com.br",
      "CustomerCode": "61092",
      "Phone": "1144021556",
      "Whatsapp": "1171030383",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1354,
      "Name": "Jorge Nakano",
      "Email": "jonakano@uol.com.br",
      "CustomerCode": "61110",
      "Phone": "1238821225",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1355,
      "Name": "Gláucio José de Almeida Gomes",
      "Email": "peruibe.conta@gmail.com",
      "CustomerCode": "61142",
      "Phone": "1334551947",
      "Whatsapp": "1381205874",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1356,
      "Name": "Simom Contabilidade",
      "Email": "simomcontabil@hotmail.com",
      "CustomerCode": "61150",
      "Phone": "1338441040",
      "Whatsapp": "1381327688",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1357,
      "Name": "Organização Contábil Chicaoka S/C Ltda.",
      "Email": "legal@chicaoka.com.br",
      "CustomerCode": "61154",
      "Phone": "1338212233",
      "Whatsapp": "1397839991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1358,
      "Name": "Escritório Assistec",
      "Email": "escritorioassistec@uol.com.br",
      "CustomerCode": "61178",
      "Phone": "1236625294",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1359,
      "Name": "Corsi Contabilidade e Assessoria Ltda. - EPP",
      "Email": "vinicio@corsi.com.br",
      "CustomerCode": "61211",
      "Phone": "1937585888",
      "Whatsapp": "1997902860",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1360,
      "Name": "Unicont Unidos P/ Contabilidade SC Ltda.",
      "Email": "benedito.unicont@terra.com.br",
      "CustomerCode": "61225",
      "Phone": "1140221627",
      "Whatsapp": "1172141767",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1361,
      "Name": "Ágape Contabilidade S/C Ltda.",
      "Email": "christo@terra.com.br",
      "CustomerCode": "61226",
      "Phone": "1140130420",
      "Whatsapp": "1198022266",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1362,
      "Name": "Luiz Carlos Bacchini",
      "Email": "fabio@bacchinicontabilidade.com.br",
      "CustomerCode": "61290",
      "Phone": "1633753305",
      "Whatsapp": "1697611525",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1363,
      "Name": "Dbm Assessoria Contábil",
      "Email": "atendimento@dbmcontabil.com.br",
      "CustomerCode": "61339",
      "Phone": "1935824812",
      "Whatsapp": "1997288189",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1364,
      "Name": "Compress-Cont. Prest Serviços",
      "Email": "compresscontabilidade@yahoo.com.br",
      "CustomerCode": "61353",
      "Phone": "1936731730",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1365,
      "Name": "Escritório Contábil Vischi S/C Ltda.",
      "Email": "ecvischi@uol.com.br",
      "CustomerCode": "61432",
      "Phone": "1936511621",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1366,
      "Name": "Jr Asses Contábil e Despachante",
      "Email": "jorge@jrescritorio.com.br",
      "CustomerCode": "61479",
      "Phone": "1733921711",
      "Whatsapp": "1797738666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1367,
      "Name": "Escritório Comercial Ibiúna - Nelson Rojo",
      "Email": "rojoadm@uol.com.br",
      "CustomerCode": "61486",
      "Phone": "1532411732",
      "Whatsapp": "1596705466",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1368,
      "Name": "Escritório Real Duas Pontes",
      "Email": "antonioeliascarta@hotmail.com",
      "CustomerCode": "61522",
      "Phone": "1734811237",
      "Whatsapp": "1797523377",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1369,
      "Name": "Sílvio Vieira do Prado",
      "Email": "meadowsprado@gmail.com",
      "CustomerCode": "61526",
      "Phone": "1732227238",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1370,
      "Name": "Assessoria Fisco Contábil J W S/C Ltda.",
      "Email": "diretoria@jwcontabilidade.com.br",
      "CustomerCode": "61610",
      "Phone": "1632421351",
      "Whatsapp": "1697851630",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1371,
      "Name": "João Aparecido Favaro",
      "Email": "escritoriofavaro@gmail.com",
      "CustomerCode": "61692",
      "Phone": "1436622609",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1372,
      "Name": "Edson Ferreira Lopes",
      "Email": "epscontabil@epscontabil.com.br",
      "CustomerCode": "61715",
      "Phone": "1432821975",
      "Whatsapp": "1497024243",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1373,
      "Name": "Luiz Quinhoneiro",
      "Email": "escritorioquinhoneiro@hotmail.com",
      "CustomerCode": "61740",
      "Phone": "1434967954",
      "Whatsapp": "1497610863",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1374,
      "Name": "Moisés de Almeida",
      "Email": "alineangelieri@gmail.com",
      "CustomerCode": "61817",
      "Phone": "1532624022",
      "Whatsapp": "1574041664",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1375,
      "Name": "Escritório Líder de Contabilidade",
      "Email": "contratos@esclider.com.br",
      "CustomerCode": "61874",
      "Phone": "1821041198",
      "Whatsapp": "1821041198",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1376,
      "Name": "Sawil Serviços Administrativos Ltda. - ME",
      "Email": "legal@sawil.com.br",
      "CustomerCode": "61883",
      "Phone": "1821049900",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1377,
      "Name": "Escritório de Contabilidade Itamarati SC Ltda.",
      "Email": "durvale150@hotmail.com",
      "CustomerCode": "61904",
      "Phone": "1832711716",
      "Whatsapp": "1896110098",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1378,
      "Name": "Bermejo´S Contabilidade Empresarial Ltda.",
      "Email": "expediente@bermejos.com.br",
      "CustomerCode": "61923",
      "Phone": "1833222999",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1379,
      "Name": "Ilha Contabilidade",
      "Email": "documentos.ilhacontab@gmail.com",
      "CustomerCode": "61978",
      "Phone": "1338423880",
      "Whatsapp": "1396114833",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1380,
      "Name": "Escritório Contábil Carvalho",
      "Email": "carvalhosasso@uol.com.br",
      "CustomerCode": "61988",
      "Phone": "1936740289",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1381,
      "Name": "Escritório 4 de Abril de Marilia",
      "Email": "escritorio4deabril@terra.com.br",
      "CustomerCode": "62010",
      "Phone": "1434132990",
      "Whatsapp": "1497035914",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1382,
      "Name": "Marivaldo Ribeiro de Carvalho",
      "Email": "cont.marivaldo@gmail.com",
      "CustomerCode": "62028",
      "Phone": "1532781463",
      "Whatsapp": "1597504771",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1383,
      "Name": "Escritório Arco Iris",
      "Email": "chiebao@escritorioarcoiris.com.br",
      "CustomerCode": "62057",
      "Phone": "1832226777",
      "Whatsapp": "1897255248",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1384,
      "Name": "Romani Assessoria Contábil",
      "Email": "recepcao@romanicontabilidade.com.br",
      "CustomerCode": "62088",
      "Phone": "1432632140",
      "Whatsapp": "1491420254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1385,
      "Name": "Organização Contábil Louveira SC Ltda.",
      "Email": "orgcont@uol.com.br",
      "CustomerCode": "62134",
      "Phone": "1939485151",
      "Whatsapp": "1993101380",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1386,
      "Name": "Cpi Contabilidade e Auditoria S/C Ltda.",
      "Email": "webmaster@cpi.com.br",
      "CustomerCode": "62252",
      "Phone": "1145225700",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1387,
      "Name": "Projeto Contábil",
      "Email": "rodrigo@projetocontabil.com.br",
      "CustomerCode": "62331",
      "Phone": "1436229289",
      "Whatsapp": "1497883047",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1388,
      "Name": "José Benedito Gonzaga Cintra Junior",
      "Email": "viacontabil@viacontabil.adm.br",
      "CustomerCode": "62366",
      "Phone": "1938638271",
      "Whatsapp": "1997426534",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1389,
      "Name": "Simões Contabilidade Administração",
      "Email": "simoescontabilidade@uol.com.br",
      "CustomerCode": "62450",
      "Phone": "1238821237",
      "Whatsapp": "1288914507",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1390,
      "Name": "Zeaze Contabilidade Ltda.",
      "Email": "zeaze@zeaze.com.br",
      "CustomerCode": "62477",
      "Phone": "1338561574",
      "Whatsapp": "13991838790",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1391,
      "Name": "Emi Contabilidade",
      "Email": "contato@emicontabilidade.com.br",
      "CustomerCode": "62507",
      "Phone": "1236661337",
      "Whatsapp": "12997361610",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1392,
      "Name": "Juscontábil Assessoria Contábil",
      "Email": "marylaine.juscontabil2023@gmail.com",
      "CustomerCode": "62541",
      "Phone": "1231258114",
      "Whatsapp": "1291594683",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1393,
      "Name": "Júlio Cesar Leandro",
      "Email": "jleandro@terra.com.br",
      "CustomerCode": "62580",
      "Phone": "1633762169",
      "Whatsapp": "1697835738",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1394,
      "Name": "Valdemir Martinez Junior",
      "Email": "vmj.martinez@terra.com.br",
      "CustomerCode": "62673",
      "Phone": "1633685124",
      "Whatsapp": "1697810331",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1395,
      "Name": "Reinaldo Luiz Maganha",
      "Email": "lucripat@terra.com.br",
      "CustomerCode": "62797",
      "Phone": "1935618876",
      "Whatsapp": "1997899918",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1396,
      "Name": "Contábil Marangon S/C Ltda.",
      "Email": "contabilmarangon@realinternet.com.br",
      "CustomerCode": "62808",
      "Phone": "1935813238",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1397,
      "Name": "Leonice Aparecida Pereira de Araújo",
      "Email": "gerencia@leocontabil.com.br",
      "CustomerCode": "62815",
      "Phone": "1935814145",
      "Whatsapp": "1999254488",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1398,
      "Name": "Visar Contabilidade Ltda.",
      "Email": "comercial@visarcontabilidade.com.br",
      "CustomerCode": "62966",
      "Phone": "1938138200",
      "Whatsapp": "1981590857",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1399,
      "Name": "Conttrolare Soluções Contábeis e Tributárias Ltda.",
      "Email": "valeria@conttrolare.com.br",
      "CustomerCode": "62977",
      "Phone": "1938138810",
      "Whatsapp": "1981483536",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1400,
      "Name": "Vagner Alexandre de Souza",
      "Email": "contabilpalmares@gmail.com",
      "CustomerCode": "62999",
      "Phone": "1788048309",
      "Whatsapp": "1796344904",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1401,
      "Name": "Contábil Modelo S/C Ltda.",
      "Email": "jonatasmanco@gmail.com",
      "CustomerCode": "63005",
      "Phone": "1636652540",
      "Whatsapp": "1691454828",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1402,
      "Name": "Gilson Belizardo da Conceição",
      "Email": "gilson@acessoassessoria.com.br",
      "CustomerCode": "63067",
      "Phone": "1238839558",
      "Whatsapp": "1292209678",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1403,
      "Name": "José Ailton Polli",
      "Email": "ailton.caragua@uol.com.br",
      "CustomerCode": "63160",
      "Phone": "1837228112",
      "Whatsapp": "1891259914",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1404,
      "Name": "Escritório Gelamo",
      "Email": "priscila@contabilgelamo.com.br",
      "CustomerCode": "63385",
      "Phone": "1435921111",
      "Whatsapp": "1497610498",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1405,
      "Name": "Marcos Antônio Mariano",
      "Email": "marcos.mariano@bevicred.com.br",
      "CustomerCode": "63586",
      "Phone": "1839169100",
      "Whatsapp": "1896719624",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1406,
      "Name": "Modelo Organização Contábil S/C Ltda.",
      "Email": "modelocb@ig.com.br",
      "CustomerCode": "63654",
      "Phone": "1535421480",
      "Whatsapp": "1597288161",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1407,
      "Name": "Somafra Org. Contábil",
      "Email": "somafra_contabil@terra.com.br",
      "CustomerCode": "63662",
      "Phone": "1535324399",
      "Whatsapp": "1581580900",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1408,
      "Name": "A. L. Assessoria Contábil",
      "Email": "alcontabil55@hotmail.com",
      "CustomerCode": "63740",
      "Phone": "1433514743",
      "Whatsapp": "1497375180",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1409,
      "Name": "Ildemar Latansio Contabilidade",
      "Email": "ilcontabilidadea@hotmail.com",
      "CustomerCode": "63790",
      "Phone": "1433518141",
      "Whatsapp": "1498124309",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1410,
      "Name": "Escritório Systema Contábil S/C Ltda.",
      "Email": "guilherme@systema.com.br",
      "CustomerCode": "63894",
      "Phone": "1833611363",
      "Whatsapp": "1881150180",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1411,
      "Name": "Escrit. São Judas Tadeu",
      "Email": "saojudas-rojo@uol.com.br",
      "CustomerCode": "63909",
      "Phone": "1833561272",
      "Whatsapp": "1897756464",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1412,
      "Name": "Escritório Volpiano",
      "Email": "lourdinhavolpiano@yahoo.com.br",
      "CustomerCode": "63988",
      "Phone": "1634125012",
      "Whatsapp": "1693427170",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1413,
      "Name": "Carlos Alberto da Costa Veloso",
      "Email": "caplocadora@zipmail.com.br",
      "CustomerCode": "63998",
      "Phone": "1236624040",
      "Whatsapp": "1297756014",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1414,
      "Name": "Delta Organização Contábil",
      "Email": "deltaconti@deltaconti.com.br",
      "CustomerCode": "64005",
      "Phone": "1734235221",
      "Whatsapp": "1781512227",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1415,
      "Name": "Luiz Gonzaga Martins",
      "Email": "documentos@ecmartins.com.br",
      "CustomerCode": "64007",
      "Phone": "1535324069",
      "Whatsapp": "1581052157",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1416,
      "Name": "Logos Assessoria Contábil",
      "Email": "recursoshumanos@logoscont.com.br",
      "CustomerCode": "64009",
      "Phone": "1632022917",
      "Whatsapp": "1697975206",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1418,
      "Name": "Sertec Contabilidade",
      "Email": "fiscal04@sertecassessoria.com.br",
      "CustomerCode": "64065",
      "Phone": "4333244356",
      "Whatsapp": "4384452602",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1419,
      "Name": "Escritório Capecci de Contabilidade",
      "Email": "jr-bergamo@hotmail.com",
      "CustomerCode": "64075",
      "Phone": "1437621440",
      "Whatsapp": "1497901444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1420,
      "Name": "Kátia Mesquita de Souza",
      "Email": "katiamesquitadesouza@gmail.com",
      "CustomerCode": "64080",
      "Phone": "1334276035",
      "Whatsapp": "1381150096",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1421,
      "Name": "Zenkom Provedor de Soluções Ltda.",
      "Email": "fibromix@fibromix.com.br",
      "CustomerCode": "64087",
      "Phone": "1234314448",
      "Whatsapp": "1281941794",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1422,
      "Name": "Patrícia Cristina Penteado Narduci",
      "Email": "patricianarduci@uol.com.br",
      "CustomerCode": "64111",
      "Phone": "1938671848",
      "Whatsapp": "1974063121",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1423,
      "Name": "Flavio Almeida da Silva",
      "Email": "adm@siaccontabilidade.com.br",
      "CustomerCode": "64113",
      "Phone": "1334555075",
      "Whatsapp": "1397755859",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1424,
      "Name": "Planos Contabilidade S/C Ltda.",
      "Email": "planos@planoscontabilidade.com.br",
      "CustomerCode": "64128",
      "Phone": "1932317388",
      "Whatsapp": "1991626153",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1425,
      "Name": "Newton Contabilidade",
      "Email": "newtonbn@hotmail.com",
      "CustomerCode": "64139",
      "Phone": "3538513848",
      "Whatsapp": "3588421552",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1427,
      "Name": "Jr Contabilidade & Consultoria S/S Ltda. ME",
      "Email": "junior@jrcontador.com.br",
      "CustomerCode": "64184",
      "Phone": "1144971950",
      "Whatsapp": "1194426039",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1428,
      "Name": "Luiz Carlos Valsecchi",
      "Email": "garcontabil@gmail.com.br",
      "CustomerCode": "64210",
      "Phone": "1434062109",
      "Whatsapp": "1497257546",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1429,
      "Name": "Escritório Novo Contábil S/C Ltda.",
      "Email": "novocontabil@gmail.com",
      "CustomerCode": "64213",
      "Phone": "14991236356",
      "Whatsapp": "1491712214",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1430,
      "Name": "Ceti Contábil S/C Ltda.",
      "Email": "celsobap@terra.com.br",
      "CustomerCode": "64224",
      "Phone": "1532619500",
      "Whatsapp": "1598015885",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1431,
      "Name": "Sergio Augusto Pereira Barbosa",
      "Email": "escritorioapiai@uol.com.br",
      "CustomerCode": "64251",
      "Phone": "1535521495",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1432,
      "Name": "FisContábil Contabilidade Assuntos Fiscais e Des",
      "Email": "fiscondesp1@uol.com.br",
      "CustomerCode": "64255",
      "Phone": "1144111509",
      "Whatsapp": "119441190430",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1433,
      "Name": "Joice Vanessa dos Santos",
      "Email": "joicevanadv@hotmail.com",
      "CustomerCode": "64260",
      "Phone": "1432636853",
      "Whatsapp": "1496015330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1434,
      "Name": "José Macedo Reis",
      "Email": "macedo.reis@terconsultoria.com.br",
      "CustomerCode": "64283",
      "Phone": "1332243531",
      "Whatsapp": "13991678758",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1436,
      "Name": "Cnc Contabilidade e Auditoria Ltda.",
      "Email": "marcelonunes@cnccontabilidade.com.br",
      "CustomerCode": "64290",
      "Phone": "1145381261",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1437,
      "Name": "Alfa Contabilidade de Tupã Ltda-ME",
      "Email": "alfacont@alfatupa.com.br",
      "CustomerCode": "64303",
      "Phone": "1434043535",
      "Whatsapp": "1497926092",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1438,
      "Name": "João Carneiro dos Santos",
      "Email": "jcs0508@hotmail.com",
      "CustomerCode": "64318",
      "Phone": "1231161283",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1439,
      "Name": "Escritório Severino",
      "Email": "escritorioseverino@yahoo.com.br",
      "CustomerCode": "64332",
      "Phone": "1532514606",
      "Whatsapp": "1598511957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1440,
      "Name": "Contábil Mirai Assessoria Empresarial",
      "Email": "contato@contabilmirai.com.br",
      "CustomerCode": "64333",
      "Phone": "1434321866",
      "Whatsapp": "1497398354",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1441,
      "Name": "ACN Assessoria & Contabilidade S/C Ltda.",
      "Email": "processos@acncon.com.br",
      "CustomerCode": "64341",
      "Phone": "4333244373",
      "Whatsapp": "4391085168",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1442,
      "Name": "Cbc Contábil Ltda-ME",
      "Email": "contabilcbc@gmail.com",
      "CustomerCode": "64367",
      "Phone": "1145247794",
      "Whatsapp": "1174846440",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1443,
      "Name": "Edivam Contabilidade",
      "Email": "adm@edivamcontabil.com.br",
      "CustomerCode": "64385",
      "Phone": "1238812516",
      "Whatsapp": "1278128593",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1444,
      "Name": "Escritório Contábil Cheque Ltda.",
      "Email": "flaviolfavaro@gmail.com",
      "CustomerCode": "64386",
      "Phone": "1141582053",
      "Whatsapp": "1184006026",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1445,
      "Name": "Cesar Augusto Alves de Lima",
      "Email": "cesaraal@gmail.com",
      "CustomerCode": "64402",
      "Phone": "1334269611",
      "Whatsapp": "1192253666",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1446,
      "Name": "José Carlos Saraiva Barreto",
      "Email": "escritosaraiva@uol.com.br",
      "CustomerCode": "64405",
      "Phone": "1836031289",
      "Whatsapp": "1897086082",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1447,
      "Name": "Aja Contábil",
      "Email": "adaoamorim@transmob.com.br",
      "CustomerCode": "64423",
      "Phone": "1733455007",
      "Whatsapp": "1791763566",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1448,
      "Name": "Dic Contabilidade & Assessoria Empresarial Ltda.",
      "Email": "contabilidade@diccontabil.net",
      "CustomerCode": "64429",
      "Phone": "193863271",
      "Whatsapp": "1984457951",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1450,
      "Name": "Karla Cristina Varandas Borba",
      "Email": "karla.varandas@terra.com.br",
      "CustomerCode": "64436",
      "Phone": "6133460950",
      "Whatsapp": "6191186919",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1451,
      "Name": "Contabcenter Serviços Contábeis Ltda.",
      "Email": "alfa_contabil@hotmail.com",
      "CustomerCode": "64445",
      "Phone": "1338543089",
      "Whatsapp": "1397938851",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1452,
      "Name": "Orru Assessoria Contábil Fiscal e Trabalhista",
      "Email": "expediente@orrucontabil.com.br",
      "CustomerCode": "64452",
      "Phone": "1437334212",
      "Whatsapp": "1498329500",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1453,
      "Name": "Marinho Contabilidade",
      "Email": "marinho.contabil@terra.com.br",
      "CustomerCode": "64453",
      "Phone": "1236661596",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1454,
      "Name": "Única Contabilidade do Transporte",
      "Email": "michele@unicaassessoria.com.br",
      "CustomerCode": "64458",
      "Phone": "4133333596",
      "Whatsapp": "4196927871",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1455,
      "Name": "Dinâmico Assessoria Contábil Ltda.",
      "Email": "dassessoria@uol.com.br",
      "CustomerCode": "64466",
      "Phone": "1938078986",
      "Whatsapp": "1999870918",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1456,
      "Name": "Cefas Organização Contábil ME",
      "Email": "cefascontabil@yahoo.com.br",
      "CustomerCode": "64475",
      "Phone": "1332345184",
      "Whatsapp": "1388678220",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1457,
      "Name": "Júnior Factore",
      "Email": "jrconsultoriaempresarial@gmail.com",
      "CustomerCode": "64477",
      "Phone": "1733428198",
      "Whatsapp": "1791486190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1458,
      "Name": "Escritório Brasil Contabilidade",
      "Email": "contato.escritoriobrasil@gmail.com",
      "CustomerCode": "64486",
      "Phone": "1637221828",
      "Whatsapp": "169696964",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1459,
      "Name": "Ariane Aparecida de Oliveira Lopes",
      "Email": "arianeo.lopes@uol.com.br",
      "CustomerCode": "64516",
      "Phone": "19994992336",
      "Whatsapp": "19994992336",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1460,
      "Name": "Júlio Cesar Samel Couto",
      "Email": "julio.couto@uol.com.br",
      "CustomerCode": "64517",
      "Phone": "1144118047",
      "Whatsapp": "1194386455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1461,
      "Name": "Sacrini Contabilidade Ltda.",
      "Email": "eduardosacrini@gmail.com",
      "CustomerCode": "64520",
      "Phone": "1140331707",
      "Whatsapp": "1199075826",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1462,
      "Name": "Adolpho Luiz Martinez",
      "Email": "contato@mda.adv.br",
      "CustomerCode": "64521",
      "Phone": "1935128818",
      "Whatsapp": "1991112163",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1464,
      "Name": "Escritório Absoluto de Contabilidade Eireli",
      "Email": "mbjunior@terra.com.br",
      "CustomerCode": "64553",
      "Phone": "1434176908",
      "Whatsapp": "1497020769",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1465,
      "Name": "Assessoria Empresarial e Contábil Lessi",
      "Email": "dudu@contabillessi.com",
      "CustomerCode": "64554",
      "Phone": "1124277378",
      "Whatsapp": "1171001479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1466,
      "Name": "Escritório Contábil Fiscal Chiquinho",
      "Email": "escritoriochiquinhoewillian@hotmail.com",
      "CustomerCode": "64559",
      "Phone": "1938672768",
      "Whatsapp": "1996122074",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1467,
      "Name": "Exit Contabilidade LTDA",
      "Email": "contador@contlex.com.br",
      "CustomerCode": "64564",
      "Phone": "4836571840",
      "Whatsapp": "4888310197",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1468,
      "Name": "Confiare Assessoria Contábil Ltda.",
      "Email": "legal@confiare.com.br",
      "CustomerCode": "64569",
      "Phone": "2137969646",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1469,
      "Name": "Multiplan Organização Contábil Ltda.",
      "Email": "administrativo@multiplancontabil.com.br",
      "CustomerCode": "64574",
      "Phone": "4734677893",
      "Whatsapp": "4788470930",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1470,
      "Name": "Roberto de Assis Barbosa",
      "Email": "robertosector3@gmail.com",
      "CustomerCode": "64576",
      "Phone": "",
      "Whatsapp": "1297350139",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1471,
      "Name": "Alexandre Martins dos Santos",
      "Email": "diretoria1@contabilams.com.br",
      "CustomerCode": "64583",
      "Phone": "1148911561",
      "Whatsapp": "11933093101",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1472,
      "Name": "Contabilidade Gonçalves",
      "Email": "thallescs@contabilidadegoncalves.com",
      "CustomerCode": "64588",
      "Phone": "3532913417",
      "Whatsapp": "3588125369",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1473,
      "Name": "M&V Contadores",
      "Email": "lurdinha.ferreira@mevcontadores.com.br",
      "CustomerCode": "64593",
      "Phone": "1832239898",
      "Whatsapp": "1897422701",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1474,
      "Name": "Escritório Paraná de Contabilidade",
      "Email": "legal@escritorioparana.com.br",
      "CustomerCode": "64597",
      "Phone": "1938972744",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1475,
      "Name": "Rosangela Mazoni Damasco",
      "Email": "legalizacao@contabilmazoni.com.br",
      "CustomerCode": "64603",
      "Phone": "1145862774",
      "Whatsapp": "11997767849",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1477,
      "Name": "Results Consultoria",
      "Email": "andreia@resultsconsultoria.com.br",
      "CustomerCode": "64611",
      "Phone": "3534716730",
      "Whatsapp": "3591910316",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1478,
      "Name": "Braga Contabilidade",
      "Email": "erick@bragacontabilidade.com.br",
      "CustomerCode": "64623",
      "Phone": "1239224877",
      "Whatsapp": "1291004752",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1479,
      "Name": "Mfc Consultoria Empresarial Ltda.",
      "Email": "mfcassessoria@gmail.com",
      "CustomerCode": "64624",
      "Phone": "1433254205",
      "Whatsapp": "1497368292",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1480,
      "Name": "Organização Contábil Donatz",
      "Email": "tezotto@ocdonatz.com",
      "CustomerCode": "64625",
      "Phone": "1933017306",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1481,
      "Name": "Francisco Mallofre",
      "Email": "fonts@fontscontabil.com.br",
      "CustomerCode": "64630",
      "Phone": "1239527495",
      "Whatsapp": "1288136838",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1482,
      "Name": "Georgetto Contabilidade",
      "Email": "administrativo@georgetto.com.br",
      "CustomerCode": "64639",
      "Phone": "1936732172",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1483,
      "Name": "Contabilidade Alves",
      "Email": "contabil.sg@hotmail.com",
      "CustomerCode": "64640",
      "Phone": "1935833716",
      "Whatsapp": "1999967152",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1484,
      "Name": "Daniela de Souza Monteiro Primazzi",
      "Email": "daniela-monteiro@uol.com.br",
      "CustomerCode": "64646",
      "Phone": "",
      "Whatsapp": "1274012881",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1485,
      "Name": "Luciano Contabilidade e Assuntos Fiscais",
      "Email": "luciano@lucianocontabil.com.br",
      "CustomerCode": "64651",
      "Phone": "1532617096",
      "Whatsapp": "1597790698",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1486,
      "Name": "Romano Valer",
      "Email": "romano@conassercontabilidade.com.br",
      "CustomerCode": "64665",
      "Phone": "5432224396",
      "Whatsapp": "5484052320",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1487,
      "Name": "André do Nascimento Rodrigues",
      "Email": "andre.contador@terra.com.br",
      "CustomerCode": "64667",
      "Phone": "1438138113",
      "Whatsapp": "1497932625",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1488,
      "Name": "F. R. A. Coelho - Documentos",
      "Email": "consuteccontabilidade@hotmail.com",
      "CustomerCode": "64670",
      "Phone": "1334947653",
      "Whatsapp": "1391069017",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1489,
      "Name": "Gedeão Macanario dos Santos",
      "Email": "gedeao@rgcontabil.cnt.br",
      "CustomerCode": "64685",
      "Phone": "3534621614",
      "Whatsapp": "3599063379",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1490,
      "Name": "João Antônio Fernandes",
      "Email": "jafernandescontabil@gmail.com",
      "CustomerCode": "64689",
      "Phone": "1833227481",
      "Whatsapp": "1896848292",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1491,
      "Name": "Escritório Contábil Tiradentes",
      "Email": "lidianecontabeis@yahoo.com.br",
      "CustomerCode": "64693",
      "Phone": "1637222244",
      "Whatsapp": "1691680489",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1492,
      "Name": "Mm Com de Sementes e Mudas Ltda.",
      "Email": "ronnymelo@hotmail.com",
      "CustomerCode": "64697",
      "Phone": "4130140432",
      "Whatsapp": "1192394809",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1493,
      "Name": "Mgf Contabilidade e Consultoria Ltda.",
      "Email": "pereira@mgfcont.com.br",
      "CustomerCode": "64702",
      "Phone": "2122246986",
      "Whatsapp": "2199455211",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1494,
      "Name": "Luís Álvaro Lanzoni de Almeida Baptista",
      "Email": "sentinello@uol.com.br",
      "CustomerCode": "64703",
      "Phone": "1338441225",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1495,
      "Name": "Paulo Sergio Marcolino da Silva",
      "Email": "giovana.rodrigues@hotmail.com",
      "CustomerCode": "64714",
      "Phone": "1838211705",
      "Whatsapp": "1897014195",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1496,
      "Name": "Estillo Contabilidade",
      "Email": "juridico@estillo.com.br",
      "CustomerCode": "64722",
      "Phone": "1637210330",
      "Whatsapp": "1699990330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1497,
      "Name": "Constâncio Organização Contábil SS Ltda.",
      "Email": "silvia@constanciocontabil.com.br",
      "CustomerCode": "64731",
      "Phone": "1239546922",
      "Whatsapp": "1297279980",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1498,
      "Name": "Canutto Contabilidade",
      "Email": "canutto.frc@hotmail.com",
      "CustomerCode": "64733",
      "Phone": "1637044550",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1499,
      "Name": "Alexandre Orsolini Violla",
      "Email": "inovacontabilidadebatatais@gmail.com",
      "CustomerCode": "64753",
      "Phone": "1637617532",
      "Whatsapp": "1699961166",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1500,
      "Name": "Hermes Heleno Ferreira ME",
      "Email": "hermes@ferreiraconsult.com.br",
      "CustomerCode": "64756",
      "Phone": "1921214710",
      "Whatsapp": "1991940073",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1501,
      "Name": "Lacerda Diniz e Sena Advogados",
      "Email": "leandro.freitas@lacerdadinizsena.com.br",
      "CustomerCode": "64758",
      "Phone": "3135077777",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1502,
      "Name": "Pires & Associados Organização Contábil",
      "Email": "edinelson.costa@pacontabil.com.br",
      "CustomerCode": "64764",
      "Phone": "1146814442",
      "Whatsapp": "11997350482",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1503,
      "Name": "Joaquim Antônio de Arruda",
      "Email": "arrudanegocios@arrudanegocios.com.br",
      "CustomerCode": "64767",
      "Phone": "1433519051",
      "Whatsapp": "11983776107",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1504,
      "Name": "M&M Contabilidade Consultoria e Coaching",
      "Email": "thalita@mmcontabil.cnt.br",
      "CustomerCode": "64783",
      "Phone": "4832257054",
      "Whatsapp": "4884854258",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1505,
      "Name": "Pereira e Correa Assessoria Contábil",
      "Email": "cristinaivete8@gmail.com",
      "CustomerCode": "64797",
      "Phone": "1141597518",
      "Whatsapp": "1184277096",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1506,
      "Name": "Avelar Contabilidade Ltda.",
      "Email": "nelson@avelarcontabil.com.br",
      "CustomerCode": "64804",
      "Phone": "1936723147",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1507,
      "Name": "B&M Contabilidade Assessoria e Pericia Ltda.",
      "Email": "kikerosj13@gmail.com",
      "CustomerCode": "64808",
      "Phone": "",
      "Whatsapp": "1981849513",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1508,
      "Name": "Ortiz Contabilidade Ltda.",
      "Email": "atendimento@escritorioortiz.com.br",
      "CustomerCode": "64809",
      "Phone": "1935417022",
      "Whatsapp": "1981591509",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1509,
      "Name": "Link Assessoria Contábil",
      "Email": "myllena@contabilidadelink.com.br",
      "CustomerCode": "64810",
      "Phone": "4133223743",
      "Whatsapp": "4184163836",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1510,
      "Name": "Concryel Pavimentação Indústria e Comércio",
      "Email": "alves.marchi@gmail.com",
      "CustomerCode": "64814",
      "Phone": "1938921805",
      "Whatsapp": "1996083131",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1511,
      "Name": "Sango Contábil e Planejamento Tributário",
      "Email": "sangocontabil@yahoo.com.br",
      "CustomerCode": "64821",
      "Phone": "",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1512,
      "Name": "Agilize Contabilidade",
      "Email": "olavo_jrs@hotmail.com",
      "CustomerCode": "64822",
      "Phone": "1338219077",
      "Whatsapp": "1397484612",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1513,
      "Name": "Claudio Capaverde",
      "Email": "claudio.capaverde@terra.com.br",
      "CustomerCode": "64830",
      "Phone": "5535126950",
      "Whatsapp": "5584046464",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1514,
      "Name": "Facility Registros",
      "Email": "leonardo@facilityregistros.com.br",
      "CustomerCode": "64850",
      "Phone": "4841053163",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1515,
      "Name": "Conac Contabilidade",
      "Email": "consultoria@idobusiness.com.br",
      "CustomerCode": "64851",
      "Phone": "8530995622",
      "Whatsapp": "8587466623",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1516,
      "Name": "N7 Assessoria Contábil",
      "Email": "josedil@n7assessoriacontabil.com.br",
      "CustomerCode": "64852",
      "Phone": "1338217055",
      "Whatsapp": "13997543290",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1517,
      "Name": "Aiello Contabilidade",
      "Email": "contato@aiellocontabilidade.com.br",
      "CustomerCode": "64856",
      "Phone": "1148070050",
      "Whatsapp": "1173073828",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1518,
      "Name": "Prata Assessoria Contábil",
      "Email": "mcbprata@hotmail.com",
      "CustomerCode": "64857",
      "Phone": "1335061871",
      "Whatsapp": "1381447180",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1519,
      "Name": "Paulo Cesar Bernardo",
      "Email": "bernardo@pbcontabil.com.br",
      "CustomerCode": "64858",
      "Phone": "2122155653",
      "Whatsapp": "2186940567",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1520,
      "Name": "Contabilidade Cruvinel e Filhas",
      "Email": "adriana_pfonseca@hotmail.com",
      "CustomerCode": "64861",
      "Phone": "3535312975",
      "Whatsapp": "3599754747",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1521,
      "Name": "Contabilidade Seabra Ltda.",
      "Email": "andreia@seabracontabilidade.com.br",
      "CustomerCode": "64863",
      "Phone": "1332031790",
      "Whatsapp": "1388218705",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1522,
      "Name": "Vagner de Sousa Rodrigues",
      "Email": "vagnersr2@gmail.com",
      "CustomerCode": "64864",
      "Phone": "1433518141",
      "Whatsapp": "1498827973",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1523,
      "Name": "Daniel Aparecido Romano",
      "Email": "contabil-mcr@hotmail.com",
      "CustomerCode": "64865",
      "Phone": "1433861242",
      "Whatsapp": "1496417480",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1524,
      "Name": "Rv Assessoria e Consultoria Contábil Eireli ME",
      "Email": "priscila.paixao@consultoriarv.com.br",
      "CustomerCode": "64866",
      "Phone": "1938092966",
      "Whatsapp": "1974150158",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1525,
      "Name": "Glip Office Serviços Contábeis Ltda.",
      "Email": "societario@glip.com.br",
      "CustomerCode": "64870",
      "Phone": "1532023641",
      "Whatsapp": "1597860746",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1526,
      "Name": "Thiago Camin Galvão",
      "Email": "accuratocontabil@gmail.com",
      "CustomerCode": "64872",
      "Phone": "3496461868",
      "Whatsapp": "3496461868",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1527,
      "Name": "Organização Contábil Sandra e Alessandro Ltda. - ME",
      "Email": "contabilidadesandra@terra.com.br",
      "CustomerCode": "64873",
      "Phone": "1338411180",
      "Whatsapp": "1397431180",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1528,
      "Name": "Calculo Real Contabilidade Ltda-ME",
      "Email": "contato@crcontab.com.br",
      "CustomerCode": "64874",
      "Phone": "3538431753",
      "Whatsapp": "3591380271",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1530,
      "Name": "Fabiana Rezende Biondi Quintana",
      "Email": "fabiana.biondi@grupobiondi.com.br",
      "CustomerCode": "64877",
      "Phone": "1231413033",
      "Whatsapp": "1291591241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1531,
      "Name": "Brasilnet Assessoria Contábil Empresarial Ltda.",
      "Email": "marcosbrasilnet@terra.com.br",
      "CustomerCode": "64882",
      "Phone": "1733532280",
      "Whatsapp": "1791986689",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1532,
      "Name": "Jaime Santos Vieira",
      "Email": "maisakobata@bol.com.br",
      "CustomerCode": "64884",
      "Phone": "1535221470",
      "Whatsapp": "1597786452",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1533,
      "Name": "Maria Aparecida de Oliveira Gonçalves",
      "Email": "razaocontab@gmail.com",
      "CustomerCode": "64886",
      "Phone": "1634092737",
      "Whatsapp": "1693827681",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1534,
      "Name": "Cardinal Assessoria Contábil SS",
      "Email": "registro@cardinal.cnt.br",
      "CustomerCode": "64887",
      "Phone": "4830316600",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1535,
      "Name": "Mburg Assessoria Contábil Ltda.",
      "Email": "contato@mburg.com.br",
      "CustomerCode": "64888",
      "Phone": "4730501030",
      "Whatsapp": "4796880788",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1536,
      "Name": "Rp Métodos Assessoria Empresarial Ltda.",
      "Email": "priscila@rpmetodos.com,br",
      "CustomerCode": "64889",
      "Phone": "5132288558",
      "Whatsapp": "51991044786",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1537,
      "Name": "Refrigelo Climatização de Ambientes S.A.",
      "Email": "juridico.interno@leveros.com.br",
      "CustomerCode": "64890",
      "Phone": "1833024888",
      "Whatsapp": "1896141956",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1538,
      "Name": "Mg Organizações Contábeis Ltda.",
      "Email": "constituicao@grupomgcontabil.com.br",
      "CustomerCode": "64891",
      "Phone": "3532231341",
      "Whatsapp": "3588053093",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1539,
      "Name": "Gfo Consultoria Ltda.",
      "Email": "adm@lumeon.com.br",
      "CustomerCode": "64893",
      "Phone": "1142809475",
      "Whatsapp": "1199933158",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1540,
      "Name": "Maurilaene Ferreira de Lima",
      "Email": "maurilaene@hotmail.com",
      "CustomerCode": "64894",
      "Phone": "1397798102",
      "Whatsapp": "1397798102",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1541,
      "Name": "Planeja Consultoria e Treinamento",
      "Email": "contabil@planejaconsultoria.com",
      "CustomerCode": "64895",
      "Phone": "2169236442",
      "Whatsapp": "2169236442",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1542,
      "Name": "Fenix Contabilidade",
      "Email": "fenix.contabil@hotmail.com",
      "CustomerCode": "64897",
      "Phone": "1332245168",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1543,
      "Name": "Ricardo da Silva Varela",
      "Email": "varela.veronezi@gmail.com",
      "CustomerCode": "64898",
      "Phone": "1172256621",
      "Whatsapp": "1198339312",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1544,
      "Name": "Estela Sucasas dos Santos",
      "Email": "estela.sucasas@lacerdadinizsena.com.br",
      "CustomerCode": "64899",
      "Phone": "3135077707",
      "Whatsapp": "3191407779",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1545,
      "Name": "Noé da Silva Neto",
      "Email": "uniaocontabilltdapc@outlook.com",
      "CustomerCode": "64900",
      "Phone": "3537224418",
      "Whatsapp": "3597538255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1546,
      "Name": "DEX Consultoria e Contabilidade Ltda",
      "Email": "thiagovasconcelos@live.com",
      "CustomerCode": "64901",
      "Phone": "6239245553",
      "Whatsapp": "6292299280",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1547,
      "Name": "Ricardo Ricco",
      "Email": "ricardoricco12@hotmail.com",
      "CustomerCode": "64902",
      "Phone": "",
      "Whatsapp": "1297328002",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1548,
      "Name": "Audaces Contábil SS ME",
      "Email": "josimar@audacescontabil.com.br",
      "CustomerCode": "64903",
      "Phone": "1133488990",
      "Whatsapp": "11933488990",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1549,
      "Name": "Andrade & Andrade",
      "Email": "processos@andrade-andrade.com.br",
      "CustomerCode": "64905",
      "Phone": "1239085800",
      "Whatsapp": "1281547258",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1550,
      "Name": "Ativa Investimentos S.A. Corretora de Títulos, Câm",
      "Email": "juridico@ativainvestimentos.com.br",
      "CustomerCode": "64906",
      "Phone": "2139578201",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1551,
      "Name": "Luiz Donizete Galvão",
      "Email": "efg@fiscogarca.com.br",
      "CustomerCode": "64907",
      "Phone": "1434710723",
      "Whatsapp": "147841813",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1552,
      "Name": "M. Rossi Contabilidade Ltda. EPP",
      "Email": "lucas_kikuti@hotmail.com",
      "CustomerCode": "64908",
      "Phone": "1430211591",
      "Whatsapp": "1899757228",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1553,
      "Name": "Malcon Contabilidade Ltda.",
      "Email": "leonardo@malconcontabilidade.com.br",
      "CustomerCode": "64909",
      "Phone": "5137483652",
      "Whatsapp": "5198193691",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1554,
      "Name": "Donin Contabilidade Ltda.",
      "Email": "doninjunior@donin-rnc.com.br",
      "CustomerCode": "64911",
      "Phone": "2138165656",
      "Whatsapp": "2195075187",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1555,
      "Name": "Delt Contabilidade",
      "Email": "eilua@deltcontabilidade.com.br",
      "CustomerCode": "64914",
      "Phone": "1333174940",
      "Whatsapp": "1396256400",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1556,
      "Name": "Conticon Serviços Eireli",
      "Email": "conticon.celsoalexandre@gmail.com",
      "CustomerCode": "64915",
      "Phone": "1134021940",
      "Whatsapp": "1195543538",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1557,
      "Name": "Ind e Com Mat Bernardo Ltda.",
      "Email": "nandinhabernardo@hotmail.com",
      "CustomerCode": "64916",
      "Phone": "1981929006",
      "Whatsapp": "1981929006",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1558,
      "Name": "Marcos Messias da Silva Lopes",
      "Email": "marcos@scalioni.com.br",
      "CustomerCode": "64917",
      "Phone": "3532215184",
      "Whatsapp": "3588433190",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1559,
      "Name": "Antônio Carlos Silva dos Santos",
      "Email": "midiassppg@gmail.com",
      "CustomerCode": "64918",
      "Phone": "1932584627",
      "Whatsapp": "1981713386",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1560,
      "Name": "R J Correa Assessoria Contábil ME",
      "Email": "contato@noroestecontabil.com.br",
      "CustomerCode": "64919",
      "Phone": "1736216323",
      "Whatsapp": "1797086660",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1561,
      "Name": "Base Contábil Ltda. ME",
      "Email": "adm@basecontabil.com.br",
      "CustomerCode": "64920",
      "Phone": "1332335351",
      "Whatsapp": "1391758801",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1562,
      "Name": "Evasola Indústria de Borrachas Ltda.",
      "Email": "contabilidade@evasola.com.br",
      "CustomerCode": "64921",
      "Phone": "1637072400",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1563,
      "Name": "Maria Regina Ferreira Gomes",
      "Email": "mrfgomes@terra.com.br",
      "CustomerCode": "64922",
      "Phone": "2298213554",
      "Whatsapp": "2298213554",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1564,
      "Name": "Nuvasive Brasil Comercial Ltda.",
      "Email": "administrativo@nuvasive.com",
      "CustomerCode": "64925",
      "Phone": "2135274902",
      "Whatsapp": "2199812273",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1565,
      "Name": "Leonardo Lamounier de Miranda",
      "Email": "processo@mouraolamounier.com.br",
      "CustomerCode": "64928",
      "Phone": "3132749340",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1566,
      "Name": "Mayer Serviços de Apoio Empresarial Ltda.",
      "Email": "registrario1@grupomayer.com.br",
      "CustomerCode": "64929",
      "Phone": "4121709970",
      "Whatsapp": "4197606273",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1567,
      "Name": "Carlos Simoes",
      "Email": "carlinhos@fiscontabilbotucatu.com.br",
      "CustomerCode": "64931",
      "Phone": "1438821460",
      "Whatsapp": "1497983717",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1568,
      "Name": "Contale Serviços Contábeis Ltda.",
      "Email": "societario@contalecontabil.com.br",
      "CustomerCode": "64932",
      "Phone": "4830931435",
      "Whatsapp": "4896317089",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1569,
      "Name": "Felipe José de Carvalho Bento",
      "Email": "felipe.cbento@gmail.com",
      "CustomerCode": "64933",
      "Phone": "",
      "Whatsapp": "1992471456",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1570,
      "Name": "St Consultoria e Serviços Contábeis Ltda.",
      "Email": "rodrigo@stcontabilidade.com.br",
      "CustomerCode": "64934",
      "Phone": "2134337949",
      "Whatsapp": "2122247279",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1571,
      "Name": "Paulo Henrique Teixeira",
      "Email": "escritorio.jp@terra.com.br",
      "CustomerCode": "64935",
      "Phone": "1732752900",
      "Whatsapp": "1797917798",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1572,
      "Name": "Worknew Engenharia",
      "Email": "diretoria@worknewengenharia.com.br",
      "CustomerCode": "64937",
      "Phone": "1137976811",
      "Whatsapp": "1176264739",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1573,
      "Name": "Carrierweb Soluções",
      "Email": "marcus.silva@carrierweb.com.br",
      "CustomerCode": "64938",
      "Phone": "2121278685",
      "Whatsapp": "2185964081",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1574,
      "Name": "Ronaldo Cesar Misael de Andrade",
      "Email": "ronaldo@escritoriouniao.maringa.br",
      "CustomerCode": "64939",
      "Phone": "4430289001",
      "Whatsapp": "4499617760",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1575,
      "Name": "Múltipla Contábeis",
      "Email": "angelapredondo@bol.com.br",
      "CustomerCode": "64940",
      "Phone": "1432345166",
      "Whatsapp": "1457166300",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1576,
      "Name": "Rosana Costa dos Santos Alcantara",
      "Email": "calcontabeis@gmail.com",
      "CustomerCode": "64941",
      "Phone": "7736122925",
      "Whatsapp": "77",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1577,
      "Name": "Orlando Leite Contabilidade Ltda.",
      "Email": "orlandoleite@terra.com.br",
      "CustomerCode": "64942",
      "Phone": "1636121613",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1578,
      "Name": "Marcos Antônio de Souza",
      "Email": "marcosquito938@hotmail.com",
      "CustomerCode": "64943",
      "Phone": "1835511408",
      "Whatsapp": "1897239665",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1579,
      "Name": "Office Contabilidade e Assessoria Fiscal",
      "Email": "hmelo.adv@gmail.com",
      "CustomerCode": "64944",
      "Phone": "2135471642",
      "Whatsapp": "2191994692",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1580,
      "Name": "Correta Assessoria e Consultoria Empresarial Ltda.",
      "Email": "rederson@escritoriocorreta.com.br",
      "CustomerCode": "64946",
      "Phone": "1437622864",
      "Whatsapp": "1496575650",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1581,
      "Name": "Brotherhood Contabilidade e Auditoria",
      "Email": "dayana@brotherhoodservicos.com.br",
      "CustomerCode": "64948",
      "Phone": "2132519479",
      "Whatsapp": "2183480035",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1582,
      "Name": "Zandonadi e Associados Serviços Contábeis",
      "Email": "samuel@zandonadi.com.br",
      "CustomerCode": "64949",
      "Phone": "2125088588",
      "Whatsapp": "2193435890",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1583,
      "Name": "Ask Soluções",
      "Email": "contato@asksolucoes.com.br",
      "CustomerCode": "64951",
      "Phone": "4833690232",
      "Whatsapp": "4896717635",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1584,
      "Name": "Organização Contábil Aiello & Pinton Ltda. - EPP",
      "Email": "mgpinton@terra.com.br",
      "CustomerCode": "64952",
      "Phone": "1145877907",
      "Whatsapp": "11",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1585,
      "Name": "Giovani Alves Maruca",
      "Email": "operacional.cmcontabilidade@gmail.com",
      "CustomerCode": "64953",
      "Phone": "4133478995",
      "Whatsapp": "4192521106",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1586,
      "Name": "Escritório Apache Serviços Contábeis Ltda.",
      "Email": "dpf@escritorioapache.com.br",
      "CustomerCode": "64957",
      "Phone": "1437322664",
      "Whatsapp": "1496337775",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1587,
      "Name": "Ss Consultoria Empresarial e Contábil",
      "Email": "ricardo@ssconsultoriaempresarial.com.br",
      "CustomerCode": "64958",
      "Phone": "1437322914",
      "Whatsapp": "1496374749",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1588,
      "Name": "Adilson Rodrigues Coelho",
      "Email": "adilsonrcoelho@hotmail.com",
      "CustomerCode": "64959",
      "Phone": "1832237678",
      "Whatsapp": "1897827971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1589,
      "Name": "Hernani Figueiredo Oliveira dos Reis",
      "Email": "reishernani@yahoo.com.br",
      "CustomerCode": "64961",
      "Phone": "7332222282",
      "Whatsapp": "7399816414",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1590,
      "Name": "Home Contabilidade e Financas",
      "Email": "corporativo@homecontabilidade.com.br",
      "CustomerCode": "64962",
      "Phone": "1140870518",
      "Whatsapp": "1174862250",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1591,
      "Name": "Denis Fernando Alves da Silva de Araújo",
      "Email": "denis@araujoconsult.com.br",
      "CustomerCode": "64963",
      "Phone": "1630269828",
      "Whatsapp": "1692552844",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1593,
      "Name": "Dcifre Tecnologia da Informação Ltda.",
      "Email": "legalizacao@dcifre.com.br",
      "CustomerCode": "64965",
      "Phone": "8133260429",
      "Whatsapp": "8181278942",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1594,
      "Name": "Tavares Consultoria e Contabilidade Eireli",
      "Email": "leonardo.st@hotmail.com",
      "CustomerCode": "64966",
      "Phone": "1533212707",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1595,
      "Name": "Planear Assessoria Contábil Ltda.",
      "Email": "deleg@planearbh.com.br",
      "CustomerCode": "64967",
      "Phone": "3134293600",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1596,
      "Name": "Halysson Henrique Veiga",
      "Email": "digiveiga@hotmail.com",
      "CustomerCode": "64968",
      "Phone": "3534315641",
      "Whatsapp": "3588685410",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1597,
      "Name": "Rubens Gomes da Silva",
      "Email": "garotinho.rubinho@hotmail.com",
      "CustomerCode": "64969",
      "Phone": "1832221418",
      "Whatsapp": "1191219088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1598,
      "Name": "André de Souza Simil",
      "Email": "deisiane@kontabilita.com.br",
      "CustomerCode": "64970",
      "Phone": "3125280500",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1599,
      "Name": "Celta Contabilidade Ltda. ME",
      "Email": "brunojos@bol.com.br",
      "CustomerCode": "64971",
      "Phone": "8432061822",
      "Whatsapp": "8499747093",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1600,
      "Name": "Debora Rufino Contabilidade e Consultoria",
      "Email": "contato@deborarufino.com.br",
      "CustomerCode": "64972",
      "Phone": "6696063819",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1601,
      "Name": "Nova Cont Serviços Contábeis Ltda.",
      "Email": "mayconoliveira@gruponovacont.com.br",
      "CustomerCode": "64973",
      "Phone": "2134881685",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1602,
      "Name": "Kautz Contabilidade",
      "Email": "simone@kautzcontabilidade.com.br",
      "CustomerCode": "64974",
      "Phone": "",
      "Whatsapp": "4391591191",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1603,
      "Name": "Tecol Assessoria Contábil Ltda.",
      "Email": "patricia@tecolcontabil.com",
      "CustomerCode": "64975",
      "Phone": "3232611758",
      "Whatsapp": "3299988471",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1604,
      "Name": "Carlos Eduardo Athayde Chaves",
      "Email": "eduardo@eduardochaves.net",
      "CustomerCode": "64978",
      "Phone": "8341414442",
      "Whatsapp": "8387870002",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1605,
      "Name": "Gilmar de Almeida Gil",
      "Email": "gilmar.almeida.gil@gmail.com",
      "CustomerCode": "64979",
      "Phone": "1330628711",
      "Whatsapp": "1189503558",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1606,
      "Name": "Cristiano Ferreira dos Santos",
      "Email": "cristiano.araujosmg@gmail.com",
      "CustomerCode": "64980",
      "Phone": "3732881238",
      "Whatsapp": "3799730073",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1607,
      "Name": "Agenda Fiscal Assessoria Contábil Ltda.",
      "Email": "roberto.kawabata@uol.com.br",
      "CustomerCode": "64981",
      "Phone": "",
      "Whatsapp": "1171184683",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1608,
      "Name": "Harmonia Contabilidade Ltda.",
      "Email": "brendon@harmoniacontabil.com.br",
      "CustomerCode": "64982",
      "Phone": "1938855622",
      "Whatsapp": "1998332512",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1609,
      "Name": "Ismael Cracco Junior",
      "Email": "ismael.junior@fadvempresarial.com.br",
      "CustomerCode": "64983",
      "Phone": "4432621595",
      "Whatsapp": "4499897903",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1610,
      "Name": "Assessoria Contábil Cilo Hummes S/S Ltda.",
      "Email": "fabio@cilohummes.com.br",
      "CustomerCode": "64984",
      "Phone": "5133322444",
      "Whatsapp": "5199513664",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1611,
      "Name": "Fabiano Caires de Araújo",
      "Email": "servoffice.jus@gmail.com",
      "CustomerCode": "64985",
      "Phone": "1221036122",
      "Whatsapp": "1291844607",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1612,
      "Name": "Roberto Rosa Santos",
      "Email": "robertorsantos2012@gmail.com",
      "CustomerCode": "64986",
      "Phone": "1534423966",
      "Whatsapp": "1596888411",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1613,
      "Name": "Ntw Contabilidade e Gestão Empresarial",
      "Email": "marins@ntwcontabilidade.com.br",
      "CustomerCode": "64987",
      "Phone": "1172122154",
      "Whatsapp": "1198302951",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1614,
      "Name": "Albergaria e Castro Sociedade de Advogados",
      "Email": "jcastro@albergariaecastro.adv.br",
      "CustomerCode": "64988",
      "Phone": "1932557692",
      "Whatsapp": "1991189707",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1616,
      "Name": "Giovanni Aparecido da Silva",
      "Email": "giovanni.contabilidade@hotmail.com",
      "CustomerCode": "64990",
      "Phone": "1238650301",
      "Whatsapp": "1291165003",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1618,
      "Name": "Nelson Correia da Silva Digitação",
      "Email": "nelson@ncscontabil.com.br",
      "CustomerCode": "64992",
      "Phone": "1147281205",
      "Whatsapp": "1165951042",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1619,
      "Name": "Guaraci Castiglioni",
      "Email": "gcccontabil@gmail.com",
      "CustomerCode": "64993",
      "Phone": "1144128861",
      "Whatsapp": "1195414532",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1620,
      "Name": "Sercon Barretos Serviços Contábeis Ltda.",
      "Email": "sercon@serconbarretos.com.br",
      "CustomerCode": "64994",
      "Phone": "1733215999",
      "Whatsapp": "1181338535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1621,
      "Name": "José Renato Rodrigues",
      "Email": "contab.renato1@gmail.com",
      "CustomerCode": "64995",
      "Phone": "4732464841",
      "Whatsapp": "4799183069",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1622,
      "Name": "AgroFay Consultoria Tributária LTDA",
      "Email": "ecred.fay@gmail.com",
      "CustomerCode": "64996",
      "Phone": "1437691831",
      "Whatsapp": "14999021934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1623,
      "Name": "Monyca Motta Sociedade Individual de Advocacia",
      "Email": "monyca@mmrights.com.br",
      "CustomerCode": "64997",
      "Phone": "1132801388",
      "Whatsapp": "1172263211",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1624,
      "Name": "Nubia Ferreira dos Santos Cardoso",
      "Email": "processo@awnsolucoescontabeis.com.br",
      "CustomerCode": "64998",
      "Phone": "6232061032",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1625,
      "Name": "Juliana Hames Medeiros",
      "Email": "megcontabilidade@outlook.com",
      "CustomerCode": "64999",
      "Phone": "4830936567",
      "Whatsapp": "1184385125",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1626,
      "Name": "Rafael Gustavo Linke",
      "Email": "rafael.linke@certezza.com.br",
      "CustomerCode": "65000",
      "Phone": "4432621595",
      "Whatsapp": "4599151900",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1627,
      "Name": "Mauricio Barbosa Campos",
      "Email": "administrativo02@almapy.com.br",
      "CustomerCode": "65001",
      "Phone": "1124101680",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1628,
      "Name": "D&H Contabilidade Ltda.",
      "Email": "henrique@labor12.com.br",
      "CustomerCode": "65002",
      "Phone": "7187092682",
      "Whatsapp": "7191048804",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1629,
      "Name": "Sf Representação Comercial",
      "Email": "etienetemeira@gmail.com",
      "CustomerCode": "65003",
      "Phone": "",
      "Whatsapp": "3891320099",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1630,
      "Name": "Andreza Maria Ribeiro",
      "Email": "contabandreza07@gmail.com",
      "CustomerCode": "65004",
      "Phone": "1338415076",
      "Whatsapp": "1397242931",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1631,
      "Name": "Efe Contabilidade e Auditoria Ltda.",
      "Email": "legalizacao@efecontabilidade.com.br",
      "CustomerCode": "65005",
      "Phone": "1145868000",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1632,
      "Name": "Laercio Carrascosa Vasco",
      "Email": "laercio@conexaospservicos.com",
      "CustomerCode": "65006",
      "Phone": "1155714812",
      "Whatsapp": "11961099000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1633,
      "Name": "Planemp Serviços Administrativos",
      "Email": "contato@planemp.com.br",
      "CustomerCode": "65007",
      "Phone": "1145216648",
      "Whatsapp": "1181076600",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1634,
      "Name": "Rubens de Jesus Falavinha",
      "Email": "escritorio@boavistacontabilidade.com.br",
      "CustomerCode": "65008",
      "Phone": "4130181230",
      "Whatsapp": "4184219064",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1635,
      "Name": "Fabio de Pala Moraes",
      "Email": "fabio@planob-empresarial.com.br",
      "CustomerCode": "65009",
      "Phone": "",
      "Whatsapp": "1192595224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1636,
      "Name": "Marcia Maria Carneiro",
      "Email": "marcia.carneiro@pbravo.com.br",
      "CustomerCode": "65010",
      "Phone": "2422217810",
      "Whatsapp": "2173739000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1638,
      "Name": "Edson Neves",
      "Email": "centro_ma@hotmail.com",
      "CustomerCode": "65012",
      "Phone": "1146042115",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1639,
      "Name": "Sagen Serviços de Informação Eireli",
      "Email": "adm@contana.cnt.br",
      "CustomerCode": "65013",
      "Phone": "4821320090",
      "Whatsapp": "4888043910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1640,
      "Name": "Eldovane Bo Filho",
      "Email": "eldovane@embcont.com.br",
      "CustomerCode": "65014",
      "Phone": "",
      "Whatsapp": "4796340973",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1641,
      "Name": "Natali da Silva Alves",
      "Email": "natali.alves20@gmail.com",
      "CustomerCode": "65015",
      "Phone": "1382043022",
      "Whatsapp": "11934745381",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1642,
      "Name": "Kleverton Rocha de Moraes Contabilidade",
      "Email": "rocha@rochacontabeis.com.br",
      "CustomerCode": "65016",
      "Phone": "4833642402",
      "Whatsapp": "4896002460",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1643,
      "Name": "Solucionar Serviços Contábeis LTDA",
      "Email": "legal@solucionarcontabilidade.com.br",
      "CustomerCode": "65017",
      "Phone": "3125643549",
      "Whatsapp": "31987811114",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1644,
      "Name": "Guanabara Assessoria Contábil Ltda.",
      "Email": "adilson@guanabaracontabil.com.br",
      "CustomerCode": "65018",
      "Phone": "1932436148",
      "Whatsapp": "1984183538",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1645,
      "Name": "Alexandre Yamakami da Cruz",
      "Email": "aycruz@terra.com.br",
      "CustomerCode": "65019",
      "Phone": "1632360051",
      "Whatsapp": "1697841862",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1646,
      "Name": "Wcfas Soluções Empresariais",
      "Email": "fiscal3@wcfas.com.br",
      "CustomerCode": "65020",
      "Phone": "1148251750",
      "Whatsapp": "1186243992",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1647,
      "Name": "Luciano Maroco Durão",
      "Email": "lucianodurao@hotmail.com",
      "CustomerCode": "65021",
      "Phone": "3232712137",
      "Whatsapp": "3299459610",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1649,
      "Name": "Bruna da Silva",
      "Email": "fiscal@ellun.com.br",
      "CustomerCode": "65023",
      "Phone": "7532211973",
      "Whatsapp": "7591199971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1650,
      "Name": "Adriana Santana Montenegro",
      "Email": "adriana.contabil16@gmail.com",
      "CustomerCode": "65024",
      "Phone": "7499639506",
      "Whatsapp": "7499080625",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1651,
      "Name": "Felipe Nunes da Silveira",
      "Email": "felipe@silveiraschneider.com.br",
      "CustomerCode": "65025",
      "Phone": "4830399999",
      "Whatsapp": "4899115061",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1653,
      "Name": "Lucas Maia",
      "Email": "financeiro@karavela.com.br",
      "CustomerCode": "65027",
      "Phone": "31991096264",
      "Whatsapp": "31992863556",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1654,
      "Name": "Angelo Ivan Martins",
      "Email": "omcontamgo@gmail.com",
      "CustomerCode": "65028",
      "Phone": "5136321302",
      "Whatsapp": "5180150372",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1655,
      "Name": "Signal Serviços Administrativos Ltda.",
      "Email": "signalcontabil@outlook.com",
      "CustomerCode": "65029",
      "Phone": "1232244942",
      "Whatsapp": "1292571720",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1656,
      "Name": "Ivo Gabriel Melo de Souza",
      "Email": "ivo@amsassociados.com",
      "CustomerCode": "65030",
      "Phone": "9293714177",
      "Whatsapp": "9293714177",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1657,
      "Name": "Ricardo Chiodelli",
      "Email": "chiodelli@chiodelli.net.br",
      "CustomerCode": "65031",
      "Phone": "5433131363",
      "Whatsapp": "5499831876",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1658,
      "Name": "Cleber Augusto de Almeida",
      "Email": "clebercontabil78@gmail.com",
      "CustomerCode": "65032",
      "Phone": "",
      "Whatsapp": "6781832143",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1659,
      "Name": "Digcont Assessoria e Consult Contábil Sociedade Simple",
      "Email": "diretoria.geral@digcontcontabil.com",
      "CustomerCode": "65033",
      "Phone": "7320118171",
      "Whatsapp": "7391285537",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1660,
      "Name": "Gehlen Contabilidade Ltda.",
      "Email": "juli.ttv@gmail.com",
      "CustomerCode": "65034",
      "Phone": "",
      "Whatsapp": "5496609594",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1661,
      "Name": "Salus Contabilidade Eireli",
      "Email": "societario@saluscontabilidade.com.br",
      "CustomerCode": "65035",
      "Phone": "4930250666",
      "Whatsapp": "4988250666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1662,
      "Name": "Adelson Lage Do Carmo",
      "Email": "adelsonlage@hotmail.com",
      "CustomerCode": "65036",
      "Phone": "7132429846",
      "Whatsapp": "7186112901",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1663,
      "Name": "Marcos José Zuanon",
      "Email": "mzuanon@folladorzuanon.com.br",
      "CustomerCode": "65037",
      "Phone": "4130148988",
      "Whatsapp": "4199640888",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1664,
      "Name": "Lowtax Gestão Contábil Ltda.",
      "Email": "francine.jimenez@lowtax.com.br",
      "CustomerCode": "65038",
      "Phone": "2121025930",
      "Whatsapp": "2281667666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1665,
      "Name": "Anderson Lira dos Reis",
      "Email": "andersonlira@outlook.com",
      "CustomerCode": "65039",
      "Phone": "",
      "Whatsapp": "7599956209",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1667,
      "Name": "Contnuvem Contabilidade",
      "Email": "contato@contnuvem.com.br",
      "CustomerCode": "65041",
      "Phone": "6932105453",
      "Whatsapp": "6984496737",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1668,
      "Name": "Bhs Organização Contábil Ltda. - ME",
      "Email": "helio@bhsorg.com.br",
      "CustomerCode": "65042",
      "Phone": "",
      "Whatsapp": "4196187265",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1669,
      "Name": "Nexo Prosper Contabilidade SS",
      "Email": "legalizacao.prosper@nexocont.com.br",
      "CustomerCode": "65043",
      "Phone": "1239417480",
      "Whatsapp": "1291388932",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1670,
      "Name": "Ercilia Teresio de Oliveira Serviços Contábeis",
      "Email": "kauana@ekcontabil.com.br",
      "CustomerCode": "65044",
      "Phone": "4131491951",
      "Whatsapp": "4195944455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1671,
      "Name": "José Eduardo Rissi",
      "Email": "gestor.societario@rissicontabilidade.com.br",
      "CustomerCode": "65045",
      "Phone": "1733059030",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1672,
      "Name": "Air Contab Consultoria Ltda.",
      "Email": "ana@aircontab.com",
      "CustomerCode": "65046",
      "Phone": "4796208101",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1673,
      "Name": "Campos & Associados Contabilidade Ltda.",
      "Email": "jessica@camposeassociados.com.br",
      "CustomerCode": "65047",
      "Phone": "1121890707",
      "Whatsapp": "11941922602",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1674,
      "Name": "Contabilidade Mainhardt Ltda.",
      "Email": "jm111@jmainhardt.com.br",
      "CustomerCode": "65048",
      "Phone": "4735629000",
      "Whatsapp": "4791766451",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1675,
      "Name": "Arrivabene Contabilidade e Assessoria S/S Ltda.",
      "Email": "anaflavia@arrivabene.com.br",
      "CustomerCode": "65049",
      "Phone": "1938278080",
      "Whatsapp": "19999572821",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1676,
      "Name": "Rosilene Arruda dos Santos",
      "Email": "leninha14@hotmail.com",
      "CustomerCode": "65050",
      "Phone": "",
      "Whatsapp": "8187888762",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1677,
      "Name": "Erika Renata Rossi Santos",
      "Email": "erika.rossi.contabilidade@gmail.com",
      "CustomerCode": "65051",
      "Phone": "6532415029",
      "Whatsapp": "6596775423",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1678,
      "Name": "Adélio Fortes Netto",
      "Email": "adeliofortes@hotmail.com",
      "CustomerCode": "65052",
      "Phone": "",
      "Whatsapp": "13997075051",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1679,
      "Name": "Antônio Celso de Camargo",
      "Email": "ace_ariane@hotmail.com",
      "CustomerCode": "65053",
      "Phone": "4430267993",
      "Whatsapp": "4499729633",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1680,
      "Name": "One Consultoria Contábil SS Ltda.",
      "Email": "rogerio@one-consultoria.com",
      "CustomerCode": "65054",
      "Phone": "1932439013",
      "Whatsapp": "19982833467",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1681,
      "Name": "Ana Clara Fernandes de Lima",
      "Email": "clara.lima@inovic.com.br",
      "CustomerCode": "65055",
      "Phone": "",
      "Whatsapp": "2199551816",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1682,
      "Name": "Gestão Digital Ltda.",
      "Email": "curitiba-pr@gestaocont.com.br",
      "CustomerCode": "65056",
      "Phone": "",
      "Whatsapp": "4199143428",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1683,
      "Name": "Progresso Contabilidade e Auditoria Ltda.",
      "Email": "rhudson@pcaweb.com.br",
      "CustomerCode": "65057",
      "Phone": "6232511231",
      "Whatsapp": "6296629640",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1684,
      "Name": "Leticia Lima Contabilidade e Consultoria Ltda.",
      "Email": "contadoraleticialima@gmail.com",
      "CustomerCode": "65058",
      "Phone": "1399690244",
      "Whatsapp": "1396902440",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1685,
      "Name": "Jaqueline Alves da Silva Cerqueira",
      "Email": "jaquelinealvess@gmail.com",
      "CustomerCode": "65059",
      "Phone": "",
      "Whatsapp": "3199256583",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1686,
      "Name": "Simel Serviços Contábeis Ltda.",
      "Email": "diretoria@simel.com.br",
      "CustomerCode": "65060",
      "Phone": "2132538166",
      "Whatsapp": "2181528360",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1687,
      "Name": "Bertolozzi & Fachini Escritório de Contabilidade L",
      "Email": "escritorioasap@gmail.com",
      "CustomerCode": "65061",
      "Phone": "1734228877",
      "Whatsapp": "1781856569",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1688,
      "Name": "The Control Contabilidade",
      "Email": "gerencia.paralegal@gmail.com",
      "CustomerCode": "65062",
      "Phone": "9233468140",
      "Whatsapp": "9295060676",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1689,
      "Name": "Contabilidade Conexus",
      "Email": "despachante@papyruscontabil.com.br",
      "CustomerCode": "65063",
      "Phone": "3135082000",
      "Whatsapp": "3599217184",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1690,
      "Name": "S.A.M Leite Serviços Contábeis",
      "Email": "sicari.ap.marinho@gmail.com",
      "CustomerCode": "65064",
      "Phone": "",
      "Whatsapp": "3284337094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1691,
      "Name": "Gdi Rotinas Administrativas Ltda.",
      "Email": "gdiapucarana@gmail.com",
      "CustomerCode": "65065",
      "Phone": "3434220794",
      "Whatsapp": "4399745413",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1692,
      "Name": "Mourão e Lamounier Auditoria e Contabilidade",
      "Email": "felipe@mouraolamounier.com.br",
      "CustomerCode": "65066",
      "Phone": "3132749340",
      "Whatsapp": "3186080133",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1693,
      "Name": "Rafael Fernandes Pinheiro",
      "Email": "rfernandespinheiro@gmail.com",
      "CustomerCode": "65067",
      "Phone": "4131563372",
      "Whatsapp": "4188733659",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1694,
      "Name": "Bazzi Assessoria Contábil & Empresarial Ltda.",
      "Email": "societario@bazziassessoria.com.br",
      "CustomerCode": "65068",
      "Phone": "4933470222",
      "Whatsapp": "4988033659",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1696,
      "Name": "Foco Assessoria Contábil Eireli",
      "Email": "e.contatofiscal@gmail.com",
      "CustomerCode": "65070",
      "Phone": "1436563160",
      "Whatsapp": "14998983394",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1697,
      "Name": "O Vigilante - Empresa de Contabilidade Eireli",
      "Email": "neumar@escritoriovigilante.com.br",
      "CustomerCode": "65071",
      "Phone": "1139026466",
      "Whatsapp": "1181335924",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1701,
      "Name": "Danilo Balaban",
      "Email": "rodrigomocz@gmail.com",
      "CustomerCode": "65075",
      "Phone": "4130205060",
      "Whatsapp": "4188390328",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1702,
      "Name": "Junillson Pinto de Arruda",
      "Email": "junillson@gmail.com",
      "CustomerCode": "65076",
      "Phone": "",
      "Whatsapp": "6791983944",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1703,
      "Name": "Vitor Batista Domingues",
      "Email": "vitor.batista_d@hotmail.com",
      "CustomerCode": "65078",
      "Phone": "1338414104",
      "Whatsapp": "1397263598",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1705,
      "Name": "Samanta Paula",
      "Email": "samantapape@hotmail.com",
      "CustomerCode": "65080",
      "Phone": "1984071650",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1706,
      "Name": "Luiz Antônio Vilas Boas Contabilidade",
      "Email": "hortensia@conceitualcontabil.com.br",
      "CustomerCode": "65081",
      "Phone": "3538262993",
      "Whatsapp": "3591910325",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1707,
      "Name": "Contabilize Empresarial Ltda.",
      "Email": "fabio.rohem@contabilize.eti.br",
      "CustomerCode": "65082",
      "Phone": "2134007570",
      "Whatsapp": "2196458115",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1708,
      "Name": "Rc Consultoria Contábil",
      "Email": "contabilidade@rcconsultoriacontabil.com.br",
      "CustomerCode": "65083",
      "Phone": "1144118540",
      "Whatsapp": "1197681395",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1709,
      "Name": "Carlos Kleber da Silva",
      "Email": "calks1@hotmail.com",
      "CustomerCode": "65084",
      "Phone": "",
      "Whatsapp": "1996892338",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1711,
      "Name": "Diego de Oliveira Silva",
      "Email": "diego@contabilidademaster.com",
      "CustomerCode": "65086",
      "Phone": "2433461059",
      "Whatsapp": "2499825424",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1712,
      "Name": "Marcos Donizete Torres Leão",
      "Email": "marcostorresleao@gmail.com",
      "CustomerCode": "65087",
      "Phone": "1334183420",
      "Whatsapp": "1396027780",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1713,
      "Name": "Alan Bean Ferreira Batista",
      "Email": "font.legalizacao@gmail.com",
      "CustomerCode": "65089",
      "Phone": "2225210672",
      "Whatsapp": "2298843377",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1714,
      "Name": "Rosinéia da Fonseca Ferreira Conceição",
      "Email": "rosimarcontabil@yahoo.com.br",
      "CustomerCode": "65090",
      "Phone": "1231447639",
      "Whatsapp": "1235001502",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1715,
      "Name": "Gicon Contabilidade Ltda.",
      "Email": "legal@gicon.cnt.br",
      "CustomerCode": "65091",
      "Phone": "4830478150",
      "Whatsapp": "4899359241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1716,
      "Name": "Marília Gomes Pereira Pinto",
      "Email": "marilia@direitoimaterial.com.br",
      "CustomerCode": "65092",
      "Phone": "15991044025",
      "Whatsapp": "15991051116",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1717,
      "Name": "Roseneide da Silva",
      "Email": "alphacontabilidadeourinhos@gmail.com",
      "CustomerCode": "65093",
      "Phone": "",
      "Whatsapp": "1498225208",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1718,
      "Name": "André Adolfo da Silva",
      "Email": "thiago.barbalho@bwa.global",
      "CustomerCode": "65094",
      "Phone": "8432046500",
      "Whatsapp": "8481500449",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1719,
      "Name": "Lenovo Tecnologia Brasil Ltda.",
      "Email": "taxbrazil@lenovo.com",
      "CustomerCode": "900212",
      "Phone": "1939325400",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1720,
      "Name": "Lenovo Comercial e Distribuição Ltda.",
      "Email": "taxbrazil@lenovo.com",
      "CustomerCode": "900213",
      "Phone": "1939325400",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1721,
      "Name": "Lenovo Global Brasil",
      "Email": "fsalvi@lenovo.com",
      "CustomerCode": "900214",
      "Phone": "1939325400",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1722,
      "Name": "Márcio Mendonça Rodrigues",
      "Email": "societario@rnvconsultoria.com.br",
      "CustomerCode": "19010",
      "Phone": "1130818677",
      "Whatsapp": "99999999999",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1723,
      "Name": "Gruen Biogás Ltda. ",
      "Email": "daniel.peroza@gruen.com.br",
      "CustomerCode": "19009",
      "Phone": "11992911873",
      "Whatsapp": "11992911873",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1724,
      "Name": "Gestaf Contabilidade ",
      "Email": "contato@gestaf.com.br",
      "CustomerCode": "19008",
      "Phone": "1132022864",
      "Whatsapp": "11958557804",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1725,
      "Name": "Bruno Vidal Sousa de Camargo Barros",
      "Email": "brunovidal@adv.oabsp.org.br",
      "CustomerCode": "65106",
      "Phone": "1532514633",
      "Whatsapp": "15991145777",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1726,
      "Name": "Valdecir Haber ",
      "Email": "contabilhaber@hotmail.com",
      "CustomerCode": "65107",
      "Phone": "4136069424",
      "Whatsapp": "41984121456",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1727,
      "Name": "Saulo de Souza Santos",
      "Email": "saulocontabeis@gmail.com",
      "CustomerCode": "65105",
      "Phone": "31999187297",
      "Whatsapp": "31991872970",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1728,
      "Name": "Patrícia de Mello Schavareto",
      "Email": "e.contesco@gmail.com",
      "CustomerCode": "36319",
      "Phone": "1136811566",
      "Whatsapp": "1197052930",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1729,
      "Name": "Alexandre Cunha",
      "Email": "ale@valordonegocio.com.br",
      "CustomerCode": "37363",
      "Phone": "1141522908",
      "Whatsapp": "11097751708",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1731,
      "Name": "André Gomes Batista",
      "Email": "contfoccus@gmail.com",
      "CustomerCode": "65103",
      "Phone": "33988444555",
      "Whatsapp": "33991644555",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1734,
      "Name": "Aurélio Thomaz da Silva",
      "Email": "aureliothomaz@hotmail.com",
      "CustomerCode": "15311",
      "Phone": "1122768903",
      "Whatsapp": "11985549713",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1735,
      "Name": "Contabilidade Inforsato Ltda.",
      "Email": "valdemar@inforsato.com.br",
      "CustomerCode": "35139",
      "Phone": "1124126315",
      "Whatsapp": "11988749896",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1736,
      "Name": "Nelson Yosiharu Hidaka",
      "Email": "hidaka.contabilidade@gmail.com",
      "CustomerCode": "37286\t",
      "Phone": "1141870755",
      "Whatsapp": "11972783629",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1737,
      "Name": "José Marcos Fernandes",
      "Email": "contasimetria@gmail.com",
      "CustomerCode": "38239",
      "Phone": "1140910670",
      "Whatsapp": "11989238088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1738,
      "Name": "Sandra Regina Lopes de Assis Ozan",
      "Email": "ozansandra@gmail.com",
      "CustomerCode": "17890 ",
      "Phone": "1120590294",
      "Whatsapp": "11981536199",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1739,
      "Name": "L A Mello Contabilidade ",
      "Email": "paralegal@contabilidademello.com.br",
      "CustomerCode": "17906",
      "Phone": "1155418343",
      "Whatsapp": "11997818012",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1740,
      "Name": "Ethika Contabilidade",
      "Email": "legalizacao@ethika.com.br",
      "CustomerCode": "38460",
      "Phone": "1141232130",
      "Whatsapp": "11997794712",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1741,
      "Name": "Contatho Soluções Empresariais ",
      "Email": "andreia.thomaz@contatho.com.br",
      "CustomerCode": "17924",
      "Phone": "1135826571",
      "Whatsapp": "11983818303",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1742,
      "Name": "Clemente Porto - Assessoria Contábil Ltda. ",
      "Email": "alex@clementeporto.com.br",
      "CustomerCode": "18226",
      "Phone": "1138322091",
      "Whatsapp": "11971590913",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1743,
      "Name": "John Michael Berude Lund",
      "Email": "johnmichaellund@gmail.com",
      "CustomerCode": "65088 ",
      "Phone": "3132003200",
      "Whatsapp": "31992013002",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1744,
      "Name": "Mauri Antônio Alves",
      "Email": "mauri@maurialves.com.br",
      "CustomerCode": "19001",
      "Phone": "1120937268",
      "Whatsapp": "11976601890",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1745,
      "Name": "Carlos Roberto Custodio ME",
      "Email": "carlos@cr3consultoriaempresarial.com.br",
      "CustomerCode": "38588",
      "Phone": "11939370220",
      "Whatsapp": "11939370220",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1746,
      "Name": "Nivaldo Cavanholi Fernandes",
      "Email": "leonardo@becks.com.br",
      "CustomerCode": "65095 ",
      "Phone": "48998091761",
      "Whatsapp": "48988228762",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1747,
      "Name": "Ana Paula Simão de Assis",
      "Email": "contato@proficoncontabilidade.com",
      "CustomerCode": "65096 ",
      "Phone": "2226438931",
      "Whatsapp": "22992195214",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1748,
      "Name": "Mauro José Batista",
      "Email": "batista-assessoria@uol.com.br",
      "CustomerCode": "19002",
      "Phone": "1122958380",
      "Whatsapp": "11999002314",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1749,
      "Name": "Victor Araújo Amorim",
      "Email": "victoramorim@pontesdemiranda.adv.br",
      "CustomerCode": "19004 ",
      "Phone": "61982368899",
      "Whatsapp": "61982368899",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1750,
      "Name": "Líder Assessorial Empresarial Eireli",
      "Email": "wagner@lidercontab.cnt.br",
      "CustomerCode": "65099",
      "Phone": "12936689080",
      "Whatsapp": "12996779080",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1751,
      "Name": "Pedro Elias Abdalla",
      "Email": "pedro_abdalla@hotmail.com",
      "CustomerCode": "19005",
      "Phone": "11964798040",
      "Whatsapp": "11964798040",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1753,
      "Name": "Solumax Contabilidade S/S Ltda.",
      "Email": "legalizacao@solumaxcontabilidade.com.br",
      "CustomerCode": "65100",
      "Phone": "2125974905",
      "Whatsapp": "21999621215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1756,
      "Name": "Ivo Gilberto Padilha",
      "Email": "igp.thiago@outlook.com",
      "CustomerCode": "65101 ",
      "Phone": "4132478198",
      "Whatsapp": "41996860305",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1757,
      "Name": "Sampaio & Lima Gestão Contábil e Condominial Ltda.",
      "Email": "caio.gomes@sampali.com.br",
      "CustomerCode": "65102 ",
      "Phone": "2126225391",
      "Whatsapp": "21996881871",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1758,
      "Name": "Guilherme de Sá Demenato",
      "Email": "juridicomix@gmail.com",
      "CustomerCode": "19007",
      "Phone": "1121661593",
      "Whatsapp": "11982050534",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1759,
      "Name": "Cassiano Aparecido Oliveira",
      "Email": "cassiano@firstcontabilidade.com.br",
      "CustomerCode": "15857",
      "Phone": "1131923888",
      "Whatsapp": "11973004226",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1760,
      "Name": "Contar Contabilidade",
      "Email": "contar@contarprocessamentos.com.br",
      "CustomerCode": "17673",
      "Phone": "1150586954",
      "Whatsapp": "11997208134",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1761,
      "Name": "Ka+ Gestão Contábil Ltda.",
      "Email": "kauana@kamaisgestao.com.br",
      "CustomerCode": "65108",
      "Phone": "4131491951",
      "Whatsapp": "41995944455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1762,
      "Name": "Dirlei Wlaudete Weber da Gama",
      "Email": "dirleycontadora@gmail.com",
      "CustomerCode": "19011",
      "Phone": "11037356014",
      "Whatsapp": "11994941066",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1763,
      "Name": "Arena Contabilidade \t",
      "Email": "arenacontabil@uol.com.br",
      "CustomerCode": "35170",
      "Phone": "1124516401",
      "Whatsapp": "11981988780",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1764,
      "Name": "Born Auditores Associados",
      "Email": "deborah.silva@bornauditores.com.br",
      "CustomerCode": "65109",
      "Phone": "3134846266",
      "Whatsapp": "31999866589",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1765,
      "Name": "Elle - Prestação de Serviços Contábeis Eireli",
      "Email": "lucilia.diretoria@contabilnicolini.com.br",
      "CustomerCode": "19012",
      "Phone": "1121458851",
      "Whatsapp": "11993074393",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1766,
      "Name": "Sueli Pedrozo de Oliveira ME",
      "Email": "teccontabilcont@hotmail.com",
      "CustomerCode": "16130",
      "Phone": "1120431909",
      "Whatsapp": "11995193408",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1767,
      "Name": "José Ricardo Sousa Pinheiro",
      "Email": "ricardopinheirocontabilidade@gmail.com",
      "CustomerCode": "19013",
      "Phone": "1129877014",
      "Whatsapp": "11996265498",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1768,
      "Name": "Eder Cassimiro de Abreu \t\t\t",
      "Email": "ecabreu@yahoo.com",
      "CustomerCode": "37272",
      "Phone": "1126074613",
      "Whatsapp": "11999274299",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1770,
      "Name": "Anderson Yukiyoshi Sato",
      "Email": "adv.ays@hotmail.com",
      "CustomerCode": "38591",
      "Phone": "1138691422",
      "Whatsapp": "11973279156",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1771,
      "Name": "Mayara Almeida de Farias",
      "Email": "hamashiassessoria@gmail.com",
      "CustomerCode": "38592",
      "Phone": "1146732013",
      "Whatsapp": "11985241248",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1772,
      "Name": "Antonio Ferreira Dourado Filho",
      "Email": "aferreirad@terra.com.br",
      "CustomerCode": "19014",
      "Phone": "11",
      "Whatsapp": "11982859938",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1773,
      "Name": "Rodrigo Jesus da Silva",
      "Email": "fiscal@lopesassessoria.com.br",
      "CustomerCode": "19015",
      "Phone": "1133150246",
      "Whatsapp": "11991976167",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1774,
      "Name": "Viagens Promo Turismo S/S Ltda",
      "Email": "arquimedes@viagenspromo.com",
      "CustomerCode": "16626",
      "Phone": "1140001798",
      "Whatsapp": "11983934122",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1775,
      "Name": "Julyenne Proença Mesquita Decrescenzo\t",
      "Email": "contadora.julyenne@gmail.com",
      "CustomerCode": "38593",
      "Phone": "11",
      "Whatsapp": "11982643319",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1776,
      "Name": "Ivo Antonio de Lima",
      "Email": "lima_contabil@uol.com.br",
      "CustomerCode": "16371",
      "Phone": "1120745484",
      "Whatsapp": "11999938138",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1777,
      "Name": "Contabilidade Novo Horizonte",
      "Email": "anderson@contabilidadenovohorizonte.com.br",
      "CustomerCode": "64660",
      "Phone": "1239071451",
      "Whatsapp": "12989704105",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1779,
      "Name": "CIPP Contábil",
      "Email": "cipp.contabil@gmail.com",
      "CustomerCode": "16577",
      "Phone": "1137315384",
      "Whatsapp": "11945413172",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1780,
      "Name": "Lumi Contabilidade e Consultoria Empresarial Ltda",
      "Email": "miller@lumicontabilidade.com",
      "CustomerCode": "65110",
      "Phone": "3532651504",
      "Whatsapp": "35999751709",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1781,
      "Name": "Expertisa Assessoria Contábil Ltda",
      "Email": "michael.vin@expertisa.com.br",
      "CustomerCode": "19016",
      "Phone": "1176983704",
      "Whatsapp": "21979185858",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1782,
      "Name": "Real Asssessoria Contábil e Empresarial",
      "Email": "selma.aa@terra.com.br",
      "CustomerCode": "16513",
      "Phone": "",
      "Whatsapp": "11996900702",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1783,
      "Name": "Mariana Ferreira Valente",
      "Email": "mariana.valente@outlook.com",
      "CustomerCode": "38590",
      "Phone": "11",
      "Whatsapp": "11981024268",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1784,
      "Name": "Wilson Luiz Feliciano",
      "Email": "orgfeliciano@gmail.com",
      "CustomerCode": "65111",
      "Phone": "1935815552",
      "Whatsapp": "19998416440",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1785,
      "Name": "Paula Ananias Ribeiro",
      "Email": "p.ananias.ribeiro@gmail.com",
      "CustomerCode": "19017",
      "Phone": "11",
      "Whatsapp": "11997469241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1787,
      "Name": "Anderson Luis da Silva",
      "Email": "andersonx@zipmail.com.br",
      "CustomerCode": "65113",
      "Phone": "62",
      "Whatsapp": "62981321688",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1788,
      "Name": "Fernando Celso Rizzo Junior Sociedade Individual",
      "Email": "daniela@mecanocontabil.com.br",
      "CustomerCode": "65112",
      "Phone": "163373313",
      "Whatsapp": null,
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1789,
      "Name": "Cesar Barbosa Costa",
      "Email": "cesarbarbosacosta@yahoo.com.br",
      "CustomerCode": "18685",
      "Phone": "1120529991",
      "Whatsapp": "11975293711",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1790,
      "Name": "Anita Assessoria Contábil e Administradora",
      "Email": "denis.maximo@anitacontabil.com.br",
      "CustomerCode": "64927",
      "Phone": "3135371210",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1791,
      "Name": "Helio Oscar Guimaraes Magri",
      "Email": "hmagri@gmail.com",
      "CustomerCode": "18245",
      "Phone": "1126533168",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1792,
      "Name": "Brombay Contabilidade Ltda",
      "Email": "tiago@brombaycontabilidade.com.br",
      "CustomerCode": "19019",
      "Phone": "1129350855",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1793,
      "Name": "Fazz Suporte Ltda",
      "Email": "suporte@fazzconsulting.com.br",
      "CustomerCode": "19020",
      "Phone": "1150951571",
      "Whatsapp": "11992774354",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1794,
      "Name": "Comelli Transportes Ltda",
      "Email": "lussandra@stegia.com.br",
      "CustomerCode": "65114",
      "Phone": "6436220649",
      "Whatsapp": "12996105381",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1795,
      "Name": "Lucas Areco Maronna",
      "Email": "lucas@maronna.com.br",
      "CustomerCode": "16975",
      "Phone": "1155796616",
      "Whatsapp": "11947549950",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1796,
      "Name": "Liderança em Assessoria Ltda",
      "Email": "junior.lideranca@gmail.com",
      "CustomerCode": "37119",
      "Phone": "1147893313",
      "Whatsapp": "11940050970",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1797,
      "Name": "Paulo Roberto Barros Dutra",
      "Email": "prbdutraadv@uol.com.br",
      "CustomerCode": "18316",
      "Phone": "11",
      "Whatsapp": "11983112999",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1798,
      "Name": "Advocacia Bushatsky",
      "Email": "carlos.baldini@bushatsky.com.br",
      "CustomerCode": "19021",
      "Phone": "1138613230",
      "Whatsapp": "11989985077",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1799,
      "Name": "Automar Patrimonial LTDA",
      "Email": "marcia.ferreira@gruponogueiralins.com.br",
      "CustomerCode": "64615",
      "Phone": "1832651719",
      "Whatsapp": "18998219474",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1800,
      "Name": "Arlene Lobo dos Santos Esteves",
      "Email": "aesteves@savixx.com.br",
      "CustomerCode": "17774",
      "Phone": "1150539682",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1801,
      "Name": "Planservi Engenharia Ltda",
      "Email": "marcio.assis@planservi.com.br",
      "CustomerCode": "65115",
      "Phone": "1133041507",
      "Whatsapp": "11995896026",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1802,
      "Name": "Cleusa Ferreira Santos",
      "Email": "cleferreiracontabilidade@gmail.com",
      "CustomerCode": "18556",
      "Phone": "1132095774",
      "Whatsapp": "11970115923",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1803,
      "Name": "Roberta Coimbra",
      "Email": "robertacoimbra10@gmail.com",
      "CustomerCode": "65116",
      "Phone": "62",
      "Whatsapp": "62982405890",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1804,
      "Name": "Cam Salvadeo Serviços de Escritório Ltda",
      "Email": "contabil@escritorioazteca.com.br",
      "CustomerCode": "64387",
      "Phone": "143322642",
      "Whatsapp": "14996062938",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1805,
      "Name": "Marco Antonio Silva Machado",
      "Email": "marcoantoniomachado796@gmail.com",
      "CustomerCode": "65117",
      "Phone": "1434711891",
      "Whatsapp": "14981574632",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1806,
      "Name": "Laborinfor Organização Contábil Ltda",
      "Email": "dp@laborinfor.com.br",
      "CustomerCode": "35140",
      "Phone": "1124123427",
      "Whatsapp": "11999758595",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1808,
      "Name": "Senel Organização Contábil S/S",
      "Email": "legalizacao@senel.com.br",
      "CustomerCode": "18441",
      "Phone": "1150988977",
      "Whatsapp": "11984924593",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1809,
      "Name": "José Antonio Chieregatto",
      "Email": "mzcont@uol.com.br",
      "CustomerCode": "18122",
      "Phone": "1142353155",
      "Whatsapp": "11958777399",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1810,
      "Name": "Victória Noel Gonçalves Magalhães de Oliveira",
      "Email": "victorianoel.oliveira@gmail.com",
      "CustomerCode": "19022",
      "Phone": "2168547828",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1811,
      "Name": "Intelitax Inteligência Tributária Ltda",
      "Email": "jaiane@intelitax.com.br",
      "CustomerCode": "19023",
      "Phone": "1132561756",
      "Whatsapp": "11986893789",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1812,
      "Name": "Luís Augusto Baron Medeiros",
      "Email": "luis.baron@praxia.com.br",
      "CustomerCode": "36247",
      "Phone": "1126267938",
      "Whatsapp": "11968603404",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1813,
      "Name": "Marcio Neilor Silva",
      "Email": "contaneilor@gmail.com",
      "CustomerCode": "65118",
      "Phone": "1596326356",
      "Whatsapp": "15996143029",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1814,
      "Name": "Daniel Augusto Negri",
      "Email": "assessoria.legal@mapah.com.br",
      "CustomerCode": "65119",
      "Phone": "6239544001",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1815,
      "Name": "Praxia Serviços Contábeis",
      "Email": "simony.chang@praxia.com.br",
      "CustomerCode": "19024",
      "Phone": "1126267983",
      "Whatsapp": "11971521901",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1816,
      "Name": "Edmar Rosa Eduardo",
      "Email": "societario@sisbs.com.br",
      "CustomerCode": "65120",
      "Phone": "1434063040",
      "Whatsapp": "14998356088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1817,
      "Name": "José Geraldo Vicente Pedro",
      "Email": "vicente_reconta@jhotmail.com",
      "CustomerCode": "63889",
      "Phone": "1437322399",
      "Whatsapp": "14997025934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1818,
      "Name": "M&M Assessoria Contábil",
      "Email": "mmassesscontabil@terra.com.br",
      "CustomerCode": "16063",
      "Phone": "1159715044",
      "Whatsapp": "11964113139",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1819,
      "Name": "Coan Escritório Técnico Contábil S/C Ltda",
      "Email": "fabio@coancontabil.com.br",
      "CustomerCode": "16781",
      "Phone": "1139662820",
      "Whatsapp": "11982515110",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1821,
      "Name": "Martinatti Assessoria Contábil Ltda",
      "Email": "h_mart_in@hotmail.com",
      "CustomerCode": "17332",
      "Phone": "1126064898",
      "Whatsapp": "11996121500",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1822,
      "Name": "Nova Skala Contabilidade",
      "Email": "israel@ekmservicos.com.br",
      "CustomerCode": "17780",
      "Phone": "1139231650",
      "Whatsapp": "1199424278",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1823,
      "Name": "Rodrigo Estevam",
      "Email": "rodrigo@habitarecontabilidade.com.br",
      "CustomerCode": "62059",
      "Phone": "1938356984",
      "Whatsapp": "11991001816",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1824,
      "Name": "Eder Carlos Severiano",
      "Email": "edencont@gmail.com",
      "CustomerCode": "62219",
      "Phone": "143301310",
      "Whatsapp": "14998490598",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1825,
      "Name": "Carla Regina Cardoso Ferreira",
      "Email": "carlarcf@ig.com.br",
      "CustomerCode": "18571 ",
      "Phone": "1122760258",
      "Whatsapp": "11981146429",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1826,
      "Name": "Portal Assessoria e Serviços",
      "Email": "assessoriaportal@hotmail.com",
      "CustomerCode": "64336",
      "Phone": "1334191819",
      "Whatsapp": "13981391965",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1827,
      "Name": "Indústria e Comércio de Colchões Castor Ltda",
      "Email": "juridico@castor.ind.br",
      "CustomerCode": "64485 ",
      "Phone": "1433022855",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1828,
      "Name": "Carmino Zeuli",
      "Email": "carminozeuli@tsicont.com.br",
      "CustomerCode": "19025",
      "Phone": "1155129813",
      "Whatsapp": "11993916336",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1829,
      "Name": "Escritório Contábil di Lorenzi Ltda",
      "Email": "escritoriodilorenzi@hotmail.com",
      "CustomerCode": "64471",
      "Phone": "1231531765",
      "Whatsapp": "11996329972",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1831,
      "Name": "Fernando Ruiz Ribeiro",
      "Email": "fernando@ruizconsultoria.com.br",
      "CustomerCode": "64600",
      "Phone": "6436321501",
      "Whatsapp": "64999025227",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1834,
      "Name": "LBHZ Serviços Contábeis Ltda",
      "Email": "rodrigo@lbhz.com.br",
      "CustomerCode": "65077",
      "Phone": "31",
      "Whatsapp": "31989652322",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1835,
      "Name": "Talita Ferreira Dionizio",
      "Email": "dionizioassessoriaambiental@gmail.com",
      "CustomerCode": "64608",
      "Phone": "1733239897",
      "Whatsapp": "17997779166",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1836,
      "Name": "Arthur Augustus Pereira de Almeida",
      "Email": "fortecontabilregistro@gmail.com",
      "CustomerCode": "65074",
      "Phone": "13",
      "Whatsapp": "13999784509",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1837,
      "Name": "Marcio Leme de Paula",
      "Email": "marcioleme2910@hotmail.com",
      "CustomerCode": "64622",
      "Phone": "1733233666",
      "Whatsapp": "17981611958",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1838,
      "Name": "Escritório Contábil Monteiro Lobato - Almaraz Serviços Contábeis Ltda",
      "Email": "jamile@ecml.com.br",
      "CustomerCode": "38481",
      "Phone": "1143302255",
      "Whatsapp": "11967781525",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1839,
      "Name": "Exito Soluções Contábeis e Empresariais Ltda",
      "Email": "valkiria@exitocontabil.com",
      "CustomerCode": "37388",
      "Phone": "1141955725",
      "Whatsapp": "11970938991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1841,
      "Name": "Siem - Serviços e Informações Empresariais Simples",
      "Email": "siem@siem.srv.br",
      "CustomerCode": "64831 ",
      "Phone": "1434514724",
      "Whatsapp": "1496216238",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1842,
      "Name": "Estilo Contabilidade Ltda EPP",
      "Email": "estilo@estilocontabilidade.com.br",
      "CustomerCode": "64885 ",
      "Phone": "1938713399",
      "Whatsapp": "1982005790",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1844,
      "Name": "Rafael Rocino da Fonseca",
      "Email": "fonseca.contabil@terra.com.br",
      "CustomerCode": "18710",
      "Phone": "11996754366",
      "Whatsapp": "11996754366",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1846,
      "Name": "Reichenbach Equipamentos Indústria  e Comércio Ltda",
      "Email": "vagner@reichenbach.com.br",
      "CustomerCode": "37463",
      "Phone": "1141815054",
      "Whatsapp": "11995370720",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1847,
      "Name": "Flávio Ikemati Bonafe",
      "Email": "fib_at@hotmail.com",
      "CustomerCode": "19018 ",
      "Phone": "1143336182",
      "Whatsapp": "11943336182",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1848,
      "Name": "Valente Contabilidade",
      "Email": "jorgevalente@terra.com.br",
      "CustomerCode": "17026",
      "Phone": "1128969855",
      "Whatsapp": "1192599894",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1849,
      "Name": "Contabilidade Vitória Ltda",
      "Email": "contavit@terra.com.br",
      "CustomerCode": "16348",
      "Phone": "1139268261",
      "Whatsapp": "11989476450",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1850,
      "Name": "Eduardo Bulhões da Silva",
      "Email": "bulhoes77@hotmail.com",
      "CustomerCode": "15246",
      "Phone": "1122942917",
      "Whatsapp": "11995463818",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1852,
      "Name": "M V F Cunha Projetar Contabilidade",
      "Email": "consultoria@projetarcontabilidade.com.br",
      "CustomerCode": "65121",
      "Phone": "3137861847",
      "Whatsapp": "31982931382",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1853,
      "Name": "Contatex Assessoria e Planejamento Empresarial",
      "Email": "alexandre@contatex.com.br",
      "CustomerCode": "17244",
      "Phone": "11",
      "Whatsapp": "11973808917",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1854,
      "Name": "Amigo Connecting Ltda",
      "Email": "nyder.santos@amigoapp.com.br",
      "CustomerCode": "19026",
      "Phone": "11",
      "Whatsapp": "1183730542",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1855,
      "Name": "Asprino E Figueiredo Sociedade De Advogados",
      "Email": "marianna@aeflaw.com.br",
      "CustomerCode": "19027",
      "Phone": "1130648711",
      "Whatsapp": "11996564889",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1856,
      "Name": "Guia Contábil e Assessoria Tributária Ltda",
      "Email": "nfse@guiacontabil.net",
      "CustomerCode": "19028",
      "Phone": "1147874907",
      "Whatsapp": "11991221991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1857,
      "Name": "Anderson Henrique Resende",
      "Email": "a.resende@adv.oabsp.org.br",
      "CustomerCode": "19029",
      "Phone": "11",
      "Whatsapp": "11971139330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1858,
      "Name": "Dalvanete Fausto da Silva de Oliveira",
      "Email": "meire_mey@hotmail.com",
      "CustomerCode": "19003",
      "Phone": "1158741278",
      "Whatsapp": "11954307597",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1859,
      "Name": "Wilson Roberto da Silva Jóia",
      "Email": "wilsonjoia@hotmail.com",
      "CustomerCode": "18963",
      "Phone": "1136372949",
      "Whatsapp": "11992717634",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1860,
      "Name": "Brasnippon Escritório Contábil SS Ltda",
      "Email": "newtonohe@brasnippon.com.br",
      "CustomerCode": "18964",
      "Phone": "1131116316",
      "Whatsapp": "11999929537",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1862,
      "Name": "Renova BPO Assessoria e Consultoria Contábil e Financeira Ltda",
      "Email": "tadeu.matias@renovabpo.com.br",
      "CustomerCode": "19030",
      "Phone": "1139230385",
      "Whatsapp": "11940698843",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1863,
      "Name": "Giselli Baraldi Vespúcio",
      "Email": "gisellibaraldi1@gmail.com",
      "CustomerCode": "19031",
      "Phone": "",
      "Whatsapp": "11984741328",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1864,
      "Name": "Organização Contábil H. Advice Eireli Me",
      "Email": "leandro.boiani@terra.com.br",
      "CustomerCode": "18208",
      "Phone": "1138641708",
      "Whatsapp": "11995919855",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1865,
      "Name": "Rodolfo da Paixão Silva Rogério",
      "Email": "rodolfo@contabilrogerio.com",
      "CustomerCode": "19032",
      "Phone": "1139769414",
      "Whatsapp": "1172454638",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1866,
      "Name": "Álamo Engenharia S/A",
      "Email": "claudiamonteiro@alamoengenharia.com.br",
      "CustomerCode": "64289",
      "Phone": "1134741034",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1867,
      "Name": "Open Vista Brasil Gestão de Investimentos Ltda",
      "Email": "juridico@sommainvestimentos.com.br",
      "CustomerCode": "18976",
      "Phone": "4830371028",
      "Whatsapp": "4891751597",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1868,
      "Name": "Contabras Contabilidade Brasileira Auditoria e Participações Ltda",
      "Email": "cezar@contabras.com.br",
      "CustomerCode": "18300",
      "Phone": "1130622388",
      "Whatsapp": "11942237454",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1869,
      "Name": "Camila Bandeira Bonfim",
      "Email": "camila@bandeiracont.com.br",
      "CustomerCode": "18688",
      "Phone": "11",
      "Whatsapp": "11998550488",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1870,
      "Name": "Osmira Pereira Thenório",
      "Email": "athenorio@gmail.com",
      "CustomerCode": "17279",
      "Phone": "1127483677",
      "Whatsapp": "11980929273",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1871,
      "Name": "M2 Contábil Ltda",
      "Email": "tais@m2contabil.com.br ",
      "CustomerCode": "19033",
      "Phone": "1132214027",
      "Whatsapp": "11997333292",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1872,
      "Name": "Erasmo Cesar de Lana",
      "Email": "vancontabilidadedp@gmail.com",
      "CustomerCode": "15428",
      "Phone": "1155246824",
      "Whatsapp": "11965234699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1873,
      "Name": "Ivan Salustiano da Silva",
      "Email": "contato@atoscontabilidaderj.com",
      "CustomerCode": "65122",
      "Phone": "2126993884",
      "Whatsapp": "21965651865",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1874,
      "Name": "Prohabil Serviços Contábeis Ltda",
      "Email": "eduardo.leal@prohabil.com.br",
      "CustomerCode": "18007",
      "Phone": "1143293129",
      "Whatsapp": "11997248489",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1875,
      "Name": "Solmar Assessoria Contábil e Fiscal Ltda",
      "Email": "solmar@solmarcontabil.com.br",
      "CustomerCode": "18975",
      "Phone": "11",
      "Whatsapp": "11996059807",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1876,
      "Name": "Magayver Batista Alves",
      "Email": "cristalcontabil@hotmail.com",
      "CustomerCode": "65123",
      "Phone": "6230877611",
      "Whatsapp": "62992041154",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1877,
      "Name": "Advance Organização e Assessoria Empresarial Ltda",
      "Email": "adm@contabiladvance.com.br",
      "CustomerCode": "36128",
      "Phone": "1126205910",
      "Whatsapp": "11961773685",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1878,
      "Name": "Sonia M S Assaad Comunicação & Serviços LTDA",
      "Email": "tributario@saconsultoria.net.br",
      "CustomerCode": "19034",
      "Phone": "1130329900",
      "Whatsapp": "11997578171",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1879,
      "Name": "Piffer Contabilidade e Assessoria Ltda",
      "Email": "fabiano@piffernet.com.br",
      "CustomerCode": "19006",
      "Phone": "1122942333",
      "Whatsapp": "11995094737",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1880,
      "Name": "Carlos Alberto Antonio de Souza",
      "Email": "uniaofederalcontabil@terra.com.br",
      "CustomerCode": "65124",
      "Phone": "4130921114",
      "Whatsapp": "41991461598",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1881,
      "Name": "Ceae Escritório Contábil",
      "Email": "leonel@ceae.com.br",
      "CustomerCode": "19035",
      "Phone": "1131075541",
      "Whatsapp": "11983697244",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1882,
      "Name": "Plurioffice Assessores Contábeis e Tributários",
      "Email": "eduardo.oliveira@plurioffice.com.br",
      "CustomerCode": "62054",
      "Phone": "1921154700",
      "Whatsapp": "1978043078",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1883,
      "Name": "Aderço Lopes",
      "Email": "lopes.astec1948@gmail.com",
      "CustomerCode": "15499",
      "Phone": "1122976984",
      "Whatsapp": "11981502375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1884,
      "Name": "2 Emmes Assessoria Contábil S/C Ltda",
      "Email": "2emmes@2emmes.com.br",
      "CustomerCode": "15889",
      "Phone": "1137910361",
      "Whatsapp": "11999924554",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1885,
      "Name": "Sonia Aparecida Camargo Prina",
      "Email": "soniacprina@gmail.com",
      "CustomerCode": "19036",
      "Phone": "1136116100",
      "Whatsapp": "11984749056",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1886,
      "Name": "Samuel Lima da Silva Júnior",
      "Email": "max.sam@uol.com.br",
      "CustomerCode": "XZI26",
      "Phone": "1129567025",
      "Whatsapp": "11986443099",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1887,
      "Name": "Max Leal Documentação Ltda",
      "Email": "wilson@maxleal.com.br",
      "CustomerCode": "16161",
      "Phone": "1150715444",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1888,
      "Name": "Rodrigo Carone",
      "Email": "juridico@apoiogerencial.com.br",
      "CustomerCode": "18789",
      "Phone": "1133411771",
      "Whatsapp": "11981516013",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1889,
      "Name": "Dionisio Florentino Pimentel",
      "Email": "dionisioflorentinopimentel@gmail.com",
      "CustomerCode": "15686",
      "Phone": "1137298954",
      "Whatsapp": "11995145122",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1890,
      "Name": "Huntcontábil Consultoria, Assessoria e Serviços Contábeis Ltda",
      "Email": "contabil.damiao@gmail.com",
      "CustomerCode": "18980",
      "Phone": "1126593863",
      "Whatsapp": "11966595509",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1891,
      "Name": "Sul America Contábil Comercial e Administradora Eireli ",
      "Email": "sulacont@uol.com.br",
      "CustomerCode": "15180",
      "Phone": "1126185822",
      "Whatsapp": "11996566831",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1892,
      "Name": "Contag Contábil Taguaí",
      "Email": "helenaromano20@hotmail.com",
      "CustomerCode": "61864",
      "Phone": "1433861412",
      "Whatsapp": "14998004456",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1893,
      "Name": "Café do Esporte Comércio de Produtos Alimentícios Ltda",
      "Email": "contato@cafeesporte.com.br",
      "CustomerCode": "65125",
      "Phone": "1145214059",
      "Whatsapp": "19997705566",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1894,
      "Name": "Cezar Henrique da Silva Oliveira",
      "Email": "cezar_henrique@hotmail.com",
      "CustomerCode": "65126",
      "Phone": "1535210755",
      "Whatsapp": "15991672675",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1895,
      "Name": "José Ricardo Vizaco",
      "Email": "ricardovizaco@gmail.com",
      "CustomerCode": "19037",
      "Phone": "11",
      "Whatsapp": "11974082218",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1896,
      "Name": "Douglas Fonseca Barbudo",
      "Email": "douglasbarbudo.inovare@gmail.com",
      "CustomerCode": "65127",
      "Phone": "3532671027",
      "Whatsapp": "35998201210",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1897,
      "Name": "Contecci Contabilidade",
      "Email": "irene@contecci.com.br",
      "CustomerCode": "61136",
      "Phone": "1334214330",
      "Whatsapp": "13997031668",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1900,
      "Name": "Domingos de Paula",
      "Email": "protocolo.dp7@gmail.com",
      "CustomerCode": "63976",
      "Phone": "18",
      "Whatsapp": "1897535120",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1901,
      "Name": "Fabricio Oliveira Fernandes",
      "Email": "fabricioadv.franca@gmail.com",
      "CustomerCode": "65128",
      "Phone": "16",
      "Whatsapp": "16981519098",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1906,
      "Name": "J.Z.S. Assessoria Contábil S/C Ltda",
      "Email": "solange.jzs@uol.com.br",
      "CustomerCode": "19038",
      "Phone": "1155391410",
      "Whatsapp": "11972171513",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1908,
      "Name": "Iraí Contabilidade S/S",
      "Email": "atendimento@iraicontabilidade.com.br",
      "CustomerCode": "64545",
      "Phone": "3533324545",
      "Whatsapp": "35988754548",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1909,
      "Name": "Andre de Lucca Jobst",
      "Email": "andre@matrixcontabil.com.br",
      "CustomerCode": "35118",
      "Phone": "1124477818",
      "Whatsapp": "11992873171",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1910,
      "Name": "Ricardo Fabreti Lima",
      "Email": "ricardofabretilima@gmail.com",
      "CustomerCode": "17409",
      "Phone": "1194727229",
      "Whatsapp": "11947272291",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1911,
      "Name": "Marilene Santana dos Santos Garcia",
      "Email": "marilenegarc@uol.com.br",
      "CustomerCode": "17739",
      "Phone": "1130631078",
      "Whatsapp": "11991127445",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1912,
      "Name": "Pedro Seiko Gushiken",
      "Email": "gm.juridico2@gmail.com",
      "CustomerCode": "19229",
      "Phone": "",
      "Whatsapp": "11983197590",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1913,
      "Name": "Marcont Serviços S/S Ltda ME",
      "Email": "legalizacao@marcontcontabil.com.br",
      "CustomerCode": "18121",
      "Phone": "11",
      "Whatsapp": "11977241314",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1914,
      "Name": "Silvestre Sória Júnior",
      "Email": "advsoria@mdbrasil.com.br",
      "CustomerCode": "61998",
      "Phone": "1733435044",
      "Whatsapp": "17981342455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1917,
      "Name": "Regina Malheiro",
      "Email": "regina.maquee@hotmail.com",
      "CustomerCode": "17245",
      "Phone": "1132801175",
      "Whatsapp": "11991887380",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1918,
      "Name": "Fabiano Rodrigues Oliveira",
      "Email": "fabiano.oliveiraimoveis@gmail.com",
      "CustomerCode": "19046",
      "Phone": "1194985167",
      "Whatsapp": "11994985167",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1919,
      "Name": "2 - Augusto Medeiros",
      "Email": "cimil67358@pahed.com",
      "CustomerCode": "SFMRAC",
      "Phone": "1132589947",
      "Whatsapp": "11997853368",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1920,
      "Name": "Tiago Tonatto",
      "Email": "tiago@tonattocontabilidade.com.br",
      "CustomerCode": "65129",
      "Phone": "5135884045",
      "Whatsapp": "51993114254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1921,
      "Name": "Luiz Henrique Leandro da Silva Junior",
      "Email": "luizlhl@gmail.com",
      "CustomerCode": "19039",
      "Phone": "1197416347",
      "Whatsapp": "11974163477",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1922,
      "Name": "Adriano Carvalho de Lima",
      "Email": "adrianolima1401@gmail.com",
      "CustomerCode": "37464",
      "Phone": "1196539281",
      "Whatsapp": "11965392816",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1923,
      "Name": "Systems Solution Serviços de Sistemas e Redes Ltda",
      "Email": "sdias@systemsol.com.br",
      "CustomerCode": "64989",
      "Phone": "1137040454",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1924,
      "Name": "David Rocha Veiga",
      "Email": "denis.araujo@respectconsultoria.com.br",
      "CustomerCode": "19042",
      "Phone": "1198548505",
      "Whatsapp": "11985485057",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1925,
      "Name": "Janio Silva Soares",
      "Email": "diretorwww@gmail.com",
      "CustomerCode": "19284",
      "Phone": "1127294352",
      "Whatsapp": "11985046990",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1926,
      "Name": "Marli Pereira de Oliveira Satto",
      "Email": "marlisatto1@gmail.com",
      "CustomerCode": "62208",
      "Phone": "1338212896",
      "Whatsapp": "13997779435",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1927,
      "Name": "Natanael Vicente da Costa",
      "Email": "cim192339@gmail.com",
      "CustomerCode": "19041",
      "Phone": "1129404731",
      "Whatsapp": "11970292804",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1930,
      "Name": "Orcival Pereira da Silva Neto",
      "Email": "contato@contabilidaderiobranco.com.br",
      "CustomerCode": "19044",
      "Phone": "1936234144",
      "Whatsapp": "19992881987",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1931,
      "Name": "Carlos Alberto da Silva",
      "Email": "kabosam@hotmail.com",
      "CustomerCode": "38126",
      "Phone": "1123767569",
      "Whatsapp": "11973342283",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1932,
      "Name": "Cecon Contabilidade Ltda",
      "Email": "cilenecontadora@hotmail.com",
      "CustomerCode": "19040",
      "Phone": "1120913359",
      "Whatsapp": "11991799094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1933,
      "Name": "Marlene Boscariol",
      "Email": "boscarioladv@yahoo.com.br",
      "CustomerCode": "19047",
      "Phone": "1196069397",
      "Whatsapp": "11960693978",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1934,
      "Name": "Felipe Rodrigues",
      "Email": "feliperdrgs@hotmail.com",
      "CustomerCode": "19043",
      "Phone": "1150528059",
      "Whatsapp": "11950528059",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1935,
      "Name": "Luis Carlos Gomes Gomes de Sá",
      "Email": "societario@dueamicicontabilidade.com.br",
      "CustomerCode": "17689",
      "Phone": "1134732235",
      "Whatsapp": "11982566490",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1938,
      "Name": "Antonia Lucivanda Aldes de Sousa",
      "Email": "tonia.alves.contadora@gmail.com",
      "CustomerCode": "EYC5JW",
      "Phone": "1149652461",
      "Whatsapp": "11986465616",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1943,
      "Name": "Consulter Consultoria",
      "Email": "michele@consultersul.com.br",
      "CustomerCode": "64790",
      "Phone": "4130147744",
      "Whatsapp": "41998370261",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1944,
      "Name": "JBS Serviços Admistrativos, Contábeis e  Auditoria EIRELI",
      "Email": "jbsservicosadm@gmail.com",
      "CustomerCode": "19045",
      "Phone": "1180824752",
      "Whatsapp": "11980824752",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1945,
      "Name": "PVB Lopes ME",
      "Email": "pvblopes@hotmail.com.br",
      "CustomerCode": "17411",
      "Phone": "1122756792",
      "Whatsapp": "11948880215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1946,
      "Name": "Ildark Maria de Araujo Barbosa",
      "Email": "ildarkcontabil@gmail.com",
      "CustomerCode": "MS2YBA",
      "Phone": "1194731513",
      "Whatsapp": "11947315134",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1947,
      "Name": "Adriano Roberto da Silva",
      "Email": "rs_adriano@hotmail.com",
      "CustomerCode": "TD1JHT",
      "Phone": "1198625609",
      "Whatsapp": "11986256092",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1948,
      "Name": "Eikiti kato",
      "Email": "eikiti@terra.com.br",
      "CustomerCode": "38380",
      "Phone": "1143524207",
      "Whatsapp": "11993373642",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1949,
      "Name": "Pontes & Santos Contabilidade",
      "Email": "pontes.contabil.assessoria@gmail.com",
      "CustomerCode": "65130",
      "Phone": "1199906340",
      "Whatsapp": "11999063402",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1950,
      "Name": "Vera Lúcia Cardoso de Souza",
      "Email": "verluci@uol.com.br",
      "CustomerCode": "15082",
      "Phone": "1198780937",
      "Whatsapp": "1132510177",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1951,
      "Name": "4 - Gustavo Gorzilo Flor",
      "Email": "gustavogflor@gmail.com",
      "CustomerCode": "U8SL2R",
      "Phone": "1199429538",
      "Whatsapp": "11994295385",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1954,
      "Name": "Willian Magalhães",
      "Email": "williamcontabilidade@hotmail.com",
      "CustomerCode": "16808",
      "Phone": "1127419640",
      "Whatsapp": "11996266192",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1955,
      "Name": "Priscila Rodrigues PIeretti",
      "Email": "ppieretti@yahoo.com.br",
      "CustomerCode": "38595",
      "Phone": "1126692836",
      "Whatsapp": "11996096564",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1956,
      "Name": "Lais Fernanda Marques Germiniani",
      "Email": "contabilidadecconsultoria@gmail.com",
      "CustomerCode": "37294",
      "Phone": "1151999244",
      "Whatsapp": "11985163275",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1957,
      "Name": "Marcos Alexandre do Nascimento Zogno",
      "Email": "zogno@lzcapital.com.br",
      "CustomerCode": "19048",
      "Phone": "1195316919",
      "Whatsapp": "11953169196",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1960,
      "Name": "Josinaldo Machado de Almeida",
      "Email": "jmamachado@aasp.org.br",
      "CustomerCode": "35189",
      "Phone": "1128343008",
      "Whatsapp": "11971198663",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1961,
      "Name": "Clovis Assis de Lima",
      "Email": "cllassis.cadl@gmail.com",
      "CustomerCode": "38492",
      "Phone": "1194751666",
      "Whatsapp": "11947516663",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1962,
      "Name": "jaime martins gomes",
      "Email": "gpcontabil@gmail.com",
      "CustomerCode": "Z77PVP",
      "Phone": "1150612320",
      "Whatsapp": "11993201125",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1963,
      "Name": "JFS Contabiliade EIRELI ",
      "Email": "fernando.silva@fenixcon.com.br",
      "CustomerCode": "NZYDU1",
      "Phone": "6734317062",
      "Whatsapp": "67999255807",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1964,
      "Name": "Anderson Pastorelli Castelhani",
      "Email": "pastorelli.anderson@gmail.com",
      "CustomerCode": "17695",
      "Phone": "1491225806",
      "Whatsapp": "14991225806",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1965,
      "Name": "Jaqueline Ursino de Britto",
      "Email": "britto.jaque@gmail.com",
      "CustomerCode": "OM5IKT",
      "Phone": "1199115235",
      "Whatsapp": "11991152356",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1966,
      "Name": "Moises Estevao de Lima",
      "Email": "moises.etec@gmail.com",
      "CustomerCode": "19055",
      "Phone": "1125792036",
      "Whatsapp": "11945223852",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1967,
      "Name": "AT Contabil SS LTDA",
      "Email": "regis@atcontabil.com.br",
      "CustomerCode": "64525",
      "Phone": "1239518357",
      "Whatsapp": "1230420567",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1968,
      "Name": "Clayton Oliveira Santos",
      "Email": "acapelabass@gmail.com",
      "CustomerCode": "MYQYZQ",
      "Phone": "1131508693",
      "Whatsapp": "11987081825",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1969,
      "Name": "FRBG Assessoria Contabil EIRELI",
      "Email": "adm@frbgcontabil.com.br",
      "CustomerCode": "19049",
      "Phone": "1146126542",
      "Whatsapp": "11999690568",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1970,
      "Name": "Glauce Cristina Atalla Pereira",
      "Email": "atallacontabil@gmail.com",
      "CustomerCode": "37256",
      "Phone": "1197688727",
      "Whatsapp": "11976887272",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 1972,
      "Name": "Alexandre Dias",
      "Email": "alexandrerh@terra.com.br",
      "CustomerCode": "15815",
      "Phone": "1121432255",
      "Whatsapp": "11993337444",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1973,
      "Name": "Apoex Administradora de Condomínios",
      "Email": "apoexadm@apoex.com.br",
      "CustomerCode": "19050",
      "Phone": "1143095312",
      "Whatsapp": "11947463833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1974,
      "Name": "Gleydson Dione Da  Silva",
      "Email": "processos@cescont.com.br",
      "CustomerCode": "65131",
      "Phone": "6293071922",
      "Whatsapp": "62993071922",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1976,
      "Name": "Medmep Excelencia em Medicna Personalizada Ltda ",
      "Email": "atendimento@medmep.com",
      "CustomerCode": "XC51T7",
      "Phone": "1121576167",
      "Whatsapp": "11940211030",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1977,
      "Name": "Joselaine Lucineia de Morais Silva",
      "Email": "joselaine.morais2305@gmail.com",
      "CustomerCode": "AIBNR8",
      "Phone": "1199464942",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1978,
      "Name": "Sander de Almeida Miranda",
      "Email": "sander@foccuscontabil.com.br",
      "CustomerCode": "17312",
      "Phone": "1198324161",
      "Whatsapp": "11983241615",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1979,
      "Name": "Yamasegcontabilidade EIRELI",
      "Email": "contabilidade.yamaseg@hotmail.com",
      "CustomerCode": "65132",
      "Phone": "1100000000",
      "Whatsapp": "11956597290",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1980,
      "Name": "Contabil Chenko Ltda",
      "Email": "marciachenko@uol.com.br",
      "CustomerCode": "15927",
      "Phone": "1120561540",
      "Whatsapp": "11988664955",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1981,
      "Name": "Lima Gestão Empresarial LTDA",
      "Email": "felipe@contabilima.com.br",
      "CustomerCode": "YZ7IO8",
      "Phone": "4132360363",
      "Whatsapp": "41992354530",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1984,
      "Name": "Rafael Franco de Souza",
      "Email": "rafael.franco@flcontadores.com.br",
      "CustomerCode": "19051",
      "Phone": "1115526006",
      "Whatsapp": "11915526006",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1985,
      "Name": "Maria da Conceição Gomes da Rocha",
      "Email": "conceicaomaxima@gmail.com",
      "CustomerCode": "16539",
      "Phone": "1166094059",
      "Whatsapp": "11966094059",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1986,
      "Name": "Romualdo Tadeu Caetano Junior",
      "Email": "romualdo.junior29@gmail.com",
      "CustomerCode": "DKBSC2",
      "Phone": "1130168700",
      "Whatsapp": "11996015876",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1988,
      "Name": "Valdirene Regina dos Santos marques ",
      "Email": "valdirene.r.s.marques@hotmail.com",
      "CustomerCode": "OTRBVG",
      "Phone": "1199927741",
      "Whatsapp": "11999277412",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1989,
      "Name": "Marcio Ramez Melhem",
      "Email": "marciormelhem@hotmail.com",
      "CustomerCode": "XZI251",
      "Phone": "1194970263",
      "Whatsapp": "11949702634",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1990,
      "Name": "Morelo Contabilidade",
      "Email": "morgana@morelocontabilidade.com.br",
      "CustomerCode": "T2NUW7",
      "Phone": "1143069793",
      "Whatsapp": "11952983350",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1991,
      "Name": "EV Serviços de Contabilidade",
      "Email": "eliane.oliviera@evservicos.com",
      "CustomerCode": "58MQ74",
      "Phone": "1155716978",
      "Whatsapp": "11930995820",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 1992,
      "Name": "Marcio Luiz de Souza",
      "Email": "mlsouza@mdbrasil.com.br",
      "CustomerCode": "62266",
      "Phone": "1799773591",
      "Whatsapp": "17997735917",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1993,
      "Name": "Papyrus Contabilidade Ltda",
      "Email": "legalizacao@contabilidadepapyrus.com.br",
      "CustomerCode": "64540",
      "Phone": "3132145150",
      "Whatsapp": "31984708851",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1994,
      "Name": "Isaque ",
      "Email": "isaqueantoniolli88@gmail.com",
      "CustomerCode": "19052",
      "Phone": "1111111111",
      "Whatsapp": "11952727580",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1995,
      "Name": "Gerencer Contabilidade LTDA",
      "Email": "legalizacao@gerencer.com.br",
      "CustomerCode": "15712",
      "Phone": "1120664788",
      "Whatsapp": "11992212161",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1996,
      "Name": "John alef Gomes de Brito lemos",
      "Email": "actransporte403@gmail.com",
      "CustomerCode": "O3A3YC",
      "Phone": "1196307308",
      "Whatsapp": "11963073086",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 1997,
      "Name": "Marta de Souza Meira Souza",
      "Email": "contabilidade_meira@hotmail.com",
      "CustomerCode": "36211",
      "Phone": "1192666685",
      "Whatsapp": "11992666685",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 1998,
      "Name": "Lakota Consultoria Empresarial",
      "Email": "clayton@lakotaconsultoria.com.br",
      "CustomerCode": "19053",
      "Phone": "1172093617",
      "Whatsapp": "1172093617",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2001,
      "Name": "Alessandro Gomes dos Santos ",
      "Email": "alessandro.agscontabil@gmail.com",
      "CustomerCode": "65260",
      "Phone": "1238325867",
      "Whatsapp": "12997691528",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2003,
      "Name": "Martins Rangel Garcia Sociedade de Advogados",
      "Email": "vilma@mrgadv.com.br",
      "CustomerCode": "19054",
      "Phone": "1125486689",
      "Whatsapp": "11976746689",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2004,
      "Name": "Applicata Contabilidade",
      "Email": "contatoapplicata@gmail.com",
      "CustomerCode": "65133",
      "Phone": "8398887925",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2005,
      "Name": "Paulo Roberto Ferreira",
      "Email": "paulo.ferreira@mbrconsultores.com.br",
      "CustomerCode": "LSSMGM",
      "Phone": "1196478767",
      "Whatsapp": "11964787673",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2006,
      "Name": "Leandro Messias Alves",
      "Email": "leandroservicos29@gmail.com",
      "CustomerCode": "18151",
      "Phone": "1195221795",
      "Whatsapp": "11952217957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2007,
      "Name": "Luiz carlos do nascimento",
      "Email": "luizleolip@gmail.com",
      "CustomerCode": "23XY7C",
      "Phone": "1194013155",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2008,
      "Name": "Mariana",
      "Email": "gica_phn@hotmail.com",
      "CustomerCode": "5G9HWG",
      "Phone": "1145090347",
      "Whatsapp": "11945090347",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2009,
      "Name": "Daniel Bertulino dos  Santos",
      "Email": "daniel@seacontabil.com",
      "CustomerCode": "18721",
      "Phone": "1126594007",
      "Whatsapp": "11987880379",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2010,
      "Name": "Jose Pereira Costa",
      "Email": "jarimarecontabil@gmail.com",
      "CustomerCode": "15818",
      "Phone": "1155725455",
      "Whatsapp": "11966272788",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2011,
      "Name": "Edvan das graças ",
      "Email": "gavioesdafiel1567@gmail.com",
      "CustomerCode": "DO5MKD",
      "Phone": "957759806",
      "Whatsapp": "11956074814",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2012,
      "Name": "Gleuber Sidnei Castelão",
      "Email": "gleubercastelao@gmail.com",
      "CustomerCode": "61894",
      "Phone": "1832823910",
      "Whatsapp": "18996233662",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2014,
      "Name": "Flaviano Taques Advogados Associados",
      "Email": "financeiro@feo.adv.br",
      "CustomerCode": "8DMRB1",
      "Phone": "6530256703",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2015,
      "Name": "Rayssa Ramos de Souza",
      "Email": "rayssa_ramos@hotmail.com",
      "CustomerCode": "MUTUVL",
      "Phone": "6530256703",
      "Whatsapp": "65999681777",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2016,
      "Name": "Natalina Santos Costa",
      "Email": "ncosta@costaesouzasolucoes.com.br",
      "CustomerCode": "19089",
      "Phone": "1196314412",
      "Whatsapp": "11963144121",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2017,
      "Name": "Organização Contábil Esfinge Eireli",
      "Email": "apoio@contabilesfinge.com.br",
      "CustomerCode": "6AFPVT",
      "Phone": "1125796849",
      "Whatsapp": "11976223340",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2018,
      "Name": "Amaury Nakamichi",
      "Email": "amaurysenpai@gmail.com",
      "CustomerCode": "K8KSR2",
      "Phone": "1124768424",
      "Whatsapp": "11984956921",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2019,
      "Name": "Marco Antonio Da Mota Kanhavalik ",
      "Email": "rastamotoboy31@gmail.com",
      "CustomerCode": "LYQNSE",
      "Phone": "1114237338",
      "Whatsapp": "11914237338",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2020,
      "Name": "Marcilene Regina Manzato",
      "Email": "marcilenemanzato@gmail.com",
      "CustomerCode": "38480",
      "Phone": "1172205638",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2024,
      "Name": "Otavio Luiz da Costa",
      "Email": "otavioluizcosta@hotmail.com",
      "CustomerCode": "R3C0I7",
      "Phone": "1433721857",
      "Whatsapp": "14997979844",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2026,
      "Name": "Letícia Fernanda Lopes",
      "Email": "leka.f.lopes@uol.com.br",
      "CustomerCode": "JR3UTZ",
      "Phone": "1141302764",
      "Whatsapp": "11941302764",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2028,
      "Name": "María José Goncalves lima  Locher ",
      "Email": "marsol771@gmail.com",
      "CustomerCode": "WUAGO6",
      "Phone": "1191110304",
      "Whatsapp": "11911103040",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2032,
      "Name": "Skalete Porto da Silva Brunelli",
      "Email": "skaletebrunelli@gmail.com",
      "CustomerCode": "37465",
      "Phone": "1162982871",
      "Whatsapp": "11962982871",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2033,
      "Name": "Caresia Serviços Contabeis",
      "Email": "caresia@caresia.com",
      "CustomerCode": "15747",
      "Phone": "1129900708",
      "Whatsapp": "11998140255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2034,
      "Name": "Camilla Alves Cordaro Bichara ",
      "Email": "camilla@bicharaadvogados.com.br",
      "CustomerCode": "18124",
      "Phone": "1150833287",
      "Whatsapp": "11981080440",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2036,
      "Name": "KN Assessoria",
      "Email": "kn_assessoria@outlook.com",
      "CustomerCode": "19057",
      "Phone": "1183657785",
      "Whatsapp": "11942247759",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2037,
      "Name": "Milton Akito Tsukamoto",
      "Email": "contabil@yuricontabil.com.br",
      "CustomerCode": "37466",
      "Phone": "1142382453",
      "Whatsapp": "11995623129",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2038,
      "Name": "Org Contábil Sagres LTDA",
      "Email": "org-sagres@uol.com.br",
      "CustomerCode": "15754",
      "Phone": "1155495655",
      "Whatsapp": "11991555880",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2039,
      "Name": "Luiz Cardacci",
      "Email": "cardacci.luiz@gmail.com",
      "CustomerCode": "3HSU37",
      "Phone": "5511913160",
      "Whatsapp": "55119131604",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2040,
      "Name": "New Century",
      "Email": "xiaoyuwang50@gmail.com",
      "CustomerCode": "19077",
      "Phone": "1194856939",
      "Whatsapp": "11948569399",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2041,
      "Name": "Cristina Gomes da Silva",
      "Email": "cris_contadora@hotmail.com",
      "CustomerCode": "19056",
      "Phone": "1194290352",
      "Whatsapp": "11974290352",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2042,
      "Name": "Victor Teste",
      "Email": "rh@hasa.com.br",
      "CustomerCode": "5MFHN7",
      "Phone": "1133333666",
      "Whatsapp": "16540654056",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2044,
      "Name": "Contabify Contabilidade EIRELI ",
      "Email": "cintia.ramos@contabify.com.br",
      "CustomerCode": "37467",
      "Phone": "1149777500",
      "Whatsapp": "11996527548",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2045,
      "Name": "Marcia Aparecida Lopes",
      "Email": "marcialopes222@gmail.com",
      "CustomerCode": "KQIUOI",
      "Phone": "113315246",
      "Whatsapp": "11991352884",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2046,
      "Name": "Simone leite da silva",
      "Email": "mone.otto123@gmail.com",
      "CustomerCode": "0WUDOR",
      "Phone": "1198666080",
      "Whatsapp": "11986660800",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2047,
      "Name": "Castro Assessoria Contábil e Gestão Empresarial Eireli",
      "Email": "financeiro@bygrupocastro.com.br",
      "CustomerCode": "65135",
      "Phone": "5434640021",
      "Whatsapp": "54984059515",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2048,
      "Name": "Adriano barros de araujo",
      "Email": "Adrianobarrorosa@gmeil.com",
      "CustomerCode": "IWEUUN",
      "Phone": "1122178601",
      "Whatsapp": "11951313097",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2050,
      "Name": "Campestre Esquadrias LTDA ",
      "Email": "luiz77zanirato@gmail.com",
      "CustomerCode": "DF9YH9",
      "Phone": "9936391075",
      "Whatsapp": "16982332851",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2051,
      "Name": "Campestre Esquadrias LTDA ",
      "Email": "luiz77zanirato@gmail.com",
      "CustomerCode": "WDV5FY",
      "Phone": "9936391075",
      "Whatsapp": "16982332851",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2052,
      "Name": " L F Indústria e Comércio de Esquadrias Metálicas LTDA ",
      "Email": "adonias44zanirato@gmail.com",
      "CustomerCode": "917Y1F",
      "Phone": "9936391777",
      "Whatsapp": "16991481544",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2054,
      "Name": "Stalden Contabilidade LTDA",
      "Email": "adriano.servelim@staldenconsult.com.br",
      "CustomerCode": "5XSF79",
      "Phone": "1938851777",
      "Whatsapp": "19989424896",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2055,
      "Name": "Luiz Carlos de Jesus Souza ",
      "Email": "luiz40tj@hotmail.com",
      "CustomerCode": "CZ65BQ",
      "Phone": "1150248800",
      "Whatsapp": "11980173760",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2056,
      "Name": "Assist Serviços Administrativo Ltda",
      "Email": "assistcefopes@gmail.com",
      "CustomerCode": "15942",
      "Phone": "1155153149",
      "Whatsapp": "11977947175",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2057,
      "Name": "Elton Milani",
      "Email": "eltonapmilani@gmail.com",
      "CustomerCode": "R0ADEO",
      "Phone": "1799628850",
      "Whatsapp": "17996288506",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2058,
      "Name": "New Contclaro Prime Administração TrinutáriaLTDA",
      "Email": "miqueias.santos@contclaro.com.br",
      "CustomerCode": "37468",
      "Phone": "1139960600",
      "Whatsapp": "11913044134",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2059,
      "Name": "Innovative Business Assessoria Empresarial Ltda",
      "Email": "contato@innovbusiness.biz",
      "CustomerCode": "Q4G8XU",
      "Phone": "1120168317",
      "Whatsapp": "11975333350",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2060,
      "Name": "Contabilidade Canesso Ltda",
      "Email": "societario@locatellisc.com.br",
      "CustomerCode": "65137",
      "Phone": "4230257673",
      "Whatsapp": "43999361287",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2061,
      "Name": "Jandir Butzen",
      "Email": "societario@costazen.com.br",
      "CustomerCode": "65136 ",
      "Phone": "4199103058",
      "Whatsapp": "41991030581",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2062,
      "Name": "V+ Contabilidade LTDA",
      "Email": "analuiza@villela.com.br",
      "CustomerCode": "65138",
      "Phone": "5130298848",
      "Whatsapp": "51981217035",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2063,
      "Name": "M&R Analise Assessoria Contábil  SS LTDA",
      "Email": "pemedeiros@analisecontab.com.br",
      "CustomerCode": "19058",
      "Phone": "1125925898",
      "Whatsapp": "11999780591",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2064,
      "Name": "Andre Jose dos Santos",
      "Email": "ajsanntos@gmail.com",
      "CustomerCode": "17649",
      "Phone": "1147714406",
      "Whatsapp": "11947714406",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2065,
      "Name": "Rogerio Utiaque Viana",
      "Email": "ruv.contabil@hotmail.com",
      "CustomerCode": "16932",
      "Phone": "1185685213",
      "Whatsapp": "11985685213",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2066,
      "Name": "Fabricio Zerbinati",
      "Email": "agzcontabil@gmail.com",
      "CustomerCode": "16337",
      "Phone": "1199495754",
      "Whatsapp": "11994957542",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2067,
      "Name": "Debem Assessoria",
      "Email": "debemassessoria@gmail.com",
      "CustomerCode": "17604",
      "Phone": "1192056913",
      "Whatsapp": "11992056913",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2068,
      "Name": "Flaviane Silva de Faria Rodrigues",
      "Email": "organizacao.mcr@gmail.com",
      "CustomerCode": "19063",
      "Phone": "1143955251",
      "Whatsapp": "11987001569",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2069,
      "Name": "Pamella Cardoso de Araujo",
      "Email": "pamella.pca@gmail.com",
      "CustomerCode": "18587",
      "Phone": "1176295380",
      "Whatsapp": "11976295380",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2070,
      "Name": "Elisabete Olivares",
      "Email": "bete.olivares@gmail.com",
      "CustomerCode": "16520",
      "Phone": "1121152299",
      "Whatsapp": "11992641195",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2071,
      "Name": "Adonias Santiago de Oliveira",
      "Email": "adonias@seculo20assis.com.br",
      "CustomerCode": "64496",
      "Phone": "1834212302",
      "Whatsapp": "18997225472",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2072,
      "Name": "Thiago Santos Reis",
      "Email": "thiagosanrei@gmail.com",
      "CustomerCode": "6W2G0U",
      "Phone": "1147163152",
      "Whatsapp": "11947163152",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2073,
      "Name": "Conexxa Assessoria Contabil LTDA",
      "Email": "gilberto.silva@connexacontabil.com.br",
      "CustomerCode": "37469",
      "Phone": "1199574265",
      "Whatsapp": "11995742657",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2074,
      "Name": "LMC Contabilidade e Assessoria EmpresarialL LTDA",
      "Email": "legal@lmccontabil.com.br",
      "CustomerCode": "HOILHE",
      "Phone": "1142320322",
      "Whatsapp": "11911009583",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2075,
      "Name": "Patrícia Vale Medeiros de Sena Souza",
      "Email": "pvmssouza15@gmail.com",
      "CustomerCode": "15047",
      "Phone": "1197156149",
      "Whatsapp": "11971561499",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2076,
      "Name": "Diego Rafael da Silva",
      "Email": "administrativo@drsolucoesempresariais.com.br",
      "CustomerCode": "65139",
      "Phone": "8197655423",
      "Whatsapp": "81997655423",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2077,
      "Name": "Valdir Pinheiro",
      "Email": "valdir@contabilidadepinheiro.com.br",
      "CustomerCode": "KRDJYK",
      "Phone": "1935462929",
      "Whatsapp": "19997959743",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2078,
      "Name": "Elizabete Aparecida de Andrade Ferreira Amario",
      "Email": "elizabete_amario@hotmail.com",
      "CustomerCode": "65142",
      "Phone": "1999610886",
      "Whatsapp": "19996108861",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2079,
      "Name": "innovazione CONTABILE SOLUCOES EMPRESARIAIS LTDA",
      "Email": "mauricio.silva@icontabile.com",
      "CustomerCode": "8GZ0CF",
      "Phone": "1165667064",
      "Whatsapp": "11967253570",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2080,
      "Name": "Cauã Miller",
      "Email": "caua.millersantos@gmail.com",
      "CustomerCode": "GE4NGQ",
      "Phone": "1299134860",
      "Whatsapp": "12991348609",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2081,
      "Name": "Gilberto Junior",
      "Email": "contabil.gilbertojr@gmail.com",
      "CustomerCode": "19059",
      "Phone": "1194855258",
      "Whatsapp": "11948552587",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2082,
      "Name": "Pedro Cantarino",
      "Email": "pedro.cantarino@gmail.com",
      "CustomerCode": "65140",
      "Phone": "2180114908",
      "Whatsapp": "21981248634",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2084,
      "Name": "Meire Nishida",
      "Email": "mncontab@gmail.com",
      "CustomerCode": "37470",
      "Phone": "1125348610",
      "Whatsapp": "11940480978",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2085,
      "Name": "Organize Consultoris",
      "Email": "organize@terra.com.br",
      "CustomerCode": "19060",
      "Phone": "1122119837",
      "Whatsapp": "11996320881",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2089,
      "Name": "João Lima Luz",
      "Email": "matogrosolima@gmail.com",
      "CustomerCode": "WFFQYL",
      "Phone": "6232237751",
      "Whatsapp": "62999376419",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2090,
      "Name": "VSS Assessoria Contábil",
      "Email": "condor@condorcontab.com.br",
      "CustomerCode": "38208",
      "Phone": "1143907377",
      "Whatsapp": "11992515090",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2091,
      "Name": "Mar Del Rio Apoio Adm. Ltda",
      "Email": "wrpessi@gmail.com",
      "CustomerCode": "18404",
      "Phone": "1198772013",
      "Whatsapp": "11987720137",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2092,
      "Name": "Alexandre Eisele Barberis",
      "Email": "alexandre.barberis@hotmail.com",
      "CustomerCode": "19061",
      "Phone": "1197540726",
      "Whatsapp": "11975407260",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2093,
      "Name": "Helena Maria Ramos Miras",
      "Email": "grmincorporadora@bol.com.br",
      "CustomerCode": "0IVZ2H",
      "Phone": "1832693083",
      "Whatsapp": "18996506617",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2094,
      "Name": "Contabilissimo Serviços de Contabilidade",
      "Email": "contato@contabilissimo.com.br",
      "CustomerCode": "19062",
      "Phone": "1126423988",
      "Whatsapp": "11976334190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2095,
      "Name": "EMAV Assessoria e Contabilidade ",
      "Email": "aurea.assis@emav.com.br",
      "CustomerCode": "37474",
      "Phone": "1155556879",
      "Whatsapp": "11996471298",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2096,
      "Name": "sergio yutaka koga",
      "Email": "sergio.koga@ig.com.br",
      "CustomerCode": "38181",
      "Phone": "1141783000",
      "Whatsapp": "11940229474",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2098,
      "Name": " 7mais Consultoria e  Assessoria Empresarial LTDA ",
      "Email": "societario01@7mais.com.br",
      "CustomerCode": "65141",
      "Phone": "1937903900",
      "Whatsapp": "19981220134",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2099,
      "Name": "JL Contabil Contadores Associados SS",
      "Email": "jlcontabil@jlcontabil.com.br",
      "CustomerCode": "5BAHW3",
      "Phone": "1126010676",
      "Whatsapp": "11991030127",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2100,
      "Name": "Estação Contábil LTDA",
      "Email": "modeloest@modeloest.com.br",
      "CustomerCode": "65143",
      "Phone": "1637228181",
      "Whatsapp": "16991171752",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2101,
      "Name": "Dircileia Aparecida Pacheco",
      "Email": "dircileia@adv.oabsp.org.br",
      "CustomerCode": "9P2UD6",
      "Phone": "1199714438",
      "Whatsapp": "11997144389",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2102,
      "Name": "Vanderlei Ricardo Innocencio do Nascimento",
      "Email": "wandernascimento468@gmail.com",
      "CustomerCode": "ZWRB40",
      "Phone": "1399741368",
      "Whatsapp": "13997413684",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2104,
      "Name": "Tiago de Vasconcelos Assessoria ",
      "Email": "tiago.vasconcelos@grassvalley.com",
      "CustomerCode": "19064",
      "Phone": "1196950624",
      "Whatsapp": "11969506249",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2106,
      "Name": "Daiane Aparecida isidoro pettine",
      "Email": "daiane.pettine@gmail.com",
      "CustomerCode": "1VVVJM",
      "Phone": "1198132962",
      "Whatsapp": "1198132962",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2107,
      "Name": "Fernando Figueiredo ",
      "Email": "fernandofigueiredo2011@gmail.com",
      "CustomerCode": "19065",
      "Phone": "1198196524",
      "Whatsapp": "11981965252",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2109,
      "Name": "Vanessa Bellinetti",
      "Email": "wancontabilidade@gmail.com",
      "CustomerCode": "19067",
      "Phone": "1190120024",
      "Whatsapp": "1190120024",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2110,
      "Name": "Escritório Contábil  Vavá LTDA",
      "Email": "fabiola@escritoriovava.com.br",
      "CustomerCode": "15204",
      "Phone": "1120246922",
      "Whatsapp": "11984805935",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2111,
      "Name": "Orghayashi",
      "Email": "vinicius@Orghayashi.com.br",
      "CustomerCode": "19066",
      "Phone": "1126826066",
      "Whatsapp": "11944475638",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2112,
      "Name": "Alpharetta Assessoria Empresarial Ltda",
      "Email": "denis.matos@gmail.com",
      "CustomerCode": "38603",
      "Phone": "1135138987",
      "Whatsapp": "11962230001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2113,
      "Name": "Michele Ferreira de Moraes",
      "Email": "moraes.mifemor@gmail.com",
      "CustomerCode": "19069",
      "Phone": "1193454147",
      "Whatsapp": "11914421855",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2114,
      "Name": "Thiago Rodrigues Colantonio",
      "Email": "thiago.colantonio@hotmail.com",
      "CustomerCode": "SMAG5Z",
      "Phone": "1198986945",
      "Whatsapp": "11989869456",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2115,
      "Name": "Megafiscontabil LTDA",
      "Email": "megafiscontabil@uol.com.br",
      "CustomerCode": "15636",
      "Phone": "1158311289",
      "Whatsapp": "11976493137",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2116,
      "Name": "Fabio Sportello",
      "Email": "nsportello@terra.com.br",
      "CustomerCode": "18045",
      "Phone": "1139047095",
      "Whatsapp": "11996301991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2117,
      "Name": "Maria Luiza Jordão de Arruda Camargo ",
      "Email": "luizitacamargo@hotmail.com",
      "CustomerCode": "19068",
      "Phone": "1198182471",
      "Whatsapp": "11981824712",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2118,
      "Name": "Tiago Vieira Fernandes Pinheiro",
      "Email": "medupcontabilidade@gmail.com",
      "CustomerCode": "FAI0EG",
      "Phone": "8199928386",
      "Whatsapp": "81999283869",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2119,
      "Name": "Consultab",
      "Email": "sueli@consultab.com.br",
      "CustomerCode": "16782",
      "Phone": "1126793011",
      "Whatsapp": "11966067113",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2120,
      "Name": "Alpha Negócios Contábeis LTDA",
      "Email": "alphacontabeis@uol.com.br",
      "CustomerCode": "16996",
      "Phone": "1144412933",
      "Whatsapp": "11984553384",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2122,
      "Name": "Francisco A.L. Rodrigues Cucchi",
      "Email": "roliveira@thomeecucchi.adv.br",
      "CustomerCode": "17300",
      "Phone": "1132362544",
      "Whatsapp": "11976888799",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2123,
      "Name": "Edson Vieira Honorio",
      "Email": "contabilidadeimperial.es@gmail.com",
      "CustomerCode": "EAOJGN",
      "Phone": "1233017263",
      "Whatsapp": "12987046553",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2125,
      "Name": "Danilo Frugis",
      "Email": "prusso@olin.com",
      "CustomerCode": "19081",
      "Phone": "1145606681",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2126,
      "Name": "Aline Portugal dos santos",
      "Email": "portugala39@gmail.com",
      "CustomerCode": "NG9EZQ",
      "Phone": "1198203217",
      "Whatsapp": "11982032172",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2128,
      "Name": "Rodolfo Paiva de Oliveira",
      "Email": "rodolfo.paiva@amigotech.com.br",
      "CustomerCode": "Y2UJXO",
      "Phone": "1130198080",
      "Whatsapp": "11998625703",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2129,
      "Name": "Cypriano e Medeiros Soluções Contábeis",
      "Email": "cm@cmcontabilidade.com",
      "CustomerCode": "0TIL13",
      "Phone": "4898844174",
      "Whatsapp": "48988441746",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2130,
      "Name": "Roberto",
      "Email": "robertocontador2009@hotmail.com",
      "CustomerCode": "7VWZH4",
      "Phone": null,
      "Whatsapp": "11975174006",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2131,
      "Name": "Silvana Montalvão de Oliveira Silva",
      "Email": "silmontalvao@yahoo.com.br",
      "CustomerCode": "18910",
      "Phone": "1195297726",
      "Whatsapp": "11995297726",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2132,
      "Name": "Cezar de Castro",
      "Email": "cezar_castro31@hotmail.com",
      "CustomerCode": "38518",
      "Phone": "1196424429",
      "Whatsapp": "11964244294",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2133,
      "Name": "Wendel Andreatta Santos",
      "Email": "contabil.andreatta@gmail.com",
      "CustomerCode": "37457",
      "Phone": "",
      "Whatsapp": "11982789138",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2134,
      "Name": "Edelcio Moreira Pajor",
      "Email": "edelciopajor@gmail.com",
      "CustomerCode": "16658",
      "Phone": "1120517997",
      "Whatsapp": "11995569415",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2136,
      "Name": "Eurobras Construções Metálicas Moduladas LTDA",
      "Email": "sidnei.sousa@eurobras.com.br",
      "CustomerCode": "37471",
      "Phone": "1120662060",
      "Whatsapp": "11942220038",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2137,
      "Name": "Paulo Alves de Freitas Junior",
      "Email": "alpha.ppm.contabilidade@gmail.com",
      "CustomerCode": "19070",
      "Phone": "1195462714",
      "Whatsapp": "11954627144",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2138,
      "Name": "Antonio Carlos Da silva",
      "Email": "toninhocarlos36777bd@gmail.com",
      "CustomerCode": "BUZ39A",
      "Phone": "1599827208",
      "Whatsapp": "11969265585",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2139,
      "Name": "IFC Contabilidade LTDA",
      "Email": "igor@ifccontabilidade.com.br",
      "CustomerCode": "65144",
      "Phone": "1938865939",
      "Whatsapp": "11992711427",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2140,
      "Name": "Jose Goncalves Rodrigues",
      "Email": "josegoncalves412@gmail.com",
      "CustomerCode": "15348",
      "Phone": "1156312443",
      "Whatsapp": "11979515254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2141,
      "Name": "LFS Contabilidade",
      "Email": "lfs@lfs.com.br",
      "CustomerCode": "19074",
      "Phone": "1156324440",
      "Whatsapp": "11977060000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2144,
      "Name": "José Carlos Vieira de Aguiar",
      "Email": "matheus@aguiarassociados.com.br",
      "CustomerCode": "19071",
      "Phone": "1120764699",
      "Whatsapp": "11994943688",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2145,
      "Name": "Elisabete Gramático",
      "Email": "eg1@outlook.com.br",
      "CustomerCode": "15705",
      "Phone": "1138560678",
      "Whatsapp": "11940399981",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2146,
      "Name": "Paulo Eiji",
      "Email": "paulo.eiji@uol.com.br",
      "CustomerCode": "15865",
      "Phone": "1150580391",
      "Whatsapp": "11997640974",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2147,
      "Name": "Erika Valeria Virgovino da Silva",
      "Email": "erika@zafiracontabilidade.com.br",
      "CustomerCode": "37475",
      "Phone": "1142201000",
      "Whatsapp": "11994163787",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2148,
      "Name": "Claudomiro Bley Vieira Junior",
      "Email": "comercial@grupopontual.com.br",
      "CustomerCode": "65145",
      "Phone": "4130132800",
      "Whatsapp": "41988057394",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2149,
      "Name": "Coopergran",
      "Email": "coopergran@terra.com.br",
      "CustomerCode": "6B85G8",
      "Phone": "1146240404",
      "Whatsapp": "11958300505",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2150,
      "Name": "Coopergran",
      "Email": "coopergran@terra.com.br",
      "CustomerCode": "C1NM8R",
      "Phone": "1136816915",
      "Whatsapp": "11958300505",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2151,
      "Name": "FMF Assessoria e Consultoria Contábil SS",
      "Email": "gerencia@fmfassessoria.com.br",
      "CustomerCode": "16286",
      "Phone": "1129899596",
      "Whatsapp": "11999466038",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2152,
      "Name": "Luzinete Maria da Costa Murari",
      "Email": "lg.consult2912@gmail.com",
      "CustomerCode": "37472",
      "Phone": "1126294358",
      "Whatsapp": "11984564035",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2158,
      "Name": "Diego Cantuario da  Silva",
      "Email": "contato@contlab.com.br",
      "CustomerCode": "19072",
      "Phone": "1198360317",
      "Whatsapp": "11983603172",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2159,
      "Name": "Flavia",
      "Email": "flavia81011983@gmail.com",
      "CustomerCode": "J1CPZU",
      "Phone": "1147015882",
      "Whatsapp": "11947101856",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2160,
      "Name": "Maria Gorete de Gouveia Gomes",
      "Email": "ma.gorete@hotmail.com",
      "CustomerCode": "19073",
      "Phone": "1150703114",
      "Whatsapp": "11998898072",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2161,
      "Name": "Contabilidade Barboza",
      "Email": "contabilidadebarbozaprado@gmail.com",
      "CustomerCode": "64448",
      "Phone": "1236461013",
      "Whatsapp": "12981463088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2162,
      "Name": "Ronaldo Cesar de Sousa Ferreira",
      "Email": "rcsf2107@gmail.com",
      "CustomerCode": "EREK89",
      "Phone": "1120712609",
      "Whatsapp": "11980494963",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2163,
      "Name": "Kátia Apolinário",
      "Email": "professorakatiapoli@gmail.com",
      "CustomerCode": "X1939Y",
      "Phone": "1194367447",
      "Whatsapp": "11943674479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2164,
      "Name": "Escritório Contábil Mello LTDA",
      "Email": "contato@contabilmello.com",
      "CustomerCode": "19075",
      "Phone": "1129123193",
      "Whatsapp": "11939297910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2165,
      "Name": "Claudio Hernandes Marques",
      "Email": "antoniocontador@contmarques.net.br",
      "CustomerCode": "19079",
      "Phone": "1122165504",
      "Whatsapp": "11940397209",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2168,
      "Name": "Antonio Fernandes Lopes",
      "Email": "contfernandes10@gmail.com",
      "CustomerCode": "37473",
      "Phone": "1197511082",
      "Whatsapp": "11940397209",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2169,
      "Name": "Paulista Contabilidade",
      "Email": "artur@cpaulista.com.br",
      "CustomerCode": "19087",
      "Phone": "1122848866",
      "Whatsapp": "11993368772",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2170,
      "Name": "Capell Contabilidade S/S Ltda",
      "Email": "contato@capell.com.br",
      "CustomerCode": "19076",
      "Phone": "1132411211",
      "Whatsapp": "11982632323",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2171,
      "Name": "A L Baptista Contabilidade",
      "Email": "adm@albcontabilidade.cnt.br",
      "CustomerCode": "XB47XH",
      "Phone": "1131298322",
      "Whatsapp": "1131298322",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2172,
      "Name": "Antonio Carlos Duarte Moreira",
      "Email": "dr.ac.duarte@gmail.com",
      "CustomerCode": "15587",
      "Phone": "1134414999",
      "Whatsapp": "11997429333",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2173,
      "Name": "Maria Lucia Furtado",
      "Email": "saacservicos@live.com",
      "CustomerCode": "IQ41U0",
      "Phone": "1141428382",
      "Whatsapp": "11996432733",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2174,
      "Name": "Leandro Cremer  Bernardo",
      "Email": "contabilidadebernardo@gmail.com",
      "CustomerCode": "2A2E2E",
      "Phone": "1433359510",
      "Whatsapp": "14998513306",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2175,
      "Name": "ATSJ Consultoria Empresarial",
      "Email": "atsjpericias@gmail.com",
      "CustomerCode": "3WAWSY",
      "Phone": "1199895266",
      "Whatsapp": "11998952663",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2176,
      "Name": "Jose de Fatima Azevedo",
      "Email": "azevedoecoelho@terra.com.br",
      "CustomerCode": "19078",
      "Phone": "1155060599",
      "Whatsapp": "11999332108",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2177,
      "Name": "Tamires de Souza ",
      "Email": "souzacontabil.assessoria@gmail.com",
      "CustomerCode": "KPTY9Y",
      "Phone": "",
      "Whatsapp": "11976063822",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2180,
      "Name": "Youko Yamamoto",
      "Email": "yoy@terra.com.br",
      "CustomerCode": "19084",
      "Phone": "1197638702",
      "Whatsapp": "11976387027",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2181,
      "Name": "Luis Henrique Silva ",
      "Email": "ls25128069@gmail.com",
      "CustomerCode": "0VQ1UH",
      "Phone": "991873906",
      "Whatsapp": "18991873906",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2182,
      "Name": "Atitude Soluções Contabeis LTDA",
      "Email": "societario@grupoatitude.com",
      "CustomerCode": "2SFARA",
      "Phone": "1128724498",
      "Whatsapp": "11970359379",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2183,
      "Name": "Assempre  Soluções Empreendedoras LTDA",
      "Email": "assempre@assempre.net.br",
      "CustomerCode": "19080",
      "Phone": "1135542960",
      "Whatsapp": "11977282814",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2184,
      "Name": "Terra Vista Gestora de Recursos Ltda.",
      "Email": "juridico@moss.earth",
      "CustomerCode": "19092",
      "Phone": "1199881360",
      "Whatsapp": "11998813606",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2185,
      "Name": "Jovair Lopes da Silva",
      "Email": "jovairls@hotmail.com",
      "CustomerCode": "38517",
      "Phone": "1140447848",
      "Whatsapp": "11987235637",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2186,
      "Name": "Marcio Zago",
      "Email": "marcio.zago@ntwcontabilidade.com.br",
      "CustomerCode": "63365",
      "Phone": "1432350601",
      "Whatsapp": "14991072868",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2187,
      "Name": "Sergio Nodari de Simoni",
      "Email": "sergio@snscontabil.com.br",
      "CustomerCode": "19082",
      "Phone": "1184014475",
      "Whatsapp": "11984014475",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2188,
      "Name": "Exatus Contabildiade Toledo Ltda",
      "Email": "exatustoledo@gmail.com",
      "CustomerCode": "LNLKXV",
      "Phone": "4532522825",
      "Whatsapp": "45999687818",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2189,
      "Name": "Difa Assessoria Contábil Ltda",
      "Email": "contato@difacontabil.com.br",
      "CustomerCode": "19083",
      "Phone": "1180148223",
      "Whatsapp": "11980148223",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2190,
      "Name": "Victor Mendes Teixeira",
      "Email": "avemconsultoria@outlook.com",
      "CustomerCode": "65147",
      "Phone": "1397752837",
      "Whatsapp": "13997752837",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2192,
      "Name": "Rogerio Galluzzi Turchetto",
      "Email": "gabigg_2@hotmail.com",
      "CustomerCode": "18408",
      "Phone": "1135968608",
      "Whatsapp": "11945177280",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2193,
      "Name": "Lopes Accounting Serviços Contabéis LTDA.",
      "Email": "lopes@lopes.cnt.br",
      "CustomerCode": "17581",
      "Phone": "1129562682",
      "Whatsapp": "11981383896",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2194,
      "Name": "Julia Pugliese",
      "Email": "jp.romao@uol.com.br",
      "CustomerCode": "19085",
      "Phone": "1126051830",
      "Whatsapp": "11999332885",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2195,
      "Name": "Consultec Contabilidade",
      "Email": "expediente1@escritorioconsultec.cnt.br",
      "CustomerCode": "65146",
      "Phone": "4199790209",
      "Whatsapp": "41997902098",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2196,
      "Name": "Fisco Contabilidade",
      "Email": "fisco@fiscocontabil.com.br",
      "CustomerCode": "17270",
      "Phone": "1129243136",
      "Whatsapp": "11993761203",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2197,
      "Name": "Escritório Globo de Contabilidade LTDA",
      "Email": "evaldojr@escglobo.com.br",
      "CustomerCode": "64841",
      "Phone": "1433252199",
      "Whatsapp": "14996951019",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2198,
      "Name": "Rodrigo Carvalho Leite",
      "Email": "xrpconsultoria@gmail.com",
      "CustomerCode": "65150",
      "Phone": "3730718424",
      "Whatsapp": "37999860251",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2199,
      "Name": "Douglas Zanotto",
      "Email": "douglas.zanotto@hotmail.com",
      "CustomerCode": "65152",
      "Phone": "4898676259",
      "Whatsapp": "48998676259",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2200,
      "Name": "Supremacia Contábil",
      "Email": "contato@supremaciacontabil.com.br",
      "CustomerCode": "19105",
      "Phone": "1127467822",
      "Whatsapp": "11934562442",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2201,
      "Name": "Adriana Cristina Bolli Manaro",
      "Email": "pedromanaro@gmail.com",
      "CustomerCode": "37451",
      "Phone": "1124074104",
      "Whatsapp": "11964688637",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2202,
      "Name": "Heigor Pereira Malaquias",
      "Email": "heigorpmalaquias@hotmail.com",
      "CustomerCode": "65222",
      "Phone": "6732227549",
      "Whatsapp": "67996617549",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2203,
      "Name": "Agostinho Serviços Empresariais LTDA ",
      "Email": "eagostinho13@gmail.com",
      "CustomerCode": "19086",
      "Phone": "1156319123",
      "Whatsapp": "11994028711",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2204,
      "Name": "Jorge Luiz espindula siqueira ",
      "Email": "jorge.nostop1234@gmail.com",
      "CustomerCode": "I67EJR",
      "Phone": "1197424205",
      "Whatsapp": "11974242059",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2205,
      "Name": "wilson roberto gil ",
      "Email": "danisercontab@gmail.com",
      "CustomerCode": "9FTEUH",
      "Phone": "4832460672",
      "Whatsapp": "48998520432",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2206,
      "Name": "Onofre Pinto da Rocha Junior",
      "Email": "rochaadvocacia@aasp.org.br",
      "CustomerCode": "15515",
      "Phone": "1147535286",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2207,
      "Name": "Elo Contabilidade LTDA",
      "Email": "elo.consultoriacontabil@outlook.com",
      "CustomerCode": "65148",
      "Phone": "3897248018",
      "Whatsapp": "38997248018",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2208,
      "Name": "Ana Gonçalves",
      "Email": "donate.ana@gmail.com",
      "CustomerCode": "1HF9OJ",
      "Phone": "1145510451",
      "Whatsapp": "11999675030",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2209,
      "Name": "Felicio & Gomes sociedade de advogados",
      "Email": "rafael@feliciogomes.adv.br ",
      "CustomerCode": "65189",
      "Phone": "1832213667",
      "Whatsapp": "18997474750",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2210,
      "Name": "Elenice Silva Santos",
      "Email": "elenice@celvcontabilidade.com.br",
      "CustomerCode": "J8ONUN",
      "Phone": "1150448455",
      "Whatsapp": "11954904412",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2211,
      "Name": "R N Cunha Contabilidade LTDA",
      "Email": "legal@contabilidadern.com.br",
      "CustomerCode": "65149",
      "Phone": "2225241550",
      "Whatsapp": "22981275645",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2212,
      "Name": "Luiz miguel Dos santos",
      "Email": "luismiguelcasimiro6@gmail.com",
      "CustomerCode": "AKEZWS",
      "Phone": "51",
      "Whatsapp": "51999087655",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2215,
      "Name": "R. F. dos Santos",
      "Email": "contabilidade@contaconta.com.br",
      "CustomerCode": "L1O3QL",
      "Phone": "1198308447",
      "Whatsapp": "11983084479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2216,
      "Name": "Gilberto Silva",
      "Email": "gpsholy@gmail.com",
      "CustomerCode": "17426",
      "Phone": "1199910647",
      "Whatsapp": "11999106473",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2218,
      "Name": "Osmar Tomazini",
      "Email": "osmarcontador@yahoo.com.br",
      "CustomerCode": "65151",
      "Phone": "4132326074",
      "Whatsapp": "41998297894",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2219,
      "Name": "Daniela Iannuzzi Silva Sanches",
      "Email": "dep.contabil@iannuzzicontabil.com.br",
      "CustomerCode": "WPUMCA",
      "Phone": "1144169338",
      "Whatsapp": "11973374788",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2220,
      "Name": "Vinícius Sales Dos Reis",
      "Email": "vinni.iam@live.com",
      "CustomerCode": "37479",
      "Phone": "1130140230",
      "Whatsapp": "11985432847",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2221,
      "Name": "Elio Rocha Cavalcante",
      "Email": "elio.cavalcante@uol.com.br",
      "CustomerCode": "37250",
      "Phone": "1199598882",
      "Whatsapp": "11995988829",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2222,
      "Name": "Maria Aurenir da Silva",
      "Email": "mascontabil12@gmail.com",
      "CustomerCode": "15769",
      "Phone": "1156136313",
      "Whatsapp": "11973685307",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2224,
      "Name": "Organização Contabil Sermin",
      "Email": "contabilsermin@gmail.com",
      "CustomerCode": "PJ6DSE",
      "Phone": "1122038099",
      "Whatsapp": "11994000327",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2225,
      "Name": "Gerson Marques Campos",
      "Email": "gmcampos58@bol.com.br",
      "CustomerCode": "19088",
      "Phone": "1196352373",
      "Whatsapp": "11963352373",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2226,
      "Name": "HAG Contabilidade LTDA",
      "Email": "angelica.gallo@hagcontabilidade.com.br",
      "CustomerCode": "65155",
      "Phone": "4330287894",
      "Whatsapp": "43991220124",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2227,
      "Name": "Nova Prime Organização Contábil Ltda ",
      "Email": "wilson@novaprime.com.br",
      "CustomerCode": "17652",
      "Phone": "1120923721",
      "Whatsapp": "11992541717",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2228,
      "Name": "Adilson da Silva",
      "Email": "adero42@yahoo.com.br",
      "CustomerCode": "NFAPVZ",
      "Phone": "1197654269",
      "Whatsapp": "11976542169",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2229,
      "Name": "Marcelo Aparecido Ferreira ",
      "Email": "xangoberiman@gmail.com",
      "CustomerCode": "FJ3KUM",
      "Phone": "1139650875",
      "Whatsapp": "11940719295",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2230,
      "Name": "Idenisia Ferreira",
      "Email": "idenisiaferreira@gmail.com",
      "CustomerCode": "HKFHR2",
      "Phone": "4899179692",
      "Whatsapp": "48991796925",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2231,
      "Name": "Ruotolo Assessoria Contabil S/S",
      "Email": "ruotolo@uol.com.br",
      "CustomerCode": "18013",
      "Phone": "1126730730",
      "Whatsapp": "11988045174",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2232,
      "Name": "Rosalia Souza Caires de Lima",
      "Email": "silvio@omegaassessoria.com.br",
      "CustomerCode": "F4MWGZ",
      "Phone": "1136882826",
      "Whatsapp": "11963520308",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2233,
      "Name": "Weslley Lima de Oliveira",
      "Email": "weslley.lima.oliveira@hotmail.com",
      "CustomerCode": "19185",
      "Phone": "0000000000",
      "Whatsapp": "11976569972",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2234,
      "Name": "Hidekazu Shigeno",
      "Email": "societario@hrshigeno.com.br",
      "CustomerCode": "4V1FVT",
      "Phone": "1147017095",
      "Whatsapp": "11999976009",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2235,
      "Name": "Alexandre Roefero Neto",
      "Email": "aroef@uol.com.br",
      "CustomerCode": "19320",
      "Phone": "1143038865",
      "Whatsapp": "11974774303",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2236,
      "Name": "Murilo Suith Delphino",
      "Email": "delphinocontabil@gmail.com",
      "CustomerCode": "19107",
      "Phone": "1697481753",
      "Whatsapp": "16997481753",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2237,
      "Name": "Paulo Sergio Mandaro",
      "Email": "contadorpaulo@terra.com.br",
      "CustomerCode": "19090",
      "Phone": "1136173137",
      "Whatsapp": "11947430742",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2239,
      "Name": "Contabil Contato SS LTDA",
      "Email": "supervisao@contabilcontato.com.br",
      "CustomerCode": "65195",
      "Phone": "1236427878",
      "Whatsapp": "12991233514",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2240,
      "Name": "Veronica Maria de Andrade",
      "Email": "contabilobjetiva2@gmail.com",
      "CustomerCode": "65154",
      "Phone": "1158417292",
      "Whatsapp": "11995411315",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2241,
      "Name": "Douglas Frank Tabelini",
      "Email": "douglastabelini@gmail.com",
      "CustomerCode": "18127",
      "Phone": "1183721798",
      "Whatsapp": "11983721798",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2242,
      "Name": "Nilson Custódio Junior",
      "Email": "ncjr7@hotmail.com",
      "CustomerCode": "15525",
      "Phone": "1125578041",
      "Whatsapp": "11974479741",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2243,
      "Name": "Radiantecont Assessoria LTDA",
      "Email": "ncjr7@hotmail.com",
      "CustomerCode": "NSUSW4",
      "Phone": "1125578041",
      "Whatsapp": "11974479741",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2244,
      "Name": "José Antonio de Sales Neto",
      "Email": "joseantoniosneto@gmail.com",
      "CustomerCode": "65162",
      "Phone": "2226531069",
      "Whatsapp": "22988331070",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2246,
      "Name": "Starley Alves Galdino",
      "Email": "starleyjuceal@gmail.com",
      "CustomerCode": "J7NRRN",
      "Phone": "8296978495",
      "Whatsapp": "82996978495",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2247,
      "Name": "Américo Shoji Furuya ",
      "Email": "asfuruya@gmail.com",
      "CustomerCode": "15695",
      "Phone": "1199418880",
      "Whatsapp": "11970746817",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2248,
      "Name": "Eduardo Flemming",
      "Email": "eduflemming@gmail.com",
      "CustomerCode": "FHEK7B",
      "Phone": "1141190969",
      "Whatsapp": "11986095933",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2249,
      "Name": "Notrya Assessoria contabil",
      "Email": "notriacontab@outlook.com",
      "CustomerCode": "16415",
      "Phone": "1139718022",
      "Whatsapp": "11995360987",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2250,
      "Name": "Consime Assessoria Contabil S/S",
      "Email": "gustavo@consimecontabil.com.br",
      "CustomerCode": "18943",
      "Phone": "1155746210",
      "Whatsapp": "11981747789",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2251,
      "Name": "Cilene",
      "Email": "fisco.contabilidade@gmail.com",
      "CustomerCode": "WRE39W",
      "Phone": "1128887612",
      "Whatsapp": "11967197552",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2252,
      "Name": "Paulo Pereira",
      "Email": "professorpaulinhoup@gmail.com",
      "CustomerCode": "58TC1F",
      "Phone": "1198369676",
      "Whatsapp": "11983696765",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2253,
      "Name": "GSA Gestão de Serviços Administrativos e  Contábeis LTDA",
      "Email": "denise@gsagestao.com.br",
      "CustomerCode": "19100",
      "Phone": "1138681604",
      "Whatsapp": "11982380091",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2254,
      "Name": "Katia Regina Dos Santos",
      "Email": "katiaebec63@gmail.com",
      "CustomerCode": "YUHUO9",
      "Phone": "3436622115",
      "Whatsapp": "34987000883",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2255,
      "Name": "Marana & Miron Soluções Contabeis LTDA",
      "Email": "ataliba.silva@uol.com.br",
      "CustomerCode": "38420",
      "Phone": "1142242908",
      "Whatsapp": "11992939429",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2256,
      "Name": "Solidus Organização Contabil LTDA",
      "Email": "rafael@soliduscontabil.com.br",
      "CustomerCode": "65153",
      "Phone": "2193057066",
      "Whatsapp": "21993057066",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2257,
      "Name": "JSSCONT Contabilidade EIRELI",
      "Email": "affininho@gmail.com",
      "CustomerCode": "65205",
      "Phone": "",
      "Whatsapp": "19997099269",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2258,
      "Name": "Suely Aparecida Gaspar",
      "Email": "suelygaspar54@gmail.com",
      "CustomerCode": "15842",
      "Phone": "1139923592",
      "Whatsapp": "11984199674",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2259,
      "Name": "Caio",
      "Email": "caioperalta@gmail.com",
      "CustomerCode": "ZJ99L1",
      "Phone": "1196909509",
      "Whatsapp": "11969095098",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2260,
      "Name": "J.A. Garcia Sociedade de Advogados ",
      "Email": "jagarciasociedadeadv@terra.com.br",
      "CustomerCode": "9HKJDQ",
      "Phone": "1132555694",
      "Whatsapp": "1132555694",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2261,
      "Name": "Grupo Rebus e Ducit Partners",
      "Email": "grd@grdcontabilidade.com.br",
      "CustomerCode": "IKD2UZ",
      "Phone": "1151992539",
      "Whatsapp": "11967292510",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2262,
      "Name": "Pergi Mey Mazzaferro",
      "Email": "dra.pergimey@mazzaferroadvocacia.com.br",
      "CustomerCode": "38583",
      "Phone": "1152001155",
      "Whatsapp": "11953989000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2264,
      "Name": "Maria Helena Cabrera",
      "Email": "lenacab5@gmail.com",
      "CustomerCode": "JP8X38",
      "Phone": "5132732990",
      "Whatsapp": "51991471743",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2265,
      "Name": "Dirceu Mendonça de  Araujo",
      "Email": "dicojunta@hotmail.com",
      "CustomerCode": "HWEVC9",
      "Phone": "2126214792",
      "Whatsapp": "21996877776",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2267,
      "Name": "Flavio Peres",
      "Email": "ricardo@fpfinance.com.br",
      "CustomerCode": "37477",
      "Phone": "1126773835",
      "Whatsapp": "11958764716",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2268,
      "Name": "Apetece Sistemas de Alimentação",
      "Email": "giovana.estevam@gmail.com",
      "CustomerCode": "37476",
      "Phone": "1142339300",
      "Whatsapp": "11942771441",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2270,
      "Name": "Sérgio Giaquinto",
      "Email": "sergiogiaquinto@terra.com.br",
      "CustomerCode": "RVQEU8",
      "Phone": "1999905272",
      "Whatsapp": "19999052727",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2271,
      "Name": "Rosana Machado Biachini",
      "Email": "dp@joia.cnt.br",
      "CustomerCode": "19094",
      "Phone": "1148286488",
      "Whatsapp": "11999607523",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2272,
      "Name": "Celma",
      "Email": "souza_celma77@hotmail.com",
      "CustomerCode": "418D6D",
      "Phone": "7799191493",
      "Whatsapp": "77991914930",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2273,
      "Name": "Jose Luiz Galhardo Contabilidade",
      "Email": "jlgalhardo@terra.com.br",
      "CustomerCode": "61240",
      "Phone": "1140280406",
      "Whatsapp": "1140280406",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2278,
      "Name": "Dorival Veiga",
      "Email": "contabilrd@uol.com.br",
      "CustomerCode": "19093",
      "Phone": "1141334995",
      "Whatsapp": "11975009886",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2279,
      "Name": "Iluminar Contabil S/S - EPP",
      "Email": "rkuabata@gmail.com",
      "CustomerCode": "15692",
      "Phone": "1156320512",
      "Whatsapp": "11999798672",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2280,
      "Name": "JAS Contabilidade e Consultoria Ltda",
      "Email": "contador@jascontabilidade.cnt.br",
      "CustomerCode": "19233",
      "Phone": "1139745025",
      "Whatsapp": "1139722912",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2281,
      "Name": "Joaquim Almeida Morais",
      "Email": "legalodl@gmail.com",
      "CustomerCode": "WF65GV",
      "Phone": "5511986432",
      "Whatsapp": "11986432251",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2282,
      "Name": "Tatiane de Brito",
      "Email": "tatylieber1@gmail.com",
      "CustomerCode": "SV4V4N",
      "Phone": "2199999483",
      "Whatsapp": "21999994833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2283,
      "Name": "Alexsandro Cristiano Ferreira Fonseca",
      "Email": "alexsandro.fonseca92@hotmail.com",
      "CustomerCode": "19095",
      "Phone": "1191438362",
      "Whatsapp": "11991438362",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2284,
      "Name": "Black Rock Business",
      "Email": "blackrockbusiness7@gmail.com",
      "CustomerCode": "XGVZ2C",
      "Phone": "1232046420",
      "Whatsapp": "12992038001",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2285,
      "Name": "Connect Soluções Contábeis LTDA",
      "Email": "lucimar@contabilidadeconnect.com.br",
      "CustomerCode": "65156",
      "Phone": "3135863870",
      "Whatsapp": "31985978210",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2286,
      "Name": "Sistema Contabil ",
      "Email": "sisistemacontabil@sistemacontabil.net",
      "CustomerCode": "65157",
      "Phone": "3438421452",
      "Whatsapp": "34991716487",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2287,
      "Name": "Silvia cavalcanti",
      "Email": "cavalcanti.silvia1@gmail.com",
      "CustomerCode": "16867",
      "Phone": "1126718663",
      "Whatsapp": "11988490278",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2288,
      "Name": "Nelson Cardoso ",
      "Email": "cavalcanti.silvia1@gmail.com",
      "CustomerCode": "9WASID",
      "Phone": "1126718663",
      "Whatsapp": "11988490278",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2289,
      "Name": "Teobaldo de Souza Guimarães",
      "Email": "teobaldog@hotmail.com",
      "CustomerCode": "CTWLGM",
      "Phone": "7799117559",
      "Whatsapp": "77991175596",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2290,
      "Name": "Joao",
      "Email": "contato@zionsolutions.com.br",
      "CustomerCode": "WACVE7",
      "Phone": "2193055852",
      "Whatsapp": "21993055852",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2291,
      "Name": "Leonidas L Pereira",
      "Email": "leoplessa@yahoo.com.br",
      "CustomerCode": "I09SXX",
      "Phone": "7734442031",
      "Whatsapp": "77991964939",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2292,
      "Name": "Rachel Sales Oliveira Hernandes",
      "Email": "ladislei@yahoo.com",
      "CustomerCode": "19096",
      "Phone": "1133336660",
      "Whatsapp": "11994955435",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2293,
      "Name": "Flávio P Barraca 01168457831",
      "Email": "flavio.barraca@cptm.sp.gov.br",
      "CustomerCode": "17790",
      "Phone": "1131177449",
      "Whatsapp": "11950726425",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2294,
      "Name": "Eliane souto santos",
      "Email": "elianesouto.santos@gmail.com",
      "CustomerCode": "AK74WV",
      "Phone": "6199293429",
      "Whatsapp": "61992934292",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2295,
      "Name": "Silvana Maria dos Santos",
      "Email": "silvana5002@yahoo.com.br",
      "CustomerCode": "19097",
      "Phone": "1184341086",
      "Whatsapp": "11984341086",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2296,
      "Name": "Alfredo Pedro da Silva",
      "Email": "mserrat.ops@uol.com.br",
      "CustomerCode": "17554",
      "Phone": "1139042649",
      "Whatsapp": "11973382308",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2297,
      "Name": "Tania Regina Gallo",
      "Email": "andrigar@uol.com.br",
      "CustomerCode": "19098",
      "Phone": "1138721957",
      "Whatsapp": "11996118554",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2298,
      "Name": "Daniel Matias Vaz Filho",
      "Email": "daniel.361@live.com",
      "CustomerCode": "65158",
      "Phone": "4199831486",
      "Whatsapp": "41998314860",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2300,
      "Name": "Steter E Silva LTDA ME",
      "Email": "jaimecontabilidade@bol.com.br",
      "CustomerCode": "61351",
      "Phone": "1936732472",
      "Whatsapp": "19993175693",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2301,
      "Name": "MPCON Assessoria Contabil LTDA",
      "Email": "erica@mpcontabil.com.br",
      "CustomerCode": "40117",
      "Phone": "1146363001",
      "Whatsapp": "11962523001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2302,
      "Name": "Guilherme Correa Henrique",
      "Email": "anne_kelly18@hotmail.com",
      "CustomerCode": "65159",
      "Phone": "8499127371",
      "Whatsapp": "84996811461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2304,
      "Name": "Carlos Bonesso",
      "Email": "bonessorock@hotmail.com",
      "CustomerCode": "15721",
      "Phone": "1127588763",
      "Whatsapp": "11999953169",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2305,
      "Name": "Camila Bandeira",
      "Email": "camila@bandeiracontabil.com.br",
      "CustomerCode": "18688",
      "Phone": "1198550488",
      "Whatsapp": "11999962020",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2306,
      "Name": "Organização Contábil Objetiva LTDA",
      "Email": "diretoria@contabilobjetiva.com.br",
      "CustomerCode": "T1Y4GW",
      "Phone": "1136810802",
      "Whatsapp": "11993333134",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2307,
      "Name": "Rebeca Fantagucci",
      "Email": "rebecamaester@gmail.com",
      "CustomerCode": "19168",
      "Phone": "1100000000",
      "Whatsapp": "11973911213",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2309,
      "Name": "H A A S - Empreendimentos e Participações em Negócios Ltda",
      "Email": "haas.empreendimentos2@gmail.com",
      "CustomerCode": "ZYQ0UH",
      "Phone": "1150332000",
      "Whatsapp": "11972332930",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2310,
      "Name": "Escritório Delta de Contabilidade LTDA",
      "Email": "legalizacao@escritoriodelta.com.br",
      "CustomerCode": "62664",
      "Phone": "1734656010",
      "Whatsapp": "17997526345",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2312,
      "Name": "NS Administradora de Bens Ltda.",
      "Email": "roseli2540@hotmail.com",
      "CustomerCode": "19099",
      "Phone": "1130711502",
      "Whatsapp": "11976903010",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2313,
      "Name": "DL Contab Serviços Empresariais LTDA",
      "Email": "diretoria@dlcontab.com.br",
      "CustomerCode": "19104",
      "Phone": "4799611379",
      "Whatsapp": "4799611379",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2314,
      "Name": "MLS Empresarial",
      "Email": "contato@mlsempresarial.com.br",
      "CustomerCode": "19101",
      "Phone": "1134674372",
      "Whatsapp": "11955596885",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2315,
      "Name": "Marcos Flavio Segamarchi",
      "Email": "marcos@mstax.com.br",
      "CustomerCode": "QYKR7J",
      "Phone": "1195000029",
      "Whatsapp": "11950000294",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2316,
      "Name": "Paulo de Carvalho lago",
      "Email": "paulocl63@gmail.com",
      "CustomerCode": "16928",
      "Phone": "1199746590",
      "Whatsapp": "11997465901",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2317,
      "Name": "Guilherme Henrique Escobar Fedocci",
      "Email": "geassessoria.ggr@gmail.com",
      "CustomerCode": "65160",
      "Phone": "19",
      "Whatsapp": "19996899656",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2318,
      "Name": "Julio Cesar dos Santos Oliveira ",
      "Email": "juliooliveira5242@gmail.com",
      "CustomerCode": "7GNWH2",
      "Phone": "4799654563",
      "Whatsapp": "47996545638",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2319,
      "Name": "Teodoro Stergios Yannacopoulos",
      "Email": "teodoro@inc.com.br",
      "CustomerCode": "64012",
      "Phone": "1132583773",
      "Whatsapp": "11974668272",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2320,
      "Name": "Raquel Jaen DAgazio",
      "Email": "raquel@dagazio.com.br",
      "CustomerCode": "16516",
      "Phone": "1196314159",
      "Whatsapp": "11963141599",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2321,
      "Name": "Mipe Contabilidade e Gestão Empresarial LTDA",
      "Email": "jeovana@mipecontabilidade.com.br",
      "CustomerCode": "19142",
      "Phone": "1141135011",
      "Whatsapp": "11998144731",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2322,
      "Name": "Rogerio Silva de Freitas",
      "Email": "rsguiga@gmail.com",
      "CustomerCode": "37478",
      "Phone": "1196144000",
      "Whatsapp": "11961440000",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2323,
      "Name": "Webert Assis",
      "Email": "webert@adv.oabsp.org.br",
      "CustomerCode": "17802",
      "Phone": "1195160101",
      "Whatsapp": "11951601011",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2324,
      "Name": "Fernando Leal Contabilidade",
      "Email": "ascosicont@gmail.com",
      "CustomerCode": "15297",
      "Phone": "1122757934",
      "Whatsapp": "11950545532",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2325,
      "Name": "José Evaldo Xavier dos Santos",
      "Email": "evaldoasscont@hotmail.com",
      "CustomerCode": "49EHWT",
      "Phone": "1334696943",
      "Whatsapp": "1334696943",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2326,
      "Name": "Tania Pontual Leite Maia",
      "Email": "contabilspm@outlook.com.br",
      "CustomerCode": "19108",
      "Phone": "1122350021",
      "Whatsapp": "11992273836",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2327,
      "Name": "Andreia Aparecida Soares Contabilidade ME",
      "Email": "escritorioobjetivo2022@gmail.com",
      "CustomerCode": "61861",
      "Phone": "1433514430",
      "Whatsapp": "14996766618",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2328,
      "Name": "Andre Bocchi",
      "Email": "decobocchi@msn.com",
      "CustomerCode": "19102",
      "Phone": "1130852737",
      "Whatsapp": "11998000286",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2329,
      "Name": "Cezar Henrique de Abreu",
      "Email": "cezabreu@terra.com.br",
      "CustomerCode": "18924",
      "Phone": "1139919388",
      "Whatsapp": "11998848217",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2330,
      "Name": "Leticia Rodrigues Barros",
      "Email": "leticia1982rodrigu@gmail.com",
      "CustomerCode": "V7HRFH",
      "Phone": "1399765916",
      "Whatsapp": "13997659160",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2331,
      "Name": "Marcelo Henrique de Freitas ",
      "Email": "acacia1@terra.com.br",
      "CustomerCode": "E5ZW4A",
      "Phone": "1124123809",
      "Whatsapp": "11999842350",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2332,
      "Name": "Fabiano Alves de Souza",
      "Email": "souza.fabiano.contabilidade@gmail.com",
      "CustomerCode": "R99924",
      "Phone": "1197399538",
      "Whatsapp": "11973995389",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2333,
      "Name": "Sumayra Contabil Sociedade Simples Ltda",
      "Email": "sucont@sucont.com.br",
      "CustomerCode": "15814",
      "Phone": "1136723022",
      "Whatsapp": "11934710058",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2334,
      "Name": "José de Sá trapia",
      "Email": "trapiajose@gmail.com",
      "CustomerCode": "D6BR6B",
      "Phone": "6698461680",
      "Whatsapp": "66984616802",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2335,
      "Name": "Domingas Nobenda Tussamba",
      "Email": "tudomingastussamba2019@gmail.com",
      "CustomerCode": "B83AZY",
      "Phone": "2197217983",
      "Whatsapp": "55219795605",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2336,
      "Name": "Gilmar Afonso Salesi",
      "Email": "escritoriosalesi@hotmail.com",
      "CustomerCode": "63741",
      "Phone": "1499737518",
      "Whatsapp": "14997375180",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2338,
      "Name": "Hagio Consult e  Assess Contabil",
      "Email": "tania@hagiocontabil.com.br",
      "CustomerCode": "38184",
      "Phone": "1196882784",
      "Whatsapp": "11996882784",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2339,
      "Name": "Anderson Schneider",
      "Email": "andermatheus@hotmail.com",
      "CustomerCode": "65161",
      "Phone": "4599857782",
      "Whatsapp": "45998577826",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2340,
      "Name": "Contabili ConsultoriaA Contabil LTDA",
      "Email": "priscila@contabilisc.com.br",
      "CustomerCode": "Q2WPWP",
      "Phone": "4898463705",
      "Whatsapp": "48984637051",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2341,
      "Name": "Nilogil",
      "Email": "nfernandescontabil@gmail.com",
      "CustomerCode": "65163",
      "Phone": "2299949608",
      "Whatsapp": "22999496088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2342,
      "Name": "Vinicius Ramos Honório",
      "Email": "vinicius.visaocontabil@gmail.com",
      "CustomerCode": "VLNNCL",
      "Phone": "1148145943",
      "Whatsapp": "11948146043",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2343,
      "Name": "Confiance Assessoria Contabiel & Empresarial",
      "Email": "confiancect@gmail.com",
      "CustomerCode": "ADELYN",
      "Phone": "1396016639",
      "Whatsapp": "13996016639",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2344,
      "Name": "Letícia Baesso Gomes",
      "Email": "contato@ampliarecontabilidade.com.br",
      "CustomerCode": "65165",
      "Phone": "3232251000",
      "Whatsapp": "32999263636",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2345,
      "Name": "Stark Contábil Ltda",
      "Email": "contato@starkcontabil.com",
      "CustomerCode": "UJHFLB",
      "Phone": "1144103550",
      "Whatsapp": "11941883288",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2346,
      "Name": "Precito Atacado Importação e Exportação ",
      "Email": "supervisorfinanceiro@precito.net",
      "CustomerCode": "NMNNZ4",
      "Phone": "6521290119",
      "Whatsapp": "65992607399",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2347,
      "Name": "RAF Serviços Administrativos",
      "Email": "raf.contabil@gmail.com",
      "CustomerCode": "19115",
      "Phone": "1128939347",
      "Whatsapp": "11981679164",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2349,
      "Name": "Agemiro Salmeron",
      "Email": "agemiro.s@gmail.com",
      "CustomerCode": "64618",
      "Phone": "1833661086",
      "Whatsapp": "18996557184",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2350,
      "Name": "Vaine Esteves de Souza",
      "Email": "vaine.esteves@yahoo.com.br",
      "CustomerCode": "56WMCF",
      "Phone": "11",
      "Whatsapp": "11989351861",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2351,
      "Name": "Vaine Esteves de Souza",
      "Email": "vaine.esteves@yahoo.com.br",
      "CustomerCode": "FFMPIP",
      "Phone": "11",
      "Whatsapp": "11989351861",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2352,
      "Name": "NS Services",
      "Email": "sitensimoveis@gmail.com",
      "CustomerCode": "19103",
      "Phone": "11",
      "Whatsapp": "11985850282",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2353,
      "Name": "Kijeme Travel Hotéis ltda",
      "Email": "contabilidade@resortlatorre.com.br",
      "CustomerCode": "65164",
      "Phone": "7321051702",
      "Whatsapp": "73988174641",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2354,
      "Name": "Davidson Pimentel Pereira da Silva",
      "Email": "dppsilva93@gmail.com",
      "CustomerCode": "EC1PAJ",
      "Phone": "2196539707",
      "Whatsapp": "21965397070",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2355,
      "Name": "G6 Contabilidade LTDA",
      "Email": "legalizacao.garcia@hotmail.com",
      "CustomerCode": "1VIBS3",
      "Phone": "4330470201",
      "Whatsapp": "4330470201",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2357,
      "Name": "Renato Goulart",
      "Email": "goulart.renato@icloud.com",
      "CustomerCode": "38385",
      "Phone": "",
      "Whatsapp": "21996152665",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2358,
      "Name": "Débora",
      "Email": "debora@drassessoriacontabil.com.br",
      "CustomerCode": "ELLK5T",
      "Phone": "1138527903",
      "Whatsapp": "11984050058",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2359,
      "Name": "Graziela Kulkauski Calvo",
      "Email": "grazielacalvo@hotmail.com",
      "CustomerCode": "18913",
      "Phone": "",
      "Whatsapp": "11985864629",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2360,
      "Name": "Eidinete glória dá silva ",
      "Email": "eidineteglorianete@gmail.com",
      "CustomerCode": "XI1YYG",
      "Phone": "6599041116",
      "Whatsapp": "6599041116",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2361,
      "Name": "Josefa Pereira dos santos",
      "Email": "josefasntts@gmail.com",
      "CustomerCode": "JG1K96",
      "Phone": "5513996636",
      "Whatsapp": "55139966366",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2362,
      "Name": "Agil Certificadora LTDA",
      "Email": "agil.certificadora2022@gmail.com",
      "CustomerCode": "65169",
      "Phone": "8199140995",
      "Whatsapp": "81991409954",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2363,
      "Name": "Luciana",
      "Email": "luciana@baptistaluz.com.br",
      "CustomerCode": "19106",
      "Phone": "1123680572",
      "Whatsapp": "11985006666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2365,
      "Name": "Noemi Marli de Alencar",
      "Email": "noemicontadora@gmail.com",
      "CustomerCode": "65167",
      "Phone": "1533211954",
      "Whatsapp": "15997550620",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2366,
      "Name": "Danilo Lourenço",
      "Email": "danilo@grupomaisq.com.br",
      "CustomerCode": "65166",
      "Phone": "3284544608",
      "Whatsapp": "32984544608",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2368,
      "Name": "Denise Olivia Dias",
      "Email": "dod.denisedias@outlook.com",
      "CustomerCode": "W1AKIH",
      "Phone": "2199888829",
      "Whatsapp": "21991625035",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2369,
      "Name": "Alcides Antonio de Souza",
      "Email": "thiago@suporteae.com.br",
      "CustomerCode": "65171",
      "Phone": "1532374778",
      "Whatsapp": "15997675160",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2371,
      "Name": "BPM Empreendimentos Imobiliários LTDA",
      "Email": "contabilidade.go@buritiempreendimentos.com.br",
      "CustomerCode": "19110",
      "Phone": "6232208800",
      "Whatsapp": "62982807868",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2372,
      "Name": "Darci de Jesus Silva Soares Leitão",
      "Email": "terracidada2019@gmail.com",
      "CustomerCode": "VAS1ZN",
      "Phone": "2198259502",
      "Whatsapp": "21982595020",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2374,
      "Name": "Maria Victoria Guarnieri de Almeida",
      "Email": "victoriaconta@yahoo.com.br",
      "CustomerCode": "RYW4PE",
      "Phone": "1130441338",
      "Whatsapp": "11999437939",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2375,
      "Name": "Cleuza Farias",
      "Email": "cleuzaolive@gmail.com",
      "CustomerCode": "19109",
      "Phone": "1184617140",
      "Whatsapp": "11984617140",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2376,
      "Name": "Ivan Baptista de Sena",
      "Email": "contato@senaconsultoriacontabil.com.br",
      "CustomerCode": "3YA0KB",
      "Phone": "1195287154",
      "Whatsapp": "11952871543",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2377,
      "Name": "José Carlos dos Santos Oliveira",
      "Email": "econservcontabil383@gmail.com",
      "CustomerCode": "6C15BQ",
      "Phone": "8981091514",
      "Whatsapp": "89981407842",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2378,
      "Name": "Fabrizio Tadeu Vieira",
      "Email": "fabrizio.vieira@gmail.com",
      "CustomerCode": "38594",
      "Phone": "1182443362",
      "Whatsapp": "11982443362",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2381,
      "Name": "Edson de Souza",
      "Email": "construcabo@hotmail.com",
      "CustomerCode": "17940",
      "Phone": "1120218000",
      "Whatsapp": "11916210060",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2382,
      "Name": "MCD Contabil",
      "Email": "elias.guarino@mcdcontabil.com.br",
      "CustomerCode": "65168",
      "Phone": "2196731811",
      "Whatsapp": "21967318117",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2383,
      "Name": "Isabel Cristiano tony ",
      "Email": "serralheriadegradee@gmail.com",
      "CustomerCode": "QN8LLQ",
      "Phone": "3298839408",
      "Whatsapp": "32988394084",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2384,
      "Name": "Emilie Silva de Carvalho",
      "Email": "emiliemika@msn.com",
      "CustomerCode": "UPE8OE",
      "Phone": "1191478516",
      "Whatsapp": "11991478516",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2385,
      "Name": "Coroline Batista Silva",
      "Email": "contato@consultoriadcastro.com.br",
      "CustomerCode": "J8SC3A",
      "Phone": "1194951162",
      "Whatsapp": "11949511625",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2386,
      "Name": "Sandra Granja",
      "Email": "granjacontabilidade@gmail.com",
      "CustomerCode": "17276",
      "Phone": "1199994385",
      "Whatsapp": "11999943858",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2387,
      "Name": "Sebastiana de Santana Azevedo ",
      "Email": "ssazevedoconsultoria@gmail.com",
      "CustomerCode": "19112",
      "Phone": "1132977138",
      "Whatsapp": "11997482003",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2388,
      "Name": "Rogers Contabilidade LTDA",
      "Email": "rogerscontabilidade@terra.com.br",
      "CustomerCode": "19111",
      "Phone": "1138331400",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2389,
      "Name": "Gilmar Bramdao",
      "Email": "gilmarbrandao81@gmail.com",
      "CustomerCode": "15934",
      "Phone": "1197598971",
      "Whatsapp": "11975989712",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2391,
      "Name": "Abaci Gestão Contábil Ltda",
      "Email": "anderson.tavares@abaci.com.br",
      "CustomerCode": "65170",
      "Phone": "1297770579",
      "Whatsapp": "12997770579",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2392,
      "Name": "Edson kendy Osako",
      "Email": "nhkcontabil@gmail.com",
      "CustomerCode": "W3YPDM",
      "Phone": "1135224345",
      "Whatsapp": "11989376237",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2393,
      "Name": "Catheo Organização Contábil",
      "Email": "escstoantonio@uol.com.br",
      "CustomerCode": "62262",
      "Phone": "1936413149",
      "Whatsapp": "19991612128",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2394,
      "Name": "Ana Cléia Teixeira da Silva Alves",
      "Email": "anasilvaalves.contabil@gmail.com",
      "CustomerCode": "5SRIXB",
      "Phone": "1196551518",
      "Whatsapp": "11965515187",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2396,
      "Name": "Silvia Junior Contabilidade LTDA",
      "Email": "contabil@calculize.com.br",
      "CustomerCode": "OCFMFY",
      "Phone": "1936735519",
      "Whatsapp": "1936735519",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2397,
      "Name": "Jucelino Geraldo",
      "Email": "jugmag@gmail.com",
      "CustomerCode": "17963",
      "Phone": "1127378146",
      "Whatsapp": "11996673527",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2398,
      "Name": "Intercon Contadores & Consultores Ltda",
      "Email": "intercon.web@gmail.com",
      "CustomerCode": "MHSHY7",
      "Phone": "7930859590",
      "Whatsapp": "79988104014",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2399,
      "Name": "Carlos Blanco",
      "Email": "cr.blanco@uol.com.br",
      "CustomerCode": "SSBV45",
      "Phone": "1195454802",
      "Whatsapp": "11995454802",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2400,
      "Name": "Nelson Francisco de Assis",
      "Email": "reformasgeraisplanej.mov@gmail.com",
      "CustomerCode": "DMSKLG",
      "Phone": "1998301347",
      "Whatsapp": "19989242824",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2401,
      "Name": "Caio Nitz",
      "Email": "caionitz@gmail.com",
      "CustomerCode": "19113",
      "Phone": "1198813998",
      "Whatsapp": "11878139986",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2402,
      "Name": "E.C.E-Escritório Contábil Evolução LTDA",
      "Email": "e.c.evolucao@gmail.com",
      "CustomerCode": "0K6CA9",
      "Phone": "1639391799",
      "Whatsapp": "16996426634",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2403,
      "Name": "Assessconti Contabilidade",
      "Email": "claudio@assessconti.com.br",
      "CustomerCode": "17856",
      "Phone": "1139744451",
      "Whatsapp": "11994074878",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2404,
      "Name": "Ribeiro & Carvalho Serviços de Escritório Sociedade Unipessoal LTDA",
      "Email": "liderconta@terra.com.br",
      "CustomerCode": "64420",
      "Phone": "1238834244",
      "Whatsapp": "12997074322",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2405,
      "Name": "Escritório Marilia de Contabilidade LTDA",
      "Email": "marta@mariliacontabil.com.br",
      "CustomerCode": "61981",
      "Phone": "1434131688",
      "Whatsapp": "14997380943",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2406,
      "Name": "Daniele Lima Gonçalves dos Reis Ferreira ",
      "Email": "dlgconsultoriacontabil@gmail.com",
      "CustomerCode": "19114",
      "Phone": "1197443939",
      "Whatsapp": "11997443939",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2407,
      "Name": "DI Sisto e Borghese Sociedade de Advogados",
      "Email": "marcos@disistoeborghese.adv.br",
      "CustomerCode": "35320",
      "Phone": "1143075682",
      "Whatsapp": "11982120651",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2408,
      "Name": "Eiffel Assessoria Contabil LTDA",
      "Email": "ffalcioni@uol.com.br",
      "CustomerCode": "15012",
      "Phone": "1128921403",
      "Whatsapp": "11989259527",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2409,
      "Name": "Rosa Maria Benedita Couto ",
      "Email": "rosamaria_snp@hotmail.com",
      "CustomerCode": "7Q00JZ",
      "Phone": "6696785337",
      "Whatsapp": "66996785337",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2410,
      "Name": "Isabel Cristina Leal Vieira",
      "Email": "isabel.leal@venuscontabil.com.br",
      "CustomerCode": "35321",
      "Phone": "1124083388",
      "Whatsapp": "11967199383",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2411,
      "Name": "Davy de Oliveira Campos",
      "Email": "juridico3@camposcsc.com.br",
      "CustomerCode": "D99CBI",
      "Phone": "3438219814",
      "Whatsapp": "34938219814",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2412,
      "Name": "Loana G. Lenski",
      "Email": "loana-85@hotmail.com",
      "CustomerCode": "65172",
      "Phone": "4132226018",
      "Whatsapp": "41984119556",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2413,
      "Name": "Cicero Carlos da Silva",
      "Email": "cicerocarlosdasilva429@gmail.com",
      "CustomerCode": "HUEWRV",
      "Phone": "5511987695",
      "Whatsapp": "55119876956",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2414,
      "Name": "Botelhos CVontabilidade",
      "Email": "bota@botelhoscontabilidade.com.br",
      "CustomerCode": "61450",
      "Phone": "1637221065",
      "Whatsapp": "16997274470",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2415,
      "Name": "Dirceu Vieira Filho",
      "Email": "martins2vieira56@gmail.com",
      "CustomerCode": "19199",
      "Phone": "1198863125",
      "Whatsapp": "11988631250",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2416,
      "Name": "Eriberto Alves dos Santos",
      "Email": "alveseriberto57@gmail.com",
      "CustomerCode": "SXXPMR",
      "Phone": "3484166825",
      "Whatsapp": "3485166825",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2417,
      "Name": "Nogueira Lins Veículos Peças e Serviços LTDA",
      "Email": "marcia.ferreira@gruponogueiralins.com.br",
      "CustomerCode": "65173",
      "Phone": "1832651719",
      "Whatsapp": "18996322087",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2418,
      "Name": "Gilberto Ramos de Araújo",
      "Email": "gilbertodearaujouber@gmail.com",
      "CustomerCode": "JIB7SY",
      "Phone": "1198171329",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2419,
      "Name": "Roniere José de Medeiros",
      "Email": "ronieremedeiros@hotmail.com",
      "CustomerCode": "62137",
      "Phone": "1194859165",
      "Whatsapp": "11948591659",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2420,
      "Name": "Jhonatan Willian da Silva Carneiro dos Santos ",
      "Email": "jhonatangremio1994@gmail.com",
      "CustomerCode": "3XFE91",
      "Phone": "5199321708",
      "Whatsapp": "51993217082",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2421,
      "Name": "Claudio Martins",
      "Email": "cloud.contabil.rj@gmail.com",
      "CustomerCode": "ZTCQG0",
      "Phone": "2120426647",
      "Whatsapp": "21996258807",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2422,
      "Name": "P.C. Bastos Contabilidade",
      "Email": "pcbastoscont@gmail.com",
      "CustomerCode": "KQ9UDA",
      "Phone": "1122119787",
      "Whatsapp": "11982165048",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2423,
      "Name": "janilton gomes",
      "Email": "grupomastercontabilidade@gmail.com",
      "CustomerCode": "R114Y6",
      "Phone": "9134253797",
      "Whatsapp": "91981831474",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2424,
      "Name": "Valoratti Serviços Administrativos",
      "Email": "valoratti@valoratti.com",
      "CustomerCode": "5Z911Y",
      "Phone": "1194880242",
      "Whatsapp": "11948802423",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2425,
      "Name": "Rafael Eduardo Xavier Gomes ",
      "Email": "eduardorafael800@gmail.com",
      "CustomerCode": "A8DS9L",
      "Phone": "1139857076",
      "Whatsapp": "11985419202",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2426,
      "Name": "Getulio Vargas da Rocha Junior",
      "Email": "getulio@rochacontabil.net.br",
      "CustomerCode": "64913",
      "Phone": "1998105837",
      "Whatsapp": "19981058375",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2427,
      "Name": "Escresul Assessoria e Organização LTDA",
      "Email": "adolar@escresul.com.br",
      "CustomerCode": "17783 ",
      "Phone": "1129558666",
      "Whatsapp": "11995604403",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2429,
      "Name": "Fernando Marioto",
      "Email": "vorpol@gmail.com",
      "CustomerCode": "YTNYYE",
      "Phone": "1195138482",
      "Whatsapp": "11995138482",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2430,
      "Name": "Marcio Chukri de Oliveira Khouri",
      "Email": "mcokhouri@hotmail.com",
      "CustomerCode": "VNVT68",
      "Phone": "1158254042",
      "Whatsapp": "11939276012",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2431,
      "Name": "Padrão Assessoria Empresarial LTDA ",
      "Email": "legalizacao@padraocontabilidade.com",
      "CustomerCode": "65181",
      "Phone": "4530554141",
      "Whatsapp": "45999330518",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2432,
      "Name": "Plínio Amaro Martins Palmeira",
      "Email": "dr.plinio@terra.com.br",
      "CustomerCode": "64162",
      "Phone": "1932731414",
      "Whatsapp": "19996778405",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2433,
      "Name": "Fernanda Santos Santana",
      "Email": "fernanda130689@gmail.com",
      "CustomerCode": "WERSI6",
      "Phone": "5511972988",
      "Whatsapp": "55119729885",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2434,
      "Name": "Rodrigo de Souza Faria",
      "Email": "rodrigoperfilmodas@hotmail.com",
      "CustomerCode": "8TNG3Q",
      "Phone": "3499992066",
      "Whatsapp": "34999920667",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2435,
      "Name": "Nani Assessoria Empresarial Ltda",
      "Email": "bm3.solucoesempresariais@outlok.com",
      "CustomerCode": "65174",
      "Phone": "1111111111",
      "Whatsapp": "11939098464",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2436,
      "Name": "Joao Batista da Costa",
      "Email": "opcaoctb@hotmail.com",
      "CustomerCode": "19116",
      "Phone": "1125466763",
      "Whatsapp": "11985064555",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2437,
      "Name": "Jessica Ann Bernstein Heumann",
      "Email": "jessica@baptistaluz.com.br",
      "CustomerCode": "17996",
      "Phone": "1130407050",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2438,
      "Name": "Fabio Roberto Turnes",
      "Email": "frturnes@hotmail.com",
      "CustomerCode": "19117",
      "Phone": "1125485130",
      "Whatsapp": "11985741000",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2440,
      "Name": "Emasfi Empresa de Assessoria e Serviços Fiscais",
      "Email": "lucas.camargo@truecpagroup.com",
      "CustomerCode": "UO8AYU",
      "Phone": "1938262087",
      "Whatsapp": "19981500441",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2441,
      "Name": "Andreia Athayde Maia",
      "Email": "mendesmaia.cont@gmail.com",
      "CustomerCode": "0CGZEY",
      "Phone": "1139766464",
      "Whatsapp": "11982794044",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2442,
      "Name": "José Joaquim Teodoro",
      "Email": "teodorocontabil@hotmail.com",
      "CustomerCode": "ZO7J5A",
      "Phone": "1132570722",
      "Whatsapp": "11993830735",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2443,
      "Name": "Vanderlei de Oliveira Silva",
      "Email": "vanderlei.contador@gmail.com",
      "CustomerCode": "H65HWT",
      "Phone": "1599167509",
      "Whatsapp": "15991675094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2444,
      "Name": "Burge Contabilidade e Consultoria LTDa",
      "Email": "marcel@burgeconsultoria.com.br",
      "CustomerCode": "65182",
      "Phone": "1698204254",
      "Whatsapp": "16982042545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2445,
      "Name": "Luciano Possente",
      "Email": "administrativo@possenteadvogados.com.br",
      "CustomerCode": "65176",
      "Phone": "1194041423",
      "Whatsapp": "11940414233",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2446,
      "Name": "Controle Contabilidade e Assessoria",
      "Email": "sefazmt@ccacontrolecontabilidade.com.br",
      "CustomerCode": "65177",
      "Phone": "6634240028",
      "Whatsapp": "66999227080",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2448,
      "Name": "Forte Contabilidade e Consultoria Empresarial LTDA",
      "Email": "carlos@fortecontabilidade.com",
      "CustomerCode": "65183",
      "Phone": "3532973765",
      "Whatsapp": "35988862905",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2449,
      "Name": "Sommar Contabilidade Empresarial LTDA",
      "Email": "contato@sommarcontabilidade.com.br",
      "CustomerCode": "65175",
      "Phone": "1124274430",
      "Whatsapp": "11979903880",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2450,
      "Name": "Nacef Contabilidade e Suporte Empresarial LTDA",
      "Email": "joaoneto@clientecontabil.com.br",
      "CustomerCode": "15074",
      "Phone": "1132054123",
      "Whatsapp": "11930384123",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2451,
      "Name": "Contabilidade Campos LTDA",
      "Email": "occfinanceiro@uol.com.br",
      "CustomerCode": "37310",
      "Phone": "1146166267",
      "Whatsapp": "11955700959",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2453,
      "Name": "Alcides Julio da Silveira Filho",
      "Email": "alcidesjulio@gmail.com",
      "CustomerCode": "17082",
      "Phone": "1198208750",
      "Whatsapp": "11982083750",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2454,
      "Name": "Renata Menchon Felcar",
      "Email": "renatafelcar@gmail.com",
      "CustomerCode": "97SITT",
      "Phone": "1139783496",
      "Whatsapp": "11994059179",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2455,
      "Name": "Suzi Paula de Souza Rocha",
      "Email": "sprocha04@gmail.com",
      "CustomerCode": "MFCW21",
      "Phone": "2198817352",
      "Whatsapp": "21988173521",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2456,
      "Name": "Barros e Descrove Contabilidade LTDA",
      "Email": "legalizacao1@barrosedescrove.com.br",
      "CustomerCode": "YF1OH5",
      "Phone": "1134021075",
      "Whatsapp": "11968663476",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2457,
      "Name": "Vinicius Emilio Nicocelli",
      "Email": "vinicius@zapcontabil.com.br",
      "CustomerCode": "SYQXI7",
      "Phone": "1122216984",
      "Whatsapp": "11964823004",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2458,
      "Name": "Fasanosp Contabilidade LTDA",
      "Email": "sueli@fasanosp.com.br",
      "CustomerCode": "17609",
      "Phone": "1127719884",
      "Whatsapp": "11945537870",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2459,
      "Name": "CCC Contabil Sociedade Simples",
      "Email": "m.pereira@complianceconsultoria.com.br",
      "CustomerCode": "19118",
      "Phone": "1132831520",
      "Whatsapp": "11992049959",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2460,
      "Name": "Rafael da Costa Silva ",
      "Email": "rafaelcostacontabil@gmail.com",
      "CustomerCode": "37378",
      "Phone": "1147035452",
      "Whatsapp": "11983951686",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2461,
      "Name": "Newccounting Soluções Contábeis e Financeiras LTDA",
      "Email": "barbara.flaviane@newccounting.com.br",
      "CustomerCode": "17834",
      "Phone": "1143273974",
      "Whatsapp": "11961065715",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2463,
      "Name": "Marcos Guilherme Silva Lima",
      "Email": "elfasassessoria@gmail.com",
      "CustomerCode": "YP7HM3",
      "Phone": "8181596519",
      "Whatsapp": "81981596519",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2465,
      "Name": "Contabilidade Moderna",
      "Email": "contabilidademoderna@live.com",
      "CustomerCode": "18068",
      "Phone": "1127294583",
      "Whatsapp": "11985747303",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2466,
      "Name": "ARKA Online Contabilidade",
      "Email": "arkasac@arkaonline.com.br",
      "CustomerCode": "65179",
      "Phone": "6199804048",
      "Whatsapp": "61999775163",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2467,
      "Name": "Abacont Serviços Contabeis LTDA",
      "Email": "abacont@abacont.com.br",
      "CustomerCode": "65178",
      "Phone": "2135470012",
      "Whatsapp": "21971442539",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2469,
      "Name": "Jaine Barros de Oliveira ",
      "Email": "jainebarros14@icloud.com",
      "CustomerCode": "19119",
      "Phone": "1135920496",
      "Whatsapp": "11942962446",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2470,
      "Name": "Jonas Escudeiro Kozak",
      "Email": "contabil.jkozak4@gmail.com",
      "CustomerCode": "19120",
      "Phone": "1126887897",
      "Whatsapp": "11974950114",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2471,
      "Name": "Daniel Ferreira Dias ",
      "Email": "contato@dfiscalassessoria.com",
      "CustomerCode": "35322",
      "Phone": "1150449386",
      "Whatsapp": "11950449386",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2472,
      "Name": "Maria Ines Pereira",
      "Email": "capcontmaria@yahoo.com.br",
      "CustomerCode": "UWEZLT",
      "Phone": "3598802784",
      "Whatsapp": "35988027849",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2473,
      "Name": "Gilson Rocha",
      "Email": "gilson@agilecont.com.br",
      "CustomerCode": "EWU4X9",
      "Phone": "1196226160",
      "Whatsapp": "11962261601",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2474,
      "Name": "Julio Junji Tamae",
      "Email": "jjtamae@uol.com.br",
      "CustomerCode": "15310",
      "Phone": "1155810822",
      "Whatsapp": "11999901224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2475,
      "Name": "Marapa Contabilidade LTDA",
      "Email": "mmobinata@gmail.com",
      "CustomerCode": "19161",
      "Phone": "1198729479",
      "Whatsapp": "11987294795",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2476,
      "Name": "Eduardo Heloany Ferreira Viana",
      "Email": "eduheloany@gmail.com",
      "CustomerCode": "16023",
      "Phone": "1134765297",
      "Whatsapp": "11993280471",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2477,
      "Name": "Active Consultoria",
      "Email": "silvio@activeconsultoria.com.br",
      "CustomerCode": "19122",
      "Phone": "1120639197",
      "Whatsapp": "11999334133",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2478,
      "Name": "Leonardo Alves Feliz",
      "Email": "artcontabilidade.felix@gmail.com",
      "CustomerCode": "65180",
      "Phone": "6133515001",
      "Whatsapp": "61992514557",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2479,
      "Name": "Willian Romual da Silva",
      "Email": "willian@romualconsultoria.com.br",
      "CustomerCode": "19121",
      "Phone": "1154491773",
      "Whatsapp": "11954491773",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2480,
      "Name": "Confiance Assessoria Empresarial",
      "Email": "confiancect@gmail.com",
      "CustomerCode": "64169",
      "Phone": "1396016639",
      "Whatsapp": "13996016639",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2481,
      "Name": "Marcio Corsini Bucheb",
      "Email": "marcio@condororg.com.br",
      "CustomerCode": "19123",
      "Phone": "1155852040",
      "Whatsapp": "11975420648",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2482,
      "Name": "Erineide Belarmino Esteves",
      "Email": "erineideesteves@gmail.com",
      "CustomerCode": "6LC6ZC",
      "Phone": "2197316255",
      "Whatsapp": "21973162557",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2483,
      "Name": "Marcos Fernandes da Cruz",
      "Email": "mfcassessoia@gmail.com",
      "CustomerCode": "L4YSL4",
      "Phone": "1433254205",
      "Whatsapp": "1433254205",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2484,
      "Name": "Rodrigo Alves de França",
      "Email": "contabilidadesmartcon@gmail.com",
      "CustomerCode": "38540",
      "Phone": "1149755579",
      "Whatsapp": "11949038098",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2485,
      "Name": "Renan Felipe da Silva ",
      "Email": "renanfeliperfds@gmail.com",
      "CustomerCode": "6LOV5S",
      "Phone": "1291295348",
      "Whatsapp": "12991103728",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2486,
      "Name": "Alan Marcos",
      "Email": "alanmarcos2013@gmail.com",
      "CustomerCode": "65184",
      "Phone": "1291295348",
      "Whatsapp": "12991103728",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2487,
      "Name": "Wanderley de Castro",
      "Email": "wanderley@conselheirocontabil.com.br",
      "CustomerCode": "19124",
      "Phone": "1120928078",
      "Whatsapp": "11948003126",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2488,
      "Name": "Lamberti Brasil Produtos Químicos LTDA",
      "Email": "renato.verzignassi@lambra.com.br",
      "CustomerCode": "65185",
      "Phone": "1931133257",
      "Whatsapp": "19981750815",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2489,
      "Name": "José Carlos Previtali",
      "Email": "jcprevitali2@gmail.com",
      "CustomerCode": "18996",
      "Phone": "1139761215",
      "Whatsapp": "11999554645",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2491,
      "Name": "Conde Organização Contábil LTDA",
      "Email": "condecontabilidade@terra.com.br",
      "CustomerCode": "SKPTI2",
      "Phone": "1124400279",
      "Whatsapp": "1124400279",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2492,
      "Name": "Fernandes Soluções",
      "Email": "wfsouza33@gmail.com",
      "CustomerCode": "17789",
      "Phone": "1125974156",
      "Whatsapp": "11981643804",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2493,
      "Name": "Disal Administradora de Consórcios Ltda",
      "Email": "alguedes@grupodisal.com.br",
      "CustomerCode": "18757",
      "Phone": "1150795218",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2494,
      "Name": "Marcio Alves Valle",
      "Email": "marcio.alves2007@terra.com.br",
      "CustomerCode": "CZLXOU",
      "Phone": "1128940526",
      "Whatsapp": "11976245882",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2495,
      "Name": "Bicalho & Associados Assessoria Contabil LTDA ",
      "Email": "pablo@bicalho.com",
      "CustomerCode": "UAXUOY",
      "Phone": "3188085516",
      "Whatsapp": "31988085516",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2496,
      "Name": "Adriano Oliveira Bastos Barboza",
      "Email": "adriano.barbosa@tsleng.com.br",
      "CustomerCode": "J0KDDI",
      "Phone": "1199198536",
      "Whatsapp": "1199198536",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2497,
      "Name": "Juarez de Lima Franco",
      "Email": "juarezcontabilidade@terra.com.br",
      "CustomerCode": "19125",
      "Phone": "1184058932",
      "Whatsapp": "11984058932",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2498,
      "Name": "Breda e Breda Advogados",
      "Email": "atendimento@bredaebredaadvogados.com.br",
      "CustomerCode": "ZP47SU",
      "Phone": "5435227377",
      "Whatsapp": "54996367086",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2499,
      "Name": "Emerson Jose Arruda",
      "Email": "e.a.pecasautomotivas@outlook.com",
      "CustomerCode": "W1CWIL",
      "Phone": "1100000000",
      "Whatsapp": "11986195042",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2501,
      "Name": "Vorios Outsourcing Serviços Contábeis",
      "Email": "jessika@vorios.com.br",
      "CustomerCode": "65186",
      "Phone": "4134084921",
      "Whatsapp": "41996845749",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2502,
      "Name": "Karim cristina Emídio",
      "Email": "karimcristina1979@gmail.com",
      "CustomerCode": "0AUKJ2",
      "Phone": "1397933565",
      "Whatsapp": "13997933565",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2503,
      "Name": "Fábio Brussolo",
      "Email": "brussolo@brussoloassessoria.com.br",
      "CustomerCode": "17127",
      "Phone": "1128943849",
      "Whatsapp": "11972331151",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2504,
      "Name": "Keila Bariani",
      "Email": "keila@atthos.com.br",
      "CustomerCode": "0XRC7X",
      "Phone": "1120916935",
      "Whatsapp": "11984058257",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2505,
      "Name": "Escritorio MBA Contabilidade Ltda",
      "Email": "mauricioluna@mbacontabilidade.com.br",
      "CustomerCode": "19126",
      "Phone": "1147809230",
      "Whatsapp": "1147809230",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2506,
      "Name": "Maxtime Contab. e Consult. Legais S/S LTDA",
      "Email": "rh.maxtime@gmail.com",
      "CustomerCode": "MOKIUB",
      "Phone": "1121718700",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2507,
      "Name": "Maxtime Contab. e Consult. Legais S/S LTDA",
      "Email": "rh.maxtime@gmail.com",
      "CustomerCode": "EM8DGS",
      "Phone": "1121718700",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2508,
      "Name": "Lucia Nobre de Miranda Palhano",
      "Email": "documentosmalu1@gmail.com",
      "CustomerCode": "9LRJB4",
      "Phone": "6733313666",
      "Whatsapp": "67996537323",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2509,
      "Name": "Unicont Unidos para Contabilidade SS Ltda ",
      "Email": "luiz.honorato@unicontitu.com.br",
      "CustomerCode": "61125",
      "Phone": "1140221627",
      "Whatsapp": "11988113186",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2510,
      "Name": "HS Assessoria Contabil Eireli ME",
      "Email": "marcelo@hsbrasilcontabil.cnt.br",
      "CustomerCode": "38439",
      "Phone": "1141774469",
      "Whatsapp": "11999635848",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2511,
      "Name": "Ana Célia dias Mota",
      "Email": "ana_dp_luana@hotmail.com",
      "CustomerCode": "J1HM74",
      "Phone": "8599677105",
      "Whatsapp": "85996771050",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2512,
      "Name": "Sibely Sousa",
      "Email": "sibelycps@hotmail.com",
      "CustomerCode": "19183",
      "Phone": "1139211671",
      "Whatsapp": "11996028986",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2513,
      "Name": "João Francisco Gasparoto",
      "Email": "jfgasparoto@gmail.com",
      "CustomerCode": "AWQ9CV",
      "Phone": "1499697198",
      "Whatsapp": "19999882928",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2514,
      "Name": "Giuliano Antonio Granito",
      "Email": "ggranito84@gmail.com",
      "CustomerCode": "19138",
      "Phone": "1175912391",
      "Whatsapp": "11975912391",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2515,
      "Name": "Ludman Advogados Associados",
      "Email": "ludman@ludman.adv.br",
      "CustomerCode": "LPK9RR",
      "Phone": "1198518331",
      "Whatsapp": "11985183311",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2516,
      "Name": "Carlos Henrique Ludman",
      "Email": "ludman@ludman.adv.br",
      "CustomerCode": "19127",
      "Phone": "1198518331",
      "Whatsapp": "11985183311",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2517,
      "Name": "Gideoni Eikiti Hirose",
      "Email": "prodconsult@terra.com.br",
      "CustomerCode": "19134",
      "Phone": "1147746188",
      "Whatsapp": "11947746188",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2518,
      "Name": "Luiz Renato Moreira Pereira",
      "Email": "lrenatopereira@uol.com.br",
      "CustomerCode": "17506",
      "Phone": "1199186118",
      "Whatsapp": "11991861189",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2519,
      "Name": "S.Moreno Auditores e Consultores LTDA",
      "Email": "sidnei@smorenoauditores.com.br",
      "CustomerCode": "19129",
      "Phone": "1129191819",
      "Whatsapp": "11971209512",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2520,
      "Name": "Quality Score Legalização LTDA",
      "Email": "cintia.cruz@qsco.com.br",
      "CustomerCode": "19131",
      "Phone": "1127775100",
      "Whatsapp": "11993676290",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2521,
      "Name": "Sandra Regina da Silva",
      "Email": "sandra.rs963@gmail.com",
      "CustomerCode": "UT5LXJ",
      "Phone": "1199992227",
      "Whatsapp": "11999922272",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2522,
      "Name": "konthabyl 1",
      "Email": "marta@konthabyl.com.br",
      "CustomerCode": "17027",
      "Phone": "1136451413",
      "Whatsapp": "11973221077",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2523,
      "Name": "Adriana Gomes de Oliveira",
      "Email": "olivadri@gmail.com",
      "CustomerCode": "19128",
      "Phone": "1194257700",
      "Whatsapp": "11942577002",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2524,
      "Name": "Adilio Ferreira de Andrade ",
      "Email": "adilio.andrade04@gmail.com",
      "CustomerCode": "BAT1FZ",
      "Phone": "3199756733",
      "Whatsapp": "31997567335",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2525,
      "Name": "Arvel Contabilidade Ltda",
      "Email": "adm@arvel.com.br",
      "CustomerCode": "S7EJ4I",
      "Phone": "5134727020",
      "Whatsapp": "51034727020",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2526,
      "Name": "Daniella Barreto de Melo",
      "Email": "contabil@contabilebm.com.br",
      "CustomerCode": "19130",
      "Phone": "1136922674",
      "Whatsapp": "11963076535",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2527,
      "Name": "Liderance Contabilidade LTDA",
      "Email": "escritoriodary@uol.com.br",
      "CustomerCode": "15457",
      "Phone": "1129574336",
      "Whatsapp": "11947516971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2528,
      "Name": "Antonio da Silva Pereira Santos",
      "Email": "antonio@cretacontabilidade.com.br",
      "CustomerCode": "37480",
      "Phone": "1145515683",
      "Whatsapp": "11984587461",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2529,
      "Name": "Avalon Participações Sociais LTDA",
      "Email": "juridico@capitalsp.com.br",
      "CustomerCode": "19135",
      "Phone": "1155088100",
      "Whatsapp": "11990130825",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2530,
      "Name": "Deivid Santos Souza ",
      "Email": "magna2700@gmeil.com",
      "CustomerCode": "82OF30",
      "Phone": "2199470297",
      "Whatsapp": "21977165087",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2531,
      "Name": "Monetae Contabilidade LTDA ME",
      "Email": "leonardo@monetaecontabilidade.com.br",
      "CustomerCode": "65187",
      "Phone": "1733044750",
      "Whatsapp": "1733044750",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2533,
      "Name": "Disal Corretora de Seguros Ltda",
      "Email": "jarruda@grupodisal.com.br",
      "CustomerCode": "19176",
      "Phone": "1150795197",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2534,
      "Name": "Gislene Venancio Honorato",
      "Email": "gisleneguardian@hotmail.com",
      "CustomerCode": "50XYFT",
      "Phone": "1199438733",
      "Whatsapp": "11994387337",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2535,
      "Name": "Josiane Ramos de Souza",
      "Email": "josyane_ramos@yahoo.com.br",
      "CustomerCode": "65188",
      "Phone": "3897301784",
      "Whatsapp": "38997301784",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2536,
      "Name": "Contaf Escritório de Contabilidade SS",
      "Email": "favaro@contafjd.com.br",
      "CustomerCode": "61972",
      "Phone": "1148070414",
      "Whatsapp": "11968399500",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2537,
      "Name": "Emerson Bulka Contrera",
      "Email": "pconta@uol.com.br",
      "CustomerCode": "35323",
      "Phone": "1124099768",
      "Whatsapp": "11999707935",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2538,
      "Name": "Luiz Marciano Dias ",
      "Email": "luizdias_marciano@yahoo.com.br",
      "CustomerCode": "DAB204",
      "Phone": "3199913874",
      "Whatsapp": "31993600976",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2539,
      "Name": "Cristina Lima de Souza",
      "Email": "lymasouza031994@gmail.com",
      "CustomerCode": "3TPSB2",
      "Phone": "2798874143",
      "Whatsapp": "27988741431",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2540,
      "Name": "Ângelo Lombardi",
      "Email": "alombardi@uol.com.br",
      "CustomerCode": "19132",
      "Phone": "1136739299",
      "Whatsapp": "11996412943",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2541,
      "Name": "Jonas Comin de Campos",
      "Email": "comin@uol.com.br",
      "CustomerCode": "KCAKSZ",
      "Phone": "1236333084",
      "Whatsapp": "12997533370",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2542,
      "Name": "CMP Assessoria Contábil LTDA",
      "Email": "cmpcontabil@cmpcontabil.com.br",
      "CustomerCode": "15714",
      "Phone": "1132554399",
      "Whatsapp": "11970729692",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2543,
      "Name": "Andrea Imperador ",
      "Email": "aimperador@uol.com.br",
      "CustomerCode": "17086",
      "Phone": "1129141528",
      "Whatsapp": "11991590532",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2544,
      "Name": "Felipe Kim Advogados",
      "Email": "fkim@fkadv.com",
      "CustomerCode": "19158",
      "Phone": "1199104132",
      "Whatsapp": "11991041329",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2545,
      "Name": "Elaine Aparecida da cunha Rodrigues ",
      "Email": "elainerodrigues260311@gmail.com",
      "CustomerCode": "5XB4RG",
      "Phone": "1598826650",
      "Whatsapp": "15988266504",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2546,
      "Name": "Adriana Isabela Moreira Abros de Camargo",
      "Email": "adriana_abros@hotmail.com",
      "CustomerCode": "19133",
      "Phone": "1199081927",
      "Whatsapp": "11999081927",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2547,
      "Name": "Marcelo Santana",
      "Email": "m1975santan@gmail.com",
      "CustomerCode": "ORCXXC",
      "Phone": "4199775203",
      "Whatsapp": "41997752036",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2549,
      "Name": "Rene Aquino Jbelle",
      "Email": "novolibano@gmail.com",
      "CustomerCode": "0IPX3O",
      "Phone": "1197413321",
      "Whatsapp": "11974133214",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2550,
      "Name": "Aristides Cardoso da Silva",
      "Email": "ari@xmlcont.com.br",
      "CustomerCode": "15241",
      "Phone": "1122933133",
      "Whatsapp": "11973531640",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2551,
      "Name": "Marilene Geremias Toni",
      "Email": "fiscal.saojoaocont@uol.com.br",
      "CustomerCode": "65190",
      "Phone": "1936334613",
      "Whatsapp": "19997762684",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2552,
      "Name": "M.M.F Administração e Participações ltda",
      "Email": "eduardo.araujo@jljempresas.com.br",
      "CustomerCode": "YHAWWH",
      "Phone": "1146027200",
      "Whatsapp": "11979738887",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2553,
      "Name": "Real Business Negócios Imobiliários S.A ",
      "Email": "eduardo.araujo@jljempresas.com.br",
      "CustomerCode": "SXI6KP",
      "Phone": "1146027200",
      "Whatsapp": "11979738887",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2554,
      "Name": "Progressiv Adeilson Adm. e Serv. Ltda.",
      "Email": "consultor.prado@outlook.com",
      "CustomerCode": "37481",
      "Phone": "1126809885",
      "Whatsapp": "11986814772",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2555,
      "Name": "Genuíno Lopes Moreira Jr",
      "Email": "contato@tributex.com.br",
      "CustomerCode": "9WNDNB",
      "Phone": "6136865581",
      "Whatsapp": "61994024473",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2556,
      "Name": "Edna Francisca de Souza Silva ",
      "Email": "efranciscasouza50@gmail.com",
      "CustomerCode": "KHQNPO",
      "Phone": "1191854039",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2557,
      "Name": "Alexandre Pareira de Almeida",
      "Email": "teklar@teklar.com.br",
      "CustomerCode": "19136",
      "Phone": "1128926772",
      "Whatsapp": "11947111628",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2558,
      "Name": "Sergio Luiz Diogo",
      "Email": "sldiogo@hotmail.com",
      "CustomerCode": "17206",
      "Phone": "1139495088",
      "Whatsapp": "11982551749",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2559,
      "Name": "Márcia Gilioli",
      "Email": "marciagilioli@gmail.com",
      "CustomerCode": "38113",
      "Phone": "1198304323",
      "Whatsapp": "11998304323",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2560,
      "Name": "ABCON Serviços Contábeis e Administrativo",
      "Email": "abconsp@gmail.com",
      "CustomerCode": "19137",
      "Phone": "1136783418",
      "Whatsapp": "11989387842",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2561,
      "Name": "Hipolito Contabil ",
      "Email": "marcelo@hipolitocontabil.com",
      "CustomerCode": "65191",
      "Phone": "6735624478",
      "Whatsapp": "67999670109",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2562,
      "Name": "Alessandro Miceli",
      "Email": "alessandro@miceliassessoria.com",
      "CustomerCode": "HRIX1U",
      "Phone": "1138364688",
      "Whatsapp": "11967771992",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2563,
      "Name": "Thayrone Esrom",
      "Email": "thayroneesrom@hotmail.com",
      "CustomerCode": "65192",
      "Phone": "38991669718",
      "Whatsapp": "3891669718",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2564,
      "Name": "Rudinei Gomes de Lima",
      "Email": "contabilmantiqueira@gmail.com",
      "CustomerCode": "62201",
      "Phone": "1231561141",
      "Whatsapp": "12997497676",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2565,
      "Name": "José Edson Nunes",
      "Email": "condespa@uol.com.br",
      "CustomerCode": "64088",
      "Phone": "1437112222",
      "Whatsapp": "14996161700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2566,
      "Name": "Roma Assessoria Contábil",
      "Email": "roma@romaassessoriacontabil.com.br",
      "CustomerCode": "64464",
      "Phone": "2125326999",
      "Whatsapp": "21995149559",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2567,
      "Name": "Eliane",
      "Email": "eliane.coles@outlook.com.br",
      "CustomerCode": "SRIB42",
      "Phone": "1143083060",
      "Whatsapp": "11986806197",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2568,
      "Name": "Vivi Presentes",
      "Email": "ivivlourdes@hotmail.com",
      "CustomerCode": "7MJGLU",
      "Phone": "1987125159",
      "Whatsapp": "19987125159",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2569,
      "Name": "Gilberto José do Carmo Batista",
      "Email": "tributario1@controltech.com.br",
      "CustomerCode": "ME56M6",
      "Phone": "2733009000",
      "Whatsapp": "27997536365",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2570,
      "Name": "Rogério da Cruz Santos",
      "Email": "rsantosadv@uol.com.br",
      "CustomerCode": "19139",
      "Phone": "1131015070",
      "Whatsapp": "11975291822",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2571,
      "Name": "Derli Valadares da Rocha",
      "Email": "derlivaladares68@gmail.com",
      "CustomerCode": "18870",
      "Phone": "1120868982",
      "Whatsapp": "11973023015",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2572,
      "Name": "RCB Organização Contabil LTDA",
      "Email": "sebastiao.campos@bragacampos.com",
      "CustomerCode": "65193",
      "Phone": "2199904012",
      "Whatsapp": "21999040122",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2573,
      "Name": "JM Moura Assessoria Fisco Contábil LTDA",
      "Email": "contato@jmmoura.com.br",
      "CustomerCode": "19140",
      "Phone": "1155480537",
      "Whatsapp": "11940139304",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2574,
      "Name": "São Paulo Escritório de Contabilidade",
      "Email": "sonnejr@hotmail.com",
      "CustomerCode": "38596",
      "Phone": "1140532570",
      "Whatsapp": "11999566888",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2576,
      "Name": "Taxcont Assessoria Empresarial LTDA",
      "Email": "regiane@taxcont.com.br",
      "CustomerCode": "19141",
      "Phone": "1144475750",
      "Whatsapp": "11956852314",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2577,
      "Name": "WS Consultoria Contabil e Fiscal",
      "Email": "sc@wsconsultoriafiscal.com.br",
      "CustomerCode": "19155",
      "Phone": "1128942750",
      "Whatsapp": "11972068815",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2578,
      "Name": "Obed Paulo da Silva",
      "Email": "obedpaulo@gmail.com",
      "CustomerCode": "9VGVWD",
      "Phone": "1197446154",
      "Whatsapp": "11974461546",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2579,
      "Name": "Andre Mauricio dos Santos",
      "Email": "societario@contabilidadeunica.com.br",
      "CustomerCode": "ZDSNZU",
      "Phone": "1178769468",
      "Whatsapp": "11978769468",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2580,
      "Name": "Contabil Ciola S/S",
      "Email": "henrique@contabilciola.com.br",
      "CustomerCode": "16170",
      "Phone": "1130355200",
      "Whatsapp": "11985879988",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2581,
      "Name": "Thais Rye Fugimoto",
      "Email": "tfugimoto@botelhoadvogados.com.br",
      "CustomerCode": "T55U63",
      "Phone": "1138462433",
      "Whatsapp": "11986529799",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2582,
      "Name": "Abner Luiz Teixeira",
      "Email": "abner@taxcont.com.br",
      "CustomerCode": "04175Z",
      "Phone": "1144475750",
      "Whatsapp": "11982056909",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2585,
      "Name": "Andre Ciampaglia Sociedade de Advogados",
      "Email": "contato@ciampaglia.com.br",
      "CustomerCode": "L4NQ6P",
      "Phone": "1123671329",
      "Whatsapp": "11999635088",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2587,
      "Name": "Sandra Aparecida Bueno Rodrigues",
      "Email": "bbr.contjuridica@gmail.com",
      "CustomerCode": "19144",
      "Phone": "1155234755",
      "Whatsapp": "11991054094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2588,
      "Name": "Monte Bravo Assessoria Contábil LTDA",
      "Email": "cadastro@montebravocontabilidade.com",
      "CustomerCode": "B2II2G",
      "Phone": "6533658227",
      "Whatsapp": "65996917210",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2589,
      "Name": "Marcelo Martins Ramalho Filho",
      "Email": "cadastro@montebravocontabilidade.com",
      "CustomerCode": "8WQN1V",
      "Phone": "6533658227",
      "Whatsapp": "65996917210",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2590,
      "Name": "Alex Sandro Borges de jesus junior",
      "Email": "alexs.junior458@gmail.com",
      "CustomerCode": "82GRW3",
      "Phone": "33638877",
      "Whatsapp": "15992624479",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2591,
      "Name": "Emilio Yoshiyuki Aoki",
      "Email": "emilio.aoki@solucont.com.br",
      "CustomerCode": "19159",
      "Phone": "1155670877",
      "Whatsapp": "11985794460",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2593,
      "Name": "Anailton de Santana Matos",
      "Email": "acountprocuradoria@gmail.com",
      "CustomerCode": "BASOFT",
      "Phone": "7130370775",
      "Whatsapp": "71988236268",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2594,
      "Name": "Eduardo Gomes da Silva",
      "Email": "gomes-fox@hotmail.com",
      "CustomerCode": "19143",
      "Phone": "1133856300",
      "Whatsapp": "11985717713",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2595,
      "Name": "Valmir Ferreira Forni",
      "Email": "valmir.forni@hotmail.com",
      "CustomerCode": "19164",
      "Phone": "1194555374",
      "Whatsapp": "11945553744",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2596,
      "Name": "JMP Assessoria Empresarial e Contabil LTDA",
      "Email": "barbara@megajmp.com.br",
      "CustomerCode": "19145",
      "Phone": "1138367344",
      "Whatsapp": "11989856317",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2597,
      "Name": "Odair Xisto",
      "Email": "ecentercontabil@gmail.com",
      "CustomerCode": "17242",
      "Phone": "1122050101",
      "Whatsapp": "11972241771",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2598,
      "Name": "Gustavo Braga Ricci",
      "Email": "ovatsugiccir@gmail.com",
      "CustomerCode": "17688",
      "Phone": "1196358142",
      "Whatsapp": "11963581426",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2599,
      "Name": "Fabio Franco",
      "Email": "francoinvesti@hotmail.com",
      "CustomerCode": "65194",
      "Phone": "1833226297",
      "Whatsapp": "18996799112",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2600,
      "Name": "Gabrielle Andres Brandão",
      "Email": "gabrielle@gabrandao.com.br",
      "CustomerCode": "19154",
      "Phone": "1123926600",
      "Whatsapp": "11998767840",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2602,
      "Name": "Proconsist",
      "Email": "paula@proconsist.com.br",
      "CustomerCode": "19151",
      "Phone": "1136677758",
      "Whatsapp": "11991693050",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2603,
      "Name": "E.Petenon Assessoria Contabil",
      "Email": "elisangela@fiscotecorg.com.br",
      "CustomerCode": "36320",
      "Phone": "1136560404",
      "Whatsapp": "11941310020",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2604,
      "Name": "Fernando Antonio Giovanetti Contabilidade",
      "Email": "escrgiovanetti@hotmail.com",
      "CustomerCode": "63685",
      "Phone": "1438822544",
      "Whatsapp": "14998929248",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2605,
      "Name": "Neuza Maria Costa Moura",
      "Email": "pilar.contabilidade@hotmail.com",
      "CustomerCode": "ITR2T3",
      "Phone": "1120326166",
      "Whatsapp": "11981632044",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2607,
      "Name": "André Ferreira da Costa",
      "Email": "andrecontador2000@gmail.com",
      "CustomerCode": "19146",
      "Phone": "1122312688",
      "Whatsapp": "11993417977",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2608,
      "Name": "Cruz e Cia Consultoria LTDA",
      "Email": "contabilidade@cruzsociedade.com.br",
      "CustomerCode": "19147",
      "Phone": "1196072564",
      "Whatsapp": "11960725641",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2610,
      "Name": "Victor Lanzillo ",
      "Email": "victor@contabillanzillo.com.br",
      "CustomerCode": "37192",
      "Phone": "1131731540",
      "Whatsapp": "11984850184",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2611,
      "Name": "Chrystal Consultoria e Assessoria Contábil Ltda.",
      "Email": "cidamilan@gmail.com",
      "CustomerCode": "15979",
      "Phone": "1189949297",
      "Whatsapp": "11989949297",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2612,
      "Name": "Rafael Moraes Cruvinel",
      "Email": "contato@evocontabilidade.com.br",
      "CustomerCode": "WFLWN9",
      "Phone": "6299951327",
      "Whatsapp": "62999513277",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2613,
      "Name": "Apolo Contabilidade",
      "Email": "elisa@apolocontabilourinhos.com.br",
      "CustomerCode": "65196",
      "Phone": "1433225922",
      "Whatsapp": "14997505922",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2614,
      "Name": "Fábio José da Cunha Souza",
      "Email": "financeirofcontcontabilidade@gmail.com",
      "CustomerCode": "19150",
      "Phone": "1131502400",
      "Whatsapp": "11931502400",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2615,
      "Name": "Dayse Garcia Silveira",
      "Email": "dayse.crcsp@gmail.com",
      "CustomerCode": "19148",
      "Phone": "1192952680",
      "Whatsapp": "11992952680",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2616,
      "Name": "Jose Romilton de Sales",
      "Email": "contato@contabilidadejrs.com",
      "CustomerCode": "17912",
      "Phone": "1125587106",
      "Whatsapp": "11958140699",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2617,
      "Name": "Emerson Luiz Martins",
      "Email": "emerluizz5@gmail.com",
      "CustomerCode": "EI3403",
      "Phone": "1198262343",
      "Whatsapp": "11982623434",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2619,
      "Name": "Cássio Lima",
      "Email": "cassiolimacontabilidade@gmail.com",
      "CustomerCode": "19149",
      "Phone": "3598836589",
      "Whatsapp": "35988365895",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2620,
      "Name": "Mary Suemi Aramaki",
      "Email": "maryaramaki3@gmail.com",
      "CustomerCode": "15165",
      "Phone": "1194811914",
      "Whatsapp": "11948119145",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2621,
      "Name": "RG Esquadrias",
      "Email": "rgesquadrias@gmail.com",
      "CustomerCode": "7I13AI",
      "Phone": "1198285674",
      "Whatsapp": "11952862862",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2622,
      "Name": "Henrique matheus Nogueira ",
      "Email": "kingnogueira94@gmail.com",
      "CustomerCode": "38QDZW",
      "Phone": "1124141770",
      "Whatsapp": "11962700367",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2626,
      "Name": "Erika Santos",
      "Email": "erikasantos_adv@hotmail.com",
      "CustomerCode": "19153",
      "Phone": "1187230852",
      "Whatsapp": "11982730852",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2627,
      "Name": "Ricardo José dos Santos",
      "Email": "ricardo@petriusassessoria.com",
      "CustomerCode": "92ZGE0",
      "Phone": "1199666831",
      "Whatsapp": "11996668314",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2628,
      "Name": "HQZ Controller",
      "Email": "virginiarodrigues@hqzcontroller.com.br",
      "CustomerCode": "6PY0NR",
      "Phone": "2124995434",
      "Whatsapp": "21964418039",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2629,
      "Name": "Elisangela Fatima Camargo dos Santos",
      "Email": "eelisangelafc@gmail.com",
      "CustomerCode": "19152",
      "Phone": "1197874814",
      "Whatsapp": "11997874814",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2630,
      "Name": "Luis Guilherme Palma",
      "Email": "guilherme@bpalma.com.br",
      "CustomerCode": "65197",
      "Phone": "1933812671",
      "Whatsapp": "19981731965",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2631,
      "Name": "Luiz Orlando Jr Christofaro",
      "Email": "contecmarilia@gmail.com",
      "CustomerCode": "63055",
      "Phone": "1434333911",
      "Whatsapp": "14997200521",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2632,
      "Name": "Marisa",
      "Email": "paralegal@dkee.com.br",
      "CustomerCode": "X8YEXQ",
      "Phone": "1198393243",
      "Whatsapp": "11983932430",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2633,
      "Name": "Meta Solução S/S",
      "Email": "rgruer@metasolucao.com.br",
      "CustomerCode": "16249",
      "Phone": "1120996070",
      "Whatsapp": "11997730685",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2634,
      "Name": "Gabrielle Evelyn Felix",
      "Email": "gabrielle_felix12@hotmail.com",
      "CustomerCode": "19163",
      "Phone": "1125148405",
      "Whatsapp": "11949363825",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2635,
      "Name": "Elizabeth Silveira Peart",
      "Email": "bethspeart@gmail.com",
      "CustomerCode": "J6R3AY",
      "Phone": "1199176270",
      "Whatsapp": "11991762700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2636,
      "Name": "Juliete Santos de Almeida",
      "Email": "juliete.almeida@hotmail.com",
      "CustomerCode": "17925",
      "Phone": "1197706520",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2638,
      "Name": "Elson Moraes",
      "Email": "contabilidade@contelconsultoria.com.br",
      "CustomerCode": "65198",
      "Phone": "3135731389",
      "Whatsapp": "31995388402",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2639,
      "Name": "Marcelo Negreti Gomes",
      "Email": "marcelo.gomes@grupozaragoza.com.br",
      "CustomerCode": "19191",
      "Phone": "1236348700",
      "Whatsapp": "12981369355",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2640,
      "Name": "Alume Contabilidade LTDA",
      "Email": "financeiro@alume.com",
      "CustomerCode": "19166",
      "Phone": "1185273408",
      "Whatsapp": "11996590414",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2641,
      "Name": "Edio Conceição de Oliveira Carneiro",
      "Email": "adv.ediocarneiro2022@gmail.com",
      "CustomerCode": "M0DO4Q",
      "Phone": "5198508570",
      "Whatsapp": "51985085700",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2642,
      "Name": "Jefferson Figueiredo",
      "Email": "jwsfigueira@hotmail.com",
      "CustomerCode": "38597",
      "Phone": "1181061701",
      "Whatsapp": "11981061701",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2643,
      "Name": "Rinivaldo José de Paulo",
      "Email": "rinivaldo@hotmail.com",
      "CustomerCode": "19156",
      "Phone": "1197102762",
      "Whatsapp": "11997102762",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2644,
      "Name": "Edna Nunes Leite",
      "Email": "ville.contabilidade@terra.com.br",
      "CustomerCode": "36126",
      "Phone": "1197164420",
      "Whatsapp": "11971644209",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2646,
      "Name": "Marcelo Caponi",
      "Email": "caponi1@hotmail.com",
      "CustomerCode": "65199",
      "Phone": "3534432324",
      "Whatsapp": "35999567929",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2647,
      "Name": "Flavio Yassuo Arashiro",
      "Email": "flaviao.moio@uol.com.br",
      "CustomerCode": "EOSRR2",
      "Phone": "1129414209",
      "Whatsapp": "11975420872",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2648,
      "Name": "Lliliam Mayumi Chirayama",
      "Email": "liliam@chirayama.com.br",
      "CustomerCode": "15228",
      "Phone": "1126710071",
      "Whatsapp": "11971503008",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2649,
      "Name": "Franca & Peluso Assessoria Contabil e Tributaria LTDA",
      "Email": "departamentolegal@peluso.com.br",
      "CustomerCode": "BR3V3P",
      "Phone": "1137877400",
      "Whatsapp": "11940779758",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2650,
      "Name": "MMS Consultoria Contabil",
      "Email": "onofre@mmscontabil.com.br",
      "CustomerCode": "19157",
      "Phone": "1136723548",
      "Whatsapp": "11983388823",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2651,
      "Name": "Lucas Henrique Soares Santos",
      "Email": "luca_henri@hotmail.com",
      "CustomerCode": "19160",
      "Phone": "1137967330",
      "Whatsapp": "11966295459",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2655,
      "Name": "Absoluta Soluções Empresariais",
      "Email": "contato@absolutacnt.com.br",
      "CustomerCode": "65200",
      "Phone": "4198429547",
      "Whatsapp": "41984295477",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2656,
      "Name": "Elias Eduardo Vieira Pimenta",
      "Email": "atendimento.c3g@gmail.com",
      "CustomerCode": "JKI6L2",
      "Phone": "1131351833",
      "Whatsapp": "1131351833",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2657,
      "Name": "Antonio Macedo Carvalho",
      "Email": "amcarvalho1906@outlook.com",
      "CustomerCode": "O1CCXG",
      "Phone": "1199618765",
      "Whatsapp": "11996187657",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2658,
      "Name": "Roseli Del Buono",
      "Email": "rbuonoconsultoria@gmail.com",
      "CustomerCode": "15972",
      "Phone": "1128483244",
      "Whatsapp": "11973086350",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2659,
      "Name": "Geferson Pereira do Nascimento",
      "Email": "geferson@contabilkennedy.com.br",
      "CustomerCode": "8MM7C3",
      "Phone": "1636653051",
      "Whatsapp": "16999693767",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2660,
      "Name": "Lodemar Sansao",
      "Email": "matrixcontabilidade@terra.com.br",
      "CustomerCode": "65201",
      "Phone": "4733326062",
      "Whatsapp": "47992204971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2661,
      "Name": "Marcelo Carvalho da Silva",
      "Email": "marcelosenior23@gmail.com",
      "CustomerCode": "19170",
      "Phone": "1122930615",
      "Whatsapp": "11998293976",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2663,
      "Name": "Evandro Paulo Volpi",
      "Email": "evandropv@netnew.com.br",
      "CustomerCode": "64268",
      "Phone": "1732451808",
      "Whatsapp": "17997063744",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2664,
      "Name": "Ana Lucia Koch Zingaro",
      "Email": "ana.koch@gmail.com",
      "CustomerCode": "19162",
      "Phone": "1199757383",
      "Whatsapp": "11999757383",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2665,
      "Name": "JA Saúde Animal SA",
      "Email": "juridico@jasaudeanimal.com.br",
      "CustomerCode": "TS5M2Q",
      "Phone": "1631459920",
      "Whatsapp": "16981211543",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2666,
      "Name": "João Paulo Hutter David",
      "Email": "gerencia@parcontabil.com.br",
      "CustomerCode": "65202",
      "Phone": "2830251812",
      "Whatsapp": "28999928253",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2667,
      "Name": "Contjamax Assessoria & Consultoria Contabil LTDA",
      "Email": "assistente@contjamax.com.br",
      "CustomerCode": "19165",
      "Phone": "1122318781",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2668,
      "Name": "ADC TEC Assessoria Contabil LTDA",
      "Email": "comercial@adctec.com.br",
      "CustomerCode": "Y8RP9U",
      "Phone": "1144322712",
      "Whatsapp": "1144322712",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2669,
      "Name": "Neide Barbosa Caraça Correa",
      "Email": "neideccorrea@hotmail.com",
      "CustomerCode": "19167",
      "Phone": "1120355533",
      "Whatsapp": "11940003723",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2670,
      "Name": "MS Gestão Contabilidade LTDA",
      "Email": "rodrigo.farias@msconsulting-brasil.com",
      "CustomerCode": "64964",
      "Phone": "9130386427",
      "Whatsapp": "11934030707",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2671,
      "Name": "Exato Contábil",
      "Email": "exato.contabil@terra.com.br",
      "CustomerCode": "64669",
      "Phone": "1140363319",
      "Whatsapp": "11944551770",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2673,
      "Name": "Guilherme França",
      "Email": "gvfranca@icloud.com",
      "CustomerCode": "1LWW0A",
      "Phone": "1197234286",
      "Whatsapp": "11972342866",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2674,
      "Name": "Revicont Revisora Contabil",
      "Email": "wespanhol@revicont.com.br",
      "CustomerCode": "16471",
      "Phone": "1156140004",
      "Whatsapp": "11994189462",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2675,
      "Name": "JRW Assessoria Contabil SS LTDA",
      "Email": "jrwcontabil@gmail.com",
      "CustomerCode": "0RFIIY",
      "Phone": "1137980684",
      "Whatsapp": "11983322076",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2676,
      "Name": "Fábio Salomão",
      "Email": "contsalomao@terra.com.br",
      "CustomerCode": "19169",
      "Phone": "1132220655",
      "Whatsapp": "11989750039",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2677,
      "Name": "Vanessa Pereira Lopes",
      "Email": "vlopes140527@gmail.com",
      "CustomerCode": "2O2DOG",
      "Phone": "6799896436",
      "Whatsapp": "67998896436",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2678,
      "Name": "Danillo de Maio Olivo ",
      "Email": "danillovanessa2709@gmail.com",
      "CustomerCode": "9M9AVY",
      "Phone": "1126815716",
      "Whatsapp": "11972387837",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2683,
      "Name": "Samuel Vieira da Silva",
      "Email": "svs-assessoria@uol.com.br",
      "CustomerCode": "OBM2D4",
      "Phone": "1183177754",
      "Whatsapp": "11983177754",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2684,
      "Name": "Pedro Henrique",
      "Email": "pedrohenrique@somosaudita.com.br",
      "CustomerCode": "65203",
      "Phone": "8431134554",
      "Whatsapp": "84994119460",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2685,
      "Name": "Politecnica Tecnologia e Sistemas Ltda",
      "Email": "politecnicatecnologia@gmail.com",
      "CustomerCode": "H0CD2W",
      "Phone": "1999746322",
      "Whatsapp": "19997463221",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2687,
      "Name": "João Francisco Gasparoto",
      "Email": "ggagroinvestimentos@gmail.com",
      "CustomerCode": "HCY3B9",
      "Phone": "1999882928",
      "Whatsapp": "14996971987",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2688,
      "Name": "Costa, Martins, Meira e Rinaldi Advogados Associados",
      "Email": "marceane@cmmr.adv.br",
      "CustomerCode": "65204",
      "Phone": "4738010440",
      "Whatsapp": "47996406406",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2690,
      "Name": "Gabriel Guillande Fantinel",
      "Email": "contabilidade@webcondigital.com.br",
      "CustomerCode": "65079",
      "Phone": "4626041544",
      "Whatsapp": "11964631291",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2691,
      "Name": "Gustavo Castro",
      "Email": "gustavoauditor@hotmail.com",
      "CustomerCode": "9CESID",
      "Phone": "1173292510",
      "Whatsapp": "11973292510",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2692,
      "Name": "Mauro Ap Pachionni",
      "Email": "san.milani@hotmail.com",
      "CustomerCode": "19171",
      "Phone": "",
      "Whatsapp": "11994213160",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2693,
      "Name": "Regina",
      "Email": "rguerdis@byengenharia.com.br",
      "CustomerCode": "542202",
      "Phone": "1199703339",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2694,
      "Name": "Sirovi Jose da Silva - ME",
      "Email": "douglas@cayrocontabil.com.br",
      "CustomerCode": "19258",
      "Phone": "1138599700",
      "Whatsapp": "11938599700",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2695,
      "Name": "Renivaldo Jesus Cardoso",
      "Email": "renicardoso34@gmail.com",
      "CustomerCode": "19172",
      "Phone": "",
      "Whatsapp": "11968182880",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2696,
      "Name": "Anderson de Souza Santos",
      "Email": "anderson_ssantos@hotmail.com",
      "CustomerCode": "37483",
      "Phone": "1145621387",
      "Whatsapp": "11970799222",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2697,
      "Name": "Ezequiel Rodrigues da Conceição",
      "Email": "renan.anjos@cvale.com.br",
      "CustomerCode": "CTVDBE",
      "Phone": "4499479971",
      "Whatsapp": "4499479971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2698,
      "Name": "Achille Luigi Berton",
      "Email": "rodrigo@dinamicaorg.cnt.br",
      "CustomerCode": "TEG8TB",
      "Phone": "1155216028",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2699,
      "Name": "RMS Assessoria Empresarial Ltda",
      "Email": "reginaldo@rmscontabil.com.br",
      "CustomerCode": "36116",
      "Phone": "1136841840",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2700,
      "Name": "Verreaux Estrategias de Negócios",
      "Email": "contabilidade@verreaux.com.br",
      "CustomerCode": "38601",
      "Phone": "1188831934",
      "Whatsapp": "11988831934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2701,
      "Name": "Enzo di Folco",
      "Email": "enzofolco@adv.oabsp.org.br",
      "CustomerCode": "MQ416V",
      "Phone": "1194754995",
      "Whatsapp": "11947549950",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2702,
      "Name": "Daniel Schmitt Advogados",
      "Email": "adm-fin@schmitt.adv.br",
      "CustomerCode": "25ZDIM",
      "Phone": "2122624097",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2703,
      "Name": "Lincoln Martins de Oliveira ",
      "Email": "lincontabil@live.com",
      "CustomerCode": "Z7G0DO",
      "Phone": "1139668533",
      "Whatsapp": "11999944116",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2704,
      "Name": "Valdomiro Soares Batista Filho",
      "Email": "valdomirocontador@gmail.com",
      "CustomerCode": "XMRL72",
      "Phone": "1143258557",
      "Whatsapp": "11966445504",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2705,
      "Name": "Heloisa Tadeu Conforto",
      "Email": "heloisa@bookcont.com.br",
      "CustomerCode": "19173",
      "Phone": "1139031854",
      "Whatsapp": "11985504966",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2706,
      "Name": "Tercio Alexandre Carareto",
      "Email": "alexandre@motozum.com",
      "CustomerCode": "64315",
      "Phone": "1435250900",
      "Whatsapp": "14998914000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2707,
      "Name": "Herculano Contabilidade SS LTDA",
      "Email": "aherculano@herculanocontabilidade.com.br",
      "CustomerCode": "61474",
      "Phone": "1733451203",
      "Whatsapp": "17997044224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2708,
      "Name": "Edvaldo Almeida Oliveira ",
      "Email": "edvaldo_3636@hotmail.com",
      "CustomerCode": "FRRN7U",
      "Phone": "1174871389",
      "Whatsapp": "11974871389",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2709,
      "Name": "Bruno Paulo Ferraz Zezzi",
      "Email": "cadastrofbn@gmail.com",
      "CustomerCode": "IU40XH",
      "Phone": "1499634377",
      "Whatsapp": "14996343772",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2710,
      "Name": "Secta Serviços Contabeis Ltda",
      "Email": "rodrigo@sectacontabil.com",
      "CustomerCode": "19225",
      "Phone": "1138763451",
      "Whatsapp": "11982655102",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2711,
      "Name": "Voice Serviço Contabil SS ",
      "Email": "rosangela@voicecontabil.com.br",
      "CustomerCode": "18089",
      "Phone": "1131011313",
      "Whatsapp": "11984130151",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2712,
      "Name": "Amanda de Souza Theodoro",
      "Email": "atheodoro@tortoromr.com.br",
      "CustomerCode": "HZRKRU",
      "Phone": "1639759100",
      "Whatsapp": "1639759100",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2713,
      "Name": "Maiara Limeira de Melo",
      "Email": "mlmelo@tortoromr.com.br",
      "CustomerCode": "KVT18W",
      "Phone": "1298158082",
      "Whatsapp": "12981580827",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2714,
      "Name": "Porto Contabil LTDA",
      "Email": "williamrobertoporto@gmail.com",
      "CustomerCode": "AXPCSO",
      "Phone": "1132270919",
      "Whatsapp": "11984019647",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2716,
      "Name": "TB Comercio de Presentes S/A",
      "Email": "contabil@taniabulhoes.com.br",
      "CustomerCode": "36321",
      "Phone": "1139509999",
      "Whatsapp": "11999639099",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2717,
      "Name": "Sonia Camelo",
      "Email": "contato@vencercontabilidade.com.br",
      "CustomerCode": "0NSUM5",
      "Phone": "1199113580",
      "Whatsapp": "11991135803",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2718,
      "Name": "Francisco Antonio Bragança Neto",
      "Email": "braganca@independenciacontabil.com.br",
      "CustomerCode": "19174",
      "Phone": "1127245599",
      "Whatsapp": "11992595400",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2719,
      "Name": "Contabile Assessoria Contabil Sociedade Simples Unipessoal LI",
      "Email": "contabile.2008@outlook.com",
      "CustomerCode": "17187",
      "Phone": "1137917300",
      "Whatsapp": "11966139402",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2720,
      "Name": "Sandra Gouvea",
      "Email": "sandra_coca@hotmail.com",
      "CustomerCode": "4BWGP6",
      "Phone": "1533111463",
      "Whatsapp": "15974014281",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2721,
      "Name": "Maronna Assessoria Contabil Administrativa LTDA",
      "Email": "maronna.caucaia@maronna.com.br",
      "CustomerCode": "37259",
      "Phone": "1146117878",
      "Whatsapp": "11944669572",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2722,
      "Name": "Lucas Gabriel Franchin",
      "Email": "contatolucasfranchin@gmail.com",
      "CustomerCode": "6QZ9HK",
      "Phone": "1194236936",
      "Whatsapp": "11942369364",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2725,
      "Name": "marlens",
      "Email": "marlens@pesgroup.com.br",
      "CustomerCode": "8N3BQV",
      "Phone": "6282405353",
      "Whatsapp": "62982405353",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2726,
      "Name": "mr stival",
      "Email": "marlens@pesgroup.com.br",
      "CustomerCode": "7O914M",
      "Phone": "6230928738",
      "Whatsapp": "62982405353",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2727,
      "Name": "Laise M da Silva ",
      "Email": "esial.contabilidade@gmail.com",
      "CustomerCode": "17295",
      "Phone": "1125065644",
      "Whatsapp": "11971063797",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2728,
      "Name": "Patrícia Garvia",
      "Email": "pgarvia@lmpa.com.br",
      "CustomerCode": "19175",
      "Phone": "1199545359",
      "Whatsapp": "11999545359",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2729,
      "Name": "Manoel Messias Soares Filho",
      "Email": "newpluscontabilidade@gmail.com",
      "CustomerCode": "PVWK8W",
      "Phone": "8288768201",
      "Whatsapp": "82988768201",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2730,
      "Name": "Edenilton Lima de Oliveira",
      "Email": "newpluscontabilidade@gmail.com",
      "CustomerCode": "64912",
      "Phone": "8288768201",
      "Whatsapp": "82988768201",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2731,
      "Name": "Marcos Dangelo Faria",
      "Email": "marcosdangelo@hotmail.com",
      "CustomerCode": "18925",
      "Phone": "1199785338",
      "Whatsapp": "11999785338",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2732,
      "Name": "Wallacy Macedo Santana",
      "Email": "dr.wallacysantanaadvogado1@outlook.com",
      "CustomerCode": "37482",
      "Phone": "",
      "Whatsapp": "11943572582",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2733,
      "Name": "Ooganização Contábil Perola LTDA",
      "Email": "organizacao@contabilperola.com.br",
      "CustomerCode": "2ZJ5DQ",
      "Phone": "1934584717",
      "Whatsapp": "1936290613",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2734,
      "Name": "Stefani Monteiro Duarte",
      "Email": "stefanimonteiroduarte@gmail.com",
      "CustomerCode": "GIP9U3",
      "Phone": "1195080394",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2735,
      "Name": "Jelvason Silva dos Santos ",
      "Email": "jelvason-silva@hotmail.com",
      "CustomerCode": "ME5NSL",
      "Phone": "1196108128",
      "Whatsapp": "11978291488",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2736,
      "Name": "Maria Gardênia Barroso Lessa ",
      "Email": "mgarbarroso@gmail.com",
      "CustomerCode": "5DLQ1D",
      "Phone": "1198957545",
      "Whatsapp": "11989575453",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2737,
      "Name": "Parcont Assessoria Contabil SS LTDA",
      "Email": "fiscal@parcont.com.br",
      "CustomerCode": "U2VB4W",
      "Phone": "1932325030",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2738,
      "Name": "Ulisses Paulo Anorozo Cunha",
      "Email": "ulissespaulo@hotmail.com",
      "CustomerCode": "NO8WUN",
      "Phone": "1332212243",
      "Whatsapp": "13974094266",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2739,
      "Name": "José Xavier Quirino",
      "Email": "x",
      "CustomerCode": "MEB5K5",
      "Phone": "8199504006",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2740,
      "Name": "Paulo Coutinho",
      "Email": "paulo@concordeseguros.com.br",
      "CustomerCode": "18312",
      "Phone": "1129416996",
      "Whatsapp": "11998482237",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2741,
      "Name": "Priscilla Calsavara Bastos",
      "Email": "pcbastoscont@gmail.com",
      "CustomerCode": "19177",
      "Phone": "1182165048",
      "Whatsapp": "11982165048",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2742,
      "Name": "Barbara Cenciarelli",
      "Email": "cenciarellicontabilidade@gmail.com",
      "CustomerCode": "XYDHZ3",
      "Phone": "1299746388",
      "Whatsapp": "12997463883",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2743,
      "Name": "G D Vieira Filho Transportes de Carga",
      "Email": "Gilvieira2606@gmail.com",
      "CustomerCode": "FNJHCD",
      "Phone": "4331320355",
      "Whatsapp": "43991902500",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2744,
      "Name": "Arnaldo Fernandes de Oliveira",
      "Email": "arnaldofernandes@uol.com.br",
      "CustomerCode": "38598",
      "Phone": "1142263247",
      "Whatsapp": "11996168617",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2745,
      "Name": "Clínica de Fonoaudiologas Associadas",
      "Email": "clinica.cfa@uol.com.br",
      "CustomerCode": "HQ2P0C",
      "Phone": "1132142352",
      "Whatsapp": "11952000205",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2746,
      "Name": "Roberto de Souza",
      "Email": "rsassessoria.1@hotmail.com",
      "CustomerCode": "17667",
      "Phone": "1139827368",
      "Whatsapp": "11982037015",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2747,
      "Name": "AM Contabilidade Online LTDA",
      "Email": "onboarding.amcont@gmail.com",
      "CustomerCode": "IUJ66B",
      "Phone": "6734615407",
      "Whatsapp": "67999381433",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2748,
      "Name": "JNR Contabilidade LTDA",
      "Email": "ismael@jnrcontabilidade.com.br",
      "CustomerCode": "65206",
      "Phone": "",
      "Whatsapp": "41999755215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2749,
      "Name": "Suellen Melo",
      "Email": "suellenmelo.contabil@gmail.com",
      "CustomerCode": "ZHXAP1",
      "Phone": "1189649844",
      "Whatsapp": "11989649844",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2750,
      "Name": "Sidnei Marcelino",
      "Email": "1203sidmar@gmail.com",
      "CustomerCode": "XRYU5H",
      "Phone": "1198549059",
      "Whatsapp": "11985490599",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2751,
      "Name": "Ana Carolina Cavalcante Sociedade de Advogados",
      "Email": "renata@c2a.adv.br",
      "CustomerCode": "18763",
      "Phone": "11986898183",
      "Whatsapp": "11986898183",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2752,
      "Name": "Christian Petterson Antunes Lemos",
      "Email": "christian.lemos2612@gmail.com",
      "CustomerCode": "MVZ526",
      "Phone": "1291592105",
      "Whatsapp": "12991592105",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2753,
      "Name": "BS Contabilidade e Informática LTDA",
      "Email": "igor@contabilbmbs.com.br",
      "CustomerCode": "19178",
      "Phone": "1126936971",
      "Whatsapp": "11986038386",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2754,
      "Name": "Lift Contabilidade",
      "Email": "iury@liftcontabilidade.com.br",
      "CustomerCode": "65207",
      "Phone": "8530633607",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2755,
      "Name": "Fabricia Maia Salzano Frazão",
      "Email": "fabricia@salzanoadv.com.br",
      "CustomerCode": "7PXT1X",
      "Phone": "1130573306",
      "Whatsapp": "11961978452",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2756,
      "Name": "Alfredo Lazzareschi",
      "Email": "alfredo@lmpa.com.br",
      "CustomerCode": "W878IK",
      "Phone": "1144203620",
      "Whatsapp": "11944414390",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2757,
      "Name": "Walner Bezerra Da Silva",
      "Email": "wallner.silvaa@gmail.com",
      "CustomerCode": "SEXFQJ",
      "Phone": "1198872395",
      "Whatsapp": "11958453278",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2758,
      "Name": "Caroline Rodrigues Sabino ",
      "Email": "crs.legalizaca@gmail.com",
      "CustomerCode": "4E89GE",
      "Phone": "1984408859",
      "Whatsapp": "19984408845",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2759,
      "Name": "Jord Organização Contábil Ltda",
      "Email": "lc@jord.com.br",
      "CustomerCode": "19180",
      "Phone": "1126184001",
      "Whatsapp": null,
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2760,
      "Name": "Laerte da Silva Cunha",
      "Email": "laertecont@gmail.com",
      "CustomerCode": "65208",
      "Phone": "12",
      "Whatsapp": "12988347043",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2761,
      "Name": "Nytrus Contabilidade",
      "Email": "comercial@nytrus.com.br",
      "CustomerCode": "65217",
      "Phone": "",
      "Whatsapp": "19998820132",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2762,
      "Name": "F7 Soluções Ltda",
      "Email": "jucesplapa@gmail.com",
      "CustomerCode": "19179",
      "Phone": "1127918877",
      "Whatsapp": "1127918877",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2763,
      "Name": "Flavia Condi Valotto Me",
      "Email": "flavia@valotto.com.br",
      "CustomerCode": "35133",
      "Phone": "1124792673",
      "Whatsapp": "11996513077",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2764,
      "Name": "Escritório Contábil Pedrosa Ltda",
      "Email": "pedrosa@uol.com.br",
      "CustomerCode": "38191",
      "Phone": "1140444640",
      "Whatsapp": "11998869911",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2765,
      "Name": "Flavio Adani",
      "Email": "flavio.adani@adani.cnt.br",
      "CustomerCode": "F7UD3E",
      "Phone": "1935615377",
      "Whatsapp": "19997843427",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2766,
      "Name": "Contábil Rocha Ltda",
      "Email": "adm1@contabilrocha.com.br",
      "CustomerCode": "38148",
      "Phone": "1143390111",
      "Whatsapp": "1143390111",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2768,
      "Name": "Maria do Socorro da Silva Chaves Digitadora",
      "Email": "chavesocorro@uol.com.br",
      "CustomerCode": "19181",
      "Phone": "1155479905",
      "Whatsapp": "11993236985",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2769,
      "Name": "Luizmar souza dos santos",
      "Email": "adrianalopes7205@gmail.com",
      "CustomerCode": "1NIPEH",
      "Phone": "6698130413",
      "Whatsapp": "66981304130",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2770,
      "Name": "Marcio Ricardo Luciano",
      "Email": "mrluciano.advocacia@gmail.com",
      "CustomerCode": "N8V9XC",
      "Phone": "4235224137",
      "Whatsapp": "42998244794",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2771,
      "Name": "Stylle Escritório de Contabilidade e Negócios Empresariais Ltda",
      "Email": "marcio@stylle.com.br",
      "CustomerCode": "BYY4C5",
      "Phone": "1121542030",
      "Whatsapp": "11970510801",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2772,
      "Name": "Padoan Assuntos Contábeis",
      "Email": "silvia@padoan.com.br",
      "CustomerCode": "15514",
      "Phone": "1129632089",
      "Whatsapp": "1129632089",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2773,
      "Name": "Nog Contabilidade e Administração Ltda.",
      "Email": "azteca@contabilazteca.com.br",
      "CustomerCode": "61845",
      "Phone": "1437327186",
      "Whatsapp": "14998512002",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2774,
      "Name": "Jose carlos da Silva Soares Alves",
      "Email": "Josecarloscarlinho343@gmail.com",
      "CustomerCode": "KOGZ8C",
      "Phone": "8288165681",
      "Whatsapp": "82988165681",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2775,
      "Name": "Jose carlos da Silva Soares Alves",
      "Email": "Josecarloscarlinho343@gmail.com",
      "CustomerCode": "0VV1XE",
      "Phone": "8288165681",
      "Whatsapp": "82988165681",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2776,
      "Name": "marcelo marcos madureira",
      "Email": "atendimento@madureiracontabil.com.br",
      "CustomerCode": "Q60Y7W",
      "Phone": "1100000000",
      "Whatsapp": "11985200665",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2777,
      "Name": "Contardi - Contabilidade e Assessoria Empresarial S/C Ltda",
      "Email": "adm@contardi.cnt.br",
      "CustomerCode": "65209",
      "Phone": "4132629136",
      "Whatsapp": "41996829594",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2778,
      "Name": "Aces Contabilidade Industrial Ltda",
      "Email": "tatiana@aces.com.br",
      "CustomerCode": "64306",
      "Phone": "1145242283",
      "Whatsapp": "11971109943",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2779,
      "Name": "Candida Silva e Santos Assessoria Empresarial e Contábil Ltda",
      "Email": "contato@ceacontabil.com.br",
      "CustomerCode": "19182",
      "Phone": "1131595372",
      "Whatsapp": "11985866142",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2780,
      "Name": "Trilogy Contabilidade Ltda",
      "Email": "ulner@trilogycontabilidade.com.br",
      "CustomerCode": "64876",
      "Phone": "",
      "Whatsapp": "62993084573",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2781,
      "Name": "lage Consultoria Contabil",
      "Email": "lage@lage.com.br",
      "CustomerCode": "BDOE6D",
      "Phone": "1135672300",
      "Whatsapp": "11999856322",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2782,
      "Name": "Almilte Conceicao Espolador ",
      "Email": "mirtesespolador@gmail.com",
      "CustomerCode": "36322",
      "Phone": "1198269332",
      "Whatsapp": "11982693325",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2783,
      "Name": "Alphaon Gestão Financeira e Contabilidade LTDA",
      "Email": "marcelo@alphaon.com.br",
      "CustomerCode": "65022",
      "Phone": "2730632052",
      "Whatsapp": "27999139410",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2784,
      "Name": "Vanessa",
      "Email": "vanessasalemeid@gmail.com",
      "CustomerCode": "YC6XZ9",
      "Phone": "1195186309",
      "Whatsapp": "11995186309",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2785,
      "Name": "Master Contabilidade LTDA",
      "Email": "danielsanches@mastercontabilsp.com.br",
      "CustomerCode": "19196",
      "Phone": "1155223657",
      "Whatsapp": "11986202987",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2786,
      "Name": "willian oliveira leite",
      "Email": "wlcontabil@outlook.com.br",
      "CustomerCode": "VY530Z",
      "Phone": "6792148320",
      "Whatsapp": "67992148320",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2787,
      "Name": "WL Assessoria e Consultoria",
      "Email": "wlcontabil@outlook.com.br",
      "CustomerCode": "48KHHY",
      "Phone": "6792148320",
      "Whatsapp": "67992148320",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2788,
      "Name": "Maria Augusta Alves Rodrigues",
      "Email": "augusta_alves@hotmail.com.br",
      "CustomerCode": "19184",
      "Phone": "",
      "Whatsapp": "11998454493",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2789,
      "Name": "Garutti Contabilidade e Assessoria SS",
      "Email": "marcio@garutti.com.br",
      "CustomerCode": "AMZTFX",
      "Phone": "1132562333",
      "Whatsapp": "11975663796",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2790,
      "Name": "Edivanio Alves Mendes",
      "Email": "atualcontabilidaderv@gmail.com",
      "CustomerCode": "65210",
      "Phone": "6436120502",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2791,
      "Name": "Marcos Felippe North",
      "Email": "marcosfelippe@gmail.com",
      "CustomerCode": "7DEU3D",
      "Phone": "1199910502",
      "Whatsapp": "11916516033",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2792,
      "Name": "Renato Sérgio Blotta",
      "Email": "renato@blotta.com.br",
      "CustomerCode": "19186",
      "Phone": "1155832058",
      "Whatsapp": "11981995082",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2793,
      "Name": "Geraldo Pereira de Oliveira Filho",
      "Email": "nathan.financeiro@uol.com.br",
      "CustomerCode": "19193",
      "Phone": "1125026831",
      "Whatsapp": "11988295302",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2794,
      "Name": "GQ Neri Assessoria Contabil",
      "Email": "nina.neri@conericontabilidade.com.br",
      "CustomerCode": "65211",
      "Phone": "1981997298",
      "Whatsapp": "11959854436",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2795,
      "Name": "Ag4 Contabilidade e assessoria Ltda",
      "Email": "carlos.ignez@ag4contabil.com.br",
      "CustomerCode": "VEQVT4",
      "Phone": "1836378500",
      "Whatsapp": "18991056062",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2796,
      "Name": "ITtagiba de Paula Vieira",
      "Email": "escritoriocp@hotmail.com",
      "CustomerCode": "65212",
      "Phone": "3438514257",
      "Whatsapp": "34999612182",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2797,
      "Name": "CR Master Assessoria",
      "Email": "carolina.mastersolucoes@gmail.com",
      "CustomerCode": "19187",
      "Phone": "",
      "Whatsapp": "11975491363",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2798,
      "Name": "Ana Lucia Proli ",
      "Email": "al.proli@gmail.com",
      "CustomerCode": "19188",
      "Phone": "1122723340",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2799,
      "Name": "Bold Brasil Prestadora de Serviços LTDA",
      "Email": "financeiro@boldbr.com",
      "CustomerCode": "18414",
      "Phone": "1193689501",
      "Whatsapp": "11993689501",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2800,
      "Name": "Danilo Maximiano",
      "Email": "maximianocont@gmail.com",
      "CustomerCode": "19189",
      "Phone": "",
      "Whatsapp": "11958359045",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2801,
      "Name": "Grasiela ferreira da Silva ",
      "Email": "grasi.86@hotmail.com",
      "CustomerCode": "DKQJBL",
      "Phone": "3199063843",
      "Whatsapp": "31996033250",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2802,
      "Name": "Saulo Theotonio de Castro Junior",
      "Email": "castrojr54@gmail.com",
      "CustomerCode": "19190",
      "Phone": "",
      "Whatsapp": "12991333050",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2803,
      "Name": "Gustavo Kitz",
      "Email": "gustavokitz@gmailcom",
      "CustomerCode": "QMV25Y",
      "Phone": "1199003290",
      "Whatsapp": "11990032903",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2804,
      "Name": "Sonia Roque Polacchine ",
      "Email": "contato@spcontabilidade.digital",
      "CustomerCode": "8PS270",
      "Phone": "1147036217",
      "Whatsapp": "1147036217",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2805,
      "Name": "Condesp Escritório Contabil LTDA",
      "Email": "condespcontabil@uol.com.br",
      "CustomerCode": "17088",
      "Phone": "1136249550",
      "Whatsapp": "11981489842",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2806,
      "Name": "Milton Francisco dos Santos",
      "Email": "contabilecomar@gmail.com",
      "CustomerCode": "19195",
      "Phone": "1127016479",
      "Whatsapp": "11960628182",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2807,
      "Name": "Alexsandre Almeida de Freitas ",
      "Email": "alex_freitas8@hotmail.com",
      "CustomerCode": "19192",
      "Phone": "",
      "Whatsapp": "11991755770",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2808,
      "Name": "Jairo Goncalves",
      "Email": "evolucaocontabil.rgt@gmail.com",
      "CustomerCode": "65213",
      "Phone": "1338224311",
      "Whatsapp": "13997816545",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2809,
      "Name": "A&S Contabilidade LTDA",
      "Email": "camila@ascontabilidadeempresarial.com.br",
      "CustomerCode": "16914",
      "Phone": "1126528415",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2810,
      "Name": "Celso Barbosa",
      "Email": "celsobarbosa601@gmail.com",
      "CustomerCode": "DGXCHO",
      "Phone": "1194301718",
      "Whatsapp": "11943017181",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2811,
      "Name": "Roberta Bindaco da Silva Salomão",
      "Email": "salomao_contabilidade@hotmail.com",
      "CustomerCode": "65214",
      "Phone": "",
      "Whatsapp": "27997413729",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2812,
      "Name": "José Evandro Monteiro",
      "Email": "je.monteiro@hotmail.com",
      "CustomerCode": "URU866",
      "Phone": "1199171077",
      "Whatsapp": "11991710771",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2813,
      "Name": "Adelino Bernardo dos Santos Pereira",
      "Email": "adelino.pereira@uol.com.br",
      "CustomerCode": "19194",
      "Phone": "1136760070",
      "Whatsapp": "11996492523",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2814,
      "Name": "Gusinsky Serviços Contábeis LTDA",
      "Email": "societario@gusinsky.com",
      "CustomerCode": "65215",
      "Phone": "4899819558",
      "Whatsapp": "48998195586",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2815,
      "Name": "buch consulting",
      "Email": "valtierbuch.consulting@gmail.com",
      "CustomerCode": "XV579R",
      "Phone": "1196888326",
      "Whatsapp": "11968883260",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2818,
      "Name": "Manoel de Godóy Pires",
      "Email": "manoel446@terra.com.br",
      "CustomerCode": "61159",
      "Phone": "",
      "Whatsapp": "13997712915",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2819,
      "Name": "Fernando Bomfim ",
      "Email": "fernando@fernandocontador.com.br",
      "CustomerCode": "Q2JX0K",
      "Phone": "1120623332",
      "Whatsapp": "11999793494",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2820,
      "Name": "Antar Contabilidade ",
      "Email": "fernando@fernandocontador.com.br",
      "CustomerCode": "RYUZ4J",
      "Phone": "1120623332",
      "Whatsapp": "11999793494",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2821,
      "Name": "Vanessa dos Santos Alves Queiroz",
      "Email": "vanessa.queirozsaq09@gmail.com",
      "CustomerCode": "F5OM9H",
      "Phone": "1997402206",
      "Whatsapp": "19974022063",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2822,
      "Name": "Jose Wagner Ursini",
      "Email": "wagner.ursini@gmail.com",
      "CustomerCode": "JK29HX",
      "Phone": "1122033335",
      "Whatsapp": "11961374836",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2823,
      "Name": "Contabil CV Tonelli LTDA",
      "Email": "alessandra@cvtonelli.com.br",
      "CustomerCode": "19197",
      "Phone": "1123077000",
      "Whatsapp": "11974860170",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2825,
      "Name": "HNM Assessoria Contabil S/C LTDA",
      "Email": "fabio@equitycontabil.com.br",
      "CustomerCode": "19198",
      "Phone": "1130564222",
      "Whatsapp": "11993885873",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2826,
      "Name": "Walter Gamberini Junior",
      "Email": "wgamberinijr@gmail.com",
      "CustomerCode": "L57ZNQ",
      "Phone": "1135696229",
      "Whatsapp": "11974183982",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2827,
      "Name": "Jonathan cascalho Fonseca ",
      "Email": "joe.goias@hotmail.com",
      "CustomerCode": "XYLFIK",
      "Phone": "6284400369",
      "Whatsapp": "62984400369",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2828,
      "Name": "Fernando Feres Miranda",
      "Email": "legalizacao@mirandaassociados.com.br",
      "CustomerCode": "GAZHS3",
      "Phone": "1196681273",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2829,
      "Name": "Claudio Renato Correia de Melo",
      "Email": "legalizacao@mirandaassociados.com.br",
      "CustomerCode": "9QYB3Y",
      "Phone": "1145080070",
      "Whatsapp": "11966801273",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2834,
      "Name": "glj serviços administrativos ltda",
      "Email": "anderson.roberto@gljcontabil.com.br",
      "CustomerCode": "UJUNDB",
      "Phone": "1127240268",
      "Whatsapp": "11976736206",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2835,
      "Name": "Anderson Roberto de Oliveira",
      "Email": "anderson.roberto@gljcontabil.com.br",
      "CustomerCode": "IRLED1",
      "Phone": "1127240268",
      "Whatsapp": "11976736206",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2836,
      "Name": "Brazcon Contabilidade",
      "Email": "jccontabilista@gmail.com",
      "CustomerCode": "65216",
      "Phone": "",
      "Whatsapp": "61996875294",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2837,
      "Name": "João Batista Gonçalves",
      "Email": "gprix@terra.com.br",
      "CustomerCode": "19200",
      "Phone": "1156666776",
      "Whatsapp": "11934124515",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2838,
      "Name": "Antônio Carlos Monteiro",
      "Email": "acmmonteirocontabil@gmail.com",
      "CustomerCode": "K69FJ3",
      "Phone": "1199419265",
      "Whatsapp": "11994192655",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2839,
      "Name": "Alexandre Aparecido Domingos",
      "Email": "aldm.contabilidade@gmail.com",
      "CustomerCode": "15879",
      "Phone": "",
      "Whatsapp": "11974657232",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2840,
      "Name": "Pedro Fulvio",
      "Email": "pedroful@uol.com.br",
      "CustomerCode": "15557",
      "Phone": "1129733839",
      "Whatsapp": "11973114078",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2841,
      "Name": "Mapa Contabil EIRELI",
      "Email": "mapa@mapacontabil.com.br",
      "CustomerCode": "16185",
      "Phone": "1132940684",
      "Whatsapp": "11974691064",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2842,
      "Name": "Contabilidade Alvorada Paulista SS LTDA",
      "Email": "contabil@contabilidadealvorada.com.br",
      "CustomerCode": "19202",
      "Phone": "1127530189",
      "Whatsapp": "11945774903",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2843,
      "Name": "Edna Cerqueira",
      "Email": "edna2014c@gmail.com",
      "CustomerCode": "3FLE67",
      "Phone": "1989324432",
      "Whatsapp": "11989324432",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2844,
      "Name": "Certa Assessoria Empresarial LTDA",
      "Email": "rodrigo@certaassessoria.com.br",
      "CustomerCode": "XKIZ6W",
      "Phone": "5535331071",
      "Whatsapp": "55996470208",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2847,
      "Name": "Felipe Oliveira Vaz ",
      "Email": "felipeoliveiravaz@yahoo.com.br",
      "CustomerCode": "19201",
      "Phone": "1122058549",
      "Whatsapp": "11954752095",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2848,
      "Name": "Verolanio Soares dos Santos",
      "Email": "verolanio@outlook.com",
      "CustomerCode": "6KZ6R2",
      "Phone": "1182315837",
      "Whatsapp": "11982315837",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2849,
      "Name": "Verolanio Soares dos Santos",
      "Email": "verolanio@outlook.com",
      "CustomerCode": "FBELWT",
      "Phone": "1182315837",
      "Whatsapp": "11982315837",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2850,
      "Name": "Claudia",
      "Email": "claudiapiresdecastro5@gmail.com",
      "CustomerCode": "8I1N6S",
      "Phone": "1197661115",
      "Whatsapp": "11976611152",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2852,
      "Name": "Planizza Bacharel Escritório de Contabilidade LTDA",
      "Email": "planizza@planizza.com.br",
      "CustomerCode": "38599",
      "Phone": "1142202565",
      "Whatsapp": "11998749990",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2853,
      "Name": "Phaebus Contabilidade LTDA",
      "Email": "phaebus@terra.com.br",
      "CustomerCode": "35292",
      "Phone": "1124053355",
      "Whatsapp": "11940382096",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2854,
      "Name": "GLJ Assessoria Contabil e Empresarial LTDA",
      "Email": "dlegal@gljcontabil.com.br",
      "CustomerCode": "19204",
      "Phone": "1127240268",
      "Whatsapp": "11976736206",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2855,
      "Name": "Spartha Serviços Administrativos",
      "Email": "anderson.roberto@gljcontabilidade.com.br",
      "CustomerCode": "IT1AOT",
      "Phone": "1127240268",
      "Whatsapp": "11976736206",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2856,
      "Name": "Eliane Yachimciuc",
      "Email": "eliane.yachim@terra.com.br",
      "CustomerCode": "N77UNO",
      "Phone": "1238965077",
      "Whatsapp": "11999659991",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2857,
      "Name": "Anderson Cardoso Pimentel ",
      "Email": "anderson.cardosop@hotmail.com",
      "CustomerCode": "65218",
      "Phone": "",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2858,
      "Name": "Letícia Lépore Callefo",
      "Email": "leticia@omegacontab.com.br",
      "CustomerCode": "19203",
      "Phone": "1145881285",
      "Whatsapp": "11969125085",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2859,
      "Name": "Fharos Contabilidade e Gestão Empresarial LTDA",
      "Email": "regulariza@fharos.com.br",
      "CustomerCode": "15541",
      "Phone": "1133331777",
      "Whatsapp": "11987092078",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2860,
      "Name": "Contábil Bichler LTDA",
      "Email": "bruna@bichler.com.br",
      "CustomerCode": "38600",
      "Phone": "1143934377",
      "Whatsapp": "11968593117",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2861,
      "Name": "Nova Caiuba Comércio de Produtos de Padaria LTDA ME",
      "Email": "ardellrl@gmail.com",
      "CustomerCode": "Z1NKL4",
      "Phone": "1139192672",
      "Whatsapp": "11985906700",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2862,
      "Name": "Paulo Roberto Barroso dos Santos",
      "Email": "paulorobertonarroso201515@gmail.com",
      "CustomerCode": "H7G247",
      "Phone": "9899206175",
      "Whatsapp": "98992061751",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2863,
      "Name": "Paulo Roberto Barroso dos Santos",
      "Email": "paulorobertonarroso201515@gmail.com",
      "CustomerCode": "VR7P45",
      "Phone": "9899206175",
      "Whatsapp": "98992061751",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2864,
      "Name": "Doctorcont Auditoria, Pericia e Assessoria Contabil LTDA",
      "Email": "doctorcontfiscal@gmail.com",
      "CustomerCode": "8BJRM8",
      "Phone": "1238965077",
      "Whatsapp": "11999659991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2865,
      "Name": "Evaristo Hanhemann",
      "Email": "administrativo@hahnemann.com.br",
      "CustomerCode": "65219",
      "Phone": "1338642006",
      "Whatsapp": "13997890607",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2866,
      "Name": "Ariano Correa",
      "Email": "contavejuquia@gmail.com",
      "CustomerCode": "65220",
      "Phone": "1338441364",
      "Whatsapp": "13997237428",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2867,
      "Name": "Hub 5.0 Group Contabilidade Digital",
      "Email": "karen.masson@hub5group.com.br",
      "CustomerCode": "37485",
      "Phone": "",
      "Whatsapp": "11980137832",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2870,
      "Name": "SWPI Empreendimentos, Investimentos e Participações Sociedade Limitada.",
      "Email": "ines@trellis.com.br",
      "CustomerCode": "81Y2M1",
      "Phone": "1199321641",
      "Whatsapp": "1199321641",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2871,
      "Name": "Nirio Texeira",
      "Email": "niriotexeira@gmail.com",
      "CustomerCode": "DT7UJA",
      "Phone": "4796589658",
      "Whatsapp": "47996589658",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2872,
      "Name": "Vinícius Rosa Amorim da Silva",
      "Email": "vviniciusamorimm@gmail.com",
      "CustomerCode": "Q4MBBL",
      "Phone": "1799734032",
      "Whatsapp": "17997340328",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2873,
      "Name": "Claudia Maria dos Santos Trindade ",
      "Email": "contabilcmst@gmail.com",
      "CustomerCode": "35324",
      "Phone": "1199138616",
      "Whatsapp": "11991386167",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2874,
      "Name": "Vinicius Flores Laranja - VKF Assessoria Contábil",
      "Email": "vkfassessoria@gmail.com",
      "CustomerCode": "37484",
      "Phone": "1152354827",
      "Whatsapp": "11952354827",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2875,
      "Name": "Valdenir Nunes da Silva",
      "Email": "valdenir@todacontabil.com.br",
      "CustomerCode": "65245",
      "Phone": "",
      "Whatsapp": "21991049032",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2876,
      "Name": "Odetino Rocha",
      "Email": "rochaodetino@gmail.com",
      "CustomerCode": "N3JVAX",
      "Phone": "1133110042",
      "Whatsapp": "11976903867",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2877,
      "Name": "Carlos Roberto Kitagawa",
      "Email": "assessoriabar@hotmail.com",
      "CustomerCode": "63776",
      "Phone": "1733243009",
      "Whatsapp": "17997327166",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2878,
      "Name": "Naisa Aparecida Velicka de Oliveria",
      "Email": "cont.feline@terra.com.br",
      "CustomerCode": "40123",
      "Phone": "1147518481",
      "Whatsapp": "11912508980",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2879,
      "Name": "Ricardo Lima Fonseca",
      "Email": "ricardo@cunhacontabil.com.br",
      "CustomerCode": "19240",
      "Phone": "1132227881",
      "Whatsapp": "11999582063",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2880,
      "Name": "Marizete Carvalho de Camargo",
      "Email": "martins_isabel36@yahoo.com.br",
      "CustomerCode": "58B6JR",
      "Phone": "1198494153",
      "Whatsapp": "11951292147",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2881,
      "Name": "Rosicler Vilela",
      "Email": "rosevilelaadvocacia@gmail.com",
      "CustomerCode": "D5R6P5",
      "Phone": "3599905581",
      "Whatsapp": "35999055812",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2882,
      "Name": "Net Worth Consultoria SS",
      "Email": "carlos.limberti@networth.com.br",
      "CustomerCode": "17331",
      "Phone": "1151829882",
      "Whatsapp": "11983595777",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2883,
      "Name": "MZ Contax Assessoria Contabil LTDA",
      "Email": "legalizacao.bauru@ntwcontabilidade.com.br",
      "CustomerCode": "O3YO9V",
      "Phone": "1433127400",
      "Whatsapp": "14991066772",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2884,
      "Name": "Sérgio Fazia Domingues ",
      "Email": "sergio@gdincorporadora.com.br",
      "CustomerCode": "19211",
      "Phone": "1139959493",
      "Whatsapp": "11996875586",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2885,
      "Name": "Cibele Reis",
      "Email": "contato@cibicon.com.br",
      "CustomerCode": "19218",
      "Phone": "1195989016",
      "Whatsapp": "11957654784",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2886,
      "Name": "Fiscalli Inteligência Tributária LTDA",
      "Email": "contato@fiscalli.com.br",
      "CustomerCode": "65221",
      "Phone": "4137218721",
      "Whatsapp": "41998620161",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2887,
      "Name": "MC Controladoria e Consultoria LTDA",
      "Email": "vanessa@mccontroladoria.com.br",
      "CustomerCode": "AZQQPA",
      "Phone": "1199929675",
      "Whatsapp": "11999296756",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2889,
      "Name": "Wilson Aparecido Moreno",
      "Email": "wj.negocios.com@gmail.com",
      "CustomerCode": "17694",
      "Phone": "",
      "Whatsapp": "11966411837",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2890,
      "Name": "Rodrigues e Souza Cons Assess Empress SS",
      "Email": "rscontadores@terra.com.br",
      "CustomerCode": "19205",
      "Phone": "",
      "Whatsapp": "11998888554",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2892,
      "Name": "Fernanda Noronha Baptista",
      "Email": "fernandanbap@hotmail.com",
      "CustomerCode": "FKT2FO",
      "Phone": "1123415204",
      "Whatsapp": "11977785782",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2893,
      "Name": "Guilherme Santos Lima",
      "Email": "guisanlima_@hotmail.com",
      "CustomerCode": "19206",
      "Phone": "",
      "Whatsapp": "11982785780",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2894,
      "Name": "CCA Contabilidade, Consultoria E Auditoria LTDA",
      "Email": "adm@vestaaccounting.com.br",
      "CustomerCode": "19208",
      "Phone": "1131295967",
      "Whatsapp": "11981413918",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2895,
      "Name": "Lucio Paulino Filho",
      "Email": "lucpaulino@gmail.com",
      "CustomerCode": "16114",
      "Phone": "1172877608",
      "Whatsapp": "11972877608",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2896,
      "Name": "Marcos Castilho Alexandre",
      "Email": "alexandre.contalexconsultoria@gmail.com",
      "CustomerCode": "19207",
      "Phone": "1139041239",
      "Whatsapp": "11941802878",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2897,
      "Name": "Daniely Macedo",
      "Email": "daniely.macedo@yahoo.com.br",
      "CustomerCode": "0DFXAP",
      "Phone": "1197034201",
      "Whatsapp": "11997034201",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2898,
      "Name": "Adalberto José da Silva",
      "Email": "contabil@rbmulti.com.br",
      "CustomerCode": "UR6C5E",
      "Phone": "1150112829",
      "Whatsapp": "11981995092",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2899,
      "Name": "Orgtec Assessoria Contábil",
      "Email": "luciano@orgtec.com.br",
      "CustomerCode": "5A8YOT",
      "Phone": "1131841720",
      "Whatsapp": "1131841720",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2900,
      "Name": "Orccap Organização Contábil Capital SS LTDA ME",
      "Email": "rodrigo@orccap.com.br",
      "CustomerCode": "19217",
      "Phone": "1127830787",
      "Whatsapp": "1127830787",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2901,
      "Name": "Fabiana Flauzino ",
      "Email": "fabiana@delfcontabil.com.br",
      "CustomerCode": "19209",
      "Phone": "1194433201",
      "Whatsapp": "11944332014",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2902,
      "Name": "João Luiz Ferreira",
      "Email": "JOAO@JLFGN.COM.BR",
      "CustomerCode": "CAI0UY",
      "Phone": "1155602500",
      "Whatsapp": "11997646928",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2903,
      "Name": "Mercilaine Lacerda",
      "Email": "merci.lacerda@gmail.com",
      "CustomerCode": "AYRDJ0",
      "Phone": "1175971642",
      "Whatsapp": "11975971642",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2904,
      "Name": "Acadia Contabilidade",
      "Email": "contato@acadiacontabilidade.com.br",
      "CustomerCode": "19210",
      "Phone": "1144752312",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2905,
      "Name": "Jefferson",
      "Email": "legal03@quorumcontabil.com.br",
      "CustomerCode": "GDDCLV",
      "Phone": "1129417661",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2906,
      "Name": "Contabilidade Treinamento Ltda",
      "Email": "thaishasadocumentos@hotmail.com",
      "CustomerCode": "SCQ2YX",
      "Phone": "1133336660",
      "Whatsapp": "11943892854",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2907,
      "Name": "Fidelis de Souza Freire",
      "Email": "central@wfconsultoria.com.br",
      "CustomerCode": "TU4P9Q",
      "Phone": "1122361175",
      "Whatsapp": "11945527090",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2908,
      "Name": "Paloma Bianco Veloso",
      "Email": "Palomabianco2405@gmail.com",
      "CustomerCode": "18000",
      "Phone": "",
      "Whatsapp": "11961820661",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2909,
      "Name": "Jessica Marques dos Santos",
      "Email": "jessymarques.com@outlook.com",
      "CustomerCode": "19212",
      "Phone": "1199999999",
      "Whatsapp": "11969753980",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2910,
      "Name": "Antonio Leandro Contabilidade",
      "Email": "l.cont@terra.com.br",
      "CustomerCode": "80QQZ8",
      "Phone": "1156666161",
      "Whatsapp": "11984561616",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2911,
      "Name": "Sérgio Luis Neves leati ",
      "Email": "zabaroba@uol.com.br ",
      "CustomerCode": "15791",
      "Phone": "1129558605",
      "Whatsapp": "11999848644",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2912,
      "Name": "Mauro Jorge",
      "Email": "mauro@somaempresarial.net.br",
      "CustomerCode": "17532",
      "Phone": "1138513218",
      "Whatsapp": "11982596018",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2913,
      "Name": "Barbosa de Vinhedo Contabilidade S/S LTDA",
      "Email": "barbosa@barbosacontabilidade.com.br",
      "CustomerCode": "61222",
      "Phone": "1938267950",
      "Whatsapp": "19981713053",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2914,
      "Name": "Renato Salzano",
      "Email": "eticocontabilrenato@uol.com.br",
      "CustomerCode": "DHHMHY",
      "Phone": "1125463249",
      "Whatsapp": "11970897291",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2916,
      "Name": "Dutra Gestão Empresarial LTDA",
      "Email": "adrielsantos@dutragestao.srv.br",
      "CustomerCode": "88GTHW",
      "Phone": "5135274145",
      "Whatsapp": "51991025317",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2917,
      "Name": "GA Expert",
      "Email": "contato@gaexpert.com",
      "CustomerCode": "6CSRTW",
      "Phone": "1198389649",
      "Whatsapp": "11983896497",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2918,
      "Name": "Contacto Assessoria Contábil S/S",
      "Email": "supervisorsocietario@contactocontabil.com.br",
      "CustomerCode": "64696",
      "Phone": "4830353666",
      "Whatsapp": "48991124628",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2919,
      "Name": "1010 Assessoria Técnica Contábil SS",
      "Email": "douglas@contec.cnt.br",
      "CustomerCode": "15304",
      "Phone": "1135720500",
      "Whatsapp": "11999966764",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2920,
      "Name": "Fabricio Benedito Sanches Guimarães",
      "Email": "contato@contabilidadeguimaraes.com.br",
      "CustomerCode": "65223",
      "Phone": "1532345003",
      "Whatsapp": "15974039311",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2921,
      "Name": "Arthur",
      "Email": "arthurptm@gmail.com",
      "CustomerCode": "S1541I",
      "Phone": "6384190887",
      "Whatsapp": "63984190887",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2922,
      "Name": "Osmar Silva de Oliveira",
      "Email": "analycon@terra.com.br",
      "CustomerCode": "17578",
      "Phone": "1199963967",
      "Whatsapp": "11999639677",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2923,
      "Name": "Fernanda Maia Salzano",
      "Email": "solange.salzano@hotmail.com",
      "CustomerCode": "19213",
      "Phone": "1130573306",
      "Whatsapp": "11971703332",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2924,
      "Name": "Marcelo J. Esteves",
      "Email": "majoes162@gmail.com",
      "CustomerCode": "19283",
      "Phone": "1132945764",
      "Whatsapp": "11968036047",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2925,
      "Name": "Andrezza Freire",
      "Email": "andrezzafreirecosta@hotmail.com",
      "CustomerCode": "65224",
      "Phone": "",
      "Whatsapp": "",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 2926,
      "Name": "Elizabeth Alexandre de Souza",
      "Email": "elizabethenfermeira@hotmail.com",
      "CustomerCode": "90OMJO",
      "Phone": "9293437877",
      "Whatsapp": "92993437877",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2927,
      "Name": "Alexandre Montovani da Silva",
      "Email": "montovani.ale@gmail.com",
      "CustomerCode": "NCC8SE",
      "Phone": "1193428504",
      "Whatsapp": "11993428504",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2928,
      "Name": "Aldo Cesar Benega",
      "Email": "aldobenega@gmail.com",
      "CustomerCode": "19301",
      "Phone": "",
      "Whatsapp": "11999903029",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2929,
      "Name": "Antonio Lemes dos Reis",
      "Email": "lemes.reis@gmail.com",
      "CustomerCode": "IP8F02",
      "Phone": "1982045103",
      "Whatsapp": "19982045103",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2930,
      "Name": "Advise Assessoria Contabil Sociedade Simples",
      "Email": "diretoria@advisecontabil.com.br",
      "CustomerCode": "19215",
      "Phone": "1126280051",
      "Whatsapp": "11997879781",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2931,
      "Name": "Avinu Malkeinu Assessoria",
      "Email": "avinumalkeinuassessoria@gmail.com",
      "CustomerCode": "7ERFGT",
      "Phone": "1122334455",
      "Whatsapp": "11966635133",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2932,
      "Name": "Evolução Contábil",
      "Email": "dhara@portalevolucao.com.br",
      "CustomerCode": "19214",
      "Phone": "1136229096",
      "Whatsapp": "11975901024",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2933,
      "Name": "Audicon Escritório de Contabiladade",
      "Email": "deise@escritorioaudicon.com.br",
      "CustomerCode": "65225",
      "Phone": "1930241718",
      "Whatsapp": "19993698088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2934,
      "Name": "Wilson Coelho Dias",
      "Email": "wilcd1902@yahoo.com.br",
      "CustomerCode": "G8C5GY",
      "Phone": "0000000000",
      "Whatsapp": "11932329419",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2935,
      "Name": "Nivaldo Alves Martins",
      "Email": "nivaldo@gmcontabilidade.com.br",
      "CustomerCode": "17895",
      "Phone": "1127215399",
      "Whatsapp": "11947461588",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2937,
      "Name": "Intelecto Contabilidade",
      "Email": "rh@intelectocontabil.cnt.br",
      "CustomerCode": "69YHEZ",
      "Phone": "1125592058",
      "Whatsapp": "11995826947",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2939,
      "Name": "Certa Consultoria Empresarial LTDA",
      "Email": "flavia.freitas@certaconsultoria.com",
      "CustomerCode": "65226",
      "Phone": "3432216602",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2940,
      "Name": "Tetracont Contabilidade LTDA",
      "Email": "vagner@tetracont.com.br",
      "CustomerCode": "61596",
      "Phone": "1735213266",
      "Whatsapp": "17992165832",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2941,
      "Name": "Mario Bergmanhs",
      "Email": "ctbmaber@terra.com.br",
      "CustomerCode": "15185",
      "Phone": "1133608523",
      "Whatsapp": "11931450707",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2942,
      "Name": "PX Accouting",
      "Email": "ivanat@pxcs.com.br",
      "CustomerCode": "ZS9L57",
      "Phone": "1199774442",
      "Whatsapp": "11997744427",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2943,
      "Name": "Márcio Nunes Paiva",
      "Email": "athenaconsultores@yahoo.com.br",
      "CustomerCode": "64518",
      "Phone": "4732684465",
      "Whatsapp": "47996110173",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2944,
      "Name": "Jose Antonio Goncalves",
      "Email": "jantalves47@gmail.com",
      "CustomerCode": "I8Y21F",
      "Phone": "1120156830",
      "Whatsapp": "11987239368",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2945,
      "Name": "Joab Machado Contabilidade LTDA",
      "Email": "vinicius@jmescritorio.com.br",
      "CustomerCode": "C702FL",
      "Phone": "1732135052",
      "Whatsapp": "17991539941",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2946,
      "Name": "Alecorp Assesoria Contábil Ltda",
      "Email": "mary@grupomary.com.br",
      "CustomerCode": "AO397L",
      "Phone": "1932011051",
      "Whatsapp": "1932011052",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2947,
      "Name": "Thamyres",
      "Email": "thamyreslima@gruponovacont.com.br",
      "CustomerCode": "8VXCAR",
      "Phone": "2126672555",
      "Whatsapp": "21984976261",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2948,
      "Name": "Carlos Alberto Prando",
      "Email": "escritorioconig@terra.com.br",
      "CustomerCode": "61701",
      "Phone": "1436441413",
      "Whatsapp": "14997411930",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2951,
      "Name": "Caio Cezar Correa de Mello ",
      "Email": "caiocezar@correamelloadvocacia.com",
      "CustomerCode": "64743",
      "Phone": "1938073131",
      "Whatsapp": "19999809973",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2952,
      "Name": "Balzac Contabilidade",
      "Email": "balzac.contabil@uol.com.br",
      "CustomerCode": "89QFOW",
      "Phone": "1126746291",
      "Whatsapp": "11995012014",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2954,
      "Name": "Salusa Account",
      "Email": "contato@salusagroup.com",
      "CustomerCode": "37400",
      "Phone": "1111111111",
      "Whatsapp": "11959903684",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2955,
      "Name": "Renan Bueno Silva",
      "Email": "renanbuenosilva@hotmail.com",
      "CustomerCode": "FTDW0C",
      "Phone": "1199446052",
      "Whatsapp": "11994460524",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2957,
      "Name": "Francisco José Mulato",
      "Email": "advogado@fjmjur.com",
      "CustomerCode": "5YJ125",
      "Phone": "1129796373",
      "Whatsapp": "11999161492",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2958,
      "Name": "Marcio Pelizzon da Silva",
      "Email": "suporte@m2mcontabilidade.com.br",
      "CustomerCode": "65227",
      "Phone": "2186663170",
      "Whatsapp": "21986663170",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2959,
      "Name": "Cial Contabilidade",
      "Email": "cial-dantas@uol.com.br",
      "CustomerCode": "7O5XC4",
      "Phone": "1149302425",
      "Whatsapp": "11942552425",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2960,
      "Name": "4Four Contabilidade LTDA",
      "Email": "comercial@4fourcontabilidade.com.br",
      "CustomerCode": "65228",
      "Phone": "2120421943",
      "Whatsapp": "21995763605",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2961,
      "Name": "Leonardo Freitas do Nascimento",
      "Email": "atendimento.contafacil@gmail.com",
      "CustomerCode": "IW7K6H",
      "Phone": "2299073205",
      "Whatsapp": "22999073205",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2962,
      "Name": "Lacobre Ind. e Com. de Metais",
      "Email": "financeiro.lacobre@gmail.com",
      "CustomerCode": "R3F85P",
      "Phone": "1141782053",
      "Whatsapp": "11949957895",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2963,
      "Name": "Jose Carlos Caetano",
      "Email": "zkarloscae@gmail.com",
      "CustomerCode": "16629",
      "Phone": "1137811419",
      "Whatsapp": "11998601470",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2964,
      "Name": "Bruna Eugenio",
      "Email": "brunaeugenio@contabilgelamo.com.br",
      "CustomerCode": "XKC4GN",
      "Phone": "1435921111",
      "Whatsapp": "14996104456",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2965,
      "Name": "Vicente Ferreira",
      "Email": "vicente.f@uol.com.br",
      "CustomerCode": "19219",
      "Phone": "1136662428",
      "Whatsapp": "11999768009",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2966,
      "Name": "Gustavo Ouvinhas Gavioli",
      "Email": "gustavo.gavioli@coelhoegavioli.com.br",
      "CustomerCode": "E3L085",
      "Phone": "1199504695",
      "Whatsapp": "11995046957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2967,
      "Name": "Zanchetta S/S LTDA",
      "Email": "legal@ciglacontabil.com.br",
      "CustomerCode": "38427",
      "Phone": "1149955353",
      "Whatsapp": "11973443927",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2968,
      "Name": "Kleber Fernandes",
      "Email": "kleber.contabil@hotmail.com",
      "CustomerCode": "VW9I9T",
      "Phone": "1147394117",
      "Whatsapp": "11947394117",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2969,
      "Name": "Kontrolla Terceirização de Processos e Negócios LTDA",
      "Email": "luanna.batista@kontrolla.com.br",
      "CustomerCode": "0NKC91",
      "Phone": "1143951902",
      "Whatsapp": "1143951902",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2970,
      "Name": "Conta Certa Contabilidade ",
      "Email": "liborsi@hotmail.com",
      "CustomerCode": "Q61UTY",
      "Phone": "1139157650",
      "Whatsapp": "11994518184",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2971,
      "Name": "Jeferson André ",
      "Email": "Jeferson27andre@gmail.com",
      "CustomerCode": "VOAVJU",
      "Phone": "5198133242",
      "Whatsapp": "51981332421",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2972,
      "Name": "BEER Place Palhoça ltda",
      "Email": "Jeferson27andre@gmail.com",
      "CustomerCode": "L7VWLY",
      "Phone": "4820230045",
      "Whatsapp": "51981332421",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2973,
      "Name": "francielli",
      "Email": "mfconsultoriatga@outlook.com",
      "CustomerCode": "5WDWOZ",
      "Phone": "6599313326",
      "Whatsapp": "65993133264",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2975,
      "Name": "Odair Ferreira da Silva",
      "Email": "odair91@hotmail.com",
      "CustomerCode": "16893",
      "Phone": "",
      "Whatsapp": "11986387318",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2976,
      "Name": "Arline Aparecida Correa",
      "Email": "arlinec61@gmail.com",
      "CustomerCode": "KNIFDO",
      "Phone": "1136955042",
      "Whatsapp": "11947516272",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2977,
      "Name": "Nelson Sérgio Martins Junior",
      "Email": "societario@conpartner.com.br",
      "CustomerCode": "F9OD1P",
      "Phone": "4730456710",
      "Whatsapp": "47988025848",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2978,
      "Name": "Papaiz Associados Diagnosticos por Imagem S.A",
      "Email": "areaseg@papaizassociados.com.br",
      "CustomerCode": "19216",
      "Phone": "1135439535",
      "Whatsapp": "11998217341",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2979,
      "Name": "Heros Processamento de Dados Ltda",
      "Email": "everton@heroscontabil.com.br",
      "CustomerCode": "18322",
      "Phone": "1134375500",
      "Whatsapp": "11974384121",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2980,
      "Name": "Diogo Bitencorte Santos",
      "Email": "contador.diogo@yahoo.com",
      "CustomerCode": "65246",
      "Phone": "",
      "Whatsapp": "12982339279",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2981,
      "Name": "Guilherme de Oliveira fernandes",
      "Email": "eduardonfernandes@yahoo.com.br",
      "CustomerCode": "KSTKB1",
      "Phone": "1998182646",
      "Whatsapp": "19981826460",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2982,
      "Name": "Claudia Marina Silva pinho ",
      "Email": "clauflores3016@gmail.com",
      "CustomerCode": "A30EQ2",
      "Phone": "11",
      "Whatsapp": "11994069428",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2983,
      "Name": "Marcelo Dias Sociedade Individual de Advocacia",
      "Email": "marcelo.dias@marcelodias.adv.br",
      "CustomerCode": "18674",
      "Phone": "1532595540",
      "Whatsapp": "11974226198",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2984,
      "Name": "Priscila Batista",
      "Email": "priscilacsouzabatista@gmail.com",
      "CustomerCode": "TAK150",
      "Phone": "1435127247",
      "Whatsapp": "14998097023",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2985,
      "Name": "Innovatio Contabilidade",
      "Email": "aldenir.motta@innovatiocontabilidade.com",
      "CustomerCode": "18150",
      "Phone": "1198910620",
      "Whatsapp": "11989106201",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2986,
      "Name": "Cicero Lima",
      "Email": "cicero.c.lima@gmail.com",
      "CustomerCode": "B1TY0U",
      "Phone": "1147294085",
      "Whatsapp": "11947294085",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2987,
      "Name": "Helio Ferreira Pires Junior",
      "Email": "heliofpjunior@hotmail.com",
      "CustomerCode": "DOYA8D",
      "Phone": "4499172670",
      "Whatsapp": "44999172670",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2989,
      "Name": "Maristela Signorini",
      "Email": "contato@sigcontabil.com",
      "CustomerCode": "65229",
      "Phone": "1797588348",
      "Whatsapp": "17997588348",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2990,
      "Name": "NTW Contabilidade SP Pinheiros",
      "Email": "Joquebede@ntwcontabilidade.com.br",
      "CustomerCode": "V3PAOH",
      "Phone": "1194796627",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2991,
      "Name": "NTW Contabilidade SP Pinheiros",
      "Email": "Joquebede@ntwcontabilidade.com.br",
      "CustomerCode": "19222",
      "Phone": "1147966272",
      "Whatsapp": "11947966272",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2992,
      "Name": "Projeto o Bom Pastor",
      "Email": "pequenojoao@ig.com.br",
      "CustomerCode": "JNZATN",
      "Phone": "2141160956",
      "Whatsapp": "21998851231",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 2994,
      "Name": "Josiane Queiroz soares",
      "Email": "josiane.qs@bol.com.br",
      "CustomerCode": "1LL1A5",
      "Phone": "",
      "Whatsapp": "11967810119",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2995,
      "Name": "DGA Corporate Contabilidade e Tecnologia LTDA",
      "Email": "stephanie.santos@dga.com.br",
      "CustomerCode": "VOQKSB",
      "Phone": "1399147049",
      "Whatsapp": "13991470497",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 2998,
      "Name": "Milton Alves de Mendonca",
      "Email": "milton.a.mendonca@gmail.com",
      "CustomerCode": "NF9QR0",
      "Phone": "8199654676",
      "Whatsapp": "81996546766",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 2999,
      "Name": "Damaris Valiante",
      "Email": "damariscont14@hotmail.com",
      "CustomerCode": "19223",
      "Phone": "1120163757",
      "Whatsapp": "11993070245",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3000,
      "Name": "Zonaro Consultores e Contadores Ltda",
      "Email": "zonaro@zonaro.com.br",
      "CustomerCode": "17111",
      "Phone": "1131745555",
      "Whatsapp": "11989412466",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3001,
      "Name": "Mario Cesar de Macedo",
      "Email": "mariocesarmacedo@gmail.com",
      "CustomerCode": "K78XQR",
      "Phone": "1121433105",
      "Whatsapp": "11999839230",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3002,
      "Name": "Janieiry Queiroga",
      "Email": "jqc.contadora@gmail.com",
      "CustomerCode": "65230",
      "Phone": "9183634319",
      "Whatsapp": "91983634319",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3003,
      "Name": "Lucia Elena Pereira Perle",
      "Email": "lucia@contjan.com.br",
      "CustomerCode": "SABQEO",
      "Phone": "1147894952",
      "Whatsapp": "1147894952",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3004,
      "Name": "G.P Contabilidade e Serviços Administrativos LTDA",
      "Email": "gilberto.p.contabilista@gmail.com",
      "CustomerCode": "19329",
      "Phone": "1173376479",
      "Whatsapp": "11973376479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3005,
      "Name": "Luiz Carlos Prudente de Oliveira",
      "Email": "saopaulo@abcrede.com.br",
      "CustomerCode": "63555",
      "Phone": "1899105605",
      "Whatsapp": "18991056055",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3006,
      "Name": "Profissional Contabil",
      "Email": "rogerio@profissionalcontabil.com.br",
      "CustomerCode": "19220",
      "Phone": "1168217097",
      "Whatsapp": "11968217097",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3007,
      "Name": "Dayber Ferreira de Oliveira",
      "Email": "legalizacao.mc@gmail.com",
      "CustomerCode": "65231",
      "Phone": "3137796888",
      "Whatsapp": "31993450293",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3008,
      "Name": "Laguna Contabilidade",
      "Email": "contato@lagunacontabil.com.br",
      "CustomerCode": "R7INCF",
      "Phone": "1698820609",
      "Whatsapp": "16988206092",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3009,
      "Name": "Mauricio Aparecido Ruiz",
      "Email": "ruizmm77@gmail.com",
      "CustomerCode": "18771",
      "Phone": "5598352271",
      "Whatsapp": "55983522717",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3010,
      "Name": "Josicley Rosa",
      "Email": "josicleyr@gmail.com",
      "CustomerCode": "EWEO76",
      "Phone": "9298522941",
      "Whatsapp": "92991262515",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3011,
      "Name": "R R da Silva Contabilidade",
      "Email": "robertonerosi2@gmail.com",
      "CustomerCode": "1LA1B7",
      "Phone": "1139015354",
      "Whatsapp": "11916506066",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3012,
      "Name": "Ednaldo Bezerra Gomes",
      "Email": "ednaldobezerragomes@gmail.com",
      "CustomerCode": "XEA46A",
      "Phone": "1141597599",
      "Whatsapp": "11974605327",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3013,
      "Name": "Ricardo Paracencio",
      "Email": "ricardoparacencio@gmail.com",
      "CustomerCode": "434VNA",
      "Phone": "1999379454",
      "Whatsapp": "19993794541",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3014,
      "Name": "Vinicius Braga",
      "Email": "viniciuszbraga@gmail.com",
      "CustomerCode": "65232",
      "Phone": "6530511840",
      "Whatsapp": "65999999516",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3015,
      "Name": "maria eunice dos santos dias",
      "Email": "eunicefarma7@gmail.com",
      "CustomerCode": "WZGTD6",
      "Phone": "3599992628",
      "Whatsapp": "35999926285",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3016,
      "Name": "Viviane Cristina Branco",
      "Email": "viviane.branco@gmail.com",
      "CustomerCode": "17143",
      "Phone": "1182053205",
      "Whatsapp": "11982053205",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3017,
      "Name": "Luciano Reis da Silva",
      "Email": "lureisrj@gmail.com ",
      "CustomerCode": "65233",
      "Phone": "2199314493",
      "Whatsapp": "21993144934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3018,
      "Name": "Daniel Rodrigues Mourao",
      "Email": "prass.servicosjuridicos@gmail.com",
      "CustomerCode": "CBHAIU",
      "Phone": "1140408522",
      "Whatsapp": "11974753385",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3019,
      "Name": "Ricardo Moreira Henrique ",
      "Email": "stgserginho@gmail.com",
      "CustomerCode": "19221",
      "Phone": "1150988977",
      "Whatsapp": "11984924593",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3020,
      "Name": "Alexandre Luiz da Silva Rabelo",
      "Email": "rabelo211@hotmail.com",
      "CustomerCode": "5R5ELP",
      "Phone": "4898092895",
      "Whatsapp": "48998092895",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3021,
      "Name": "Catiana Mittmann",
      "Email": "catianamittmann10@yahoo.com.br",
      "CustomerCode": "ZYC7TP",
      "Phone": "4999662746",
      "Whatsapp": null,
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3022,
      "Name": "José da Silva",
      "Email": "pr.josesilva@hotmail.com",
      "CustomerCode": "UP8BP0",
      "Phone": "1127527152",
      "Whatsapp": "11994460524",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3024,
      "Name": "AGS Serviço de Contabilidade LTDA",
      "Email": "contags123@gmail.com",
      "CustomerCode": "D2PD4B",
      "Phone": "1150449185",
      "Whatsapp": "11994900322",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3025,
      "Name": "Aoki Contabilidade LTDA",
      "Email": "aoki_consultoria@hotmail.com",
      "CustomerCode": "19224 ",
      "Phone": "1131057552",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3027,
      "Name": "Carlos Eduardo Pedro",
      "Email": "carloseduardopedro15@gmail.com",
      "CustomerCode": "FUTHKI",
      "Phone": "1181559544",
      "Whatsapp": "11981559544",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3028,
      "Name": "Asserv Digitação LTDA",
      "Email": "contabilmade@uol.com.br",
      "CustomerCode": "15341",
      "Phone": "1155622584",
      "Whatsapp": "11999997993",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3029,
      "Name": "Celso de Souza Almeida",
      "Email": "calculo@linkway.com.br",
      "CustomerCode": "KPH10Z",
      "Phone": "1634133451",
      "Whatsapp": "16997729486",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3030,
      "Name": "Manoel",
      "Email": "manoelcontador2051@gmail.com",
      "CustomerCode": "8DGXQZ",
      "Phone": "1144561462",
      "Whatsapp": "11943410103",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3031,
      "Name": "Reth Assessoria Contabil",
      "Email": "elizeu@reth.com.br",
      "CustomerCode": "18120",
      "Phone": "1133262296",
      "Whatsapp": "11947280118",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3032,
      "Name": "Cibicon Contabilidade",
      "Email": "cibicon.servicos@gmail.com",
      "CustomerCode": "ONSHI5",
      "Phone": "1157654784",
      "Whatsapp": "11957654784",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3033,
      "Name": "Contábil Oliveira Jr",
      "Email": "carlos@oliveirajr.com.br",
      "CustomerCode": "38326",
      "Phone": "1141216111",
      "Whatsapp": "11972085404",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3034,
      "Name": "Contag Contabilidade SS",
      "Email": "contag@contag.com.br",
      "CustomerCode": "19277",
      "Phone": "1128020222",
      "Whatsapp": "11999460543",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3037,
      "Name": "Márcio D´Amato",
      "Email": "marciodamato@hotmail.com",
      "CustomerCode": "1Y8UR8",
      "Phone": "1196524731",
      "Whatsapp": "11996524731",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3039,
      "Name": "Alves & Avelar Assessoria Contábil",
      "Email": "marta@alvesavelar.com.br",
      "CustomerCode": "16706",
      "Phone": "1132290982",
      "Whatsapp": "11998884486",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3040,
      "Name": "Soma Cooperativa de Trabalho em Tecnologia da Informação",
      "Email": "marta.santos@soma.coop.br",
      "CustomerCode": "BPYGV0",
      "Phone": "1198298055",
      "Whatsapp": "11982980555",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3041,
      "Name": "Lima Contabilidade Gestão Empresarial LTDA",
      "Email": "reynaldo.lima@lima.com.br",
      "CustomerCode": "19251",
      "Phone": "1129522220",
      "Whatsapp": "11970644555",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3042,
      "Name": "Brasconti Adviser Praticas Contabeis",
      "Email": "paralegal@brascontiadviser.com.br",
      "CustomerCode": "19226",
      "Phone": "1122216714",
      "Whatsapp": "11995260515",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3043,
      "Name": "Agência e Produtora Jeolsi",
      "Email": "renan@jeolsi.com",
      "CustomerCode": "19230",
      "Phone": "1199446052",
      "Whatsapp": "11994460524",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3044,
      "Name": "Cleber José Moreira",
      "Email": "cleberjmoreira@gmail.com",
      "CustomerCode": "19227",
      "Phone": "1197440838",
      "Whatsapp": "11974408381",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3045,
      "Name": "Luiz Carlos Renzi",
      "Email": "previsora@uol.com.br",
      "CustomerCode": "40172",
      "Phone": "1147481041",
      "Whatsapp": "11960785957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3047,
      "Name": "Marcelo de Souza Moraes",
      "Email": "mmoraesdv@gmail.com",
      "CustomerCode": "19228",
      "Phone": "1938467707",
      "Whatsapp": "19981818840",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3048,
      "Name": "Talita Cristina Moya dos Santos",
      "Email": "tcacontabilefiscal@gmail.com",
      "CustomerCode": "37494",
      "Phone": "",
      "Whatsapp": "11958053165",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3050,
      "Name": "Contabil Barão Assessoria Empresarial LTDA",
      "Email": "oswaldo@contabilbarao.com.br",
      "CustomerCode": "16765",
      "Phone": "1155242210",
      "Whatsapp": "11996256190",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3051,
      "Name": "kanamaro & Lucas Consultoria Contábil e Tributária SS LTDA.",
      "Email": "julio@kanamaroelucas.com.br",
      "CustomerCode": "16586",
      "Phone": "1131515063",
      "Whatsapp": "11993129763",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3052,
      "Name": "Cesar da Silva e Oliveira",
      "Email": "cesar@oliveira-contabil.com",
      "CustomerCode": "19231",
      "Phone": "1125484706",
      "Whatsapp": "11999250001",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3053,
      "Name": "Confiar Gestão Contabil LTDA",
      "Email": "cristianosantos1@gmail.com",
      "CustomerCode": "19234",
      "Phone": "1140963018",
      "Whatsapp": "11942524957",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3054,
      "Name": "Jeremias da Nóbrega Linhares",
      "Email": "uniqcontdigital@gmail.com",
      "CustomerCode": "65234",
      "Phone": "8499986669",
      "Whatsapp": "84999866693",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3055,
      "Name": "Escritório Contábil Interlagos",
      "Email": "gnreis@uol.com.br",
      "CustomerCode": "65235",
      "Phone": "1156129545",
      "Whatsapp": "11976857179",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3056,
      "Name": "Organização Contabil Estrella LTDA",
      "Email": "sharlene@ocestrella.com.br",
      "CustomerCode": "A5BB6D",
      "Phone": "1129141406",
      "Whatsapp": "11983022349",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3057,
      "Name": "Estrella Suporte Administrativo LTDA",
      "Email": "legalização@ocestrella.com.br",
      "CustomerCode": "Y41VQV",
      "Phone": "1129141406",
      "Whatsapp": "11983022349",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3058,
      "Name": "Fiscontau Contabilidade",
      "Email": "vmelo@fiscontau.com.br",
      "CustomerCode": "19257",
      "Phone": "1122250039",
      "Whatsapp": "11972524345",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3059,
      "Name": "Estanislau Eder Vick",
      "Email": "edervick.contador@gmail.com",
      "CustomerCode": "VP2E56",
      "Phone": "4788805479",
      "Whatsapp": "47988805479",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3060,
      "Name": "Edson Luiz da Silva",
      "Email": "edson@eluslicenciamento.com.br",
      "CustomerCode": "Z3N9AI",
      "Phone": "5511999577",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3061,
      "Name": "Carlos Eduardo",
      "Email": "JAC@JACONT.COM.BR",
      "CustomerCode": "7T20N9",
      "Phone": "1236327373",
      "Whatsapp": "12991147783",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3062,
      "Name": "Imero Mussolin Filho",
      "Email": "mussolinadvogados@gmail.com",
      "CustomerCode": "38602",
      "Phone": "1199191170",
      "Whatsapp": "11991911707",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3064,
      "Name": "Pires Viieira Sociedade Individual de Advocacia",
      "Email": "mayra@vieiraemarques.com",
      "CustomerCode": "1Y8ZSP",
      "Phone": "1131814523",
      "Whatsapp": "11997794450",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3065,
      "Name": "Bruna Severiana",
      "Email": "bruna.seve@outlook.com",
      "CustomerCode": "2BIRD5",
      "Phone": "7799811629",
      "Whatsapp": "77998116298",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3066,
      "Name": "Thayslany Arruda Gomes",
      "Email": "fiscal9@contabilidaderiogrande.com.br",
      "CustomerCode": "5V0B51",
      "Phone": "7799057373",
      "Whatsapp": "77999057373",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3067,
      "Name": "Agnaldo Pedro de Oliveira",
      "Email": "Agnaldo.oliveira@contabilidadegpo.com.br",
      "CustomerCode": "17956",
      "Phone": "1136732255",
      "Whatsapp": "11913612411",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3068,
      "Name": "SSCA Consultoria e Assessoria",
      "Email": "ingrid.santos@ssca.com.br",
      "CustomerCode": "19255",
      "Phone": "1151806666",
      "Whatsapp": "11972914998",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3069,
      "Name": "Princípio Contabilidade Digital",
      "Email": "rodolfo@principiocontabilidade.com.br",
      "CustomerCode": "19247",
      "Phone": "1144863735",
      "Whatsapp": "11993996900",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3070,
      "Name": "Renato Pedro da Silva",
      "Email": "genesyscont@terra.com.br",
      "CustomerCode": "17251",
      "Phone": "1125776519",
      "Whatsapp": "11972832369",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3071,
      "Name": "EBB Master FRQ01 EMS Fitness do Brasil Ltda",
      "Email": "administracao@ebodybrasil.com.br",
      "CustomerCode": "19238",
      "Phone": "9999999999",
      "Whatsapp": "11963288577",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3072,
      "Name": "Silva Pinto Assessoria Contabil",
      "Email": "cadastro2@spcontab.com.br",
      "CustomerCode": "19256",
      "Phone": "1135026466",
      "Whatsapp": "11987687219",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3073,
      "Name": "Lucas Carvalho Martins José ",
      "Email": "lcmj2912@gmail.com",
      "CustomerCode": "65236",
      "Phone": "2198242828",
      "Whatsapp": "21982428282",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3074,
      "Name": "Pense Contabil LTDA",
      "Email": "antonio.polato@pensecontabil.com",
      "CustomerCode": "19232",
      "Phone": "1122034111",
      "Whatsapp": "11970911317",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3076,
      "Name": "Escritório Contabil Multilucro",
      "Email": "processos@multilucro.com.br",
      "CustomerCode": "65237",
      "Phone": "6634232477",
      "Whatsapp": "6634233058",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3077,
      "Name": "Divisao Contabil ltda",
      "Email": "divisao@divisaocontabil.com.br",
      "CustomerCode": "35325",
      "Phone": "1124557700",
      "Whatsapp": "11999997418",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3078,
      "Name": "Ana Maria Ramalho ",
      "Email": "Amramalho20@gmail.com",
      "CustomerCode": "NVBYG8",
      "Phone": "6599219024",
      "Whatsapp": "65999219024",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3079,
      "Name": "Sergio de Souza",
      "Email": "metodossolucoes@gmail.com",
      "CustomerCode": " 64555",
      "Phone": "1140386872",
      "Whatsapp": "11999181837",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3081,
      "Name": "Marcelo Sampaio Teixeira",
      "Email": "marcelosampaioteixeira1@gmail.com",
      "CustomerCode": "BZJK8Y",
      "Phone": "1194027475",
      "Whatsapp": "11940274752",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3082,
      "Name": "Valore Contabilidade e Assessoria lTDA",
      "Email": "suelen@sejavalor.com.br",
      "CustomerCode": "65248",
      "Phone": "2137863139",
      "Whatsapp": "21998008833",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3083,
      "Name": "MJ Serviços sw promoção Vendas LTDA ME",
      "Email": "rafaela_siciliano@yahoo.com.br",
      "CustomerCode": "38584",
      "Phone": "1123818358",
      "Whatsapp": "11983742737",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3084,
      "Name": "Contmariz Contabilidade LTDA",
      "Email": "telma.contmariz@gmail.com ",
      "CustomerCode": "19236",
      "Phone": "1138517962",
      "Whatsapp": "11952456104",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3085,
      "Name": "PJZEN Apoio Administratuvo LTDA",
      "Email": "bsantos@pjzen.com",
      "CustomerCode": "19291",
      "Phone": "1152002510",
      "Whatsapp": "11933140590",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3087,
      "Name": "Agil Contabilidade",
      "Email": "marcelo@agilcontabilidade.com.br",
      "CustomerCode": "19235",
      "Phone": "1193313209",
      "Whatsapp": "11933132090",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3088,
      "Name": "Innovar Assessoria Empresarial",
      "Email": "renata@innovarassessoriacontabil.com",
      "CustomerCode": "19243",
      "Phone": "1120742513",
      "Whatsapp": "11995779991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3089,
      "Name": "Castro e Silva Contadores Associados LTDA",
      "Email": "societario@movenegocios.com.br",
      "CustomerCode": "QO2DUV",
      "Phone": "4195398554",
      "Whatsapp": "41999658619",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3090,
      "Name": "José",
      "Email": "jose@hasa.com.br",
      "CustomerCode": "BWVS4J",
      "Phone": "1199446052",
      "Whatsapp": "11994460524",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3091,
      "Name": "Poddium Organização Contábil",
      "Email": "poddium.fiscal@terra.com.br",
      "CustomerCode": "38144",
      "Phone": "1143300788",
      "Whatsapp": "11941415731",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3092,
      "Name": "José",
      "Email": "jose1@jeolsi.com",
      "CustomerCode": "0L5WC1",
      "Phone": "1199446052",
      "Whatsapp": "11994460524",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3093,
      "Name": "De Maria Sociedade de Advogados",
      "Email": "contato@dmsadvogados.adv.br",
      "CustomerCode": "17982",
      "Phone": "1125799100",
      "Whatsapp": "11257991000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3095,
      "Name": "Claritas Assessoria e Consultoria Contabil LTDA",
      "Email": "luciana@claritascontabil.com.br",
      "CustomerCode": "65261",
      "Phone": "1173235949",
      "Whatsapp": "11978306000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3096,
      "Name": "Diego Fabiano Tome Gozzo",
      "Email": "andrebarbosa501@outlook.com",
      "CustomerCode": "RUM9W1",
      "Phone": "1138357679",
      "Whatsapp": "11982188526",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3098,
      "Name": "Alessandro Lustosa Damasceno",
      "Email": "ale_ld@yahoo.com.br",
      "CustomerCode": "6638ZM",
      "Phone": "1181658208",
      "Whatsapp": "11981658208",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3099,
      "Name": "Alessandro Lustosa Damasceno",
      "Email": "ale_ld@yahoo.com.br",
      "CustomerCode": "7QDV8Y",
      "Phone": "1181658208",
      "Whatsapp": "11981658208",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3100,
      "Name": "Contacte Assessoria e Consultoria Contabil LTDA",
      "Email": "contacte@contactecontabilidade.com.br",
      "CustomerCode": "65241",
      "Phone": "6133543313",
      "Whatsapp": "61992457495",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3101,
      "Name": "Organização Contabil Astro Reis LTDA",
      "Email": "contabil_astrorei@hotmail.com",
      "CustomerCode": "36136",
      "Phone": "1136868626",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3102,
      "Name": "Hold Desenvolvimento Profissional Ltda",
      "Email": "daniele@holdgp.com.br",
      "CustomerCode": "65238",
      "Phone": "1434417120",
      "Whatsapp": "14997764300",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3103,
      "Name": "Elect Contabilidade",
      "Email": "marciolinsp@gmail.com",
      "CustomerCode": "64BMM6",
      "Phone": "1199767385",
      "Whatsapp": "11997673853",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3104,
      "Name": "C-Level Consultoria Contabil LTDA",
      "Email": "operacaoclevel@gmail.com",
      "CustomerCode": "65252",
      "Phone": "",
      "Whatsapp": "91992740910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3106,
      "Name": "Rosana de Oliveira Costa",
      "Email": "rosana.contabilidade2011@hotmail.com",
      "CustomerCode": "18961",
      "Phone": "1187172600",
      "Whatsapp": "11987172600",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3107,
      "Name": "Anderson Bosso",
      "Email": "jpcontabil@uol.com.br",
      "CustomerCode": "19241",
      "Phone": "1997359578",
      "Whatsapp": "19997359578",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3108,
      "Name": "Rafael Ignacio",
      "Email": "ignaciocontabilidade@gmail.com",
      "CustomerCode": "19237",
      "Phone": "1197286204",
      "Whatsapp": "11972862047",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3109,
      "Name": "Guilherme Ferreira Vernasque",
      "Email": "guilherme@ruralissimo.com.br",
      "CustomerCode": "65239",
      "Phone": "1434226700",
      "Whatsapp": "14998224397",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3110,
      "Name": "Contabilex",
      "Email": "david@contabilexvale.com.br",
      "CustomerCode": "1WF4UX",
      "Phone": "1231991209",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3111,
      "Name": "Vinícius José de Souza Rocha",
      "Email": "viniciusjsrocha@gmail.com",
      "CustomerCode": "37487",
      "Phone": "",
      "Whatsapp": "11973394254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3112,
      "Name": "Daniel Carlos Silva Mendonca",
      "Email": "controlerconsultoria@gmail.com",
      "CustomerCode": "KDEFAR",
      "Phone": "3788122966",
      "Whatsapp": "37988122966",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3113,
      "Name": "Daniel Carlos Silva Mendonca",
      "Email": "controlerconsultoria@gmail.com",
      "CustomerCode": "65243",
      "Phone": "3788122966",
      "Whatsapp": "37988122966",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3114,
      "Name": "Daniel Carlos Silva Mendonca",
      "Email": "controlerconsultoria@gmail.com",
      "CustomerCode": "SW2WOE",
      "Phone": "3788122966",
      "Whatsapp": "37988122966",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3115,
      "Name": "Macariello Sociedade Individual de Advocacia",
      "Email": "tatiana@mteixeiraempresarial.com.br",
      "CustomerCode": "19239",
      "Phone": "1197098205",
      "Whatsapp": "11959975350",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3116,
      "Name": "Paulo Sergio Winck Medeiros",
      "Email": "paulo.escritorio@hotmail.com",
      "CustomerCode": "P8JJ0I",
      "Phone": "5332250807",
      "Whatsapp": "53991010280",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3117,
      "Name": "Esquadra H. Cont Serviços Contabeis LTDA",
      "Email": "administrativo@esquadracontabil.com.br",
      "CustomerCode": "65240",
      "Phone": "1141582273",
      "Whatsapp": "11940117000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3118,
      "Name": "D. A. Maciel Contabilidade",
      "Email": "daniel@aleixocontabilidade.com.br",
      "CustomerCode": "37486",
      "Phone": "1148093057",
      "Whatsapp": "11950890362",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3119,
      "Name": "Dynamique Assessoria Contabil LTDA",
      "Email": "marcelo.victtor@dycont.com.br",
      "CustomerCode": "19245",
      "Phone": "1135653710",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3120,
      "Name": "Júlio Marques da Silva",
      "Email": "juliosilveira13@gmail.com",
      "CustomerCode": "R0LRLQ",
      "Phone": "3199198609",
      "Whatsapp": "31991986098",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3121,
      "Name": "Escritório Contábel Moreira",
      "Email": "geniam@uol.com.br",
      "CustomerCode": "62179",
      "Phone": "1145984022",
      "Whatsapp": "1145984587",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3122,
      "Name": "Neves e Patriota Contabilidade Ltda",
      "Email": "npcontabildf@gmail.com",
      "CustomerCode": "65242",
      "Phone": "6186544089",
      "Whatsapp": "61986544089",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3123,
      "Name": "Edmar Pereira",
      "Email": "edmar.paulistana@yahoo.com.br",
      "CustomerCode": "17744",
      "Phone": "1127491135",
      "Whatsapp": "11983480439",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3124,
      "Name": "Mônica Carneiro ",
      "Email": "advmonicacarneiro@gmail.com",
      "CustomerCode": "H0PYSS",
      "Phone": "6492178857",
      "Whatsapp": "64992178857",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3125,
      "Name": " Loja Reseller",
      "Email": "lojareseller@gmail.com",
      "CustomerCode": "NLULYU",
      "Phone": "3299231871",
      "Whatsapp": "32999231871",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3126,
      "Name": "Luciano Pereira Sobrinho",
      "Email": "lu.33pinho@gmail.com",
      "CustomerCode": "2YZ6T5",
      "Phone": "2730256006",
      "Whatsapp": "27998758831",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3127,
      "Name": "Jose Roberto Moreira",
      "Email": "elba@elbanet.com.br",
      "CustomerCode": "0GJ40D",
      "Phone": "1125340022",
      "Whatsapp": "1125340022",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3128,
      "Name": "Elba Assessoria Empresarial",
      "Email": "elba@elbanet.com.br",
      "CustomerCode": "1QOQ3R",
      "Phone": "1125340022",
      "Whatsapp": "1125340022",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3129,
      "Name": "Sueli Aparecida Collaço Magno",
      "Email": "suelimag@uol.com.br",
      "CustomerCode": "65244",
      "Phone": "1140278600",
      "Whatsapp": "11998408530",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3130,
      "Name": "Ronaldo Pacheco",
      "Email": "ronaldosunhog@yahoo.com.br",
      "CustomerCode": "65250",
      "Phone": "1194011700",
      "Whatsapp": "11953519693",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3131,
      "Name": "Mac Matriz Assessoria Contabil LTDA",
      "Email": "depto.legalizacao@macmatriz.com.br",
      "CustomerCode": "38409",
      "Phone": "1141259577",
      "Whatsapp": "11947004724",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3132,
      "Name": "MAC Matriz Assessoria Contábil LTDA",
      "Email": "depto.legalizacao@macmatriz.com.br",
      "CustomerCode": "VED5RC",
      "Phone": "1141259577",
      "Whatsapp": "11947004724",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3133,
      "Name": "Alessandra Aparecida da Silva",
      "Email": "escritorio.sos.solucoes@gmail.com",
      "CustomerCode": "37488",
      "Phone": "1145526210",
      "Whatsapp": "11985034830",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3134,
      "Name": "Marta Meire",
      "Email": "blanckmarta@gmail.com",
      "CustomerCode": "15281",
      "Phone": "",
      "Whatsapp": "11998323144",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3135,
      "Name": "Josilene Aparecida Simões Caetano",
      "Email": "josisimoes@hotmail.com",
      "CustomerCode": "61427",
      "Phone": "1145971399",
      "Whatsapp": "11971901890",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3136,
      "Name": "Carolina Bonvicini",
      "Email": "bonvicinizavitoski@gmail.com",
      "CustomerCode": "19242",
      "Phone": "",
      "Whatsapp": "11971466530",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3137,
      "Name": "Escritório Contábil Fiscolopes ",
      "Email": "fiscolopes@bol.com.br",
      "CustomerCode": "62043",
      "Phone": "1532551436",
      "Whatsapp": "15997300875",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3138,
      "Name": "Execute Serviços Administrativos LTDA",
      "Email": "silvania2111@hotmail.com",
      "CustomerCode": "SMAAP0",
      "Phone": "1181372154",
      "Whatsapp": "11981372154",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3139,
      "Name": "Luciano Lopes da Rocha",
      "Email": "contato@orgtec.com.br",
      "CustomerCode": "LCMDHY",
      "Phone": "1131841720",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3140,
      "Name": "Vera Lucia Zanutti Gomes  ",
      "Email": "veraagecom@uol.com.br",
      "CustomerCode": "G1FC0S",
      "Phone": "1139610000",
      "Whatsapp": "11981059125",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3141,
      "Name": "Rogério Tomiato",
      "Email": "rncontabilidade1@gmail.com",
      "CustomerCode": "ZWIHY4",
      "Phone": "6392209090",
      "Whatsapp": "63992209090",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3142,
      "Name": "Lourenço e Gonçalves Serviços Contábeis SS LTDA",
      "Email": "contadorcelio@gmail.com",
      "CustomerCode": "YMIXQ1",
      "Phone": "1135783623",
      "Whatsapp": "11997297625",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3143,
      "Name": "Divanir Gonçalves de Souza",
      "Email": "divanirs@gmail.com",
      "CustomerCode": "36153",
      "Phone": "1194163141",
      "Whatsapp": "11941631411",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3144,
      "Name": "Denilton Rodrigues dos Santos",
      "Email": "denilton.rsadvocacia@gmail.com",
      "CustomerCode": "TV0923",
      "Phone": "1143742221",
      "Whatsapp": "11947014383",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3146,
      "Name": "maria aparecida camelo",
      "Email": "contato@macrat.com.br",
      "CustomerCode": "FHYOIO",
      "Phone": "1123080433",
      "Whatsapp": "11982113437",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3147,
      "Name": "George Fernando Rodrigues Vaz",
      "Email": "gfrvazcontador@gmail.com",
      "CustomerCode": "19244",
      "Phone": "",
      "Whatsapp": "11999787210",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3148,
      "Name": "Elza da Silva siqueira",
      "Email": "contabilsiqueira@hormail.com",
      "CustomerCode": "15617",
      "Phone": "1127021851",
      "Whatsapp": "11982813536",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3149,
      "Name": "Edgar José de Angelo",
      "Email": "mundialcontdp1@uol.com.br",
      "CustomerCode": "19246",
      "Phone": "1138325796",
      "Whatsapp": "11997443341",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3150,
      "Name": "Jaqueline Arena da Costa",
      "Email": "jaquelinearena05@gmail.com",
      "CustomerCode": "GKV83B",
      "Phone": "0000000000",
      "Whatsapp": "11949445187",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3152,
      "Name": "Ronaldo Bocucce Schlogl",
      "Email": "ronaldo@escritoriocontasul.com.br",
      "CustomerCode": "65247",
      "Phone": "4532431596",
      "Whatsapp": "45999140008",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3153,
      "Name": "Ambrozio Barreto de Medeiros",
      "Email": "ambroziomedeiros@uol.com.br",
      "CustomerCode": "35326",
      "Phone": "1197533304",
      "Whatsapp": "11975333047",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3154,
      "Name": "JOSÉ OSVALDO RODRIGUES ROMÃO",
      "Email": "zadimcontabil@gmail.com",
      "CustomerCode": "YN374P",
      "Phone": "8999422461",
      "Whatsapp": "89994224612",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3155,
      "Name": "Samanta Sampronha Ferraz",
      "Email": "samantaferraz1510@gmail.com",
      "CustomerCode": "65249",
      "Phone": "",
      "Whatsapp": "11913413646",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3156,
      "Name": "Eliane Gastring",
      "Email": "relacionamento@eligcontabil.com.br",
      "CustomerCode": "95UJXW",
      "Phone": "5192817402",
      "Whatsapp": "51992817402",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3159,
      "Name": "Lisboa Empresa Contábil LTDA",
      "Email": "lisboasc@terra.com.br",
      "CustomerCode": "19249",
      "Phone": "1131012802",
      "Whatsapp": "11973027870",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3160,
      "Name": "Lachi Impacto Social Ltda",
      "Email": "draleticiala@gmail.com",
      "CustomerCode": "19250",
      "Phone": "1123676032",
      "Whatsapp": "11996412424",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3161,
      "Name": "Ronnie Lima da Cruz",
      "Email": "rlc.contabilidade@gmail.com",
      "CustomerCode": "19248",
      "Phone": "1125618005",
      "Whatsapp": "11947559194",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3162,
      "Name": "Daniel",
      "Email": "daniel@mastercontabilidade.cnt.br",
      "CustomerCode": "QTVGTU",
      "Phone": "1136563714",
      "Whatsapp": "11973003642",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3163,
      "Name": "Tecnocamp Service  Ltda ",
      "Email": "tecnocamp@tecnocampcontabilidade.com.br",
      "CustomerCode": "35327",
      "Phone": "1124412847",
      "Whatsapp": "11968311516",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3164,
      "Name": "CESAR ANTONIO CARVALHO DE LIMA",
      "Email": "cesarlcontador@gmail.com",
      "CustomerCode": "23NUI6",
      "Phone": "1198015348",
      "Whatsapp": "11980153484",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3165,
      "Name": "Roberval Nunes da Silva",
      "Email": "robervalauditoria@yahoo.com.br",
      "CustomerCode": "38604",
      "Phone": "",
      "Whatsapp": "11983257143",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3166,
      "Name": "Somed Contabilidade Especializada",
      "Email": "administracao3@somed.com.br",
      "CustomerCode": "0XE9FN",
      "Phone": "1155722911",
      "Whatsapp": "11913303629",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3167,
      "Name": "Pedro ",
      "Email": "pedro.toledo@grupoaudicont.com.br",
      "CustomerCode": "KLRT7Z",
      "Phone": "1197088831",
      "Whatsapp": "11970888313",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3168,
      "Name": "AUDICONT CONTABILIDADE LTDA",
      "Email": "atendimento@grupoaudicont.com.br",
      "CustomerCode": "TY9EO6",
      "Phone": "1120783520",
      "Whatsapp": "1120783520",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3169,
      "Name": "Ilson Roberto da Silva",
      "Email": "fiscal.irs@outlook.com",
      "CustomerCode": "DE0UDU",
      "Phone": "1199866140",
      "Whatsapp": "11998661401",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3170,
      "Name": "Hugo Fernandez Linhares de Oliveira",
      "Email": "hugolinhares91@hotmail.com",
      "CustomerCode": "65251",
      "Phone": "",
      "Whatsapp": "84996539299",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3171,
      "Name": "Reinaldo Fernandes Junior",
      "Email": "fernandesreinaldo@hotmail.com",
      "CustomerCode": "65254",
      "Phone": "1130283277",
      "Whatsapp": "11994379359",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3172,
      "Name": "Afonso Gomes Coelho",
      "Email": "afgcoelho@uol.com.br",
      "CustomerCode": "21VPLL",
      "Phone": "1155603445",
      "Whatsapp": "11999999390",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3173,
      "Name": "Andrea Cristina Lima Coelho",
      "Email": "afonso.agcoelho@gmail.com",
      "CustomerCode": "16532",
      "Phone": "1155603445",
      "Whatsapp": "11999999390",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3175,
      "Name": "Amanda Scarpin",
      "Email": "amanda@galantecontabilidade.com.br",
      "CustomerCode": "62RMK1",
      "Phone": "1196431385",
      "Whatsapp": "11964313859",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3176,
      "Name": "MP & Associados - Prestação de Serviços Contábeis LTDA",
      "Email": "mp.associados@terra.com.br",
      "CustomerCode": "38605",
      "Phone": "1199756771",
      "Whatsapp": "11997567715",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3177,
      "Name": "Rosana Batista dos Santos",
      "Email": "rosanabtista@gmail.com",
      "CustomerCode": "17668",
      "Phone": "",
      "Whatsapp": "11962798363",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3178,
      "Name": "Raphael Pereira de Oliveira",
      "Email": "raphael.contabilista@gmail.com",
      "CustomerCode": "QF43L3",
      "Phone": "1188644130",
      "Whatsapp": "11988644130",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3179,
      "Name": "Andre Seoanes Rampazo",
      "Email": "carcontabil@uol.com.br",
      "CustomerCode": "3XJPFL",
      "Phone": "1172725580",
      "Whatsapp": "11972725580",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3180,
      "Name": "Yuri Silva Siqueira",
      "Email": "yurisiqueiracontador@gmail.com",
      "CustomerCode": "65274",
      "Phone": "",
      "Whatsapp": "27995259067",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3181,
      "Name": "MARCIA APARECIDA GOMES PEREIRA",
      "Email": "marcinhagomes564@gmail.com",
      "CustomerCode": "4TRYZG",
      "Phone": "1180516255",
      "Whatsapp": "11980516255",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3182,
      "Name": "Lucelia Domingues Araujo",
      "Email": "lucelia.domingues@fielcontabil.com.br",
      "CustomerCode": "UD48F8",
      "Phone": "1124639700",
      "Whatsapp": "11996049700",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3183,
      "Name": "EDSON LEITE DA COSTA JUNIOR",
      "Email": "edsonleite10@hotmail.com",
      "CustomerCode": "SQ9K4Z",
      "Phone": "1199255160",
      "Whatsapp": "11992551602",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3184,
      "Name": "sah contabil",
      "Email": "andreia@sahcontabil.com.br",
      "CustomerCode": "9ZTZYI",
      "Phone": "1132746300",
      "Whatsapp": "11976617307",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3185,
      "Name": "Edson Leite da Costa Junior",
      "Email": "edsonleite10@hotmail.com",
      "CustomerCode": "3D9KR9",
      "Phone": "1199255160",
      "Whatsapp": "11992551602",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3186,
      "Name": "Alexander Cassiano",
      "Email": "ascassiano@hotmail.com",
      "CustomerCode": "ODJH1Z",
      "Phone": "9294424965",
      "Whatsapp": "92994424965",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3187,
      "Name": "Rosania Alves Santos LTDA",
      "Email": "rosania33@hotmail.com",
      "CustomerCode": "65253",
      "Phone": "8296666630",
      "Whatsapp": "82996666630",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3188,
      "Name": "Maria Adriana Pereira de Souza",
      "Email": "madrianapereira@hotmail.com",
      "CustomerCode": "PJILT8",
      "Phone": "4130780450",
      "Whatsapp": "41999078704",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3189,
      "Name": "Campesi Contabil",
      "Email": "ale.campesi@gmail.com",
      "CustomerCode": "19263",
      "Phone": "1133264431",
      "Whatsapp": "11994249855",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3190,
      "Name": "Damiana Andrade Lobo",
      "Email": "desrotulandoacontabilidade@gmail.com",
      "CustomerCode": "65255",
      "Phone": "7599164751",
      "Whatsapp": "75991647513",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3191,
      "Name": "paulo leandro dos santos",
      "Email": "lvcontabilidade@gmail.com",
      "CustomerCode": "0RBWW1",
      "Phone": "3598425172",
      "Whatsapp": "35984251725",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3192,
      "Name": "Sérgio Luis dos Santos",
      "Email": "sergioalemdosnumeros@outlook.com",
      "CustomerCode": "EXWTOU",
      "Phone": "9999999999",
      "Whatsapp": "11998540924",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3193,
      "Name": "Tobias Martins de Oliveira",
      "Email": "tobias.martins.oliveira@gmail.com",
      "CustomerCode": "UIKIZL",
      "Phone": "1932583423",
      "Whatsapp": "19991159001",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3194,
      "Name": "José Cezar Doro",
      "Email": "teio_doro@hotmail.com",
      "CustomerCode": "61681",
      "Phone": "1798122463",
      "Whatsapp": "17981224631",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3195,
      "Name": "VIP Contabilidade",
      "Email": "marco@vipcontabilidade.com.br",
      "CustomerCode": "64406",
      "Phone": "1930226150",
      "Whatsapp": "19988917966",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3197,
      "Name": "Daniel Gizolde Correa de Almeida",
      "Email": "danielgizolde@gmail.com",
      "CustomerCode": "19253",
      "Phone": "1120388542",
      "Whatsapp": "11986211237",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3200,
      "Name": "Edsangela Silva Gomes",
      "Email": "edsassessoria202@gmail.com",
      "CustomerCode": "19268",
      "Phone": "1127036622",
      "Whatsapp": "11981901637",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3203,
      "Name": "LUIS CARLOS DOS SANTOS",
      "Email": "tiradentes@contabiltiradentes.com.br",
      "CustomerCode": "TUYMV1",
      "Phone": "1637222244",
      "Whatsapp": "1637222244",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3204,
      "Name": "Controle Agora",
      "Email": "bsantos@controleagora.com",
      "CustomerCode": "19252",
      "Phone": "1152002490",
      "Whatsapp": "11965852598",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3205,
      "Name": "Icaro Vieira",
      "Email": "ic99vieira@gmail.com",
      "CustomerCode": "BC5XRV",
      "Phone": "1139225314",
      "Whatsapp": "11969649845",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3206,
      "Name": "TOMAS CARDOSO MORO",
      "Email": "CONTATO@EXPENSECONTABIL.COM",
      "CustomerCode": "V7RDBP",
      "Phone": "1198179554",
      "Whatsapp": "11981795546",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3207,
      "Name": "LUCCAS FERNANDES",
      "Email": "luccas.msf@gmail.com",
      "CustomerCode": "7JB0XD",
      "Phone": "1130283275",
      "Whatsapp": "11966712591",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3208,
      "Name": "MARIA EUGENIA PEREIRA PENTEADO",
      "Email": "eugeniapenteado@gmail.com",
      "CustomerCode": "QL2Z8Y",
      "Phone": "1125770785",
      "Whatsapp": "11984332581",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3211,
      "Name": "Orbecon Contabilidade",
      "Email": "orbecon@uol.com.br",
      "CustomerCode": "16203",
      "Phone": "1120326351",
      "Whatsapp": "11947329598",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3212,
      "Name": "Elite Assessoria Empresarial LTDA",
      "Email": "juliana.constitucional@eliteassessoriacontabil.com",
      "CustomerCode": "65256",
      "Phone": "6430504940",
      "Whatsapp": "64992082985",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3213,
      "Name": "YWSK SILVA BORGES",
      "Email": "ywskborges@hotmail.com",
      "CustomerCode": "WUEL8J",
      "Phone": "8499997328",
      "Whatsapp": "84999973285",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3214,
      "Name": "ALZIRA DE JESUS FONSECA",
      "Email": "nucleocont@hotmail.com",
      "CustomerCode": "5ESH9P",
      "Phone": "1132941434",
      "Whatsapp": "11944971819",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3215,
      "Name": "GERT HYPPOLITO",
      "Email": "hmativa@terra.com.br",
      "CustomerCode": "9KRXFC",
      "Phone": "1126722729",
      "Whatsapp": "11983162533",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3216,
      "Name": "Integrity Contábil LTDA",
      "Email": "igor@integritycontabil.com.br",
      "CustomerCode": "19254",
      "Phone": "1151966466",
      "Whatsapp": "11966064455",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3217,
      "Name": "Emanuelle Misielle Silva Gomes",
      "Email": "legalizacao@misielle.com.br",
      "CustomerCode": "H4CTLE",
      "Phone": "1120923961",
      "Whatsapp": "11977505575",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3218,
      "Name": "Pruden.lex Contabilidade e Softwares LTDA",
      "Email": "contabil3@prudenlex.com.br",
      "CustomerCode": "65269",
      "Phone": "1832229382",
      "Whatsapp": "18997177246",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3219,
      "Name": "JR&M Assessoria Contabil SS",
      "Email": "societaria@jrem.com.br",
      "CustomerCode": "J4P3WI",
      "Phone": "1122260100",
      "Whatsapp": "11968647128",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3220,
      "Name": "ISP Contabil LTDA",
      "Email": "controladoria@ispconsultoria.com.br",
      "CustomerCode": "65257",
      "Phone": "",
      "Whatsapp": "31975318324",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3221,
      "Name": "Sérgio de Oliveira",
      "Email": "sergio@oliveirafavret.com.br",
      "CustomerCode": "PBR2IO",
      "Phone": "1148014820",
      "Whatsapp": "11981145720",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3222,
      "Name": "Izabela da Silva Ribeiro ",
      "Email": "Izab.ribeiro11@gmail.com",
      "CustomerCode": "F3IKV7",
      "Phone": "1834061319",
      "Whatsapp": "18991116026",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3224,
      "Name": "Vitae Consultoria Contabil ",
      "Email": "luciano.santos@vitaecontabil.com.br",
      "CustomerCode": "18176",
      "Phone": "1134365485",
      "Whatsapp": "11997328869",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3225,
      "Name": "Sérgio",
      "Email": "piramide@sili.com.br",
      "CustomerCode": "BRSIX1",
      "Phone": "1126466732",
      "Whatsapp": "",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3228,
      "Name": "Dayube Contabilidade LTDA",
      "Email": "paulodayubefilho@dayubecontabilidade.com.br",
      "CustomerCode": "65262",
      "Phone": "",
      "Whatsapp": "21994873690",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3229,
      "Name": "Luis Gustavo Lima de Camargo",
      "Email": "gustavocamargo.assessoria@hotmail.com",
      "CustomerCode": "65258",
      "Phone": "1636106096",
      "Whatsapp": "16981170268",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3231,
      "Name": "Diego da Silviera Pavanelli",
      "Email": "diegospavanelli@gmail.com",
      "CustomerCode": "19259",
      "Phone": "1183831094",
      "Whatsapp": "11983831094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3232,
      "Name": "IRINEU SUGIMOTO",
      "Email": "irineu.contaexata@gmail.com",
      "CustomerCode": "B47IN3",
      "Phone": "1158452679",
      "Whatsapp": "11948751483",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3233,
      "Name": "PAULO ROBERTO DE SOUZA SANTOS ",
      "Email": "paulo@ekmservicos.com.br",
      "CustomerCode": "0XFFNN",
      "Phone": "1138510001",
      "Whatsapp": "11976237024",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3236,
      "Name": "ASSOCIACAO COMERCIAL DE SAO PAULO",
      "Email": "dpassos@acsp.com.br",
      "CustomerCode": "QXR4QM",
      "Phone": "1131803610",
      "Whatsapp": "11941510976",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3237,
      "Name": "Trady Serviços Contabeis LTDA",
      "Email": "legalizacoes@trady.com.br",
      "CustomerCode": "17568",
      "Phone": "1137299009",
      "Whatsapp": "11983546380",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3238,
      "Name": "Anderson Cerqueira Filgueiras",
      "Email": "andersonfilgueiras@hotmail.com",
      "CustomerCode": "19260",
      "Phone": "1159616487",
      "Whatsapp": "11959616487",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3239,
      "Name": "Triangulo Assessoria Contabil ",
      "Email": "debora@trianguloportalcontabil.com.br",
      "CustomerCode": "19261",
      "Phone": "1122766348",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3241,
      "Name": "LKD Contabilidade LTDA",
      "Email": "luandias@lkdcontabilidade.com.br",
      "CustomerCode": "65259",
      "Phone": "8591698255",
      "Whatsapp": "85991698255",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3242,
      "Name": "Thais Amaral",
      "Email": "thamaralcontabil@hotmail.com",
      "CustomerCode": "19266",
      "Phone": "1120957763",
      "Whatsapp": "11943892854",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3243,
      "Name": "AUDIT CONTABILIDADE E AUDITORIA LTDA",
      "Email": "savio@audit.cnt.br",
      "CustomerCode": "TV74TD",
      "Phone": "8499126436",
      "Whatsapp": "84987002190",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3244,
      "Name": "Luiz Augusto Eugenio",
      "Email": "laeassessoria@outlook.com",
      "CustomerCode": "40190",
      "Phone": "",
      "Whatsapp": "11974053254",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3245,
      "Name": "Contabexpress Contabilidade Ltda",
      "Email": "relacionamento@grupocontab.com.br",
      "CustomerCode": "19262",
      "Phone": "1299243917",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3246,
      "Name": "H K CHUN CONTABILIDADE",
      "Email": "valmirmodesto@uol.com.br",
      "CustomerCode": "HY0J1F",
      "Phone": "1133268819",
      "Whatsapp": "11992294867",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3247,
      "Name": "Edson Jose de Oliveira",
      "Email": "edoliver1965@gmail.com",
      "CustomerCode": "18430",
      "Phone": "",
      "Whatsapp": "11983264426",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3248,
      "Name": "Nicons Asses Contabil e Fiscal SS LTDA",
      "Email": "otacilio@niconscontabil.com",
      "CustomerCode": "16572",
      "Phone": "1137853388",
      "Whatsapp": "11985782178",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3249,
      "Name": "Romulo Olivar Casimiro",
      "Email": "rlolivar@yahoo.com.br",
      "CustomerCode": "GCHLSQ",
      "Phone": "1125350426",
      "Whatsapp": "11987043033",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3250,
      "Name": "Tiago Emilio Arduim",
      "Email": "tarduim.contador@gmail.com",
      "CustomerCode": "G71KS7",
      "Phone": "1699736999",
      "Whatsapp": "16996072236",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3251,
      "Name": "Larissa",
      "Email": "larissa@pollaio.com.br",
      "CustomerCode": "0U5R5U",
      "Phone": "1195278060",
      "Whatsapp": "11952780600",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3252,
      "Name": "Daniela Ap Vieira da Silva",
      "Email": "daniela@cananeiacontabilidade.com.br",
      "CustomerCode": "I0HI1L",
      "Phone": "1197675764",
      "Whatsapp": "11993740575",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3253,
      "Name": "RITA DE CÁSSIA",
      "Email": "gonper_rita@hotmail.com",
      "CustomerCode": "J4AKZL",
      "Phone": "1197992087",
      "Whatsapp": "11979920878",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3254,
      "Name": "Konzac Contabilidade Ltda",
      "Email": "contato@konzac.com.br",
      "CustomerCode": "CJ1J0U",
      "Phone": "1194958624",
      "Whatsapp": "11949586246",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3255,
      "Name": "Marcílio Costa Nascimento",
      "Email": "marcilio@consolidos.com.br",
      "CustomerCode": "19264",
      "Phone": "",
      "Whatsapp": "11981763748",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3256,
      "Name": "Carlos Cesar Angeli ",
      "Email": "ccangeli2013@gmail.com ",
      "CustomerCode": "PF7HZE",
      "Phone": "1999746244",
      "Whatsapp": "19997462444",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3258,
      "Name": "Vera Lucia de Andrade",
      "Email": "vera2612@uol.com.br",
      "CustomerCode": "QAYS9N",
      "Phone": "1138367560",
      "Whatsapp": "11984568021",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3259,
      "Name": "Elisangela Maria Baia",
      "Email": "elisangela@elmarcontabil.com.br",
      "CustomerCode": "38606",
      "Phone": "1149963430",
      "Whatsapp": "11984548324",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3260,
      "Name": "Llibra Contabilidade & Assessoria Empresarial LTDA",
      "Email": "libracontabilidade@uol.com.br",
      "CustomerCode": "64558",
      "Phone": "1733222041",
      "Whatsapp": "17992069075",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3261,
      "Name": "RAFAEL HIDEMITSU THOMAZ",
      "Email": "rafael@thomazosako.com.br",
      "CustomerCode": "11IJGG",
      "Phone": "1135224345",
      "Whatsapp": "11983776427",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3262,
      "Name": "Hazul Controller e Finanças LDTA",
      "Email": "diretoria@hazulcontabilidade.com.br",
      "CustomerCode": "19265",
      "Phone": "",
      "Whatsapp": "11995153819",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3263,
      "Name": "Diretiva Contabilidade SS LTDA",
      "Email": "contato@diretiva.net.br",
      "CustomerCode": "19270",
      "Phone": "1129818064",
      "Whatsapp": "11997335725",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3264,
      "Name": "Central Paulista Contabilidade Empresarial",
      "Email": "eva@central-paulista.com",
      "CustomerCode": "WP2Z5I",
      "Phone": "1133131465",
      "Whatsapp": "11999428084",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3265,
      "Name": "INAIA  PAULO",
      "Email": "inaia.paulo@finemp.com.br",
      "CustomerCode": "6087ZM",
      "Phone": "1297493658",
      "Whatsapp": "12991293658",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3266,
      "Name": "Casa Amarela - Residencial Terapêutico Dependência Química e Transtornos Mentais",
      "Email": "helena_polito@hotmail.com",
      "CustomerCode": "4HFT4Z",
      "Phone": "1399646547",
      "Whatsapp": "13996465472",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3267,
      "Name": "GILBERTO ALVES COSTA",
      "Email": "gilbertoalvescosta68@gmail.com",
      "CustomerCode": "A0BCQA",
      "Phone": "8399610679",
      "Whatsapp": "83996106795",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3268,
      "Name": "SALVADOR SOARES DE BRITO",
      "Email": "salvador.soaresbrito@gmail.com",
      "CustomerCode": "B84IV0",
      "Phone": "1198876287",
      "Whatsapp": "11988762872",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3269,
      "Name": "Tegens LTDA",
      "Email": "contato@tegens.com",
      "CustomerCode": "65263",
      "Phone": "",
      "Whatsapp": "48996837000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3270,
      "Name": "Sonia Regina Dias",
      "Email": "hsgestaocont@gmail.com",
      "CustomerCode": "19269",
      "Phone": "1125483191",
      "Whatsapp": "11984195646",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3271,
      "Name": "Luana Moreira Gomes",
      "Email": "luanamoreiracont@gmail.com",
      "CustomerCode": "P1S47H",
      "Phone": "2196825928",
      "Whatsapp": "21968259283",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3272,
      "Name": "Conceição Vercesi",
      "Email": "cadvcontabil@gmail.com",
      "CustomerCode": "TOHKIW",
      "Phone": "1434401825",
      "Whatsapp": "14996012772",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3273,
      "Name": "CONTABILIDADE MOLINA LTDA",
      "Email": "marcos@contabilmolina.com.br",
      "CustomerCode": "JH2D61",
      "Phone": "1120101030",
      "Whatsapp": "11996167936",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3274,
      "Name": "Cesar Francisco",
      "Email": "cesar@escritorionovosistema.com.br",
      "CustomerCode": "64673",
      "Phone": "1432322024",
      "Whatsapp": "14997506409",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3275,
      "Name": "Simone Bueno das Chagas",
      "Email": "sifaria12@hotmail.com",
      "CustomerCode": "17916",
      "Phone": "1123847671",
      "Whatsapp": "11979590330",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3276,
      "Name": "ERM Organização Contabil LTDA",
      "Email": "ronnymelo29@gmail.com",
      "CustomerCode": "65265",
      "Phone": "4130140432",
      "Whatsapp": "41992394809",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3277,
      "Name": "João Aparecido Martins ",
      "Email": "m.joaoaparecido@gmail.com",
      "CustomerCode": "19267",
      "Phone": "1169432619",
      "Whatsapp": "11984899020",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3278,
      "Name": "Pladicon Assessoria Contabil ",
      "Email": "fernandooliveira@terra.com.br",
      "CustomerCode": "F6UDJ7",
      "Phone": "1121165523",
      "Whatsapp": "11984800447",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3279,
      "Name": "Kelvia Franklim Tavares de Souza",
      "Email": "kelvialeiteb02@gmail.com",
      "CustomerCode": "65264",
      "Phone": "6130373786",
      "Whatsapp": "61985582199",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3280,
      "Name": "Pércio Pires Portella",
      "Email": "percio.organizare@gmail.com",
      "CustomerCode": "65271",
      "Phone": "5133724624",
      "Whatsapp": "51991027801",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3281,
      "Name": "JOAO RAIMUNDO DOS SANTOS NETO",
      "Email": "lcmcontabill@gmail.com",
      "CustomerCode": "7NZCSH",
      "Phone": "1195101680",
      "Whatsapp": "11951016807",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3282,
      "Name": "Antonio Sergio Caitano",
      "Email": "sergiocaitano@hotmail.com",
      "CustomerCode": "65266",
      "Phone": "1633427226",
      "Whatsapp": "16997016910",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3283,
      "Name": "Louriva Francisca Bezerra",
      "Email": "lfb.8dezembro@outlook.com",
      "CustomerCode": "0LVKRP",
      "Phone": "1147073233",
      "Whatsapp": "11976613332",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3284,
      "Name": "FORMALIZAAI APOIO ADMINISTRATIVO LTDA",
      "Email": "comercial@formalizaai.com",
      "CustomerCode": "ZUNPLY",
      "Phone": "1199385224",
      "Whatsapp": "11998352244",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3285,
      "Name": "Marcia Ferreira Ribeiro Leite",
      "Email": "marciafrleite@gmail.com",
      "CustomerCode": "19272",
      "Phone": "1138516385",
      "Whatsapp": "11974239939",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3287,
      "Name": "Creuza Souza Novais Kahale",
      "Email": "knscontato@outlook.com",
      "CustomerCode": "19271",
      "Phone": "1165990072",
      "Whatsapp": "11965990072",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3288,
      "Name": "A A DE Araujo Barlli",
      "Email": "contato@dbcontabilidade.org",
      "CustomerCode": "LJUKXN",
      "Phone": "1933090524",
      "Whatsapp": "1933090524",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3289,
      "Name": "JOSE CARLOS FERREIRA DA SILVA",
      "Email": "jcfsilva554@gmail.com",
      "CustomerCode": "7AN6O7",
      "Phone": "1198867125",
      "Whatsapp": "11988671251",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3290,
      "Name": "MF CONTADORES E CONSULTORES ASSOCIADOS LTDA",
      "Email": "luiz.macena@mfcontadores.com.br",
      "CustomerCode": "6ST95S",
      "Phone": "1129169033",
      "Whatsapp": "11996231917",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3291,
      "Name": "PÂMELA",
      "Email": "pamela@prestcont.com.br",
      "CustomerCode": "S2BNFP",
      "Phone": "1126855321",
      "Whatsapp": "11982129331",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3292,
      "Name": "Salvador das Neves Silva",
      "Email": "ativass1@hotmail.com",
      "CustomerCode": "18042",
      "Phone": "1125217665",
      "Whatsapp": "11979512528",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3293,
      "Name": "Amado & Ishizawa Contabilidade LTDA",
      "Email": "joseluiz@assesconsc.com.br",
      "CustomerCode": "65267",
      "Phone": "1422272025",
      "Whatsapp": "14996879316",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3295,
      "Name": "Ivanilda da Silva",
      "Email": "ivanilda@zadoquecontabilidade.com.br",
      "CustomerCode": "17862",
      "Phone": "11940127740",
      "Whatsapp": "11940127740",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3296,
      "Name": "Francisco Rodrigues de Lima",
      "Email": "lima.contabil@outlook.com",
      "CustomerCode": "WBTVD7",
      "Phone": "1126143569",
      "Whatsapp": "11939053461",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3297,
      "Name": "Rafael Dias",
      "Email": "rafael.dias@eluslicenciamento.com.br",
      "CustomerCode": "19274",
      "Phone": "1142253789",
      "Whatsapp": "11980856088",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3298,
      "Name": "OCJ Assessoria Contábil e Empresárial Ltda",
      "Email": "ocjordan@uol.com.br",
      "CustomerCode": "40191",
      "Phone": "1144475130",
      "Whatsapp": "11941391315",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3299,
      "Name": "Roana Daphne Xavier de Carvalho ",
      "Email": "roanadaphne@gmail.com",
      "CustomerCode": "WE6M0U",
      "Phone": "3194500288",
      "Whatsapp": "31994500288",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3300,
      "Name": "Jaime Alvino Starke ME",
      "Email": "icappbr@gmail.com",
      "CustomerCode": "B80EE2",
      "Phone": "5398116987",
      "Whatsapp": "53981169878",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3301,
      "Name": "FIN FIX CONTABIL LTDA",
      "Email": "victor.zonato@finfix.com.br",
      "CustomerCode": "1UMVGD",
      "Phone": "4130993800",
      "Whatsapp": "41987009864",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3302,
      "Name": "IS ASSESSORIA CONTABIL E EMPRESARIAL LTDA",
      "Email": "isaac.j@uol.com.br",
      "CustomerCode": "6Q7JN1",
      "Phone": "1197760185",
      "Whatsapp": "11977601857",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3303,
      "Name": "Kesef Finanças e Contabilidade Estratégica LTDA",
      "Email": "financeiro1@kesefcontabil.com.br",
      "CustomerCode": "19273",
      "Phone": "1194441235",
      "Whatsapp": "11944412358",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3304,
      "Name": "Elisete Nazaré de Carvalho ",
      "Email": "ecarvalho.cont@gmail.com",
      "CustomerCode": "TF315N",
      "Phone": "1231324805",
      "Whatsapp": "12992048823",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3305,
      "Name": "Marcelo Paiva",
      "Email": "visaomarcelo@terra.com.br",
      "CustomerCode": "HNU5ZS",
      "Phone": "1199103081",
      "Whatsapp": "11991030812",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3306,
      "Name": "Vladimir Eustáquio Monteiro",
      "Email": "vladimir.monteiro@outlook.com",
      "CustomerCode": "35117",
      "Phone": "1175469552",
      "Whatsapp": "11975469552",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3309,
      "Name": "Etos Comércio e Locação de Veículos LTDA",
      "Email": "claudioneri@yahoo.com.br",
      "CustomerCode": "38607",
      "Phone": "1197111444",
      "Whatsapp": "11971114442",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3310,
      "Name": "Leandro de Jesus Ribeiro",
      "Email": "leandro@rockfile.com.br",
      "CustomerCode": "GS38OB",
      "Phone": "1233222659",
      "Whatsapp": "12981858374",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3311,
      "Name": "Joao Roberto Bolzon",
      "Email": "joaorbolzon@gmail.com",
      "CustomerCode": "65268",
      "Phone": "4399127862",
      "Whatsapp": "43999127862",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3312,
      "Name": "Ivan Florido",
      "Email": "floridocontabilidade@yahoo.com.br",
      "CustomerCode": "63069",
      "Phone": "1338217173",
      "Whatsapp": "13997408912",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3313,
      "Name": "José Adriano dos Santos",
      "Email": "adriano@contabilagil.com.br",
      "CustomerCode": "37491",
      "Phone": "",
      "Whatsapp": "11984094045",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3314,
      "Name": "ESCRITORIO CONTABIL VANSAN LTDA",
      "Email": "idemario@vansancontabil.com.br",
      "CustomerCode": "AGFT14",
      "Phone": "1122793075",
      "Whatsapp": "11983159374",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3315,
      "Name": "Ana Flávia Vieira",
      "Email": "ana.flavia86@gmail.com",
      "CustomerCode": "19275",
      "Phone": "",
      "Whatsapp": "21991171096",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3317,
      "Name": "Finemp Parceria Empresarial LTDA",
      "Email": "fiscal@finemp.com.br",
      "CustomerCode": "19276",
      "Phone": "1150266712",
      "Whatsapp": "",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3318,
      "Name": "Mila Contabilidade",
      "Email": "araujoepovoa@gmail.com",
      "CustomerCode": "JBUA7R",
      "Phone": "1139968273",
      "Whatsapp": "11987544721",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3320,
      "Name": "Únita Contabilidade Ltda",
      "Email": "atendimento@unitaonline.com.br",
      "CustomerCode": "65287",
      "Phone": "1931995225",
      "Whatsapp": "19993124094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3322,
      "Name": "Ivone Look",
      "Email": "ive.look@uol.com.br",
      "CustomerCode": "3ZHP2B",
      "Phone": "1158311956",
      "Whatsapp": "11944520797",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3323,
      "Name": "Caroline Martinelli Santos Ribeiro",
      "Email": "cmsantos172@hotmail.com",
      "CustomerCode": "19278",
      "Phone": "1189829788",
      "Whatsapp": "11989829788",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3324,
      "Name": "Fabiola de Fatima Barbosa Govertz",
      "Email": "fabiolagovertz@gmail.com",
      "CustomerCode": "7YU0PT",
      "Phone": "1124522763",
      "Whatsapp": "11971116098",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3325,
      "Name": "Solution Outsourcing Contabil e Empresarial Ltda",
      "Email": "luciana@solutioncontabilidade.com.br",
      "CustomerCode": "19279",
      "Phone": "1127876204",
      "Whatsapp": "11962151519",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3326,
      "Name": "Costa Assessoria Contabil LTDA",
      "Email": "financeiro@costacontabilidademt.com.br",
      "CustomerCode": "QIJWWB",
      "Phone": "6599759689",
      "Whatsapp": "65999759689",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3327,
      "Name": "Exatus Serviços Contábeis Ltda",
      "Email": "cadastro@exatusassessoria.com.br",
      "CustomerCode": "65270",
      "Phone": "5135612466",
      "Whatsapp": "51997601991",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3328,
      "Name": "ELSON DE ASSIS MORAES",
      "Email": "elson.assis.moraes@gmail.com",
      "CustomerCode": "PZ3S7X",
      "Phone": "1139416134",
      "Whatsapp": "11993551777",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3329,
      "Name": "Gilson santos de gois",
      "Email": "gilena.gois@uol.com.br",
      "CustomerCode": "AG923E",
      "Phone": "1199451141",
      "Whatsapp": "11994511414",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3330,
      "Name": "Gilson santos de gois",
      "Email": "gilena.gois@uol.com.br",
      "CustomerCode": "ANZ51K",
      "Phone": "1199451141",
      "Whatsapp": "11994511414",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3331,
      "Name": "DENISON VIEIRA DE OLIVEIRA",
      "Email": "legal@denisons.com.br",
      "CustomerCode": "63TFSX",
      "Phone": "1138588306",
      "Whatsapp": "11971158962",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3332,
      "Name": "Carlos Leon Junior",
      "Email": "leonregularize@gmail.com",
      "CustomerCode": "19324",
      "Phone": "",
      "Whatsapp": "12996857594",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3333,
      "Name": "Lourival Pinto Junior",
      "Email": "contabilidade@la.cnt.br",
      "CustomerCode": "19280",
      "Phone": "1120191920",
      "Whatsapp": "1120191920",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3334,
      "Name": "JOAO BENICIO SILVA GOMES",
      "Email": "joaobeniciogomes@gmail.com",
      "CustomerCode": "6XBKK5",
      "Phone": "1198122832",
      "Whatsapp": "11981228326",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3335,
      "Name": "Fabio Vale",
      "Email": "fabiovaleadv@hotmail.com",
      "CustomerCode": "JT2VAA",
      "Phone": "1122721224",
      "Whatsapp": "11949938186",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3336,
      "Name": "Osny Gussoni Junior",
      "Email": "osny.gussoni@praxia.com.br",
      "CustomerCode": "19281",
      "Phone": "1126267983",
      "Whatsapp": "11971521901",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3337,
      "Name": "Christoffer Ricken",
      "Email": "ricken@grupopotencializa.com.br",
      "CustomerCode": "3BLFT6",
      "Phone": "4491527670",
      "Whatsapp": "44991527670",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3338,
      "Name": "FRANCO CONTABILIDADE LTDA",
      "Email": "recepcao@francontabil.com.br",
      "CustomerCode": "KVS6JB",
      "Phone": "1133110888",
      "Whatsapp": "11991096653",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3339,
      "Name": "CTB Rema Assessoria Contábil Ltda",
      "Email": "mauricio.freitas@ctbrema.com.br",
      "CustomerCode": "19282",
      "Phone": "1139717492",
      "Whatsapp": "11996296168",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3340,
      "Name": "SEGURA CNTABILIDADE ON LINE LTDA EPP",
      "Email": "legalizacao@seguracontabilidade.com",
      "CustomerCode": "22AJLE",
      "Phone": "1142083388",
      "Whatsapp": "11947894289",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3341,
      "Name": "MIRIAM DE LUCCA",
      "Email": "miriamdelucca@uol.com.br",
      "CustomerCode": "ER1SBQ",
      "Phone": "1197549300",
      "Whatsapp": "11975493008",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3342,
      "Name": "Streiff Brians",
      "Email": "s.brianscontabilidade@gmail.com",
      "CustomerCode": "7IFDVJ",
      "Phone": "1198211977",
      "Whatsapp": "11982119771",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3343,
      "Name": "Vitor Ribeiro Araujo",
      "Email": "vitor-araujo00@hotmail.com",
      "CustomerCode": "37489",
      "Phone": "1112518369",
      "Whatsapp": "11912518369",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3344,
      "Name": "Alexandre Cartolari",
      "Email": "alexandre.cartolari@adv.oabsp.org.br",
      "CustomerCode": "FZ9C4F",
      "Phone": "1198380224",
      "Whatsapp": "11983802240",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3345,
      "Name": "Vergilio Rodrigues Alves",
      "Email": "vergiliocontabil@gmail.com",
      "CustomerCode": "36323",
      "Phone": "1136026900",
      "Whatsapp": "11981290430",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3346,
      "Name": "JR Soluções Contabeis e Administrativas",
      "Email": "jessica.jrcontabilidade@outlook.com",
      "CustomerCode": "19310",
      "Phone": "",
      "Whatsapp": "11963295307",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3347,
      "Name": "Diogo Erlo Alves",
      "Email": "diogo@sccac.com.br",
      "CustomerCode": "38608",
      "Phone": "1142277076",
      "Whatsapp": "11974417215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3348,
      "Name": "Susana Silva de Oliveira",
      "Email": "susana@consultoriaclassea.com.br",
      "CustomerCode": "37490",
      "Phone": "",
      "Whatsapp": "11956524384",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3349,
      "Name": "Pracont Contabilidade e Gestão LTDA",
      "Email": "valdenir@pracont.com.br",
      "CustomerCode": "65272",
      "Phone": "4130102001",
      "Whatsapp": "41999141345",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3350,
      "Name": "TIAGO DE VASCONCELOS",
      "Email": "tiagodevasconcelos@yahoo.com.br",
      "CustomerCode": "Y9O466",
      "Phone": "1196950624",
      "Whatsapp": "11969506249",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3351,
      "Name": "Libserv Serviços Contábeis LTDA",
      "Email": "leandro.casusa@terra.com.br",
      "CustomerCode": "O3GUPO",
      "Phone": "1136081000",
      "Whatsapp": "11975194375",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3352,
      "Name": "Gesta Contabilidade e Serviços Administrativos LTDA",
      "Email": "tatiane.carvalho@gestasp.com",
      "CustomerCode": "40192",
      "Phone": "",
      "Whatsapp": "11953901644",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3353,
      "Name": "Mauricio",
      "Email": "mauricioprettinho@gmail.com",
      "CustomerCode": "NLNVF3",
      "Phone": "1199801872",
      "Whatsapp": "11980187241",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3354,
      "Name": "A B Guedes Assessoria Contabil Ltda",
      "Email": "adriano@aguedes.com.br",
      "CustomerCode": "YRA1ED",
      "Phone": "1193908368",
      "Whatsapp": "11993908368",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3355,
      "Name": "Lidercont Contabilidade LTDA",
      "Email": "contato@lidercnt.com.br",
      "CustomerCode": "38252",
      "Phone": "1141233788",
      "Whatsapp": "11947755859",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3356,
      "Name": "Claudett Ribeira da Luz",
      "Email": "claudettrluz@hotmail.com",
      "CustomerCode": "35328",
      "Phone": "1124083334",
      "Whatsapp": "11941400445",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3357,
      "Name": "Myrian Mariana Pinheiro ",
      "Email": "myrianps@gmail.com",
      "CustomerCode": "8DRBUW",
      "Phone": "6892054788",
      "Whatsapp": "6892054788",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3359,
      "Name": "Cirne & Souza Contabilidade Ltda",
      "Email": "contabilidade@cirneesouza.com.br",
      "CustomerCode": "19306",
      "Phone": "1117559990",
      "Whatsapp": "11917559990",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3360,
      "Name": "Thiago Nascimento",
      "Email": "thiago.alvesdonascimento@hotmail.com",
      "CustomerCode": "19286",
      "Phone": "",
      "Whatsapp": "11982610213",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3361,
      "Name": "Daniele da Silva Circuncisão ",
      "Email": "danielesilva252@gmail.com",
      "CustomerCode": "A8TRS0",
      "Phone": "1183094661",
      "Whatsapp": "11983094661",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3362,
      "Name": "Aguinaldo Cesar Calori",
      "Email": "aguinaldo@acccontabilidade.com.br",
      "CustomerCode": "80JG4U",
      "Phone": "1735434453",
      "Whatsapp": "17981166575",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3363,
      "Name": "Lucas Ávila ",
      "Email": "lucasbarrosavila@gmail.com",
      "CustomerCode": "LTXX46",
      "Phone": "2167825570",
      "Whatsapp": "21967825570",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3364,
      "Name": "Susana Maria Santos Neto",
      "Email": "susananeto@hotmail.com",
      "CustomerCode": "19285",
      "Phone": "",
      "Whatsapp": "11995464413",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3365,
      "Name": "NTW Contabilidade  e Gestão Empresarial  - Dom Cavati",
      "Email": "luiz.carlos@ntwcontabilidade.com.br",
      "CustomerCode": "65273",
      "Phone": "3333571102",
      "Whatsapp": "33988441102",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3367,
      "Name": "Mariana de Lara França",
      "Email": "nery.estagio@gmail.com",
      "CustomerCode": "HBDGSK",
      "Phone": "4199539647",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3370,
      "Name": "PROBITATIS CONTABILIDADE E ASSESSORIA EMPRESARIAL LTDA",
      "Email": "cassia@probitatiscontabil.com.br",
      "CustomerCode": "NRX0IK",
      "Phone": "1123623176",
      "Whatsapp": "11952510678",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3371,
      "Name": "CILASI ALIMENTOS S.A ",
      "Email": "juridico@belavista.com.br",
      "CustomerCode": "8TVT1A",
      "Phone": "1133223048",
      "Whatsapp": "11932121999",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3372,
      "Name": "Rosa Maria",
      "Email": "rosafortunaoliveira@gmail.com",
      "CustomerCode": "62283",
      "Phone": "1334276229",
      "Whatsapp": "13981642823",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3373,
      "Name": "Soluzione",
      "Email": "atendimento@soluzionecontabil.com.br",
      "CustomerCode": "18203",
      "Phone": "",
      "Whatsapp": "11984885008",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3374,
      "Name": "Asstil Serviços Contábeis e Empresariais Ltda",
      "Email": "alessandra@asstil.com.br",
      "CustomerCode": "19287",
      "Phone": "1122332614",
      "Whatsapp": "11964220029",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3375,
      "Name": "MARIA IMACULADA BICEGO SILVA",
      "Email": "imaculadabicego@gmail.com",
      "CustomerCode": "VJXOCB",
      "Phone": "3588822574",
      "Whatsapp": "35988822574",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3377,
      "Name": "Dotcontrol Processamento de Dados LTDA ME",
      "Email": "renan@dotcontrol.com.br",
      "CustomerCode": "D7YZWB",
      "Phone": "1144380102",
      "Whatsapp": "11963874518",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3378,
      "Name": "Costa Bastos Contabilidade LTDA",
      "Email": "mcosta@costabastosassessoria.com.br",
      "CustomerCode": "65289",
      "Phone": "",
      "Whatsapp": "21964737200",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3379,
      "Name": "Sergio krauss",
      "Email": "universalsbc9@gmail.com",
      "CustomerCode": "WB2DX4",
      "Phone": "1199932191",
      "Whatsapp": "11999321912",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3380,
      "Name": "Hebert José Silva ",
      "Email": "hebertj04@gmail.com",
      "CustomerCode": "W0UBDP",
      "Phone": "3535312574",
      "Whatsapp": "35997572574",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3382,
      "Name": "Kassia Napoles",
      "Email": "kassia_napoles@hotmail.com",
      "CustomerCode": "19328",
      "Phone": "9299325156",
      "Whatsapp": "92993251567",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3385,
      "Name": "Hemera Serviços Contábeis Ltda",
      "Email": "caio@hemeracontabil.com.br",
      "CustomerCode": "38528",
      "Phone": "1141272711",
      "Whatsapp": "11999403046",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3387,
      "Name": "MARCIA ELISA ADONA NASCIMENTO",
      "Email": "marcia@mancontabilidade.net.br",
      "CustomerCode": "4SC0R8",
      "Phone": "5130851827",
      "Whatsapp": "51996646866",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3388,
      "Name": "EVR Auditores Independentes Ltda",
      "Email": "junior@evrauditores.com.br",
      "CustomerCode": "19288",
      "Phone": "1129342324",
      "Whatsapp": "11940144666",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3389,
      "Name": "Jose Henrique Maciel Reis",
      "Email": "josehenrique@maconbh.com.br",
      "CustomerCode": "65275",
      "Phone": "3134177572",
      "Whatsapp": "31993791600",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3390,
      "Name": "Alexandre Gomes Ferreira",
      "Email": "alexandre.gomes.irpf@gmail.com",
      "CustomerCode": "37492",
      "Phone": "",
      "Whatsapp": "11984130639",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3391,
      "Name": "Marco Aurélio Franceschini",
      "Email": "marco.assetec@asseteccontabil.com.br",
      "CustomerCode": "NNRD77",
      "Phone": "1144338322",
      "Whatsapp": "11998945117",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3392,
      "Name": "ANDREIA",
      "Email": "andreiarfonseca06@gmail.com",
      "CustomerCode": "4V7ZJJ",
      "Phone": "1148581016",
      "Whatsapp": "11960678030",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3393,
      "Name": "Marcia Abrantes Torelli",
      "Email": "marcia_torelli@hotmail.com",
      "CustomerCode": "19289",
      "Phone": "",
      "Whatsapp": "11978356962",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3394,
      "Name": "Giovanna Valentim Cozza",
      "Email": "jpaulofaria@icloud.com",
      "CustomerCode": "19290",
      "Phone": "",
      "Whatsapp": "11953144501",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3395,
      "Name": "ALEXSANDER SOUZA CARODOS",
      "Email": "fabio.silva@oscpaulistana.com.br",
      "CustomerCode": "19X293",
      "Phone": "1150702700",
      "Whatsapp": "11914023338",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3397,
      "Name": "Itucontabil Associados",
      "Email": "silvia@itucontabil.com.br",
      "CustomerCode": "64198",
      "Phone": "1140134002",
      "Whatsapp": "11945792553",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3399,
      "Name": "LEMES ASSESSORIA EMPRESARIAL LTDA",
      "Email": "legalizacao@lemesassessoria.com.br",
      "CustomerCode": "X699NC",
      "Phone": "1148253879",
      "Whatsapp": "11973270988",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3400,
      "Name": "Spindola Gestão Empresarial LTDA",
      "Email": "spindola.gerson@gmail.com",
      "CustomerCode": "65276",
      "Phone": "6134474472",
      "Whatsapp": "61982400013",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3401,
      "Name": "Para Você Contar Consultoria Empresarial LTDA",
      "Email": "legalize@atlantico.digital",
      "CustomerCode": "19292",
      "Phone": "1130030506",
      "Whatsapp": "11995301934",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3402,
      "Name": "Sonia Lopes Costa",
      "Email": "soniaccosta14@gmail.com",
      "CustomerCode": "19293",
      "Phone": "",
      "Whatsapp": "11962957167",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3403,
      "Name": "Fernanda Hirata",
      "Email": "fernandahirata.adv@hotmail.com",
      "CustomerCode": "RZ20UM",
      "Phone": "8599196966",
      "Whatsapp": "85991969665",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3405,
      "Name": "RM DE OLIVEIRA CONTABILIDADE",
      "Email": "retivi834@gmail.com",
      "CustomerCode": "CQ62YG",
      "Phone": "1120687666",
      "Whatsapp": "11987156658",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3406,
      "Name": "Primeiro Plano Escritório de Contabilidade",
      "Email": "julio@primeiroplanocontabilidade.com.br",
      "CustomerCode": "65279",
      "Phone": "1145219585",
      "Whatsapp": "11989440146",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3407,
      "Name": "Jose Arnaldo da Silva",
      "Email": "jota.as@uol.com.br",
      "CustomerCode": "19294",
      "Phone": "1126190521",
      "Whatsapp": "11976560806",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3408,
      "Name": "Joseane Martins Cardoso Duarte",
      "Email": "joseane@illuminaregestaocontabil.com.br",
      "CustomerCode": "65278",
      "Phone": "",
      "Whatsapp": "67999628669",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3409,
      "Name": "Cleiton Jones de Godoi",
      "Email": "contabil.godoi22@gmail.com",
      "CustomerCode": "19295",
      "Phone": "",
      "Whatsapp": "11976256363",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3410,
      "Name": "Ricardo Alexandre Hidalgo da Silva",
      "Email": "ricardoal.h.s@gmail.com",
      "CustomerCode": "84ZZYK",
      "Phone": "1198979897",
      "Whatsapp": "11958764716",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3411,
      "Name": "Marcio Mielke",
      "Email": "gabrieloliveira.avive@gmail.com",
      "CustomerCode": "0X7HOT",
      "Phone": "4333370426",
      "Whatsapp": "4333370426",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3412,
      "Name": "Julio Salgado",
      "Email": "julio@razaorazec.com.br",
      "CustomerCode": "15850",
      "Phone": "1129898803",
      "Whatsapp": "11993133249",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3413,
      "Name": "CELSO RIBEIRO",
      "Email": "climaxsbc@uol.com.br",
      "CustomerCode": "GP99IZ",
      "Phone": "1141788181",
      "Whatsapp": "11991402814",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3414,
      "Name": "Henrique",
      "Email": "hrqfernandes@icloud.com",
      "CustomerCode": "7XVNEK",
      "Phone": "1177271851",
      "Whatsapp": "11977271851",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3415,
      "Name": "ALEXANDRE DIAS DE SOUZA",
      "Email": "alexandre.souza.campinas@gmail.com",
      "CustomerCode": "YUVX19",
      "Phone": "1991020592",
      "Whatsapp": "19991020592",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3416,
      "Name": "OTO AUDITORIA CONTABIL LTDA",
      "Email": "adm@oto.com.br",
      "CustomerCode": "KSN5E5",
      "Phone": "1126056800",
      "Whatsapp": "11981366800",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3419,
      "Name": "Alvani Assessoria",
      "Email": "joao@alvaniassessoria.com.br",
      "CustomerCode": "65277",
      "Phone": "4330554455",
      "Whatsapp": "43999157797",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3420,
      "Name": "Caveo Tecnologia Ltda",
      "Email": "wilgo@caveo.com.br",
      "CustomerCode": "19298",
      "Phone": "1174952021",
      "Whatsapp": "11990010565",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3421,
      "Name": "Integra Valor Assessoria Contabil LTDA",
      "Email": "contato@integravalor.com.br",
      "CustomerCode": "19296",
      "Phone": "",
      "Whatsapp": "11986609726",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3422,
      "Name": "Enio Bernardino dos Santos",
      "Email": "eniosantos@adv.oabsp.org.br",
      "CustomerCode": "AB88OV",
      "Phone": "1194702801",
      "Whatsapp": "11947028018",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3423,
      "Name": "Mayanne",
      "Email": "mayanne.teixeira@conectacontabil.com",
      "CustomerCode": "H3AVJE",
      "Phone": "8530820505",
      "Whatsapp": "85997061043",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3424,
      "Name": "Marcela de Araújo Leite",
      "Email": "acec.contabil2@gmail.com",
      "CustomerCode": "19297",
      "Phone": "",
      "Whatsapp": "11976430078",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3425,
      "Name": "RL Consultoria e Serviços Contabeis LTDA",
      "Email": "rafael@rlescritoriocontabil.com.br",
      "CustomerCode": "40193",
      "Phone": "1100000000",
      "Whatsapp": "11943985932",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3426,
      "Name": "BDS Brasil Consultoria Ltda",
      "Email": "rodrigo@bdsbrazil.com",
      "CustomerCode": "37493",
      "Phone": "1131641949",
      "Whatsapp": "11940889264",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3427,
      "Name": "ADR Costa Contabil Contabilidade LTDA",
      "Email": "david@acostacontabil.com.br",
      "CustomerCode": "19299",
      "Phone": "1132312402",
      "Whatsapp": "11982956649",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3428,
      "Name": "Saverio Luiz Botino",
      "Email": "slb@uol.com.br",
      "CustomerCode": "19300",
      "Phone": "1127246443",
      "Whatsapp": "11999853366",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3429,
      "Name": "Fernanda Roberta Cavalcanti Barros",
      "Email": "fernanda08@yahoo.com.br ",
      "CustomerCode": "USW0JV",
      "Phone": "1129443289",
      "Whatsapp": "11993552361",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3430,
      "Name": "Iara Cristina Gouvea Silva",
      "Email": "igouveaa@hotmail.com",
      "CustomerCode": "DGU6EG",
      "Phone": "1239398505",
      "Whatsapp": "12988491304",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3431,
      "Name": "Adriano Bezerra de Medeiros",
      "Email": "contatoplanoa@hotmail.com",
      "CustomerCode": "65280",
      "Phone": "",
      "Whatsapp": "84996244367",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3433,
      "Name": "Reinaldo de Ramos",
      "Email": "reinaldo116@hotmail.com",
      "CustomerCode": "FE8S1Z",
      "Phone": "1125214641",
      "Whatsapp": "11983633358",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3434,
      "Name": "Selma Aparecida Guio  Zanni",
      "Email": "selmazanni@hotmail.com",
      "CustomerCode": "WO2XFE",
      "Phone": "1199605234",
      "Whatsapp": "11996052347",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3435,
      "Name": "Suzane Guimarães",
      "Email": "suzane.guimaraes@hotmail.com",
      "CustomerCode": "19302",
      "Phone": "",
      "Whatsapp": "11985716733",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3436,
      "Name": "Efeito Contabil",
      "Email": "contato@efeitocontabil.com.br",
      "CustomerCode": "F0KN79",
      "Phone": "1997405177",
      "Whatsapp": "19974051772",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3437,
      "Name": "SOU Contabilidade Inteligente Ltda",
      "Email": "rafael.peres@soudigital.co",
      "CustomerCode": "19304",
      "Phone": "1134367984",
      "Whatsapp": "11934367984",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3438,
      "Name": "HCI Global Contabilidade LTDA",
      "Email": "societario@hciglobal.com.br",
      "CustomerCode": "19303",
      "Phone": "",
      "Whatsapp": "27997696992",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3439,
      "Name": "Roseli Lopes de Souza",
      "Email": "rosyloppes@hotmail.com",
      "CustomerCode": "E64LFY",
      "Phone": "1195957427",
      "Whatsapp": "11959574271",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3440,
      "Name": "Jaércio Alexandre Rech",
      "Email": "jaercio@avantiscontabilidade.com.br",
      "CustomerCode": "65281",
      "Phone": "4735255688",
      "Whatsapp": "47989084439",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3441,
      "Name": "Andressa Prado Viana",
      "Email": "andressa.viana@qualitycontabil.com.br",
      "CustomerCode": "19305",
      "Phone": "1128425000",
      "Whatsapp": "11971531224",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3442,
      "Name": "Mayara Gonçalves de Souza",
      "Email": "contato@obrandagency.com",
      "CustomerCode": "O4ZLYG",
      "Phone": "1999424047",
      "Whatsapp": "19994240475",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3443,
      "Name": "Mayara Gonçalves",
      "Email": "contato.obrand@gmail.com",
      "CustomerCode": "OGG95B",
      "Phone": "1999424047",
      "Whatsapp": "19994240475",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3444,
      "Name": "Debora Sguizzardi Conde",
      "Email": "debora-md@uol.com.br",
      "CustomerCode": "3CXFV1",
      "Phone": "1195203776",
      "Whatsapp": "11952003776",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3445,
      "Name": "Delta Consultoria Fiscal e Contábil",
      "Email": "deltaconsultoria@hotmail.com.br",
      "CustomerCode": "ZQ8C7Q",
      "Phone": "1198688227",
      "Whatsapp": "11986882275",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3446,
      "Name": "CLAUDIA JUNIAS SERAFIM DOS SANTOS",
      "Email": "juniasserafim@gmail.com",
      "CustomerCode": "XGR42D",
      "Phone": "7191588797",
      "Whatsapp": "71991588797",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3447,
      "Name": "Amanda Amaro Martins",
      "Email": "amand.asato12@gmail.com",
      "CustomerCode": "RYFIZA",
      "Phone": "1177939371",
      "Whatsapp": "11977939371",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3448,
      "Name": "Milena Leal Parise",
      "Email": "milena@lufranspa.com.br",
      "CustomerCode": "65282",
      "Phone": "1436023738",
      "Whatsapp": "19997901405",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3449,
      "Name": "FCONTABIL CONTABILIDADE LTDA",
      "Email": "simone@fcontabilidade.com.br ",
      "CustomerCode": "SUOVCF",
      "Phone": "1123893673",
      "Whatsapp": "11995863413",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3450,
      "Name": "Edson Guirão Pires",
      "Email": "ipanema.brasil@uol.com.br",
      "CustomerCode": "X434PT",
      "Phone": "1198654011",
      "Whatsapp": "11986540118",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3451,
      "Name": "Milaré Contabilidade LTDA",
      "Email": "emc@milarecontabilidade.com.br",
      "CustomerCode": "19307",
      "Phone": "1161255094",
      "Whatsapp": "11961255094",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3453,
      "Name": "Escritório Contábil Itaapuan",
      "Email": "valdir@contabilitapuan.com.br",
      "CustomerCode": "DD0NMR",
      "Phone": "1149964125",
      "Whatsapp": "11993738229",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3456,
      "Name": "Paulo Ricardo Segeti Ferreira",
      "Email": "paulo@orgedson.com.br",
      "CustomerCode": "19313",
      "Phone": "1132373552",
      "Whatsapp": "11967612241",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3457,
      "Name": "LUIS EDUARDO DA SILVA",
      "Email": "pasiolicon@hotmail.com",
      "CustomerCode": "8HWYCL",
      "Phone": "1186547309",
      "Whatsapp": "11986547309",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3458,
      "Name": "Herika Arten",
      "Email": "herika.arten@hotmail.com",
      "CustomerCode": "O76CF6",
      "Phone": "1177314924",
      "Whatsapp": "11977314924",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3459,
      "Name": "WEST CONSULTORIA E CONTABILIDADE LTDA",
      "Email": "WEST@WESTCONSULTORIA.COM.BR",
      "CustomerCode": "FHR734",
      "Phone": "1136821427",
      "Whatsapp": "11984261360",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3460,
      "Name": "helena aparecida calencio",
      "Email": "ALTAVISAO@UOL.COM.BR",
      "CustomerCode": "3WDLXZ",
      "Phone": "1436410521",
      "Whatsapp": "14991643190",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3461,
      "Name": "D' Paula Contábil",
      "Email": "dpaulacontabil@dpaulacontabil.com.br",
      "CustomerCode": "16929",
      "Phone": "1141171716",
      "Whatsapp": "11973240074",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3462,
      "Name": "Marcos Vinícius Dias Serviços Contábeis Ltda",
      "Email": "marcosvdias@mdcontabilidade.com",
      "CustomerCode": "65283",
      "Phone": "1897514450",
      "Whatsapp": "18997514450",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3463,
      "Name": "Carla Viviane Heylmann",
      "Email": "viviane.capitalsp@hotmail.com",
      "CustomerCode": "19308",
      "Phone": "1155088100",
      "Whatsapp": "11987777589",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3464,
      "Name": "REGISCON CONTABILIDADE SOCIEDADE SIMPLES ",
      "Email": "rodrigo@regiscon.com.br",
      "CustomerCode": "LWXW11",
      "Phone": "4132488371",
      "Whatsapp": "41988390328",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3465,
      "Name": "LUCIA MARIA FERREIRA DA SILVA FRANCO",
      "Email": "shalon.contabil@gmail.com",
      "CustomerCode": "H9OWWH",
      "Phone": "1157615899",
      "Whatsapp": "11957615899",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3466,
      "Name": "Zezinho Contabilidade",
      "Email": "zezinhop@uol.com.br",
      "CustomerCode": "OPUVQR",
      "Phone": "1238822132",
      "Whatsapp": "12997155775",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3467,
      "Name": "Rps Financas e contabilidade",
      "Email": "rpsfinancas@gmail.com",
      "CustomerCode": "Q6XS2N",
      "Phone": "1138769684",
      "Whatsapp": "11947103151",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3469,
      "Name": "Estevão de Sá",
      "Email": "tdagau@gmail.com",
      "CustomerCode": "N363EW",
      "Phone": "6282202553",
      "Whatsapp": "62982202553",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3470,
      "Name": "Erich Palmos Sampaio",
      "Email": "atendimento@sgmconsultores.com.br",
      "CustomerCode": "B02J59",
      "Phone": "1130789626",
      "Whatsapp": "11937512350",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3471,
      "Name": "sabrina nogueira monteiro",
      "Email": "TED.AUTOMOVEIS01@HOTMAIL.COM",
      "CustomerCode": "ZTZCNI",
      "Phone": "6734816447",
      "Whatsapp": "67999327143",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3473,
      "Name": "Ludmila Vieira Casimiro Costa",
      "Email": "ludmilavccosta@gmail.com",
      "CustomerCode": "19309",
      "Phone": "1128928931",
      "Whatsapp": "12997061222",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3476,
      "Name": "JOAO VICENTE NETO",
      "Email": "JOHNETO856@GMAIL.COM",
      "CustomerCode": "XA4MR1",
      "Phone": "1142079066",
      "Whatsapp": "11975812947",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3477,
      "Name": "ALISSON DIAS DE SOUSA",
      "Email": "alissondias1@gmail.com",
      "CustomerCode": "WYH8GL",
      "Phone": "6181500559",
      "Whatsapp": "61981500559",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3478,
      "Name": "Second Contabilidade",
      "Email": "second.adriano@uol.com.br",
      "CustomerCode": "15732",
      "Phone": "1126719737",
      "Whatsapp": "1198333697",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3479,
      "Name": "Bersan Serviços Administrativos LTDA",
      "Email": "fiscal@bersan.com.br",
      "CustomerCode": "19312",
      "Phone": "1146100116",
      "Whatsapp": "11967011171",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3481,
      "Name": "Andréa Moreira",
      "Email": "andreamoreira76@gmail.com",
      "CustomerCode": "19311",
      "Phone": "",
      "Whatsapp": "11992003139",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3482,
      "Name": "greco advogados",
      "Email": "roberto.greco@gccadv.com",
      "CustomerCode": "PJH2ZM",
      "Phone": "1199951365",
      "Whatsapp": "11999513653",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3483,
      "Name": "Melina pelegrini",
      "Email": "melinapelegrini1@gmail.com",
      "CustomerCode": "NSYW19",
      "Phone": "1799638350",
      "Whatsapp": "17996383507",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3484,
      "Name": "Adriana Escrivanini Botelho Pavani ",
      "Email": "nfe@fattorcontabil.com.br",
      "CustomerCode": "65284",
      "Phone": "1733116746",
      "Whatsapp": "1733116746",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3485,
      "Name": "PRISCILA",
      "Email": "PRISCILA@DESAFIOCONTABILASSESSORIA.COM.BR",
      "CustomerCode": "836YQ0",
      "Phone": "1239371216",
      "Whatsapp": "12991275022",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3486,
      "Name": "Rafael Elias Fontes de Andrade ",
      "Email": "Rafael.elias@e2zconsultoria.com.br",
      "CustomerCode": "35329",
      "Phone": "",
      "Whatsapp": "11983575762",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3489,
      "Name": "TRIBUTO CERTO CONTABILIDADE LTDA",
      "Email": "contabilidade@tirbutocerto.com.br",
      "CustomerCode": "A8OY75",
      "Phone": "1147903619",
      "Whatsapp": "11915123803",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3490,
      "Name": "Tributo Certo Contabilidade LTDA",
      "Email": "contabilidade@tributocerto.com.br",
      "CustomerCode": "40195",
      "Phone": "1147903619",
      "Whatsapp": "11915123803",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3492,
      "Name": "Cyber Contador LTDA",
      "Email": "christiele@cybercontador.com.br",
      "CustomerCode": "65285",
      "Phone": "",
      "Whatsapp": "31921802081",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3493,
      "Name": "Maria Lenair de Jesus Silva",
      "Email": "leneir@gmail.com",
      "CustomerCode": "65286",
      "Phone": "",
      "Whatsapp": "98988671601",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3494,
      "Name": "Multiescritório Assessoria Empresarial Especializada LTDA",
      "Email": "alaylton@gmail.com",
      "CustomerCode": "40194",
      "Phone": "1170905158",
      "Whatsapp": "11970905158",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3497,
      "Name": "Aive Negócios Financeiros LTDA",
      "Email": "thiago.luiz@aivenegocios.com.br",
      "CustomerCode": "19314",
      "Phone": "1152414585",
      "Whatsapp": "11985241999",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3499,
      "Name": "Rearsa Organização Contabil LTDA ME",
      "Email": "luizrearsa12@gmail.com",
      "CustomerCode": "64430",
      "Phone": "1338711344",
      "Whatsapp": "13997452895",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3500,
      "Name": "Walquiria Guimaraes Costa",
      "Email": "walquiriacosta09@hotmail.com",
      "CustomerCode": "19316",
      "Phone": "1170832551",
      "Whatsapp": "11970832551",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3501,
      "Name": "Gilson oliveira",
      "Email": "glosasco@terra.com.br",
      "CustomerCode": "19315",
      "Phone": "1135919657",
      "Whatsapp": "11982714869",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3502,
      "Name": "Marlene Alves da Rocha",
      "Email": "da.rocha@terra.com.br",
      "CustomerCode": "VG9TC5",
      "Phone": "8199986008",
      "Whatsapp": "81999876008",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3503,
      "Name": "NASKI PRESTACAO DE SERVICO E GESTAO EMPRESARIAL LTDA",
      "Email": "gruponaski@gmail.com ",
      "CustomerCode": "MPAVI7",
      "Phone": "1154770806",
      "Whatsapp": "11954770806",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3504,
      "Name": "Rio Branco Serviços Contabeis LTDA",
      "Email": "paulo@escritorioriobranco.com.br",
      "CustomerCode": "64022",
      "Phone": "1434781301",
      "Whatsapp": "14996315596",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3505,
      "Name": "Melina pelegrini",
      "Email": "melinapelegrini1@gmail.com",
      "CustomerCode": "D8W4UP",
      "Phone": "1799638350",
      "Whatsapp": "17996383507",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3506,
      "Name": "Simone Menegatti",
      "Email": "simonemenegatti@hotmail.com",
      "CustomerCode": "19317",
      "Phone": "0000000000",
      "Whatsapp": "11930167973",
      "Status": 4,
      "TimeType": 0
    },
    {
      "Id": 3507,
      "Name": "CRISTIANE FONTES",
      "Email": "cris@notekcontabil.com.br",
      "CustomerCode": "E1GH46",
      "Phone": "1148285250",
      "Whatsapp": "11950618623",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3508,
      "Name": "NIRIA SILVA MACARIO",
      "Email": "niria.macario@gmail.com",
      "CustomerCode": "0QJZZQ",
      "Phone": "1196333768",
      "Whatsapp": "11963337686",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3509,
      "Name": "kiyoko Terezinha Ogusuku",
      "Email": "ktocontabil@uol.com.br",
      "CustomerCode": "19318",
      "Phone": "1199550565",
      "Whatsapp": "11995505655",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3510,
      "Name": "MAYARA GOMES CHAVES MARTINS",
      "Email": "mayaragomeschaves@gmail.com",
      "CustomerCode": "MQC3M4",
      "Phone": "1141808973",
      "Whatsapp": "11941808973",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3511,
      "Name": "Ricardo de Carvalho",
      "Email": "ricardo@viacontabilassessoria.com.br",
      "CustomerCode": "QR8OUI",
      "Phone": "1630422828",
      "Whatsapp": "16992874475",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3512,
      "Name": "MN Ferreirinha Contabil",
      "Email": "financeiro@ferreirinhacontabil.com.br",
      "CustomerCode": "37495",
      "Phone": "1141958112",
      "Whatsapp": "11938004119",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3513,
      "Name": "Eduardo Martin",
      "Email": "reemltda@gmail.com",
      "CustomerCode": "15358",
      "Phone": "1155364599",
      "Whatsapp": "11968900049",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3514,
      "Name": "FULL TIME CONTABILIDADE EMPRESARIAL LTDA",
      "Email": "admin@fulltimecontabilidade.com.br",
      "CustomerCode": "7W42C6",
      "Phone": "5135829143",
      "Whatsapp": "51996781244",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3515,
      "Name": "Kleber Maeda",
      "Email": "amandamaeda.lory@gmail.com",
      "CustomerCode": "19319",
      "Phone": "1197688297",
      "Whatsapp": "11976882971",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3516,
      "Name": "Colinear Contabilidade e Finanças Ltda",
      "Email": "lucas@colinear.com.br",
      "CustomerCode": "AKP2Y2",
      "Phone": "4798834763",
      "Whatsapp": "",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3517,
      "Name": "Alexsandro Nunes Nazario",
      "Email": "alexsandronazario@terra.com.br",
      "CustomerCode": "JP7B5D",
      "Phone": "1125592058",
      "Whatsapp": "11972454553",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3519,
      "Name": "Joao Souza",
      "Email": "joaospinto@ig.com.br",
      "CustomerCode": "9ILZDS",
      "Phone": "1168559511",
      "Whatsapp": "11968559511",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3521,
      "Name": "Morgan Augusto de Oliveira",
      "Email": "morgan.advogado@gmail.com",
      "CustomerCode": "TQARRU",
      "Phone": "1170289075",
      "Whatsapp": "11970289075",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3522,
      "Name": "Bobice Soluções Empresariais",
      "Email": "pablo@bobice.com.br",
      "CustomerCode": "65288",
      "Phone": "1934042424",
      "Whatsapp": "1934042424",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3523,
      "Name": "Carla Souza",
      "Email": "csoucontrol@gmail.com ",
      "CustomerCode": "3HF0EM",
      "Phone": "8496255376",
      "Whatsapp": "84996255376",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3524,
      "Name": "MARCELO DE ALMEIDA",
      "Email": "marcelo@agrocontec.com.br",
      "CustomerCode": "NTU7ST",
      "Phone": "6434030654",
      "Whatsapp": "64984098828",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3525,
      "Name": "Flavio Garcia",
      "Email": "fgplanejamento@gmail.com",
      "CustomerCode": "38609",
      "Phone": "1174091215",
      "Whatsapp": "11974091215",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3526,
      "Name": "LIibra Consultoria e Contabilidade LTDA",
      "Email": "chris@libracontabilidade.com.br",
      "CustomerCode": "36101",
      "Phone": "1136814681",
      "Whatsapp": "11950007000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3527,
      "Name": "Antonio Marcos Pereira",
      "Email": "uniao@netnew.com.br",
      "CustomerCode": "19321",
      "Phone": "1732455200",
      "Whatsapp": "17991622660",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3528,
      "Name": "CG SERVIÇOS FISCAIS E DE DEPART. DE PESSOAL LTDA. - ME",
      "Email": "setor.societario@cgcontadores.com.br",
      "CustomerCode": "WQLZYZ",
      "Phone": "5133114333",
      "Whatsapp": "5133114333",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3529,
      "Name": "Rodrigo Rodriguez Martin",
      "Email": "rodrigo@warcap.com.br",
      "CustomerCode": "19323",
      "Phone": "11994366161",
      "Whatsapp": "11994366161",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3530,
      "Name": "Contabilidade Santa Catarina Ltda",
      "Email": "contascatarina@uol.com.br",
      "CustomerCode": "6IMNQI",
      "Phone": "1338216723",
      "Whatsapp": "13996732888",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3531,
      "Name": "INBOUNDCONT CONTABILIDADE DIGITAL",
      "Email": "atendimento@inboundcont.com.br",
      "CustomerCode": "GEJQ82",
      "Phone": "4888377766",
      "Whatsapp": "48988377766",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3532,
      "Name": "LIDIANE RAMOS DOS SAN TOS",
      "Email": "lidianeramos1996@gmail.com ",
      "CustomerCode": "R94TTK",
      "Phone": "6498427326",
      "Whatsapp": "64984273268",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3533,
      "Name": "LIDIANE RAMOS DOS SAN TOS",
      "Email": "lidianeramos1996@gmail.com ",
      "CustomerCode": "SHOF7S",
      "Phone": "6498427326",
      "Whatsapp": "64984273268",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3534,
      "Name": "Marina Ramos",
      "Email": "marina@s2rcontabil.com.br",
      "CustomerCode": "65290",
      "Phone": "1153101109",
      "Whatsapp": "11953101109",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3536,
      "Name": "Solis Serviços Contábeis Ltda",
      "Email": "agnaldo@solisassessoria.com.br",
      "CustomerCode": "4BUHP9",
      "Phone": "1198586160",
      "Whatsapp": "11985861602",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3537,
      "Name": "José Emilio Santos Lima",
      "Email": "jemiliolima@hotmail.com",
      "CustomerCode": "TBD9G5",
      "Phone": "1195203500",
      "Whatsapp": "11952035009",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3538,
      "Name": "Dkt Assessoria Contabil Ltda",
      "Email": "contato@dktcontabilidade.com.br",
      "CustomerCode": "MPC9A2",
      "Phone": "1971331214",
      "Whatsapp": "19971331214",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3539,
      "Name": "AMZN Negócios e Consultoria de Imóveis LTDA",
      "Email": "joycegimenez@pontofortesa.com.br",
      "CustomerCode": "38610",
      "Phone": "1144337777",
      "Whatsapp": "11996162109",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3540,
      "Name": "Antonio Carlos de Souza Naves ",
      "Email": "antonio@rnvconsultoria.com.br ",
      "CustomerCode": "19325",
      "Phone": "11998370565",
      "Whatsapp": "11998370565",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3541,
      "Name": "Rodrigues e Cordeiro Assessoria Contabil LTDA",
      "Email": "fabio.contador@hotmail.com",
      "CustomerCode": "64671",
      "Phone": "1438831158",
      "Whatsapp": "14981763838",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3542,
      "Name": "B&B Escritório Contabil e Consultoria LTDA",
      "Email": "claudio.bertoluci@bebcontabil.com.br",
      "CustomerCode": "19327",
      "Phone": "1120417767",
      "Whatsapp": "11999850894",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3544,
      "Name": "Alexandre Saviolo Egydio",
      "Email": "escritorio.saopaulo.adm@gmail.com",
      "CustomerCode": "62657",
      "Phone": "1732811832",
      "Whatsapp": "1732794337",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3545,
      "Name": "Company Hero ",
      "Email": "monia@companyhero.com",
      "CustomerCode": "54FULS",
      "Phone": "1132327354",
      "Whatsapp": "11947081613",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3547,
      "Name": "Luciano Pereira Pires ",
      "Email": "rohll@uol.com.br",
      "CustomerCode": "35206",
      "Phone": "1120860361",
      "Whatsapp": "11995098072",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3548,
      "Name": "GRUPO TRES / INTERSERVICE CONTABILIDADE",
      "Email": "tatiana@grupotres.com.br",
      "CustomerCode": "FQVE6Z",
      "Phone": "1138712084",
      "Whatsapp": "11991259827",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3549,
      "Name": "Maria de Fátima da Silva ",
      "Email": "fatima.kaka@hotmail.com",
      "CustomerCode": "19326",
      "Phone": "1130622388",
      "Whatsapp": "11970375304",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3550,
      "Name": "Coutbecck Soluções Empresariais e Serviços Digitais Ltda",
      "Email": "contabilidade@coutbecck.com.br",
      "CustomerCode": "65291",
      "Phone": "3533612332",
      "Whatsapp": "3533612332",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3551,
      "Name": "Elisangela de Cassia Santos Santiago",
      "Email": "alphaseteconsultoriacontabil@gmail.com",
      "CustomerCode": "38611",
      "Phone": "",
      "Whatsapp": "11942997942",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3552,
      "Name": "INOVA ASSESSORIA E SOLUÇÕES EMPRESARIAIS",
      "Email": "GISLAINE@INOVAASSESSORIA.COM",
      "CustomerCode": "7V5T4E",
      "Phone": "1196549192",
      "Whatsapp": "11996549192",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3553,
      "Name": "Arthur Bertoncello Golin",
      "Email": "arthurgolin.adv@gmail.com",
      "CustomerCode": "70MXAQ",
      "Phone": "1199959630",
      "Whatsapp": "11999596309",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3554,
      "Name": "Angelica Fernandes Pereira",
      "Email": "amfp12@terra.com.br",
      "CustomerCode": "58474E",
      "Phone": "1197998435",
      "Whatsapp": "11997994354",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3555,
      "Name": "DNC Assessoria Empresarial LTDA",
      "Email": "legalizacao@dnccontabil.com.br",
      "CustomerCode": "37496",
      "Phone": "1135398452",
      "Whatsapp": "11962606811",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3557,
      "Name": "Claudia Aparecida Minieri Martins",
      "Email": "escritoriohumaita@yahoo.com.br",
      "CustomerCode": "19330",
      "Phone": "1998906144",
      "Whatsapp": "19989061448",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3558,
      "Name": "SONÉLIA",
      "Email": "sonelia@hotmail.com",
      "CustomerCode": "J7F2DS",
      "Phone": "7398834060",
      "Whatsapp": "73988340609",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3560,
      "Name": "Pro Result Gestao Empresarial Sociedade Simples Ltda",
      "Email": "thiago@proresult.com.br",
      "CustomerCode": "WMIIXR",
      "Phone": "1132843520",
      "Whatsapp": "11981809292",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3561,
      "Name": "Borsatto Contabilidade LTDA",
      "Email": "adm@borsatto.srv.br ",
      "CustomerCode": "38612",
      "Phone": "1142284035",
      "Whatsapp": "11945195164",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3562,
      "Name": "José Horacio Cardoso de Oliveira",
      "Email": "shimu@idealsocorro.com.br",
      "CustomerCode": "XHKJOJ",
      "Phone": "1938952544",
      "Whatsapp": "19997785650",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3563,
      "Name": "LUZ PUBLICIDADE SP SUL LTDA",
      "Email": "juridico@luzsp.com",
      "CustomerCode": "97Y498",
      "Phone": "1131215555",
      "Whatsapp": null,
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3564,
      "Name": "Camila Aparecida dos Santos",
      "Email": "camilacontabil.santos@gmail.com",
      "CustomerCode": "65293",
      "Phone": "4399127773",
      "Whatsapp": "43991277730",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3565,
      "Name": "jonas oliveira das ilva ",
      "Email": "suprememidias.ok@gmail.com",
      "CustomerCode": "496LAI",
      "Phone": "4184004869",
      "Whatsapp": "41984004869",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3566,
      "Name": "Norma Vicente Sant Ana",
      "Email": "vicenteassessoriaadm@gmail.com",
      "CustomerCode": "PLIGMZ",
      "Phone": "1199999999",
      "Whatsapp": "11911281890",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3567,
      "Name": "Stephanie Cristina Adão Marcilio",
      "Email": "gerencia@mapiacontabil.com.br",
      "CustomerCode": "65292",
      "Phone": "3136714022",
      "Whatsapp": "31999484658",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3569,
      "Name": "Orgacont Assessoria Contabil LTDA",
      "Email": "william.bolonhani@gmail.com",
      "CustomerCode": "38613",
      "Phone": "1131060038",
      "Whatsapp": "11999489861",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3570,
      "Name": "Única Assessoria Contábil ltda",
      "Email": "fabio@contabilidadeunica.com.br",
      "CustomerCode": "8SL4L8",
      "Phone": "1124510888",
      "Whatsapp": "11996124276",
      "Status": 0,
      "TimeType": 0
    },
    {
      "Id": 3571,
      "Name": "Igesp SA Instituto de Gastroenterologia de SP",
      "Email": "marina.dias@hospitaligesp.com.br",
      "CustomerCode": "08XOYK",
      "Phone": "1135884900",
      "Whatsapp": "11976304510",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3572,
      "Name": "DUARTE MENEZES ASSESSORIA CONTABIL LTDA",
      "Email": "contabilidade@duartemenezes.com.br",
      "CustomerCode": "8U9O2B",
      "Phone": "1141398009",
      "Whatsapp": "11987052933",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3574,
      "Name": "CF GESTAO EMPRESARIAL",
      "Email": "cfgestaocontabil@gmail.com",
      "CustomerCode": "4GBSE4",
      "Phone": "6498427326",
      "Whatsapp": "64984273268",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3575,
      "Name": "Thiciane Guanabara Souza",
      "Email": "nexa@nexa.supply",
      "CustomerCode": "19331",
      "Phone": "1140408277",
      "Whatsapp": "11919811000",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3577,
      "Name": "Aline Trindade do Sacramento Cabral",
      "Email": "alinetscabral@gmail.com",
      "CustomerCode": "QK92OF",
      "Phone": "2170228089",
      "Whatsapp": "21970228089",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3579,
      "Name": "Eficon Assessoria Contabil Ltda",
      "Email": "Eficoncontabil@uol.com.br",
      "CustomerCode": "19332",
      "Phone": "1155237947",
      "Whatsapp": "11988982524",
      "Status": 3,
      "TimeType": 0
    },
    {
      "Id": 3581,
      "Name": "Intercon Ass Contabil Empresarial",
      "Email": "iaceempresa@terra.com.br",
      "CustomerCode": "7J9J1Y",
      "Phone": "1122961242",
      "Whatsapp": "11992453629",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3582,
      "Name": "Claudio de Moura ",
      "Email": "choco2003@uol.com.br",
      "CustomerCode": "FT81EU",
      "Phone": "1143783584",
      "Whatsapp": "11987097760",
      "Status": 1,
      "TimeType": 0
    },
    {
      "Id": 3583,
      "Name": "maria giseli vieira de ",
      "Email": "giseli2511@gmail.com",
      "CustomerCode": "UFEPHP",
      "Phone": "1129894466",
      "Whatsapp": "11998723133",
      "Status": 1,
      "TimeType": 0
    }
  ]

  const userData = Storage.getUserData();

  const financialHelper = FinancialHelper();
  const cashFlow = CashFlowController();

  useEffect(() => {
    getCustomerData();
  }, []);

  async function getCustomerData() {
    // CustomerList(customers => {
    SelectedServiceByDate("2024-01-01T00:00", "2024-12-31T23:59", res => {

      for (let s of customers) {
        s.services = res.filter(x => x.process.customerId === s.Id);

        s.revenue = s.services.reduce(function (a, b) {
          return a + b.price;
        }, 0);
      }
      console.log("Resultados", customers);
      setData(customers.filter(x => x.revenue > 0))
    })
    // })
  }

  return (
    <>
      <div className={Storage.getTheme() + " content"}>
        <div className="title">
          <h2 className="content-heading">
            <i className="fa fa-angle-right text-muted mr-1"></i> Fluxo de caixa
          </h2>
        </div>

        <div className="block-content">
          <table className="table table-hover table-bordered">
            {data && data.map(item => (
              <tbody>
                <tr className="border-info">
                  <td className="border-info font-w700">Código de cliente</td>
                  <td className="border-info font-w700">Nome</td>
                  <td className="border-info font-w700">Faturamento</td>
                  <td className="border-info font-w700">Email</td>
                </tr>
                <tr className="border-info">
                  <td className="border-info">{item.CustomerCode}</td>
                  <td className="border-info">{item.Name}</td>
                  <td className="border-info">{MoneyMask(item.revenue)}</td>
                  <td className="border-info">{item.Email}</td>
                </tr>
                <tr className="border-info">
                  <td className="border-info font-w700">Id</td>
                  <td className="border-info font-w700">Nome do serviço</td>
                  <td className="border-info font-w700">Valor</td>
                  <td className="border-info font-w700">Data</td>
                </tr>
                {item.services.map(service => (
                  <tr className="border-info">
                    <td className="border-info">{item.CustomerCode}</td>
                    <td className="border-info">{service.serviceName}</td>
                    <td className="border-info">{service && MoneyMask(service.price)}</td>
                    <td className="border-info">{OnlyDateTranslate(service.acceptedDate)}</td>
                  </tr>
                ))}
              </tbody>
            ))}
          </table>
        </div>
      </div>
    </>
  );
}
